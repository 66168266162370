export const BuildVU = {
  OCR_NEEDED: `
  <!DOCTYPE html >
  <html lang="en">
  <head>
  <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
  <meta charset="utf-8" />
  </head>
  
  <body style="margin: 0;">
  
  <div id="p1" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1285px;">
  
  <!-- Begin page background -->
  <div id="pg1Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
  <div id="pg1" style="-webkit-user-select: none;"><object width="909" height="1285" data="1/1.svg" type="image/svg+xml" id="pdf1" style="width:909px; height:1285px; -moz-transform:scale(1); z-index: 0;"></object></div>
  <!-- End page background -->
  
  
  </div>
  </body>
  </html>
  `,
  Folotec: `
  <!DOCTYPE html >
  <html lang="en">
  <head>
  <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
  <meta charset="utf-8" />
  </head>
  
  <body style="margin: 0;">
  
  <div id="p1" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">
  
  <!-- Begin shared CSS values -->
  <style class="shared-css" type="text/css" >
  .t {
    -webkit-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    transform-origin: bottom left;
    -webkit-transform: scale(0.25);
    -ms-transform: scale(0.25);
    transform: scale(0.25);
    z-index: 2;
    position: absolute;
    white-space: pre;
    overflow: visible;
    line-height: 1.5;
  }
  </style>
  <!-- End shared CSS values -->
  
  
  <!-- Begin inline CSS -->
  <style type="text/css" >
  
  #t1_1{left:87px;bottom:1023px;letter-spacing:0.1px;}
  #t2_1{left:87px;bottom:1002px;word-spacing:0.2px;}
  #t3_1{left:87px;bottom:960px;letter-spacing:0.1px;word-spacing:0.1px;}
  #t4_1{left:628px;bottom:929px;letter-spacing:0.1px;}
  #t5_1{left:837px;bottom:929px;}
  #t6_1{left:628px;bottom:914px;letter-spacing:0.1px;}
  #t7_1{left:806px;bottom:914px;letter-spacing:0.2px;}
  #t8_1{left:628px;bottom:899px;letter-spacing:0.1px;}
  #t9_1{left:742px;bottom:899px;letter-spacing:0.2px;word-spacing:-0.1px;}
  #ta_1{left:628px;bottom:884px;letter-spacing:0.2px;}
  #tb_1{left:775px;bottom:884px;letter-spacing:0.2px;}
  #tc_1{left:628px;bottom:869px;letter-spacing:0.2px;}
  #td_1{left:775px;bottom:869px;letter-spacing:0.2px;}
  #te_1{left:87px;bottom:827px;letter-spacing:0.1px;}
  #tf_1{left:87px;bottom:798px;letter-spacing:0.1px;}
  #tg_1{left:87px;bottom:767px;letter-spacing:0.2px;}
  #th_1{left:87px;bottom:752px;letter-spacing:0.2px;word-spacing:-0.1px;}
  #ti_1{left:89px;bottom:716px;}
  #tj_1{left:146px;bottom:716px;letter-spacing:-0.1px;word-spacing:0.1px;}
  #tk_1{left:272px;bottom:716px;}
  #tl_1{left:347px;bottom:716px;letter-spacing:-0.2px;}
  #tm_1{left:656px;bottom:716px;letter-spacing:-0.3px;}
  #tn_1{left:677px;bottom:698px;letter-spacing:-0.1px;}
  #to_1{left:747px;bottom:716px;letter-spacing:0.1px;}
  #tp_1{left:774px;bottom:698px;letter-spacing:-0.1px;}
  #tq_1{left:96px;bottom:677px;}
  #tr_1{left:155px;bottom:677px;letter-spacing:0.2px;}
  #ts_1{left:195px;bottom:677px;}
  #tt_1{left:260px;bottom:677px;letter-spacing:0.2px;}
  #tu_1{left:347px;bottom:677px;letter-spacing:0.2px;word-spacing:-0.1px;}
  #tv_1{left:347px;bottom:646px;letter-spacing:0.1px;word-spacing:0.1px;}
  #tw_1{left:347px;bottom:631px;letter-spacing:0.1px;}
  #tx_1{left:347px;bottom:616px;letter-spacing:0.2px;}
  #ty_1{left:347px;bottom:601px;letter-spacing:0.2px;word-spacing:-0.1px;}
  #tz_1{left:347px;bottom:586px;letter-spacing:0.2px;word-spacing:-0.1px;}
  #t10_1{left:690px;bottom:677px;letter-spacing:0.2px;}
  #t11_1{left:798px;bottom:677px;letter-spacing:0.2px;}
  #t12_1{left:96px;bottom:549px;letter-spacing:0.1px;}
  #t13_1{left:798px;bottom:549px;letter-spacing:0.2px;}
  #t14_1{left:96px;bottom:527px;letter-spacing:0.1px;}
  #t15_1{left:690px;bottom:527px;letter-spacing:0.2px;}
  #t16_1{left:806px;bottom:527px;letter-spacing:0.2px;}
  #t17_1{left:96px;bottom:489px;letter-spacing:0.1px;}
  #t18_1{left:798px;bottom:489px;letter-spacing:0.2px;}
  #t19_1{left:87px;bottom:455px;letter-spacing:0.1px;}
  #t1a_1{left:87px;bottom:425px;}
  #t1b_1{left:87px;bottom:379px;letter-spacing:0.1px;}
  #t1c_1{left:87px;bottom:349px;letter-spacing:0.1px;}
  #t1d_1{left:87px;bottom:334px;letter-spacing:0.2px;}
  
  .s1{
    FONT-SIZE: 73.3px;
    FONT-FAMILY: Arial_d;
    color: rgb(0,0,0);
  }
  
  .s2{
    FONT-SIZE: 73.3px;
    FONT-FAMILY: Arial-Bold_e;
    color: rgb(0,0,0);
  }
  
  .s3{
    FONT-SIZE: 55px;
    FONT-FAMILY: Arial_d;
    color: rgb(0,0,0);
  }
  
  .s4{
    FONT-SIZE: 60.9px;
    FONT-FAMILY: Arial_d;
    color: rgb(0,0,0);
  }
  
  .s5{
    FONT-SIZE: 55px;
    FONT-FAMILY: Arial-Bold_e;
    color: rgb(0,0,0);
  }
  
  </style>
  <!-- End inline CSS -->
  
  <!-- Begin embedded font definitions -->
  <style id="fonts1" type="text/css" >
  
  @font-face {
    font-family: Arial-Bold_e;
    src: url("fonts/Arial-Bold_e.woff") format("woff");
  }
  
  @font-face {
    font-family: Arial_d;
    src: url("fonts/Arial_d.woff") format("woff");
  }
  
  </style>
  <!-- End embedded font definitions -->
  
  <!-- Begin page background -->
  <div id="pg1Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
  <div id="pg1" style="-webkit-user-select: none;"><object width="909" height="1286" data="1/1.svg" type="image/svg+xml" id="pdf1" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
  <!-- End page background -->
  
  
  <!-- Begin text definitions (Positioned/styled in CSS) -->
  <div id="t1_1" class="t s1">APSOparts® GmbH Deutschland</div>
  <div id="t2_1" class="t s1">Schulze-Delitzsch-Straße 38</div>
  <div id="t3_1" class="t s2">70565 Stuttgart</div>
  <div id="t4_1" class="t s3">Seite:</div>
  <div id="t5_1" class="t s3">1</div>
  <div id="t6_1" class="t s3">Lief.-Nr.:</div>
  <div id="t7_1" class="t s3">70271</div>
  <div id="t8_1" class="t s3">Bearbeiter:</div>
  <div id="t9_1" class="t s3">Jennifer Leopold</div>
  <div id="ta_1" class="t s3">Lieferdatum:</div>
  <div id="tb_1" class="t s3">18.12.2018</div>
  <div id="tc_1" class="t s3">Datum:</div>
  <div id="td_1" class="t s3">14.12.2018</div>
  <div id="te_1" class="t s2">Bestellung Nr. 52004371</div>
  <div id="tf_1" class="t s3">Sehr geehrte Damen und Herren,</div>
  <div id="tg_1" class="t s3">hiermit bestellen wir unter Zugrundelegung unserer Allgemeinen</div>
  <div id="th_1" class="t s3">Geschäftsbedingungen folgende Positionen:</div>
  <div id="ti_1" class="t s4">Pos.</div>
  <div id="tj_1" class="t s4">Menge / ME</div>
  <div id="tk_1" class="t s4">Art.-Nr</div>
  <div id="tl_1" class="t s4">Bezeichnung</div>
  <div id="tm_1" class="t s4">Einzelpreis</div>
  <div id="tn_1" class="t s4">EUR</div>
  <div id="to_1" class="t s4">Gesamtpreis</div>
  <div id="tp_1" class="t s4">EUR</div>
  <div id="tq_1" class="t s3">1</div>
  <div id="tr_1" class="t s3">1,00</div>
  <div id="ts_1" class="t s3">ST</div>
  <div id="tt_1" class="t s3">1004375</div>
  <div id="tu_1" class="t s3">Gleitband PE-UHMW </div>
  <div id="tv_1" class="t s3">Typ:7.4210 (CHR 2302-10R)</div>
  <div id="tw_1" class="t s3">Farbe: natur (weiss)</div>
  <div id="tx_1" class="t s3">Breite: 101,6 mm</div>
  <div id="ty_1" class="t s3">Rollenlänge: 33 m</div>
  <div id="tz_1" class="t s3">Dicke: 0.254 mm</div>
  <div id="t10_1" class="t s3">203,41</div>
  <div id="t11_1" class="t s3">203,41</div>
  <div id="t12_1" class="t s3">Gesamt Netto</div>
  <div id="t13_1" class="t s3">203,41</div>
  <div id="t14_1" class="t s3">zzgl. 19,00 % USt. auf</div>
  <div id="t15_1" class="t s3">203,41</div>
  <div id="t16_1" class="t s3">38,65</div>
  <div id="t17_1" class="t s5">Gesamtbetrag</div>
  <div id="t18_1" class="t s5">242,06</div>
  <div id="t19_1" class="t s3">Zahlbar innerhalb von 30 Tagen ohne Abzug</div>
  <div id="t1a_1" class="t s3">.</div>
  <div id="t1b_1" class="t s3">Mit freundlichen Grüßen</div>
  <div id="t1c_1" class="t s3">Folo-Tec GmbH</div>
  <div id="t1d_1" class="t s3">Spezialprodukte in Kunststoff</div>
  
  <!-- End text definitions -->
  
  
  </div>
  </body>
  </html>
  `,
  Hasler: `
      <!DOCTYPE html >
      <html lang="en">
      <head>
      <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
      <meta charset="utf-8" />
      </head>
      
      <body style="margin: 0;">
      
      <div id="p1" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">
      
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css" >
      .t {
        -webkit-transform-origin: bottom left;
        -ms-transform-origin: bottom left;
        transform-origin: bottom left;
        -webkit-transform: scale(0.25);
        -ms-transform: scale(0.25);
        transform: scale(0.25);
        z-index: 2;
        position: absolute;
        white-space: pre;
        overflow: visible;
      }
      </style>
      <!-- End shared CSS values -->
      
      
      <!-- Begin inline CSS -->
      <style type="text/css" >
      
      #t1_1{left:83px;bottom:1082px;}
      #t2_1{left:83px;bottom:1061px;}
      #t3_1{left:83px;bottom:1046px;}
      #t4_1{left:83px;bottom:1030px;}
      #t5_1{left:83px;bottom:990px;}
      #t6_1{left:83px;bottom:970px;}
      #t7_1{left:83px;bottom:954px;}
      #t8_1{left:83px;bottom:938px;}
      #t9_1{left:527px;bottom:1061px;}
      #ta_1{left:527px;bottom:1046px;}
      #tb_1{left:527px;bottom:1030px;}
      #tc_1{left:527px;bottom:1014px;}
      #td_1{left:527px;bottom:998px;}
      #te_1{left:527px;bottom:905px;}
      #tf_1{left:642px;bottom:905px;}
      #tg_1{left:527px;bottom:884px;}
      #th_1{left:642px;bottom:884px;}
      #ti_1{left:82px;bottom:844px;}
      #tj_1{left:527px;bottom:844px;}
      #tk_1{left:797px;bottom:844px;}
      #tl_1{left:855px;bottom:844px;}
      #tm_1{left:83px;bottom:803px;}
      #tn_1{left:213px;bottom:802px;}
      #to_1{left:83px;bottom:787px;}
      #tp_1{left:213px;bottom:787px;}
      #tq_1{left:213px;bottom:771px;}
      #tr_1{left:126px;bottom:738px;word-spacing:3.8px;}
      #ts_1{left:213px;bottom:738px;}
      #tt_1{left:668px;bottom:738px;}
      #tu_1{left:213px;bottom:698px;}
      #tv_1{left:157px;bottom:652px;letter-spacing:17.3px;}
      #tw_1{left:213px;bottom:652px;}
      #tx_1{left:213px;bottom:636px;}
      #ty_1{left:213px;bottom:620px;}
      #tz_1{left:214px;bottom:604px;}
      #t10_1{left:433px;bottom:604px;}
      #t11_1{left:213px;bottom:589px;}
      #t12_1{left:667px;bottom:652px;}
      #t13_1{left:157px;bottom:567px;letter-spacing:-0.1px;word-spacing:2.2px;}
      #t14_1{left:213px;bottom:567px;}
      #t15_1{left:213px;bottom:552px;}
      #t16_1{left:213px;bottom:536px;}
      #t17_1{left:214px;bottom:520px;}
      #t18_1{left:433px;bottom:520px;}
      #t19_1{left:667px;bottom:567px;}
      #t1a_1{left:150px;bottom:499px;word-spacing:2.2px;}
      #t1b_1{left:213px;bottom:499px;}
      #t1c_1{left:213px;bottom:483px;}
      #t1d_1{left:214px;bottom:467px;}
      #t1e_1{left:667px;bottom:499px;}
      #t1f_1{left:150px;bottom:446px;word-spacing:2.2px;}
      #t1g_1{left:213px;bottom:446px;}
      #t1h_1{left:213px;bottom:430px;}
      #t1i_1{left:213px;bottom:414px;}
      #t1j_1{left:214px;bottom:399px;}
      #t1k_1{left:667px;bottom:446px;}
      #t1l_1{left:157px;bottom:377px;letter-spacing:17.3px;}
      #t1m_1{left:213px;bottom:377px;}
      #t1n_1{left:213px;bottom:362px;}
      #t1o_1{left:213px;bottom:346px;}
      #t1p_1{left:214px;bottom:330px;}
      #t1q_1{left:667px;bottom:377px;}
      #t1r_1{left:157px;bottom:309px;letter-spacing:17.3px;}
      #t1s_1{left:213px;bottom:309px;}
      #t1t_1{left:213px;bottom:293px;}
      #t1u_1{left:213px;bottom:277px;}
      #t1v_1{left:214px;bottom:262px;}
      #t1w_1{left:667px;bottom:309px;}
      #t1x_1{left:150px;bottom:240px;word-spacing:2.1px;}
      #t1y_1{left:213px;bottom:240px;}
      #t1z_1{left:213px;bottom:225px;}
      #t20_1{left:213px;bottom:209px;}
      #t21_1{left:214px;bottom:193px;}
      #t22_1{left:667px;bottom:240px;}
      #t23_1{left:157px;bottom:172px;letter-spacing:-0.1px;word-spacing:2.2px;}
      #t24_1{left:213px;bottom:172px;}
      #t25_1{left:213px;bottom:156px;}
      #t26_1{left:213px;bottom:140px;}
      #t27_1{left:214px;bottom:124px;}
      #t28_1{left:667px;bottom:172px;}
      #t29_1{left:157px;bottom:103px;letter-spacing:-0.1px;word-spacing:2.2px;}
      #t2a_1{left:213px;bottom:103px;}
      #t2b_1{left:213px;bottom:87px;}
      #t2c_1{left:667px;bottom:103px;}
      #t2d_1{left:376px;bottom:51px;}
      #t2e_1{left:280px;bottom:37px;}
      #t2f_1{left:528px;bottom:37px;}
      #t2g_1{left:369px;bottom:23px;}
      
      .s1_1{
        FONT-SIZE: 55px;
        FONT-FAMILY: ArialMT_g;
        color: rgb(0,0,0);
      }
      
      .s2_1{
        FONT-SIZE: 61.1px;
        FONT-FAMILY: Arial-BoldMT_h;
        color: rgb(0,0,0);
      }
      
      .s3_1{
        FONT-SIZE: 48.9px;
        FONT-FAMILY: ArialMT_g;
        color: rgb(0,0,0);
      }
      
      .s4_1{
        FONT-SIZE: 48.9px;
        FONT-FAMILY: Arial-BoldMT_h;
        color: rgb(0,0,0);
      }
      
      </style>
      <!-- End inline CSS -->
      
      <!-- Begin embedded font definitions -->
      <style id="fonts1" type="text/css" >
      
      @font-face {
        font-family: Arial-BoldMT_h;
        src: url("fonts/Arial-BoldMT_h.woff") format("woff");
      }
      
      @font-face {
        font-family: ArialMT_g;
        src: url("fonts/ArialMT_g.woff") format("woff");
      }
      
      </style>
      <!-- End embedded font definitions -->
      
      <!-- Begin page background -->
      <div id="pg1Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
      <div id="pg1" style="-webkit-user-select: none;"><object width="909" height="1286" data="1/1.svg" type="image/svg+xml" id="pdf1" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
      <!-- End page background -->
      
      
      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div id="t1_1" class="t s1_1">Lieferadresse:</div>
      <div id="t2_1" class="t s1_1">Hasler + Co AG, Winterthur</div>
      <div id="t3_1" class="t s1_1">Rudolf-Diesel-Strasse 12</div>
      <div id="t4_1" class="t s1_1">8404 Winterthur</div>
      <div id="t5_1" class="t s1_1">Fakturaadresse:</div>
      <div id="t6_1" class="t s1_1">Hasler + Co AG, Winterthur</div>
      <div id="t7_1" class="t s1_1">Rudolf-Diesel-Strasse 12</div>
      <div id="t8_1" class="t s1_1">8404 Winterthur</div>
      <div id="t9_1" class="t s1_1">Angst + Pfister AG</div>
      <div id="ta_1" class="t s1_1">Industrie - Komponenten</div>
      <div id="tb_1" class="t s1_1">Thurgauerstrasse 66</div>
      <div id="tc_1" class="t s1_1">Postfach</div>
      <div id="td_1" class="t s1_1">8052 Zürich</div>
      <div id="te_1" class="t s1_1">Tel</div>
      <div id="tf_1" class="t s1_1">044 306 61 11</div>
      <div id="tg_1" class="t s1_1">E-Mail</div>
      <div id="th_1" class="t s1_1">ch@angst-pfister.com</div>
      <div id="ti_1" class="t s2_1">Bestellung 10-590984/0</div>
      <div id="tj_1" class="t s1_1">Winterthur, 15.11.2019</div>
      <div id="tk_1" class="t s1_1">Seite:</div>
      <div id="tl_1" class="t s1_1">1</div>
      <div id="tm_1" class="t s3_1">U/Referenz</div>
      <div id="tn_1" class="t s1_1">Rafael Osta</div>
      <div id="to_1" class="t s3_1">Lieferung</div>
      <div id="tp_1" class="t s1_1">20.11.2019</div>
      <div id="tq_1" class="t s1_1">kostengünstigst</div>
      <div id="tr_1" class="t s4_1">Menge Eht</div>
      <div id="ts_1" class="t s4_1">Bezeichnung</div>
      <div id="tt_1" class="t s4_1">I/Artikel-NR</div>
      <div id="tu_1" class="t s1_1">*Pos. 2 Zusatztext beachten!*</div>
      <div id="tv_1" class="t s1_1">2m</div>
      <div id="tw_1" class="t s1_1">POM-C AH Rundstab, schwarz</div>
      <div id="tx_1" class="t s1_1">Ø 40 + 0,2/+ 1,1 mm</div>
      <div id="ty_1" class="t s1_1">POM-C, SCHWARZ RUNDS</div>
      <div id="tz_1" class="t s1_1">U/Artikel-NR AP01.1935.1040 | NL </div>
      <div id="t10_1" class="t s1_1">| Auftrag 2886706/0</div>
      <div id="t11_1" class="t s1_1">Zwei Stück à 1m</div>
      <div id="t12_1" class="t s1_1">01.1935.1040</div>
      <div id="t13_1" class="t s1_1">5 Stk</div>
      <div id="t14_1" class="t s1_1">Zellkautschukplatte CR 0,21 g/cm³</div>
      <div id="t15_1" class="t s1_1">schwarz L: 1850 mm, B: 1000 mm, D: 3 mm</div>
      <div id="t16_1" class="t s1_1">ZELLKAUTSCHUKPLATTE</div>
      <div id="t17_1" class="t s1_1">U/Artikel-NR AP10.1021.2303 | NL </div>
      <div id="t18_1" class="t s1_1">| Auftrag 2887532/0</div>
      <div id="t19_1" class="t s1_1">10.1021.2303</div>
      <div id="t1a_1" class="t s1_1">10 Stk</div>
      <div id="t1b_1" class="t s1_1">APSOvib Anschlagpuffer Form B mittel</div>
      <div id="t1c_1" class="t s1_1">D 15 mm: H 14 mm: G M4: L 13 mm</div>
      <div id="t1d_1" class="t s1_1">U/Artikel-NR 23.3090.0015</div>
      <div id="t1e_1" class="t s1_1">12.2036.1003</div>
      <div id="t1f_1" class="t s1_1">30 Stk</div>
      <div id="t1g_1" class="t s1_1">Schnellkupplung m. Innengewinde</div>
      <div id="t1h_1" class="t s1_1">Gewinde 1/2 Zoll, Messing</div>
      <div id="t1i_1" class="t s1_1">mit EAN-Etikette</div>
      <div id="t1j_1" class="t s1_1">U/Artikel-NR 25.1830.0100</div>
      <div id="t1k_1" class="t s1_1">06.5033.2012</div>
      <div id="t1l_1" class="t s1_1">3m</div>
      <div id="t1m_1" class="t s1_1">PVC-U Rohr, grau RAL 7011</div>
      <div id="t1n_1" class="t s1_1">Ø 32 x 27,2 mm</div>
      <div id="t1o_1" class="t s1_1">PVC-U, RAL 7011 ROHR</div>
      <div id="t1p_1" class="t s1_1">U/Artikel-NR AP01.2120.1008 | NL </div>
      <div id="t1q_1" class="t s1_1">01.2120.1008</div>
      <div id="t1r_1" class="t s1_1">2m</div>
      <div id="t1s_1" class="t s1_1">Acrylglas PMMA-GS Rundstab, transparent</div>
      <div id="t1t_1" class="t s1_1">Ø 40 ± 0,2 mm</div>
      <div id="t1u_1" class="t s1_1">PMMA-GS, TRANSPARENT</div>
      <div id="t1v_1" class="t s1_1">U/Artikel-NR AP01.2415.0040 | NL </div>
      <div id="t1w_1" class="t s1_1">01.2415.0040</div>
      <div id="t1x_1" class="t s1_1">12 m</div>
      <div id="t1y_1" class="t s1_1">PMMA-XT Rohr, transparent</div>
      <div id="t1z_1" class="t s1_1">Ø 30 / 26,0 mm</div>
      <div id="t20_1" class="t s1_1">PMMA-XT, TRANSPARENT</div>
      <div id="t21_1" class="t s1_1">U/Artikel-NR AP01.2420.0028 | NL </div>
      <div id="t22_1" class="t s1_1">01.2420.0028</div>
      <div id="t23_1" class="t s1_1">5 Stk</div>
      <div id="t24_1" class="t s1_1">Messingverschraubung Mutterteil 6-kt, 10</div>
      <div id="t25_1" class="t s1_1">mm, G 3/8</div>
      <div id="t26_1" class="t s1_1">MESSINGVERSCHRAUBUNG</div>
      <div id="t27_1" class="t s1_1">U/Artikel-NR AP06.5030.6002</div>
      <div id="t28_1" class="t s1_1">06.5030.6002</div>
      <div id="t29_1" class="t s1_1">1 Stk</div>
      <div id="t2a_1" class="t s1_1">BAND-IT Band 201 C 204</div>
      <div id="t2b_1" class="t s1_1">B= 1/2 Z / 12,7 mm: L=30,5 m</div>
      <div id="t2c_1" class="t s1_1">06.5047.0204</div>
      <div id="t2d_1" class="t s3_1">Hasler + Co AG, Zentrallager</div>
      <div id="t2e_1" class="t s3_1">Rudolf-Diesel-Strasse 12 · 8404 Winterthur · </div>
      <div id="t2f_1" class="t s3_1">Tel. 052 235 33 33</div>
      <div id="t2g_1" class="t s3_1">info@hasler.ch · www.hasler.ch</div>
      
      <!-- End text definitions -->
      
      
      </div>
      </body>
      </html>

<!DOCTYPE html >
<html lang="en">
<head>
<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
<meta charset="utf-8" />
</head>

<body style="margin: 0;">

<div id="p2" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
	-webkit-transform: scale(0.25);
	-ms-transform: scale(0.25);
	transform: scale(0.25);
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_2{left:82px;bottom:1080px;}
#t2_2{left:527px;bottom:1080px;}
#t3_2{left:797px;bottom:1080px;}
#t4_2{left:855px;bottom:1080px;}
#t5_2{left:126px;bottom:1040px;word-spacing:3.8px;}
#t6_2{left:213px;bottom:1040px;}
#t7_2{left:668px;bottom:1040px;}
#t8_2{left:213px;bottom:999px;}
#t9_2{left:214px;bottom:983px;}
#ta_2{left:157px;bottom:962px;letter-spacing:-0.1px;word-spacing:2.2px;}
#tb_2{left:213px;bottom:962px;}
#tc_2{left:213px;bottom:946px;}
#td_2{left:213px;bottom:931px;}
#te_2{left:214px;bottom:915px;}
#tf_2{left:667px;bottom:962px;}
#tg_2{left:150px;bottom:894px;word-spacing:2.1px;}
#th_2{left:213px;bottom:894px;}
#ti_2{left:213px;bottom:878px;}
#tj_2{left:213px;bottom:862px;}
#tk_2{left:214px;bottom:846px;}
#tl_2{left:667px;bottom:894px;}
#tm_2{left:157px;bottom:825px;letter-spacing:-0.1px;word-spacing:2.2px;}
#tn_2{left:213px;bottom:825px;}
#to_2{left:213px;bottom:809px;}
#tp_2{left:213px;bottom:793px;}
#tq_2{left:214px;bottom:778px;}
#tr_2{left:667px;bottom:825px;}
#ts_2{left:157px;bottom:756px;letter-spacing:-0.1px;word-spacing:2.2px;}
#tt_2{left:213px;bottom:756px;}
#tu_2{left:213px;bottom:741px;}
#tv_2{left:213px;bottom:725px;}
#tw_2{left:214px;bottom:709px;}
#tx_2{left:667px;bottom:756px;}
#ty_2{left:157px;bottom:688px;letter-spacing:17.3px;}
#tz_2{left:213px;bottom:688px;}
#t10_2{left:213px;bottom:672px;}
#t11_2{left:213px;bottom:656px;}
#t12_2{left:214px;bottom:641px;}
#t13_2{left:667px;bottom:688px;}
#t14_2{left:157px;bottom:619px;letter-spacing:17.3px;}
#t15_2{left:213px;bottom:619px;}
#t16_2{left:213px;bottom:604px;}
#t17_2{left:213px;bottom:588px;}
#t18_2{left:214px;bottom:572px;}
#t19_2{left:667px;bottom:619px;}
#t1a_2{left:157px;bottom:551px;letter-spacing:-0.1px;word-spacing:2.2px;}
#t1b_2{left:213px;bottom:551px;}
#t1c_2{left:213px;bottom:535px;}
#t1d_2{left:213px;bottom:519px;}
#t1e_2{left:214px;bottom:503px;}
#t1f_2{left:667px;bottom:551px;}
#t1g_2{left:157px;bottom:482px;letter-spacing:-0.1px;word-spacing:2.2px;}
#t1h_2{left:213px;bottom:482px;}
#t1i_2{left:213px;bottom:466px;}
#t1j_2{left:213px;bottom:451px;}
#t1k_2{left:214px;bottom:435px;}
#t1l_2{left:667px;bottom:482px;}
#t1m_2{left:157px;bottom:414px;letter-spacing:17.3px;}
#t1n_2{left:213px;bottom:414px;}
#t1o_2{left:213px;bottom:398px;}
#t1p_2{left:213px;bottom:382px;}
#t1q_2{left:214px;bottom:366px;}
#t1r_2{left:667px;bottom:414px;}
#t1s_2{left:157px;bottom:345px;letter-spacing:-0.1px;word-spacing:2.2px;}
#t1t_2{left:213px;bottom:345px;}
#t1u_2{left:213px;bottom:329px;}
#t1v_2{left:213px;bottom:313px;}
#t1w_2{left:214px;bottom:298px;}
#t1x_2{left:667px;bottom:345px;}
#t1y_2{left:157px;bottom:277px;letter-spacing:17.3px;}
#t1z_2{left:213px;bottom:277px;}
#t20_2{left:213px;bottom:261px;}
#t21_2{left:213px;bottom:245px;}
#t22_2{left:214px;bottom:229px;}
#t23_2{left:667px;bottom:277px;}
#t24_2{left:150px;bottom:208px;word-spacing:2.1px;}
#t25_2{left:213px;bottom:208px;}
#t26_2{left:213px;bottom:192px;}
#t27_2{left:213px;bottom:176px;}
#t28_2{left:214px;bottom:161px;}
#t29_2{left:667px;bottom:208px;}
#t2a_2{left:150px;bottom:139px;word-spacing:2.2px;}
#t2b_2{left:213px;bottom:139px;}
#t2c_2{left:213px;bottom:124px;}
#t2d_2{left:213px;bottom:108px;}
#t2e_2{left:214px;bottom:92px;}
#t2f_2{left:667px;bottom:139px;}
#t2g_2{left:376px;bottom:51px;}
#t2h_2{left:280px;bottom:37px;}
#t2i_2{left:528px;bottom:37px;}
#t2j_2{left:369px;bottom:23px;}

.s1_2{
	FONT-SIZE: 61.1px;
	FONT-FAMILY: Arial-BoldMT_h;
	color: rgb(0,0,0);
}

.s2_2{
	FONT-SIZE: 55px;
	FONT-FAMILY: ArialMT_g;
	color: rgb(0,0,0);
}

.s3_2{
	FONT-SIZE: 48.9px;
	FONT-FAMILY: Arial-BoldMT_h;
	color: rgb(0,0,0);
}

.s4_2{
	FONT-SIZE: 48.9px;
	FONT-FAMILY: ArialMT_g;
	color: rgb(0,0,0);
}

</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts2" type="text/css" >

@font-face {
	font-family: Arial-BoldMT_h;
	src: url("fonts/Arial-BoldMT_h.woff") format("woff");
}

@font-face {
	font-family: ArialMT_g;
	src: url("fonts/ArialMT_g.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg2Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg2" style="-webkit-user-select: none;"><object width="909" height="1286" data="2/2.svg" type="image/svg+xml" id="pdf2" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div id="t1_2" class="t s1_2">Bestellung 10-590984/0</div>
<div id="t2_2" class="t s2_2">Winterthur, 15.11.2019</div>
<div id="t3_2" class="t s2_2">Seite:</div>
<div id="t4_2" class="t s2_2">2</div>
<div id="t5_2" class="t s3_2">Menge Eht</div>
<div id="t6_2" class="t s3_2">Bezeichnung</div>
<div id="t7_2" class="t s3_2">I/Artikel-NR</div>
<div id="t8_2" class="t s2_2">BAND-IT BAND 201 TYP</div>
<div id="t9_2" class="t s2_2">U/Artikel-NR AP06.5047.0204 | NL </div>
<div id="ta_2" class="t s2_2">1 Stk</div>
<div id="tb_2" class="t s2_2">BAND-IT Valustrap Schlaufen 304 C 154</div>
<div id="tc_2" class="t s2_2">B= 1/2 Z / 12,7 mm: Box à 100</div>
<div id="td_2" class="t s2_2">BAND-IT VALUSTRAP SC</div>
<div id="te_2" class="t s2_2">U/Artikel-NR AP06.5047.1154 | NL </div>
<div id="tf_2" class="t s2_2">06.5047.1154</div>
<div id="tg_2" class="t s2_2">40 m</div>
<div id="th_2" class="t s2_2">Wasserschlauch TRIX-ROTSTRAHL</div>
<div id="ti_2" class="t s2_2">ID 16 x AD 23 mm</div>
<div id="tj_2" class="t s2_2">WASSERSCHLAUCH TRIX-</div>
<div id="tk_2" class="t s2_2">U/Artikel-NR AP06.5512.4016</div>
<div id="tl_2" class="t s2_2">06.5512.4016</div>
<div id="tm_2" class="t s2_2">1 Stk</div>
<div id="tn_2" class="t s2_2">LEGRIS Durchgangskugelhahn Typ 0491</div>
<div id="to_2" class="t s2_2">DN 4 Zapfen/Muffe G 1/4 0491 04 13</div>
<div id="tp_2" class="t s2_2">DURCHGANGSKUGELHAHN,</div>
<div id="tq_2" class="t s2_2">U/Artikel-NR AP09.0501.1704 | NL </div>
<div id="tr_2" class="t s2_2">09.0501.1704</div>
<div id="ts_2" class="t s2_2">1 Stk</div>
<div id="tt_2" class="t s2_2">APSOdrive Standardkeilriemen 13/A</div>
<div id="tu_2" class="t s2_2">A43, Li 1090 mm</div>
<div id="tv_2" class="t s2_2">STANDARDKEILRIEMEN A</div>
<div id="tw_2" class="t s2_2">U/Artikel-NR AP09.1130.2109 | NL </div>
<div id="tx_2" class="t s2_2">09.1130.2109</div>
<div id="ty_2" class="t s2_2">2m</div>
<div id="tz_2" class="t s2_2">Zahnriemen BRECO T5 Meterware Rollen</div>
<div id="t10_2" class="t s2_2">10T5-M</div>
<div id="t11_2" class="t s2_2">ZAHNRIEMEN BRECO T5</div>
<div id="t12_2" class="t s2_2">U/Artikel-NR AP09.1841.0505 | NL </div>
<div id="t13_2" class="t s2_2">09.1841.0505</div>
<div id="t14_2" class="t s2_2">2m</div>
<div id="t15_2" class="t s2_2">Zahnriemen BRECO AT10 Meterware Rollen</div>
<div id="t16_2" class="t s2_2">16AT10-M</div>
<div id="t17_2" class="t s2_2">ZAHNRIEMEN BRECO AT1</div>
<div id="t18_2" class="t s2_2">U/Artikel-NR AP09.1841.4005 | NL </div>
<div id="t19_2" class="t s2_2">09.1841.4005</div>
<div id="t1a_2" class="t s2_2">2 Stk</div>
<div id="t1b_2" class="t s2_2">Zahnrad 21 T5, Riemen</div>
<div id="t1c_2" class="t s2_2">B: 10 mm AL 21T5/10-2 Nabe 8 x 6</div>
<div id="t1d_2" class="t s2_2">ZAHNRAD 21 T5 FÜR ZA</div>
<div id="t1e_2" class="t s2_2">U/Artikel-NR AP09.1941.1101 | NL </div>
<div id="t1f_2" class="t s2_2">09.1941.1101</div>
<div id="t1g_2" class="t s2_2">2 Stk</div>
<div id="t1h_2" class="t s2_2">Zahnrad 31 T10, Riemen</div>
<div id="t1i_2" class="t s2_2">B: 16 mm AL 31T10/12-2 Nabe 28 x 10</div>
<div id="t1j_2" class="t s2_2">ZAHNRAD 31 T10 FÜR Z</div>
<div id="t1k_2" class="t s2_2">U/Artikel-NR AP09.1941.1301 | NL </div>
<div id="t1l_2" class="t s2_2">09.1941.1301</div>
<div id="t1m_2" class="t s2_2">6m</div>
<div id="t1n_2" class="t s2_2">AUMA Spitzgerippte Gummimatte</div>
<div id="t1o_2" class="t s2_2">D: 3 ± 0,4 mm B 1250 mm ohne Einlage</div>
<div id="t1p_2" class="t s2_2">BELAG NR/SBR SPITZGE</div>
<div id="t1q_2" class="t s2_2">U/Artikel-NR AP10.1203.1101</div>
<div id="t1r_2" class="t s2_2">10.1203.1101</div>
<div id="t1s_2" class="t s2_2">1 Stk</div>
<div id="t1t_2" class="t s2_2">Primer V-2 für Silikon-Klebstoffe</div>
<div id="t1u_2" class="t s2_2">Flasche à 100 ml</div>
<div id="t1v_2" class="t s2_2">PRIMER V-2 FÜR SILIK</div>
<div id="t1w_2" class="t s2_2">U/Artikel-NR AP10.1603.0214 | NL </div>
<div id="t1x_2" class="t s2_2">10.1603.0214</div>
<div id="t1y_2" class="t s2_2">5m</div>
<div id="t1z_2" class="t s2_2">U-Klemmprofil armiert PVC, 9,5 x 6,5 mm</div>
<div id="t20_2" class="t s2_2">schwarz</div>
<div id="t21_2" class="t s2_2">U-KLEMMPROFIL ARMIER</div>
<div id="t22_2" class="t s2_2">U/Artikel-NR AP10.2508.8940 | NL </div>
<div id="t23_2" class="t s2_2">10.2508.8940</div>
<div id="t24_2" class="t s2_2">20 m</div>
<div id="t25_2" class="t s2_2">Rundschnur NBR 70 Sh A schwarz</div>
<div id="t26_2" class="t s2_2">Ø 8,00 + 0,50/-0,20 mm</div>
<div id="t27_2" class="t s2_2">RUNDSCHNUR NBR 70 ±5</div>
<div id="t28_2" class="t s2_2">U/Artikel-NR AP10.2603.0708</div>
<div id="t29_2" class="t s2_2">10.2603.0708</div>
<div id="t2a_2" class="t s2_2">50 Stk</div>
<div id="t2b_2" class="t s2_2">MEGU Schraubendichtung Stahl passiv.</div>
<div id="t2c_2" class="t s2_2">NBR Lippe 17,28 x 23,80 x 2,03 mm</div>
<div id="t2d_2" class="t s2_2">SCHRAUBENDICHTUNG ME</div>
<div id="t2e_2" class="t s2_2">U/Artikel-NR AP10.3605.0023 | NL </div>
<div id="t2f_2" class="t s2_2">10.3605.0023</div>
<div id="t2g_2" class="t s4_2">Hasler + Co AG, Zentrallager</div>
<div id="t2h_2" class="t s4_2">Rudolf-Diesel-Strasse 12 · 8404 Winterthur · </div>
<div id="t2i_2" class="t s4_2">Tel. 052 235 33 33</div>
<div id="t2j_2" class="t s4_2">info@hasler.ch · www.hasler.ch</div>

<!-- End text definitions -->


</div>
</body>
</html>

<!DOCTYPE html >
<html lang="en">
<head>
<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
<meta charset="utf-8" />
</head>

<body style="margin: 0;">

<div id="p3" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
	-webkit-transform: scale(0.25);
	-ms-transform: scale(0.25);
	transform: scale(0.25);
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_3{left:82px;bottom:1080px;}
#t2_3{left:527px;bottom:1080px;}
#t3_3{left:797px;bottom:1080px;}
#t4_3{left:855px;bottom:1080px;}
#t5_3{left:126px;bottom:1040px;word-spacing:3.8px;}
#t6_3{left:213px;bottom:1040px;}
#t7_3{left:668px;bottom:1040px;}
#t8_3{left:157px;bottom:999px;letter-spacing:-0.1px;word-spacing:2.2px;}
#t9_3{left:213px;bottom:999px;}
#ta_3{left:213px;bottom:983px;}
#tb_3{left:213px;bottom:968px;}
#tc_3{left:214px;bottom:952px;}
#td_3{left:667px;bottom:999px;}
#te_3{left:157px;bottom:931px;letter-spacing:-0.1px;word-spacing:2.2px;}
#tf_3{left:213px;bottom:931px;}
#tg_3{left:213px;bottom:915px;}
#th_3{left:213px;bottom:899px;}
#ti_3{left:214px;bottom:883px;}
#tj_3{left:667px;bottom:931px;}
#tk_3{left:157px;bottom:862px;letter-spacing:-0.1px;word-spacing:2.2px;}
#tl_3{left:213px;bottom:862px;}
#tm_3{left:213px;bottom:846px;}
#tn_3{left:213px;bottom:830px;}
#to_3{left:214px;bottom:815px;}
#tp_3{left:667px;bottom:862px;}
#tq_3{left:157px;bottom:793px;letter-spacing:-0.1px;word-spacing:2.2px;}
#tr_3{left:213px;bottom:793px;}
#ts_3{left:213px;bottom:778px;}
#tt_3{left:213px;bottom:762px;}
#tu_3{left:214px;bottom:746px;}
#tv_3{left:667px;bottom:793px;}
#tw_3{left:157px;bottom:725px;letter-spacing:-0.1px;word-spacing:2.2px;}
#tx_3{left:213px;bottom:725px;}
#ty_3{left:213px;bottom:709px;}
#tz_3{left:213px;bottom:693px;}
#t10_3{left:214px;bottom:677px;}
#t11_3{left:667px;bottom:725px;}
#t12_3{left:157px;bottom:656px;letter-spacing:-0.1px;word-spacing:2.2px;}
#t13_3{left:213px;bottom:656px;}
#t14_3{left:213px;bottom:641px;}
#t15_3{left:213px;bottom:625px;}
#t16_3{left:214px;bottom:609px;}
#t17_3{left:667px;bottom:656px;}
#t18_3{left:150px;bottom:588px;word-spacing:2.2px;}
#t19_3{left:213px;bottom:588px;}
#t1a_3{left:213px;bottom:572px;}
#t1b_3{left:213px;bottom:556px;}
#t1c_3{left:214px;bottom:540px;}
#t1d_3{left:667px;bottom:588px;}
#t1e_3{left:213px;bottom:502px;}
#t1f_3{left:213px;bottom:486px;}
#t1g_3{left:213px;bottom:454px;}
#t1h_3{left:213px;bottom:439px;}
#t1i_3{left:376px;bottom:51px;}
#t1j_3{left:280px;bottom:37px;}
#t1k_3{left:528px;bottom:37px;}
#t1l_3{left:369px;bottom:23px;}

.s1{
	FONT-SIZE: 61.1px;
	FONT-FAMILY: Arial-BoldMT_h;
	color: rgb(0,0,0);
}

.s2{
	FONT-SIZE: 55px;
	FONT-FAMILY: ArialMT_g;
	color: rgb(0,0,0);
}

.s3{
	FONT-SIZE: 48.9px;
	FONT-FAMILY: Arial-BoldMT_h;
	color: rgb(0,0,0);
}

.s4{
	FONT-SIZE: 48.9px;
	FONT-FAMILY: ArialMT_g;
	color: rgb(0,0,0);
}

</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts3" type="text/css" >

@font-face {
	font-family: Arial-BoldMT_h;
	src: url("fonts/Arial-BoldMT_h.woff") format("woff");
}

@font-face {
	font-family: ArialMT_g;
	src: url("fonts/ArialMT_g.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg3Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg3" style="-webkit-user-select: none;"><object width="909" height="1286" data="3/3.svg" type="image/svg+xml" id="pdf3" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div id="t1_3" class="t s1">Bestellung 10-590984/0</div>
<div id="t2_3" class="t s2">Winterthur, 15.11.2019</div>
<div id="t3_3" class="t s2">Seite:</div>
<div id="t4_3" class="t s2">3</div>
<div id="t5_3" class="t s3">Menge Eht</div>
<div id="t6_3" class="t s3">Bezeichnung</div>
<div id="t7_3" class="t s3">I/Artikel-NR</div>
<div id="t8_3" class="t s2">3 Stk</div>
<div id="t9_3" class="t s2">NORMATEC O-Ring NBR NT 70.11 OR 3575</div>
<div id="ta_3" class="t s2">ID 145,72 x 2,62 mm</div>
<div id="tb_3" class="t s2">O-RING NORMATEC® NBR</div>
<div id="tc_3" class="t s2">U/Artikel-NR AP11.2003.1608 | NL </div>
<div id="td_3" class="t s2">11.2003.1608</div>
<div id="te_3" class="t s2">2 Stk</div>
<div id="tf_3" class="t s2">NORMATEC O-Ring NBR NT 70.11 OR 3800</div>
<div id="tg_3" class="t s2">ID 202,87 x 2,62 mm</div>
<div id="th_3" class="t s2">O-RING NORMATEC® NBR</div>
<div id="ti_3" class="t s2">U/Artikel-NR AP11.2003.1626 | NL </div>
<div id="tj_3" class="t s2">11.2003.1626</div>
<div id="tk_3" class="t s2">2 Stk</div>
<div id="tl_3" class="t s2">NORMATEC O-Ring FKM NT 80.7/70 grün</div>
<div id="tm_3" class="t s2">ORM 0120-15 ID 12,00 x 1,50 mm</div>
<div id="tn_3" class="t s2">O-RING NORMATEC FKM</div>
<div id="to_3" class="t s2">U/Artikel-NR AP11.2017.0146 | NL </div>
<div id="tp_3" class="t s2">11.2017.0146</div>
<div id="tq_3" class="t s2">2 Stk</div>
<div id="tr_3" class="t s2">A+P Radial-Wellendichtring Form AS</div>
<div id="ts_3" class="t s2">60,00 x 80,00 x 8,00 mm, NBR</div>
<div id="tt_3" class="t s2">RADIAL-WELLENDICHTRI</div>
<div id="tu_3" class="t s2">U/Artikel-NR AP11.5002.1080 | NL </div>
<div id="tv_3" class="t s2">11.5002.1080</div>
<div id="tw_3" class="t s2">1 Stk</div>
<div id="tx_3" class="t s2">APSOvib Rundpuffer Form A mittel</div>
<div id="ty_3" class="t s2">D 75 mm: H 55 mm: G M12: L 37 mm</div>
<div id="tz_3" class="t s2">APSOVIB RUNDPUFFER F</div>
<div id="t10_3" class="t s2">U/Artikel-NR AP12.2033.0393 | NL </div>
<div id="t11_3" class="t s2">12.2033.0393</div>
<div id="t12_3" class="t s2">1 Stk</div>
<div id="t13_3" class="t s2">APSOvib Anschlagpuffer Form A mittel</div>
<div id="t14_3" class="t s2">D 75 mm: H 25 mm: G M12: L 37 mm</div>
<div id="t15_3" class="t s2">APSOVIB ANSCHLAGPUFF</div>
<div id="t16_3" class="t s2">U/Artikel-NR AP12.2036.0543 | NL </div>
<div id="t17_3" class="t s2">12.2036.0543</div>
<div id="t18_3" class="t s2">50 Stk</div>
<div id="t19_3" class="t s2">KAPSTO Vierkantstopfen GPN 270 schwarz</div>
<div id="t1a_3" class="t s2">270 R 60404</div>
<div id="t1b_3" class="t s2">VIERKANTSTOPFEN KAPS</div>
<div id="t1c_3" class="t s2">U/Artikel-NR AP13.0103.7070 | NL </div>
<div id="t1d_3" class="t s2">13.0103.7070</div>
<div id="t1e_3" class="t s2">Bitte senden Sie uns eine Auftragsbestätigung, per e-Mail an den Absender der Bestellung und vermerken</div>
<div id="t1f_3" class="t s2">Sie jeweils unsere Bestellnummer auf ihren Dokumenten. Vielen Dank.</div>
<div id="t1g_3" class="t s2">Es gelten unsere allgemeinen Geschäftsbedingungen. </div>
<div id="t1h_3" class="t s2">Diese sind auf www.hasler.ch/de/AEB einsehbar.</div>
<div id="t1i_3" class="t s4">Hasler + Co AG, Zentrallager</div>
<div id="t1j_3" class="t s4">Rudolf-Diesel-Strasse 12 · 8404 Winterthur · </div>
<div id="t1k_3" class="t s4">Tel. 052 235 33 33</div>
<div id="t1l_3" class="t s4">info@hasler.ch · www.hasler.ch</div>

<!-- End text definitions -->


</div>
</body>
</html>

      `,
  ABB: `
      <!DOCTYPE html >
      <html lang="en">
      <head>
      <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
      <meta charset="utf-8" />
      </head>
      
      <body style="margin: 0;">
      
      <div id="p1" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">
      
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css" >
      .t {
        -webkit-transform-origin: bottom left;
        -ms-transform-origin: bottom left;
        transform-origin: bottom left;
        -webkit-transform: scale(0.25);
        -ms-transform: scale(0.25);
        transform: scale(0.25);
        z-index: 2;
        position: absolute;
        white-space: pre;
        overflow: visible;
      }
      </style>
      <!-- End shared CSS values -->
      
      
      <!-- Begin inline CSS -->
      <style type="text/css" >
      
      #t1_1{left:69px;bottom:604px;}
      #t2_1{left:238px;bottom:604px;}
      #t3_1{left:69px;bottom:589px;}
      #t4_1{left:69px;bottom:575px;}
      #t5_1{left:238px;bottom:575px;word-spacing:-0.1px;}
      #t6_1{left:69px;bottom:560px;}
      #t7_1{left:238px;bottom:560px;}
      #t8_1{left:65px;bottom:531px;}
      #t9_1{left:65px;bottom:516px;}
      #ta_1{left:69px;bottom:501px;}
      #tb_1{left:65px;bottom:492px;letter-spacing:-0.1px;}
      #tc_1{left:65px;bottom:468px;}
      #td_1{left:130px;bottom:468px;}
      #te_1{left:195px;bottom:468px;}
      #tf_1{left:238px;bottom:468px;}
      #tg_1{left:628px;bottom:468px;}
      #th_1{left:464px;bottom:454px;}
      #ti_1{left:550px;bottom:454px;}
      #tj_1{left:765px;bottom:454px;}
      #tk_1{left:65px;bottom:444px;letter-spacing:-0.1px;}
      #tl_1{left:65px;bottom:412px;}
      #tm_1{left:163px;bottom:412px;}
      #tn_1{left:195px;bottom:412px;}
      #to_1{left:238px;bottom:412px;word-spacing:-0.2px;}
      #tp_1{left:628px;bottom:412px;}
      #tq_1{left:238px;bottom:382px;}
      #tr_1{left:433px;bottom:382px;}
      #ts_1{left:238px;bottom:353px;}
      #tt_1{left:450px;bottom:353px;}
      #tu_1{left:238px;bottom:309px;}
      #tv_1{left:456px;bottom:309px;}
      #tw_1{left:550px;bottom:309px;}
      #tx_1{left:651px;bottom:309px;}
      #ty_1{left:238px;bottom:294px;}
      #tz_1{left:781px;bottom:294px;}
      #t10_1{left:65px;bottom:250px;}
      #t11_1{left:163px;bottom:250px;}
      #t12_1{left:195px;bottom:250px;}
      #t13_1{left:238px;bottom:250px;word-spacing:-0.1px;}
      #t14_1{left:628px;bottom:250px;}
      #t15_1{left:238px;bottom:221px;}
      #t16_1{left:433px;bottom:221px;}
      #t17_1{left:238px;bottom:192px;}
      #t18_1{left:450px;bottom:192px;}
      #t19_1{left:242px;bottom:148px;}
      #t1a_1{left:238px;bottom:133px;}
      #t1b_1{left:357px;bottom:108px;}
      #t1c_1{left:117px;bottom:1066px;}
      #t1d_1{left:117px;bottom:1048px;}
      #t1e_1{left:215px;bottom:1048px;letter-spacing:-0.1px;word-spacing:0.1px;}
      #t1f_1{left:302px;bottom:1048px;}
      #t1g_1{left:117px;bottom:996px;}
      #t1h_1{left:117px;bottom:981px;}
      #t1i_1{left:117px;bottom:966px;}
      #t1j_1{left:117px;bottom:952px;}
      #t1k_1{left:117px;bottom:937px;}
      #t1l_1{left:490px;bottom:839px;}
      #t1m_1{left:489px;bottom:826px;letter-spacing:0.1px;word-spacing:-0.2px;}
      #t1n_1{left:485px;bottom:793px;}
      #t1o_1{left:485px;bottom:775px;}
      #t1p_1{left:485px;bottom:756px;}
      #t1q_1{left:485px;bottom:738px;}
      #t1r_1{left:485px;bottom:720px;}
      #t1s_1{left:74px;bottom:861px;}
      #t1t_1{left:74px;bottom:848px;}
      #t1u_1{left:143px;bottom:848px;}
      #t1v_1{left:74px;bottom:833px;}
      #t1w_1{left:143px;bottom:833px;}
      #t1x_1{left:74px;bottom:818px;}
      #t1y_1{left:143px;bottom:818px;}
      #t1z_1{left:74px;bottom:770px;}
      #t20_1{left:74px;bottom:756px;}
      #t21_1{left:74px;bottom:741px;}
      #t22_1{left:74px;bottom:727px;}
      #t23_1{left:74px;bottom:712px;}
      #t24_1{left:43px;bottom:61px;}
      #t25_1{left:347px;bottom:61px;}
      #t26_1{left:476px;bottom:61px;}
      #t27_1{left:606px;bottom:61px;}
      #t28_1{left:780px;bottom:61px;}
      #t29_1{left:43px;bottom:43px;}
      #t2a_1{left:347px;bottom:43px;}
      #t2b_1{left:476px;bottom:43px;}
      #t2c_1{left:606px;bottom:43px;word-spacing:0.2px;}
      #t2d_1{left:43px;bottom:80px;word-spacing:-4.7px;}
      #t2e_1{left:43px;bottom:79px;word-spacing:-4.7px;}
      #t2f_1{left:489px;bottom:1069px;}
      #t2g_1{left:658px;bottom:1069px;}
      #t2h_1{left:489px;bottom:1055px;}
      #t2i_1{left:658px;bottom:1055px;}
      #t2j_1{left:490px;bottom:1006px;}
      #t2k_1{left:489px;bottom:992px;}
      #t2l_1{left:572px;bottom:992px;}
      #t2m_1{left:489px;bottom:978px;}
      #t2n_1{left:572px;bottom:978px;}
      #t2o_1{left:489px;bottom:963px;}
      #t2p_1{left:572px;bottom:963px;}
      #t2q_1{left:489px;bottom:948px;}
      #t2r_1{left:572px;bottom:948px;}
      #t2s_1{left:489px;bottom:934px;}
      #t2t_1{left:572px;bottom:934px;}
      #t2u_1{left:489px;bottom:919px;}
      #t2v_1{left:572px;bottom:919px;}
      #t2w_1{left:489px;bottom:904px;}
      #t2x_1{left:572px;bottom:904px;}
      #t2y_1{left:489px;bottom:890px;}
      #t2z_1{left:572px;bottom:890px;}
      #t30_1{left:492px;bottom:1115px;}
      #t31_1{left:815px;bottom:1117px;word-spacing:0.1px;}
      #t32_1{left:69px;bottom:1117px;}
      #t33_1{left:69px;bottom:1103px;word-spacing:-0.1px;}
      #t34_1{left:69px;bottom:1141px;}
      
      .s1_1{
        FONT-SIZE: 55px;
        FONT-FAMILY: Helvetica_4;
        color: rgb(0,0,0);
      }
      
      .s2_1{
        FONT-SIZE: 61.1px;
        FONT-FAMILY: Helvetica_4;
        color: rgb(0,0,0);
      }
      
      .s3_1{
        FONT-SIZE: 55px;
        FONT-FAMILY: Helvetica-Bold_6;
        color: rgb(0,0,0);
      }
      
      .s4_1{
        FONT-SIZE: 85.6px;
        FONT-FAMILY: Helvetica_4;
        color: rgb(0,0,0);
      }
      
      .s5_1{
        FONT-SIZE: 48.9px;
        FONT-FAMILY: Helvetica_4;
        color: rgb(0,0,0);
      }
      
      .s6_1{
        FONT-SIZE: 73.3px;
        FONT-FAMILY: Helvetica-Bold_6;
        color: rgb(0,0,0);
      }
      
      .s7_1{
        FONT-SIZE: 51.9px;
        FONT-FAMILY: Helvetica-Oblique_8;
        color: rgb(0,0,0);
      }
      
      .s8_1{
        FONT-SIZE: 30.6px;
        FONT-FAMILY: Helvetica_4;
        color: rgb(0,0,0);
      }
      
      .s9_1{
        FONT-SIZE: 97.8px;
        FONT-FAMILY: Helvetica-Bold_6;
        color: rgb(0,0,0);
      }
      
      .sa_1{
        FONT-SIZE: 61.1px;
        FONT-FAMILY: Helvetica-Bold_6;
        color: rgb(0,0,0);
      }
      
      </style>
      <!-- End inline CSS -->
      
      <!-- Begin embedded font definitions -->
      <style id="fonts1" type="text/css" >
      
      @font-face {
        font-family: Helvetica-Bold_6;
        src: url("fonts/Helvetica-Bold_6.woff") format("woff");
      }
      
      @font-face {
        font-family: Helvetica-Oblique_8;
        src: url("fonts/Helvetica-Oblique_8.woff") format("woff");
      }
      
      @font-face {
        font-family: Helvetica_4;
        src: url("fonts/Helvetica_4.woff") format("woff");
      }
      
      </style>
      <!-- End embedded font definitions -->
      
      <!-- Begin page background -->
      <div id="pg1Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
      <div id="pg1" style="-webkit-user-select: none;"><object width="909" height="1286" data="1/1.svg" type="image/svg+xml" id="pdf1" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
      <!-- End page background -->
      
      
      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div id="t1_1" class="t s1_1">Terms of delivery </div>
      <div id="t2_1" class="t s1_1">DDP, CH-8610 Uster ab CHF 300.-</div>
      <div id="t3_1" class="t s1_1">(Incoterms 2010)</div>
      <div id="t4_1" class="t s1_1">Terms of payment</div>
      <div id="t5_1" class="t s1_1">innerhalb von 90 Tagen ohne Abzug</div>
      <div id="t6_1" class="t s1_1">Currency</div>
      <div id="t7_1" class="t s1_1">CHF</div>
      <div id="t8_1" class="t s1_1">We anticipate receiving your order confirmation.</div>
      <div id="t9_1" class="t s1_1">In case of further clarifications please contact our ABB contact person</div>
      <div id="ta_1" class="t s1_1">as above.</div>
      <div id="tb_1" class="t s2_1">___________________________________________________________________________________________</div>
      <div id="tc_1" class="t s3_1">Item</div>
      <div id="td_1" class="t s3_1">Quantity</div>
      <div id="te_1" class="t s3_1">Unit</div>
      <div id="tf_1" class="t s3_1">Description</div>
      <div id="tg_1" class="t s3_1">Mat No</div>
      <div id="th_1" class="t s3_1">Price</div>
      <div id="ti_1" class="t s3_1">Per unit</div>
      <div id="tj_1" class="t s3_1">Total net</div>
      <div id="tk_1" class="t s2_1">___________________________________________________________________________________________</div>
      <div id="tl_1" class="t s1_1">0010</div>
      <div id="tm_1" class="t s1_1">400</div>
      <div id="tn_1" class="t s1_1">PC</div>
      <div id="to_1" class="t s1_1">Bride,Edelstahl,Klemmber. 30-45,NW29</div>
      <div id="tp_1" class="t s1_1">06.5046.9045</div>
      <div id="tq_1" class="t s1_1">YOUR MATERIAL NUMBER</div>
      <div id="tr_1" class="t s1_1">FT - 06 5046 9045</div>
      <div id="ts_1" class="t s3_1">Delivery date</div>
      <div id="tt_1" class="t s3_1">21.03.2019</div>
      <div id="tu_1" class="t s1_1">Gross Price</div>
      <div id="tv_1" class="t s1_1">135,00</div>
      <div id="tw_1" class="t s1_1">100 PC</div>
      <div id="tx_1" class="t s1_1">540,00</div>
      <div id="ty_1" class="t s1_1">Total</div>
      <div id="tz_1" class="t s1_1">540,00</div>
      <div id="t10_1" class="t s1_1">0020</div>
      <div id="t11_1" class="t s1_1">500</div>
      <div id="t12_1" class="t s1_1">PC</div>
      <div id="t13_1" class="t s1_1">PMA Blindverschluss f.SPV,PA6,NW23</div>
      <div id="t14_1" class="t s1_1">SPV2-23</div>
      <div id="t15_1" class="t s1_1">YOUR MATERIAL NUMBER</div>
      <div id="t16_1" class="t s1_1">KT - 80 0019 3607</div>
      <div id="t17_1" class="t s3_1">Delivery date</div>
      <div id="t18_1" class="t s3_1">21.03.2019</div>
      <div id="t19_1" class="t s1_1">Weich-PE. natur, gestanzt</div>
      <div id="t1a_1" class="t s1_1">DRM 30 x 2 mm</div>
      <div id="t1b_1" class="t s4_1">ABB Switzerland Ltd</div>
      <div id="t1c_1" class="t s5_1">ABB Schweiz AG</div>
      <div id="t1d_1" class="t s5_1">Aathalstrasse 90 </div>
      <div id="t1e_1" class="t s5_1">CH 8610 Uster </div>
      <div id="t1f_1" class="t s5_1">Switzerland</div>
      <div id="t1g_1" class="t s2_1">500016</div>
      <div id="t1h_1" class="t s2_1">Angst + Pfister AG</div>
      <div id="t1i_1" class="t s2_1">Thurgauerstrasse 66 </div>
      <div id="t1j_1" class="t s2_1">CH-8052 Zürich</div>
      <div id="t1k_1" class="t s2_1">Schweiz</div>
      <div id="t1l_1" class="t s6_1">Billing address</div>
      <div id="t1m_1" class="t s7_1">Please submit invoice stating order number and item number</div>
      <div id="t1n_1" class="t s5_1">ABB Switzerland Ltd</div>
      <div id="t1o_1" class="t s5_1">PMA Cable protection</div>
      <div id="t1p_1" class="t s5_1">SAS-16</div>
      <div id="t1q_1" class="t s5_1">P.O. Box 1946</div>
      <div id="t1r_1" class="t s5_1">CH-5400 Baden</div>
      <div id="t1s_1" class="t s6_1">Supplier contact data</div>
      <div id="t1t_1" class="t s1_1">Name</div>
      <div id="t1u_1" class="t s1_1">Angst + Pfister AG</div>
      <div id="t1v_1" class="t s1_1">Phone</div>
      <div id="t1w_1" class="t s1_1">044 306 61 11 </div>
      <div id="t1x_1" class="t s1_1">Fax</div>
      <div id="t1y_1" class="t s1_1">044 302 18 71</div>
      <div id="t1z_1" class="t s6_1">Delivery address</div>
      <div id="t20_1" class="t s1_1">ABB Schweiz AG</div>
      <div id="t21_1" class="t s1_1">PMA Kabelschutz</div>
      <div id="t22_1" class="t s1_1">Aathalstrasse 90 </div>
      <div id="t23_1" class="t s1_1">8610 Uster Schweiz</div>
      <div id="t24_1" class="t s5_1">Address</div>
      <div id="t25_1" class="t s5_1">Telephone</div>
      <div id="t26_1" class="t s5_1">Fax</div>
      <div id="t27_1" class="t s5_1">Internet</div>
      <div id="t28_1" class="t s5_1">MWST</div>
      <div id="t29_1" class="t s5_1">Aathalstrasse 90, CH-8610 Uster</div>
      <div id="t2a_1" class="t s5_1">+41 58 585 00 11</div>
      <div id="t2b_1" class="t s5_1">+41 58 585 00 22</div>
      <div id="t2c_1" class="t s5_1">www.abb.com|CHE-116.265.869 MWST</div>
      <div id="t2d_1" class="t s8_1">_______________________________ _______________________________ _______________________________ _______________________________ _______________________________ _______________________________ ____</div>
      <div id="t2e_1" class="t s8_1">_______________________________ _</div>
      <div id="t2f_1" class="t s1_1">Order date</div>
      <div id="t2g_1" class="t s1_1">01.03.2019</div>
      <div id="t2h_1" class="t s1_1">Amendment date</div>
      <div id="t2i_1" class="t s1_1">01.03.2019</div>
      <div id="t2j_1" class="t s6_1">ABB contact data</div>
      <div id="t2k_1" class="t s1_1">Name</div>
      <div id="t2l_1" class="t s1_1">P. Bindschedler</div>
      <div id="t2m_1" class="t s1_1">Department</div>
      <div id="t2n_1" class="t s1_1">Werk ABB PMA Uster/Einkauf</div>
      <div id="t2o_1" class="t s1_1">Pur Group</div>
      <div id="t2p_1" class="t s1_1">PM8</div>
      <div id="t2q_1" class="t s1_1">Street</div>
      <div id="t2r_1" class="t s1_1">Aathalstrasse 90</div>
      <div id="t2s_1" class="t s1_1">Town</div>
      <div id="t2t_1" class="t s1_1">8610 Uster</div>
      <div id="t2u_1" class="t s1_1">Phone</div>
      <div id="t2v_1" class="t s1_1">+41 58 585 0048</div>
      <div id="t2w_1" class="t s1_1">Fax</div>
      <div id="t2x_1" class="t s1_1">+41 58 585 0022</div>
      <div id="t2y_1" class="t s1_1">Email</div>
      <div id="t2z_1" class="t s1_1">patrick.bindschedler@ch.abb.com</div>
      <div id="t30_1" class="t s9_1">Purchase Order</div>
      <div id="t31_1" class="t sa_1">1 /  3 </div>
      <div id="t32_1" class="t s6_1">Our ref 4500556739</div>
      <div id="t33_1" class="t s2_1">Bestellung ist bereits freigegeben</div>
      <div id="t34_1" class="t s9_1">Original</div>
      
      <!-- End text definitions -->
      
      
      </div>
      </body>
      </html>

<!DOCTYPE html >
<html lang="en">
<head>
<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
<meta charset="utf-8" />
</head>

<body style="margin: 0;">

<div id="p2" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
	-webkit-transform: scale(0.25);
	-ms-transform: scale(0.25);
	transform: scale(0.25);
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_2{left:74px;bottom:1074px;letter-spacing:-0.1px;}
#t2_2{left:74px;bottom:1051px;}
#t3_2{left:139px;bottom:1051px;}
#t4_2{left:204px;bottom:1051px;}
#t5_2{left:247px;bottom:1051px;}
#t6_2{left:637px;bottom:1051px;}
#t7_2{left:473px;bottom:1036px;}
#t8_2{left:559px;bottom:1036px;}
#t9_2{left:774px;bottom:1036px;}
#ta_2{left:74px;bottom:1027px;letter-spacing:-0.1px;}
#tb_2{left:247px;bottom:798px;}
#tc_2{left:472px;bottom:798px;}
#td_2{left:559px;bottom:798px;}
#te_2{left:667px;bottom:798px;}
#tf_2{left:247px;bottom:783px;}
#tg_2{left:797px;bottom:783px;}
#th_2{left:74px;bottom:739px;}
#ti_2{left:160px;bottom:739px;}
#tj_2{left:204px;bottom:739px;}
#tk_2{left:247px;bottom:739px;}
#tl_2{left:637px;bottom:739px;}
#tm_2{left:247px;bottom:710px;}
#tn_2{left:442px;bottom:710px;}
#to_2{left:247px;bottom:681px;}
#tp_2{left:459px;bottom:681px;}
#tq_2{left:247px;bottom:637px;}
#tr_2{left:465px;bottom:637px;}
#ts_2{left:559px;bottom:637px;}
#tt_2{left:648px;bottom:637px;}
#tu_2{left:247px;bottom:622px;}
#tv_2{left:778px;bottom:622px;}
#tw_2{left:74px;bottom:578px;}
#tx_2{left:153px;bottom:578px;word-spacing:19.4px;}
#ty_2{left:247px;bottom:578px;}
#tz_2{left:637px;bottom:578px;}
#t10_2{left:247px;bottom:549px;}
#t11_2{left:442px;bottom:549px;}
#t12_2{left:247px;bottom:519px;}
#t13_2{left:459px;bottom:519px;}
#t14_2{left:251px;bottom:475px;}
#t15_2{left:247px;bottom:241px;}
#t16_2{left:480px;bottom:241px;}
#t17_2{left:559px;bottom:241px;}
#t18_2{left:660px;bottom:241px;}
#t19_2{left:247px;bottom:226px;}
#t1a_2{left:789px;bottom:226px;}
#t1b_2{left:74px;bottom:182px;}
#t1c_2{left:153px;bottom:182px;}
#t1d_2{left:204px;bottom:182px;}
#t1e_2{left:247px;bottom:182px;}
#t1f_2{left:637px;bottom:182px;}
#t1g_2{left:247px;bottom:153px;}
#t1h_2{left:442px;bottom:153px;}
#t1i_2{left:492px;bottom:1115px;}
#t1j_2{left:815px;bottom:1117px;word-spacing:0.1px;}
#t1k_2{left:74px;bottom:1117px;}
#t1l_2{left:69px;bottom:1141px;}

.s1_2{
	FONT-SIZE: 61.1px;
	FONT-FAMILY: Helvetica_4;
	color: rgb(0,0,0);
}

.s2_2{
	FONT-SIZE: 55px;
	FONT-FAMILY: Helvetica-Bold_6;
	color: rgb(0,0,0);
}

.s3_2{
	FONT-SIZE: 55px;
	FONT-FAMILY: Helvetica_4;
	color: rgb(0,0,0);
}

.s4_2{
	FONT-SIZE: 97.8px;
	FONT-FAMILY: Helvetica-Bold_6;
	color: rgb(0,0,0);
}

.s5_2{
	FONT-SIZE: 61.1px;
	FONT-FAMILY: Helvetica-Bold_6;
	color: rgb(0,0,0);
}

.s6_2{
	FONT-SIZE: 73.3px;
	FONT-FAMILY: Helvetica-Bold_6;
	color: rgb(0,0,0);
}

</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts2" type="text/css" >

@font-face {
	font-family: Helvetica-Bold_6;
	src: url("fonts/Helvetica-Bold_6.woff") format("woff");
}

@font-face {
	font-family: Helvetica_4;
	src: url("fonts/Helvetica_4.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg2Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg2" style="-webkit-user-select: none;"><object width="909" height="1286" data="2/2.svg" type="image/svg+xml" id="pdf2" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div id="t1_2" class="t s1_2">___________________________________________________________________________________________</div>
<div id="t2_2" class="t s2_2">Item</div>
<div id="t3_2" class="t s2_2">Quantity</div>
<div id="t4_2" class="t s2_2">Unit</div>
<div id="t5_2" class="t s2_2">Description</div>
<div id="t6_2" class="t s2_2">Mat No</div>
<div id="t7_2" class="t s2_2">Price</div>
<div id="t8_2" class="t s2_2">Per unit</div>
<div id="t9_2" class="t s2_2">Total net</div>
<div id="ta_2" class="t s1_2">___________________________________________________________________________________________</div>
<div id="tb_2" class="t s3_2">Gross Price</div>
<div id="tc_2" class="t s3_2">11,40</div>
<div id="td_2" class="t s3_2">100 PC</div>
<div id="te_2" class="t s3_2">57,00</div>
<div id="tf_2" class="t s3_2">Total</div>
<div id="tg_2" class="t s3_2">57,00</div>
<div id="th_2" class="t s3_2">0030</div>
<div id="ti_2" class="t s3_2">6.000</div>
<div id="tj_2" class="t s3_2">PC</div>
<div id="tk_2" class="t s3_2">PMA GewindeFlachdichtung,NBR,M50,blau</div>
<div id="tl_2" class="t s3_2">SVN4-M50.10</div>
<div id="tm_2" class="t s3_2">YOUR MATERIAL NUMBER</div>
<div id="tn_2" class="t s3_2">8000608768</div>
<div id="to_2" class="t s2_2">Delivery date</div>
<div id="tp_2" class="t s2_2">21.03.2019</div>
<div id="tq_2" class="t s3_2">Gross Price</div>
<div id="tr_2" class="t s3_2">298,19</div>
<div id="ts_2" class="t s3_2">1.000 PC</div>
<div id="tt_2" class="t s3_2">1.789,14</div>
<div id="tu_2" class="t s3_2">Total</div>
<div id="tv_2" class="t s3_2">1.789,14</div>
<div id="tw_2" class="t s3_2">0040</div>
<div id="tx_2" class="t s3_2">10.000 PC</div>
<div id="ty_2" class="t s3_2">O-Ring,NBR,sw</div>
<div id="tz_2" class="t s3_2">OR51.00X2.00</div>
<div id="t10_2" class="t s3_2">YOUR MATERIAL NUMBER</div>
<div id="t11_2" class="t s3_2">8000579970</div>
<div id="t12_2" class="t s2_2">Delivery date</div>
<div id="t13_2" class="t s2_2">28.03.2019</div>
<div id="t14_2" class="t s3_2">IN BEUTEL À 500 STK. ABGEPACKT.</div>
<div id="t15_2" class="t s3_2">Gross Price</div>
<div id="t16_2" class="t s3_2">2,78</div>
<div id="t17_2" class="t s3_2">100 PC</div>
<div id="t18_2" class="t s3_2">278,00</div>
<div id="t19_2" class="t s3_2">Total</div>
<div id="t1a_2" class="t s3_2">278,00</div>
<div id="t1b_2" class="t s3_2">0050</div>
<div id="t1c_2" class="t s3_2">30.000</div>
<div id="t1d_2" class="t s3_2">PC</div>
<div id="t1e_2" class="t s3_2">PMA GewindeFlachdichtung,NBR,M16,blau</div>
<div id="t1f_2" class="t s3_2">SVN4-M16.50</div>
<div id="t1g_2" class="t s3_2">YOUR MATERIAL NUMBER</div>
<div id="t1h_2" class="t s3_2">8000608776</div>
<div id="t1i_2" class="t s4_2">Purchase Order</div>
<div id="t1j_2" class="t s5_2">2 /  3 </div>
<div id="t1k_2" class="t s6_2">Our ref 4500556739</div>
<div id="t1l_2" class="t s4_2">Original</div>

<!-- End text definitions -->


</div>
</body>
</html>

<!DOCTYPE html >
<html lang="en">
<head>
<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
<meta charset="utf-8" />
</head>

<body style="margin: 0;">

<div id="p3" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
	-webkit-transform: scale(0.25);
	-ms-transform: scale(0.25);
	transform: scale(0.25);
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_3{left:74px;bottom:1074px;letter-spacing:-0.1px;}
#t2_3{left:74px;bottom:1051px;}
#t3_3{left:139px;bottom:1051px;}
#t4_3{left:204px;bottom:1051px;}
#t5_3{left:247px;bottom:1051px;}
#t6_3{left:637px;bottom:1051px;}
#t7_3{left:473px;bottom:1036px;}
#t8_3{left:559px;bottom:1036px;}
#t9_3{left:774px;bottom:1036px;}
#ta_3{left:74px;bottom:1027px;letter-spacing:-0.1px;}
#tb_3{left:247px;bottom:1003px;}
#tc_3{left:459px;bottom:1003px;}
#td_3{left:247px;bottom:959px;}
#te_3{left:472px;bottom:959px;}
#tf_3{left:559px;bottom:959px;}
#tg_3{left:648px;bottom:959px;}
#th_3{left:247px;bottom:945px;}
#ti_3{left:778px;bottom:945px;}
#tj_3{left:74px;bottom:935px;letter-spacing:-0.1px;}
#tk_3{left:247px;bottom:897px;}
#tl_3{left:774px;bottom:897px;}
#tm_3{left:74px;bottom:809px;letter-spacing:0.1px;word-spacing:-0.8px;}
#tn_3{left:74px;bottom:794px;letter-spacing:0.1px;word-spacing:-0.4px;}
#to_3{left:74px;bottom:765px;letter-spacing:0.1px;word-spacing:-0.4px;}
#tp_3{left:74px;bottom:750px;word-spacing:-0.2px;}
#tq_3{left:74px;bottom:736px;letter-spacing:0.1px;word-spacing:-0.6px;}
#tr_3{left:74px;bottom:721px;letter-spacing:0.1px;word-spacing:-0.4px;}
#ts_3{left:74px;bottom:692px;word-spacing:-0.4px;}
#tt_3{left:74px;bottom:677px;letter-spacing:0.1px;word-spacing:-0.5px;}
#tu_3{left:74px;bottom:662px;letter-spacing:0.1px;word-spacing:-0.7px;}
#tv_3{left:74px;bottom:648px;letter-spacing:0.1px;word-spacing:-0.4px;}
#tw_3{left:74px;bottom:604px;}
#tx_3{left:463px;bottom:604px;}
#ty_3{left:492px;bottom:1115px;}
#tz_3{left:815px;bottom:1117px;word-spacing:0.1px;}
#t10_3{left:74px;bottom:1117px;}
#t11_3{left:69px;bottom:1141px;}

.s1{
	FONT-SIZE: 61.1px;
	FONT-FAMILY: Helvetica_4;
	color: rgb(0,0,0);
}

.s2{
	FONT-SIZE: 55px;
	FONT-FAMILY: Helvetica-Bold_6;
	color: rgb(0,0,0);
}

.s3{
	FONT-SIZE: 55px;
	FONT-FAMILY: Helvetica_4;
	color: rgb(0,0,0);
}

.s4{
	FONT-SIZE: 55px;
	FONT-FAMILY: Helvetica-Oblique_8;
	color: rgb(0,0,0);
}

.s5{
	FONT-SIZE: 97.8px;
	FONT-FAMILY: Helvetica-Bold_6;
	color: rgb(0,0,0);
}

.s6{
	FONT-SIZE: 61.1px;
	FONT-FAMILY: Helvetica-Bold_6;
	color: rgb(0,0,0);
}

.s7{
	FONT-SIZE: 73.3px;
	FONT-FAMILY: Helvetica-Bold_6;
	color: rgb(0,0,0);
}

</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts3" type="text/css" >

@font-face {
	font-family: Helvetica-Bold_6;
	src: url("fonts/Helvetica-Bold_6.woff") format("woff");
}

@font-face {
	font-family: Helvetica-Oblique_8;
	src: url("fonts/Helvetica-Oblique_8.woff") format("woff");
}

@font-face {
	font-family: Helvetica_4;
	src: url("fonts/Helvetica_4.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg3Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg3" style="-webkit-user-select: none;"><object width="909" height="1286" data="3/3.svg" type="image/svg+xml" id="pdf3" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div id="t1_3" class="t s1">___________________________________________________________________________________________</div>
<div id="t2_3" class="t s2">Item</div>
<div id="t3_3" class="t s2">Quantity</div>
<div id="t4_3" class="t s2">Unit</div>
<div id="t5_3" class="t s2">Description</div>
<div id="t6_3" class="t s2">Mat No</div>
<div id="t7_3" class="t s2">Price</div>
<div id="t8_3" class="t s2">Per unit</div>
<div id="t9_3" class="t s2">Total net</div>
<div id="ta_3" class="t s1">___________________________________________________________________________________________</div>
<div id="tb_3" class="t s2">Delivery date</div>
<div id="tc_3" class="t s2">28.03.2019</div>
<div id="td_3" class="t s3">Gross Price</div>
<div id="te_3" class="t s3">42,07</div>
<div id="tf_3" class="t s3">1.000 PC</div>
<div id="tg_3" class="t s3">1.262,10</div>
<div id="th_3" class="t s3">Total</div>
<div id="ti_3" class="t s3">1.262,10</div>
<div id="tj_3" class="t s1">___________________________________________________________________________________________</div>
<div id="tk_3" class="t s2">Total net value excluding value added tax CHF</div>
<div id="tl_3" class="t s2">3.926,25 </div>
<div id="tm_3" class="t s4">Purchase order, order acknowledgement and invoice must agree. Variances will result in delayed payment. The documents</div>
<div id="tn_3" class="t s4">cannot be processed if the order number is not given.</div>
<div id="to_3" class="t s4">The customs tariff numbers of the country of consignment must be indicated for all goods. For controlled goods, the national</div>
<div id="tp_3" class="t s4">export control number must be indicated and, if the goods are subject to U.S. export regulations, the ECCN or ITAR</div>
<div id="tq_3" class="t s4">classifications. Proofs of preferential origin as well as conformity declarations and marks of the country of consignment or</div>
<div id="tr_3" class="t s4">destination are to be submitted without being requested; certificates of origin upon request.</div>
<div id="ts_3" class="t s4">Unless stated differently, this Purchase Order (PO) is made pursuant to the ABB General Terms and Conditions (ABB GTC) for</div>
<div id="tt_3" class="t s4">the purchase of Goods (in case of delivery of Goods) and/or Services (in case of delivery of Services). These ABB GTC,made</div>
<div id="tu_3" class="t s4">available under www.abb.com "Supplying to ABB" "ABB Conditions of Purchase",are hereby incorporated into the contractual</div>
<div id="tv_3" class="t s4">relationship between ABB as Customer and Supplier. No other general terms and conditions shall apply.</div>
<div id="tw_3" class="t s3">ABB Switzerland Ltd</div>
<div id="tx_3" class="t s3">Forms without signature are legally valid</div>
<div id="ty_3" class="t s5">Purchase Order</div>
<div id="tz_3" class="t s6">3 /  3 </div>
<div id="t10_3" class="t s7">Our ref 4500556739</div>
<div id="t11_3" class="t s5">Original</div>

<!-- End text definitions -->


</div>
</body>
</html>

      `,
  Hoval: `
      <!DOCTYPE html >
      <html lang="en">
      <head>
      <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
      <meta charset="utf-8" />
      </head>
      
      <body style="margin: 0;">
      
      <div id="p1" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">
      
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css" >
      .t {
        -webkit-transform-origin: bottom left;
        -ms-transform-origin: bottom left;
        transform-origin: bottom left;
        -webkit-transform: scale(0.25);
        -ms-transform: scale(0.25);
        transform: scale(0.25);
        z-index: 2;
        position: absolute;
        white-space: pre;
        overflow: visible;
      }
      </style>
      <!-- End shared CSS values -->
      
      
      <!-- Begin inline CSS -->
      <style type="text/css" >
      
      #t1_1{left:74px;bottom:714px;letter-spacing:2.7px;}
      #t2_1{left:294px;bottom:714px;}
      #t3_1{left:74px;bottom:696px;letter-spacing:2.2px;}
      #t4_1{left:294px;bottom:696px;letter-spacing:1.8px;word-spacing:-1.8px;}
      #t5_1{left:448px;bottom:696px;letter-spacing:0.5px;word-spacing:-0.3px;}
      #t6_1{left:74px;bottom:678px;letter-spacing:2.7px;}
      #t7_1{left:294px;bottom:678px;letter-spacing:-0.1px;word-spacing:0.1px;}
      #t8_1{left:74px;bottom:659px;letter-spacing:2.7px;}
      #t9_1{left:294px;bottom:659px;}
      #ta_1{left:74px;bottom:641px;letter-spacing:2.4px;}
      #tb_1{left:294px;bottom:641px;letter-spacing:-0.1px;}
      #tc_1{left:74px;bottom:604px;word-spacing:0.2px;}
      #td_1{left:74px;bottom:595px;}
      #te_1{left:74px;bottom:568px;letter-spacing:-0.1px;}
      #tf_1{left:160px;bottom:568px;}
      #tg_1{left:406px;bottom:568px;}
      #th_1{left:507px;bottom:568px;letter-spacing:-0.1px;}
      #ti_1{left:640px;bottom:568px;}
      #tj_1{left:788px;bottom:568px;}
      #tk_1{left:160px;bottom:549px;letter-spacing:-0.1px;}
      #tl_1{left:74px;bottom:540px;}
      #tm_1{left:74px;bottom:494px;letter-spacing:-0.1px;}
      #tn_1{left:160px;bottom:494px;letter-spacing:-0.1px;}
      #to_1{left:481px;bottom:494px;word-spacing:19.4px;}
      #tp_1{left:659px;bottom:494px;}
      #tq_1{left:815px;bottom:494px;}
      #tr_1{left:160px;bottom:476px;word-spacing:0.2px;}
      #ts_1{left:160px;bottom:458px;}
      #tt_1{left:160px;bottom:439px;}
      #tu_1{left:160px;bottom:421px;}
      #tv_1{left:74px;bottom:384px;letter-spacing:-0.1px;}
      #tw_1{left:160px;bottom:384px;letter-spacing:-0.1px;}
      #tx_1{left:481px;bottom:384px;word-spacing:19.4px;}
      #ty_1{left:650px;bottom:384px;}
      #tz_1{left:806px;bottom:384px;}
      #t10_1{left:160px;bottom:366px;word-spacing:0.2px;}
      #t11_1{left:160px;bottom:348px;}
      #t12_1{left:363px;bottom:348px;letter-spacing:-0.4px;word-spacing:0.5px;}
      #t13_1{left:160px;bottom:311px;word-spacing:0.1px;}
      #t14_1{left:160px;bottom:293px;word-spacing:0.1px;}
      #t15_1{left:160px;bottom:256px;letter-spacing:0.1px;word-spacing:-0.1px;}
      #t16_1{left:160px;bottom:238px;letter-spacing:0.1px;word-spacing:-0.1px;}
      #t17_1{left:160px;bottom:219px;word-spacing:0.2px;}
      #t18_1{left:160px;bottom:183px;}
      #t19_1{left:160px;bottom:164px;}
      #t1a_1{left:160px;bottom:146px;}
      #t1b_1{left:160px;bottom:128px;}
      #t1c_1{left:160px;bottom:109px;}
      #t1d_1{left:160px;bottom:91px;letter-spacing:-0.1px;word-spacing:0.1px;}
      #t1e_1{left:74px;bottom:1045px;}
      #t1f_1{left:214px;bottom:1045px;}
      #t1g_1{left:74px;bottom:1008px;}
      #t1h_1{left:74px;bottom:989px;}
      #t1i_1{left:74px;bottom:971px;}
      #t1j_1{left:74px;bottom:953px;word-spacing:0.1px;}
      #t1k_1{left:74px;bottom:927px;}
      #t1l_1{left:112px;bottom:927px;letter-spacing:-0.1px;word-spacing:0.1px;}
      #t1m_1{left:74px;bottom:861px;letter-spacing:2.8px;}
      #t1n_1{left:74px;bottom:843px;}
      #t1o_1{left:74px;bottom:824px;letter-spacing:-0.1px;word-spacing:0.1px;}
      #t1p_1{left:74px;bottom:806px;}
      #t1q_1{left:74px;bottom:788px;letter-spacing:-0.1px;word-spacing:0.1px;}
      #t1r_1{left:74px;bottom:81px;}
      #t1s_1{left:74px;bottom:54px;letter-spacing:2.5px;word-spacing:-2.4px;}
      #t1t_1{left:74px;bottom:1232px;letter-spacing:-0.1px;word-spacing:0.1px;}
      #t1u_1{left:74px;bottom:1214px;}
      #t1v_1{left:74px;bottom:1196px;}
      #t1w_1{left:218px;bottom:1196px;letter-spacing:-0.1px;word-spacing:0.3px;}
      #t1x_1{left:74px;bottom:1177px;}
      #t1y_1{left:462px;bottom:1061px;letter-spacing:4.2px;}
      #t1z_1{left:462px;bottom:1039px;letter-spacing:2.3px;}
      #t20_1{left:462px;bottom:1021px;word-spacing:0.1px;}
      #t21_1{left:462px;bottom:997px;letter-spacing:2.3px;word-spacing:-2.3px;}
      #t22_1{left:462px;bottom:978px;letter-spacing:-0.1px;}
      #t23_1{left:462px;bottom:955px;letter-spacing:2.2px;word-spacing:-2.1px;}
      #t24_1{left:462px;bottom:936px;}
      #t25_1{left:627px;bottom:936px;letter-spacing:-0.1px;word-spacing:0.1px;}
      #t26_1{left:712px;bottom:936px;letter-spacing:-0.1px;}
      #t27_1{left:462px;bottom:918px;}
      #t28_1{left:627px;bottom:918px;}
      #t29_1{left:722px;bottom:918px;letter-spacing:-0.1px;}
      #t2a_1{left:462px;bottom:894px;letter-spacing:1.3px;}
      #t2b_1{left:627px;bottom:894px;letter-spacing:-0.1px;}
      #t2c_1{left:462px;bottom:870px;letter-spacing:2.2px;word-spacing:-2.1px;}
      #t2d_1{left:462px;bottom:852px;}
      #t2e_1{left:462px;bottom:1118px;word-spacing:0.1px;}
      #t2f_1{left:462px;bottom:1100px;}
      #t2g_1{left:814px;bottom:1118px;letter-spacing:1.7px;}
      #t2h_1{left:814px;bottom:1100px;}
      #t2i_1{left:841px;bottom:1100px;}
      
      .s1_1{
        FONT-SIZE: 61.1px;
        FONT-FAMILY: Helvetica-BoldOblique_4;
        color: rgb(0,0,0);
      }
      
      .s2_1{
        FONT-SIZE: 61.1px;
        FONT-FAMILY: Helvetica_6;
        color: rgb(0,0,0);
      }
      
      .s3_1{
        FONT-SIZE: 73.3px;
        FONT-FAMILY: Courier;
        color: rgb(0,0,0);
      }
      
      .s4_1{
        FONT-SIZE: 61.1px;
        FONT-FAMILY: Helvetica-Oblique_9;
        color: rgb(0,0,0);
      }
      
      .s5_1{
        FONT-SIZE: 48.9px;
        FONT-FAMILY: Helvetica_6;
        color: rgb(0,0,0);
      }
      
      .s6_1{
        FONT-SIZE: 97.8px;
        FONT-FAMILY: Helvetica-BoldOblique_4;
        color: rgb(0,0,0);
      }
      
      </style>
      <!-- End inline CSS -->
      
      <!-- Begin embedded font definitions -->
      <style id="fonts1" type="text/css" >
      
      @font-face {
        font-family: Helvetica-BoldOblique_4;
        src: url("fonts/Helvetica-BoldOblique_4.woff") format("woff");
      }
      
      @font-face {
        font-family: Helvetica-Oblique_9;
        src: url("fonts/Helvetica-Oblique_9.woff") format("woff");
      }
      
      @font-face {
        font-family: Helvetica_6;
        src: url("fonts/Helvetica_6.woff") format("woff");
      }
      
      </style>
      <!-- End embedded font definitions -->
      
      <!-- Begin page background -->
      <div id="pg1Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
      <div id="pg1" style="-webkit-user-select: none;"><object width="909" height="1286" data="1/1.svg" type="image/svg+xml" id="pdf1" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
      <!-- End page background -->
      
      
      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div id="t1_1" class="t s1_1">Anliefertermin:</div>
      <div id="t2_1" class="t s2_1">02.04.2019</div>
      <div id="t3_1" class="t s1_1">Anlieferzeiten:</div>
      <div id="t4_1" class="t s1_1">Montag - Freitag</div>
      <div id="t5_1" class="t s1_1">07.00 - 12.00 Uhr &amp; 13.00 - 15.00 Uhr</div>
      <div id="t6_1" class="t s1_1">Lieferbedingung:</div>
      <div id="t7_1" class="t s2_1">DAP Vaduz</div>
      <div id="t8_1" class="t s1_1">Zahlungsbedingung:</div>
      <div id="t9_1" class="t s2_1">30 Tage netto</div>
      <div id="ta_1" class="t s1_1">Währung:</div>
      <div id="tb_1" class="t s2_1">EUR</div>
      <div id="tc_1" class="t s2_1">Für die folgenden Positionen erwarten wir innerhalb von 3 Tagen Ihre Auftragsbestätigung !</div>
      <div id="td_1" class="t s3_1">_______________________________________________________________________</div>
      <div id="te_1" class="t s4_1">Position</div>
      <div id="tf_1" class="t s4_1">Material</div>
      <div id="tg_1" class="t s4_1">Bestellmenge</div>
      <div id="th_1" class="t s4_1">Einheit</div>
      <div id="ti_1" class="t s4_1">Preis pro</div>
      <div id="tj_1" class="t s4_1">Nettowert</div>
      <div id="tk_1" class="t s4_1">Bezeichnung</div>
      <div id="tl_1" class="t s3_1">_______________________________________________________________________</div>
      <div id="tm_1" class="t s2_1">00010</div>
      <div id="tn_1" class="t s2_1">2025926 </div>
      <div id="to_1" class="t s2_1">15 Stück</div>
      <div id="tp_1" class="t s2_1">1,30/1</div>
      <div id="tq_1" class="t s2_1">19,50</div>
      <div id="tr_1" class="t s2_1">Schlauchklemme FIT-Topinox D20-32x12</div>
      <div id="ts_1" class="t s2_1">W4 </div>
      <div id="tt_1" class="t s2_1">nach Zeichnungs-Nr.: HN 21614 / Index-Nr.:01</div>
      <div id="tu_1" class="t s2_1">Ihre Materialnummer 0650469032</div>
      <div id="tv_1" class="t s2_1">00020</div>
      <div id="tw_1" class="t s2_1">5020998 </div>
      <div id="tx_1" class="t s2_1">25 Stück</div>
      <div id="ty_1" class="t s2_1">25,87/1</div>
      <div id="tz_1" class="t s2_1">646,75</div>
      <div id="t10_1" class="t s2_1">Modul-Dichtung I (Geteilte Version)</div>
      <div id="t11_1" class="t s2_1">Chlorsulfonyl-PE-Kautschuk </div>
      <div id="t12_1" class="t s2_1">CSM 65°SHORE</div>
      <div id="t13_1" class="t s2_1">nach Zeichnungs-Nr.: 5020998-4 / Index-Nr.:00</div>
      <div id="t14_1" class="t s2_1">Ihre Materialnummer DT-30 2005 024</div>
      <div id="t15_1" class="t s2_1">CSM 65 ±5 Sh.A Fertigteil</div>
      <div id="t16_1" class="t s2_1">D 5 ±0,4 mm, schwarz</div>
      <div id="t17_1" class="t s2_1">3020050024 F2:910066-4/150/58/5mm</div>
      <div id="t18_1" class="t s2_1">Form: Dichtung rund</div>
      <div id="t19_1" class="t s2_1">Dicke in mm: 5</div>
      <div id="t1a_1" class="t s2_1">Aussen-Durchmesser in mm: 150</div>
      <div id="t1b_1" class="t s2_1">Innen-Durchmesser in mm: 58</div>
      <div id="t1c_1" class="t s2_1">Zeichnungsnummer: 910066-4</div>
      <div id="t1d_1" class="t s2_1">Zeichnungsindex: 00</div>
      <div id="t1e_1" class="t s5_1">Hoval Aktiengesellschaft, </div>
      <div id="t1f_1" class="t s5_1">Austrasse 70, FL-9490 Vaduz</div>
      <div id="t1g_1" class="t s2_1">Angst + Pfister AG</div>
      <div id="t1h_1" class="t s2_1">Industrie/Komponenten</div>
      <div id="t1i_1" class="t s2_1">Thurgauerstrasse 66</div>
      <div id="t1j_1" class="t s2_1">8052 Zürich</div>
      <div id="t1k_1" class="t s2_1">Fax: </div>
      <div id="t1l_1" class="t s2_1">+41 44 306 64 60</div>
      <div id="t1m_1" class="t s1_1">Anlieferort:</div>
      <div id="t1n_1" class="t s2_1">Firma</div>
      <div id="t1o_1" class="t s2_1">Hoval Aktiengesellschaft</div>
      <div id="t1p_1" class="t s2_1">Austrasse 70</div>
      <div id="t1q_1" class="t s2_1">9490 Vaduz</div>
      <div id="t1r_1" class="t s3_1">_______________________________________________________________________</div>
      <div id="t1s_1" class="t s1_1">Verantwortung für Energie und Umwelt</div>
      <div id="t1t_1" class="t s2_1">Hoval Aktiengesellschaft</div>
      <div id="t1u_1" class="t s2_1">Austrasse 70, 9490 Vaduz</div>
      <div id="t1v_1" class="t s2_1">Tel. +423 3992 400, </div>
      <div id="t1w_1" class="t s2_1">Fax +423 3992 411</div>
      <div id="t1x_1" class="t s2_1">info@hoval.com, www.hoval.com</div>
      <div id="t1y_1" class="t s6_1">Bestellung</div>
      <div id="t1z_1" class="t s1_1">Bestellnummer/Datum</div>
      <div id="t20_1" class="t s2_1">4501751499 / 28.02.2019</div>
      <div id="t21_1" class="t s1_1">Ihre Lieferantennummer bei uns</div>
      <div id="t22_1" class="t s2_1">510028</div>
      <div id="t23_1" class="t s1_1">Kontaktdaten - Hoval</div>
      <div id="t24_1" class="t s2_1">Katharina Baric:</div>
      <div id="t25_1" class="t s2_1">+43 50 365 </div>
      <div id="t26_1" class="t s2_1">5185</div>
      <div id="t27_1" class="t s2_1">Fax:</div>
      <div id="t28_1" class="t s2_1">+43 50 365 - </div>
      <div id="t29_1" class="t s2_1">5016</div>
      <div id="t2a_1" class="t s1_1">MwSt.-Nr.</div>
      <div id="t2b_1" class="t s1_1">50587</div>
      <div id="t2c_1" class="t s1_1">Ihr(e) AnsprechpartnerIn</div>
      <div id="t2d_1" class="t s2_1">Herr Hermann Kutzer</div>
      <div id="t2e_1" class="t s5_1">Handelsregister Nr. FL-0001.017.259-1</div>
      <div id="t2f_1" class="t s5_1">Unternehmens-Identifikationsnummer: CHE-491.026.449</div>
      <div id="t2g_1" class="t s1_1">Seite</div>
      <div id="t2h_1" class="t s5_1">1 / </div>
      <div id="t2i_1" class="t s5_1">3</div>
      
      <!-- End text definitions -->
      
      
      </div>
      </body>
      </html>
      
<!DOCTYPE html >
<html lang="en">
<head>
<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
<meta charset="utf-8" />
</head>

<body style="margin: 0;">

<div id="p2" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
	-webkit-transform: scale(0.25);
	-ms-transform: scale(0.25);
	transform: scale(0.25);
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_2{left:74px;bottom:980px;}
#t2_2{left:74px;bottom:953px;letter-spacing:-0.1px;}
#t3_2{left:160px;bottom:953px;}
#t4_2{left:406px;bottom:953px;}
#t5_2{left:507px;bottom:953px;letter-spacing:-0.1px;}
#t6_2{left:640px;bottom:953px;}
#t7_2{left:788px;bottom:953px;}
#t8_2{left:160px;bottom:934px;letter-spacing:-0.1px;}
#t9_2{left:74px;bottom:925px;}
#ta_2{left:74px;bottom:879px;letter-spacing:-0.1px;}
#tb_2{left:160px;bottom:879px;}
#tc_2{left:160px;bottom:861px;word-spacing:0.1px;}
#td_2{left:160px;bottom:843px;}
#te_2{left:160px;bottom:824px;}
#tf_2{left:160px;bottom:806px;letter-spacing:-0.1px;word-spacing:0.1px;}
#tg_2{left:160px;bottom:788px;letter-spacing:-0.1px;word-spacing:0.1px;}
#th_2{left:74px;bottom:751px;letter-spacing:-0.1px;}
#ti_2{left:160px;bottom:751px;letter-spacing:-0.1px;}
#tj_2{left:481px;bottom:751px;word-spacing:19.4px;}
#tk_2{left:650px;bottom:751px;}
#tl_2{left:806px;bottom:751px;}
#tm_2{left:160px;bottom:733px;word-spacing:0.1px;}
#tn_2{left:160px;bottom:714px;}
#to_2{left:363px;bottom:714px;letter-spacing:-0.4px;word-spacing:0.5px;}
#tp_2{left:160px;bottom:678px;word-spacing:0.1px;}
#tq_2{left:160px;bottom:659px;word-spacing:0.1px;}
#tr_2{left:160px;bottom:623px;letter-spacing:0.1px;word-spacing:-0.1px;}
#ts_2{left:160px;bottom:604px;letter-spacing:0.1px;word-spacing:-0.1px;}
#tt_2{left:160px;bottom:586px;word-spacing:0.2px;}
#tu_2{left:160px;bottom:549px;}
#tv_2{left:160px;bottom:531px;}
#tw_2{left:160px;bottom:513px;}
#tx_2{left:160px;bottom:494px;}
#ty_2{left:160px;bottom:476px;}
#tz_2{left:160px;bottom:458px;letter-spacing:-0.1px;word-spacing:0.1px;}
#t10_2{left:160px;bottom:439px;word-spacing:0.1px;}
#t11_2{left:160px;bottom:421px;}
#t12_2{left:160px;bottom:403px;}
#t13_2{left:160px;bottom:384px;letter-spacing:-0.1px;word-spacing:0.1px;}
#t14_2{left:160px;bottom:366px;letter-spacing:-0.1px;word-spacing:0.1px;}
#t15_2{left:74px;bottom:338px;}
#t16_2{left:442px;bottom:311px;}
#t17_2{left:802px;bottom:311px;}
#t18_2{left:74px;bottom:219px;}
#t19_2{left:74px;bottom:201px;word-spacing:0.1px;}
#t1a_2{left:74px;bottom:183px;word-spacing:0.2px;}
#t1b_2{left:74px;bottom:146px;word-spacing:0.2px;}
#t1c_2{left:74px;bottom:128px;letter-spacing:-0.1px;word-spacing:0.1px;}
#t1d_2{left:74px;bottom:81px;}
#t1e_2{left:74px;bottom:54px;letter-spacing:2.5px;word-spacing:-2.4px;}
#t1f_2{left:74px;bottom:1209px;letter-spacing:-0.1px;word-spacing:0.1px;}
#t1g_2{left:74px;bottom:1191px;}
#t1h_2{left:74px;bottom:1173px;}
#t1i_2{left:218px;bottom:1173px;letter-spacing:-0.1px;word-spacing:0.3px;}
#t1j_2{left:74px;bottom:1154px;}
#t1k_2{left:462px;bottom:1063px;letter-spacing:2.3px;}
#t1l_2{left:462px;bottom:1044px;word-spacing:0.1px;}
#t1m_2{left:462px;bottom:1118px;word-spacing:0.1px;}
#t1n_2{left:462px;bottom:1100px;}
#t1o_2{left:814px;bottom:1118px;letter-spacing:1.7px;}
#t1p_2{left:814px;bottom:1100px;}
#t1q_2{left:841px;bottom:1100px;}

.s1_2{
	FONT-SIZE: 73.3px;
	FONT-FAMILY: Courier;
	color: rgb(0,0,0);
}

.s2_2{
	FONT-SIZE: 61.1px;
	FONT-FAMILY: Helvetica-Oblique_9;
	color: rgb(0,0,0);
}

.s3_2{
	FONT-SIZE: 61.1px;
	FONT-FAMILY: Helvetica_6;
	color: rgb(0,0,0);
}

.s4_2{
	FONT-SIZE: 61.1px;
	FONT-FAMILY: Helvetica-Bold_l;
	color: rgb(0,0,0);
}

.s5_2{
	FONT-SIZE: 61.1px;
	FONT-FAMILY: Helvetica-BoldOblique_4;
	color: rgb(0,0,0);
}

.s6_2{
	FONT-SIZE: 48.9px;
	FONT-FAMILY: Helvetica_6;
	color: rgb(0,0,0);
}

</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts2" type="text/css" >

@font-face {
	font-family: Helvetica-BoldOblique_4;
	src: url("fonts/Helvetica-BoldOblique_4.woff") format("woff");
}

@font-face {
	font-family: Helvetica-Bold_l;
	src: url("fonts/Helvetica-Bold_l.woff") format("woff");
}

@font-face {
	font-family: Helvetica-Oblique_9;
	src: url("fonts/Helvetica-Oblique_9.woff") format("woff");
}

@font-face {
	font-family: Helvetica_6;
	src: url("fonts/Helvetica_6.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg2Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg2" style="-webkit-user-select: none;"><object width="909" height="1286" data="2/2.svg" type="image/svg+xml" id="pdf2" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div id="t1_2" class="t s1_2">_______________________________________________________________________</div>
<div id="t2_2" class="t s2_2">Position</div>
<div id="t3_2" class="t s2_2">Material</div>
<div id="t4_2" class="t s2_2">Bestellmenge</div>
<div id="t5_2" class="t s2_2">Einheit</div>
<div id="t6_2" class="t s2_2">Preis pro</div>
<div id="t7_2" class="t s2_2">Nettowert</div>
<div id="t8_2" class="t s2_2">Bezeichnung</div>
<div id="t9_2" class="t s1_2">_______________________________________________________________________</div>
<div id="ta_2" class="t s3_2">00020</div>
<div id="tb_2" class="t s2_2">Fortsetzung</div>
<div id="tc_2" class="t s3_2">Toleranz: DIN 7715 T5 P2 bzw. ±0.5mm</div>
<div id="td_2" class="t s3_2">Werkstoff: 10.1017.1105</div>
<div id="te_2" class="t s3_2">I/Art: 910066</div>
<div id="tf_2" class="t s3_2">Zollposition: 40169900</div>
<div id="tg_2" class="t s3_2">Ursprungsland Schweiz</div>
<div id="th_2" class="t s3_2">00030</div>
<div id="ti_2" class="t s3_2">5020999 </div>
<div id="tj_2" class="t s3_2">10 Stück</div>
<div id="tk_2" class="t s3_2">27,50/1</div>
<div id="tl_2" class="t s3_2">275,00</div>
<div id="tm_2" class="t s3_2">Modul-Dichtung II (Geteilte Version)</div>
<div id="tn_2" class="t s3_2">Chlorsulfonyl-PE-Kautschuk </div>
<div id="to_2" class="t s3_2">CSM 65°SHORE</div>
<div id="tp_2" class="t s3_2">nach Zeichnungs-Nr.: 5020999-4 / Index-Nr.:00</div>
<div id="tq_2" class="t s3_2">Ihre Materialnummer DT-30 2005 0025</div>
<div id="tr_2" class="t s3_2">CSM 65 ±5 Sh.A Fertigteil</div>
<div id="ts_2" class="t s3_2">D 5 ±0,4 mm, schwarz</div>
<div id="tt_2" class="t s3_2">3020050025 F2:910066-4/120/58/5mm</div>
<div id="tu_2" class="t s3_2">Form: Dichtung rund</div>
<div id="tv_2" class="t s3_2">Dicke in mm: 5</div>
<div id="tw_2" class="t s3_2">Aussen-Durchmesser in mm: 120</div>
<div id="tx_2" class="t s3_2">Innen-Durchmesser in mm: 58</div>
<div id="ty_2" class="t s3_2">Zeichnungsnummer: 910067-4</div>
<div id="tz_2" class="t s3_2">Zeichnungsindex: 00</div>
<div id="t10_2" class="t s3_2">Toleranz: DIN 7715 T5 P2 bzw. ±0.5mm</div>
<div id="t11_2" class="t s3_2">Werkstoff: 10.1017.1105</div>
<div id="t12_2" class="t s3_2">I/Alt Art: 910067 - Neu 5020999</div>
<div id="t13_2" class="t s3_2">Zollposition: 40169900</div>
<div id="t14_2" class="t s3_2">Ursprungsland Schweiz</div>
<div id="t15_2" class="t s1_2">_______________________________________________________________________</div>
<div id="t16_2" class="t s2_2">Gesamtnettowert ohne Mwst</div>
<div id="t17_2" class="t s3_2">941,25 </div>
<div id="t18_2" class="t s4_2">Lieferungen ab nicht EU Land:</div>
<div id="t19_2" class="t s3_2">Wir bitten Sie die Lieferadresse, Zolltarifnummer, Ursprungsland und Hinweis auf präferenziellen Ursprung der</div>
<div id="t1a_2" class="t s3_2">Ware auf der Rechnung zu vermerken.</div>
<div id="t1b_2" class="t s3_2">Zusätzlich benötigen wir eine Langzeitlieferantenerklärung für alle Materialien, die Sie an Hoval Aktiengesellschaft</div>
<div id="t1c_2" class="t s3_2">in Vaduz liefern.</div>
<div id="t1d_2" class="t s1_2">_______________________________________________________________________</div>
<div id="t1e_2" class="t s5_2">Verantwortung für Energie und Umwelt</div>
<div id="t1f_2" class="t s3_2">Hoval Aktiengesellschaft</div>
<div id="t1g_2" class="t s3_2">Austrasse 70, 9490 Vaduz</div>
<div id="t1h_2" class="t s3_2">Tel. +423 3992 400, </div>
<div id="t1i_2" class="t s3_2">Fax +423 3992 411</div>
<div id="t1j_2" class="t s3_2">info@hoval.com, www.hoval.com</div>
<div id="t1k_2" class="t s5_2">Bestellnummer/Datum</div>
<div id="t1l_2" class="t s3_2">4501751499 / 28.02.2019</div>
<div id="t1m_2" class="t s6_2">Handelsregister Nr. FL-0001.017.259-1</div>
<div id="t1n_2" class="t s6_2">Unternehmens-Identifikationsnummer: CHE-491.026.449</div>
<div id="t1o_2" class="t s5_2">Seite</div>
<div id="t1p_2" class="t s6_2">2 / </div>
<div id="t1q_2" class="t s6_2">3</div>

<!-- End text definitions -->


</div>
</body>
</html>

<!DOCTYPE html >
<html lang="en">
<head>
<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
<meta charset="utf-8" />
</head>

<body style="margin: 0;">

<div id="p3" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
	-webkit-transform: scale(0.25);
	-ms-transform: scale(0.25);
	transform: scale(0.25);
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_3{left:74px;bottom:971px;word-spacing:0.1px;}
#t2_3{left:74px;bottom:953px;word-spacing:0.2px;}
#t3_3{left:74px;bottom:916px;word-spacing:0.1px;}
#t4_3{left:74px;bottom:898px;word-spacing:0.1px;}
#t5_3{left:74px;bottom:879px;word-spacing:0.2px;}
#t6_3{left:74px;bottom:843px;word-spacing:0.2px;}
#t7_3{left:74px;bottom:769px;word-spacing:0.1px;}
#t8_3{left:74px;bottom:751px;word-spacing:0.1px;}
#t9_3{left:74px;bottom:714px;word-spacing:0.1px;}
#ta_3{left:74px;bottom:678px;word-spacing:0.1px;}
#tb_3{left:74px;bottom:641px;}
#tc_3{left:74px;bottom:604px;letter-spacing:-0.1px;word-spacing:0.1px;}
#td_3{left:74px;bottom:81px;}
#te_3{left:74px;bottom:54px;letter-spacing:2.5px;word-spacing:-2.4px;}
#tf_3{left:74px;bottom:1209px;letter-spacing:-0.1px;word-spacing:0.1px;}
#tg_3{left:74px;bottom:1191px;}
#th_3{left:74px;bottom:1173px;}
#ti_3{left:218px;bottom:1173px;letter-spacing:-0.1px;word-spacing:0.3px;}
#tj_3{left:74px;bottom:1154px;}
#tk_3{left:462px;bottom:1063px;letter-spacing:2.3px;}
#tl_3{left:462px;bottom:1044px;word-spacing:0.1px;}
#tm_3{left:462px;bottom:1118px;word-spacing:0.1px;}
#tn_3{left:462px;bottom:1100px;}
#to_3{left:814px;bottom:1118px;letter-spacing:1.7px;}
#tp_3{left:814px;bottom:1100px;}
#tq_3{left:841px;bottom:1100px;}

.s1{
	FONT-SIZE: 61.1px;
	FONT-FAMILY: Helvetica_6;
	color: rgb(0,0,0);
}

.s2{
	FONT-SIZE: 61.1px;
	FONT-FAMILY: Helvetica-Bold_l;
	color: rgb(0,0,0);
}

.s3{
	FONT-SIZE: 73.3px;
	FONT-FAMILY: Courier;
	color: rgb(0,0,0);
}

.s4{
	FONT-SIZE: 61.1px;
	FONT-FAMILY: Helvetica-BoldOblique_4;
	color: rgb(0,0,0);
}

.s5{
	FONT-SIZE: 48.9px;
	FONT-FAMILY: Helvetica_6;
	color: rgb(0,0,0);
}

</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts3" type="text/css" >

@font-face {
	font-family: Helvetica-BoldOblique_4;
	src: url("fonts/Helvetica-BoldOblique_4.woff") format("woff");
}

@font-face {
	font-family: Helvetica-Bold_l;
	src: url("fonts/Helvetica-Bold_l.woff") format("woff");
}

@font-face {
	font-family: Helvetica_6;
	src: url("fonts/Helvetica_6.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg3Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg3" style="-webkit-user-select: none;"><object width="909" height="1286" data="3/3.svg" type="image/svg+xml" id="pdf3" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div id="t1_3" class="t s1">Die Langzeitlieferantenerklärung bitte im Original an Hoval Aktiengesellschaft, Abt. Strategische Zollabwicklung, zu</div>
<div id="t2_3" class="t s1">senden (E-Mail: zoll@hoval.com).</div>
<div id="t3_3" class="t s2">Lieferungen ab EU Land:</div>
<div id="t4_3" class="t s1">Wir bitten Sie die Lieferadresse, Zolltarifnummer, Ursprungsland und Hinweis auf präferenziellen Ursprung der</div>
<div id="t5_3" class="t s1">Ware auf der Rechnung zu vermerken.</div>
<div id="t6_3" class="t s1">Vielen Dank für Ihre Unterstützung.</div>
<div id="t7_3" class="t s2">Bitte geben Sie auf allen Dokumenten wie Lieferpapiere, Auftragsbestätigung und Rechnungen immer</div>
<div id="t8_3" class="t s2">unsere Bestell- und Artikelnummer an.</div>
<div id="t9_3" class="t s2">Vermerken Sie auf dem Lieferschein, wenn es sich um eine Teil- oder Endlieferung handelt.</div>
<div id="ta_3" class="t s2">Diese Bestellung ist auch ohne Unterschrift gültig.</div>
<div id="tb_3" class="t s1">Freundliche Grüsse</div>
<div id="tc_3" class="t s1">Hoval Aktiengesellschaft</div>
<div id="td_3" class="t s3">_______________________________________________________________________</div>
<div id="te_3" class="t s4">Verantwortung für Energie und Umwelt</div>
<div id="tf_3" class="t s1">Hoval Aktiengesellschaft</div>
<div id="tg_3" class="t s1">Austrasse 70, 9490 Vaduz</div>
<div id="th_3" class="t s1">Tel. +423 3992 400, </div>
<div id="ti_3" class="t s1">Fax +423 3992 411</div>
<div id="tj_3" class="t s1">info@hoval.com, www.hoval.com</div>
<div id="tk_3" class="t s4">Bestellnummer/Datum</div>
<div id="tl_3" class="t s1">4501751499 / 28.02.2019</div>
<div id="tm_3" class="t s5">Handelsregister Nr. FL-0001.017.259-1</div>
<div id="tn_3" class="t s5">Unternehmens-Identifikationsnummer: CHE-491.026.449</div>
<div id="to_3" class="t s4">Seite</div>
<div id="tp_3" class="t s5">3 / </div>
<div id="tq_3" class="t s5">3</div>

<!-- End text definitions -->


</div>
</body>
</html>
`,
  Jorns: `
<!DOCTYPE html >
<html lang="en">
<head>
<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
<meta charset="utf-8" />
</head>

<body style="margin: 0;">

<div id="p1" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
	-webkit-transform: scale(0.25);
	-ms-transform: scale(0.25);
	transform: scale(0.25);
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_1{left:86px;bottom:811px;}
#t2_1{left:844px;bottom:1185px;}
#t3_1{left:648px;bottom:1011px;}
#t4_1{left:648px;bottom:1057px;}
#t5_1{left:518px;bottom:1041px;}
#t6_1{left:648px;bottom:1026px;}
#t7_1{left:518px;bottom:1057px;}
#t8_1{left:518px;bottom:1026px;}
#t9_1{left:518px;bottom:1011px;}
#ta_1{left:648px;bottom:1041px;}
#tb_1{left:518px;bottom:996px;}
#tc_1{left:518px;bottom:980px;}
#td_1{left:518px;bottom:950px;}
#te_1{left:648px;bottom:980px;}
#tf_1{left:648px;bottom:950px;}
#tg_1{left:518px;bottom:919px;}
#th_1{left:518px;bottom:934px;}
#ti_1{left:86px;bottom:1020px;}
#tj_1{left:86px;bottom:1004px;}
#tk_1{left:86px;bottom:988px;}
#tl_1{left:86px;bottom:1057px;}
#tm_1{left:86px;bottom:787px;}
#tn_1{left:784px;bottom:764px;}
#to_1{left:668px;bottom:764px;}
#tp_1{left:564px;bottom:764px;}
#tq_1{left:519px;bottom:764px;}
#tr_1{left:280px;bottom:764px;}
#ts_1{left:128px;bottom:764px;}
#tt_1{left:86px;bottom:764px;}
#tu_1{left:280px;bottom:738px;}
#tv_1{left:564px;bottom:738px;}
#tw_1{left:823px;bottom:738px;}
#tx_1{left:128px;bottom:738px;}
#ty_1{left:519px;bottom:738px;}
#tz_1{left:708px;bottom:738px;}
#t10_1{left:86px;bottom:738px;}
#t11_1{left:128px;bottom:719px;}
#t12_1{left:128px;bottom:699px;}
#t13_1{left:280px;bottom:699px;}
#t14_1{left:280px;bottom:674px;}
#t15_1{left:564px;bottom:674px;}
#t16_1{left:823px;bottom:674px;}
#t17_1{left:128px;bottom:674px;}
#t18_1{left:519px;bottom:674px;}
#t19_1{left:708px;bottom:674px;}
#t1a_1{left:86px;bottom:674px;}
#t1b_1{left:128px;bottom:654px;}
#t1c_1{left:128px;bottom:635px;}
#t1d_1{left:280px;bottom:635px;}
#t1e_1{left:280px;bottom:610px;}
#t1f_1{left:564px;bottom:610px;}
#t1g_1{left:823px;bottom:610px;}
#t1h_1{left:128px;bottom:610px;}
#t1i_1{left:526px;bottom:610px;}
#t1j_1{left:708px;bottom:610px;}
#t1k_1{left:86px;bottom:610px;}
#t1l_1{left:128px;bottom:590px;}
#t1m_1{left:128px;bottom:570px;}
#t1n_1{left:280px;bottom:570px;}
#t1o_1{left:280px;bottom:546px;}
#t1p_1{left:564px;bottom:546px;}
#t1q_1{left:823px;bottom:546px;}
#t1r_1{left:128px;bottom:546px;}
#t1s_1{left:526px;bottom:546px;}
#t1t_1{left:708px;bottom:546px;}
#t1u_1{left:86px;bottom:546px;}
#t1v_1{left:128px;bottom:526px;}
#t1w_1{left:128px;bottom:506px;}
#t1x_1{left:280px;bottom:506px;}
#t1y_1{left:280px;bottom:482px;}
#t1z_1{left:564px;bottom:482px;}
#t20_1{left:823px;bottom:482px;}
#t21_1{left:128px;bottom:482px;}
#t22_1{left:526px;bottom:482px;}
#t23_1{left:708px;bottom:482px;}
#t24_1{left:86px;bottom:482px;}
#t25_1{left:128px;bottom:462px;}
#t26_1{left:128px;bottom:442px;}
#t27_1{left:280px;bottom:442px;}
#t28_1{left:280px;bottom:418px;}
#t29_1{left:564px;bottom:418px;}
#t2a_1{left:830px;bottom:418px;}
#t2b_1{left:128px;bottom:418px;}
#t2c_1{left:534px;bottom:418px;}
#t2d_1{left:700px;bottom:418px;}
#t2e_1{left:86px;bottom:418px;}
#t2f_1{left:128px;bottom:398px;}
#t2g_1{left:128px;bottom:378px;}
#t2h_1{left:128px;bottom:358px;}
#t2i_1{left:280px;bottom:358px;}
#t2j_1{left:280px;bottom:334px;}
#t2k_1{left:564px;bottom:334px;}
#t2l_1{left:830px;bottom:334px;}
#t2m_1{left:128px;bottom:334px;}
#t2n_1{left:534px;bottom:334px;}
#t2o_1{left:700px;bottom:334px;}
#t2p_1{left:86px;bottom:334px;}
#t2q_1{left:128px;bottom:314px;}
#t2r_1{left:128px;bottom:294px;}
#t2s_1{left:128px;bottom:274px;}
#t2t_1{left:280px;bottom:274px;}
#t2u_1{left:128px;bottom:254px;}
#t2v_1{left:197px;bottom:254px;}
#t2w_1{left:280px;bottom:230px;}
#t2x_1{left:564px;bottom:230px;}
#t2y_1{left:823px;bottom:230px;}
#t2z_1{left:128px;bottom:230px;}
#t30_1{left:534px;bottom:230px;}
#t31_1{left:700px;bottom:230px;}
#t32_1{left:86px;bottom:230px;}
#t33_1{left:128px;bottom:210px;}
#t34_1{left:128px;bottom:190px;}
#t35_1{left:128px;bottom:170px;}
#t36_1{left:280px;bottom:170px;}

.s1_1{
	FONT-SIZE: 73.3px;
	FONT-FAMILY: Arial-BoldMT_o;
	color: rgb(0,0,0);
}

.s2_1{
	FONT-SIZE: 42.8px;
	FONT-FAMILY: ArialMT_n;
	color: rgb(0,0,0);
}

.s3_1{
	FONT-SIZE: 42.8px;
	FONT-FAMILY: Arial-BoldMT_o;
	color: rgb(0,0,0);
}

.s4_1{
	FONT-SIZE: 55px;
	FONT-FAMILY: ArialMT_n;
	color: rgb(0,0,0);
}

.s5_1{
	FONT-SIZE: 55px;
	FONT-FAMILY: ArialUnicodeMS_p;
	color: rgb(0,0,0);
}

.s6_1{
	FONT-SIZE: 55px;
	FONT-FAMILY: Arial-BoldMT_o;
	color: rgb(0,0,0);
}

</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts1" type="text/css" >

@font-face {
	font-family: Arial-BoldMT_o;
	src: url("fonts/Arial-BoldMT_o.woff") format("woff");
}

@font-face {
	font-family: ArialMT_n;
	src: url("fonts/ArialMT_n.woff") format("woff");
}

@font-face {
	font-family: ArialUnicodeMS_p;
	src: url("fonts/ArialUnicodeMS_p.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg1Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg1" style="-webkit-user-select: none;"><object width="909" height="1286" data="1/1.svg" type="image/svg+xml" id="pdf1" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div id="t1_1" class="t s1_1">Bestellung Nr. EB1903913</div>
<div id="t2_1" class="t s2_1">1 / 2</div>
<div id="t3_1" class="t s2_1">d.oegger@jorns.ch</div>
<div id="t4_1" class="t s2_1">18. November 2019</div>
<div id="t5_1" class="t s3_1">Jorns Kontaktperson</div>
<div id="t6_1" class="t s2_1">+41 62 919 91 79</div>
<div id="t7_1" class="t s3_1">Datum</div>
<div id="t8_1" class="t s3_1">Telefon</div>
<div id="t9_1" class="t s3_1">eMail</div>
<div id="ta_1" class="t s2_1">Herr D. Oegger / OED</div>
<div id="tb_1" class="t s3_1">Jorns Kunden Nr.</div>
<div id="tc_1" class="t s3_1">Jorns MwSt.-Nr.</div>
<div id="td_1" class="t s3_1">Lieferant Nr.</div>
<div id="te_1" class="t s2_1">CHE-102.577.156</div>
<div id="tf_1" class="t s2_1">00021</div>
<div id="tg_1" class="t s3_1">Kommission</div>
<div id="th_1" class="t s3_1">Bemerkung</div>
<div id="ti_1" class="t s4_1">Angst+Pfister AG</div>
<div id="tj_1" class="t s4_1">Thurgauerstrasse 66</div>
<div id="tk_1" class="t s4_1">8052 Zürich</div>
<div id="tl_1" class="t s2_1">Jorns AG, Kirchgasse 12, 4932 Lotzwil, Schweiz</div>
<div id="tm_1" class="t s5_1">Wir bestellen bei Ihnen folgende Artikel zu den untenstehenden Konditionen:</div>
<div id="tn_1" class="t s4_1">Gesamt EUR</div>
<div id="to_1" class="t s4_1">Einzelpreis</div>
<div id="tp_1" class="t s4_1">ME</div>
<div id="tq_1" class="t s4_1">Menge</div>
<div id="tr_1" class="t s4_1">Liefertermin</div>
<div id="ts_1" class="t s4_1">Artikel</div>
<div id="tt_1" class="t s4_1">Pos</div>
<div id="tu_1" class="t s4_1">5. Dezember 2019</div>
<div id="tv_1" class="t s4_1">Stk</div>
<div id="tw_1" class="t s4_1">859.50</div>
<div id="tx_1" class="t s4_1">102298</div>
<div id="ty_1" class="t s4_1">150.00</div>
<div id="tz_1" class="t s4_1">5.73</div>
<div id="t10_1" class="t s4_1">1</div>
<div id="t11_1" class="t s4_1">Hydraulik-Schl. DN10 DOL12/K90DOL12x700</div>
<div id="t12_1" class="t s4_1">Ihre Artikeldaten</div>
<div id="t13_1" class="t s4_1">FT - 80 0058 5958</div>
<div id="t14_1" class="t s4_1">5. Dezember 2019</div>
<div id="t15_1" class="t s4_1">Stk</div>
<div id="t16_1" class="t s4_1">504.00</div>
<div id="t17_1" class="t s4_1">102293</div>
<div id="t18_1" class="t s4_1">100.00</div>
<div id="t19_1" class="t s4_1">5.04</div>
<div id="t1a_1" class="t s4_1">2</div>
<div id="t1b_1" class="t s4_1">Hydraulik-Schl. DN10 DOL12/DOL12x570</div>
<div id="t1c_1" class="t s4_1">Ihre Artikeldaten</div>
<div id="t1d_1" class="t s4_1">FT - 80 0056 4303</div>
<div id="t1e_1" class="t s4_1">5. Dezember 2019</div>
<div id="t1f_1" class="t s4_1">Stk</div>
<div id="t1g_1" class="t s4_1">106.80</div>
<div id="t1h_1" class="t s4_1">102294</div>
<div id="t1i_1" class="t s4_1">20.00</div>
<div id="t1j_1" class="t s4_1">5.34</div>
<div id="t1k_1" class="t s4_1">3</div>
<div id="t1l_1" class="t s4_1">Hydraulik-Schl. DN10 DOL12/DOL12x720</div>
<div id="t1m_1" class="t s4_1">Ihre Artikeldaten</div>
<div id="t1n_1" class="t s4_1">FT - 80 0059 1189</div>
<div id="t1o_1" class="t s4_1">5. Dezember 2019</div>
<div id="t1p_1" class="t s4_1">Stk</div>
<div id="t1q_1" class="t s4_1">315.50</div>
<div id="t1r_1" class="t s4_1">102300</div>
<div id="t1s_1" class="t s4_1">50.00</div>
<div id="t1t_1" class="t s4_1">6.31</div>
<div id="t1u_1" class="t s4_1">4</div>
<div id="t1v_1" class="t s4_1">Hydraulik-Schl. DN10 DOL12/K90DOL12x900</div>
<div id="t1w_1" class="t s4_1">Ihre Artikeldaten</div>
<div id="t1x_1" class="t s4_1">FT - 80 0058 0689</div>
<div id="t1y_1" class="t s4_1">5. Dezember 2019</div>
<div id="t1z_1" class="t s4_1">Stk</div>
<div id="t20_1" class="t s4_1">501.00</div>
<div id="t21_1" class="t s4_1">109973</div>
<div id="t22_1" class="t s4_1">60.00</div>
<div id="t23_1" class="t s4_1">8.35</div>
<div id="t24_1" class="t s4_1">5</div>
<div id="t25_1" class="t s4_1">Hydraulik-Schl. DN10 DOL12/K90DOL12x800</div>
<div id="t26_1" class="t s4_1">Ihre Artikeldaten</div>
<div id="t27_1" class="t s4_1">FT - 80 0062 6517</div>
<div id="t28_1" class="t s4_1">5. Dezember 2019</div>
<div id="t29_1" class="t s4_1">Stk</div>
<div id="t2a_1" class="t s4_1">78.28</div>
<div id="t2b_1" class="t s4_1">203003</div>
<div id="t2c_1" class="t s4_1">2.00</div>
<div id="t2d_1" class="t s4_1">39.14</div>
<div id="t2e_1" class="t s4_1">6</div>
<div id="t2f_1" class="t s4_1">Hydraulik-Schl. DN25 DOS30/DOS30x585</div>
<div id="t2g_1" class="t s4_1">Typ: 4SP</div>
<div id="t2h_1" class="t s4_1">Ihre Artikeldaten</div>
<div id="t2i_1" class="t s4_1">FT - 80 0061 9359</div>
<div id="t2j_1" class="t s4_1">5. Dezember 2019</div>
<div id="t2k_1" class="t s4_1">Stk</div>
<div id="t2l_1" class="t s4_1">89.60</div>
<div id="t2m_1" class="t s4_1">203004</div>
<div id="t2n_1" class="t s4_1">2.00</div>
<div id="t2o_1" class="t s4_1">44.80</div>
<div id="t2p_1" class="t s4_1">7</div>
<div id="t2q_1" class="t s4_1">Hydraulik-Schl. DN25 DOS30/K90DOS30x940</div>
<div id="t2r_1" class="t s4_1">Typ: 4SP</div>
<div id="t2s_1" class="t s4_1">Ihre Artikeldaten</div>
<div id="t2t_1" class="t s4_1">FT - 80 0061 9360</div>
<div id="t2u_1" class="t s6_1">Toleranz: </div>
<div id="t2v_1" class="t s6_1">0 / + 5 mm</div>
<div id="t2w_1" class="t s4_1">5. Dezember 2019</div>
<div id="t2x_1" class="t s4_1">Stk</div>
<div id="t2y_1" class="t s4_1">148.52</div>
<div id="t2z_1" class="t s4_1">203005</div>
<div id="t30_1" class="t s4_1">2.00</div>
<div id="t31_1" class="t s4_1">74.26</div>
<div id="t32_1" class="t s4_1">8</div>
<div id="t33_1" class="t s4_1">Hydraulik-Schl. DN38 DOL42/K90DOL42x860</div>
<div id="t34_1" class="t s4_1">Typ: 1SN</div>
<div id="t35_1" class="t s4_1">Ihre Artikeldaten</div>
<div id="t36_1" class="t s4_1">FT - 80 0061 9363</div>

<!-- End text definitions -->


</div>
</body>
</html>

<!DOCTYPE html >
<html lang="en">
<head>
<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
<meta charset="utf-8" />
</head>

<body style="margin: 0;">

<div id="p2" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
	-webkit-transform: scale(0.25);
	-ms-transform: scale(0.25);
	transform: scale(0.25);
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_2{left:86px;bottom:1117px;}
#t2_2{left:744px;bottom:1094px;}
#t3_2{left:86px;bottom:1094px;}
#t4_2{left:844px;bottom:1185px;}
#t5_2{left:784px;bottom:1067px;}
#t6_2{left:668px;bottom:1067px;}
#t7_2{left:564px;bottom:1067px;}
#t8_2{left:519px;bottom:1067px;}
#t9_2{left:280px;bottom:1067px;}
#ta_2{left:128px;bottom:1067px;}
#tb_2{left:86px;bottom:1067px;}
#tc_2{left:280px;bottom:1041px;}
#td_2{left:564px;bottom:1041px;}
#te_2{left:823px;bottom:1041px;}
#tf_2{left:128px;bottom:1041px;}
#tg_2{left:526px;bottom:1041px;}
#th_2{left:708px;bottom:1041px;}
#ti_2{left:86px;bottom:1041px;}
#tj_2{left:128px;bottom:1021px;}
#tk_2{left:128px;bottom:1001px;}
#tl_2{left:280px;bottom:1001px;}
#tm_2{left:280px;bottom:977px;}
#tn_2{left:564px;bottom:977px;}
#to_2{left:823px;bottom:977px;}
#tp_2{left:128px;bottom:977px;}
#tq_2{left:534px;bottom:977px;}
#tr_2{left:700px;bottom:977px;}
#ts_2{left:86px;bottom:977px;}
#tt_2{left:128px;bottom:957px;}
#tu_2{left:128px;bottom:937px;}
#tv_2{left:128px;bottom:917px;}
#tw_2{left:280px;bottom:917px;}
#tx_2{left:280px;bottom:893px;}
#ty_2{left:564px;bottom:893px;}
#tz_2{left:830px;bottom:893px;}
#t10_2{left:128px;bottom:893px;}
#t11_2{left:534px;bottom:893px;}
#t12_2{left:700px;bottom:893px;}
#t13_2{left:86px;bottom:893px;}
#t14_2{left:128px;bottom:873px;}
#t15_2{left:128px;bottom:853px;}
#t16_2{left:128px;bottom:833px;}
#t17_2{left:280px;bottom:833px;}
#t18_2{left:280px;bottom:809px;}
#t19_2{left:564px;bottom:809px;}
#t1a_2{left:823px;bottom:809px;}
#t1b_2{left:128px;bottom:809px;}
#t1c_2{left:534px;bottom:809px;}
#t1d_2{left:700px;bottom:809px;}
#t1e_2{left:86px;bottom:809px;}
#t1f_2{left:128px;bottom:789px;}
#t1g_2{left:128px;bottom:769px;}
#t1h_2{left:128px;bottom:749px;}
#t1i_2{left:280px;bottom:749px;}
#t1j_2{left:280px;bottom:725px;}
#t1k_2{left:564px;bottom:725px;}
#t1l_2{left:830px;bottom:725px;}
#t1m_2{left:128px;bottom:725px;}
#t1n_2{left:534px;bottom:725px;}
#t1o_2{left:700px;bottom:725px;}
#t1p_2{left:86px;bottom:725px;}
#t1q_2{left:128px;bottom:705px;}
#t1r_2{left:128px;bottom:685px;}
#t1s_2{left:128px;bottom:665px;}
#t1t_2{left:280px;bottom:665px;}
#t1u_2{left:280px;bottom:641px;}
#t1v_2{left:564px;bottom:641px;}
#t1w_2{left:830px;bottom:641px;}
#t1x_2{left:128px;bottom:641px;}
#t1y_2{left:534px;bottom:641px;}
#t1z_2{left:700px;bottom:641px;}
#t20_2{left:86px;bottom:641px;}
#t21_2{left:128px;bottom:621px;}
#t22_2{left:128px;bottom:601px;}
#t23_2{left:280px;bottom:601px;}
#t24_2{left:536px;bottom:581px;}
#t25_2{left:631px;bottom:581px;}
#t26_2{left:830px;bottom:581px;}
#t27_2{left:639px;bottom:551px;}
#t28_2{left:812px;bottom:549px;}
#t29_2{left:823px;bottom:534px;}
#t2a_2{left:578px;bottom:534px;}
#t2b_2{left:603px;bottom:509px;}
#t2c_2{left:812px;bottom:508px;}
#t2d_2{left:86px;bottom:261px;}
#t2e_2{left:86px;bottom:411px;}
#t2f_2{left:86px;bottom:453px;}
#t2g_2{left:280px;bottom:451px;}
#t2h_2{left:86px;bottom:392px;}
#t2i_2{left:86px;bottom:341px;}
#t2j_2{left:86px;bottom:323px;}
#t2k_2{left:86px;bottom:268px;}

.s1{
	FONT-SIZE: 55px;
	FONT-FAMILY: Arial-BoldMT_o;
	color: rgb(0,0,0);
}

.s2{
	FONT-SIZE: 55px;
	FONT-FAMILY: ArialMT_n;
	color: rgb(0,0,0);
}

.s3{
	FONT-SIZE: 42.8px;
	FONT-FAMILY: ArialMT_n;
	color: rgb(0,0,0);
}

.s4{
	FONT-SIZE: 6.1px;
	FONT-FAMILY: Arial-BoldMT_o;
	color: rgb(255,255,255);
}

.s5{
	FONT-SIZE: 55px;
	FONT-FAMILY: ArialUnicodeMS_p;
	color: rgb(0,0,0);
}

.s6{
	FONT-SIZE: 55px;
	FONT-FAMILY: ArialUnicodeMS_p;
	color: rgb(0,0,0);
	-webkit-text-stroke: 1.8px #000000;
	text-stroke: 1.8px #000000;
}

.s7{
	FONT-SIZE: 61.1px;
	FONT-FAMILY: ArialMT_n;
	color: rgb(0,0,0);
}

</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts2" type="text/css" >

@font-face {
	font-family: Arial-BoldMT_o;
	src: url("fonts/Arial-BoldMT_o.woff") format("woff");
}

@font-face {
	font-family: ArialMT_n;
	src: url("fonts/ArialMT_n.woff") format("woff");
}

@font-face {
	font-family: ArialUnicodeMS_p;
	src: url("fonts/ArialUnicodeMS_p.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg2Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg2" style="-webkit-user-select: none;"><object width="909" height="1286" data="2/2.svg" type="image/svg+xml" id="pdf2" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div id="t1_2" class="t s1">Bestellung Nr. EB1903913</div>
<div id="t2_2" class="t s2">18. November 2019</div>
<div id="t3_2" class="t s2">00021 / Angst+Pfister AG</div>
<div id="t4_2" class="t s3">2 / 2</div>
<div id="t5_2" class="t s2">Gesamt EUR</div>
<div id="t6_2" class="t s2">Einzelpreis</div>
<div id="t7_2" class="t s2">ME</div>
<div id="t8_2" class="t s2">Menge</div>
<div id="t9_2" class="t s2">Liefertermin</div>
<div id="ta_2" class="t s2">Artikel</div>
<div id="tb_2" class="t s2">Pos</div>
<div id="tc_2" class="t s2">5. Dezember 2019</div>
<div id="td_2" class="t s2">Stk</div>
<div id="te_2" class="t s2">167.10</div>
<div id="tf_2" class="t s2">102297</div>
<div id="tg_2" class="t s2">30.00</div>
<div id="th_2" class="t s2">5.57</div>
<div id="ti_2" class="t s2">9</div>
<div id="tj_2" class="t s2">Hydraulik-Schl. DN10 DOL12/K90DOL12x620</div>
<div id="tk_2" class="t s2">Ihre Artikeldaten</div>
<div id="tl_2" class="t s2">FT - 80 0056 4304</div>
<div id="tm_2" class="t s2">5. Dezember 2019</div>
<div id="tn_2" class="t s2">Stk</div>
<div id="to_2" class="t s2">124.08</div>
<div id="tp_2" class="t s2">203006</div>
<div id="tq_2" class="t s2">6.00</div>
<div id="tr_2" class="t s2">20.68</div>
<div id="ts_2" class="t s2">10</div>
<div id="tt_2" class="t s2">Hydraulik-Schl. DN16 DOL18/DOL18x640</div>
<div id="tu_2" class="t s2">Typ: 2SN-K / EC881-10</div>
<div id="tv_2" class="t s2">Ihre Artikeldaten</div>
<div id="tw_2" class="t s2">FT - 80 0061 9345</div>
<div id="tx_2" class="t s2">5. Dezember 2019</div>
<div id="ty_2" class="t s2">Stk</div>
<div id="tz_2" class="t s2">91.65</div>
<div id="t10_2" class="t s2">203007</div>
<div id="t11_2" class="t s2">5.00</div>
<div id="t12_2" class="t s2">18.33</div>
<div id="t13_2" class="t s2">11</div>
<div id="t14_2" class="t s2">Hydraulik-Schl. DN16 DOL18/DOL18x1600</div>
<div id="t15_2" class="t s2">Typ: 2SN-K</div>
<div id="t16_2" class="t s2">Ihre Artikeldaten</div>
<div id="t17_2" class="t s2">FT - 80 0065 0593</div>
<div id="t18_2" class="t s2">5. Dezember 2019</div>
<div id="t19_2" class="t s2">Stk</div>
<div id="t1a_2" class="t s2">183.30</div>
<div id="t1b_2" class="t s2">204982</div>
<div id="t1c_2" class="t s2">3.00</div>
<div id="t1d_2" class="t s2">61.10</div>
<div id="t1e_2" class="t s2">12</div>
<div id="t1f_2" class="t s2">Hydraulik-Schl. DN25 DOS30/DOS30x1750</div>
<div id="t1g_2" class="t s2">Typ: 4SP / EC881-16</div>
<div id="t1h_2" class="t s2">Ihre Artikeldaten</div>
<div id="t1i_2" class="t s2">FT - 80 0071 3078</div>
<div id="t1j_2" class="t s2">5. Dezember 2019</div>
<div id="t1k_2" class="t s2">Stk</div>
<div id="t1l_2" class="t s2">64.00</div>
<div id="t1m_2" class="t s2">203055</div>
<div id="t1n_2" class="t s2">4.00</div>
<div id="t1o_2" class="t s2">16.00</div>
<div id="t1p_2" class="t s2">13</div>
<div id="t1q_2" class="t s2">Hydraulik-Schl. DN16 DOL18/DOL18x700</div>
<div id="t1r_2" class="t s2">Typ: 2SN-K</div>
<div id="t1s_2" class="t s2">Ihre Artikeldaten</div>
<div id="t1t_2" class="t s2">FT - 80 0062 1271</div>
<div id="t1u_2" class="t s2">5. Dezember 2019</div>
<div id="t1v_2" class="t s2">Stk</div>
<div id="t1w_2" class="t s2">27.60</div>
<div id="t1x_2" class="t s2">115782</div>
<div id="t1y_2" class="t s2">2.00</div>
<div id="t1z_2" class="t s2">13.80</div>
<div id="t20_2" class="t s2">14</div>
<div id="t21_2" class="t s2">Hydraulik-Schl. DN19 DOL22/DOL22x720</div>
<div id="t22_2" class="t s2">Ihre Artikeldaten</div>
<div id="t23_2" class="t s2">8000362721</div>
<div id="t24_2" class="t s2">- 4.00 %</div>
<div id="t25_2" class="t s2">Lieferantenrabatt</div>
<div id="t26_2" class="t s2">- 1.10</div>
<div id="t27_2" class="t s2">Nettowarenwert</div>
<div id="t28_2" class="t s2">3'259.83</div>
<div id="t29_2" class="t s2">251,00</div>
<div id="t2a_2" class="t s2">MwSt. 7.7% von 3.259,83</div>
<div id="t2b_2" class="t s1">Gesamtsumme EUR</div>
<div id="t2c_2" class="t s1">3'510.83</div>
<div id="t2d_2" class="t s4">Dauertext Einkauf - Hinweis auf Messen, Betriebsurlaub etc.</div>
<div id="t2e_2" class="t s5">Bitte geben Sie auf Ihren Lieferscheinen und Rechnungen unsere Bestell-Nummer und Artikelnummern an.</div>
<div id="t2f_2" class="t s1">Zahlungsbedingung:</div>
<div id="t2g_2" class="t s2">14 Tage 2% / 30 Tage netto ab Fakturadatum</div>
<div id="t2h_2" class="t s1">Bitte senden Sie uns eine Auftragsbestätigung.</div>
<div id="t2i_2" class="t s5">Freundliche Grüsse</div>
<div id="t2j_2" class="t s6">Jorns AG</div>
<div id="t2k_2" class="t s7">Daniel Oegger</div>

<!-- End text definitions -->


</div>
</body>
</html>
`,
  Distrelec: `
<!DOCTYPE html >
<html lang="en">
<head>
<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
<meta charset="utf-8" />
</head>

<body style="margin: 0;">

<div id="p1" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
	-webkit-transform: scale(0.25);
	-ms-transform: scale(0.25);
	transform: scale(0.25);
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_1{left:43px;bottom:98px;}
#t2_1{left:43px;bottom:87px;}
#t3_1{left:224px;bottom:87px;}
#t4_1{left:350px;bottom:87px;}
#t5_1{left:461px;bottom:87px;}
#t6_1{left:43px;bottom:76px;}
#t7_1{left:266px;bottom:76px;}
#t8_1{left:376px;bottom:76px;word-spacing:3.6px;}
#t9_1{left:43px;bottom:64px;}
#ta_1{left:321px;bottom:64px;}
#tb_1{left:541px;bottom:64px;}
#tc_1{left:41px;bottom:1145px;}
#td_1{left:41px;bottom:1128px;}
#te_1{left:41px;bottom:1111px;word-spacing:-3px;}
#tf_1{left:41px;bottom:1094px;}
#tg_1{left:87px;bottom:1094px;}
#th_1{left:41px;bottom:1078px;}
#ti_1{left:453px;bottom:1162px;}
#tj_1{left:833px;bottom:1160px;}
#tk_1{left:833px;bottom:1160px;word-spacing:3.1px;}
#tl_1{left:849px;bottom:1160px;letter-spacing:19.1px;}
#tm_1{left:622px;bottom:1136px;}
#tn_1{left:457px;bottom:1136px;}
#to_1{left:622px;bottom:1117px;}
#tp_1{left:457px;bottom:1117px;}
#tq_1{left:622px;bottom:1098px;}
#tr_1{left:457px;bottom:1098px;}
#ts_1{left:622px;bottom:1079px;}
#tt_1{left:457px;bottom:1079px;}
#tu_1{left:622px;bottom:1060px;}
#tv_1{left:457px;bottom:1060px;}
#tw_1{left:622px;bottom:1041px;}
#tx_1{left:457px;bottom:1041px;}
#ty_1{left:622px;bottom:1022px;}
#tz_1{left:457px;bottom:1022px;}
#t10_1{left:622px;bottom:1003px;}
#t11_1{left:457px;bottom:1003px;}
#t12_1{left:622px;bottom:984px;}
#t13_1{left:457px;bottom:984px;}
#t14_1{left:41px;bottom:894px;}
#t15_1{left:156px;bottom:880px;}
#t16_1{left:41px;bottom:880px;}
#t17_1{left:282px;bottom:880px;}
#t18_1{left:217px;bottom:880px;}
#t19_1{left:461px;bottom:880px;}
#t1a_1{left:452px;bottom:880px;}
#t1b_1{left:576px;bottom:880px;}
#t1c_1{left:506px;bottom:880px;}
#t1d_1{left:41px;bottom:831px;}
#t1e_1{left:132px;bottom:815px;}
#t1f_1{left:41px;bottom:815px;}
#t1g_1{left:168px;bottom:815px;}
#t1h_1{left:484px;bottom:815px;}
#t1i_1{left:392px;bottom:815px;}
#t1j_1{left:519px;bottom:815px;}
#t1k_1{left:687px;bottom:815px;}
#t1l_1{left:626px;bottom:815px;}
#t1m_1{left:726px;bottom:815px;}
#t1n_1{left:50px;bottom:764px;}
#t1o_1{left:132px;bottom:764px;}
#t1p_1{left:238px;bottom:764px;}
#t1q_1{left:476px;bottom:764px;}
#t1r_1{left:563px;bottom:764px;}
#t1s_1{left:662px;bottom:766px;}
#t1t_1{left:645px;bottom:756px;}
#t1u_1{left:763px;bottom:764px;}
#t1v_1{left:50px;bottom:732px;}
#t1w_1{left:132px;bottom:732px;}
#t1x_1{left:238px;bottom:733px;}
#t1y_1{left:497px;bottom:732px;}
#t1z_1{left:528px;bottom:732px;}
#t20_1{left:586px;bottom:732px;}
#t21_1{left:666px;bottom:732px;}
#t22_1{left:800px;bottom:732px;word-spacing:7px;}
#t23_1{left:132px;bottom:720px;}
#t24_1{left:238px;bottom:720px;}
#t25_1{left:405px;bottom:720px;}
#t26_1{left:438px;bottom:720px;}
#t27_1{left:414px;bottom:720px;}
#t28_1{left:221px;bottom:707px;}
#t29_1{left:132px;bottom:707px;}
#t2a_1{left:50px;bottom:682px;}
#t2b_1{left:132px;bottom:682px;}
#t2c_1{left:238px;bottom:683px;}
#t2d_1{left:497px;bottom:682px;}
#t2e_1{left:528px;bottom:682px;}
#t2f_1{left:586px;bottom:682px;}
#t2g_1{left:666px;bottom:682px;}
#t2h_1{left:800px;bottom:682px;word-spacing:7px;}
#t2i_1{left:132px;bottom:670px;}
#t2j_1{left:238px;bottom:670px;}
#t2k_1{left:405px;bottom:670px;}
#t2l_1{left:438px;bottom:670px;}
#t2m_1{left:414px;bottom:670px;}
#t2n_1{left:221px;bottom:657px;}
#t2o_1{left:132px;bottom:657px;}
#t2p_1{left:50px;bottom:632px;}
#t2q_1{left:132px;bottom:632px;}
#t2r_1{left:238px;bottom:633px;}
#t2s_1{left:497px;bottom:632px;}
#t2t_1{left:528px;bottom:632px;}
#t2u_1{left:586px;bottom:632px;}
#t2v_1{left:666px;bottom:632px;}
#t2w_1{left:800px;bottom:632px;word-spacing:7px;}
#t2x_1{left:132px;bottom:620px;}
#t2y_1{left:238px;bottom:621px;}
#t2z_1{left:405px;bottom:620px;}
#t30_1{left:438px;bottom:620px;}
#t31_1{left:414px;bottom:621px;}
#t32_1{left:221px;bottom:607px;}
#t33_1{left:132px;bottom:608px;}
#t34_1{left:50px;bottom:583px;}
#t35_1{left:132px;bottom:583px;}
#t36_1{left:238px;bottom:583px;}
#t37_1{left:497px;bottom:583px;}
#t38_1{left:528px;bottom:583px;}
#t39_1{left:586px;bottom:583px;}
#t3a_1{left:666px;bottom:583px;}
#t3b_1{left:800px;bottom:583px;word-spacing:7px;}
#t3c_1{left:132px;bottom:570px;}
#t3d_1{left:238px;bottom:571px;}
#t3e_1{left:405px;bottom:570px;}
#t3f_1{left:438px;bottom:570px;}
#t3g_1{left:414px;bottom:571px;}
#t3h_1{left:221px;bottom:557px;}
#t3i_1{left:132px;bottom:558px;}
#t3j_1{left:50px;bottom:533px;}
#t3k_1{left:132px;bottom:533px;}
#t3l_1{left:238px;bottom:534px;}
#t3m_1{left:497px;bottom:533px;}
#t3n_1{left:528px;bottom:533px;}
#t3o_1{left:586px;bottom:533px;}
#t3p_1{left:666px;bottom:533px;}
#t3q_1{left:812px;bottom:533px;letter-spacing:17.3px;}
#t3r_1{left:132px;bottom:521px;}
#t3s_1{left:238px;bottom:521px;}
#t3t_1{left:405px;bottom:521px;}
#t3u_1{left:438px;bottom:521px;}
#t3v_1{left:414px;bottom:521px;}
#t3w_1{left:221px;bottom:508px;}
#t3x_1{left:132px;bottom:508px;}
#t3y_1{left:50px;bottom:483px;}
#t3z_1{left:132px;bottom:483px;}
#t40_1{left:238px;bottom:484px;}
#t41_1{left:497px;bottom:483px;}
#t42_1{left:528px;bottom:483px;}
#t43_1{left:586px;bottom:483px;}
#t44_1{left:666px;bottom:483px;}
#t45_1{left:800px;bottom:483px;word-spacing:7px;}
#t46_1{left:132px;bottom:471px;}
#t47_1{left:238px;bottom:471px;}
#t48_1{left:405px;bottom:471px;}
#t49_1{left:438px;bottom:471px;}
#t4a_1{left:414px;bottom:471px;}
#t4b_1{left:221px;bottom:458px;}
#t4c_1{left:132px;bottom:458px;}
#t4d_1{left:50px;bottom:433px;}
#t4e_1{left:132px;bottom:433px;}
#t4f_1{left:238px;bottom:434px;}
#t4g_1{left:497px;bottom:433px;}
#t4h_1{left:528px;bottom:433px;}
#t4i_1{left:586px;bottom:433px;}
#t4j_1{left:666px;bottom:433px;}
#t4k_1{left:812px;bottom:433px;letter-spacing:17.3px;}
#t4l_1{left:132px;bottom:421px;}
#t4m_1{left:238px;bottom:421px;}
#t4n_1{left:405px;bottom:421px;}
#t4o_1{left:438px;bottom:421px;}
#t4p_1{left:414px;bottom:421px;}
#t4q_1{left:221px;bottom:408px;}
#t4r_1{left:132px;bottom:408px;}
#t4s_1{left:50px;bottom:383px;}
#t4t_1{left:132px;bottom:383px;}
#t4u_1{left:238px;bottom:384px;}
#t4v_1{left:497px;bottom:383px;}
#t4w_1{left:528px;bottom:383px;}
#t4x_1{left:586px;bottom:383px;}
#t4y_1{left:666px;bottom:383px;}
#t4z_1{left:812px;bottom:383px;letter-spacing:17.3px;}
#t50_1{left:132px;bottom:371px;}
#t51_1{left:238px;bottom:372px;}
#t52_1{left:405px;bottom:371px;}
#t53_1{left:438px;bottom:371px;}
#t54_1{left:414px;bottom:372px;}
#t55_1{left:221px;bottom:358px;}
#t56_1{left:132px;bottom:359px;}
#t57_1{left:50px;bottom:334px;}
#t58_1{left:132px;bottom:334px;}
#t59_1{left:238px;bottom:334px;}
#t5a_1{left:497px;bottom:334px;}
#t5b_1{left:528px;bottom:334px;}
#t5c_1{left:586px;bottom:334px;}
#t5d_1{left:666px;bottom:334px;}
#t5e_1{left:800px;bottom:334px;word-spacing:7px;}
#t5f_1{left:132px;bottom:321px;}
#t5g_1{left:238px;bottom:322px;}
#t5h_1{left:405px;bottom:321px;}
#t5i_1{left:438px;bottom:321px;}
#t5j_1{left:414px;bottom:322px;}
#t5k_1{left:221px;bottom:308px;}
#t5l_1{left:132px;bottom:309px;}
#t5m_1{left:50px;bottom:284px;}
#t5n_1{left:132px;bottom:284px;}
#t5o_1{left:238px;bottom:285px;}
#t5p_1{left:497px;bottom:284px;}
#t5q_1{left:528px;bottom:284px;}
#t5r_1{left:586px;bottom:284px;}
#t5s_1{left:666px;bottom:284px;}
#t5t_1{left:794px;bottom:284px;word-spacing:7px;}
#t5u_1{left:132px;bottom:272px;}
#t5v_1{left:238px;bottom:271px;}
#t5w_1{left:405px;bottom:272px;}
#t5x_1{left:438px;bottom:272px;}
#t5y_1{left:414px;bottom:272px;}
#t5z_1{left:221px;bottom:258px;}
#t60_1{left:132px;bottom:259px;}

.s1{
	FONT-SIZE: 42.8px;
	FONT-FAMILY: Helvetica, Arial, sans-serif;
	color: rgb(0,0,0);
}

.s2{
	FONT-SIZE: 55px;
	FONT-FAMILY: MyriadPro-Bold_9f;
	color: rgb(0,0,0);
}

.s3{
	FONT-SIZE: 55px;
	FONT-FAMILY: MyriadPro-Regular_9g;
	color: rgb(0,0,0);
}

.s4{
	FONT-SIZE: 122.2px;
	FONT-FAMILY: MyriadPro-Light_9h;
	color: rgb(0,0,0);
}

.s5{
	FONT-SIZE: 48.9px;
	FONT-FAMILY: MyriadPro-Regular_9g;
	color: rgb(0,0,0);
}

.s6{
	FONT-SIZE: 42.8px;
	FONT-FAMILY: MyriadPro-Bold_9f;
	color: rgb(0,0,0);
}

</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts1" type="text/css" >

@font-face {
	font-family: MyriadPro-Bold_9f;
	src: url("fonts/MyriadPro-Bold_9f.woff") format("woff");
}

@font-face {
	font-family: MyriadPro-Light_9h;
	src: url("fonts/MyriadPro-Light_9h.woff") format("woff");
}

@font-face {
	font-family: MyriadPro-Regular_9g;
	src: url("fonts/MyriadPro-Regular_9g.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg1Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg1" style="-webkit-user-select: none;"><object width="909" height="1286" data="1/1.svg" type="image/svg+xml" id="pdf1" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div id="t1_1" class="t s1">Distrelec Group AG, Grabenstrasse 6, CH-8006 Nänikon, Switzerland </div>
<div id="t2_1" class="t s1">MwSt-Nr.: CHE-116.346.605 MWST </div>
<div id="t3_1" class="t s1">Phone:+41 44 944 99 11 </div>
<div id="t4_1" class="t s1">Fax:+41 44 94499 88 </div>
<div id="t5_1" class="t s1">Email:accounting@distrelec.com </div>
<div id="t6_1" class="t s1">Für Zahlungen in CHF: </div>
<div id="t7_1" class="t s1">Credit Suisse AG </div>
<div id="t8_1" class="t s1">IBAN:CH0504835127231471000  SWIFT: CRESCHZZ30A </div>
<div id="t9_1" class="t s1">Für Zahlungen in anderen Währungen: Deutsche Bank </div>
<div id="ta_1" class="t s1">IBAN: DE40690700320041348400 </div>
<div id="tb_1" class="t s1">SWIFT: DEUTDEHBXXX </div>
<div id="tc_1" class="t s2">Lieferadresse</div>
<div id="td_1" class="t s3">Sly GmbH</div>
<div id="te_1" class="t s3">Binzmühlestrasse 99</div>
<div id="tf_1" class="t s3">8050</div>
<div id="tg_1" class="t s3">Zürich</div>
<div id="th_1" class="t s3">Schweiz</div>
<div id="ti_1" class="t s4">Lieferschein</div>
<div id="tj_1" class="t s5">1 </div>
<div id="tk_1" class="t s5">1 ( 1 </div>
<div id="tl_1" class="t s5">1)</div>
<div id="tm_1" class="t s3">85782083</div>
<div id="tn_1" class="t s2">Lieferschein Nr.</div>
<div id="to_1" class="t s3">23.07.2018</div>
<div id="tp_1" class="t s2">Lieferdatum</div>
<div id="tq_1" class="t s3">1577906</div>
<div id="tr_1" class="t s2">Kundennummer</div>
<div id="ts_1" class="t s3">Distrelec Personal</div>
<div id="tt_1" class="t s2">Kundenname</div>
<div id="tu_1" class="t s3">WEBSHOP ORDER</div>
<div id="tv_1" class="t s2">Referenz</div>
<div id="tw_1" class="t s3">1002649596</div>
<div id="tx_1" class="t s2">Unsere Auftragsnummer</div>
<div id="ty_1" class="t s3">23.07.2018</div>
<div id="tz_1" class="t s2">Bestelldatum</div>
<div id="t10_1" class="t s3">René Hämmerli</div>
<div id="t11_1" class="t s2">Bestellt durch</div>
<div id="t12_1" class="t s3">1329274</div>
<div id="t13_1" class="t s2">Kundennummer</div>
<div id="t14_1" class="t s2">Konditionen</div>
<div id="t15_1" class="t s3">Standard</div>
<div id="t16_1" class="t s3">Lieferbedingungen</div>
<div id="t17_1" class="t s3">DAP Geliefert benannter Ort</div>
<div id="t18_1" class="t s3">Incoterms:</div>
<div id="t19_1" class="t s3">Zürich</div>
<div id="t1a_1" class="t s3">/</div>
<div id="t1b_1" class="t s3">10</div>
<div id="t1c_1" class="t s3">Anzahl Pos:</div>
<div id="t1d_1" class="t s2">Gewicht (Brutto/Netto) - Volumen</div>
<div id="t1e_1" class="t s3">2.534</div>
<div id="t1f_1" class="t s3">Bruttogewicht</div>
<div id="t1g_1" class="t s3">KG</div>
<div id="t1h_1" class="t s3">2.534</div>
<div id="t1i_1" class="t s3">Netto Gewicht</div>
<div id="t1j_1" class="t s3">KG</div>
<div id="t1k_1" class="t s3">8.438</div>
<div id="t1l_1" class="t s3">Volumen</div>
<div id="t1m_1" class="t s3">CDM</div>
<div id="t1n_1" class="t s6">Pos</div>
<div id="t1o_1" class="t s6">Artikelnr</div>
<div id="t1p_1" class="t s6">Beschreibung</div>
<div id="t1q_1" class="t s6">Bestellmenge</div>
<div id="t1r_1" class="t s6">Liefermenge</div>
<div id="t1s_1" class="t s6">Offene </div>
<div id="t1t_1" class="t s6">Bestellmenge</div>
<div id="t1u_1" class="t s6">Bruttogewicht</div>
<div id="t1v_1" class="t s5">000010</div>
<div id="t1w_1" class="t s5">300-87-165</div>
<div id="t1x_1" class="t s5">Steckdosenleiste/7xTyp 13/2 m</div>
<div id="t1y_1" class="t s5">1</div>
<div id="t1z_1" class="t s5">ST</div>
<div id="t20_1" class="t s5">1</div>
<div id="t21_1" class="t s5">0</div>
<div id="t22_1" class="t s5">362 G</div>
<div id="t23_1" class="t s5">Herstellartikelnr.:</div>
<div id="t24_1" class="t s5">Brennenstuhl</div>
<div id="t25_1" class="t s5">/</div>
<div id="t26_1" class="t s5">1157922</div>
<div id="t27_1" class="t s5">Teil:</div>
<div id="t28_1" class="t s5">279295</div>
<div id="t29_1" class="t s5">Alte Distrelec Nr.</div>
<div id="t2a_1" class="t s5">000020</div>
<div id="t2b_1" class="t s5">110-42-750</div>
<div id="t2c_1" class="t s5">Charger NiZn</div>
<div id="t2d_1" class="t s5">1</div>
<div id="t2e_1" class="t s5">ST</div>
<div id="t2f_1" class="t s5">1</div>
<div id="t2g_1" class="t s5">0</div>
<div id="t2h_1" class="t s5">151 G</div>
<div id="t2i_1" class="t s5">Herstellartikelnr.:</div>
<div id="t2j_1" class="t s5">Ansmann Accu-technik</div>
<div id="t2k_1" class="t s5">/</div>
<div id="t2l_1" class="t s5">NIZN CHARGER</div>
<div id="t2m_1" class="t s5">Teil:</div>
<div id="t2n_1" class="t s5">970395</div>
<div id="t2o_1" class="t s5">Alte Distrelec Nr.</div>
<div id="t2p_1" class="t s5">000030</div>
<div id="t2q_1" class="t s5">300-87-183</div>
<div id="t2r_1" class="t s5">Steckdosenleiste/6xTyp 13/1.5 m</div>
<div id="t2s_1" class="t s5">1</div>
<div id="t2t_1" class="t s5">ST</div>
<div id="t2u_1" class="t s5">1</div>
<div id="t2v_1" class="t s5">0</div>
<div id="t2w_1" class="t s5">290 G</div>
<div id="t2x_1" class="t s5">Herstellartikelnr.:</div>
<div id="t2y_1" class="t s5">Brennenstuhl</div>
<div id="t2z_1" class="t s5">/</div>
<div id="t30_1" class="t s5">1159462826</div>
<div id="t31_1" class="t s5">Teil:</div>
<div id="t32_1" class="t s5">279313</div>
<div id="t33_1" class="t s5">Alte Distrelec Nr.</div>
<div id="t34_1" class="t s5">000040</div>
<div id="t35_1" class="t s5">300-87-184</div>
<div id="t36_1" class="t s5">Steckdosenleiste/8xTyp 13/1.5 m</div>
<div id="t37_1" class="t s5">1</div>
<div id="t38_1" class="t s5">ST</div>
<div id="t39_1" class="t s5">1</div>
<div id="t3a_1" class="t s5">0</div>
<div id="t3b_1" class="t s5">320 G</div>
<div id="t3c_1" class="t s5">Herstellartikelnr.:</div>
<div id="t3d_1" class="t s5">Brennenstuhl</div>
<div id="t3e_1" class="t s5">/</div>
<div id="t3f_1" class="t s5">1159462828</div>
<div id="t3g_1" class="t s5">Teil:</div>
<div id="t3h_1" class="t s5">279314</div>
<div id="t3i_1" class="t s5">Alte Distrelec Nr.</div>
<div id="t3j_1" class="t s5">000050</div>
<div id="t3k_1" class="t s5">300-62-434</div>
<div id="t3l_1" class="t s5">NiMH Akku HR03/AAA 1.2V 800mAh VE=Packu</div>
<div id="t3m_1" class="t s5">2</div>
<div id="t3n_1" class="t s5">PAK</div>
<div id="t3o_1" class="t s5">2</div>
<div id="t3p_1" class="t s5">0</div>
<div id="t3q_1" class="t s5">2G</div>
<div id="t3r_1" class="t s5">Herstellartikelnr.:</div>
<div id="t3s_1" class="t s5">Energizer</div>
<div id="t3t_1" class="t s5">/</div>
<div id="t3u_1" class="t s5">EXTREME AAA 800MAH 4P</div>
<div id="t3v_1" class="t s5">Teil:</div>
<div id="t3w_1" class="t s5">255889</div>
<div id="t3x_1" class="t s5">Alte Distrelec Nr.</div>
<div id="t3y_1" class="t s5">000060</div>
<div id="t3z_1" class="t s5">300-87-182</div>
<div id="t40_1" class="t s5">Steckdosenleiste/4xTyp 13/1.5 m</div>
<div id="t41_1" class="t s5">1</div>
<div id="t42_1" class="t s5">ST</div>
<div id="t43_1" class="t s5">1</div>
<div id="t44_1" class="t s5">0</div>
<div id="t45_1" class="t s5">260 G</div>
<div id="t46_1" class="t s5">Herstellartikelnr.:</div>
<div id="t47_1" class="t s5">Brennenstuhl</div>
<div id="t48_1" class="t s5">/</div>
<div id="t49_1" class="t s5">1159462824</div>
<div id="t4a_1" class="t s5">Teil:</div>
<div id="t4b_1" class="t s5">279312</div>
<div id="t4c_1" class="t s5">Alte Distrelec Nr.</div>
<div id="t4d_1" class="t s5">000070</div>
<div id="t4e_1" class="t s5">300-62-435</div>
<div id="t4f_1" class="t s5">NiMH Akku HR6/AA 1.2V 2000mAh VE=Packun</div>
<div id="t4g_1" class="t s5">2</div>
<div id="t4h_1" class="t s5">PAK</div>
<div id="t4i_1" class="t s5">2</div>
<div id="t4j_1" class="t s5">0</div>
<div id="t4k_1" class="t s5">2G</div>
<div id="t4l_1" class="t s5">Herstellartikelnr.:</div>
<div id="t4m_1" class="t s5">Energizer</div>
<div id="t4n_1" class="t s5">/</div>
<div id="t4o_1" class="t s5">POWERPLUS AA 2000MAH 4P</div>
<div id="t4p_1" class="t s5">Teil:</div>
<div id="t4q_1" class="t s5">255890</div>
<div id="t4r_1" class="t s5">Alte Distrelec Nr.</div>
<div id="t4s_1" class="t s5">000080</div>
<div id="t4t_1" class="t s5">110-81-164</div>
<div id="t4u_1" class="t s5">NiZn Rechargeable Battery 550mAh 1.65 V/</div>
<div id="t4v_1" class="t s5">1</div>
<div id="t4w_1" class="t s5">PAK</div>
<div id="t4x_1" class="t s5">1</div>
<div id="t4y_1" class="t s5">0</div>
<div id="t4z_1" class="t s5">1G</div>
<div id="t50_1" class="t s5">Herstellartikelnr.:</div>
<div id="t51_1" class="t s5">Ansmann Accu-technik</div>
<div id="t52_1" class="t s5">/</div>
<div id="t53_1" class="t s5">NIZN AAA 900MAH SHRINK PAC</div>
<div id="t54_1" class="t s5">Teil:</div>
<div id="t55_1" class="t s5">971134</div>
<div id="t56_1" class="t s5">Alte Distrelec Nr.</div>
<div id="t57_1" class="t s5">000090</div>
<div id="t58_1" class="t s5">110-81-163</div>
<div id="t59_1" class="t s5">NiZn Rechargeable Battery 1500mAh 1.65 V</div>
<div id="t5a_1" class="t s5">1</div>
<div id="t5b_1" class="t s5">PAK</div>
<div id="t5c_1" class="t s5">1</div>
<div id="t5d_1" class="t s5">0</div>
<div id="t5e_1" class="t s5">100 G</div>
<div id="t5f_1" class="t s5">Herstellartikelnr.:</div>
<div id="t5g_1" class="t s5">Ansmann Accu-technik</div>
<div id="t5h_1" class="t s5">/</div>
<div id="t5i_1" class="t s5">NIZN AA 1500MAH SHRINK PAC</div>
<div id="t5j_1" class="t s5">Teil:</div>
<div id="t5k_1" class="t s5">971133</div>
<div id="t5l_1" class="t s5">Alte Distrelec Nr.</div>
<div id="t5m_1" class="t s5">000100</div>
<div id="t5n_1" class="t s5">300-36-451</div>
<div id="t5o_1" class="t s5">Monitorkabel 7.5m anthrazit</div>
<div id="t5p_1" class="t s5">2</div>
<div id="t5q_1" class="t s5">ST</div>
<div id="t5r_1" class="t s5">2</div>
<div id="t5s_1" class="t s5">0</div>
<div id="t5t_1" class="t s5">1046 G</div>
<div id="t5u_1" class="t s5">Herstellartikelnr.:</div>
<div id="t5v_1" class="t s5">KÖNIG</div>
<div id="t5w_1" class="t s5">/</div>
<div id="t5x_1" class="t s5">KNV34000E75</div>
<div id="t5y_1" class="t s5">Teil:</div>
<div id="t5z_1" class="t s5">225875</div>
<div id="t60_1" class="t s5">Alte Distrelec Nr.</div>

<!-- End text definitions -->


</div>
</body>
</html>
`,
  BaderGruppe: `

<!DOCTYPE html >
<html lang="en">
<head>
<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
<meta charset="utf-8" />
</head>

<body style="margin: 0;">

<div id="p1" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
	-webkit-transform: scale(0.25);
	-ms-transform: scale(0.25);
	transform: scale(0.25);
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_1{left:520px;bottom:1011px;letter-spacing:-0.3px;}
#t2_1{left:520px;bottom:924px;letter-spacing:-0.6px;}
#t3_1{left:632px;bottom:945px;}
#t4_1{left:520px;bottom:989px;letter-spacing:0.2px;}
#t5_1{left:632px;bottom:988px;}
#t6_1{left:520px;bottom:859px;letter-spacing:-0.4px;}
#t7_1{left:632px;bottom:858px;}
#t8_1{left:520px;bottom:1046px;}
#t9_1{left:645px;bottom:946px;letter-spacing:-0.4px;word-spacing:-0.1px;}
#ta_1{left:645px;bottom:925px;letter-spacing:0.4px;word-spacing:-0.8px;}
#tb_1{left:520px;bottom:946px;letter-spacing:0.3px;}
#tc_1{left:632px;bottom:923px;}
#td_1{left:108px;bottom:1065px;letter-spacing:-0.5px;word-spacing:0.2px;}
#te_1{left:632px;bottom:1009px;}
#tf_1{left:632px;bottom:966px;}
#tg_1{left:520px;bottom:968px;letter-spacing:-0.1px;}
#th_1{left:754px;bottom:1076px;letter-spacing:-0.6px;}
#ti_1{left:840px;bottom:1076px;letter-spacing:17.9px;word-spacing:-14.7px;}
#tj_1{left:82px;bottom:705px;letter-spacing:-0.3px;}
#tk_1{left:143px;bottom:705px;letter-spacing:-0.4px;}
#tl_1{left:238px;bottom:705px;letter-spacing:0.1px;}
#tm_1{left:238px;bottom:727px;letter-spacing:0.5px;}
#tn_1{left:546px;bottom:705px;letter-spacing:-0.6px;}
#to_1{left:778px;bottom:705px;}
#tp_1{left:645px;bottom:1009px;letter-spacing:0.2px;}
#tq_1{left:645px;bottom:860px;letter-spacing:0.5px;}
#tr_1{left:645px;bottom:800px;letter-spacing:0.1px;word-spacing:-0.5px;}
#ts_1{left:520px;bottom:803px;letter-spacing:-0.3px;}
#tt_1{left:632px;bottom:802px;}
#tu_1{left:108px;bottom:1029px;word-spacing:-0.5px;}
#tv_1{left:108px;bottom:1007px;letter-spacing:0.2px;word-spacing:-0.8px;}
#tw_1{left:108px;bottom:985px;word-spacing:-0.6px;}
#tx_1{left:520px;bottom:903px;letter-spacing:-0.3px;}
#ty_1{left:645px;bottom:903px;letter-spacing:0.4px;word-spacing:-0.8px;}
#tz_1{left:632px;bottom:901px;}
#t10_1{left:520px;bottom:881px;}
#t11_1{left:645px;bottom:881px;letter-spacing:-0.1px;}
#t12_1{left:632px;bottom:880px;}
#t13_1{left:675px;bottom:705px;letter-spacing:1.3px;}
#t14_1{left:645px;bottom:822px;letter-spacing:0.6px;}
#t15_1{left:520px;bottom:825px;letter-spacing:0.1px;}
#t16_1{left:632px;bottom:823px;}
#t17_1{left:87px;bottom:838px;letter-spacing:0.1px;word-spacing:-0.5px;}
#t18_1{left:87px;bottom:822px;word-spacing:-0.4px;}
#t19_1{left:87px;bottom:806px;letter-spacing:-0.3px;word-spacing:-0.1px;}
#t1a_1{left:87px;bottom:790px;letter-spacing:-0.2px;word-spacing:-0.2px;}
#t1b_1{left:645px;bottom:986px;letter-spacing:0.3px;}
#t1c_1{left:190px;bottom:640px;letter-spacing:-0.5px;}
#t1d_1{left:827px;bottom:640px;}
#t1e_1{left:238px;bottom:620px;letter-spacing:0.4px;}
#t1f_1{left:238px;bottom:603px;letter-spacing:0.2px;word-spacing:-0.8px;}
#t1g_1{left:238px;bottom:432px;letter-spacing:0.1px;}
#t1h_1{left:82px;bottom:640px;letter-spacing:-0.2px;}
#t1i_1{left:177px;bottom:640px;}
#t1j_1{left:238px;bottom:640px;letter-spacing:0.2px;}
#t1k_1{left:542px;bottom:640px;}
#t1l_1{left:785px;bottom:640px;}
#t1m_1{left:368px;bottom:432px;letter-spacing:0.1px;}
#t1n_1{left:584px;bottom:640px;}
#t1o_1{left:385px;bottom:588px;letter-spacing:-0.7px;}
#t1p_1{left:238px;bottom:588px;letter-spacing:-0.3px;}
#t1q_1{left:385px;bottom:572px;letter-spacing:-0.2px;}
#t1r_1{left:385px;bottom:557px;}
#t1s_1{left:238px;bottom:572px;letter-spacing:-0.8px;}
#t1t_1{left:238px;bottom:557px;letter-spacing:-0.6px;word-spacing:-2.7px;}
#t1u_1{left:680px;bottom:640px;}
#t1v_1{left:238px;bottom:534px;letter-spacing:-0.4px;word-spacing:-3px;}
#t1w_1{left:407px;bottom:533px;}
#t1x_1{left:407px;bottom:513px;letter-spacing:-0.1px;word-spacing:-0.3px;}
#t1y_1{left:407px;bottom:498px;letter-spacing:-0.2px;word-spacing:-0.2px;}
#t1z_1{left:693px;bottom:640px;letter-spacing:-0.5px;}
#t20_1{left:407px;bottom:482px;letter-spacing:-0.6px;word-spacing:0.2px;}
#t21_1{left:407px;bottom:467px;letter-spacing:0.6px;}
#t22_1{left:238px;bottom:514px;letter-spacing:-0.5px;word-spacing:-2.9px;}
#t23_1{left:87px;bottom:284px;letter-spacing:-0.6px;word-spacing:-0.3px;}
#t24_1{left:87px;bottom:267px;letter-spacing:-0.9px;word-spacing:0.6px;}
#t25_1{left:87px;bottom:249px;letter-spacing:-0.7px;word-spacing:-0.1px;}
#t26_1{left:87px;bottom:232px;letter-spacing:-0.2px;word-spacing:-0.1px;}
#t27_1{left:87px;bottom:373px;letter-spacing:-0.3px;word-spacing:-0.2px;}
#t28_1{left:87px;bottom:357px;letter-spacing:0.6px;word-spacing:-1px;}
#t29_1{left:87px;bottom:342px;letter-spacing:-0.1px;word-spacing:-0.3px;}
#t2a_1{left:87px;bottom:391px;letter-spacing:0.1px;}
#t2b_1{left:87px;bottom:311px;letter-spacing:0.4px;}
#t2c_1{left:87px;bottom:327px;letter-spacing:0.2px;word-spacing:-0.6px;}
#t2d_1{left:346px;bottom:373px;letter-spacing:-0.3px;word-spacing:-0.1px;}
#t2e_1{left:346px;bottom:357px;letter-spacing:-0.1px;word-spacing:-0.3px;}
#t2f_1{left:346px;bottom:342px;letter-spacing:-0.1px;word-spacing:-0.3px;}
#t2g_1{left:346px;bottom:391px;letter-spacing:0.1px;}
#t2h_1{left:346px;bottom:311px;letter-spacing:0.4px;}
#t2i_1{left:346px;bottom:327px;letter-spacing:0.3px;word-spacing:-0.7px;}

.s1_1{
	FONT-SIZE: 54.3px;
	FONT-FAMILY: Arial-BoldMT_8;
	color: rgb(0,0,0);
}

.s2_1{
	FONT-SIZE: 60.1px;
	FONT-FAMILY: Arial-BoldMT_8;
	color: rgb(0,0,0);
}

.s3_1{
	FONT-SIZE: 96.8px;
	FONT-FAMILY: Arial-BoldMT_8;
	color: rgb(0,0,0);
}

.s4_1{
	FONT-SIZE: 54.3px;
	FONT-FAMILY: ArialMT_d;
	color: rgb(0,0,0);
}

.s5_1{
	FONT-SIZE: 48.4px;
	FONT-FAMILY: ArialMT_d;
	color: rgb(0,0,0);
}

.s6_1{
	FONT-SIZE: 60.1px;
	FONT-FAMILY: ArialMT_d;
	color: rgb(0,0,0);
}

</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts1" type="text/css" >

@font-face {
	font-family: Arial-BoldMT_8;
	src: url("fonts/Arial-BoldMT_8.woff") format("woff");
}

@font-face {
	font-family: ArialMT_d;
	src: url("fonts/ArialMT_d.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg1Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg1" style="-webkit-user-select: none;"><object width="909" height="1286" data="1/1.svg" type="image/svg+xml" id="pdf1" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div id="t1_1" class="t s1_1">Bestell-Nr.</div>
<div id="t2_1" class="t s1_1">Telefon-Nr.</div>
<div id="t3_1" class="t s2_1">:</div>
<div id="t4_1" class="t s1_1">Datum</div>
<div id="t5_1" class="t s2_1">:</div>
<div id="t6_1" class="t s1_1">USt.-Ident.-Nr.</div>
<div id="t7_1" class="t s2_1">:</div>
<div id="t8_1" class="t s3_1">Bestellung</div>
<div id="t9_1" class="t s4_1">Kathrin Gollsch</div>
<div id="ta_1" class="t s4_1">+49 (3528) 4827-40</div>
<div id="tb_1" class="t s1_1">Sachbearbeiter</div>
<div id="tc_1" class="t s2_1">:</div>
<div id="td_1" class="t s5_1">Alvo GmbH, Hauptstrasse 101, 01454 Wachau/Dresden</div>
<div id="te_1" class="t s2_1">:</div>
<div id="tf_1" class="t s2_1">:</div>
<div id="tg_1" class="t s1_1">Kunden-Nr.</div>
<div id="th_1" class="t s6_1">Seite</div>
<div id="ti_1" class="t s6_1">1/ 2</div>
<div id="tj_1" class="t s6_1">Pos.</div>
<div id="tk_1" class="t s6_1">Menge/ME</div>
<div id="tl_1" class="t s6_1">Bezeichnung</div>
<div id="tm_1" class="t s6_1">Artikelnummer</div>
<div id="tn_1" class="t s6_1">Preis/ME</div>
<div id="to_1" class="t s6_1">Betrag</div>
<div id="tp_1" class="t s2_1">22714516</div>
<div id="tq_1" class="t s4_1">DE815094275</div>
<div id="tr_1" class="t s4_1">+49 (711) 489992-69</div>
<div id="ts_1" class="t s1_1">Ihre Fax-Nr.:</div>
<div id="tt_1" class="t s2_1">:</div>
<div id="tu_1" class="t s6_1">Angst + Pfister GmbH</div>
<div id="tv_1" class="t s6_1">Schulze-Delitzsch-Straße 38</div>
<div id="tw_1" class="t s6_1">70565 Stuttgart</div>
<div id="tx_1" class="t s1_1">Fax-Nr.</div>
<div id="ty_1" class="t s4_1">+49 (3528) 4827-30</div>
<div id="tz_1" class="t s2_1">:</div>
<div id="t10_1" class="t s1_1">E-Mail:</div>
<div id="t11_1" class="t s4_1">kathrin.gollsch@badergruppe.com</div>
<div id="t12_1" class="t s2_1">:</div>
<div id="t13_1" class="t s6_1">je</div>
<div id="t14_1" class="t s4_1">9090889</div>
<div id="t15_1" class="t s1_1">Lieferanten-Nr:</div>
<div id="t16_1" class="t s2_1">:</div>
<div id="t17_1" class="t s4_1">Warenannahmezeiten ALVO GmbH:</div>
<div id="t18_1" class="t s4_1">Montag-Freitag 7.00 bis 14.45 Uhr</div>
<div id="t19_1" class="t s4_1">Anlieferungen vor den genannten Lieferterminen</div>
<div id="t1a_1" class="t s4_1">werden nur nach vorheriger Freigabe durch uns akzeptiert!</div>
<div id="t1b_1" class="t s4_1">20.12.2018</div>
<div id="t1c_1" class="t s6_1">Stk</div>
<div id="t1d_1" class="t s6_1">EUR</div>
<div id="t1e_1" class="t s6_1">Schlauchtülle</div>
<div id="t1f_1" class="t s6_1">R2" / Ø50</div>
<div id="t1g_1" class="t s6_1">Liefertermin:</div>
<div id="t1h_1" class="t s6_1">1,0</div>
<div id="t1i_1" class="t s6_1">2</div>
<div id="t1j_1" class="t s6_1">00178781</div>
<div id="t1k_1" class="t s6_1">14,68</div>
<div id="t1l_1" class="t s6_1">29,36</div>
<div id="t1m_1" class="t s6_1">04.01.2019</div>
<div id="t1n_1" class="t s6_1">EUR</div>
<div id="t1o_1" class="t s4_1">verzinkt</div>
<div id="t1p_1" class="t s4_1">Oberfläche:</div>
<div id="t1q_1" class="t s4_1">Angst+Pfister</div>
<div id="t1r_1" class="t s4_1">06.5011.9050</div>
<div id="t1s_1" class="t s4_1">Hersteller:</div>
<div id="t1t_1" class="t s4_1">Hersteller ArtikelNr:</div>
<div id="t1u_1" class="t s6_1">1</div>
<div id="t1v_1" class="t s4_1">Ihre Artikelnummer:</div>
<div id="t1w_1" class="t s4_1">06.5011.9050</div>
<div id="t1x_1" class="t s4_1">Schlauchstutzen mit konischem</div>
<div id="t1y_1" class="t s4_1">Aussengewinde, mit 8-kt.</div>
<div id="t1z_1" class="t s6_1">Stk</div>
<div id="t20_1" class="t s4_1">Stahl verzinkt</div>
<div id="t21_1" class="t s4_1">40141734</div>
<div id="t22_1" class="t s4_1">Ihre Artikeldaten:</div>
<div id="t23_1" class="t s5_1">Es gelten ausschließlich unsere Allgemeinen Geschäftsbedingungen.</div>
<div id="t24_1" class="t s5_1">Diese können Sie im Internet unter www.badergruppe.com einsehen.</div>
<div id="t25_1" class="t s5_1">Gerne senden wir Ihnen unsere Allgemeinen Geschäftsbedingungen</div>
<div id="t26_1" class="t s5_1">auch auf Wunsch zu.</div>
<div id="t27_1" class="t s4_1">Alvo GmbH</div>
<div id="t28_1" class="t s4_1">Werk Wachau</div>
<div id="t29_1" class="t s4_1">Hauptstraße 101</div>
<div id="t2a_1" class="t s2_1">Lieferadresse:</div>
<div id="t2b_1" class="t s4_1">DEUTSCHLAND</div>
<div id="t2c_1" class="t s4_1">01454 Wachau / Dresden</div>
<div id="t2d_1" class="t s4_1">Alvo GmbH</div>
<div id="t2e_1" class="t s4_1">Zentrale Buchhaltung Bader Gruppe</div>
<div id="t2f_1" class="t s4_1">Robert-Bosch-Straße 4</div>
<div id="t2g_1" class="t s2_1">Rechnungsadresse:</div>
<div id="t2h_1" class="t s4_1">DEUTSCHLAND</div>
<div id="t2i_1" class="t s4_1">89250 Senden</div>

<!-- End text definitions -->


</div>
</body>
</html>

<!DOCTYPE html >
<html lang="en">
<head>
<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
<meta charset="utf-8" />
</head>

<body style="margin: 0;">

<div id="p2" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
	-webkit-transform: scale(0.25);
	-ms-transform: scale(0.25);
	transform: scale(0.25);
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_2{left:520px;bottom:1042px;}
#t2_2{left:520px;bottom:1011px;letter-spacing:-0.3px;}
#t3_2{left:632px;bottom:1009px;}
#t4_2{left:520px;bottom:989px;letter-spacing:0.2px;}
#t5_2{left:632px;bottom:988px;}
#t6_2{left:754px;bottom:1081px;letter-spacing:-0.6px;}
#t7_2{left:840px;bottom:1081px;letter-spacing:17.9px;word-spacing:-14.7px;}
#t8_2{left:82px;bottom:920px;letter-spacing:-0.3px;}
#t9_2{left:143px;bottom:920px;letter-spacing:-0.4px;}
#ta_2{left:238px;bottom:920px;letter-spacing:0.1px;}
#tb_2{left:238px;bottom:942px;letter-spacing:0.5px;}
#tc_2{left:778px;bottom:920px;}
#td_2{left:645px;bottom:1009px;letter-spacing:0.2px;}
#te_2{left:551px;bottom:920px;letter-spacing:-0.6px;}
#tf_2{left:675px;bottom:920px;letter-spacing:1.3px;}
#tg_2{left:645px;bottom:986px;letter-spacing:0.3px;}
#th_2{left:87px;bottom:877px;letter-spacing:0.3px;}
#ti_2{left:87px;bottom:817px;letter-spacing:-0.6px;}
#tj_2{left:87px;bottom:856px;letter-spacing:-0.2px;}
#tk_2{left:87px;bottom:797px;letter-spacing:-0.5px;}
#tl_2{left:87px;bottom:777px;letter-spacing:-0.2px;}
#tm_2{left:87px;bottom:836px;letter-spacing:-0.2px;}
#tn_2{left:515px;bottom:793px;letter-spacing:0.4px;}
#to_2{left:810px;bottom:854px;}
#tp_2{left:515px;bottom:824px;letter-spacing:-0.1px;word-spacing:-0.4px;}
#tq_2{left:810px;bottom:824px;}
#tr_2{left:810px;bottom:793px;}
#ts_2{left:515px;bottom:854px;}
#tt_2{left:763px;bottom:854px;}
#tu_2{left:771px;bottom:824px;}
#tv_2{left:763px;bottom:793px;}
#tw_2{left:208px;bottom:817px;letter-spacing:0.4px;}
#tx_2{left:208px;bottom:777px;letter-spacing:-0.2px;}
#ty_2{left:208px;bottom:797px;letter-spacing:-0.5px;word-spacing:-0.7px;}
#tz_2{left:208px;bottom:856px;letter-spacing:-0.1px;word-spacing:-0.3px;}
#t10_2{left:247px;bottom:836px;letter-spacing:0.3px;word-spacing:-0.7px;}
#t11_2{left:208px;bottom:836px;letter-spacing:-1.5px;}

.s1{
	FONT-SIZE: 96.8px;
	FONT-FAMILY: Arial-BoldMT_8;
	color: rgb(0,0,0);
}

.s2{
	FONT-SIZE: 54.3px;
	FONT-FAMILY: Arial-BoldMT_8;
	color: rgb(0,0,0);
}

.s3{
	FONT-SIZE: 60.1px;
	FONT-FAMILY: Arial-BoldMT_8;
	color: rgb(0,0,0);
}

.s4{
	FONT-SIZE: 60.1px;
	FONT-FAMILY: ArialMT_d;
	color: rgb(0,0,0);
}

.s5{
	FONT-SIZE: 54.3px;
	FONT-FAMILY: ArialMT_d;
	color: rgb(0,0,0);
}

</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts2" type="text/css" >

@font-face {
	font-family: Arial-BoldMT_8;
	src: url("fonts/Arial-BoldMT_8.woff") format("woff");
}

@font-face {
	font-family: ArialMT_d;
	src: url("fonts/ArialMT_d.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg2Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg2" style="-webkit-user-select: none;"><object width="909" height="1286" data="2/2.svg" type="image/svg+xml" id="pdf2" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div id="t1_2" class="t s1">Bestellung</div>
<div id="t2_2" class="t s2">Bestell-Nr.</div>
<div id="t3_2" class="t s3">:</div>
<div id="t4_2" class="t s2">Datum</div>
<div id="t5_2" class="t s3">:</div>
<div id="t6_2" class="t s4">Seite</div>
<div id="t7_2" class="t s4">2/ 2</div>
<div id="t8_2" class="t s4">Pos.</div>
<div id="t9_2" class="t s4">Menge/ME</div>
<div id="ta_2" class="t s4">Bezeichnung</div>
<div id="tb_2" class="t s4">Artikelnummer</div>
<div id="tc_2" class="t s4">Betrag</div>
<div id="td_2" class="t s3">22714516</div>
<div id="te_2" class="t s4">Preis/ME</div>
<div id="tf_2" class="t s4">je</div>
<div id="tg_2" class="t s5">20.12.2018</div>
<div id="th_2" class="t s2">Konditionen:</div>
<div id="ti_2" class="t s5">Versandart:</div>
<div id="tj_2" class="t s5">Lieferbedingung:</div>
<div id="tk_2" class="t s5">Zahlungsziel:</div>
<div id="tl_2" class="t s5">Zahlungsart:</div>
<div id="tm_2" class="t s5">Incoterm:</div>
<div id="tn_2" class="t s3">Nettowert:</div>
<div id="to_2" class="t s3">EUR</div>
<div id="tp_2" class="t s3">Summe Zuschläge:</div>
<div id="tq_2" class="t s3">EUR</div>
<div id="tr_2" class="t s3">EUR</div>
<div id="ts_2" class="t s3">Warenwert:</div>
<div id="tt_2" class="t s3">29,36</div>
<div id="tu_2" class="t s3">0,00</div>
<div id="tv_2" class="t s3">29,36</div>
<div id="tw_2" class="t s5">Zufuhr</div>
<div id="tx_2" class="t s5">Überweisung</div>
<div id="ty_2" class="t s5">netto 30 Tage</div>
<div id="tz_2" class="t s5">verpackt ab Werk</div>
<div id="t10_2" class="t s5">ab Werk</div>
<div id="t11_2" class="t s5">exw</div>

<!-- End text definitions -->


</div>
</body>
</html>
`,
  GreifVelox: `
<!DOCTYPE html >
<html lang="en">
<head>
<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
<meta charset="utf-8" />
</head>

<body style="margin: 0;">

<div id="p1" style="overflow: hidden; position: relative; background-color: white; width: 907px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
	-webkit-transform: scale(0.25);
	-ms-transform: scale(0.25);
	transform: scale(0.25);
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_1{left:629px;bottom:891px;}
#t2_1{left:488px;bottom:891px;}
#t3_1{left:629px;bottom:913px;}
#t4_1{left:488px;bottom:913px;}
#t5_1{left:629px;bottom:934px;}
#t6_1{left:488px;bottom:934px;}
#t7_1{left:629px;bottom:956px;}
#t8_1{left:488px;bottom:956px;}
#t9_1{left:629px;bottom:978px;}
#ta_1{left:488px;bottom:978px;}
#tb_1{left:629px;bottom:1000px;}
#tc_1{left:488px;bottom:1000px;}
#td_1{left:488px;bottom:1021px;}
#te_1{left:629px;bottom:1021px;}
#tf_1{left:486px;bottom:1046px;}
#tg_1{left:98px;bottom:1081px;}
#th_1{left:99px;bottom:1047px;}
#ti_1{left:99px;bottom:1028px;}
#tj_1{left:99px;bottom:1010px;}
#tk_1{left:98px;bottom:869px;}
#tl_1{left:98px;bottom:852px;}
#tm_1{left:98px;bottom:823px;}
#tn_1{left:129px;bottom:823px;}
#to_1{left:181px;bottom:823px;letter-spacing:0.1px;}
#tp_1{left:282px;bottom:823px;}
#tq_1{left:339px;bottom:823px;}
#tr_1{left:489px;bottom:823px;}
#ts_1{left:539px;bottom:823px;letter-spacing:0.8px;}
#tt_1{left:600px;bottom:823px;}
#tu_1{left:635px;bottom:823px;}
#tv_1{left:681px;bottom:823px;letter-spacing:0.1px;}
#tw_1{left:98px;bottom:806px;letter-spacing:0.1px;}
#tx_1{left:171px;bottom:806px;}
#ty_1{left:206px;bottom:806px;}
#tz_1{left:253px;bottom:806px;}
#t10_1{left:286px;bottom:806px;letter-spacing:0.2px;}
#t11_1{left:427px;bottom:806px;}
#t12_1{left:468px;bottom:806px;}
#t13_1{left:520px;bottom:806px;}
#t14_1{left:554px;bottom:806px;}
#t15_1{left:587px;bottom:806px;}
#t16_1{left:630px;bottom:806px;}
#t17_1{left:695px;bottom:806px;}
#t18_1{left:750px;bottom:806px;}
#t19_1{left:856px;bottom:806px;}
#t1a_1{left:98px;bottom:788px;}
#t1b_1{left:478px;bottom:867px;}
#t1c_1{left:469px;bottom:843px;}
#t1d_1{left:666px;bottom:779px;}
#t1e_1{left:98px;bottom:779px;}
#t1f_1{left:98px;bottom:746px;}
#t1g_1{left:152px;bottom:703px;}
#t1h_1{left:98px;bottom:703px;}
#t1i_1{left:524px;bottom:703px;}
#t1j_1{left:593px;bottom:703px;}
#t1k_1{left:683px;bottom:703px;}
#t1l_1{left:802px;bottom:703px;}
#t1m_1{left:739px;bottom:703px;}
#t1n_1{left:98px;bottom:670px;}
#t1o_1{left:152px;bottom:670px;}
#t1p_1{left:532px;bottom:670px;}
#t1q_1{left:680px;bottom:670px;}
#t1r_1{left:750px;bottom:670px;}
#t1s_1{left:588px;bottom:670px;}
#t1t_1{left:152px;bottom:651px;}
#t1u_1{left:152px;bottom:633px;}
#t1v_1{left:152px;bottom:615px;}
#t1w_1{left:152px;bottom:598px;}
#t1x_1{left:152px;bottom:580px;}
#t1y_1{left:152px;bottom:563px;}
#t1z_1{left:152px;bottom:544px;}
#t20_1{left:152px;bottom:526px;}
#t21_1{left:781px;bottom:507px;}
#t22_1{left:851px;bottom:507px;}
#t23_1{left:152px;bottom:488px;}
#t24_1{left:152px;bottom:470px;}
#t25_1{left:152px;bottom:437px;}
#t26_1{left:852px;bottom:437px;}
#t27_1{left:782px;bottom:437px;}
#t28_1{left:98px;bottom:361px;}
#t29_1{left:218px;bottom:361px;}
#t2a_1{left:98px;bottom:336px;}
#t2b_1{left:164px;bottom:336px;}
#t2c_1{left:180px;bottom:336px;}
#t2d_1{left:98px;bottom:317px;}
#t2e_1{left:101px;bottom:275px;}
#t2f_1{left:217px;bottom:275px;}
#t2g_1{left:217px;bottom:258px;}
#t2h_1{left:217px;bottom:237px;}
#t2i_1{left:369px;bottom:237px;}
#t2j_1{left:436px;bottom:237px;}
#t2k_1{left:611px;bottom:237px;}
#t2l_1{left:657px;bottom:237px;}
#t2m_1{left:436px;bottom:219px;}
#t2n_1{left:28px;bottom:88px;}
#t2o_1{left:238px;bottom:88px;}
#t2p_1{left:431px;bottom:88px;}
#t2q_1{left:651px;bottom:88px;}
#t2r_1{left:733px;bottom:88px;}
#t2s_1{left:737px;bottom:88px;}
#t2t_1{left:28px;bottom:73px;}
#t2u_1{left:238px;bottom:73px;}
#t2v_1{left:431px;bottom:73px;}
#t2w_1{left:651px;bottom:73px;}
#t2x_1{left:28px;bottom:58px;}
#t2y_1{left:91px;bottom:58px;}
#t2z_1{left:95px;bottom:58px;}
#t30_1{left:238px;bottom:58px;}
#t31_1{left:431px;bottom:58px;}
#t32_1{left:651px;bottom:58px;}
#t33_1{left:730px;bottom:58px;}
#t34_1{left:736px;bottom:58px;}
#t35_1{left:28px;bottom:44px;}
#t36_1{left:238px;bottom:44px;}
#t37_1{left:431px;bottom:44px;}
#t38_1{left:651px;bottom:44px;}

.s1{
	FONT-SIZE: 54.7px;
	FONT-FAMILY: Arial_9;
	color: rgb(0,0,0);
}

.s2{
	FONT-SIZE: 53.5px;
	FONT-FAMILY: Arial-Bold_a;
	color: rgb(0,0,0);
}

.s3{
	FONT-SIZE: 64.2px;
	FONT-FAMILY: Arial-Bold_a;
	color: rgb(0,0,0);
}

.s4{
	FONT-SIZE: 33px;
	FONT-FAMILY: Arial_9;
	color: rgb(0,0,0);
}

.s5{
	FONT-SIZE: 57.1px;
	FONT-FAMILY: CourierNew_e;
	color: rgb(255,255,255);
}

.s6{
	FONT-SIZE: 37.6px;
	FONT-FAMILY: CalibriLight_f;
	color: rgb(0,0,0);
}

.s7{
	FONT-SIZE: 37.6px;
	FONT-FAMILY: CalibriLight_g;
	color: rgb(0,0,0);
}

</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts1" type="text/css" >

@font-face {
	font-family: Arial-Bold_a;
	src: url("fonts/Arial-Bold_a.woff") format("woff");
}

@font-face {
	font-family: Arial_9;
	src: url("fonts/Arial_9.woff") format("woff");
}

@font-face {
	font-family: CalibriLight_f;
	src: url("fonts/CalibriLight_f.woff") format("woff");
}

@font-face {
	font-family: CalibriLight_g;
	src: url("fonts/CalibriLight_g.woff") format("woff");
}

@font-face {
	font-family: CourierNew_e;
	src: url("fonts/CourierNew_e.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg1Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg1" style="-webkit-user-select: none;"><object width="907" height="1286" data="1/1.svg" type="image/svg+xml" id="pdf1" style="width:907px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div id="t1_1" class="t s1">t.meineke@greif-velox.de</div>
<div id="t2_1" class="t s1">E-Mail: </div>
<div id="t3_1" class="t s1">+49 451 5303-224</div>
<div id="t4_1" class="t s1">Direktwahl: </div>
<div id="t5_1" class="t s1">Tobias Meineke</div>
<div id="t6_1" class="t s1">Ansprechpartner: </div>
<div id="t7_1" class="t s1">601226</div>
<div id="t8_1" class="t s1">Lieferanten-Nr.: </div>
<div id="t9_1" class="t s1">1/1</div>
<div id="ta_1" class="t s1">Seite: </div>
<div id="tb_1" class="t s1">19.12.2018</div>
<div id="tc_1" class="t s1">Datum: </div>
<div id="td_1" class="t s2">Bestell-Nr.:</div>
<div id="te_1" class="t s2">187118</div>
<div id="tf_1" class="t s3">BESTELLUNG</div>
<div id="tg_1" class="t s4">GREIF-VELOX Maschinenfabrik GmbH • Postfach 121151 • 23532 Lübeck</div>
<div id="th_1" class="t s1">Angst + Pfister GmbH</div>
<div id="ti_1" class="t s1">Schulze-Delitzsch-Str. 38</div>
<div id="tj_1" class="t s1">70565 Stuttgart</div>
<div id="tk_1" class="t s1">Ihr Fon: 0711 489 9920</div>
<div id="tl_1" class="t s1">Ihr Fax: 0711 489 9922569</div>
<div id="tm_1" class="t s1">Es </div>
<div id="tn_1" class="t s1">gelten </div>
<div id="to_1" class="t s1">ausschließlich </div>
<div id="tp_1" class="t s1">unsere </div>
<div id="tq_1" class="t s1">Einkaufsbedingungen. </div>
<div id="tr_1" class="t s1">Diese </div>
<div id="ts_1" class="t s1">können </div>
<div id="tt_1" class="t s1">Sie </div>
<div id="tu_1" class="t s1">unter </div>
<div id="tv_1" class="t s1">https://www.greif-velox.com/agb/ </div>
<div id="tw_1" class="t s1">einsehen. </div>
<div id="tx_1" class="t s1">Wir </div>
<div id="ty_1" class="t s1">bitten </div>
<div id="tz_1" class="t s1">um </div>
<div id="t10_1" class="t s1">Auftragsbestätigung. </div>
<div id="t11_1" class="t s1">Bitte </div>
<div id="t12_1" class="t s1">geben </div>
<div id="t13_1" class="t s1">Sie </div>
<div id="t14_1" class="t s1">auf </div>
<div id="t15_1" class="t s1">allen </div>
<div id="t16_1" class="t s1">Belegen </div>
<div id="t17_1" class="t s1">unsere </div>
<div id="t18_1" class="t s1">Bestellnummer </div>
<div id="t19_1" class="t s1">und </div>
<div id="t1a_1" class="t s1">Ident-Nummern an.</div>
<div id="t1b_1" class="t s5">@@OWNERNAME tmeineke</div>
<div id="t1c_1" class="t s5">@@WARTEN</div>
<div id="t1d_1" class="t s5">@@NUMBER 0711 489 9922569</div>
<div id="t1e_1" class="t s5">@@BETREFF BESTELLUNG 187118</div>
<div id="t1f_1" class="t s1">Hiermit bestellen wir wie folgt:</div>
<div id="t1g_1" class="t s1">Ident-Nr. / Bezeichnung</div>
<div id="t1h_1" class="t s1">Pos.</div>
<div id="t1i_1" class="t s1">Menge</div>
<div id="t1j_1" class="t s1">ME</div>
<div id="t1k_1" class="t s1">Preis</div>
<div id="t1l_1" class="t s1">Gesamtpreis</div>
<div id="t1m_1" class="t s1">PE</div>
<div id="t1n_1" class="t s1">1</div>
<div id="t1o_1" class="t s1">02310449</div>
<div id="t1p_1" class="t s1">4,000</div>
<div id="t1q_1" class="t s1">31,62</div>
<div id="t1r_1" class="t s1">1</div>
<div id="t1s_1" class="t s1">STK</div>
<div id="t1t_1" class="t s1">Gummiholfeder Typ A 0800A</div>
<div id="t1u_1" class="t s1">Fz=320N</div>
<div id="t1v_1" class="t s1">H=110mm</div>
<div id="t1w_1" class="t s1">Dmax=142mm</div>
<div id="t1x_1" class="t s1">D=102mm</div>
<div id="t1y_1" class="t s1">12.2132.0113</div>
<div id="t1z_1" class="t s1">NR,schwarz</div>
<div id="t20_1" class="t s1">Gewicht: 0,68 KG</div>
<div id="t21_1" class="t s1">126,48</div>
<div id="t22_1" class="t s1">EUR</div>
<div id="t23_1" class="t s2">Liefertermin: 04.01.2019, verbindlich eingehend </div>
<div id="t24_1" class="t s1">Interner Vermerk: 10-180918 / 1 / 300</div>
<div id="t25_1" class="t s2">Gesamtbestellwert:</div>
<div id="t26_1" class="t s2">EUR</div>
<div id="t27_1" class="t s2">126,48</div>
<div id="t28_1" class="t s1">Zahlung:</div>
<div id="t29_1" class="t s1">14 Tage 2,00 % Skonto, 30 Tage netto.</div>
<div id="t2a_1" class="t s2">G R E I F </div>
<div id="t2b_1" class="t s2">- </div>
<div id="t2c_1" class="t s2">V E L O X</div>
<div id="t2d_1" class="t s2">Maschinenfabrik GmbH</div>
<div id="t2e_1" class="t s1">Lieferhinweise: </div>
<div id="t2f_1" class="t s1">Wir wünschen keine Eindeckung einer Schadenversicherung / Warentransport- </div>
<div id="t2g_1" class="t s1">versicherung durch die Spedition gem. Ziffer 29.1.2 ADSp (Verbots- /Verzichtskunde).</div>
<div id="t2h_1" class="t s1">WARENANNAHME: </div>
<div id="t2i_1" class="t s1">MO-DO: </div>
<div id="t2j_1" class="t s1">07.00 - 12.00 UHR </div>
<div id="t2k_1" class="t s1">FR: </div>
<div id="t2l_1" class="t s1">07.00 - 12.00 UHR </div>
<div id="t2m_1" class="t s1">12.30 - 15.00 UHR </div>
<div id="t2n_1" class="t s6">GREIF-VELOX Maschinenfabrik GmbH</div>
<div id="t2o_1" class="t s6">Telefon: +49 (0) 451 5303-0 </div>
<div id="t2p_1" class="t s6">Vorsitzender der Geschäftsführung: </div>
<div id="t2q_1" class="t s6">Volksbank Lübeck eG </div>
<div id="t2r_1" class="t s7">│</div>
<div id="t2s_1" class="t s6">BIC: GEN0DEF1HLU </div>
<div id="t2t_1" class="t s6">Kronsforder Landstraße 177 </div>
<div id="t2u_1" class="t s6">Telefax: +49 (0) 451 5303-233 </div>
<div id="t2v_1" class="t s6">Ralf Drews (CEO) </div>
<div id="t2w_1" class="t s6">IBAN: DE58 2309 0142 0030 0007 00 </div>
<div id="t2x_1" class="t s6">D-23560 Lübeck </div>
<div id="t2y_1" class="t s7">│</div>
<div id="t2z_1" class="t s6">Germany </div>
<div id="t30_1" class="t s6">E-Mail: webmaster@greif-velox.de </div>
<div id="t31_1" class="t s6">Registergericht: Amtsgericht Lübeck </div>
<div id="t32_1" class="t s6">Sparkasse zu Lübeck </div>
<div id="t33_1" class="t s7">│</div>
<div id="t34_1" class="t s6">BIC: NOLADE21SPL </div>
<div id="t35_1" class="t s6">USt-ID: DE 812139805 </div>
<div id="t36_1" class="t s6">Internet: www.greif-velox.de </div>
<div id="t37_1" class="t s6">Registernummer: HRB 4103 </div>
<div id="t38_1" class="t s6">IBAN: DE94 2305 0101 0001 0395 28 </div>

<!-- End text definitions -->


</div>
</body>
</html>
`,
  Fasnacht_altered: `
<!DOCTYPE html >
<html lang="en">
<head>
<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
<meta charset="utf-8" />
</head>

<body style="margin: 0;">

<div id="p1" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
	-webkit-transform: scale(0.25);
	-ms-transform: scale(0.25);
	transform: scale(0.25);
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_1{left:87px;bottom:1171px;letter-spacing:-7.6px;}
#t2_1{left:87px;bottom:1148px;letter-spacing:-2.7px;}
#t3_1{left:178px;bottom:1148px;letter-spacing:-5.1px;}
#t4_1{left:87px;bottom:1131px;letter-spacing:-2.4px;word-spacing:-0.8px;}
#t5_1{left:87px;bottom:1031px;letter-spacing:-2.5px;word-spacing:0.3px;}
#t6_1{left:87px;bottom:996px;letter-spacing:-3.7px;word-spacing:0.5px;}
#t7_1{left:87px;bottom:979px;letter-spacing:-3px;word-spacing:-0.1px;}
#t8_1{left:87px;bottom:961px;letter-spacing:-2.9px;word-spacing:7.1px;}
#t9_1{left:87px;bottom:892px;letter-spacing:-2.4px;word-spacing:-0.7px;}
#ta_1{left:238px;bottom:892px;letter-spacing:-2.3px;word-spacing:-0.9px;}
#tb_1{left:87px;bottom:858px;letter-spacing:-2.9px;word-spacing:-0.3px;}
#tc_1{left:238px;bottom:858px;letter-spacing:-3px;}
#td_1{left:477px;bottom:858px;letter-spacing:-1.3px;}
#te_1{left:628px;bottom:858px;letter-spacing:-2.7px;}
#tf_1{left:87px;bottom:841px;letter-spacing:-2.2px;}
#tg_1{left:477px;bottom:841px;letter-spacing:-2.3px;}
#th_1{left:628px;bottom:841px;letter-spacing:-2.6px;word-spacing:-0.6px;}
#ti_1{left:87px;bottom:771px;letter-spacing:-6.4px;}
#tj_1{left:132px;bottom:771px;letter-spacing:2.5px;}
#tk_1{left:173px;bottom:771px;letter-spacing:-4.6px;}
#tl_1{left:238px;bottom:771px;letter-spacing:-2.7px;}
#tm_1{left:347px;bottom:771px;letter-spacing:-5.1px;}

#tn_1{left:600px;bottom:671px;letter-spacing:-11.5px;}

#to_1{left:836px;bottom:771px;letter-spacing:-3.6px;}
#tp_1{left:87px;bottom:737px;}
#tq_1{left:120px;bottom:737px;letter-spacing:-3px;}
#tr_1{left:173px;bottom:737px;letter-spacing:-4px;}
#ts_1{left:238px;bottom:737px;letter-spacing:-2.1px;}
#tt_1{left:347px;bottom:737px;letter-spacing:-4.9px;}

#tu_1{left:579px;bottom:637px;letter-spacing:-2.6px;}

#tv_1{left:823px;bottom:737px;letter-spacing:-2.5px;}
#tw_1{left:347px;bottom:720px;letter-spacing:-2.6px;}

#ty_1{left:447px;bottom:720px;letter-spacing:-2.7px;}

#tz_1{left:87px;bottom:673px;}
#t10_1{left:127px;bottom:673px;letter-spacing:-3px;}
#t11_1{left:173px;bottom:673px;letter-spacing:-4px;}
#t12_1{left:238px;bottom:673px;letter-spacing:-2.1px;}
#t13_1{left:347px;bottom:673px;letter-spacing:-3.6px;word-spacing:0.4px;}

#t14_1{left:588px;bottom:603px;letter-spacing:-2.5px;}

#t15_1{left:831px;bottom:673px;letter-spacing:-2.4px;}
#t16_1{left:347px;bottom:656px;letter-spacing:-1.2px;word-spacing:-1.9px;}
#t17_1{left:447px;bottom:656px;letter-spacing:-2.7px;}
#t18_1{left:87px;bottom:192px;letter-spacing:-1.5px;}
#t19_1{left:756px;bottom:192px;letter-spacing:-8.8px;}
#t1a_1{left:823px;bottom:192px;letter-spacing:-2.7px;}
#t1b_1{left:87px;bottom:175px;letter-spacing:-4.5px;word-spacing:1.3px;}
#t1c_1{left:756px;bottom:175px;letter-spacing:-8.8px;}
#t1d_1{left:831px;bottom:175px;letter-spacing:-2.6px;}
#t1e_1{left:87px;bottom:140px;letter-spacing:-3.3px;word-spacing:0.1px;}
#t1f_1{left:756px;bottom:140px;letter-spacing:-8.7px;}
#t1g_1{left:823px;bottom:140px;letter-spacing:-2.5px;}
#t1h_1{left:87px;bottom:91px;letter-spacing:-5px;word-spacing:2.1px;}
#t1i_1{left:390px;bottom:91px;letter-spacing:-4.6px;word-spacing:1.7px;}
#t1j_1{left:87px;bottom:75px;letter-spacing:-3.1px;word-spacing:0.2px;}
#t1k_1{left:390px;bottom:75px;letter-spacing:-4.5px;}
#t1l_1{left:464px;bottom:75px;letter-spacing:-3.2px;}
#t1m_1{left:87px;bottom:60px;letter-spacing:-2.7px;word-spacing:-0.2px;}
#t1n_1{left:390px;bottom:60px;letter-spacing:-3.2px;}
#t1o_1{left:464px;bottom:60px;letter-spacing:-2.5px;}
#t1p_1{left:87px;bottom:44px;letter-spacing:-3.7px;word-spacing:0.9px;}
#t1q_1{left:390px;bottom:44px;letter-spacing:-3.9px;word-spacing:1.1px;}
#t1r_1{left:464px;bottom:44px;letter-spacing:-5.4px;word-spacing:2.5px;}

.s1{
	FONT-SIZE: 110px;
	FONT-FAMILY: Calibri-Bold_a;
	color: rgb(0,0,0);
}

.s2{
	FONT-SIZE: 61.1px;
	FONT-FAMILY: Calibri_b;
	color: rgb(0,0,0);
}

.s3{
	FONT-SIZE: 61.1px;
	FONT-FAMILY: Calibri-Bold_c;
	color: rgb(0,0,0);
}

.s4{
	FONT-SIZE: 42.8px;
	FONT-FAMILY: Calibri_d;
	color: rgb(0,0,0);
}

.s5{
	FONT-SIZE: 55px;
	FONT-FAMILY: Calibri-Bold_f;
	color: rgb(0,0,0);
}

.s6{
	FONT-SIZE: 55px;
	FONT-FAMILY: Calibri_h;
	color: rgb(0,0,0);
}

</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts1" type="text/css" >

@font-face {
	font-family: Calibri-Bold_a;
	src: url("fonts/Calibri-Bold_a.woff") format("woff");
}

@font-face {
	font-family: Calibri-Bold_c;
	src: url("fonts/Calibri-Bold_c.woff") format("woff");
}

@font-face {
	font-family: Calibri-Bold_f;
	src: url("fonts/Calibri-Bold_f.woff") format("woff");
}

@font-face {
	font-family: Calibri_b;
	src: url("fonts/Calibri_b.woff") format("woff");
}

@font-face {
	font-family: Calibri_d;
	src: url("fonts/Calibri_d.woff") format("woff");
}

@font-face {
	font-family: Calibri_h;
	src: url("fonts/Calibri_h.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg1Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg1" style="-webkit-user-select: none;"><object width="909" height="1286" data="1/1.svg" type="image/svg+xml" id="pdf1" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div id="t1_1" class="t s1">Bestellung</div>
<div id="t2_1" class="t s2">Belegnummer:</div>
<div id="t3_1" class="t s3">BE250933</div>
<div id="t4_1" class="t s2">Datum: 09.01.2019</div>
<div id="t5_1" class="t s4">Fasnacht Dynamics AG, Bollstrasse 61A, CH-3076 Worb</div>
<div id="t6_1" class="t s2">Angst + Pfister AG APSOparts</div>
<div id="t7_1" class="t s2">Thurgauerstrasse 66</div>
<div id="t8_1" class="t s2">8052 Zürich</div>
<div id="t9_1" class="t s2">Unser Kontakt:</div>
<div id="ta_1" class="t s2">Stefan Dällenbach / einkauf@fasnacht.biz</div>
<div id="tb_1" class="t s2">Unsere Kunden-Nr.:</div>
<div id="tc_1" class="t s2">819651</div>
<div id="td_1" class="t s2">Liefertermin:</div>
<div id="te_1" class="t s2">25.01.2019</div>
<div id="tf_1" class="t s2">Kontaktperson:</div>
<div id="tg_1" class="t s2">Zahlungskonditionen:</div>
<div id="th_1" class="t s2">30 Tage netto</div>
<div id="ti_1" class="t s3">Pos</div>
<div id="tj_1" class="t s3">ME</div>
<div id="tk_1" class="t s3">Einheit</div>
<div id="tl_1" class="t s3">Art-Nr.</div>
<div id="tm_1" class="t s3">Bezeichnung</div>
<div id="tn_1" class="t s3">LP</div>
<div id="to_1" class="t s3">Total</div>
<div id="tp_1" class="t s2">1</div>
<div id="tq_1" class="t s2">1000</div>
<div id="tr_1" class="t s2">Stk.</div>
<div id="ts_1" class="t s3">1.1.09</div>
<div id="tt_1" class="t s3">Flachdichtung </div>
<div id="tu_1" class="t s2">34.20</div>
<div id="tv_1" class="t s3">342.00</div>
<div id="tw_1" class="t s2">Ihre Artikel-Nr: </div>
<div id="ty_1" class="t s2">30.1687.0001</div>
<div id="tz_1" class="t s2">2</div>
<div id="t10_1" class="t s2">200</div>
<div id="t11_1" class="t s2">Stk.</div>
<div id="t12_1" class="t s3">1.3.07</div>
<div id="t13_1" class="t s3">Dichtung Farbfilter 1" </div>
<div id="t14_1" class="t s2">0.40</div>
<div id="t15_1" class="t s3">80.00</div>
<div id="t16_1" class="t s2">Ihre Artikel-Nr: </div>
<div id="t17_1" class="t s2">30.1688.0005</div>
<div id="t18_1" class="t s2">Netto-Betrag</div>
<div id="t19_1" class="t s2">CHF</div>
<div id="t1a_1" class="t s2">422.00</div>
<div id="t1b_1" class="t s2">+ 7.70 % MWST auf CHF 422.00</div>
<div id="t1c_1" class="t s2">CHF</div>
<div id="t1d_1" class="t s2">32.50</div>
<div id="t1e_1" class="t s3">Gesamttotal inkl. MWST</div>
<div id="t1f_1" class="t s3">CHF</div>
<div id="t1g_1" class="t s3">454.50</div>
<div id="t1h_1" class="t s5">Fasnacht Dynamics AG</div>
<div id="t1i_1" class="t s5">Credit Suisse AG, CH-3001 Bern</div>
<div id="t1j_1" class="t s6">Bollstrasse 61A; CH-3076</div>
<div id="t1k_1" class="t s5">IBAN: </div>
<div id="t1l_1" class="t s6">CH3804835138091901000</div>
<div id="t1m_1" class="t s6">+41 (0)44 382 10 10</div>
<div id="t1n_1" class="t s5">Konto-Nr.: </div>
<div id="t1o_1" class="t s6">0094-1380919-01</div>
<div id="t1p_1" class="t s6">CHE-491.800.249 MWST</div>
<div id="t1q_1" class="t s5">Swift Code: </div>
<div id="t1r_1" class="t s6">CRESCHZZ80A / Bank Charges: SHA</div>

<!-- End text definitions -->


</div>
</body>
</html>
`,
  Fasnacht: `
<!DOCTYPE html >
<html lang="en">
<head>
<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
<meta charset="utf-8" />
</head>

<body style="margin: 0;">

<div id="p1" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
	-webkit-transform: scale(0.25);
	-ms-transform: scale(0.25);
	transform: scale(0.25);
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_1{left:87px;bottom:1171px;letter-spacing:-7.6px;}
#t2_1{left:87px;bottom:1148px;letter-spacing:-2.7px;}
#t3_1{left:178px;bottom:1148px;letter-spacing:-5.1px;}
#t4_1{left:87px;bottom:1131px;letter-spacing:-2.4px;word-spacing:-0.8px;}
#t5_1{left:87px;bottom:1031px;letter-spacing:-2.5px;word-spacing:0.3px;}
#t6_1{left:87px;bottom:996px;letter-spacing:-3.7px;word-spacing:0.5px;}
#t7_1{left:87px;bottom:979px;letter-spacing:-3px;word-spacing:-0.1px;}
#t8_1{left:87px;bottom:961px;letter-spacing:-2.9px;word-spacing:7.1px;}
#t9_1{left:87px;bottom:892px;letter-spacing:-2.4px;word-spacing:-0.7px;}
#ta_1{left:238px;bottom:892px;letter-spacing:-2.3px;word-spacing:-0.9px;}
#tb_1{left:87px;bottom:858px;letter-spacing:-2.9px;word-spacing:-0.3px;}
#tc_1{left:238px;bottom:858px;letter-spacing:-3px;}
#td_1{left:477px;bottom:858px;letter-spacing:-1.3px;}
#te_1{left:628px;bottom:858px;letter-spacing:-2.7px;}
#tf_1{left:87px;bottom:841px;letter-spacing:-2.2px;}
#tg_1{left:477px;bottom:841px;letter-spacing:-2.3px;}
#th_1{left:628px;bottom:841px;letter-spacing:-2.6px;word-spacing:-0.6px;}
#ti_1{left:87px;bottom:771px;letter-spacing:-6.4px;}
#tj_1{left:132px;bottom:771px;letter-spacing:2.5px;}
#tk_1{left:173px;bottom:771px;letter-spacing:-4.6px;}
#tl_1{left:238px;bottom:771px;letter-spacing:-2.7px;}
#tm_1{left:347px;bottom:771px;letter-spacing:-5.1px;}
#tn_1{left:700px;bottom:771px;letter-spacing:-11.5px;}
#to_1{left:836px;bottom:771px;letter-spacing:-3.6px;}
#tp_1{left:87px;bottom:737px;}
#tq_1{left:120px;bottom:737px;letter-spacing:-3px;}
#tr_1{left:173px;bottom:737px;letter-spacing:-4px;}
#ts_1{left:238px;bottom:737px;letter-spacing:-2.1px;}
#tt_1{left:347px;bottom:737px;letter-spacing:-4.9px;}
#tu_1{left:679px;bottom:737px;letter-spacing:-2.6px;}
#tv_1{left:823px;bottom:737px;letter-spacing:-2.5px;}
#tw_1{left:347px;bottom:720px;letter-spacing:-2.6px;}
#tx_1{left:347px;bottom:702px;letter-spacing:-1.2px;word-spacing:-1.9px;}
#ty_1{left:447px;bottom:702px;letter-spacing:-2.7px;}
#tz_1{left:87px;bottom:673px;}
#t10_1{left:127px;bottom:673px;letter-spacing:-3px;}
#t11_1{left:173px;bottom:673px;letter-spacing:-4px;}
#t12_1{left:238px;bottom:673px;letter-spacing:-2.1px;}
#t13_1{left:347px;bottom:673px;letter-spacing:-3.6px;word-spacing:0.4px;}
#t14_1{left:688px;bottom:673px;letter-spacing:-2.5px;}
#t15_1{left:831px;bottom:673px;letter-spacing:-2.4px;}
#t16_1{left:347px;bottom:656px;letter-spacing:-1.2px;word-spacing:-1.9px;}
#t17_1{left:447px;bottom:656px;letter-spacing:-2.7px;}
#t18_1{left:87px;bottom:192px;letter-spacing:-1.5px;}
#t19_1{left:756px;bottom:192px;letter-spacing:-8.8px;}
#t1a_1{left:823px;bottom:192px;letter-spacing:-2.7px;}
#t1b_1{left:87px;bottom:175px;letter-spacing:-4.5px;word-spacing:1.3px;}
#t1c_1{left:756px;bottom:175px;letter-spacing:-8.8px;}
#t1d_1{left:831px;bottom:175px;letter-spacing:-2.6px;}
#t1e_1{left:87px;bottom:140px;letter-spacing:-3.3px;word-spacing:0.1px;}
#t1f_1{left:756px;bottom:140px;letter-spacing:-8.7px;}
#t1g_1{left:823px;bottom:140px;letter-spacing:-2.5px;}
#t1h_1{left:87px;bottom:91px;letter-spacing:-5px;word-spacing:2.1px;}
#t1i_1{left:390px;bottom:91px;letter-spacing:-4.6px;word-spacing:1.7px;}
#t1j_1{left:87px;bottom:75px;letter-spacing:-3.1px;word-spacing:0.2px;}
#t1k_1{left:390px;bottom:75px;letter-spacing:-4.5px;}
#t1l_1{left:464px;bottom:75px;letter-spacing:-3.2px;}
#t1m_1{left:87px;bottom:60px;letter-spacing:-2.7px;word-spacing:-0.2px;}
#t1n_1{left:390px;bottom:60px;letter-spacing:-3.2px;}
#t1o_1{left:464px;bottom:60px;letter-spacing:-2.5px;}
#t1p_1{left:87px;bottom:44px;letter-spacing:-3.7px;word-spacing:0.9px;}
#t1q_1{left:390px;bottom:44px;letter-spacing:-3.9px;word-spacing:1.1px;}
#t1r_1{left:464px;bottom:44px;letter-spacing:-5.4px;word-spacing:2.5px;}

.s1{
	FONT-SIZE: 110px;
	FONT-FAMILY: Calibri-Bold_a;
	color: rgb(0,0,0);
}

.s2{
	FONT-SIZE: 61.1px;
	FONT-FAMILY: Calibri_b;
	color: rgb(0,0,0);
}

.s3{
	FONT-SIZE: 61.1px;
	FONT-FAMILY: Calibri-Bold_c;
	color: rgb(0,0,0);
}

.s4{
	FONT-SIZE: 42.8px;
	FONT-FAMILY: Calibri_d;
	color: rgb(0,0,0);
}

.s5{
	FONT-SIZE: 55px;
	FONT-FAMILY: Calibri-Bold_f;
	color: rgb(0,0,0);
}

.s6{
	FONT-SIZE: 55px;
	FONT-FAMILY: Calibri_h;
	color: rgb(0,0,0);
}

</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts1" type="text/css" >

@font-face {
	font-family: Calibri-Bold_a;
	src: url("fonts/Calibri-Bold_a.woff") format("woff");
}

@font-face {
	font-family: Calibri-Bold_c;
	src: url("fonts/Calibri-Bold_c.woff") format("woff");
}

@font-face {
	font-family: Calibri-Bold_f;
	src: url("fonts/Calibri-Bold_f.woff") format("woff");
}

@font-face {
	font-family: Calibri_b;
	src: url("fonts/Calibri_b.woff") format("woff");
}

@font-face {
	font-family: Calibri_d;
	src: url("fonts/Calibri_d.woff") format("woff");
}

@font-face {
	font-family: Calibri_h;
	src: url("fonts/Calibri_h.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg1Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg1" style="-webkit-user-select: none;"><object width="909" height="1286" data="1/1.svg" type="image/svg+xml" id="pdf1" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div id="t1_1" class="t s1">Bestellung</div>
<div id="t2_1" class="t s2">Belegnummer:</div>
<div id="t3_1" class="t s3">BE250933</div>
<div id="t4_1" class="t s2">Datum: 09.01.2019</div>
<div id="t5_1" class="t s4">Fasnacht Dynamics AG, Bollstrasse 61A, CH-3076 Worb</div>
<div id="t6_1" class="t s2">Angst + Pfister AG APSOparts</div>
<div id="t7_1" class="t s2">Thurgauerstrasse 66</div>
<div id="t8_1" class="t s2">8052 Zürich</div>
<div id="t9_1" class="t s2">Unser Kontakt:</div>
<div id="ta_1" class="t s2">Stefan Dällenbach / einkauf@fasnacht.biz</div>
<div id="tb_1" class="t s2">Unsere Kunden-Nr.:</div>
<div id="tc_1" class="t s2">819651</div>
<div id="td_1" class="t s2">Liefertermin:</div>
<div id="te_1" class="t s2">25.01.2019</div>
<div id="tf_1" class="t s2">Kontaktperson:</div>
<div id="tg_1" class="t s2">Zahlungskonditionen:</div>
<div id="th_1" class="t s2">30 Tage netto</div>
<div id="ti_1" class="t s3">Pos</div>
<div id="tj_1" class="t s3">ME</div>
<div id="tk_1" class="t s3">Einheit</div>
<div id="tl_1" class="t s3">Art-Nr.</div>
<div id="tm_1" class="t s3">Bezeichnung</div>
<div id="tn_1" class="t s3">LP</div>
<div id="to_1" class="t s3">Total</div>
<div id="tp_1" class="t s2">1</div>
<div id="tq_1" class="t s2">1000</div>
<div id="tr_1" class="t s2">Stk.</div>
<div id="ts_1" class="t s3">1.1.09</div>
<div id="tt_1" class="t s3">Flachdichtung </div>
<div id="tu_1" class="t s2">34.20</div>
<div id="tv_1" class="t s3">342.00</div>
<div id="tw_1" class="t s2">Ölpapier</div>
<div id="tx_1" class="t s2">Ihre Artikel-Nr: </div>
<div id="ty_1" class="t s2">30.1687.0001</div>
<div id="tz_1" class="t s2">2</div>
<div id="t10_1" class="t s2">200</div>
<div id="t11_1" class="t s2">Stk.</div>
<div id="t12_1" class="t s3">1.3.07</div>
<div id="t13_1" class="t s3">Dichtung Farbfilter 1" </div>
<div id="t14_1" class="t s2">0.40</div>
<div id="t15_1" class="t s3">80.00</div>
<div id="t16_1" class="t s2">Ihre Artikel-Nr: </div>
<div id="t17_1" class="t s2">30.1688.0005</div>
<div id="t18_1" class="t s2">Netto-Betrag</div>
<div id="t19_1" class="t s2">CHF</div>
<div id="t1a_1" class="t s2">422.00</div>
<div id="t1b_1" class="t s2">+ 7.70 % MWST auf CHF 422.00</div>
<div id="t1c_1" class="t s2">CHF</div>
<div id="t1d_1" class="t s2">32.50</div>
<div id="t1e_1" class="t s3">Gesamttotal inkl. MWST</div>
<div id="t1f_1" class="t s3">CHF</div>
<div id="t1g_1" class="t s3">454.50</div>
<div id="t1h_1" class="t s5">Fasnacht Dynamics AG</div>
<div id="t1i_1" class="t s5">Credit Suisse AG, CH-3001 Bern</div>
<div id="t1j_1" class="t s6">Bollstrasse 61A; CH-3076</div>
<div id="t1k_1" class="t s5">IBAN: </div>
<div id="t1l_1" class="t s6">CH3804835138091901000</div>
<div id="t1m_1" class="t s6">+41 (0)44 382 10 10</div>
<div id="t1n_1" class="t s5">Konto-Nr.: </div>
<div id="t1o_1" class="t s6">0094-1380919-01</div>
<div id="t1p_1" class="t s6">CHE-491.800.249 MWST</div>
<div id="t1q_1" class="t s5">Swift Code: </div>
<div id="t1r_1" class="t s6">CRESCHZZ80A / Bank Charges: SHA</div>

<!-- End text definitions -->


</div>
</body>
</html>
`,
  Gueng: `
<!DOCTYPE html >
<html lang="en">
<head>
<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
<meta charset="utf-8" />
</head>

<body style="margin: 0;">

<div id="p1" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1284px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
	-webkit-transform: scale(0.25);
	-ms-transform: scale(0.25);
	transform: scale(0.25);
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_1{left:86px;bottom:1250px;letter-spacing:-0.1px;word-spacing:-0.8px;}
#t2_1{left:86px;bottom:1233px;}
#t3_1{left:86px;bottom:1216px;}
#t4_1{left:86px;bottom:1237px;}
#t5_1{left:86px;bottom:1216px;word-spacing:-1.8px;}
#t6_1{left:86px;bottom:1141px;word-spacing:-2.4px;}
#t7_1{left:86px;bottom:1127px;}
#t8_1{left:86px;bottom:1113px;}
#t9_1{left:86px;bottom:1099px;letter-spacing:-0.3px;word-spacing:0.3px;}
#ta_1{left:86px;bottom:1085px;}
#tb_1{left:86px;bottom:1071px;}
#tc_1{left:86px;bottom:1057px;}
#td_1{left:86px;bottom:1043px;}
#te_1{left:86px;bottom:990px;}
#tf_1{left:86px;bottom:976px;letter-spacing:-0.2px;word-spacing:0.2px;}
#tg_1{left:86px;bottom:962px;}
#th_1{left:86px;bottom:893px;}
#ti_1{left:532px;bottom:1027px;word-spacing:-0.7px;}
#tj_1{left:532px;bottom:1005px;}
#tk_1{left:532px;bottom:983px;}
#tl_1{left:532px;bottom:900px;}
#tm_1{left:762px;bottom:868px;}
#tn_1{left:86px;bottom:812px;}
#to_1{left:86px;bottom:830px;}
#tp_1{left:480px;bottom:781px;}
#tq_1{left:480px;bottom:761px;}
#tr_1{left:650px;bottom:761px;}
#ts_1{left:86px;bottom:761px;}
#tt_1{left:86px;bottom:781px;}
#tu_1{left:242px;bottom:781px;letter-spacing:-0.7px;}
#tv_1{left:86px;bottom:737px;}
#tw_1{left:156px;bottom:738px;}
#tx_1{left:206px;bottom:738px;}
#ty_1{left:436px;bottom:738px;}
#tz_1{left:540px;bottom:738px;word-spacing:0.5px;}
#t10_1{left:670px;bottom:737px;}
#t11_1{left:765px;bottom:737px;}
#t12_1{left:242px;bottom:719px;}
#t13_1{left:242px;bottom:738px;}
#t14_1{left:593px;bottom:694px;}
#t15_1{left:242px;bottom:694px;}
#t16_1{left:242px;bottom:673px;}
#t17_1{left:436px;bottom:694px;}
#t18_1{left:206px;bottom:694px;}
#t19_1{left:608px;bottom:694px;}
#t1a_1{left:103px;bottom:694px;}
#t1b_1{left:175px;bottom:694px;}
#t1c_1{left:242px;bottom:652px;}
#t1d_1{left:577px;bottom:631px;}
#t1e_1{left:242px;bottom:631px;}
#t1f_1{left:242px;bottom:610px;}
#t1g_1{left:436px;bottom:631px;}
#t1h_1{left:206px;bottom:631px;}
#t1i_1{left:608px;bottom:631px;}
#t1j_1{left:103px;bottom:631px;}
#t1k_1{left:175px;bottom:631px;}
#t1l_1{left:242px;bottom:589px;}
#t1m_1{left:577px;bottom:568px;}
#t1n_1{left:702px;bottom:568px;}
#t1o_1{left:242px;bottom:568px;}
#t1p_1{left:242px;bottom:547px;}
#t1q_1{left:436px;bottom:568px;}
#t1r_1{left:800px;bottom:568px;}
#t1s_1{left:206px;bottom:568px;}
#t1t_1{left:608px;bottom:568px;}
#t1u_1{left:103px;bottom:568px;}
#t1v_1{left:165px;bottom:568px;}
#t1w_1{left:242px;bottom:526px;}
#t1x_1{left:577px;bottom:505px;}
#t1y_1{left:702px;bottom:505px;}
#t1z_1{left:242px;bottom:505px;}
#t20_1{left:242px;bottom:484px;}
#t21_1{left:436px;bottom:505px;}
#t22_1{left:800px;bottom:505px;}
#t23_1{left:206px;bottom:505px;}
#t24_1{left:608px;bottom:505px;}
#t25_1{left:103px;bottom:505px;}
#t26_1{left:165px;bottom:505px;}
#t27_1{left:242px;bottom:463px;}
#t28_1{left:577px;bottom:442px;}
#t29_1{left:702px;bottom:442px;}
#t2a_1{left:242px;bottom:442px;}
#t2b_1{left:242px;bottom:421px;}
#t2c_1{left:436px;bottom:442px;}
#t2d_1{left:800px;bottom:442px;}
#t2e_1{left:206px;bottom:442px;}
#t2f_1{left:608px;bottom:442px;}
#t2g_1{left:103px;bottom:442px;}
#t2h_1{left:165px;bottom:442px;}
#t2i_1{left:242px;bottom:400px;}
#t2j_1{left:641px;bottom:373px;}
#t2k_1{left:790px;bottom:373px;}
#t2l_1{left:800px;bottom:355px;}
#t2m_1{left:567px;bottom:355px;}
#t2n_1{left:597px;bottom:325px;}
#t2o_1{left:790px;bottom:325px;}
#t2p_1{left:86px;bottom:258px;}
#t2q_1{left:86px;bottom:238px;}
#t2r_1{left:86px;bottom:221px;word-spacing:-0.7px;}
#t2s_1{left:532px;bottom:189px;}
#t2t_1{left:532px;bottom:125px;word-spacing:-3.1px;}

.s1{
	FONT-SIZE: 43.7px;
	FONT-FAMILY: Arial_9;
	color: rgb(255,255,255);
}

.s2{
	FONT-SIZE: 68.4px;
	FONT-FAMILY: Arial_9;
	color: rgb(0,0,0);
}

.s3{
	FONT-SIZE: 43.7px;
	FONT-FAMILY: Arial_9;
	color: rgb(0,0,0);
}

.s4{
	FONT-SIZE: 74.9px;
	FONT-FAMILY: Arial-Bold_d;
	color: rgb(0,0,0);
}

.s5{
	FONT-SIZE: 64.2px;
	FONT-FAMILY: Arial-Bold_d;
	color: rgb(0,0,0);
}

.s6{
	FONT-SIZE: 54.7px;
	FONT-FAMILY: Arial_9;
	color: rgb(0,0,0);
}

.s7{
	FONT-SIZE: 45.5px;
	FONT-FAMILY: ArialUnicodeMS_e;
	color: rgb(0,0,0);
}

.s8{
	FONT-SIZE: 45.5px;
	FONT-FAMILY: ArialUnicodeMS_e;
	color: rgb(0,0,0);
	-webkit-text-stroke: 1.8px #000000;
	text-stroke: 1.8px #000000;
}

.s9{
	FONT-SIZE: 49.5px;
	FONT-FAMILY: Arial_9;
	color: rgb(0,0,0);
}

</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts1" type="text/css" >

@font-face {
	font-family: Arial-Bold_d;
	src: url("fonts/Arial-Bold_d.woff") format("woff");
}

@font-face {
	font-family: ArialUnicodeMS_e;
	src: url("fonts/ArialUnicodeMS_e.woff") format("woff");
}

@font-face {
	font-family: Arial_9;
	src: url("fonts/Arial_9.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg1Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg1" style="-webkit-user-select: none;"><object width="909" height="1284" data="1/1.svg" type="image/svg+xml" id="pdf1" style="width:909px; height:1284px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div id="t1_1" class="t s1">@@LieferantenNr=L115006@LieferantenKTxt=Angst + Pfister AG</div>
<div id="t2_1" class="t s1">@Kommission=@BelegArt=Bestellung@BestellNr=EB12290</div>
<div id="t3_1" class="t s1">@BelegDatum=29.05.2018@Sachbearbeiter=@@</div>
<div id="t4_1" class="t s2">Décolletages</div>
<div id="t5_1" class="t s2">Feinmechanik · Apparatebau</div>
<div id="t6_1" class="t s3">Gueng AG</div>
<div id="t7_1" class="t s3">Gewerbestrasse 3</div>
<div id="t8_1" class="t s3">CH - 8162 Steinmaur</div>
<div id="t9_1" class="t s3">Tel: 043 422 29 49</div>
<div id="ta_1" class="t s3">Fax: 043 422 29 40</div>
<div id="tb_1" class="t s3">Email: info@gueng.ch</div>
<div id="tc_1" class="t s3">Web: www.gueng.ch</div>
<div id="td_1" class="t s3">MwSt-Nr. CHE-107.958.703</div>
<div id="te_1" class="t s3">Bezirkssparkasse 8389 IBAN CHF CH05 0838 9016 5010 5000 0</div>
<div id="tf_1" class="t s3">ZKB 725 IBAN CHF CH46 0070 0112 5001 9311 6</div>
<div id="tg_1" class="t s3">ZKB 725 IBAN EURO CH23 0070 0132 5000 1437 0</div>
<div id="th_1" class="t s4">Bestellung Nr EB12290</div>
<div id="ti_1" class="t s5">Angst + Pfister AG</div>
<div id="tj_1" class="t s5">Thurgauerstrasse 66</div>
<div id="tk_1" class="t s5">8052 Zürich</div>
<div id="tl_1" class="t s6">Steinmaur, 29.05.2018</div>
<div id="tm_1" class="t s6">Seite 1 von 1</div>
<div id="tn_1" class="t s6">wir bestellen bei Ihnen die im folgenden aufgeführten Positionen zu unseren Einkaufbedingungen:</div>
<div id="to_1" class="t s6">Sehr geehrte Damen und Herren,</div>
<div id="tp_1" class="t s6">Kommission:</div>
<div id="tq_1" class="t s6">Unser Zeichen:</div>
<div id="tr_1" class="t s6">JMA</div>
<div id="ts_1" class="t s6">MwSt-Nr.</div>
<div id="tt_1" class="t s6">Lieferant:</div>
<div id="tu_1" class="t s6">L115006</div>
<div id="tv_1" class="t s6">Lfd Nr</div>
<div id="tw_1" class="t s6">Menge</div>
<div id="tx_1" class="t s6">ME</div>
<div id="ty_1" class="t s6">Liefertermin</div>
<div id="tz_1" class="t s6">Je Menge ME</div>
<div id="t10_1" class="t s6">Einzelpreis</div>
<div id="t11_1" class="t s6">Gesamtpreis</div>
<div id="t12_1" class="t s6">Bezeichnung</div>
<div id="t13_1" class="t s6">Artikelnr.</div>
<div id="t14_1" class="t s6">1</div>
<div id="t15_1" class="t s6">070134</div>
<div id="t16_1" class="t s6">O-Ring D6x2 NBR 70</div>
<div id="t17_1" class="t s6">31.05.2018</div>
<div id="t18_1" class="t s6">Stk</div>
<div id="t19_1" class="t s6">Stk</div>
<div id="t1a_1" class="t s6">10</div>
<div id="t1b_1" class="t s6">500</div>
<div id="t1c_1" class="t s7">11.4005.2006</div>
<div id="t1d_1" class="t s6">100</div>
<div id="t1e_1" class="t s6">071044</div>
<div id="t1f_1" class="t s6">O-Ring ID22x3NBR70</div>
<div id="t1g_1" class="t s6">31.05.2018</div>
<div id="t1h_1" class="t s6">Stk</div>
<div id="t1i_1" class="t s6">Stk</div>
<div id="t1j_1" class="t s6">20</div>
<div id="t1k_1" class="t s6">500</div>
<div id="t1l_1" class="t s7">11.2003.1766</div>
<div id="t1m_1" class="t s6">100</div>
<div id="t1n_1" class="t s6">27.23</div>
<div id="t1o_1" class="t s6">070183</div>
<div id="t1p_1" class="t s6">O-Ring D6x2 MVQ80</div>
<div id="t1q_1" class="t s6">31.05.2018</div>
<div id="t1r_1" class="t s6">381.20</div>
<div id="t1s_1" class="t s6">Stk</div>
<div id="t1t_1" class="t s6">Stk</div>
<div id="t1u_1" class="t s6">30</div>
<div id="t1v_1" class="t s6">1'400</div>
<div id="t1w_1" class="t s7">11.4009.2006</div>
<div id="t1x_1" class="t s6">100</div>
<div id="t1y_1" class="t s6">29.79</div>
<div id="t1z_1" class="t s6">070184</div>
<div id="t20_1" class="t s6">O-Ring ID9.25x1.78 MVQ80</div>
<div id="t21_1" class="t s6">31.05.2018</div>
<div id="t22_1" class="t s6">893.70</div>
<div id="t23_1" class="t s6">Stk</div>
<div id="t24_1" class="t s6">Stk</div>
<div id="t25_1" class="t s6">40</div>
<div id="t26_1" class="t s6">3'000</div>
<div id="t27_1" class="t s7">11.4049.1814</div>
<div id="t28_1" class="t s6">100</div>
<div id="t29_1" class="t s6">40.64</div>
<div id="t2a_1" class="t s6">071069</div>
<div id="t2b_1" class="t s6">O-Ring ID22x3 MVQ80</div>
<div id="t2c_1" class="t s6">31.05.2018</div>
<div id="t2d_1" class="t s6">670.55</div>
<div id="t2e_1" class="t s6">Stk</div>
<div id="t2f_1" class="t s6">Stk</div>
<div id="t2g_1" class="t s6">50</div>
<div id="t2h_1" class="t s6">1'650</div>
<div id="t2i_1" class="t s7">11.4009.3022</div>
<div id="t2j_1" class="t s6">Nettowarenwert</div>
<div id="t2k_1" class="t s6">1'945.45</div>
<div id="t2l_1" class="t s6">149.80</div>
<div id="t2m_1" class="t s6">MwSt 7.700 % von 1'945.45</div>
<div id="t2n_1" class="t s6">Gesamtsumme in CHF</div>
<div id="t2o_1" class="t s6">2'095.25</div>
<div id="t2p_1" class="t s8">Gemäss Offerte DT-O18.683205</div>
<div id="t2q_1" class="t s8">von Herrn Stegmann</div>
<div id="t2r_1" class="t s9">Wir bitten um Ihre Auftragsbestätigung</div>
<div id="t2s_1" class="t s6">Mit freundlichen Grüssen</div>
<div id="t2t_1" class="t s6">Gueng AG</div>

<!-- End text definitions -->


</div>
</body>
</html>
`,
  'Velox Bestellung': `
  <!DOCTYPE html >
<html lang="en">
<head>
<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
<meta charset="utf-8" />
</head>

<body style="margin: 0;">

<div id="p1" style="overflow: hidden; position: relative; background-color: white; width: 935px; height: 1210px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
	-webkit-transform: scale(0.25);
	-ms-transform: scale(0.25);
	transform: scale(0.25);
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_1{left:660px;bottom:1076px;letter-spacing:-0.3px;word-spacing:0.3px;}
#t2_1{left:660px;bottom:1053px;letter-spacing:-0.2px;}
#t3_1{left:660px;bottom:1029px;letter-spacing:-0.2px;word-spacing:0.1px;}
#t4_1{left:110px;bottom:888px;}
#t5_1{left:110px;bottom:864px;letter-spacing:-0.2px;word-spacing:0.2px;}
#t6_1{left:110px;bottom:841px;letter-spacing:-0.2px;word-spacing:0.1px;}
#t7_1{left:110px;bottom:793px;letter-spacing:-0.2px;word-spacing:0.2px;}
#t8_1{left:110px;bottom:699px;letter-spacing:-0.1px;}
#t9_1{left:275px;bottom:699px;}
#ta_1{left:110px;bottom:676px;letter-spacing:-0.2px;}
#tb_1{left:275px;bottom:676px;letter-spacing:-0.2px;}
#tc_1{left:110px;bottom:652px;letter-spacing:-0.2px;}
#td_1{left:275px;bottom:652px;letter-spacing:-0.2px;word-spacing:0.2px;}
#te_1{left:110px;bottom:629px;letter-spacing:-0.1px;}
#tf_1{left:275px;bottom:629px;}
#tg_1{left:110px;bottom:581px;letter-spacing:-0.3px;}
#th_1{left:275px;bottom:581px;letter-spacing:-0.2px;word-spacing:0.2px;}
#ti_1{left:118px;bottom:505px;letter-spacing:-0.4px;}
#tj_1{left:167px;bottom:505px;letter-spacing:-0.1px;}
#tk_1{left:391px;bottom:505px;}
#tl_1{left:581px;bottom:505px;}
#tm_1{left:658px;bottom:505px;letter-spacing:-0.2px;}
#tn_1{left:761px;bottom:505px;letter-spacing:-0.4px;}
#to_1{left:118px;bottom:472px;}
#tp_1{left:167px;bottom:472px;letter-spacing:-0.4px;word-spacing:0.4px;}
#tq_1{left:391px;bottom:472px;letter-spacing:-0.2px;}
#tr_1{left:627px;bottom:472px;}
#ts_1{left:658px;bottom:472px;letter-spacing:0.1px;word-spacing:-0.1px;}
#tt_1{left:793px;bottom:472px;}
#tu_1{left:118px;bottom:414px;}
#tv_1{left:167px;bottom:414px;letter-spacing:-0.1px;word-spacing:0.1px;}
#tw_1{left:391px;bottom:414px;letter-spacing:-0.2px;}
#tx_1{left:633px;bottom:414px;}
#ty_1{left:658px;bottom:414px;letter-spacing:0.1px;word-spacing:-0.1px;}
#tz_1{left:799px;bottom:414px;}
#t10_1{left:118px;bottom:356px;}
#t11_1{left:167px;bottom:356px;letter-spacing:-0.1px;word-spacing:0.1px;}
#t12_1{left:391px;bottom:356px;letter-spacing:-0.1px;}
#t13_1{left:632px;bottom:356px;}
#t14_1{left:658px;bottom:356px;letter-spacing:0.1px;word-spacing:-0.1px;}
#t15_1{left:798px;bottom:356px;}
#t16_1{left:118px;bottom:298px;}
#t17_1{left:167px;bottom:298px;letter-spacing:-0.3px;word-spacing:0.3px;}
#t18_1{left:391px;bottom:298px;letter-spacing:-0.1px;}
#t19_1{left:634px;bottom:298px;}
#t1a_1{left:658px;bottom:298px;letter-spacing:0.1px;word-spacing:-0.1px;}
#t1b_1{left:800px;bottom:298px;}
#t1c_1{left:118px;bottom:226px;letter-spacing:-0.3px;word-spacing:0.3px;}
#t1d_1{left:658px;bottom:226px;letter-spacing:-0.9px;}
#t1e_1{left:792px;bottom:226px;}
#t1f_1{left:110px;bottom:92px;}
#t1g_1{left:385px;bottom:92px;}
#t1h_1{left:110px;bottom:73px;}
#t1i_1{left:385px;bottom:73px;letter-spacing:-0.6px;}
#t1j_1{left:437px;bottom:73px;}
#t1k_1{left:443px;bottom:73px;letter-spacing:-0.3px;}
#t1l_1{left:605px;bottom:70px;}

.s1{
	FONT-SIZE: 67.2px;
	FONT-FAMILY: Montserrat-Bold_f;
	color: rgb(0,0,0);
}

.s2{
	FONT-SIZE: 67.2px;
	FONT-FAMILY: Montserrat-Bold_f;
	color: rgb(29,77,109);
}

.s3{
	FONT-SIZE: 61.1px;
	FONT-FAMILY: Montserrat-Bold_f;
	color: rgb(0,0,0);
}

.s4{
	FONT-SIZE: 55px;
	FONT-FAMILY: Montserrat-Bold_f;
	color: rgb(0,0,0);
}

.s5{
	FONT-SIZE: 42.8px;
	FONT-FAMILY: ArialMT_j;
	color: rgb(64,64,64);
}

.s6{
	FONT-SIZE: 39.7px;
	FONT-FAMILY: ArialMT_j;
	color: rgb(64,64,64);
}

.s7{
	FONT-SIZE: 42.8px;
	FONT-FAMILY: ArialMT_j;
	color: rgb(17,85,204);
}

.s8{
	FONT-SIZE: 67.2px;
	FONT-FAMILY: ArialMT_j;
	color: rgb(0,0,0);
}

</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts1" type="text/css" >

@font-face {
	font-family: ArialMT_j;
	src: url("fonts/ArialMT_j.woff") format("woff");
}

@font-face {
	font-family: Montserrat-Bold_f;
	src: url("fonts/Montserrat-Bold_f.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg1Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg1" style="-webkit-user-select: none;"><object width="935" height="1210" data="1/1.svg" type="image/svg+xml" id="pdf1" style="width:935px; height:1210px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div id="t1_1" class="t s1">Sly Connect Test</div>
<div id="t2_1" class="t s1">Binzmühlestrasse</div>
<div id="t3_1" class="t s1">8052 Zürich</div>
<div id="t4_1" class="t s1">Sly GmbH</div>
<div id="t5_1" class="t s1">Binzmühlestrasse 99</div>
<div id="t6_1" class="t s1">8052 Zürich</div>
<div id="t7_1" class="t s1">Bestellung Nr. 9999999</div>
<div id="t8_1" class="t s1">Besteller:</div>
<div id="t9_1" class="t s2">Luca Solimine</div>
<div id="ta_1" class="t s1">E-Mail:</div>
<div id="tb_1" class="t s2">luca.solimine@sly.ch</div>
<div id="tc_1" class="t s1">Referenz:</div>
<div id="td_1" class="t s2">Testbestellung Sly Connect</div>
<div id="te_1" class="t s1">Bestelldatum:</div>
<div id="tf_1" class="t s2">am 12/01/2023</div>
<div id="tg_1" class="t s1">Lieferadresse:</div>
<div id="th_1" class="t s2">Binzmühlestrasse 99, 8052 Zürich</div>
<div id="ti_1" class="t s3">Pos</div>
<div id="tj_1" class="t s3">Bezeichnung</div>
<div id="tk_1" class="t s3">Artikelnummer</div>
<div id="tl_1" class="t s3">Menge</div>
<div id="tm_1" class="t s3">Preis/Eht</div>
<div id="tn_1" class="t s3">Betrag</div>
<div id="to_1" class="t s4">10</div>
<div id="tp_1" class="t s4">Velox Note Book</div>
<div id="tq_1" class="t s4">velox-note-book</div>
<div id="tr_1" class="t s4">10</div>
<div id="ts_1" class="t s4">10 / Stk.</div>
<div id="tt_1" class="t s4">100</div>
<div id="tu_1" class="t s4">20</div>
<div id="tv_1" class="t s4">VELOX Polo Shirt Black (S)</div>
<div id="tw_1" class="t s4">velox-polo-shirt-black-s</div>
<div id="tx_1" class="t s4">5</div>
<div id="ty_1" class="t s4">10 / Stk.</div>
<div id="tz_1" class="t s4">50</div>
<div id="t10_1" class="t s4">30</div>
<div id="t11_1" class="t s4">VELOX Polo Shirt Red (M)</div>
<div id="t12_1" class="t s4">velox-polo-shirt-red-m</div>
<div id="t13_1" class="t s4">4</div>
<div id="t14_1" class="t s4">10 / Stk.</div>
<div id="t15_1" class="t s4">40</div>
<div id="t16_1" class="t s4">40</div>
<div id="t17_1" class="t s4">Velox Espresso-Cup</div>
<div id="t18_1" class="t s4">velox-coffee-cup</div>
<div id="t19_1" class="t s4">2</div>
<div id="t1a_1" class="t s4">10 / Stk.</div>
<div id="t1b_1" class="t s4">20</div>
<div id="t1c_1" class="t s3">Bestellung Total</div>
<div id="t1d_1" class="t s3">Total</div>
<div id="t1e_1" class="t s3">210</div>
<div id="t1f_1" class="t s5">Sly GmbH</div>
<div id="t1g_1" class="t s6">CHE-134.205.441</div>
<div id="t1h_1" class="t s5">Binzmühlestrasse 99, 8050 Zürich</div>
<div id="t1i_1" class="t s7">www.sly.ch</div>
<div id="t1j_1" class="t s5">,</div>
<div id="t1k_1" class="t s7">info@sly.ch</div>
<div id="t1l_1" class="t s8">1/1</div>

<!-- End text definitions -->


</div>
</body>
</html>

  `,

  'Bestellung Header grösser': `
  <!DOCTYPE html >
<html lang="en">
<head>
<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
<meta charset="utf-8" />
</head>

<body style="margin: 0;">

<div id="p1" style="overflow: hidden; position: relative; background-color: white; width: 935px; height: 1210px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
	-webkit-transform: scale(0.25);
	-ms-transform: scale(0.25);
	transform: scale(0.25);
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_1{left:660px;bottom:1076px;letter-spacing:-0.3px;word-spacing:0.3px;}
#t2_1{left:660px;bottom:1053px;letter-spacing:-0.2px;}
#t3_1{left:660px;bottom:1029px;letter-spacing:-0.2px;word-spacing:0.1px;}
#t4_1{left:110px;bottom:888px;}
#t5_1{left:110px;bottom:864px;letter-spacing:-0.2px;word-spacing:0.2px;}
#t6_1{left:110px;bottom:841px;letter-spacing:-0.2px;word-spacing:0.1px;}
#t7_1{left:110px;bottom:793px;letter-spacing:-0.2px;word-spacing:0.2px;}
#t8_1{left:110px;bottom:699px;letter-spacing:-0.1px;}
#t9_1{left:275px;bottom:699px;}
#ta_1{left:110px;bottom:676px;letter-spacing:-0.2px;}
#tb_1{left:275px;bottom:676px;letter-spacing:-0.2px;}
#tc_1{left:110px;bottom:652px;letter-spacing:-0.2px;}
#td_1{left:275px;bottom:652px;letter-spacing:-0.2px;word-spacing:0.2px;}
#te_1{left:110px;bottom:629px;letter-spacing:-0.1px;}
#tf_1{left:275px;bottom:629px;}
#tg_1{left:110px;bottom:581px;letter-spacing:-0.3px;}
#th_1{left:275px;bottom:581px;letter-spacing:-0.2px;word-spacing:0.2px;}
#ti_1{left:110px;bottom:534px;letter-spacing:-0.2px;word-spacing:0.2px;}
#tj_1{left:118px;bottom:458px;letter-spacing:-0.4px;}
#tk_1{left:167px;bottom:458px;letter-spacing:-0.1px;}
#tl_1{left:391px;bottom:458px;}
#tm_1{left:581px;bottom:458px;}
#tn_1{left:658px;bottom:458px;letter-spacing:-0.2px;}
#to_1{left:761px;bottom:458px;letter-spacing:-0.4px;}
#tp_1{left:118px;bottom:425px;}
#tq_1{left:167px;bottom:425px;letter-spacing:-0.4px;word-spacing:0.4px;}
#tr_1{left:391px;bottom:425px;letter-spacing:-0.2px;}
#ts_1{left:608px;bottom:425px;}
#tt_1{left:658px;bottom:425px;letter-spacing:0.1px;word-spacing:-0.1px;}
#tu_1{left:793px;bottom:425px;}
#tv_1{left:118px;bottom:367px;}
#tw_1{left:167px;bottom:367px;letter-spacing:-0.1px;word-spacing:0.1px;}
#tx_1{left:391px;bottom:367px;letter-spacing:-0.2px;}
#ty_1{left:633px;bottom:367px;}
#tz_1{left:658px;bottom:367px;letter-spacing:0.1px;word-spacing:-0.1px;}
#t10_1{left:799px;bottom:367px;}
#t11_1{left:118px;bottom:309px;}
#t12_1{left:167px;bottom:309px;letter-spacing:-0.1px;word-spacing:0.1px;}
#t13_1{left:391px;bottom:309px;letter-spacing:-0.1px;}
#t14_1{left:632px;bottom:309px;}
#t15_1{left:658px;bottom:309px;letter-spacing:0.1px;word-spacing:-0.1px;}
#t16_1{left:798px;bottom:309px;}
#t17_1{left:118px;bottom:251px;}
#t18_1{left:167px;bottom:251px;letter-spacing:-0.3px;word-spacing:0.3px;}
#t19_1{left:391px;bottom:251px;letter-spacing:-0.1px;}
#t1a_1{left:634px;bottom:251px;}
#t1b_1{left:658px;bottom:251px;letter-spacing:0.1px;word-spacing:-0.1px;}
#t1c_1{left:800px;bottom:251px;}
#t1d_1{left:118px;bottom:179px;letter-spacing:-0.3px;word-spacing:0.3px;}
#t1e_1{left:658px;bottom:179px;letter-spacing:-0.9px;}
#t1f_1{left:792px;bottom:179px;}
#t1g_1{left:110px;bottom:92px;}
#t1h_1{left:385px;bottom:92px;}
#t1i_1{left:110px;bottom:73px;}
#t1j_1{left:385px;bottom:73px;letter-spacing:-0.6px;}
#t1k_1{left:437px;bottom:73px;}
#t1l_1{left:443px;bottom:73px;letter-spacing:-0.3px;}
#t1m_1{left:605px;bottom:70px;}

.s1{
	FONT-SIZE: 67.2px;
	FONT-FAMILY: Montserrat-Bold_f;
	color: rgb(0,0,0);
}

.s2{
	FONT-SIZE: 67.2px;
	FONT-FAMILY: Montserrat-Bold_f;
	color: rgb(29,77,109);
}

.s3{
	FONT-SIZE: 61.1px;
	FONT-FAMILY: Montserrat-Bold_f;
	color: rgb(0,0,0);
}

.s4{
	FONT-SIZE: 55px;
	FONT-FAMILY: Montserrat-Bold_f;
	color: rgb(0,0,0);
}

.s5{
	FONT-SIZE: 42.8px;
	FONT-FAMILY: ArialMT_j;
	color: rgb(64,64,64);
}

.s6{
	FONT-SIZE: 39.7px;
	FONT-FAMILY: ArialMT_j;
	color: rgb(64,64,64);
}

.s7{
	FONT-SIZE: 42.8px;
	FONT-FAMILY: ArialMT_j;
	color: rgb(17,85,204);
}

.s8{
	FONT-SIZE: 67.2px;
	FONT-FAMILY: ArialMT_j;
	color: rgb(0,0,0);
}

</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts1" type="text/css" >

@font-face {
	font-family: ArialMT_j;
	src: url("fonts/ArialMT_j.woff") format("woff");
}

@font-face {
	font-family: Montserrat-Bold_f;
	src: url("fonts/Montserrat-Bold_f.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg1Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg1" style="-webkit-user-select: none;"><object width="935" height="1210" data="1/1.svg" type="image/svg+xml" id="pdf1" style="width:935px; height:1210px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div id="t1_1" class="t s1">Sly Connect Test</div>
<div id="t2_1" class="t s1">Binzmühlestrasse</div>
<div id="t3_1" class="t s1">8052 Zürich</div>
<div id="t4_1" class="t s1">Sly GmbH</div>
<div id="t5_1" class="t s1">Binzmühlestrasse 99</div>
<div id="t6_1" class="t s1">8052 Zürich</div>
<div id="t7_1" class="t s1">Bestellung Nr. 1239876</div>
<div id="t8_1" class="t s1">Besteller:</div>
<div id="t9_1" class="t s2">Luca Solimine</div>
<div id="ta_1" class="t s1">E-Mail:</div>
<div id="tb_1" class="t s2">luca.solimine@sly.ch</div>
<div id="tc_1" class="t s1">Referenz:</div>
<div id="td_1" class="t s2">Testbestellung Sly Connect</div>
<div id="te_1" class="t s1">Bestelldatum:</div>
<div id="tf_1" class="t s2">18.01.2022</div>
<div id="tg_1" class="t s1">Lieferadresse:</div>
<div id="th_1" class="t s2">Binzmühlestrasse 99, 8052 Zürich</div>
<div id="ti_1" class="t s2">Dies ist ein Test mit grösserem Header als normal.</div>
<div id="tj_1" class="t s3">Pos</div>
<div id="tk_1" class="t s3">Bezeichnung</div>
<div id="tl_1" class="t s3">Artikelnummer</div>
<div id="tm_1" class="t s3">Menge</div>
<div id="tn_1" class="t s3">Preis/Eht</div>
<div id="to_1" class="t s3">Betrag</div>
<div id="tp_1" class="t s4">10</div>
<div id="tq_1" class="t s4">Velox Note Book</div>
<div id="tr_1" class="t s4">velox-note-book</div>

<div id="ts_1" class="t s4">10000</div>

<div id="tt_1" class="t s4">10 / Stk.</div>
<div id="tu_1" class="t s4">100</div>
<div id="tv_1" class="t s4">20</div>
<div id="tw_1" class="t s4">VELOX Polo Shirt Black (S)</div>
<div id="tx_1" class="t s4">velox-polo-shirt-black-s</div>
<div id="ty_1" class="t s4">5</div>
<div id="tz_1" class="t s4">10 / Stk.</div>
<div id="t10_1" class="t s4">50</div>
<div id="t11_1" class="t s4">30</div>
<div id="t12_1" class="t s4">VELOX Polo Shirt Red (M)</div>
<div id="t13_1" class="t s4">velox-polo-shirt-red-m</div>
<div id="t14_1" class="t s4">4</div>
<div id="t15_1" class="t s4">10 / Stk.</div>
<div id="t16_1" class="t s4">40</div>
<div id="t17_1" class="t s4">40</div>
<div id="t18_1" class="t s4">Velox Espresso-Cup</div>
<div id="t19_1" class="t s4">velox-coffee-cup</div>
<div id="t1a_1" class="t s4">2</div>
<div id="t1b_1" class="t s4">10 / Stk.</div>
<div id="t1c_1" class="t s4">20</div>
<div id="t1d_1" class="t s3">Bestellung Total</div>
<div id="t1e_1" class="t s3">Total</div>
<div id="t1f_1" class="t s3">210</div>
<div id="t1g_1" class="t s5">Sly GmbH</div>
<div id="t1h_1" class="t s6">CHE-134.205.441</div>
<div id="t1i_1" class="t s5">Binzmühlestrasse 99, 8050 Zürich</div>
<div id="t1j_1" class="t s7">www.sly.ch</div>
<div id="t1k_1" class="t s5">,</div>
<div id="t1l_1" class="t s7">info@sly.ch</div>
<div id="t1m_1" class="t s8">1/1</div>

<!-- End text definitions -->


</div>
</body>
</html>

  `,
  BE_306371_3: `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
      <meta charset="utf-8" />
    </head>
  
    <body style="margin: 0">
      <div
        id="p1"
        style="
          overflow: hidden;
          position: relative;
          background-color: white;
          width: 909px;
          height: 1286px;
        "
      >
        <!-- Begin shared CSS values -->
        <style class="shared-css" type="text/css">
          .t {
            -webkit-transform-origin: bottom left;
            -ms-transform-origin: bottom left;
            transform-origin: bottom left;
            -webkit-transform: scale(0.25);
            -ms-transform: scale(0.25);
            transform: scale(0.25);
            z-index: 2;
            position: absolute;
            white-space: pre;
            overflow: visible;
            line-height: 1.5;
          }
        </style>
        <!-- End shared CSS values -->
  
        <!-- Begin inline CSS -->
        <style type="text/css">
          #t1_1 {
            left: 32px;
            bottom: 1142px;
            word-spacing: -0.1px;
          }
          #t2_1 {
            left: 118px;
            bottom: 1142px;
          }
          #t3_1 {
            left: 582px;
            bottom: 1044px;
            letter-spacing: -0.1px;
            word-spacing: 0.1px;
          }
          #t4_1 {
            left: 109px;
            bottom: 1031px;
            letter-spacing: 0.1px;
            word-spacing: 0.2px;
          }
          #t5_1 {
            left: 582px;
            bottom: 1024px;
            letter-spacing: -0.2px;
          }
          #t6_1 {
            left: 582px;
            bottom: 1003px;
            letter-spacing: -0.2px;
          }
          #t7_1 {
            left: 582px;
            bottom: 985px;
            letter-spacing: -0.2px;
          }
          #t8_1 {
            left: 109px;
            bottom: 983px;
            letter-spacing: -0.1px;
            word-spacing: 0.2px;
          }
          #t9_1 {
            left: 109px;
            bottom: 964px;
            letter-spacing: -0.2px;
            word-spacing: 0.3px;
          }
          #ta_1 {
            left: 583px;
            bottom: 961px;
            letter-spacing: -0.2px;
            word-spacing: -0.3px;
          }
          #tb_1 {
            left: 109px;
            bottom: 931px;
            letter-spacing: -0.2px;
          }
          #tc_1 {
            left: 143px;
            bottom: 931px;
            letter-spacing: -0.3px;
          }
          #td_1 {
            left: 198px;
            bottom: 931px;
            letter-spacing: -0.1px;
          }
          #te_1 {
            left: 588px;
            bottom: 923px;
          }
          #tf_1 {
            left: 586px;
            bottom: 892px;
            letter-spacing: -0.3px;
          }
          #tg_1 {
            left: 789px;
            bottom: 894px;
          }
          #th_1 {
            left: 676px;
            bottom: 893px;
            letter-spacing: -0.1px;
          }
          #ti_1 {
            left: 589px;
            bottom: 869px;
          }
          #tj_1 {
            left: 677px;
            bottom: 869px;
            word-spacing: 0.5px;
          }
          #tk_1 {
            left: 790px;
            bottom: 869px;
            letter-spacing: -0.1px;
          }
          #tl_1 {
            left: 620px;
            bottom: 809px;
          }
          #tm_1 {
            left: 582px;
            bottom: 809px;
            letter-spacing: 0.1px;
            word-spacing: 0.4px;
          }
          #tn_1 {
            left: 246px;
            bottom: 782px;
            letter-spacing: -0.1px;
            word-spacing: 0.2px;
          }
          #to_1 {
            left: 109px;
            bottom: 782px;
            letter-spacing: -0.2px;
          }
          #tp_1 {
            left: 246px;
            bottom: 762px;
            letter-spacing: -0.2px;
            word-spacing: 0.3px;
          }
          #tq_1 {
            left: 384px;
            bottom: 762px;
            letter-spacing: -0.2px;
            word-spacing: 0.4px;
          }
          #tr_1 {
            left: 450px;
            bottom: 762px;
            letter-spacing: -0.1px;
          }
          #ts_1 {
            left: 114px;
            bottom: 743px;
            letter-spacing: 0.2px;
          }
          #tt_1 {
            left: 147px;
            bottom: 743px;
            letter-spacing: 0.1px;
          }
          #tu_1 {
            left: 518px;
            bottom: 743px;
          }
          #tv_1 {
            left: 588px;
            bottom: 743px;
            letter-spacing: 0.1px;
            word-spacing: 1.9px;
          }
          #tw_1 {
            left: 661px;
            bottom: 743px;
            letter-spacing: 0.1px;
          }
          #tx_1 {
            left: 734px;
            bottom: 743px;
          }
          #ty_1 {
            left: 781px;
            bottom: 743px;
            letter-spacing: 0.1px;
          }
          #tz_1 {
            left: 669px;
            bottom: 725px;
            letter-spacing: 0.2px;
          }
          #t10_1 {
            left: 788px;
            bottom: 725px;
          }
          #t11_1 {
            left: 147px;
            bottom: 725px;
            letter-spacing: 0.1px;
          }
          #t12_1 {
            left: 541px;
            bottom: 699px;
            letter-spacing: 0.2px;
          }
          #t13_1 {
            left: 676px;
            bottom: 699px;
            letter-spacing: 0.2px;
          }
          #t14_1 {
            left: 794px;
            bottom: 699px;
            letter-spacing: 0.1px;
          }
          #t15_1 {
            left: 143px;
            bottom: 699px;
            letter-spacing: -0.2px;
          }
          #t16_1 {
            left: 586px;
            bottom: 699px;
            letter-spacing: -0.2px;
            word-spacing: 19.7px;
          }
          #t17_1 {
            left: 110px;
            bottom: 701px;
          }
          #t18_1 {
            left: 143px;
            bottom: 679px;
            letter-spacing: -0.1px;
            word-spacing: 0.2px;
          }
          #t19_1 {
            left: 143px;
            bottom: 659px;
            letter-spacing: -0.2px;
            word-spacing: 0.3px;
          }
          #t1a_1 {
            left: 237px;
            bottom: 641px;
            letter-spacing: 0.2px;
          }
          #t1b_1 {
            left: 143px;
            bottom: 641px;
            letter-spacing: 0.1px;
            word-spacing: -0.1px;
          }
          #t1c_1 {
            left: 354px;
            bottom: 617px;
            letter-spacing: 0.1px;
          }
          #t1d_1 {
            left: 143px;
            bottom: 617px;
            letter-spacing: 0.2px;
            word-spacing: 0.1px;
          }
          #t1e_1 {
            left: 541px;
            bottom: 593px;
            letter-spacing: 0.2px;
          }
          #t1f_1 {
            left: 685px;
            bottom: 593px;
            letter-spacing: 0.2px;
          }
          #t1g_1 {
            left: 794px;
            bottom: 593px;
            letter-spacing: 0.1px;
          }
          #t1h_1 {
            left: 143px;
            bottom: 594px;
            letter-spacing: -0.2px;
          }
          #t1i_1 {
            left: 586px;
            bottom: 594px;
            letter-spacing: -0.2px;
            word-spacing: 19.7px;
          }
          #t1j_1 {
            left: 110px;
            bottom: 596px;
          }
          #t1k_1 {
            left: 143px;
            bottom: 574px;
            letter-spacing: -0.1px;
            word-spacing: 0.2px;
          }
          #t1l_1 {
            left: 143px;
            bottom: 554px;
            word-spacing: -0.4px;
          }
          #t1m_1 {
            left: 237px;
            bottom: 535px;
            letter-spacing: 0.2px;
          }
          #t1n_1 {
            left: 143px;
            bottom: 535px;
            letter-spacing: 0.1px;
            word-spacing: -0.1px;
          }
          #t1o_1 {
            left: 354px;
            bottom: 511px;
            letter-spacing: 0.1px;
          }
          #t1p_1 {
            left: 143px;
            bottom: 511px;
            letter-spacing: 0.2px;
            word-spacing: 0.1px;
          }
          #t1q_1 {
            left: 541px;
            bottom: 488px;
            letter-spacing: 0.2px;
          }
          #t1r_1 {
            left: 685px;
            bottom: 488px;
            letter-spacing: 0.2px;
          }
          #t1s_1 {
            left: 794px;
            bottom: 488px;
            letter-spacing: 0.1px;
          }
          #t1t_1 {
            left: 143px;
            bottom: 489px;
            letter-spacing: -0.2px;
          }
          #t1u_1 {
            left: 586px;
            bottom: 489px;
            letter-spacing: -0.2px;
            word-spacing: 19.7px;
          }
          #t1v_1 {
            left: 110px;
            bottom: 491px;
          }
          #t1w_1 {
            left: 143px;
            bottom: 469px;
            letter-spacing: -0.1px;
            word-spacing: 0.2px;
          }
          #t1x_1 {
            left: 143px;
            bottom: 449px;
            letter-spacing: -0.2px;
            word-spacing: 0.2px;
          }
          #t1y_1 {
            left: 237px;
            bottom: 430px;
            letter-spacing: 0.2px;
          }
          #t1z_1 {
            left: 143px;
            bottom: 430px;
            letter-spacing: 0.1px;
            word-spacing: -0.1px;
          }
          #t20_1 {
            left: 354px;
            bottom: 406px;
            letter-spacing: 0.1px;
          }
          #t21_1 {
            left: 143px;
            bottom: 406px;
            letter-spacing: 0.2px;
            word-spacing: 0.1px;
          }
          #t22_1 {
            left: 801px;
            bottom: 242px;
          }
          #t23_1 {
            left: 722px;
            bottom: 241px;
          }
          #t24_1 {
            left: 588px;
            bottom: 240px;
            letter-spacing: -0.1px;
          }
          #t25_1 {
            left: 109px;
            bottom: 135px;
            word-spacing: 0.5px;
          }
          #t26_1 {
            left: 551px;
            bottom: 135px;
            letter-spacing: -0.1px;
          }
          #t27_1 {
            left: 87px;
            bottom: 108px;
            letter-spacing: 0.1px;
          }
          #t28_1 {
            left: 208px;
            bottom: 108px;
          }
          #t29_1 {
            left: 339px;
            bottom: 108px;
            letter-spacing: 0.2px;
          }
          #t2a_1 {
            left: 496px;
            bottom: 108px;
            letter-spacing: 0.1px;
            word-spacing: 0.4px;
          }
          #t2b_1 {
            left: 700px;
            bottom: 108px;
            word-spacing: 0.4px;
          }
          #t2c_1 {
            left: 87px;
            bottom: 94px;
            word-spacing: -0.1px;
          }
          #t2d_1 {
            left: 208px;
            bottom: 94px;
            word-spacing: 0.5px;
          }
          #t2e_1 {
            left: 339px;
            bottom: 94px;
            letter-spacing: -0.1px;
            word-spacing: 0.6px;
          }
          #t2f_1 {
            left: 496px;
            bottom: 94px;
            word-spacing: 0.2px;
          }
          #t2g_1 {
            left: 700px;
            bottom: 94px;
            word-spacing: 0.4px;
          }
          #t2h_1 {
            left: 208px;
            bottom: 80px;
            word-spacing: 0.5px;
          }
          #t2i_1 {
            left: 339px;
            bottom: 80px;
            word-spacing: 0.5px;
          }
          #t2j_1 {
            left: 496px;
            bottom: 80px;
            word-spacing: 0.5px;
          }
          #t2k_1 {
            left: 700px;
            bottom: 80px;
            letter-spacing: 0.1px;
            word-spacing: 0.4px;
          }
          #t2l_1 {
            left: 208px;
            bottom: 67px;
            letter-spacing: 0.1px;
            word-spacing: -0.2px;
          }
          #t2m_1 {
            left: 339px;
            bottom: 67px;
            letter-spacing: 0.1px;
            word-spacing: 0.4px;
          }
          #t2n_1 {
            left: 496px;
            bottom: 67px;
            letter-spacing: 0.2px;
            word-spacing: -0.3px;
          }
          #t2o_1 {
            left: 700px;
            bottom: 67px;
            letter-spacing: 0.1px;
            word-spacing: 0.3px;
          }
          #t2p_1 {
            left: 127px;
            bottom: 37px;
            letter-spacing: 0.1px;
            word-spacing: 0.4px;
          }
  
          .s1_1 {
            font-size: 36.7px;
            font-family: LiberationSans_l;
            color: rgb(255, 255, 255);
          }
  
          .s2_1 {
            font-size: 61.1px;
            font-family: LiberationSans_l;
            color: rgb(0, 0, 0);
          }
  
          .s3_1 {
            font-size: 48.9px;
            font-family: LiberationSans_l;
            color: rgb(0, 0, 128);
          }
  
          .s4_1 {
            font-size: 61.1px;
            font-family: LiberationSans-Bold_g;
            color: rgb(0, 0, 0);
          }
  
          .s5_1 {
            font-size: 67.2px;
            font-family: LiberationSans-Bold_g;
            color: rgb(0, 0, 0);
          }
  
          .s6_1 {
            font-size: 48.9px;
            font-family: LiberationSans-Bold_g;
            color: rgb(0, 0, 0);
          }
  
          .s7_1 {
            font-size: 48.9px;
            font-family: LiberationSans_l;
            color: rgb(0, 0, 0);
          }
  
          .s8_1 {
            font-size: 67.2px;
            font-family: LiberationSans_l;
            color: rgb(0, 0, 0);
          }
  
          .s9_1 {
            font-size: 55.1px;
            font-family: LiberationSans_l;
            color: rgb(0, 0, 0);
          }
        </style>
        <!-- End inline CSS -->
  
        <!-- Begin embedded font definitions -->
        <style id="fonts1" type="text/css">
          @font-face {
            font-family: LiberationSans-Bold_g;
            src: url("fonts/LiberationSans-Bold_g.woff") format("woff");
          }
  
          @font-face {
            font-family: LiberationSans_l;
            src: url("fonts/LiberationSans_l.woff") format("woff");
          }
        </style>
        <!-- End embedded font definitions -->
  
        <!-- Begin page background -->
        <div
          id="pg1Overlay"
          style="
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0);
            -webkit-user-select: none;
          "
        ></div>
        <div id="pg1" style="-webkit-user-select: none">
          <object
            width="909"
            height="1286"
            data="1/1.svg"
            type="image/svg+xml"
            id="pdf1"
            style="
              width: 909px;
              height: 1286px;
              -moz-transform: scale(1);
              z-index: 0;
            "
          ></object>
        </div>
        <!-- End page background -->
  
        <!-- Begin text definitions (Positioned/styled in CSS) -->
        <div id="t1_1" class="t s1_1">@@BETREFF BE /</div>
        <div id="t2_1" class="t s1_1">APSOPARTS GMBH / 306371@@</div>
        <div id="t3_1" class="t s2_1">Ihr Ansprechpartner / Durchwahl</div>
        <div id="t4_1" class="t s3_1">
          TECMAT Handels-GmbH • Hauptstraße 56 • 88138 Sigmarszell/Schlachters
        </div>
        <div id="t5_1" class="t s2_1">Lukas Weber / (0 83 89) 9 29 49 - 12</div>
        <div id="t6_1" class="t s2_1">E-Mail</div>
        <div id="t7_1" class="t s2_1">l.weber@tecmat.de</div>
        <div id="t8_1" class="t s4_1">APSOparts GmbH</div>
        <div id="t9_1" class="t s4_1">Siemensstrasse 5</div>
        <div id="ta_1" class="t s2_1">#OCR-BE# 306371-1</div>
        <div id="tb_1" class="t s4_1">DE</div>
        <div id="tc_1" class="t s4_1">70736</div>
        <div id="td_1" class="t s4_1">Fellbach</div>
        <div id="te_1" class="t s5_1">BESTELLUNG</div>
        <div id="tf_1" class="t s4_1">306371</div>
        <div id="tg_1" class="t s6_1">01.07.2019</div>
        <div id="th_1" class="t s6_1">751850</div>
        <div id="ti_1" class="t s6_1">Beleg-Nr.</div>
        <div id="tj_1" class="t s6_1">Unsere Kd-Nr.</div>
        <div id="tk_1" class="t s6_1">Datum</div>
        <div id="tl_1" class="t s7_1">1</div>
        <div id="tm_1" class="t s7_1">Seite :</div>
        <div id="tn_1" class="t s4_1">TECMAT Handels GmbH</div>
        <div id="to_1" class="t s4_1">LIEFERADRESSE:</div>
        <div id="tp_1" class="t s4_1">Hauptstrasse 56</div>
        <div id="tq_1" class="t s4_1">D 88138</div>
        <div id="tr_1" class="t s4_1">Sigmarszell-Schlachters</div>
        <div id="ts_1" class="t s7_1">Pos.</div>
        <div id="tt_1" class="t s7_1">Artikel-Nummer</div>
        <div id="tu_1" class="t s7_1">Menge</div>
        <div id="tv_1" class="t s7_1">Einheit PE</div>
        <div id="tw_1" class="t s7_1">E-Preis</div>
        <div id="tx_1" class="t s7_1">%</div>
        <div id="ty_1" class="t s7_1">Gesamt</div>
        <div id="tz_1" class="t s7_1">EUR</div>
        <div id="t10_1" class="t s7_1">EUR</div>
        <div id="t11_1" class="t s7_1">Bezeichnung</div>
        <div id="t12_1" class="t s8_1">40,00</div>
        <div id="t13_1" class="t s8_1">14,06</div>
        <div id="t14_1" class="t s8_1">562,40</div>
        <div id="t15_1" class="t s2_1">08-04-282364</div>
        <div id="t16_1" class="t s2_1">Stück 1</div>
        <div id="t17_1" class="t s9_1">1</div>
        <div id="t18_1" class="t s4_1">Zahnriemen Synchroflex</div>
        <div id="t19_1" class="t s2_1">25 AT 5 - 975 mm</div>
        <div id="t1a_1" class="t s9_1">0918317147</div>
        <div id="t1b_1" class="t s9_1">Ihre Artikel-Nr.</div>
        <div id="t1c_1" class="t s5_1">12.09.2019</div>
        <div id="t1d_1" class="t s5_1">Lieferdatum eintreffend:</div>
        <div id="t1e_1" class="t s8_1">40,00</div>
        <div id="t1f_1" class="t s8_1">3,73</div>
        <div id="t1g_1" class="t s8_1">149,20</div>
        <div id="t1h_1" class="t s2_1">08-04-272831</div>
        <div id="t1i_1" class="t s2_1">Stück 1</div>
        <div id="t1j_1" class="t s9_1">2</div>
        <div id="t1k_1" class="t s4_1">Zahnriemen Synchroflex</div>
        <div id="t1l_1" class="t s2_1">Typ 10 AT5 - 390</div>
        <div id="t1m_1" class="t s9_1">0918316975</div>
        <div id="t1n_1" class="t s9_1">Ihre Artikel-Nr.</div>
        <div id="t1o_1" class="t s5_1">12.09.2019</div>
        <div id="t1p_1" class="t s5_1">Lieferdatum eintreffend:</div>
        <div id="t1q_1" class="t s8_1">80,00</div>
        <div id="t1r_1" class="t s8_1">7,18</div>
        <div id="t1s_1" class="t s8_1">574,40</div>
        <div id="t1t_1" class="t s2_1">08-04-268821</div>
        <div id="t1u_1" class="t s2_1">Stück 1</div>
        <div id="t1v_1" class="t s9_1">3</div>
        <div id="t1w_1" class="t s4_1">Zahnriemen Synchroflex</div>
        <div id="t1x_1" class="t s2_1">25 AT 5 - 390</div>
        <div id="t1y_1" class="t s9_1">0918316979</div>
        <div id="t1z_1" class="t s9_1">Ihre Artikel-Nr.</div>
        <div id="t20_1" class="t s5_1">12.09.2019</div>
        <div id="t21_1" class="t s5_1">Lieferdatum eintreffend:</div>
        <div id="t22_1" class="t s7_1">1286,00</div>
        <div id="t23_1" class="t s7_1">EUR</div>
        <div id="t24_1" class="t s7_1">Übertrag</div>
        <div id="t25_1" class="t s7_1">
          (PE 1= pro1, 2= pro10, 3= pro100, 4= pro1.000)
        </div>
        <div id="t26_1" class="t s7_1">82199</div>
        <div id="t27_1" class="t s3_1">Geschäftsführung</div>
        <div id="t28_1" class="t s3_1">Handelsregister</div>
        <div id="t29_1" class="t s3_1">Umsatzst.Ident.Nr.</div>
        <div id="t2a_1" class="t s3_1">Bayerische Bodenseebank Lindau</div>
        <div id="t2b_1" class="t s3_1">Tel. 0049 (0)8389 / 92 94 9-0</div>
        <div id="t2c_1" class="t s3_1">Hermann Zöller</div>
        <div id="t2d_1" class="t s3_1">Kempten HRB 6382</div>
        <div id="t2e_1" class="t s3_1">DE 182358703</div>
        <div id="t2f_1" class="t s3_1">Konto 44 58 43 • BLZ 733 698 21</div>
        <div id="t2g_1" class="t s3_1">Fax 0049 (0)8389 / 92 94 949</div>
        <div id="t2h_1" class="t s3_1">Erfüllungsort und</div>
        <div id="t2i_1" class="t s3_1">Steuer.Nr. 127/140/10105</div>
        <div id="t2j_1" class="t s3_1">IBAN: DE48733698210000445843</div>
        <div id="t2k_1" class="t s3_1">Internet: www.tecmat.de</div>
        <div id="t2l_1" class="t s3_1">Gerichtstand Lindau</div>
        <div id="t2m_1" class="t s3_1">Finanzamt Kempten</div>
        <div id="t2n_1" class="t s3_1">SWIFT (BIC) GENODEF1LBB</div>
        <div id="t2o_1" class="t s3_1">e-mail: info@tecmat.de</div>
        <div id="t2p_1" class="t s3_1">
          Es gelten grundsätzlich unsere allgemeinen Geschäftsbedingungen.
          Anderslautende Bedingungen werden nicht anerkannt.
        </div>
  
        <!-- End text definitions -->
      </div>
      <div
        id="p2"
        style="
          overflow: hidden;
          position: relative;
          background-color: white;
          width: 909px;
          height: 1286px;
        "
      >
        <!-- Begin shared CSS values -->
        <style class="shared-css" type="text/css">
          .t {
            -webkit-transform-origin: bottom left;
            -ms-transform-origin: bottom left;
            transform-origin: bottom left;
            -webkit-transform: scale(0.25);
            -ms-transform: scale(0.25);
            transform: scale(0.25);
            z-index: 2;
            position: absolute;
            white-space: pre;
            overflow: visible;
            line-height: 1.5;
          }
        </style>
        <!-- End shared CSS values -->
  
        <!-- Begin inline CSS -->
        <style type="text/css">
          #t1_2 {
            left: 32px;
            bottom: 1142px;
            word-spacing: -0.1px;
          }
          #t2_2 {
            left: 118px;
            bottom: 1142px;
          }
          #t3_2 {
            left: 582px;
            bottom: 1044px;
            letter-spacing: -0.1px;
            word-spacing: 0.1px;
          }
          #t4_2 {
            left: 109px;
            bottom: 1031px;
            letter-spacing: 0.1px;
            word-spacing: 0.2px;
          }
          #t5_2 {
            left: 582px;
            bottom: 1024px;
            letter-spacing: -0.2px;
          }
          #t6_2 {
            left: 582px;
            bottom: 1003px;
            letter-spacing: -0.2px;
          }
          #t7_2 {
            left: 582px;
            bottom: 985px;
            letter-spacing: -0.2px;
          }
          #t8_2 {
            left: 109px;
            bottom: 983px;
            letter-spacing: -0.1px;
            word-spacing: 0.2px;
          }
          #t9_2 {
            left: 109px;
            bottom: 964px;
            letter-spacing: -0.2px;
            word-spacing: 0.3px;
          }
          #ta_2 {
            left: 583px;
            bottom: 961px;
            letter-spacing: -0.2px;
            word-spacing: -0.3px;
          }
          #tb_2 {
            left: 109px;
            bottom: 931px;
            letter-spacing: -0.2px;
          }
          #tc_2 {
            left: 143px;
            bottom: 931px;
            letter-spacing: -0.3px;
          }
          #td_2 {
            left: 198px;
            bottom: 931px;
            letter-spacing: -0.1px;
          }
          #te_2 {
            left: 588px;
            bottom: 923px;
          }
          #tf_2 {
            left: 586px;
            bottom: 892px;
            letter-spacing: -0.3px;
          }
          #tg_2 {
            left: 789px;
            bottom: 894px;
          }
          #th_2 {
            left: 676px;
            bottom: 893px;
            letter-spacing: -0.1px;
          }
          #ti_2 {
            left: 589px;
            bottom: 869px;
          }
          #tj_2 {
            left: 677px;
            bottom: 869px;
            word-spacing: 0.5px;
          }
          #tk_2 {
            left: 790px;
            bottom: 869px;
            letter-spacing: -0.1px;
          }
          #tl_2 {
            left: 620px;
            bottom: 809px;
          }
          #tm_2 {
            left: 582px;
            bottom: 809px;
            letter-spacing: 0.1px;
            word-spacing: 0.4px;
          }
          #tn_2 {
            left: 246px;
            bottom: 782px;
            letter-spacing: -0.1px;
            word-spacing: 0.2px;
          }
          #to_2 {
            left: 109px;
            bottom: 782px;
            letter-spacing: -0.2px;
          }
          #tp_2 {
            left: 246px;
            bottom: 762px;
            letter-spacing: -0.2px;
            word-spacing: 0.3px;
          }
          #tq_2 {
            left: 384px;
            bottom: 762px;
            letter-spacing: -0.2px;
            word-spacing: 0.4px;
          }
          #tr_2 {
            left: 450px;
            bottom: 762px;
            letter-spacing: -0.1px;
          }
          #ts_2 {
            left: 114px;
            bottom: 743px;
            letter-spacing: 0.2px;
          }
          #tt_2 {
            left: 147px;
            bottom: 743px;
            letter-spacing: 0.1px;
          }
          #tu_2 {
            left: 518px;
            bottom: 743px;
          }
          #tv_2 {
            left: 588px;
            bottom: 743px;
            letter-spacing: 0.1px;
            word-spacing: 1.9px;
          }
          #tw_2 {
            left: 661px;
            bottom: 743px;
            letter-spacing: 0.1px;
          }
          #tx_2 {
            left: 734px;
            bottom: 743px;
          }
          #ty_2 {
            left: 781px;
            bottom: 743px;
            letter-spacing: 0.1px;
          }
          #tz_2 {
            left: 669px;
            bottom: 725px;
            letter-spacing: 0.2px;
          }
          #t10_2 {
            left: 788px;
            bottom: 725px;
          }
          #t11_2 {
            left: 147px;
            bottom: 725px;
            letter-spacing: 0.1px;
          }
          #t12_2 {
            left: 541px;
            bottom: 699px;
            letter-spacing: 0.2px;
          }
          #t13_2 {
            left: 685px;
            bottom: 699px;
            letter-spacing: 0.2px;
          }
          #t14_2 {
            left: 794px;
            bottom: 699px;
            letter-spacing: 0.1px;
          }
          #t15_2 {
            left: 143px;
            bottom: 699px;
            letter-spacing: -0.2px;
          }
          #t16_2 {
            left: 586px;
            bottom: 699px;
            letter-spacing: -0.2px;
            word-spacing: 19.7px;
          }
          #t17_2 {
            left: 110px;
            bottom: 701px;
          }
          #t18_2 {
            left: 143px;
            bottom: 679px;
            letter-spacing: -0.1px;
            word-spacing: 0.2px;
          }
          #t19_2 {
            left: 143px;
            bottom: 659px;
            letter-spacing: -0.2px;
            word-spacing: 0.2px;
          }
          #t1a_2 {
            left: 143px;
            bottom: 642px;
            letter-spacing: -0.1px;
            word-spacing: -0.3px;
          }
          #t1b_2 {
            left: 237px;
            bottom: 621px;
            letter-spacing: 0.2px;
          }
          #t1c_2 {
            left: 143px;
            bottom: 621px;
            letter-spacing: 0.1px;
            word-spacing: -0.1px;
          }
          #t1d_2 {
            left: 354px;
            bottom: 597px;
            letter-spacing: 0.1px;
          }
          #t1e_2 {
            left: 143px;
            bottom: 597px;
            letter-spacing: 0.2px;
            word-spacing: 0.1px;
          }
          #t1f_2 {
            left: 541px;
            bottom: 573px;
            letter-spacing: 0.2px;
          }
          #t1g_2 {
            left: 685px;
            bottom: 573px;
            letter-spacing: 0.2px;
          }
          #t1h_2 {
            left: 794px;
            bottom: 573px;
            letter-spacing: 0.1px;
          }
          #t1i_2 {
            left: 143px;
            bottom: 574px;
            letter-spacing: -0.2px;
          }
          #t1j_2 {
            left: 586px;
            bottom: 574px;
            letter-spacing: -0.2px;
            word-spacing: 19.7px;
          }
          #t1k_2 {
            left: 110px;
            bottom: 576px;
          }
          #t1l_2 {
            left: 143px;
            bottom: 554px;
            letter-spacing: -0.1px;
            word-spacing: 0.2px;
          }
          #t1m_2 {
            left: 143px;
            bottom: 534px;
            letter-spacing: -0.2px;
            word-spacing: 0.4px;
          }
          #t1n_2 {
            left: 237px;
            bottom: 515px;
            letter-spacing: 0.2px;
          }
          #t1o_2 {
            left: 143px;
            bottom: 515px;
            letter-spacing: 0.1px;
            word-spacing: -0.1px;
          }
          #t1p_2 {
            left: 354px;
            bottom: 491px;
            letter-spacing: 0.1px;
          }
          #t1q_2 {
            left: 143px;
            bottom: 491px;
            letter-spacing: 0.2px;
            word-spacing: 0.1px;
          }
          #t1r_2 {
            left: 550px;
            bottom: 468px;
            letter-spacing: 0.2px;
          }
          #t1s_2 {
            left: 676px;
            bottom: 468px;
            letter-spacing: 0.2px;
          }
          #t1t_2 {
            left: 794px;
            bottom: 468px;
            letter-spacing: 0.1px;
          }
          #t1u_2 {
            left: 143px;
            bottom: 469px;
            letter-spacing: -0.2px;
          }
          #t1v_2 {
            left: 586px;
            bottom: 469px;
            letter-spacing: -0.2px;
            word-spacing: 19.7px;
          }
          #t1w_2 {
            left: 110px;
            bottom: 471px;
          }
          #t1x_2 {
            left: 143px;
            bottom: 449px;
            letter-spacing: -0.1px;
            word-spacing: 0.2px;
          }
          #t1y_2 {
            left: 143px;
            bottom: 429px;
            letter-spacing: -0.2px;
            word-spacing: 0.4px;
          }
          #t1z_2 {
            left: 237px;
            bottom: 410px;
            letter-spacing: 0.2px;
          }
          #t20_2 {
            left: 143px;
            bottom: 410px;
            letter-spacing: 0.1px;
            word-spacing: -0.1px;
          }
          #t21_2 {
            left: 354px;
            bottom: 386px;
            letter-spacing: 0.1px;
          }
          #t22_2 {
            left: 143px;
            bottom: 386px;
            letter-spacing: 0.2px;
            word-spacing: 0.1px;
          }
          #t23_2 {
            left: 801px;
            bottom: 242px;
          }
          #t24_2 {
            left: 722px;
            bottom: 241px;
          }
          #t25_2 {
            left: 588px;
            bottom: 240px;
            letter-spacing: -0.1px;
          }
          #t26_2 {
            left: 109px;
            bottom: 135px;
            word-spacing: 0.5px;
          }
          #t27_2 {
            left: 551px;
            bottom: 135px;
            letter-spacing: -0.1px;
          }
          #t28_2 {
            left: 87px;
            bottom: 108px;
            letter-spacing: 0.1px;
          }
          #t29_2 {
            left: 208px;
            bottom: 108px;
          }
          #t2a_2 {
            left: 339px;
            bottom: 108px;
            letter-spacing: 0.2px;
          }
          #t2b_2 {
            left: 496px;
            bottom: 108px;
            letter-spacing: 0.1px;
            word-spacing: 0.4px;
          }
          #t2c_2 {
            left: 700px;
            bottom: 108px;
            word-spacing: 0.4px;
          }
          #t2d_2 {
            left: 87px;
            bottom: 94px;
            word-spacing: -0.1px;
          }
          #t2e_2 {
            left: 208px;
            bottom: 94px;
            word-spacing: 0.5px;
          }
          #t2f_2 {
            left: 339px;
            bottom: 94px;
            letter-spacing: -0.1px;
            word-spacing: 0.6px;
          }
          #t2g_2 {
            left: 496px;
            bottom: 94px;
            word-spacing: 0.2px;
          }
          #t2h_2 {
            left: 700px;
            bottom: 94px;
            word-spacing: 0.4px;
          }
          #t2i_2 {
            left: 208px;
            bottom: 80px;
            word-spacing: 0.5px;
          }
          #t2j_2 {
            left: 339px;
            bottom: 80px;
            word-spacing: 0.5px;
          }
          #t2k_2 {
            left: 496px;
            bottom: 80px;
            word-spacing: 0.5px;
          }
          #t2l_2 {
            left: 700px;
            bottom: 80px;
            letter-spacing: 0.1px;
            word-spacing: 0.4px;
          }
          #t2m_2 {
            left: 208px;
            bottom: 67px;
            letter-spacing: 0.1px;
            word-spacing: -0.2px;
          }
          #t2n_2 {
            left: 339px;
            bottom: 67px;
            letter-spacing: 0.1px;
            word-spacing: 0.4px;
          }
          #t2o_2 {
            left: 496px;
            bottom: 67px;
            letter-spacing: 0.2px;
            word-spacing: -0.3px;
          }
          #t2p_2 {
            left: 700px;
            bottom: 67px;
            letter-spacing: 0.1px;
            word-spacing: 0.3px;
          }
          #t2q_2 {
            left: 127px;
            bottom: 37px;
            letter-spacing: 0.1px;
            word-spacing: 0.4px;
          }
  
          .s1_2 {
            font-size: 36.7px;
            font-family: LiberationSans_l;
            color: rgb(255, 255, 255);
          }
  
          .s2_2 {
            font-size: 61.1px;
            font-family: LiberationSans_l;
            color: rgb(0, 0, 0);
          }
  
          .s3_2 {
            font-size: 48.9px;
            font-family: LiberationSans_l;
            color: rgb(0, 0, 128);
          }
  
          .s4_2 {
            font-size: 61.1px;
            font-family: LiberationSans-Bold_g;
            color: rgb(0, 0, 0);
          }
  
          .s5_2 {
            font-size: 67.2px;
            font-family: LiberationSans-Bold_g;
            color: rgb(0, 0, 0);
          }
  
          .s6_2 {
            font-size: 48.9px;
            font-family: LiberationSans-Bold_g;
            color: rgb(0, 0, 0);
          }
  
          .s7_2 {
            font-size: 48.9px;
            font-family: LiberationSans_l;
            color: rgb(0, 0, 0);
          }
  
          .s8_2 {
            font-size: 67.2px;
            font-family: LiberationSans_l;
            color: rgb(0, 0, 0);
          }
  
          .s9_2 {
            font-size: 55.1px;
            font-family: LiberationSans_l;
            color: rgb(0, 0, 0);
          }
        </style>
        <!-- End inline CSS -->
  
        <!-- Begin embedded font definitions -->
        <style id="fonts2" type="text/css">
          @font-face {
            font-family: LiberationSans-Bold_g;
            src: url("fonts/LiberationSans-Bold_g.woff") format("woff");
          }
  
          @font-face {
            font-family: LiberationSans_l;
            src: url("fonts/LiberationSans_l.woff") format("woff");
          }
        </style>
        <!-- End embedded font definitions -->
  
        <!-- Begin page background -->
        <div
          id="pg2Overlay"
          style="
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0);
            -webkit-user-select: none;
          "
        ></div>
        <div id="pg2" style="-webkit-user-select: none">
          <object
            width="909"
            height="1286"
            data="2/2.svg"
            type="image/svg+xml"
            id="pdf2"
            style="
              width: 909px;
              height: 1286px;
              -moz-transform: scale(1);
              z-index: 0;
            "
          ></object>
        </div>
        <!-- End page background -->
  
        <!-- Begin text definitions (Positioned/styled in CSS) -->
        <div id="t1_2" class="t s1_2">@@BETREFF BE /</div>
        <div id="t2_2" class="t s1_2">APSOPARTS GMBH / 306371@@</div>
        <div id="t3_2" class="t s2_2">Ihr Ansprechpartner / Durchwahl</div>
        <div id="t4_2" class="t s3_2">
          TECMAT Handels-GmbH • Hauptstraße 56 • 88138 Sigmarszell/Schlachters
        </div>
        <div id="t5_2" class="t s2_2">Lukas Weber / (0 83 89) 9 29 49 - 12</div>
        <div id="t6_2" class="t s2_2">E-Mail</div>
        <div id="t7_2" class="t s2_2">l.weber@tecmat.de</div>
        <div id="t8_2" class="t s4_2">APSOparts GmbH</div>
        <div id="t9_2" class="t s4_2">Siemensstrasse 5</div>
        <div id="ta_2" class="t s2_2">#OCR-BE# 306371-1</div>
        <div id="tb_2" class="t s4_2">DE</div>
        <div id="tc_2" class="t s4_2">70736</div>
        <div id="td_2" class="t s4_2">Fellbach</div>
        <div id="te_2" class="t s5_2">BESTELLUNG</div>
        <div id="tf_2" class="t s4_2">306371</div>
        <div id="tg_2" class="t s6_2">01.07.2019</div>
        <div id="th_2" class="t s6_2">751850</div>
        <div id="ti_2" class="t s6_2">Beleg-Nr.</div>
        <div id="tj_2" class="t s6_2">Unsere Kd-Nr.</div>
        <div id="tk_2" class="t s6_2">Datum</div>
        <div id="tl_2" class="t s7_2">2</div>
        <div id="tm_2" class="t s7_2">Seite :</div>
        <div id="tn_2" class="t s4_2">TECMAT Handels GmbH</div>
        <div id="to_2" class="t s4_2">LIEFERADRESSE:</div>
        <div id="tp_2" class="t s4_2">Hauptstrasse 56</div>
        <div id="tq_2" class="t s4_2">D 88138</div>
        <div id="tr_2" class="t s4_2">Sigmarszell-Schlachters</div>
        <div id="ts_2" class="t s7_2">Pos.</div>
        <div id="tt_2" class="t s7_2">Artikel-Nummer</div>
        <div id="tu_2" class="t s7_2">Menge</div>
        <div id="tv_2" class="t s7_2">Einheit PE</div>
        <div id="tw_2" class="t s7_2">E-Preis</div>
        <div id="tx_2" class="t s7_2">%</div>
        <div id="ty_2" class="t s7_2">Gesamt</div>
        <div id="tz_2" class="t s7_2">EUR</div>
        <div id="t10_2" class="t s7_2">EUR</div>
        <div id="t11_2" class="t s7_2">Bezeichnung</div>
        <div id="t12_2" class="t s8_2">40,00</div>
        <div id="t13_2" class="t s8_2">8,05</div>
        <div id="t14_2" class="t s8_2">322,00</div>
        <div id="t15_2" class="t s2_2">08-04-283192</div>
        <div id="t16_2" class="t s2_2">Stück 1</div>
        <div id="t17_2" class="t s9_2">4</div>
        <div id="t18_2" class="t s4_2">Zahnriemen Synchroflex</div>
        <div id="t19_2" class="t s2_2">25 AT 5 - 500</div>
        <div id="t1a_2" class="t s2_2">mit Kevlar-Zugstrang P 3/8</div>
        <div id="t1b_2" class="t s9_2">8000301362</div>
        <div id="t1c_2" class="t s9_2">Ihre Artikel-Nr.</div>
        <div id="t1d_2" class="t s5_2">12.09.2019</div>
        <div id="t1e_2" class="t s5_2">Lieferdatum eintreffend:</div>
        <div id="t1f_2" class="t s8_2">70,00</div>
        <div id="t1g_2" class="t s8_2">8,36</div>
        <div id="t1h_2" class="t s8_2">585,20</div>
        <div id="t1i_2" class="t s2_2">08-04-269214</div>
        <div id="t1j_2" class="t s2_2">Stück 1</div>
        <div id="t1k_2" class="t s9_2">5</div>
        <div id="t1l_2" class="t s4_2">Zahnriemen Synchroflex</div>
        <div id="t1m_2" class="t s2_2">16 AT5 - 720</div>
        <div id="t1n_2" class="t s9_2">0918317097</div>
        <div id="t1o_2" class="t s9_2">Ihre Artikel-Nr.</div>
        <div id="t1p_2" class="t s5_2">12.09.2019</div>
        <div id="t1q_2" class="t s5_2">Lieferdatum eintreffend:</div>
        <div id="t1r_2" class="t s8_2">5,00</div>
        <div id="t1s_2" class="t s8_2">32,50</div>
        <div id="t1t_2" class="t s8_2">162,50</div>
        <div id="t1u_2" class="t s2_2">08-04-1030835</div>
        <div id="t1v_2" class="t s2_2">Stück 1</div>
        <div id="t1w_2" class="t s9_2">6</div>
        <div id="t1x_2" class="t s4_2">Zahnriemen Synchroflex</div>
        <div id="t1y_2" class="t s2_2">50 AT5 - 1125</div>
        <div id="t1z_2" class="t s9_2">0918317175</div>
        <div id="t20_2" class="t s9_2">Ihre Artikel-Nr.</div>
        <div id="t21_2" class="t s5_2">12.09.2019</div>
        <div id="t22_2" class="t s5_2">Lieferdatum eintreffend:</div>
        <div id="t23_2" class="t s7_2">2355,70</div>
        <div id="t24_2" class="t s7_2">EUR</div>
        <div id="t25_2" class="t s7_2">Übertrag</div>
        <div id="t26_2" class="t s7_2">
          (PE 1= pro1, 2= pro10, 3= pro100, 4= pro1.000)
        </div>
        <div id="t27_2" class="t s7_2">82199</div>
        <div id="t28_2" class="t s3_2">Geschäftsführung</div>
        <div id="t29_2" class="t s3_2">Handelsregister</div>
        <div id="t2a_2" class="t s3_2">Umsatzst.Ident.Nr.</div>
        <div id="t2b_2" class="t s3_2">Bayerische Bodenseebank Lindau</div>
        <div id="t2c_2" class="t s3_2">Tel. 0049 (0)8389 / 92 94 9-0</div>
        <div id="t2d_2" class="t s3_2">Hermann Zöller</div>
        <div id="t2e_2" class="t s3_2">Kempten HRB 6382</div>
        <div id="t2f_2" class="t s3_2">DE 182358703</div>
        <div id="t2g_2" class="t s3_2">Konto 44 58 43 • BLZ 733 698 21</div>
        <div id="t2h_2" class="t s3_2">Fax 0049 (0)8389 / 92 94 949</div>
        <div id="t2i_2" class="t s3_2">Erfüllungsort und</div>
        <div id="t2j_2" class="t s3_2">Steuer.Nr. 127/140/10105</div>
        <div id="t2k_2" class="t s3_2">IBAN: DE48733698210000445843</div>
        <div id="t2l_2" class="t s3_2">Internet: www.tecmat.de</div>
        <div id="t2m_2" class="t s3_2">Gerichtstand Lindau</div>
        <div id="t2n_2" class="t s3_2">Finanzamt Kempten</div>
        <div id="t2o_2" class="t s3_2">SWIFT (BIC) GENODEF1LBB</div>
        <div id="t2p_2" class="t s3_2">e-mail: info@tecmat.de</div>
        <div id="t2q_2" class="t s3_2">
          Es gelten grundsätzlich unsere allgemeinen Geschäftsbedingungen.
          Anderslautende Bedingungen werden nicht anerkannt.
        </div>
  
        <!-- End text definitions -->
      </div>
      <div
        id="p3"
        style="
          overflow: hidden;
          position: relative;
          background-color: white;
          width: 909px;
          height: 1286px;
        "
      >
        <!-- Begin shared CSS values -->
        <style class="shared-css" type="text/css">
          .t {
            -webkit-transform-origin: bottom left;
            -ms-transform-origin: bottom left;
            transform-origin: bottom left;
            -webkit-transform: scale(0.25);
            -ms-transform: scale(0.25);
            transform: scale(0.25);
            z-index: 2;
            position: absolute;
            white-space: pre;
            overflow: visible;
            line-height: 1.5;
          }
        </style>
        <!-- End shared CSS values -->
  
        <!-- Begin inline CSS -->
        <style type="text/css">
          #t1_3 {
            left: 32px;
            bottom: 1142px;
            word-spacing: -0.1px;
          }
          #t2_3 {
            left: 118px;
            bottom: 1142px;
          }
          #t3_3 {
            left: 582px;
            bottom: 1044px;
            letter-spacing: -0.1px;
            word-spacing: 0.1px;
          }
          #t4_3 {
            left: 109px;
            bottom: 1031px;
            letter-spacing: 0.1px;
            word-spacing: 0.2px;
          }
          #t5_3 {
            left: 582px;
            bottom: 1024px;
            letter-spacing: -0.2px;
          }
          #t6_3 {
            left: 582px;
            bottom: 1003px;
            letter-spacing: -0.2px;
          }
          #t7_3 {
            left: 582px;
            bottom: 985px;
            letter-spacing: -0.2px;
          }
          #t8_3 {
            left: 109px;
            bottom: 983px;
            letter-spacing: -0.1px;
            word-spacing: 0.2px;
          }
          #t9_3 {
            left: 109px;
            bottom: 964px;
            letter-spacing: -0.2px;
            word-spacing: 0.3px;
          }
          #ta_3 {
            left: 583px;
            bottom: 961px;
            letter-spacing: -0.2px;
            word-spacing: -0.3px;
          }
          #tb_3 {
            left: 109px;
            bottom: 931px;
            letter-spacing: -0.2px;
          }
          #tc_3 {
            left: 143px;
            bottom: 931px;
            letter-spacing: -0.3px;
          }
          #td_3 {
            left: 198px;
            bottom: 931px;
            letter-spacing: -0.1px;
          }
          #te_3 {
            left: 588px;
            bottom: 923px;
          }
          #tf_3 {
            left: 586px;
            bottom: 892px;
            letter-spacing: -0.3px;
          }
          #tg_3 {
            left: 789px;
            bottom: 894px;
          }
          #th_3 {
            left: 676px;
            bottom: 893px;
            letter-spacing: -0.1px;
          }
          #ti_3 {
            left: 589px;
            bottom: 869px;
          }
          #tj_3 {
            left: 677px;
            bottom: 869px;
            word-spacing: 0.5px;
          }
          #tk_3 {
            left: 790px;
            bottom: 869px;
            letter-spacing: -0.1px;
          }
          #tl_3 {
            left: 620px;
            bottom: 809px;
          }
          #tm_3 {
            left: 582px;
            bottom: 809px;
            letter-spacing: 0.1px;
            word-spacing: 0.4px;
          }
          #tn_3 {
            left: 246px;
            bottom: 782px;
            letter-spacing: -0.1px;
            word-spacing: 0.2px;
          }
          #to_3 {
            left: 109px;
            bottom: 782px;
            letter-spacing: -0.2px;
          }
          #tp_3 {
            left: 246px;
            bottom: 762px;
            letter-spacing: -0.2px;
            word-spacing: 0.3px;
          }
          #tq_3 {
            left: 384px;
            bottom: 762px;
            letter-spacing: -0.2px;
            word-spacing: 0.4px;
          }
          #tr_3 {
            left: 450px;
            bottom: 762px;
            letter-spacing: -0.1px;
          }
          #ts_3 {
            left: 114px;
            bottom: 743px;
            letter-spacing: 0.2px;
          }
          #tt_3 {
            left: 147px;
            bottom: 743px;
            letter-spacing: 0.1px;
          }
          #tu_3 {
            left: 518px;
            bottom: 743px;
          }
          #tv_3 {
            left: 588px;
            bottom: 743px;
            letter-spacing: 0.1px;
            word-spacing: 1.9px;
          }
          #tw_3 {
            left: 661px;
            bottom: 743px;
            letter-spacing: 0.1px;
          }
          #tx_3 {
            left: 734px;
            bottom: 743px;
          }
          #ty_3 {
            left: 781px;
            bottom: 743px;
            letter-spacing: 0.1px;
          }
          #tz_3 {
            left: 669px;
            bottom: 725px;
            letter-spacing: 0.2px;
          }
          #t10_3 {
            left: 788px;
            bottom: 725px;
          }
          #t11_3 {
            left: 147px;
            bottom: 725px;
            letter-spacing: 0.1px;
          }
          #t12_3 {
            left: 541px;
            bottom: 699px;
            letter-spacing: 0.2px;
          }
          #t13_3 {
            left: 676px;
            bottom: 699px;
            letter-spacing: 0.2px;
          }
          #t14_3 {
            left: 794px;
            bottom: 699px;
            letter-spacing: 0.1px;
          }
          #t15_3 {
            left: 143px;
            bottom: 699px;
            letter-spacing: -0.2px;
          }
          #t16_3 {
            left: 586px;
            bottom: 699px;
            letter-spacing: -0.2px;
            word-spacing: 16.6px;
          }
          #t17_3 {
            left: 110px;
            bottom: 701px;
          }
          #t18_3 {
            left: 143px;
            bottom: 679px;
            letter-spacing: -0.1px;
          }
          #t19_3 {
            left: 143px;
            bottom: 659px;
            letter-spacing: -0.2px;
            word-spacing: 0.4px;
          }
          #t1a_3 {
            left: 143px;
            bottom: 642px;
            letter-spacing: -0.2px;
          }
          #t1b_3 {
            left: 237px;
            bottom: 621px;
            letter-spacing: 0.2px;
          }
          #t1c_3 {
            left: 143px;
            bottom: 621px;
            letter-spacing: 0.1px;
            word-spacing: -0.1px;
          }
          #t1d_3 {
            left: 354px;
            bottom: 597px;
            letter-spacing: 0.1px;
          }
          #t1e_3 {
            left: 143px;
            bottom: 597px;
            letter-spacing: 0.2px;
            word-spacing: 0.1px;
          }
          #t1f_3 {
            left: 531px;
            bottom: 573px;
            letter-spacing: 0.1px;
          }
          #t1g_3 {
            left: 685px;
            bottom: 573px;
            letter-spacing: 0.2px;
          }
          #t1h_3 {
            left: 794px;
            bottom: 573px;
            letter-spacing: 0.1px;
          }
          #t1i_3 {
            left: 143px;
            bottom: 574px;
            letter-spacing: -0.2px;
          }
          #t1j_3 {
            left: 586px;
            bottom: 574px;
            letter-spacing: -0.2px;
            word-spacing: 19.7px;
          }
          #t1k_3 {
            left: 110px;
            bottom: 576px;
          }
          #t1l_3 {
            left: 143px;
            bottom: 554px;
            letter-spacing: -0.1px;
            word-spacing: 0.2px;
          }
          #t1m_3 {
            left: 143px;
            bottom: 534px;
            letter-spacing: -0.2px;
            word-spacing: 0.2px;
          }
          #t1n_3 {
            left: 237px;
            bottom: 515px;
            letter-spacing: 0.2px;
          }
          #t1o_3 {
            left: 143px;
            bottom: 515px;
            letter-spacing: 0.1px;
            word-spacing: -0.1px;
          }
          #t1p_3 {
            left: 354px;
            bottom: 491px;
            letter-spacing: 0.1px;
          }
          #t1q_3 {
            left: 143px;
            bottom: 491px;
            letter-spacing: 0.2px;
            word-spacing: 0.1px;
          }
          #t1r_3 {
            left: 146px;
            bottom: 456px;
            letter-spacing: 0.1px;
            word-spacing: 0.1px;
          }
          #t1s_3 {
            left: 146px;
            bottom: 442px;
            letter-spacing: 0.1px;
            word-spacing: 0.1px;
          }
          #t1t_3 {
            left: 146px;
            bottom: 428px;
            letter-spacing: 0.1px;
            word-spacing: 0.4px;
          }
          #t1u_3 {
            left: 146px;
            bottom: 401px;
            letter-spacing: -0.2px;
            word-spacing: 0.3px;
          }
          #t1v_3 {
            left: 146px;
            bottom: 383px;
            letter-spacing: -0.1px;
          }
          #t1w_3 {
            left: 146px;
            bottom: 365px;
            letter-spacing: -0.1px;
            word-spacing: -0.2px;
          }
          #t1x_3 {
            left: 146px;
            bottom: 344px;
            letter-spacing: -0.1px;
            word-spacing: -0.1px;
          }
          #t1y_3 {
            left: 146px;
            bottom: 326px;
            letter-spacing: -0.1px;
            word-spacing: 0.2px;
          }
          #t1z_3 {
            left: 256px;
            bottom: 198px;
            letter-spacing: -0.2px;
          }
          #t20_3 {
            left: 725px;
            bottom: 198px;
          }
          #t21_3 {
            left: 800px;
            bottom: 198px;
          }
          #t22_3 {
            left: 115px;
            bottom: 196px;
          }
          #t23_3 {
            left: 588px;
            bottom: 196px;
            letter-spacing: 0.2px;
          }
          #t24_3 {
            left: 256px;
            bottom: 176px;
            letter-spacing: 0.1px;
            word-spacing: -0.2px;
          }
          #t25_3 {
            left: 670px;
            bottom: 176px;
          }
          #t26_3 {
            left: 725px;
            bottom: 176px;
          }
          #t27_3 {
            left: 808px;
            bottom: 176px;
          }
          #t28_3 {
            left: 115px;
            bottom: 175px;
          }
          #t29_3 {
            left: 588px;
            bottom: 175px;
            letter-spacing: 0.2px;
          }
          #t2a_3 {
            left: 256px;
            bottom: 154px;
            letter-spacing: 0.1px;
            word-spacing: 0.3px;
          }
          #t2b_3 {
            left: 725px;
            bottom: 154px;
          }
          #t2c_3 {
            left: 801px;
            bottom: 154px;
          }
          #t2d_3 {
            left: 115px;
            bottom: 153px;
          }
          #t2e_3 {
            left: 588px;
            bottom: 153px;
            letter-spacing: 0.1px;
          }
          #t2f_3 {
            left: 109px;
            bottom: 135px;
            word-spacing: 0.5px;
          }
          #t2g_3 {
            left: 551px;
            bottom: 135px;
            letter-spacing: -0.1px;
          }
          #t2h_3 {
            left: 87px;
            bottom: 108px;
            letter-spacing: 0.1px;
          }
          #t2i_3 {
            left: 208px;
            bottom: 108px;
          }
          #t2j_3 {
            left: 339px;
            bottom: 108px;
            letter-spacing: 0.2px;
          }
          #t2k_3 {
            left: 496px;
            bottom: 108px;
            letter-spacing: 0.1px;
            word-spacing: 0.4px;
          }
          #t2l_3 {
            left: 700px;
            bottom: 108px;
            word-spacing: 0.4px;
          }
          #t2m_3 {
            left: 87px;
            bottom: 94px;
            word-spacing: -0.1px;
          }
          #t2n_3 {
            left: 208px;
            bottom: 94px;
            word-spacing: 0.5px;
          }
          #t2o_3 {
            left: 339px;
            bottom: 94px;
            letter-spacing: -0.1px;
            word-spacing: 0.6px;
          }
          #t2p_3 {
            left: 496px;
            bottom: 94px;
            word-spacing: 0.2px;
          }
          #t2q_3 {
            left: 700px;
            bottom: 94px;
            word-spacing: 0.4px;
          }
          #t2r_3 {
            left: 208px;
            bottom: 80px;
            word-spacing: 0.5px;
          }
          #t2s_3 {
            left: 339px;
            bottom: 80px;
            word-spacing: 0.5px;
          }
          #t2t_3 {
            left: 496px;
            bottom: 80px;
            word-spacing: 0.5px;
          }
          #t2u_3 {
            left: 700px;
            bottom: 80px;
            letter-spacing: 0.1px;
            word-spacing: 0.4px;
          }
          #t2v_3 {
            left: 208px;
            bottom: 67px;
            letter-spacing: 0.1px;
            word-spacing: -0.2px;
          }
          #t2w_3 {
            left: 339px;
            bottom: 67px;
            letter-spacing: 0.1px;
            word-spacing: 0.4px;
          }
          #t2x_3 {
            left: 496px;
            bottom: 67px;
            letter-spacing: 0.2px;
            word-spacing: -0.3px;
          }
          #t2y_3 {
            left: 700px;
            bottom: 67px;
            letter-spacing: 0.1px;
            word-spacing: 0.3px;
          }
          #t2z_3 {
            left: 127px;
            bottom: 37px;
            letter-spacing: 0.1px;
            word-spacing: 0.4px;
          }
  
          .s1 {
            font-size: 36.7px;
            font-family: LiberationSans_l;
            color: rgb(255, 255, 255);
          }
  
          .s2 {
            font-size: 61.1px;
            font-family: LiberationSans_l;
            color: rgb(0, 0, 0);
          }
  
          .s3 {
            font-size: 48.9px;
            font-family: LiberationSans_l;
            color: rgb(0, 0, 128);
          }
  
          .s4 {
            font-size: 61.1px;
            font-family: LiberationSans-Bold_g;
            color: rgb(0, 0, 0);
          }
  
          .s5 {
            font-size: 67.2px;
            font-family: LiberationSans-Bold_g;
            color: rgb(0, 0, 0);
          }
  
          .s6 {
            font-size: 48.9px;
            font-family: LiberationSans-Bold_g;
            color: rgb(0, 0, 0);
          }
  
          .s7 {
            font-size: 48.9px;
            font-family: LiberationSans_l;
            color: rgb(0, 0, 0);
          }
  
          .s8 {
            font-size: 67.2px;
            font-family: LiberationSans_l;
            color: rgb(0, 0, 0);
          }
  
          .s9 {
            font-size: 55.1px;
            font-family: LiberationSans_l;
            color: rgb(0, 0, 0);
          }
        </style>
        <!-- End inline CSS -->
  
        <!-- Begin embedded font definitions -->
        <style id="fonts3" type="text/css">
          @font-face {
            font-family: LiberationSans-Bold_g;
            src: url("fonts/LiberationSans-Bold_g.woff") format("woff");
          }
  
          @font-face {
            font-family: LiberationSans_l;
            src: url("fonts/LiberationSans_l.woff") format("woff");
          }
        </style>
        <!-- End embedded font definitions -->
  
        <!-- Begin page background -->
        <div
          id="pg3Overlay"
          style="
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0);
            -webkit-user-select: none;
          "
        ></div>
        <div id="pg3" style="-webkit-user-select: none">
          <object
            width="909"
            height="1286"
            data="3/3.svg"
            type="image/svg+xml"
            id="pdf3"
            style="
              width: 909px;
              height: 1286px;
              -moz-transform: scale(1);
              z-index: 0;
            "
          ></object>
        </div>
        <!-- End page background -->
  
        <!-- Begin text definitions (Positioned/styled in CSS) -->
        <div id="t1_3" class="t s1">@@BETREFF BE /</div>
        <div id="t2_3" class="t s1">APSOPARTS GMBH / 306371@@</div>
        <div id="t3_3" class="t s2">Ihr Ansprechpartner / Durchwahl</div>
        <div id="t4_3" class="t s3">
          TECMAT Handels-GmbH • Hauptstraße 56 • 88138 Sigmarszell/Schlachters
        </div>
        <div id="t5_3" class="t s2">Lukas Weber / (0 83 89) 9 29 49 - 12</div>
        <div id="t6_3" class="t s2">E-Mail</div>
        <div id="t7_3" class="t s2">l.weber@tecmat.de</div>
        <div id="t8_3" class="t s4">APSOparts GmbH</div>
        <div id="t9_3" class="t s4">Siemensstrasse 5</div>
        <div id="ta_3" class="t s2">#OCR-BE# 306371-1</div>
        <div id="tb_3" class="t s4">DE</div>
        <div id="tc_3" class="t s4">70736</div>
        <div id="td_3" class="t s4">Fellbach</div>
        <div id="te_3" class="t s5">BESTELLUNG</div>
        <div id="tf_3" class="t s4">306371</div>
        <div id="tg_3" class="t s6">01.07.2019</div>
        <div id="th_3" class="t s6">751850</div>
        <div id="ti_3" class="t s6">Beleg-Nr.</div>
        <div id="tj_3" class="t s6">Unsere Kd-Nr.</div>
        <div id="tk_3" class="t s6">Datum</div>
        <div id="tl_3" class="t s7">3</div>
        <div id="tm_3" class="t s7">Seite :</div>
        <div id="tn_3" class="t s4">TECMAT Handels GmbH</div>
        <div id="to_3" class="t s4">LIEFERADRESSE:</div>
        <div id="tp_3" class="t s4">Hauptstrasse 56</div>
        <div id="tq_3" class="t s4">D 88138</div>
        <div id="tr_3" class="t s4">Sigmarszell-Schlachters</div>
        <div id="ts_3" class="t s7">Pos.</div>
        <div id="tt_3" class="t s7">Artikel-Nummer</div>
        <div id="tu_3" class="t s7">Menge</div>
        <div id="tv_3" class="t s7">Einheit PE</div>
        <div id="tw_3" class="t s7">E-Preis</div>
        <div id="tx_3" class="t s7">%</div>
        <div id="ty_3" class="t s7">Gesamt</div>
        <div id="tz_3" class="t s7">EUR</div>
        <div id="t10_3" class="t s7">EUR</div>
        <div id="t11_3" class="t s7">Bezeichnung</div>
        <div id="t12_3" class="t s8">50,00</div>
        <div id="t13_3" class="t s8">16,65</div>
        <div id="t14_3" class="t s8">832,50</div>
        <div id="t15_3" class="t s2">08-04-273615</div>
        <div id="t16_3" class="t s2">Meter 1</div>
        <div id="t17_3" class="t s9">7</div>
        <div id="t18_3" class="t s4">PU-Zahnriemen/Meterware</div>
        <div id="t19_3" class="t s2">Abmessung 75 AT 10</div>
        <div id="t1a_3" class="t s2">Stahlzugträger</div>
        <div id="t1b_3" class="t s9">8000259133</div>
        <div id="t1c_3" class="t s9">Ihre Artikel-Nr.</div>
        <div id="t1d_3" class="t s5">17.09.2019</div>
        <div id="t1e_3" class="t s5">Lieferdatum eintreffend:</div>
        <div id="t1f_3" class="t s8">100,00</div>
        <div id="t1g_3" class="t s8">5,50</div>
        <div id="t1h_3" class="t s8">550,00</div>
        <div id="t1i_3" class="t s2">08-04-271132</div>
        <div id="t1j_3" class="t s2">Stück 1</div>
        <div id="t1k_3" class="t s9">8</div>
        <div id="t1l_3" class="t s4">Zahnriemen Synchroflex</div>
        <div id="t1m_3" class="t s2">16 AT 5 - 420</div>
        <div id="t1n_3" class="t s9">0918316989</div>
        <div id="t1o_3" class="t s9">Ihre Artikel-Nr.</div>
        <div id="t1p_3" class="t s5">17.09.2019</div>
        <div id="t1q_3" class="t s5">Lieferdatum eintreffend:</div>
        <div id="t1r_3" class="t s7">
          Grundlage der Bestellung der TECMAT GmbH ist die
        </div>
        <div id="t1s_3" class="t s7">
          Zusicherung des Lieferanten, dass die von ihm gelieferten
        </div>
        <div id="t1t_3" class="t s7">
          Materialien unter REACH vorregistriert bzw. registriert sind.
        </div>
        <div id="t1u_3" class="t s4">DIE ANGEGEBENEN TERMINE VERSTEHEN</div>
        <div id="t1v_3" class="t s4">SICH EINTREFFEND! Bitte disponieren</div>
        <div id="t1w_3" class="t s4">Sie Ihren Versand rechtzeitig!</div>
        <div id="t1x_3" class="t s4">
          Die Verpackung muss streng neutral (Klebe-
        </div>
        <div id="t1y_3" class="t s4">bänder/Etiketten etc.) erfolgen.</div>
        <div id="t1z_3" class="t s7">DHL</div>
        <div id="t20_3" class="t s7">EUR</div>
        <div id="t21_3" class="t s7">3738,20</div>
        <div id="t22_3" class="t s7">Versandart</div>
        <div id="t23_3" class="t s7">Netto</div>
        <div id="t24_3" class="t s7">frei Haus</div>
        <div id="t25_3" class="t s7">19,00%</div>
        <div id="t26_3" class="t s7">EUR</div>
        <div id="t27_3" class="t s7">710,26</div>
        <div id="t28_3" class="t s7">Versandbedingungen</div>
        <div id="t29_3" class="t s7">MwSt</div>
        <div id="t2a_3" class="t s7">30 Tage 2 %, 60 Tage netto</div>
        <div id="t2b_3" class="t s7">EUR</div>
        <div id="t2c_3" class="t s7">4448,46</div>
        <div id="t2d_3" class="t s7">Zahlungsbedingungen</div>
        <div id="t2e_3" class="t s7">Bestellwert</div>
        <div id="t2f_3" class="t s7">
          (PE 1= pro1, 2= pro10, 3= pro100, 4= pro1.000)
        </div>
        <div id="t2g_3" class="t s7">82199</div>
        <div id="t2h_3" class="t s3">Geschäftsführung</div>
        <div id="t2i_3" class="t s3">Handelsregister</div>
        <div id="t2j_3" class="t s3">Umsatzst.Ident.Nr.</div>
        <div id="t2k_3" class="t s3">Bayerische Bodenseebank Lindau</div>
        <div id="t2l_3" class="t s3">Tel. 0049 (0)8389 / 92 94 9-0</div>
        <div id="t2m_3" class="t s3">Hermann Zöller</div>
        <div id="t2n_3" class="t s3">Kempten HRB 6382</div>
        <div id="t2o_3" class="t s3">DE 182358703</div>
        <div id="t2p_3" class="t s3">Konto 44 58 43 • BLZ 733 698 21</div>
        <div id="t2q_3" class="t s3">Fax 0049 (0)8389 / 92 94 949</div>
        <div id="t2r_3" class="t s3">Erfüllungsort und</div>
        <div id="t2s_3" class="t s3">Steuer.Nr. 127/140/10105</div>
        <div id="t2t_3" class="t s3">IBAN: DE48733698210000445843</div>
        <div id="t2u_3" class="t s3">Internet: www.tecmat.de</div>
        <div id="t2v_3" class="t s3">Gerichtstand Lindau</div>
        <div id="t2w_3" class="t s3">Finanzamt Kempten</div>
        <div id="t2x_3" class="t s3">SWIFT (BIC) GENODEF1LBB</div>
        <div id="t2y_3" class="t s3">e-mail: info@tecmat.de</div>
        <div id="t2z_3" class="t s3">
          Es gelten grundsätzlich unsere allgemeinen Geschäftsbedingungen.
          Anderslautende Bedingungen werden nicht anerkannt.
        </div>
  
        <!-- End text definitions -->
      </div>
    </body>
  </html>
  
  `,
  BE_306371_2: `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
    <meta charset="utf-8" />
  </head>

  <body style="margin: 0">
    <div
      id="p1"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1286px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          -webkit-transform-origin: bottom left;
          -ms-transform-origin: bottom left;
          transform-origin: bottom left;
          -webkit-transform: scale(0.25);
          -ms-transform: scale(0.25);
          transform: scale(0.25);
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_1 {
          left: 32px;
          bottom: 1142px;
          word-spacing: -0.1px;
        }
        #t2_1 {
          left: 118px;
          bottom: 1142px;
        }
        #t3_1 {
          left: 109px;
          bottom: 782px;
          letter-spacing: -0.2px;
        }
        #t4_1 {
          left: 114px;
          bottom: 743px;
          letter-spacing: 0.2px;
        }
        #t5_1 {
          left: 147px;
          bottom: 743px;
          letter-spacing: 0.1px;
        }
        #t6_1 {
          left: 518px;
          bottom: 743px;
        }
        #t7_1 {
          left: 588px;
          bottom: 743px;
        }
        #t8_1 {
          left: 734px;
          bottom: 743px;
        }
        #t9_1 {
          left: 781px;
          bottom: 743px;
          letter-spacing: 0.1px;
        }
        #ta_1 {
          left: 661px;
          bottom: 743px;
          letter-spacing: 0.1px;
        }
        #tb_1 {
          left: 277px;
          bottom: 725px;
          letter-spacing: 0.1px;
        }
        #tc_1 {
          left: 669px;
          bottom: 725px;
          letter-spacing: 0.2px;
        }
        #td_1 {
          left: 788px;
          bottom: 725px;
        }
        #te_1 {
          left: 583px;
          bottom: 961px;
          letter-spacing: -0.2px;
          word-spacing: -0.3px;
        }
        #tf_1 {
          left: 246px;
          bottom: 782px;
          letter-spacing: -0.1px;
          word-spacing: 0.2px;
        }
        #tg_1 {
          left: 246px;
          bottom: 762px;
          letter-spacing: -0.2px;
          word-spacing: 0.3px;
        }
        #th_1 {
          left: 384px;
          bottom: 762px;
          letter-spacing: -0.2px;
          word-spacing: 0.4px;
        }
        #ti_1 {
          left: 450px;
          bottom: 762px;
          letter-spacing: -0.1px;
        }
        #tj_1 {
          left: 109px;
          bottom: 964px;
          letter-spacing: -0.2px;
          word-spacing: 0.3px;
        }
        #tk_1 {
          left: 109px;
          bottom: 931px;
          letter-spacing: -0.2px;
        }
        #tl_1 {
          left: 143px;
          bottom: 931px;
          letter-spacing: -0.3px;
        }
        #tm_1 {
          left: 198px;
          bottom: 931px;
          letter-spacing: -0.1px;
        }
        #tn_1 {
          left: 109px;
          bottom: 983px;
          letter-spacing: -0.1px;
          word-spacing: 0.2px;
        }
        #to_1 {
          left: 109px;
          bottom: 848px;
          letter-spacing: -0.3px;
        }
        #tp_1 {
          left: 200px;
          bottom: 850px;
          letter-spacing: -0.1px;
        }
        #tq_1 {
          left: 113px;
          bottom: 826px;
        }
        #tr_1 {
          left: 201px;
          bottom: 826px;
          letter-spacing: -0.1px;
          word-spacing: 0.6px;
        }
        #ts_1 {
          left: 112px;
          bottom: 880px;
        }
        #tt_1 {
          left: 620px;
          bottom: 809px;
        }
        #tu_1 {
          left: 582px;
          bottom: 809px;
          letter-spacing: 0.1px;
          word-spacing: 0.4px;
        }
        #tv_1 {
          left: 312px;
          bottom: 851px;
        }
        #tw_1 {
          left: 313px;
          bottom: 826px;
          letter-spacing: -0.1px;
        }
        #tx_1 {
          left: 629px;
          bottom: 743px;
          letter-spacing: 0.4px;
        }
        #ty_1 {
          left: 582px;
          bottom: 1044px;
          letter-spacing: -0.1px;
          word-spacing: 0.1px;
        }
        #tz_1 {
          left: 582px;
          bottom: 1024px;
          letter-spacing: -0.2px;
        }
        #t10_1 {
          left: 109px;
          bottom: 1031px;
          letter-spacing: 0.1px;
          word-spacing: 0.2px;
        }
        #t11_1 {
          left: 582px;
          bottom: 1003px;
          letter-spacing: -0.2px;
        }
        #t12_1 {
          left: 582px;
          bottom: 985px;
          letter-spacing: -0.2px;
        }
        #t13_1 {
          left: 143px;
          bottom: 699px;
          letter-spacing: -0.2px;
        }
        #t14_1 {
          left: 273px;
          bottom: 679px;
          letter-spacing: -0.1px;
          word-spacing: -0.3px;
        }
        #t15_1 {
          left: 110px;
          bottom: 701px;
        }
        #t16_1 {
          left: 367px;
          bottom: 641px;
          letter-spacing: 0.2px;
        }
        #t17_1 {
          left: 143px;
          bottom: 659px;
          letter-spacing: -0.2px;
          word-spacing: 0.3px;
        }
        #t18_1 {
          left: 541px;
          bottom: 699px;
          letter-spacing: 0.2px;
        }
        #t19_1 {
          left: 586px;
          bottom: 699px;
          letter-spacing: -0.2px;
        }
        #t1a_1 {
          left: 794px;
          bottom: 699px;
          letter-spacing: 0.1px;
        }
        #t1b_1 {
          left: 676px;
          bottom: 699px;
          letter-spacing: 0.2px;
        }
        #t1c_1 {
          left: 273px;
          bottom: 641px;
          letter-spacing: 0.1px;
          word-spacing: -0.1px;
        }
        #t1d_1 {
          left: 633px;
          bottom: 699px;
        }
        #t1e_1 {
          left: 143px;
          bottom: 617px;
          letter-spacing: 0.2px;
          word-spacing: 0.1px;
        }
        #t1f_1 {
          left: 354px;
          bottom: 617px;
          letter-spacing: 0.1px;
        }
        #t1g_1 {
          left: 143px;
          bottom: 594px;
          letter-spacing: -0.2px;
        }
        #t1h_1 {
          left: 273px;
          bottom: 574px;
          letter-spacing: -0.1px;
          word-spacing: -0.3px;
        }
        #t1i_1 {
          left: 110px;
          bottom: 596px;
        }
        #t1j_1 {
          left: 367px;
          bottom: 535px;
          letter-spacing: 0.2px;
        }
        #t1k_1 {
          left: 143px;
          bottom: 554px;
          word-spacing: -0.4px;
        }
        #t1l_1 {
          left: 541px;
          bottom: 593px;
          letter-spacing: 0.2px;
        }
        #t1m_1 {
          left: 586px;
          bottom: 594px;
          letter-spacing: -0.2px;
        }
        #t1n_1 {
          left: 794px;
          bottom: 593px;
          letter-spacing: 0.1px;
        }
        #t1o_1 {
          left: 685px;
          bottom: 593px;
          letter-spacing: 0.2px;
        }
        #t1p_1 {
          left: 273px;
          bottom: 535px;
          letter-spacing: 0.1px;
          word-spacing: -0.1px;
        }
        #t1q_1 {
          left: 633px;
          bottom: 594px;
        }
        #t1r_1 {
          left: 143px;
          bottom: 511px;
          letter-spacing: 0.2px;
          word-spacing: 0.1px;
        }
        #t1s_1 {
          left: 354px;
          bottom: 511px;
          letter-spacing: 0.1px;
        }
        #t1t_1 {
          left: 143px;
          bottom: 489px;
          letter-spacing: -0.2px;
        }
        #t1u_1 {
          left: 273px;
          bottom: 469px;
          letter-spacing: -0.1px;
          word-spacing: -0.3px;
        }
        #t1v_1 {
          left: 110px;
          bottom: 491px;
        }
        #t1w_1 {
          left: 367px;
          bottom: 430px;
          letter-spacing: 0.2px;
        }
        #t1x_1 {
          left: 143px;
          bottom: 449px;
          letter-spacing: -0.2px;
          word-spacing: 0.2px;
        }
        #t1y_1 {
          left: 541px;
          bottom: 488px;
          letter-spacing: 0.2px;
        }
        #t1z_1 {
          left: 586px;
          bottom: 489px;
          letter-spacing: -0.2px;
        }
        #t20_1 {
          left: 794px;
          bottom: 488px;
          letter-spacing: 0.1px;
        }
        #t21_1 {
          left: 685px;
          bottom: 488px;
          letter-spacing: 0.2px;
        }
        #t22_1 {
          left: 273px;
          bottom: 430px;
          letter-spacing: 0.1px;
          word-spacing: -0.1px;
        }
        #t23_1 {
          left: 633px;
          bottom: 489px;
        }
        #t24_1 {
          left: 143px;
          bottom: 406px;
          letter-spacing: 0.2px;
          word-spacing: 0.1px;
        }
        #t25_1 {
          left: 354px;
          bottom: 406px;
          letter-spacing: 0.1px;
        }
        #t26_1 {
          left: 588px;
          bottom: 240px;
          letter-spacing: -0.1px;
        }
        #t27_1 {
          left: 722px;
          bottom: 241px;
        }
        #t28_1 {
          left: 801px;
          bottom: 242px;
        }
        #t29_1 {
          left: 127px;
          bottom: 37px;
          letter-spacing: 0.1px;
          word-spacing: 0.4px;
        }
        #t2a_1 {
          left: 109px;
          bottom: 135px;
          word-spacing: 0.5px;
        }
        #t2b_1 {
          left: 87px;
          bottom: 108px;
          letter-spacing: 0.1px;
        }
        #t2c_1 {
          left: 87px;
          bottom: 94px;
          word-spacing: -0.1px;
        }
        #t2d_1 {
          left: 208px;
          bottom: 108px;
        }
        #t2e_1 {
          left: 208px;
          bottom: 94px;
          word-spacing: 0.5px;
        }
        #t2f_1 {
          left: 208px;
          bottom: 80px;
          word-spacing: 0.5px;
        }
        #t2g_1 {
          left: 208px;
          bottom: 67px;
          letter-spacing: 0.1px;
          word-spacing: -0.2px;
        }
        #t2h_1 {
          left: 339px;
          bottom: 108px;
          letter-spacing: 0.2px;
        }
        #t2i_1 {
          left: 339px;
          bottom: 94px;
          letter-spacing: -0.1px;
          word-spacing: 0.6px;
        }
        #t2j_1 {
          left: 339px;
          bottom: 80px;
          word-spacing: 0.5px;
        }
        #t2k_1 {
          left: 339px;
          bottom: 67px;
          letter-spacing: 0.1px;
          word-spacing: 0.4px;
        }
        #t2l_1 {
          left: 496px;
          bottom: 108px;
          letter-spacing: 0.1px;
          word-spacing: 0.4px;
        }
        #t2m_1 {
          left: 496px;
          bottom: 94px;
          word-spacing: 0.2px;
        }
        #t2n_1 {
          left: 496px;
          bottom: 80px;
          word-spacing: 0.5px;
        }
        #t2o_1 {
          left: 496px;
          bottom: 67px;
          letter-spacing: 0.2px;
          word-spacing: -0.3px;
        }
        #t2p_1 {
          left: 700px;
          bottom: 108px;
          word-spacing: 0.4px;
        }
        #t2q_1 {
          left: 700px;
          bottom: 94px;
          word-spacing: 0.4px;
        }
        #t2r_1 {
          left: 700px;
          bottom: 80px;
          letter-spacing: 0.1px;
          word-spacing: 0.4px;
        }
        #t2s_1 {
          left: 700px;
          bottom: 67px;
          letter-spacing: 0.1px;
          word-spacing: 0.3px;
        }
        #t2t_1 {
          left: 551px;
          bottom: 135px;
          letter-spacing: -0.1px;
        }

        .s1_1 {
          font-size: 36.7px;
          font-family: LiberationSans_l;
          color: rgb(255, 255, 255);
        }

        .s2_1 {
          font-size: 61.1px;
          font-family: LiberationSans-Bold_g;
          color: rgb(0, 0, 0);
        }

        .s3_1 {
          font-size: 48.9px;
          font-family: LiberationSans_l;
          color: rgb(0, 0, 0);
        }

        .s4_1 {
          font-size: 61.1px;
          font-family: LiberationSans_l;
          color: rgb(0, 0, 0);
        }

        .s5_1 {
          font-size: 48.9px;
          font-family: LiberationSans-Bold_g;
          color: rgb(0, 0, 0);
        }

        .s6_1 {
          font-size: 67.2px;
          font-family: LiberationSans-Bold_g;
          color: rgb(0, 0, 0);
        }

        .s7_1 {
          font-size: 48.9px;
          font-family: LiberationSans_l;
          color: rgb(0, 0, 128);
        }

        .s8_1 {
          font-size: 55.1px;
          font-family: LiberationSans_l;
          color: rgb(0, 0, 0);
        }

        .s9_1 {
          font-size: 67.2px;
          font-family: LiberationSans_l;
          color: rgb(0, 0, 0);
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts1" type="text/css">
        @font-face {
          font-family: LiberationSans-Bold_g;
          src: url("fonts/LiberationSans-Bold_g.woff") format("woff");
        }

        @font-face {
          font-family: LiberationSans_l;
          src: url("fonts/LiberationSans_l.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg1Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg1" style="-webkit-user-select: none">
        <object
          width="909"
          height="1286"
          data="1/1.svg"
          type="image/svg+xml"
          id="pdf1"
          style="
            width: 909px;
            height: 1286px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div id="t1_1" class="t s1_1">@@BETREFF BE /</div>
      <div id="t2_1" class="t s1_1">APSOPARTS GMBH / 306371@@</div>
      <div id="t3_1" class="t s2_1">LIEFERADRESSE:</div>
      <div id="t4_1" class="t s3_1">Pos.</div>
      <div id="t5_1" class="t s3_1">Artikel-Nummer</div>
      <div id="t6_1" class="t s3_1">Menge</div>
      <div id="t7_1" class="t s3_1">Einheit</div>
      <div id="t8_1" class="t s3_1">%</div>
      <div id="t9_1" class="t s3_1">Gesamt</div>
      <div id="ta_1" class="t s3_1">E-Preis</div>
      <div id="tb_1" class="t s3_1">Bezeichnung</div>
      <div id="tc_1" class="t s3_1">EUR</div>
      <div id="td_1" class="t s3_1">EUR</div>
      <div id="te_1" class="t s4_1">#OCR-BE# 306371-1</div>
      <div id="tf_1" class="t s2_1">TECMAT Handels GmbH</div>
      <div id="tg_1" class="t s2_1">Hauptstrasse 56</div>
      <div id="th_1" class="t s2_1">D 88138</div>
      <div id="ti_1" class="t s2_1">Sigmarszell-Schlachters</div>
      <div id="tj_1" class="t s2_1">Siemensstrasse 5</div>
      <div id="tk_1" class="t s2_1">DE</div>
      <div id="tl_1" class="t s2_1">70736</div>
      <div id="tm_1" class="t s2_1">Fellbach</div>
      <div id="tn_1" class="t s2_1">APSOparts GmbH</div>
      <div id="to_1" class="t s2_1">306371</div>
      <div id="tp_1" class="t s5_1">751850</div>
      <div id="tq_1" class="t s5_1">Beleg-Nr.</div>
      <div id="tr_1" class="t s5_1">Unsere Kd-Nr.</div>
      <div id="ts_1" class="t s6_1">BESTELLUNG</div>
      <div id="tt_1" class="t s3_1">1</div>
      <div id="tu_1" class="t s3_1">Seite :</div>
      <div id="tv_1" class="t s5_1">01.07.2019</div>
      <div id="tw_1" class="t s5_1">Datum</div>
      <div id="tx_1" class="t s3_1">PE</div>
      <div id="ty_1" class="t s4_1">Ihr Ansprechpartner / Durchwahl</div>
      <div id="tz_1" class="t s4_1">Lukas Weber / (0 83 89) 9 29 49 - 12</div>
      <div id="t10_1" class="t s7_1">
        TECMAT Handels-GmbH • Hauptstraße 56 • 88138 Sigmarszell/Schlachters
      </div>
      <div id="t11_1" class="t s4_1">E-Mail</div>
      <div id="t12_1" class="t s4_1">l.weber@tecmat.de</div>
      <div id="t13_1" class="t s4_1">08-04-282364</div>
      <div id="t14_1" class="t s2_1">Zahnriemen Synchroflex</div>
      <div id="t15_1" class="t s8_1">1</div>
      <div id="t16_1" class="t s8_1">0918317147</div>
      <div id="t17_1" class="t s4_1">25 AT 5 - 975 mm</div>
      <div id="t18_1" class="t s9_1">40,00</div>
      <div id="t19_1" class="t s4_1">Stück</div>
      <div id="t1a_1" class="t s9_1">562,40</div>
      <div id="t1b_1" class="t s9_1">14,06</div>
      <div id="t1c_1" class="t s8_1">Ihre Artikel-Nr.</div>
      <div id="t1d_1" class="t s4_1">1</div>
      <div id="t1e_1" class="t s6_1">Lieferdatum eintreffend:</div>
      <div id="t1f_1" class="t s6_1">12.09.2019</div>
      <div id="t1g_1" class="t s4_1">08-04-272831</div>
      <div id="t1h_1" class="t s2_1">Zahnriemen Synchroflex</div>
      <div id="t1i_1" class="t s8_1">2</div>
      <div id="t1j_1" class="t s8_1">0918316975</div>
      <div id="t1k_1" class="t s4_1">Typ 10 AT5 - 390</div>
      <div id="t1l_1" class="t s9_1">40,00</div>
      <div id="t1m_1" class="t s4_1">Stück</div>
      <div id="t1n_1" class="t s9_1">149,20</div>
      <div id="t1o_1" class="t s9_1">3,73</div>
      <div id="t1p_1" class="t s8_1">Ihre Artikel-Nr.</div>
      <div id="t1q_1" class="t s4_1">1</div>
      <div id="t1r_1" class="t s6_1">Lieferdatum eintreffend:</div>
      <div id="t1s_1" class="t s6_1">12.09.2019</div>
      <div id="t1t_1" class="t s4_1">08-04-268821</div>
      <div id="t1u_1" class="t s2_1">Zahnriemen Synchroflex</div>
      <div id="t1v_1" class="t s8_1">3</div>
      <div id="t1w_1" class="t s8_1">0918316979</div>
      <div id="t1x_1" class="t s4_1">25 AT 5 - 390</div>
      <div id="t1y_1" class="t s9_1">80,00</div>
      <div id="t1z_1" class="t s4_1">Stück</div>
      <div id="t20_1" class="t s9_1">574,40</div>
      <div id="t21_1" class="t s9_1">7,18</div>
      <div id="t22_1" class="t s8_1">Ihre Artikel-Nr.</div>
      <div id="t23_1" class="t s4_1">1</div>
      <div id="t24_1" class="t s6_1">Lieferdatum eintreffend:</div>
      <div id="t25_1" class="t s6_1">12.09.2019</div>
      <div id="t26_1" class="t s3_1">Übertrag</div>
      <div id="t27_1" class="t s3_1">EUR</div>
      <div id="t28_1" class="t s3_1">1286,00</div>
      <div id="t29_1" class="t s7_1">
        Es gelten grundsätzlich unsere allgemeinen Geschäftsbedingungen.
        Anderslautende Bedingungen werden nicht anerkannt.
      </div>
      <div id="t2a_1" class="t s3_1">
        (PE 1= pro1, 2= pro10, 3= pro100, 4= pro1.000)
      </div>
      <div id="t2b_1" class="t s7_1">Geschäftsführung</div>
      <div id="t2c_1" class="t s7_1">Hermann Zöller</div>
      <div id="t2d_1" class="t s7_1">Handelsregister</div>
      <div id="t2e_1" class="t s7_1">Kempten HRB 6382</div>
      <div id="t2f_1" class="t s7_1">Erfüllungsort und</div>
      <div id="t2g_1" class="t s7_1">Gerichtstand Lindau</div>
      <div id="t2h_1" class="t s7_1">Umsatzst.Ident.Nr.</div>
      <div id="t2i_1" class="t s7_1">DE 182358703</div>
      <div id="t2j_1" class="t s7_1">Steuer.Nr. 127/140/10105</div>
      <div id="t2k_1" class="t s7_1">Finanzamt Kempten</div>
      <div id="t2l_1" class="t s7_1">Bayerische Bodenseebank Lindau</div>
      <div id="t2m_1" class="t s7_1">Konto 44 58 43 • BLZ 733 698 21</div>
      <div id="t2n_1" class="t s7_1">IBAN: DE48733698210000445843</div>
      <div id="t2o_1" class="t s7_1">SWIFT (BIC) GENODEF1LBB</div>
      <div id="t2p_1" class="t s7_1">Tel. 0049 (0)8389 / 92 94 9-0</div>
      <div id="t2q_1" class="t s7_1">Fax 0049 (0)8389 / 92 94 949</div>
      <div id="t2r_1" class="t s7_1">Internet: www.tecmat.de</div>
      <div id="t2s_1" class="t s7_1">e-mail: info@tecmat.de</div>
      <div id="t2t_1" class="t s3_1">82199</div>

      <!-- End text definitions -->
    </div>
    <div
      id="p2"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1286px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          -webkit-transform-origin: bottom left;
          -ms-transform-origin: bottom left;
          transform-origin: bottom left;
          -webkit-transform: scale(0.25);
          -ms-transform: scale(0.25);
          transform: scale(0.25);
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_2 {
          left: 32px;
          bottom: 1142px;
          word-spacing: -0.1px;
        }
        #t2_2 {
          left: 118px;
          bottom: 1142px;
        }
        #t3_2 {
          left: 109px;
          bottom: 782px;
          letter-spacing: -0.2px;
        }
        #t4_2 {
          left: 114px;
          bottom: 743px;
          letter-spacing: 0.2px;
        }
        #t5_2 {
          left: 147px;
          bottom: 743px;
          letter-spacing: 0.1px;
        }
        #t6_2 {
          left: 518px;
          bottom: 743px;
        }
        #t7_2 {
          left: 588px;
          bottom: 743px;
        }
        #t8_2 {
          left: 734px;
          bottom: 743px;
        }
        #t9_2 {
          left: 781px;
          bottom: 743px;
          letter-spacing: 0.1px;
        }
        #ta_2 {
          left: 661px;
          bottom: 743px;
          letter-spacing: 0.1px;
        }
        #tb_2 {
          left: 277px;
          bottom: 725px;
          letter-spacing: 0.1px;
        }
        #tc_2 {
          left: 669px;
          bottom: 725px;
          letter-spacing: 0.2px;
        }
        #td_2 {
          left: 788px;
          bottom: 725px;
        }
        #te_2 {
          left: 583px;
          bottom: 961px;
          letter-spacing: -0.2px;
          word-spacing: -0.3px;
        }
        #tf_2 {
          left: 246px;
          bottom: 782px;
          letter-spacing: -0.1px;
          word-spacing: 0.2px;
        }
        #tg_2 {
          left: 246px;
          bottom: 762px;
          letter-spacing: -0.2px;
          word-spacing: 0.3px;
        }
        #th_2 {
          left: 384px;
          bottom: 762px;
          letter-spacing: -0.2px;
          word-spacing: 0.4px;
        }
        #ti_2 {
          left: 450px;
          bottom: 762px;
          letter-spacing: -0.1px;
        }
        #tj_2 {
          left: 109px;
          bottom: 964px;
          letter-spacing: -0.2px;
          word-spacing: 0.3px;
        }
        #tk_2 {
          left: 109px;
          bottom: 931px;
          letter-spacing: -0.2px;
        }
        #tl_2 {
          left: 143px;
          bottom: 931px;
          letter-spacing: -0.3px;
        }
        #tm_2 {
          left: 198px;
          bottom: 931px;
          letter-spacing: -0.1px;
        }
        #tn_2 {
          left: 109px;
          bottom: 983px;
          letter-spacing: -0.1px;
          word-spacing: 0.2px;
        }
        #to_2 {
          left: 109px;
          bottom: 848px;
          letter-spacing: -0.3px;
        }
        #tp_2 {
          left: 200px;
          bottom: 850px;
          letter-spacing: -0.1px;
        }
        #tq_2 {
          left: 113px;
          bottom: 826px;
        }
        #tr_2 {
          left: 201px;
          bottom: 826px;
          letter-spacing: -0.1px;
          word-spacing: 0.6px;
        }
        #ts_2 {
          left: 112px;
          bottom: 880px;
        }
        #tt_2 {
          left: 620px;
          bottom: 809px;
        }
        #tu_2 {
          left: 582px;
          bottom: 809px;
          letter-spacing: 0.1px;
          word-spacing: 0.4px;
        }
        #tv_2 {
          left: 312px;
          bottom: 851px;
        }
        #tw_2 {
          left: 313px;
          bottom: 826px;
          letter-spacing: -0.1px;
        }
        #tx_2 {
          left: 629px;
          bottom: 743px;
          letter-spacing: 0.4px;
        }
        #ty_2 {
          left: 582px;
          bottom: 1044px;
          letter-spacing: -0.1px;
          word-spacing: 0.1px;
        }
        #tz_2 {
          left: 582px;
          bottom: 1024px;
          letter-spacing: -0.2px;
        }
        #t10_2 {
          left: 109px;
          bottom: 1031px;
          letter-spacing: 0.1px;
          word-spacing: 0.2px;
        }
        #t11_2 {
          left: 582px;
          bottom: 1003px;
          letter-spacing: -0.2px;
        }
        #t12_2 {
          left: 582px;
          bottom: 985px;
          letter-spacing: -0.2px;
        }
        #t13_2 {
          left: 143px;
          bottom: 699px;
          letter-spacing: -0.2px;
        }
        #t14_2 {
          left: 273px;
          bottom: 679px;
          letter-spacing: -0.1px;
          word-spacing: -0.3px;
        }
        #t15_2 {
          left: 110px;
          bottom: 701px;
        }
        #t16_2 {
          left: 367px;
          bottom: 621px;
          letter-spacing: 0.2px;
        }
        #t17_2 {
          left: 143px;
          bottom: 659px;
          letter-spacing: -0.2px;
          word-spacing: 0.2px;
        }
        #t18_2 {
          left: 143px;
          bottom: 642px;
          letter-spacing: -0.1px;
          word-spacing: -0.3px;
        }
        #t19_2 {
          left: 541px;
          bottom: 699px;
          letter-spacing: 0.2px;
        }
        #t1a_2 {
          left: 586px;
          bottom: 699px;
          letter-spacing: -0.2px;
        }
        #t1b_2 {
          left: 794px;
          bottom: 699px;
          letter-spacing: 0.1px;
        }
        #t1c_2 {
          left: 685px;
          bottom: 699px;
          letter-spacing: 0.2px;
        }
        #t1d_2 {
          left: 273px;
          bottom: 621px;
          letter-spacing: 0.1px;
          word-spacing: -0.1px;
        }
        #t1e_2 {
          left: 633px;
          bottom: 699px;
        }
        #t1f_2 {
          left: 143px;
          bottom: 597px;
          letter-spacing: 0.2px;
          word-spacing: 0.1px;
        }
        #t1g_2 {
          left: 354px;
          bottom: 597px;
          letter-spacing: 0.1px;
        }
        #t1h_2 {
          left: 143px;
          bottom: 574px;
          letter-spacing: -0.2px;
        }
        #t1i_2 {
          left: 273px;
          bottom: 554px;
          letter-spacing: -0.1px;
          word-spacing: -0.3px;
        }
        #t1j_2 {
          left: 110px;
          bottom: 576px;
        }
        #t1k_2 {
          left: 367px;
          bottom: 515px;
          letter-spacing: 0.2px;
        }
        #t1l_2 {
          left: 143px;
          bottom: 534px;
          letter-spacing: -0.2px;
          word-spacing: 0.4px;
        }
        #t1m_2 {
          left: 541px;
          bottom: 573px;
          letter-spacing: 0.2px;
        }
        #t1n_2 {
          left: 586px;
          bottom: 574px;
          letter-spacing: -0.2px;
        }
        #t1o_2 {
          left: 794px;
          bottom: 573px;
          letter-spacing: 0.1px;
        }
        #t1p_2 {
          left: 685px;
          bottom: 573px;
          letter-spacing: 0.2px;
        }
        #t1q_2 {
          left: 273px;
          bottom: 515px;
          letter-spacing: 0.1px;
          word-spacing: -0.1px;
        }
        #t1r_2 {
          left: 633px;
          bottom: 574px;
        }
        #t1s_2 {
          left: 143px;
          bottom: 491px;
          letter-spacing: 0.2px;
          word-spacing: 0.1px;
        }
        #t1t_2 {
          left: 354px;
          bottom: 491px;
          letter-spacing: 0.1px;
        }
        #t1u_2 {
          left: 143px;
          bottom: 469px;
          letter-spacing: -0.2px;
        }
        #t1v_2 {
          left: 273px;
          bottom: 449px;
          letter-spacing: -0.1px;
          word-spacing: -0.3px;
        }
        #t1w_2 {
          left: 110px;
          bottom: 471px;
        }
        #t1x_2 {
          left: 367px;
          bottom: 410px;
          letter-spacing: 0.2px;
        }
        #t1y_2 {
          left: 143px;
          bottom: 429px;
          letter-spacing: -0.2px;
          word-spacing: 0.4px;
        }
        #t1z_2 {
          left: 550px;
          bottom: 468px;
          letter-spacing: 0.2px;
        }
        #t20_2 {
          left: 586px;
          bottom: 469px;
          letter-spacing: -0.2px;
        }
        #t21_2 {
          left: 794px;
          bottom: 468px;
          letter-spacing: 0.1px;
        }
        #t22_2 {
          left: 676px;
          bottom: 468px;
          letter-spacing: 0.2px;
        }
        #t23_2 {
          left: 273px;
          bottom: 410px;
          letter-spacing: 0.1px;
          word-spacing: -0.1px;
        }
        #t24_2 {
          left: 633px;
          bottom: 469px;
        }
        #t25_2 {
          left: 143px;
          bottom: 386px;
          letter-spacing: 0.2px;
          word-spacing: 0.1px;
        }
        #t26_2 {
          left: 354px;
          bottom: 386px;
          letter-spacing: 0.1px;
        }
        #t27_2 {
          left: 588px;
          bottom: 240px;
          letter-spacing: -0.1px;
        }
        #t28_2 {
          left: 722px;
          bottom: 241px;
        }
        #t29_2 {
          left: 801px;
          bottom: 242px;
        }
        #t2a_2 {
          left: 127px;
          bottom: 37px;
          letter-spacing: 0.1px;
          word-spacing: 0.4px;
        }
        #t2b_2 {
          left: 109px;
          bottom: 135px;
          word-spacing: 0.5px;
        }
        #t2c_2 {
          left: 87px;
          bottom: 108px;
          letter-spacing: 0.1px;
        }
        #t2d_2 {
          left: 87px;
          bottom: 94px;
          word-spacing: -0.1px;
        }
        #t2e_2 {
          left: 208px;
          bottom: 108px;
        }
        #t2f_2 {
          left: 208px;
          bottom: 94px;
          word-spacing: 0.5px;
        }
        #t2g_2 {
          left: 208px;
          bottom: 80px;
          word-spacing: 0.5px;
        }
        #t2h_2 {
          left: 208px;
          bottom: 67px;
          letter-spacing: 0.1px;
          word-spacing: -0.2px;
        }
        #t2i_2 {
          left: 339px;
          bottom: 108px;
          letter-spacing: 0.2px;
        }
        #t2j_2 {
          left: 339px;
          bottom: 94px;
          letter-spacing: -0.1px;
          word-spacing: 0.6px;
        }
        #t2k_2 {
          left: 339px;
          bottom: 80px;
          word-spacing: 0.5px;
        }
        #t2l_2 {
          left: 339px;
          bottom: 67px;
          letter-spacing: 0.1px;
          word-spacing: 0.4px;
        }
        #t2m_2 {
          left: 496px;
          bottom: 108px;
          letter-spacing: 0.1px;
          word-spacing: 0.4px;
        }
        #t2n_2 {
          left: 496px;
          bottom: 94px;
          word-spacing: 0.2px;
        }
        #t2o_2 {
          left: 496px;
          bottom: 80px;
          word-spacing: 0.5px;
        }
        #t2p_2 {
          left: 496px;
          bottom: 67px;
          letter-spacing: 0.2px;
          word-spacing: -0.3px;
        }
        #t2q_2 {
          left: 700px;
          bottom: 108px;
          word-spacing: 0.4px;
        }
        #t2r_2 {
          left: 700px;
          bottom: 94px;
          word-spacing: 0.4px;
        }
        #t2s_2 {
          left: 700px;
          bottom: 80px;
          letter-spacing: 0.1px;
          word-spacing: 0.4px;
        }
        #t2t_2 {
          left: 700px;
          bottom: 67px;
          letter-spacing: 0.1px;
          word-spacing: 0.3px;
        }
        #t2u_2 {
          left: 551px;
          bottom: 135px;
          letter-spacing: -0.1px;
        }

        .s1_2 {
          font-size: 36.7px;
          font-family: LiberationSans_l;
          color: rgb(255, 255, 255);
        }

        .s2_2 {
          font-size: 61.1px;
          font-family: LiberationSans-Bold_g;
          color: rgb(0, 0, 0);
        }

        .s3_2 {
          font-size: 48.9px;
          font-family: LiberationSans_l;
          color: rgb(0, 0, 0);
        }

        .s4_2 {
          font-size: 61.1px;
          font-family: LiberationSans_l;
          color: rgb(0, 0, 0);
        }

        .s5_2 {
          font-size: 48.9px;
          font-family: LiberationSans-Bold_g;
          color: rgb(0, 0, 0);
        }

        .s6_2 {
          font-size: 67.2px;
          font-family: LiberationSans-Bold_g;
          color: rgb(0, 0, 0);
        }

        .s7_2 {
          font-size: 48.9px;
          font-family: LiberationSans_l;
          color: rgb(0, 0, 128);
        }

        .s8_2 {
          font-size: 55.1px;
          font-family: LiberationSans_l;
          color: rgb(0, 0, 0);
        }

        .s9_2 {
          font-size: 67.2px;
          font-family: LiberationSans_l;
          color: rgb(0, 0, 0);
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts2" type="text/css">
        @font-face {
          font-family: LiberationSans-Bold_g;
          src: url("fonts/LiberationSans-Bold_g.woff") format("woff");
        }

        @font-face {
          font-family: LiberationSans_l;
          src: url("fonts/LiberationSans_l.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg2Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg2" style="-webkit-user-select: none">
        <object
          width="909"
          height="1286"
          data="2/2.svg"
          type="image/svg+xml"
          id="pdf2"
          style="
            width: 909px;
            height: 1286px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div id="t1_2" class="t s1_2">@@BETREFF BE /</div>
      <div id="t2_2" class="t s1_2">APSOPARTS GMBH / 306371@@</div>
      <div id="t3_2" class="t s2_2">LIEFERADRESSE:</div>
      <div id="t4_2" class="t s3_2">Pos.</div>
      <div id="t5_2" class="t s3_2">Artikel-Nummer</div>
      <div id="t6_2" class="t s3_2">Menge</div>
      <div id="t7_2" class="t s3_2">Einheit</div>
      <div id="t8_2" class="t s3_2">%</div>
      <div id="t9_2" class="t s3_2">Gesamt</div>
      <div id="ta_2" class="t s3_2">E-Preis</div>
      <div id="tb_2" class="t s3_2">Bezeichnung</div>
      <div id="tc_2" class="t s3_2">EUR</div>
      <div id="td_2" class="t s3_2">EUR</div>
      <div id="te_2" class="t s4_2">#OCR-BE# 306371-1</div>
      <div id="tf_2" class="t s2_2">TECMAT Handels GmbH</div>
      <div id="tg_2" class="t s2_2">Hauptstrasse 56</div>
      <div id="th_2" class="t s2_2">D 88138</div>
      <div id="ti_2" class="t s2_2">Sigmarszell-Schlachters</div>
      <div id="tj_2" class="t s2_2">Siemensstrasse 5</div>
      <div id="tk_2" class="t s2_2">DE</div>
      <div id="tl_2" class="t s2_2">70736</div>
      <div id="tm_2" class="t s2_2">Fellbach</div>
      <div id="tn_2" class="t s2_2">APSOparts GmbH</div>
      <div id="to_2" class="t s2_2">306371</div>
      <div id="tp_2" class="t s5_2">751850</div>
      <div id="tq_2" class="t s5_2">Beleg-Nr.</div>
      <div id="tr_2" class="t s5_2">Unsere Kd-Nr.</div>
      <div id="ts_2" class="t s6_2">BESTELLUNG</div>
      <div id="tt_2" class="t s3_2">2</div>
      <div id="tu_2" class="t s3_2">Seite :</div>
      <div id="tv_2" class="t s5_2">01.07.2019</div>
      <div id="tw_2" class="t s5_2">Datum</div>
      <div id="tx_2" class="t s3_2">PE</div>
      <div id="ty_2" class="t s4_2">Ihr Ansprechpartner / Durchwahl</div>
      <div id="tz_2" class="t s4_2">Lukas Weber / (0 83 89) 9 29 49 - 12</div>
      <div id="t10_2" class="t s7_2">
        TECMAT Handels-GmbH • Hauptstraße 56 • 88138 Sigmarszell/Schlachters
      </div>
      <div id="t11_2" class="t s4_2">E-Mail</div>
      <div id="t12_2" class="t s4_2">l.weber@tecmat.de</div>
      <div id="t13_2" class="t s4_2">08-04-283192</div>
      <div id="t14_2" class="t s2_2">Zahnriemen Synchroflex</div>
      <div id="t15_2" class="t s8_2">4</div>
      <div id="t16_2" class="t s8_2">8000301362</div>
      <div id="t17_2" class="t s4_2">25 AT 5 - 500</div>
      <div id="t18_2" class="t s4_2">mit Kevlar-Zugstrang P 3/8</div>
      <div id="t19_2" class="t s9_2">40,00</div>
      <div id="t1a_2" class="t s4_2">Stück</div>
      <div id="t1b_2" class="t s9_2">322,00</div>
      <div id="t1c_2" class="t s9_2">8,05</div>
      <div id="t1d_2" class="t s8_2">Ihre Artikel-Nr.</div>
      <div id="t1e_2" class="t s4_2">1</div>
      <div id="t1f_2" class="t s6_2">Lieferdatum eintreffend:</div>
      <div id="t1g_2" class="t s6_2">12.09.2019</div>
      <div id="t1h_2" class="t s4_2">08-04-269214</div>
      <div id="t1i_2" class="t s2_2">Zahnriemen Synchroflex</div>
      <div id="t1j_2" class="t s8_2">5</div>
      <div id="t1k_2" class="t s8_2">0918317097</div>
      <div id="t1l_2" class="t s4_2">16 AT5 - 720</div>
      <div id="t1m_2" class="t s9_2">70,00</div>
      <div id="t1n_2" class="t s4_2">Stück</div>
      <div id="t1o_2" class="t s9_2">585,20</div>
      <div id="t1p_2" class="t s9_2">8,36</div>
      <div id="t1q_2" class="t s8_2">Ihre Artikel-Nr.</div>
      <div id="t1r_2" class="t s4_2">1</div>
      <div id="t1s_2" class="t s6_2">Lieferdatum eintreffend:</div>
      <div id="t1t_2" class="t s6_2">12.09.2019</div>
      <div id="t1u_2" class="t s4_2">08-04-1030835</div>
      <div id="t1v_2" class="t s2_2">Zahnriemen Synchroflex</div>
      <div id="t1w_2" class="t s8_2">6</div>
      <div id="t1x_2" class="t s8_2">0918317175</div>
      <div id="t1y_2" class="t s4_2">50 AT5 - 1125</div>
      <div id="t1z_2" class="t s9_2">5,00</div>
      <div id="t20_2" class="t s4_2">Stück</div>
      <div id="t21_2" class="t s9_2">162,50</div>
      <div id="t22_2" class="t s9_2">32,50</div>
      <div id="t23_2" class="t s8_2">Ihre Artikel-Nr.</div>
      <div id="t24_2" class="t s4_2">1</div>
      <div id="t25_2" class="t s6_2">Lieferdatum eintreffend:</div>
      <div id="t26_2" class="t s6_2">12.09.2019</div>
      <div id="t27_2" class="t s3_2">Übertrag</div>
      <div id="t28_2" class="t s3_2">EUR</div>
      <div id="t29_2" class="t s3_2">2355,70</div>
      <div id="t2a_2" class="t s7_2">
        Es gelten grundsätzlich unsere allgemeinen Geschäftsbedingungen.
        Anderslautende Bedingungen werden nicht anerkannt.
      </div>
      <div id="t2b_2" class="t s3_2">
        (PE 1= pro1, 2= pro10, 3= pro100, 4= pro1.000)
      </div>
      <div id="t2c_2" class="t s7_2">Geschäftsführung</div>
      <div id="t2d_2" class="t s7_2">Hermann Zöller</div>
      <div id="t2e_2" class="t s7_2">Handelsregister</div>
      <div id="t2f_2" class="t s7_2">Kempten HRB 6382</div>
      <div id="t2g_2" class="t s7_2">Erfüllungsort und</div>
      <div id="t2h_2" class="t s7_2">Gerichtstand Lindau</div>
      <div id="t2i_2" class="t s7_2">Umsatzst.Ident.Nr.</div>
      <div id="t2j_2" class="t s7_2">DE 182358703</div>
      <div id="t2k_2" class="t s7_2">Steuer.Nr. 127/140/10105</div>
      <div id="t2l_2" class="t s7_2">Finanzamt Kempten</div>
      <div id="t2m_2" class="t s7_2">Bayerische Bodenseebank Lindau</div>
      <div id="t2n_2" class="t s7_2">Konto 44 58 43 • BLZ 733 698 21</div>
      <div id="t2o_2" class="t s7_2">IBAN: DE48733698210000445843</div>
      <div id="t2p_2" class="t s7_2">SWIFT (BIC) GENODEF1LBB</div>
      <div id="t2q_2" class="t s7_2">Tel. 0049 (0)8389 / 92 94 9-0</div>
      <div id="t2r_2" class="t s7_2">Fax 0049 (0)8389 / 92 94 949</div>
      <div id="t2s_2" class="t s7_2">Internet: www.tecmat.de</div>
      <div id="t2t_2" class="t s7_2">e-mail: info@tecmat.de</div>
      <div id="t2u_2" class="t s3_2">82199</div>

      <!-- End text definitions -->
    </div>
    <div
      id="p3"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1286px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          -webkit-transform-origin: bottom left;
          -ms-transform-origin: bottom left;
          transform-origin: bottom left;
          -webkit-transform: scale(0.25);
          -ms-transform: scale(0.25);
          transform: scale(0.25);
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_3 {
          left: 32px;
          bottom: 1142px;
          word-spacing: -0.1px;
        }
        #t2_3 {
          left: 118px;
          bottom: 1142px;
        }
        #t3_3 {
          left: 109px;
          bottom: 782px;
          letter-spacing: -0.2px;
        }
        #t4_3 {
          left: 114px;
          bottom: 743px;
          letter-spacing: 0.2px;
        }
        #t5_3 {
          left: 147px;
          bottom: 743px;
          letter-spacing: 0.1px;
        }
        #t6_3 {
          left: 518px;
          bottom: 743px;
        }
        #t7_3 {
          left: 588px;
          bottom: 743px;
        }
        #t8_3 {
          left: 734px;
          bottom: 743px;
        }
        #t9_3 {
          left: 781px;
          bottom: 743px;
          letter-spacing: 0.1px;
        }
        #ta_3 {
          left: 661px;
          bottom: 743px;
          letter-spacing: 0.1px;
        }
        #tb_3 {
          left: 277px;
          bottom: 725px;
          letter-spacing: 0.1px;
        }
        #tc_3 {
          left: 669px;
          bottom: 725px;
          letter-spacing: 0.2px;
        }
        #td_3 {
          left: 788px;
          bottom: 725px;
        }
        #te_3 {
          left: 583px;
          bottom: 961px;
          letter-spacing: -0.2px;
          word-spacing: -0.3px;
        }
        #tf_3 {
          left: 246px;
          bottom: 782px;
          letter-spacing: -0.1px;
          word-spacing: 0.2px;
        }
        #tg_3 {
          left: 246px;
          bottom: 762px;
          letter-spacing: -0.2px;
          word-spacing: 0.3px;
        }
        #th_3 {
          left: 384px;
          bottom: 762px;
          letter-spacing: -0.2px;
          word-spacing: 0.4px;
        }
        #ti_3 {
          left: 450px;
          bottom: 762px;
          letter-spacing: -0.1px;
        }
        #tj_3 {
          left: 109px;
          bottom: 964px;
          letter-spacing: -0.2px;
          word-spacing: 0.3px;
        }
        #tk_3 {
          left: 109px;
          bottom: 931px;
          letter-spacing: -0.2px;
        }
        #tl_3 {
          left: 143px;
          bottom: 931px;
          letter-spacing: -0.3px;
        }
        #tm_3 {
          left: 198px;
          bottom: 931px;
          letter-spacing: -0.1px;
        }
        #tn_3 {
          left: 109px;
          bottom: 983px;
          letter-spacing: -0.1px;
          word-spacing: 0.2px;
        }
        #to_3 {
          left: 109px;
          bottom: 848px;
          letter-spacing: -0.3px;
        }
        #tp_3 {
          left: 200px;
          bottom: 850px;
          letter-spacing: -0.1px;
        }
        #tq_3 {
          left: 113px;
          bottom: 826px;
        }
        #tr_3 {
          left: 201px;
          bottom: 826px;
          letter-spacing: -0.1px;
          word-spacing: 0.6px;
        }
        #ts_3 {
          left: 112px;
          bottom: 880px;
        }
        #tt_3 {
          left: 620px;
          bottom: 809px;
        }
        #tu_3 {
          left: 582px;
          bottom: 809px;
          letter-spacing: 0.1px;
          word-spacing: 0.4px;
        }
        #tv_3 {
          left: 312px;
          bottom: 851px;
        }
        #tw_3 {
          left: 313px;
          bottom: 826px;
          letter-spacing: -0.1px;
        }
        #tx_3 {
          left: 629px;
          bottom: 743px;
          letter-spacing: 0.4px;
        }
        #ty_3 {
          left: 582px;
          bottom: 1044px;
          letter-spacing: -0.1px;
          word-spacing: 0.1px;
        }
        #tz_3 {
          left: 582px;
          bottom: 1024px;
          letter-spacing: -0.2px;
        }
        #t10_3 {
          left: 109px;
          bottom: 1031px;
          letter-spacing: 0.1px;
          word-spacing: 0.2px;
        }
        #t11_3 {
          left: 582px;
          bottom: 1003px;
          letter-spacing: -0.2px;
        }
        #t12_3 {
          left: 582px;
          bottom: 985px;
          letter-spacing: -0.2px;
        }
        #t13_3 {
          left: 143px;
          bottom: 699px;
          letter-spacing: -0.2px;
        }
        #t14_3 {
          left: 273px;
          bottom: 679px;
          letter-spacing: -0.1px;
        }
        #t15_3 {
          left: 110px;
          bottom: 701px;
        }
        #t16_3 {
          left: 367px;
          bottom: 621px;
          letter-spacing: 0.2px;
        }
        #t17_3 {
          left: 143px;
          bottom: 659px;
          letter-spacing: -0.2px;
          word-spacing: 0.4px;
        }
        #t18_3 {
          left: 143px;
          bottom: 642px;
          letter-spacing: -0.2px;
        }
        #t19_3 {
          left: 541px;
          bottom: 699px;
          letter-spacing: 0.2px;
        }
        #t1a_3 {
          left: 586px;
          bottom: 699px;
          letter-spacing: -0.2px;
        }
        #t1b_3 {
          left: 794px;
          bottom: 699px;
          letter-spacing: 0.1px;
        }
        #t1c_3 {
          left: 676px;
          bottom: 699px;
          letter-spacing: 0.2px;
        }
        #t1d_3 {
          left: 273px;
          bottom: 621px;
          letter-spacing: 0.1px;
          word-spacing: -0.1px;
        }
        #t1e_3 {
          left: 633px;
          bottom: 699px;
        }
        #t1f_3 {
          left: 143px;
          bottom: 597px;
          letter-spacing: 0.2px;
          word-spacing: 0.1px;
        }
        #t1g_3 {
          left: 354px;
          bottom: 597px;
          letter-spacing: 0.1px;
        }
        #t1h_3 {
          left: 143px;
          bottom: 574px;
          letter-spacing: -0.2px;
        }
        #t1i_3 {
          left: 273px;
          bottom: 554px;
          letter-spacing: -0.1px;
          word-spacing: -0.3px;
        }
        #t1j_3 {
          left: 110px;
          bottom: 576px;
        }
        #t1k_3 {
          left: 367px;
          bottom: 515px;
          letter-spacing: 0.2px;
        }
        #t1l_3 {
          left: 143px;
          bottom: 534px;
          letter-spacing: -0.2px;
          word-spacing: 0.2px;
        }
        #t1m_3 {
          left: 531px;
          bottom: 573px;
          letter-spacing: 0.1px;
        }
        #t1n_3 {
          left: 586px;
          bottom: 574px;
          letter-spacing: -0.2px;
        }
        #t1o_3 {
          left: 794px;
          bottom: 573px;
          letter-spacing: 0.1px;
        }
        #t1p_3 {
          left: 685px;
          bottom: 573px;
          letter-spacing: 0.2px;
        }
        #t1q_3 {
          left: 273px;
          bottom: 515px;
          letter-spacing: 0.1px;
          word-spacing: -0.1px;
        }
        #t1r_3 {
          left: 633px;
          bottom: 574px;
        }
        #t1s_3 {
          left: 143px;
          bottom: 491px;
          letter-spacing: 0.2px;
          word-spacing: 0.1px;
        }
        #t1t_3 {
          left: 354px;
          bottom: 491px;
          letter-spacing: 0.1px;
        }
        #t1u_3 {
          left: 146px;
          bottom: 456px;
          letter-spacing: 0.1px;
          word-spacing: 0.1px;
        }
        #t1v_3 {
          left: 146px;
          bottom: 442px;
          letter-spacing: 0.1px;
          word-spacing: 0.1px;
        }
        #t1w_3 {
          left: 146px;
          bottom: 428px;
          letter-spacing: 0.1px;
          word-spacing: 0.4px;
        }
        #t1x_3 {
          left: 146px;
          bottom: 401px;
          letter-spacing: -0.2px;
          word-spacing: 0.3px;
        }
        #t1y_3 {
          left: 146px;
          bottom: 383px;
          letter-spacing: -0.1px;
        }
        #t1z_3 {
          left: 146px;
          bottom: 365px;
          letter-spacing: -0.1px;
          word-spacing: -0.2px;
        }
        #t20_3 {
          left: 146px;
          bottom: 344px;
          letter-spacing: -0.1px;
          word-spacing: -0.1px;
        }
        #t21_3 {
          left: 146px;
          bottom: 326px;
          letter-spacing: -0.1px;
          word-spacing: 0.2px;
        }
        #t22_3 {
          left: 588px;
          bottom: 153px;
          letter-spacing: 0.1px;
        }
        #t23_3 {
          left: 588px;
          bottom: 196px;
          letter-spacing: 0.2px;
        }
        #t24_3 {
          left: 588px;
          bottom: 175px;
          letter-spacing: 0.2px;
        }
        #t25_3 {
          left: 725px;
          bottom: 154px;
        }
        #t26_3 {
          left: 725px;
          bottom: 176px;
        }
        #t27_3 {
          left: 725px;
          bottom: 198px;
        }
        #t28_3 {
          left: 115px;
          bottom: 153px;
        }
        #t29_3 {
          left: 115px;
          bottom: 196px;
        }
        #t2a_3 {
          left: 115px;
          bottom: 175px;
        }
        #t2b_3 {
          left: 256px;
          bottom: 198px;
          letter-spacing: -0.2px;
        }
        #t2c_3 {
          left: 256px;
          bottom: 176px;
          letter-spacing: 0.1px;
          word-spacing: -0.2px;
        }
        #t2d_3 {
          left: 256px;
          bottom: 154px;
          letter-spacing: 0.1px;
          word-spacing: 0.3px;
        }
        #t2e_3 {
          left: 670px;
          bottom: 176px;
        }
        #t2f_3 {
          left: 808px;
          bottom: 176px;
        }
        #t2g_3 {
          left: 800px;
          bottom: 198px;
        }
        #t2h_3 {
          left: 801px;
          bottom: 154px;
        }
        #t2i_3 {
          left: 127px;
          bottom: 37px;
          letter-spacing: 0.1px;
          word-spacing: 0.4px;
        }
        #t2j_3 {
          left: 109px;
          bottom: 135px;
          word-spacing: 0.5px;
        }
        #t2k_3 {
          left: 87px;
          bottom: 108px;
          letter-spacing: 0.1px;
        }
        #t2l_3 {
          left: 87px;
          bottom: 94px;
          word-spacing: -0.1px;
        }
        #t2m_3 {
          left: 208px;
          bottom: 108px;
        }
        #t2n_3 {
          left: 208px;
          bottom: 94px;
          word-spacing: 0.5px;
        }
        #t2o_3 {
          left: 208px;
          bottom: 80px;
          word-spacing: 0.5px;
        }
        #t2p_3 {
          left: 208px;
          bottom: 67px;
          letter-spacing: 0.1px;
          word-spacing: -0.2px;
        }
        #t2q_3 {
          left: 339px;
          bottom: 108px;
          letter-spacing: 0.2px;
        }
        #t2r_3 {
          left: 339px;
          bottom: 94px;
          letter-spacing: -0.1px;
          word-spacing: 0.6px;
        }
        #t2s_3 {
          left: 339px;
          bottom: 80px;
          word-spacing: 0.5px;
        }
        #t2t_3 {
          left: 339px;
          bottom: 67px;
          letter-spacing: 0.1px;
          word-spacing: 0.4px;
        }
        #t2u_3 {
          left: 496px;
          bottom: 108px;
          letter-spacing: 0.1px;
          word-spacing: 0.4px;
        }
        #t2v_3 {
          left: 496px;
          bottom: 94px;
          word-spacing: 0.2px;
        }
        #t2w_3 {
          left: 496px;
          bottom: 80px;
          word-spacing: 0.5px;
        }
        #t2x_3 {
          left: 496px;
          bottom: 67px;
          letter-spacing: 0.2px;
          word-spacing: -0.3px;
        }
        #t2y_3 {
          left: 700px;
          bottom: 108px;
          word-spacing: 0.4px;
        }
        #t2z_3 {
          left: 700px;
          bottom: 94px;
          word-spacing: 0.4px;
        }
        #t30_3 {
          left: 700px;
          bottom: 80px;
          letter-spacing: 0.1px;
          word-spacing: 0.4px;
        }
        #t31_3 {
          left: 700px;
          bottom: 67px;
          letter-spacing: 0.1px;
          word-spacing: 0.3px;
        }
        #t32_3 {
          left: 551px;
          bottom: 135px;
          letter-spacing: -0.1px;
        }

        .s1 {
          font-size: 36.7px;
          font-family: LiberationSans_l;
          color: rgb(255, 255, 255);
        }

        .s2 {
          font-size: 61.1px;
          font-family: LiberationSans-Bold_g;
          color: rgb(0, 0, 0);
        }

        .s3 {
          font-size: 48.9px;
          font-family: LiberationSans_l;
          color: rgb(0, 0, 0);
        }

        .s4 {
          font-size: 61.1px;
          font-family: LiberationSans_l;
          color: rgb(0, 0, 0);
        }

        .s5 {
          font-size: 48.9px;
          font-family: LiberationSans-Bold_g;
          color: rgb(0, 0, 0);
        }

        .s6 {
          font-size: 67.2px;
          font-family: LiberationSans-Bold_g;
          color: rgb(0, 0, 0);
        }

        .s7 {
          font-size: 48.9px;
          font-family: LiberationSans_l;
          color: rgb(0, 0, 128);
        }

        .s8 {
          font-size: 55.1px;
          font-family: LiberationSans_l;
          color: rgb(0, 0, 0);
        }

        .s9 {
          font-size: 67.2px;
          font-family: LiberationSans_l;
          color: rgb(0, 0, 0);
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts3" type="text/css">
        @font-face {
          font-family: LiberationSans-Bold_g;
          src: url("fonts/LiberationSans-Bold_g.woff") format("woff");
        }

        @font-face {
          font-family: LiberationSans_l;
          src: url("fonts/LiberationSans_l.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg3Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg3" style="-webkit-user-select: none">
        <object
          width="909"
          height="1286"
          data="3/3.svg"
          type="image/svg+xml"
          id="pdf3"
          style="
            width: 909px;
            height: 1286px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div id="t1_3" class="t s1">@@BETREFF BE /</div>
      <div id="t2_3" class="t s1">APSOPARTS GMBH / 306371@@</div>
      <div id="t3_3" class="t s2">LIEFERADRESSE:</div>
      <div id="t4_3" class="t s3">Pos.</div>
      <div id="t5_3" class="t s3">Artikel-Nummer</div>
      <div id="t6_3" class="t s3">Menge</div>
      <div id="t7_3" class="t s3">Einheit</div>
      <div id="t8_3" class="t s3">%</div>
      <div id="t9_3" class="t s3">Gesamt</div>
      <div id="ta_3" class="t s3">E-Preis</div>
      <div id="tb_3" class="t s3">Bezeichnung</div>
      <div id="tc_3" class="t s3">EUR</div>
      <div id="td_3" class="t s3">EUR</div>
      <div id="te_3" class="t s4">#OCR-BE# 306371-1</div>
      <div id="tf_3" class="t s2">TECMAT Handels GmbH</div>
      <div id="tg_3" class="t s2">Hauptstrasse 56</div>
      <div id="th_3" class="t s2">D 88138</div>
      <div id="ti_3" class="t s2">Sigmarszell-Schlachters</div>
      <div id="tj_3" class="t s2">Siemensstrasse 5</div>
      <div id="tk_3" class="t s2">DE</div>
      <div id="tl_3" class="t s2">70736</div>
      <div id="tm_3" class="t s2">Fellbach</div>
      <div id="tn_3" class="t s2">APSOparts GmbH</div>
      <div id="to_3" class="t s2">306371</div>
      <div id="tp_3" class="t s5">751850</div>
      <div id="tq_3" class="t s5">Beleg-Nr.</div>
      <div id="tr_3" class="t s5">Unsere Kd-Nr.</div>
      <div id="ts_3" class="t s6">BESTELLUNG</div>
      <div id="tt_3" class="t s3">3</div>
      <div id="tu_3" class="t s3">Seite :</div>
      <div id="tv_3" class="t s5">01.07.2019</div>
      <div id="tw_3" class="t s5">Datum</div>
      <div id="tx_3" class="t s3">PE</div>
      <div id="ty_3" class="t s4">Ihr Ansprechpartner / Durchwahl</div>
      <div id="tz_3" class="t s4">Lukas Weber / (0 83 89) 9 29 49 - 12</div>
      <div id="t10_3" class="t s7">
        TECMAT Handels-GmbH • Hauptstraße 56 • 88138 Sigmarszell/Schlachters
      </div>
      <div id="t11_3" class="t s4">E-Mail</div>
      <div id="t12_3" class="t s4">l.weber@tecmat.de</div>
      <div id="t13_3" class="t s4">08-04-273615</div>
      <div id="t14_3" class="t s2">PU-Zahnriemen/Meterware</div>
      <div id="t15_3" class="t s8">7</div>
      <div id="t16_3" class="t s8">8000259133</div>
      <div id="t17_3" class="t s4">Abmessung 75 AT 10</div>
      <div id="t18_3" class="t s4">Stahlzugträger</div>
      <div id="t19_3" class="t s9">50,00</div>
      <div id="t1a_3" class="t s4">Meter</div>
      <div id="t1b_3" class="t s9">832,50</div>
      <div id="t1c_3" class="t s9">16,65</div>
      <div id="t1d_3" class="t s8">Ihre Artikel-Nr.</div>
      <div id="t1e_3" class="t s4">1</div>
      <div id="t1f_3" class="t s6">Lieferdatum eintreffend:</div>
      <div id="t1g_3" class="t s6">17.09.2019</div>
      <div id="t1h_3" class="t s4">08-04-271132</div>
      <div id="t1i_3" class="t s2">Zahnriemen Synchroflex</div>
      <div id="t1j_3" class="t s8">8</div>
      <div id="t1k_3" class="t s8">0918316989</div>
      <div id="t1l_3" class="t s4">16 AT 5 - 420</div>
      <div id="t1m_3" class="t s9">100,00</div>
      <div id="t1n_3" class="t s4">Stück</div>
      <div id="t1o_3" class="t s9">550,00</div>
      <div id="t1p_3" class="t s9">5,50</div>
      <div id="t1q_3" class="t s8">Ihre Artikel-Nr.</div>
      <div id="t1r_3" class="t s4">1</div>
      <div id="t1s_3" class="t s6">Lieferdatum eintreffend:</div>
      <div id="t1t_3" class="t s6">17.09.2019</div>
      <div id="t1u_3" class="t s3">
        Grundlage der Bestellung der TECMAT GmbH ist die
      </div>
      <div id="t1v_3" class="t s3">
        Zusicherung des Lieferanten, dass die von ihm gelieferten
      </div>
      <div id="t1w_3" class="t s3">
        Materialien unter REACH vorregistriert bzw. registriert sind.
      </div>
      <div id="t1x_3" class="t s2">DIE ANGEGEBENEN TERMINE VERSTEHEN</div>
      <div id="t1y_3" class="t s2">SICH EINTREFFEND! Bitte disponieren</div>
      <div id="t1z_3" class="t s2">Sie Ihren Versand rechtzeitig!</div>
      <div id="t20_3" class="t s2">
        Die Verpackung muss streng neutral (Klebe-
      </div>
      <div id="t21_3" class="t s2">bänder/Etiketten etc.) erfolgen.</div>
      <div id="t22_3" class="t s3">Bestellwert</div>
      <div id="t23_3" class="t s3">Netto</div>
      <div id="t24_3" class="t s3">MwSt</div>
      <div id="t25_3" class="t s3">EUR</div>
      <div id="t26_3" class="t s3">EUR</div>
      <div id="t27_3" class="t s3">EUR</div>
      <div id="t28_3" class="t s3">Zahlungsbedingungen</div>
      <div id="t29_3" class="t s3">Versandart</div>
      <div id="t2a_3" class="t s3">Versandbedingungen</div>
      <div id="t2b_3" class="t s3">DHL</div>
      <div id="t2c_3" class="t s3">frei Haus</div>
      <div id="t2d_3" class="t s3">30 Tage 2 %, 60 Tage netto</div>
      <div id="t2e_3" class="t s3">19,00%</div>
      <div id="t2f_3" class="t s3">710,26</div>
      <div id="t2g_3" class="t s3">3738,20</div>
      <div id="t2h_3" class="t s3">4448,46</div>
      <div id="t2i_3" class="t s7">
        Es gelten grundsätzlich unsere allgemeinen Geschäftsbedingungen.
        Anderslautende Bedingungen werden nicht anerkannt.
      </div>
      <div id="t2j_3" class="t s3">
        (PE 1= pro1, 2= pro10, 3= pro100, 4= pro1.000)
      </div>
      <div id="t2k_3" class="t s7">Geschäftsführung</div>
      <div id="t2l_3" class="t s7">Hermann Zöller</div>
      <div id="t2m_3" class="t s7">Handelsregister</div>
      <div id="t2n_3" class="t s7">Kempten HRB 6382</div>
      <div id="t2o_3" class="t s7">Erfüllungsort und</div>
      <div id="t2p_3" class="t s7">Gerichtstand Lindau</div>
      <div id="t2q_3" class="t s7">Umsatzst.Ident.Nr.</div>
      <div id="t2r_3" class="t s7">DE 182358703</div>
      <div id="t2s_3" class="t s7">Steuer.Nr. 127/140/10105</div>
      <div id="t2t_3" class="t s7">Finanzamt Kempten</div>
      <div id="t2u_3" class="t s7">Bayerische Bodenseebank Lindau</div>
      <div id="t2v_3" class="t s7">Konto 44 58 43 • BLZ 733 698 21</div>
      <div id="t2w_3" class="t s7">IBAN: DE48733698210000445843</div>
      <div id="t2x_3" class="t s7">SWIFT (BIC) GENODEF1LBB</div>
      <div id="t2y_3" class="t s7">Tel. 0049 (0)8389 / 92 94 9-0</div>
      <div id="t2z_3" class="t s7">Fax 0049 (0)8389 / 92 94 949</div>
      <div id="t30_3" class="t s7">Internet: www.tecmat.de</div>
      <div id="t31_3" class="t s7">e-mail: info@tecmat.de</div>
      <div id="t32_3" class="t s3">82199</div>

      <!-- End text definitions -->
    </div>
  </body>
</html>
  `,
  BE_306371: `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
    <meta charset="utf-8" />
  </head>

  <body style="margin: 0">
    <div
      id="p1"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1286px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_1 {
          left: 32px;
          bottom: 1142px;
          letter-spacing: 0.09px;
          word-spacing: -0.01px;
        }
        #t2_1 {
          left: 118px;
          bottom: 1142px;
          letter-spacing: 0.1px;
          word-spacing: -0.01px;
        }
        #t3_1 {
          left: 109px;
          bottom: 782px;
          letter-spacing: 0.17px;
        }
        #t4_1 {
          left: 114px;
          bottom: 744px;
          letter-spacing: 0.11px;
        }
        #t5_1 {
          left: 147px;
          bottom: 744px;
          letter-spacing: 0.11px;
        }
        #t6_1 {
          left: 518px;
          bottom: 744px;
          letter-spacing: 0.13px;
        }
        #t7_1 {
          left: 588px;
          bottom: 744px;
          letter-spacing: 0.1px;
        }
        #t8_1 {
          left: 734px;
          bottom: 744px;
        }
        #t9_1 {
          left: 781px;
          bottom: 744px;
          letter-spacing: 0.13px;
        }
        #ta_1 {
          left: 661px;
          bottom: 744px;
          letter-spacing: 0.1px;
        }
        #tb_1 {
          left: 147px;
          bottom: 725px;
          letter-spacing: 0.12px;
        }
        #tc_1 {
          left: 669px;
          bottom: 725px;
          letter-spacing: 0.16px;
        }
        #td_1 {
          left: 788px;
          bottom: 725px;
          letter-spacing: 0.16px;
        }
        #te_1 {
          left: 264px;
          bottom: 782px;
          letter-spacing: 0.14px;
        }
        #tf_1 {
          left: 291px;
          bottom: 782px;
          letter-spacing: 0.2px;
        }
        #tg_1 {
          left: 264px;
          bottom: 762px;
          letter-spacing: 0.15px;
        }
        #th_1 {
          left: 399px;
          bottom: 762px;
          letter-spacing: 0.1px;
        }
        #ti_1 {
          left: 429px;
          bottom: 762px;
          letter-spacing: 0.15px;
        }
        #tj_1 {
          left: 467px;
          bottom: 762px;
          letter-spacing: 0.14px;
        }
        #tk_1 {
          left: 109px;
          bottom: 984px;
          letter-spacing: 0.16px;
        }
        #tl_1 {
          left: 194px;
          bottom: 984px;
          letter-spacing: 0.14px;
        }
        #tm_1 {
          left: 109px;
          bottom: 964px;
          letter-spacing: 0.15px;
        }
        #tn_1 {
          left: 109px;
          bottom: 931px;
          letter-spacing: 0.16px;
        }
        #to_1 {
          left: 174px;
          bottom: 931px;
          letter-spacing: 0.14px;
        }
        #tp_1 {
          left: 586px;
          bottom: 892px;
          letter-spacing: 0.15px;
        }
        #tq_1 {
          left: 676px;
          bottom: 893px;
          letter-spacing: 0.12px;
        }
        #tr_1 {
          left: 589px;
          bottom: 869px;
          letter-spacing: 0.11px;
        }
        #ts_1 {
          left: 677px;
          bottom: 869px;
          letter-spacing: 0.11px;
          word-spacing: -0.01px;
        }
        #tt_1 {
          left: 588px;
          bottom: 923px;
          letter-spacing: -0.14px;
        }
        #tu_1 {
          left: 620px;
          bottom: 809px;
        }
        #tv_1 {
          left: 582px;
          bottom: 809px;
          letter-spacing: 0.09px;
          word-spacing: -0.01px;
        }
        #tw_1 {
          left: 789px;
          bottom: 895px;
          letter-spacing: 0.11px;
        }
        #tx_1 {
          left: 790px;
          bottom: 869px;
          letter-spacing: 0.13px;
        }
        #ty_1 {
          left: 629px;
          bottom: 744px;
          letter-spacing: 0.15px;
        }
        #tz_1 {
          left: 109px;
          bottom: 1031px;
          letter-spacing: 0.08px;
        }
        #t10_1 {
          left: 129px;
          bottom: 1031px;
          letter-spacing: 0.11px;
          word-spacing: -0.01px;
        }
        #t11_1 {
          left: 176px;
          bottom: 1031px;
          letter-spacing: 0.11px;
        }
        #t12_1 {
          left: 276px;
          bottom: 1031px;
          letter-spacing: 0.12px;
        }
        #t13_1 {
          left: 293px;
          bottom: 1031px;
        }
        #t14_1 {
          left: 300px;
          bottom: 1031px;
          letter-spacing: 0.12px;
        }
        #t15_1 {
          left: 331px;
          bottom: 1031px;
          letter-spacing: 0.1px;
        }
        #t16_1 {
          left: 582px;
          bottom: 1043px;
          letter-spacing: 0.13px;
          word-spacing: -0.01px;
        }
        #t17_1 {
          left: 582px;
          bottom: 1019px;
          letter-spacing: 0.1px;
        }
        #t18_1 {
          left: 608px;
          bottom: 1019px;
        }
        #t19_1 {
          left: 616px;
          bottom: 1019px;
          letter-spacing: 0.14px;
        }
        #t1a_1 {
          left: 582px;
          bottom: 1000px;
          letter-spacing: 0.13px;
        }
        #t1b_1 {
          left: 626px;
          bottom: 1000px;
          letter-spacing: 0.1px;
        }
        #t1c_1 {
          left: 659px;
          bottom: 1000px;
        }
        #t1d_1 {
          left: 674px;
          bottom: 1000px;
          letter-spacing: 0.11px;
        }
        #t1e_1 {
          left: 693px;
          bottom: 1000px;
        }
        #t1f_1 {
          left: 697px;
          bottom: 1000px;
          letter-spacing: 0.15px;
        }
        #t1g_1 {
          left: 582px;
          bottom: 976px;
          letter-spacing: 0.15px;
          word-spacing: -0.01px;
        }
        #t1h_1 {
          left: 143px;
          bottom: 699px;
          letter-spacing: 0.14px;
        }
        #t1i_1 {
          left: 143px;
          bottom: 679px;
          letter-spacing: 0.14px;
          word-spacing: -0.01px;
        }
        #t1j_1 {
          left: 110px;
          bottom: 701px;
        }
        #t1k_1 {
          left: 237px;
          bottom: 641px;
          letter-spacing: -0.14px;
        }
        #t1l_1 {
          left: 143px;
          bottom: 659px;
          letter-spacing: 0.13px;
          word-spacing: -0.01px;
        }
        #t1m_1 {
          left: 541px;
          bottom: 698px;
          letter-spacing: -0.1px;
        }
        #t1n_1 {
          left: 586px;
          bottom: 699px;
          letter-spacing: 0.14px;
        }
        #t1o_1 {
          left: 794px;
          bottom: 698px;
          letter-spacing: -0.1px;
        }
        #t1p_1 {
          left: 676px;
          bottom: 698px;
          letter-spacing: -0.1px;
        }
        #t1q_1 {
          left: 143px;
          bottom: 641px;
          letter-spacing: -0.1px;
          word-spacing: -0.01px;
        }
        #t1r_1 {
          left: 633px;
          bottom: 699px;
        }
        #t1s_1 {
          left: 143px;
          bottom: 617px;
          letter-spacing: -0.1px;
          word-spacing: -0.01px;
        }
        #t1t_1 {
          left: 354px;
          bottom: 617px;
          letter-spacing: -0.1px;
        }
        #t1u_1 {
          left: 143px;
          bottom: 594px;
          letter-spacing: 0.14px;
        }
        #t1v_1 {
          left: 143px;
          bottom: 574px;
          letter-spacing: 0.14px;
          word-spacing: -0.01px;
        }
        #t1w_1 {
          left: 110px;
          bottom: 596px;
        }
        #t1x_1 {
          left: 237px;
          bottom: 535px;
          letter-spacing: -0.14px;
        }
        #t1y_1 {
          left: 143px;
          bottom: 554px;
          letter-spacing: 0.13px;
          word-spacing: -0.01px;
        }
        #t1z_1 {
          left: 541px;
          bottom: 593px;
          letter-spacing: -0.1px;
        }
        #t20_1 {
          left: 586px;
          bottom: 594px;
          letter-spacing: 0.14px;
        }
        #t21_1 {
          left: 794px;
          bottom: 593px;
          letter-spacing: -0.1px;
        }
        #t22_1 {
          left: 685px;
          bottom: 593px;
          letter-spacing: -0.1px;
        }
        #t23_1 {
          left: 143px;
          bottom: 535px;
          letter-spacing: -0.1px;
          word-spacing: -0.01px;
        }
        #t24_1 {
          left: 633px;
          bottom: 594px;
        }
        #t25_1 {
          left: 143px;
          bottom: 511px;
          letter-spacing: -0.1px;
          word-spacing: -0.01px;
        }
        #t26_1 {
          left: 354px;
          bottom: 511px;
          letter-spacing: -0.1px;
        }
        #t27_1 {
          left: 143px;
          bottom: 489px;
          letter-spacing: 0.14px;
        }
        #t28_1 {
          left: 143px;
          bottom: 469px;
          letter-spacing: 0.14px;
          word-spacing: -0.01px;
        }
        #t29_1 {
          left: 110px;
          bottom: 491px;
        }
        #t2a_1 {
          left: 237px;
          bottom: 430px;
          letter-spacing: -0.14px;
        }
        #t2b_1 {
          left: 143px;
          bottom: 449px;
          letter-spacing: 0.13px;
          word-spacing: -0.01px;
        }
        #t2c_1 {
          left: 541px;
          bottom: 488px;
          letter-spacing: -0.1px;
        }
        #t2d_1 {
          left: 586px;
          bottom: 489px;
          letter-spacing: 0.14px;
        }
        #t2e_1 {
          left: 794px;
          bottom: 488px;
          letter-spacing: -0.1px;
        }
        #t2f_1 {
          left: 685px;
          bottom: 488px;
          letter-spacing: -0.1px;
        }
        #t2g_1 {
          left: 143px;
          bottom: 430px;
          letter-spacing: -0.1px;
          word-spacing: -0.01px;
        }
        #t2h_1 {
          left: 633px;
          bottom: 489px;
        }
        #t2i_1 {
          left: 143px;
          bottom: 406px;
          letter-spacing: -0.1px;
          word-spacing: -0.01px;
        }
        #t2j_1 {
          left: 354px;
          bottom: 406px;
          letter-spacing: -0.1px;
        }
        #t2k_1 {
          left: 588px;
          bottom: 240px;
          letter-spacing: 0.11px;
        }
        #t2l_1 {
          left: 722px;
          bottom: 241px;
          letter-spacing: 0.16px;
        }
        #t2m_1 {
          left: 801px;
          bottom: 242px;
          letter-spacing: 0.11px;
        }
        #t2n_1 {
          left: 127px;
          bottom: 37px;
          letter-spacing: 0.1px;
          word-spacing: -0.01px;
        }
        #t2o_1 {
          left: 109px;
          bottom: 135px;
          letter-spacing: 0.1px;
          word-spacing: -0.01px;
        }

        .s1_1 {
          font-size: 9px;
          font-family: sub_Arial_lsr;
          color: #fff;
        }
        .s2_1 {
          font-size: 15px;
          font-family: sub_Arial-Bold_lsb;
          color: #000;
        }
        .s3_1 {
          font-size: 12px;
          font-family: sub_Arial_lsr;
          color: #000;
        }
        .s4_1 {
          font-size: 15px;
          font-family: Arial-BoldMT_46;
          color: #000;
        }
        .s5_1 {
          font-size: 15px;
          font-family: Arial-BoldMT_28;
          color: #000;
        }
        .s6_1 {
          font-size: 12px;
          font-family: Arial-BoldMT_x;
          color: #000;
        }
        .s7_1 {
          font-size: 12px;
          font-family: sub_Arial-Bold_lsb;
          color: #000;
        }
        .s8_1 {
          font-size: 17px;
          font-family: sub_Arial-Bold_lsb;
          color: #000;
        }
        .s9_1 {
          font-size: 12px;
          font-family: ArialMT_21;
          color: #000080;
        }
        .sa_1 {
          font-size: 12px;
          font-family: sub_Arial_lsr;
          color: #000080;
        }
        .sb_1 {
          font-size: 12px;
          font-family: ArialMT_-;
          color: #000080;
        }
        .sc_1 {
          font-size: 15px;
          font-family: sub_Arial_lsr;
          color: #000;
        }
        .sd_1 {
          font-size: 15px;
          font-family: ArialMT_3-;
          color: #000;
        }
        .se_1 {
          font-size: 14px;
          font-family: sub_Arial_lsr;
          color: #000;
        }
        .sf_1 {
          font-size: 17px;
          font-family: sub_Arial_lsr;
          color: #000;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts1" type="text/css">
        @font-face {
          font-family: Arial-BoldMT_28;
          src: url("fonts/Arial-BoldMT_28.woff") format("woff");
        }

        @font-face {
          font-family: Arial-BoldMT_46;
          src: url("fonts/Arial-BoldMT_46.woff") format("woff");
        }

        @font-face {
          font-family: Arial-BoldMT_x;
          src: url("fonts/Arial-BoldMT_x.woff") format("woff");
        }

        @font-face {
          font-family: ArialMT_-;
          src: url("fonts/ArialMT_-.woff") format("woff");
        }

        @font-face {
          font-family: ArialMT_21;
          src: url("fonts/ArialMT_21.woff") format("woff");
        }

        @font-face {
          font-family: ArialMT_3-;
          src: url("fonts/ArialMT_3-.woff") format("woff");
        }

        @font-face {
          font-family: sub_Arial-Bold_lsb;
          src: url("fonts/sub_Arial-Bold_lsb.woff") format("woff");
        }

        @font-face {
          font-family: sub_Arial_lsr;
          src: url("fonts/sub_Arial_lsr.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg1Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg1" style="-webkit-user-select: none">
        <object
          width="909"
          height="1286"
          data="1/1.svg"
          type="image/svg+xml"
          id="pdf1"
          style="
            width: 909px;
            height: 1286px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_1" class="t s1_1">@@BETREFF BE / </span
        ><span id="t2_1" class="t s1_1">APSOPARTS GMBH / 306371@@ </span>
        <span id="t3_1" class="t s2_1">LIEFERADRESSE: </span>
        <span id="t4_1" class="t s3_1">Pos. </span
        ><span id="t5_1" class="t s3_1">Artikel-Nummer </span
        ><span id="t6_1" class="t s3_1">Menge </span
        ><span id="t7_1" class="t s3_1">Einheit </span
        ><span id="t8_1" class="t s3_1">% </span
        ><span id="t9_1" class="t s3_1">Gesamt </span
        ><span id="ta_1" class="t s3_1">E-Preis </span>
        <span id="tb_1" class="t s3_1">Bezeichnung </span
        ><span id="tc_1" class="t s3_1">EUR </span
        ><span id="td_1" class="t s3_1">EUR </span>
        <span id="te_1" class="t s4_1">Sly </span
        ><span id="tf_1" class="t s2_1">GmbH </span>
        <span id="tg_1" class="t s4_1">Binzmühlestrasse </span
        ><span id="th_1" class="t s4_1">99 </span
        ><span id="ti_1" class="t s4_1">8050 </span
        ><span id="tj_1" class="t s4_1">Zürich </span>
        <span id="tk_1" class="t s2_1">APSOparts </span
        ><span id="tl_1" class="t s5_1">AG </span>
        <span id="tm_1" class="t s5_1">Leutschenbachstrasse 55 </span>
        <span id="tn_1" class="t s5_1">CH-8052 </span
        ><span id="to_1" class="t s5_1">Zürich </span>
        <span id="tp_1" class="t s2_1">306371 </span
        ><span id="tq_1" class="t s6_1">999999 </span>
        <span id="tr_1" class="t s7_1">Beleg-Nr. </span
        ><span id="ts_1" class="t s7_1">Unsere Kd-Nr. </span>
        <span id="tt_1" class="t s8_1">BESTELLUNG </span>
        <span id="tu_1" class="t s3_1">1 </span
        ><span id="tv_1" class="t s3_1">Seite : </span>
        <span id="tw_1" class="t s7_1">01.07.2019 </span>
        <span id="tx_1" class="t s7_1">Datum </span>
        <span id="ty_1" class="t s3_1">PE </span>
        <span id="tz_1" class="t s9_1">Sly </span
        ><span id="t10_1" class="t sa_1">GmbH • </span
        ><span id="t11_1" class="t s9_1">Binzmühlestrasse </span
        ><span id="t12_1" class="t s9_1">99 </span
        ><span id="t13_1" class="t sa_1">• </span
        ><span id="t14_1" class="t sb_1">8050 </span
        ><span id="t15_1" class="t sb_1">Zürich </span>
        <span id="t16_1" class="t sc_1">Ihr Ansprechpartner / Durchwahl </span>
        <span id="t17_1" class="t sd_1">Sly </span
        ><span id="t18_1" class="t sc_1">/ </span
        ><span id="t19_1" class="t sd_1">044 552 72 72 </span>
        <span id="t1a_1" class="t sc_1">E-Mail</span
        ><span id="t1b_1" class="t sd_1">: info</span
        ><span id="t1c_1" class="t sc_1">@</span
        ><span id="t1d_1" class="t sd_1">sly</span
        ><span id="t1e_1" class="t sc_1">.</span
        ><span id="t1f_1" class="t sd_1">ch </span>
        <span id="t1g_1" class="t sc_1">#OCR-BE# 306371-1 </span>
        <span id="t1h_1" class="t sc_1">08-04-282364 </span>
        <span id="t1i_1" class="t s2_1">Zahnriemen Synchroflex </span>
        <span id="t1j_1" class="t se_1">1 </span>
        <span id="t1k_1" class="t se_1">0918317147 </span>
        <span id="t1l_1" class="t sc_1">25 AT 5 - 975 mm </span>
        <span id="t1m_1" class="t sf_1">40,00 </span
        ><span id="t1n_1" class="t sc_1">Stück </span
        ><span id="t1o_1" class="t sf_1">562,40 </span
        ><span id="t1p_1" class="t sf_1">14,06 </span>
        <span id="t1q_1" class="t se_1">Ihre Artikel-Nr. </span>
        <span id="t1r_1" class="t sc_1">1 </span>
        <span id="t1s_1" class="t s8_1">Lieferdatum eintreffend: </span
        ><span id="t1t_1" class="t s8_1">12.09.2019 </span>
        <span id="t1u_1" class="t sc_1">08-04-272831 </span>
        <span id="t1v_1" class="t s2_1">Zahnriemen Synchroflex </span>
        <span id="t1w_1" class="t se_1">2 </span>
        <span id="t1x_1" class="t se_1">0918316975 </span>
        <span id="t1y_1" class="t sc_1">Typ 10 AT5 - 390 </span>
        <span id="t1z_1" class="t sf_1">40,00 </span
        ><span id="t20_1" class="t sc_1">Stück </span
        ><span id="t21_1" class="t sf_1">149,20 </span
        ><span id="t22_1" class="t sf_1">3,73 </span>
        <span id="t23_1" class="t se_1">Ihre Artikel-Nr. </span>
        <span id="t24_1" class="t sc_1">1 </span>
        <span id="t25_1" class="t s8_1">Lieferdatum eintreffend: </span
        ><span id="t26_1" class="t s8_1">12.09.2019 </span>
        <span id="t27_1" class="t sc_1">08-04-268821 </span>
        <span id="t28_1" class="t s2_1">Zahnriemen Synchroflex </span>
        <span id="t29_1" class="t se_1">3 </span>
        <span id="t2a_1" class="t se_1">0918316979 </span>
        <span id="t2b_1" class="t sc_1">25 AT 5 - 390 </span>
        <span id="t2c_1" class="t sf_1">80,00 </span
        ><span id="t2d_1" class="t sc_1">Stück </span
        ><span id="t2e_1" class="t sf_1">574,40 </span
        ><span id="t2f_1" class="t sf_1">7,18 </span>
        <span id="t2g_1" class="t se_1">Ihre Artikel-Nr. </span>
        <span id="t2h_1" class="t sc_1">1 </span>
        <span id="t2i_1" class="t s8_1">Lieferdatum eintreffend: </span
        ><span id="t2j_1" class="t s8_1">12.09.2019 </span>
        <span id="t2k_1" class="t s3_1">Übertrag </span
        ><span id="t2l_1" class="t s3_1">EUR </span
        ><span id="t2m_1" class="t s3_1">1286,00 </span>
        <span id="t2n_1" class="t sa_1"
          >Es gelten grundsätzlich unsere allgemeinen Geschäftsbedingungen.
          Anderslautende Bedingungen werden nicht anerkannt.
        </span>
        <span id="t2o_1" class="t s3_1"
          >(PE 1= pro1, 2= pro10, 3= pro100, 4= pro1.000)
        </span>
      </div>
      <!-- End text definitions -->
    </div>
    <div
      id="p2"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1286px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_2 {
          left: 32px;
          bottom: 1142px;
          letter-spacing: 0.09px;
          word-spacing: -0.01px;
        }
        #t2_2 {
          left: 118px;
          bottom: 1142px;
          letter-spacing: 0.1px;
          word-spacing: -0.01px;
        }
        #t3_2 {
          left: 109px;
          bottom: 782px;
          letter-spacing: 0.17px;
        }
        #t4_2 {
          left: 114px;
          bottom: 744px;
          letter-spacing: 0.11px;
        }
        #t5_2 {
          left: 147px;
          bottom: 744px;
          letter-spacing: 0.11px;
        }
        #t6_2 {
          left: 518px;
          bottom: 744px;
          letter-spacing: 0.13px;
        }
        #t7_2 {
          left: 588px;
          bottom: 744px;
          letter-spacing: 0.1px;
        }
        #t8_2 {
          left: 734px;
          bottom: 744px;
        }
        #t9_2 {
          left: 781px;
          bottom: 744px;
          letter-spacing: 0.13px;
        }
        #ta_2 {
          left: 661px;
          bottom: 744px;
          letter-spacing: 0.1px;
        }
        #tb_2 {
          left: 147px;
          bottom: 725px;
          letter-spacing: 0.12px;
        }
        #tc_2 {
          left: 669px;
          bottom: 725px;
          letter-spacing: 0.16px;
        }
        #td_2 {
          left: 788px;
          bottom: 725px;
          letter-spacing: 0.16px;
        }
        #te_2 {
          left: 264px;
          bottom: 782px;
          letter-spacing: 0.17px;
        }
        #tf_2 {
          left: 264px;
          bottom: 762px;
          letter-spacing: 0.14px;
        }
        #tg_2 {
          left: 429px;
          bottom: 762px;
          letter-spacing: 0.14px;
        }
        #th_2 {
          left: 109px;
          bottom: 984px;
          letter-spacing: 0.15px;
        }
        #ti_2 {
          left: 109px;
          bottom: 964px;
          letter-spacing: 0.15px;
        }
        #tj_2 {
          left: 109px;
          bottom: 945px;
          letter-spacing: 0.15px;
        }
        #tk_2 {
          left: 586px;
          bottom: 892px;
          letter-spacing: 0.15px;
        }
        #tl_2 {
          left: 676px;
          bottom: 893px;
          letter-spacing: 0.12px;
        }
        #tm_2 {
          left: 589px;
          bottom: 869px;
          letter-spacing: 0.11px;
        }
        #tn_2 {
          left: 677px;
          bottom: 869px;
          letter-spacing: 0.11px;
          word-spacing: -0.01px;
        }
        #to_2 {
          left: 588px;
          bottom: 923px;
          letter-spacing: -0.14px;
        }
        #tp_2 {
          left: 620px;
          bottom: 809px;
        }
        #tq_2 {
          left: 582px;
          bottom: 809px;
          letter-spacing: 0.09px;
          word-spacing: -0.01px;
        }
        #tr_2 {
          left: 789px;
          bottom: 895px;
          letter-spacing: 0.11px;
        }
        #ts_2 {
          left: 790px;
          bottom: 869px;
          letter-spacing: 0.13px;
        }
        #tt_2 {
          left: 629px;
          bottom: 744px;
          letter-spacing: 0.15px;
        }
        #tu_2 {
          left: 109px;
          bottom: 1031px;
          letter-spacing: 0.11px;
        }
        #tv_2 {
          left: 583px;
          bottom: 1043px;
          letter-spacing: 0.12px;
        }
        #tw_2 {
          left: 583px;
          bottom: 1024px;
          letter-spacing: 0.13px;
        }
        #tx_2 {
          left: 583px;
          bottom: 1004px;
          letter-spacing: 0.13px;
        }
        #ty_2 {
          left: 583px;
          bottom: 985px;
          letter-spacing: 0.16px;
        }
        #tz_2 {
          left: 143px;
          bottom: 699px;
          letter-spacing: 0.14px;
        }
        #t10_2 {
          left: 143px;
          bottom: 679px;
          letter-spacing: 0.14px;
          word-spacing: -0.01px;
        }
        #t11_2 {
          left: 110px;
          bottom: 701px;
        }
        #t12_2 {
          left: 237px;
          bottom: 621px;
          letter-spacing: -0.14px;
        }
        #t13_2 {
          left: 143px;
          bottom: 659px;
          letter-spacing: 0.13px;
          word-spacing: -0.01px;
        }
        #t14_2 {
          left: 143px;
          bottom: 642px;
          letter-spacing: 0.12px;
          word-spacing: -0.01px;
        }
        #t15_2 {
          left: 541px;
          bottom: 698px;
          letter-spacing: -0.1px;
        }
        #t16_2 {
          left: 586px;
          bottom: 699px;
          letter-spacing: 0.14px;
        }
        #t17_2 {
          left: 794px;
          bottom: 698px;
          letter-spacing: -0.1px;
        }
        #t18_2 {
          left: 685px;
          bottom: 698px;
          letter-spacing: -0.1px;
        }
        #t19_2 {
          left: 143px;
          bottom: 621px;
          letter-spacing: -0.1px;
          word-spacing: -0.01px;
        }
        #t1a_2 {
          left: 633px;
          bottom: 699px;
        }
        #t1b_2 {
          left: 143px;
          bottom: 597px;
          letter-spacing: -0.1px;
          word-spacing: -0.01px;
        }
        #t1c_2 {
          left: 354px;
          bottom: 597px;
          letter-spacing: -0.1px;
        }
        #t1d_2 {
          left: 143px;
          bottom: 574px;
          letter-spacing: 0.14px;
        }
        #t1e_2 {
          left: 143px;
          bottom: 554px;
          letter-spacing: 0.14px;
          word-spacing: -0.01px;
        }
        #t1f_2 {
          left: 110px;
          bottom: 576px;
        }
        #t1g_2 {
          left: 237px;
          bottom: 515px;
          letter-spacing: -0.14px;
        }
        #t1h_2 {
          left: 143px;
          bottom: 534px;
          letter-spacing: 0.12px;
          word-spacing: -0.01px;
        }
        #t1i_2 {
          left: 541px;
          bottom: 573px;
          letter-spacing: -0.1px;
        }
        #t1j_2 {
          left: 586px;
          bottom: 574px;
          letter-spacing: 0.14px;
        }
        #t1k_2 {
          left: 794px;
          bottom: 573px;
          letter-spacing: -0.1px;
        }
        #t1l_2 {
          left: 685px;
          bottom: 573px;
          letter-spacing: -0.1px;
        }
        #t1m_2 {
          left: 143px;
          bottom: 515px;
          letter-spacing: -0.1px;
          word-spacing: -0.01px;
        }
        #t1n_2 {
          left: 633px;
          bottom: 574px;
        }
        #t1o_2 {
          left: 143px;
          bottom: 491px;
          letter-spacing: -0.1px;
          word-spacing: -0.01px;
        }
        #t1p_2 {
          left: 354px;
          bottom: 491px;
          letter-spacing: -0.1px;
        }
        #t1q_2 {
          left: 143px;
          bottom: 469px;
          letter-spacing: 0.14px;
        }
        #t1r_2 {
          left: 143px;
          bottom: 449px;
          letter-spacing: 0.14px;
          word-spacing: -0.01px;
        }
        #t1s_2 {
          left: 110px;
          bottom: 471px;
        }
        #t1t_2 {
          left: 237px;
          bottom: 410px;
          letter-spacing: -0.14px;
        }
        #t1u_2 {
          left: 143px;
          bottom: 429px;
          letter-spacing: 0.13px;
          word-spacing: -0.01px;
        }
        #t1v_2 {
          left: 550px;
          bottom: 468px;
          letter-spacing: -0.1px;
        }
        #t1w_2 {
          left: 586px;
          bottom: 469px;
          letter-spacing: 0.14px;
        }
        #t1x_2 {
          left: 794px;
          bottom: 468px;
          letter-spacing: -0.1px;
        }
        #t1y_2 {
          left: 676px;
          bottom: 468px;
          letter-spacing: -0.1px;
        }
        #t1z_2 {
          left: 143px;
          bottom: 410px;
          letter-spacing: -0.1px;
          word-spacing: -0.01px;
        }
        #t20_2 {
          left: 633px;
          bottom: 469px;
        }
        #t21_2 {
          left: 143px;
          bottom: 386px;
          letter-spacing: -0.1px;
          word-spacing: -0.01px;
        }
        #t22_2 {
          left: 354px;
          bottom: 386px;
          letter-spacing: -0.1px;
        }
        #t23_2 {
          left: 588px;
          bottom: 240px;
          letter-spacing: 0.11px;
        }
        #t24_2 {
          left: 722px;
          bottom: 241px;
          letter-spacing: 0.16px;
        }
        #t25_2 {
          left: 801px;
          bottom: 242px;
          letter-spacing: 0.11px;
        }
        #t26_2 {
          left: 127px;
          bottom: 37px;
          letter-spacing: 0.1px;
          word-spacing: -0.01px;
        }
        #t27_2 {
          left: 109px;
          bottom: 135px;
          letter-spacing: 0.1px;
          word-spacing: -0.01px;
        }

        .s1_2 {
          font-size: 9px;
          font-family: sub_Arial_lsr;
          color: #fff;
        }
        .s2_2 {
          font-size: 15px;
          font-family: sub_Arial-Bold_lsb;
          color: #000;
        }
        .s3_2 {
          font-size: 12px;
          font-family: sub_Arial_lsr;
          color: #000;
        }
        .s4_2 {
          font-size: 15px;
          font-family: Arial-BoldMT_3s;
          color: #000;
        }
        .s5_2 {
          font-size: 15px;
          font-family: Arial-BoldMT_3l;
          color: #000;
        }
        .s6_2 {
          font-size: 12px;
          font-family: Arial-BoldMT_x;
          color: #000;
        }
        .s7_2 {
          font-size: 12px;
          font-family: sub_Arial-Bold_lsb;
          color: #000;
        }
        .s8_2 {
          font-size: 17px;
          font-family: sub_Arial-Bold_lsb;
          color: #000;
        }
        .s9_2 {
          font-size: 12px;
          font-family: ArialMT_3e;
          color: #000080;
        }
        .sa_2 {
          font-size: 15px;
          font-family: ArialMT_37;
          color: #000;
        }
        .sb_2 {
          font-size: 15px;
          font-family: sub_Arial_lsr;
          color: #000;
        }
        .sc_2 {
          font-size: 14px;
          font-family: sub_Arial_lsr;
          color: #000;
        }
        .sd_2 {
          font-size: 17px;
          font-family: sub_Arial_lsr;
          color: #000;
        }
        .se_2 {
          font-size: 12px;
          font-family: sub_Arial_lsr;
          color: #000080;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts2" type="text/css">
        @font-face {
          font-family: Arial-BoldMT_3l;
          src: url("fonts/Arial-BoldMT_3l.woff") format("woff");
        }

        @font-face {
          font-family: Arial-BoldMT_3s;
          src: url("fonts/Arial-BoldMT_3s.woff") format("woff");
        }

        @font-face {
          font-family: Arial-BoldMT_x;
          src: url("fonts/Arial-BoldMT_x.woff") format("woff");
        }

        @font-face {
          font-family: ArialMT_37;
          src: url("fonts/ArialMT_37.woff") format("woff");
        }

        @font-face {
          font-family: ArialMT_3e;
          src: url("fonts/ArialMT_3e.woff") format("woff");
        }

        @font-face {
          font-family: sub_Arial-Bold_lsb;
          src: url("fonts/sub_Arial-Bold_lsb.woff") format("woff");
        }

        @font-face {
          font-family: sub_Arial_lsr;
          src: url("fonts/sub_Arial_lsr.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg2Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg2" style="-webkit-user-select: none">
        <object
          width="909"
          height="1286"
          data="2/2.svg"
          type="image/svg+xml"
          id="pdf2"
          style="
            width: 909px;
            height: 1286px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_2" class="t s1_2">@@BETREFF BE / </span
        ><span id="t2_2" class="t s1_2">APSOPARTS GMBH / 306371@@ </span>
        <span id="t3_2" class="t s2_2">LIEFERADRESSE: </span>
        <span id="t4_2" class="t s3_2">Pos. </span
        ><span id="t5_2" class="t s3_2">Artikel-Nummer </span
        ><span id="t6_2" class="t s3_2">Menge </span
        ><span id="t7_2" class="t s3_2">Einheit </span
        ><span id="t8_2" class="t s3_2">% </span
        ><span id="t9_2" class="t s3_2">Gesamt </span
        ><span id="ta_2" class="t s3_2">E-Preis </span>
        <span id="tb_2" class="t s3_2">Bezeichnung </span
        ><span id="tc_2" class="t s3_2">EUR </span
        ><span id="td_2" class="t s3_2">EUR </span>
        <span id="te_2" class="t s4_2">Sly GmbH </span>
        <span id="tf_2" class="t s4_2">Binzmühlestrasse 99 </span
        ><span id="tg_2" class="t s4_2">8050 Zürich </span>
        <span id="th_2" class="t s5_2">APSOparts AG </span>
        <span id="ti_2" class="t s5_2">Leutschenbachstrasse 55 </span>
        <span id="tj_2" class="t s5_2">CH-8052 Zürich </span>
        <span id="tk_2" class="t s2_2">306371 </span
        ><span id="tl_2" class="t s6_2">999999 </span>
        <span id="tm_2" class="t s7_2">Beleg-Nr. </span
        ><span id="tn_2" class="t s7_2">Unsere Kd-Nr. </span>
        <span id="to_2" class="t s8_2">BESTELLUNG </span>
        <span id="tp_2" class="t s3_2">2 </span
        ><span id="tq_2" class="t s3_2">Seite : </span>
        <span id="tr_2" class="t s7_2">01.07.2019 </span>
        <span id="ts_2" class="t s7_2">Datum </span>
        <span id="tt_2" class="t s3_2">PE </span>
        <span id="tu_2" class="t s9_2"
          >Sly GmbH • Binzmühlestrasse 99 • 8050 Zürich
        </span>
        <span id="tv_2" class="t sa_2">Ihr Ansprechpartner / Durchwahl </span>
        <span id="tw_2" class="t sa_2">Sly / 044 552 72 72 </span>
        <span id="tx_2" class="t sa_2">E-Mail: info@sly.ch </span>
        <span id="ty_2" class="t sa_2">#OCR-BE# 306371-1 </span>
        <span id="tz_2" class="t sb_2">08-04-283192 </span>
        <span id="t10_2" class="t s2_2">Zahnriemen Synchroflex </span>
        <span id="t11_2" class="t sc_2">4 </span>
        <span id="t12_2" class="t sc_2">8000301362 </span>
        <span id="t13_2" class="t sb_2">25 AT 5 - 500 </span>
        <span id="t14_2" class="t sb_2">mit Kevlar-Zugstrang P 3/8 </span>
        <span id="t15_2" class="t sd_2">40,00 </span
        ><span id="t16_2" class="t sb_2">Stück </span
        ><span id="t17_2" class="t sd_2">322,00 </span
        ><span id="t18_2" class="t sd_2">8,05 </span>
        <span id="t19_2" class="t sc_2">Ihre Artikel-Nr. </span>
        <span id="t1a_2" class="t sb_2">1 </span>
        <span id="t1b_2" class="t s8_2">Lieferdatum eintreffend: </span
        ><span id="t1c_2" class="t s8_2">12.09.2019 </span>
        <span id="t1d_2" class="t sb_2">08-04-269214 </span>
        <span id="t1e_2" class="t s2_2">Zahnriemen Synchroflex </span>
        <span id="t1f_2" class="t sc_2">5 </span>
        <span id="t1g_2" class="t sc_2">0918317097 </span>
        <span id="t1h_2" class="t sb_2">16 AT5 - 720 </span>
        <span id="t1i_2" class="t sd_2">70,00 </span
        ><span id="t1j_2" class="t sb_2">Stück </span
        ><span id="t1k_2" class="t sd_2">585,20 </span
        ><span id="t1l_2" class="t sd_2">8,36 </span>
        <span id="t1m_2" class="t sc_2">Ihre Artikel-Nr. </span>
        <span id="t1n_2" class="t sb_2">1 </span>
        <span id="t1o_2" class="t s8_2">Lieferdatum eintreffend: </span
        ><span id="t1p_2" class="t s8_2">12.09.2019 </span>
        <span id="t1q_2" class="t sb_2">08-04-1030835 </span>
        <span id="t1r_2" class="t s2_2">Zahnriemen Synchroflex </span>
        <span id="t1s_2" class="t sc_2">6 </span>
        <span id="t1t_2" class="t sc_2">0918317175 </span>
        <span id="t1u_2" class="t sb_2">50 AT5 - 1125 </span>
        <span id="t1v_2" class="t sd_2">5,00 </span
        ><span id="t1w_2" class="t sb_2">Stück </span
        ><span id="t1x_2" class="t sd_2">162,50 </span
        ><span id="t1y_2" class="t sd_2">32,50 </span>
        <span id="t1z_2" class="t sc_2">Ihre Artikel-Nr. </span>
        <span id="t20_2" class="t sb_2">1 </span>
        <span id="t21_2" class="t s8_2">Lieferdatum eintreffend: </span
        ><span id="t22_2" class="t s8_2">12.09.2019 </span>
        <span id="t23_2" class="t s3_2">Übertrag </span
        ><span id="t24_2" class="t s3_2">EUR </span
        ><span id="t25_2" class="t s3_2">2355,70 </span>
        <span id="t26_2" class="t se_2"
          >Es gelten grundsätzlich unsere allgemeinen Geschäftsbedingungen.
          Anderslautende Bedingungen werden nicht anerkannt.
        </span>
        <span id="t27_2" class="t s3_2"
          >(PE 1= pro1, 2= pro10, 3= pro100, 4= pro1.000)
        </span>
      </div>
      <!-- End text definitions -->
    </div>
    <div
      id="p3"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1286px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_3 {
          left: 32px;
          bottom: 1142px;
          letter-spacing: 0.09px;
          word-spacing: -0.01px;
        }
        #t2_3 {
          left: 118px;
          bottom: 1142px;
          letter-spacing: 0.1px;
          word-spacing: -0.01px;
        }
        #t3_3 {
          left: 109px;
          bottom: 782px;
          letter-spacing: 0.17px;
        }
        #t4_3 {
          left: 114px;
          bottom: 744px;
          letter-spacing: 0.11px;
        }
        #t5_3 {
          left: 147px;
          bottom: 744px;
          letter-spacing: 0.11px;
        }
        #t6_3 {
          left: 518px;
          bottom: 744px;
          letter-spacing: 0.13px;
        }
        #t7_3 {
          left: 588px;
          bottom: 744px;
          letter-spacing: 0.1px;
        }
        #t8_3 {
          left: 734px;
          bottom: 744px;
        }
        #t9_3 {
          left: 781px;
          bottom: 744px;
          letter-spacing: 0.13px;
        }
        #ta_3 {
          left: 661px;
          bottom: 744px;
          letter-spacing: 0.1px;
        }
        #tb_3 {
          left: 147px;
          bottom: 725px;
          letter-spacing: 0.12px;
        }
        #tc_3 {
          left: 669px;
          bottom: 725px;
          letter-spacing: 0.16px;
        }
        #td_3 {
          left: 788px;
          bottom: 725px;
          letter-spacing: 0.16px;
        }
        #te_3 {
          left: 264px;
          bottom: 782px;
          letter-spacing: 0.17px;
        }
        #tf_3 {
          left: 264px;
          bottom: 762px;
          letter-spacing: 0.14px;
        }
        #tg_3 {
          left: 429px;
          bottom: 762px;
          letter-spacing: 0.14px;
        }
        #th_3 {
          left: 109px;
          bottom: 984px;
          letter-spacing: 0.15px;
        }
        #ti_3 {
          left: 109px;
          bottom: 964px;
          letter-spacing: 0.15px;
        }
        #tj_3 {
          left: 109px;
          bottom: 945px;
          letter-spacing: 0.15px;
        }
        #tk_3 {
          left: 586px;
          bottom: 892px;
          letter-spacing: 0.15px;
        }
        #tl_3 {
          left: 676px;
          bottom: 893px;
          letter-spacing: 0.12px;
        }
        #tm_3 {
          left: 589px;
          bottom: 869px;
          letter-spacing: 0.11px;
        }
        #tn_3 {
          left: 677px;
          bottom: 869px;
          letter-spacing: 0.11px;
          word-spacing: -0.01px;
        }
        #to_3 {
          left: 588px;
          bottom: 923px;
          letter-spacing: -0.14px;
        }
        #tp_3 {
          left: 620px;
          bottom: 809px;
        }
        #tq_3 {
          left: 582px;
          bottom: 809px;
          letter-spacing: 0.09px;
          word-spacing: -0.01px;
        }
        #tr_3 {
          left: 789px;
          bottom: 895px;
          letter-spacing: 0.11px;
        }
        #ts_3 {
          left: 790px;
          bottom: 869px;
          letter-spacing: 0.13px;
        }
        #tt_3 {
          left: 629px;
          bottom: 744px;
          letter-spacing: 0.15px;
        }
        #tu_3 {
          left: 109px;
          bottom: 1031px;
          letter-spacing: 0.11px;
        }
        #tv_3 {
          left: 582px;
          bottom: 1043px;
        }
        #tw_3 {
          left: 587px;
          bottom: 1043px;
          letter-spacing: 0.13px;
        }
        #tx_3 {
          left: 582px;
          bottom: 1019px;
          letter-spacing: 0.13px;
        }
        #ty_3 {
          left: 582px;
          bottom: 995px;
          letter-spacing: 0.13px;
        }
        #tz_3 {
          left: 582px;
          bottom: 971px;
          letter-spacing: 0.16px;
        }
        #t10_3 {
          left: 728px;
          bottom: 971px;
        }
        #t11_3 {
          left: 143px;
          bottom: 699px;
          letter-spacing: 0.14px;
        }
        #t12_3 {
          left: 143px;
          bottom: 679px;
          letter-spacing: 0.15px;
        }
        #t13_3 {
          left: 110px;
          bottom: 701px;
        }
        #t14_3 {
          left: 237px;
          bottom: 621px;
          letter-spacing: -0.14px;
        }
        #t15_3 {
          left: 143px;
          bottom: 659px;
          letter-spacing: 0.14px;
          word-spacing: -0.01px;
        }
        #t16_3 {
          left: 143px;
          bottom: 642px;
          letter-spacing: 0.13px;
        }
        #t17_3 {
          left: 541px;
          bottom: 698px;
          letter-spacing: -0.1px;
        }
        #t18_3 {
          left: 586px;
          bottom: 699px;
          letter-spacing: 0.13px;
        }
        #t19_3 {
          left: 794px;
          bottom: 698px;
          letter-spacing: -0.1px;
        }
        #t1a_3 {
          left: 676px;
          bottom: 698px;
          letter-spacing: -0.1px;
        }
        #t1b_3 {
          left: 143px;
          bottom: 621px;
          letter-spacing: -0.1px;
          word-spacing: -0.01px;
        }
        #t1c_3 {
          left: 633px;
          bottom: 699px;
        }
        #t1d_3 {
          left: 110px;
          bottom: 576px;
        }
        #t1e_3 {
          left: 531px;
          bottom: 573px;
          letter-spacing: -0.1px;
        }
        #t1f_3 {
          left: 586px;
          bottom: 574px;
          letter-spacing: 0.14px;
        }
        #t1g_3 {
          left: 794px;
          bottom: 573px;
          letter-spacing: -0.1px;
        }
        #t1h_3 {
          left: 685px;
          bottom: 573px;
          letter-spacing: -0.1px;
        }
        #t1i_3 {
          left: 633px;
          bottom: 574px;
        }
        #t1j_3 {
          left: 143px;
          bottom: 597px;
          letter-spacing: -0.1px;
          word-spacing: -0.01px;
        }
        #t1k_3 {
          left: 354px;
          bottom: 597px;
          letter-spacing: -0.1px;
        }
        #t1l_3 {
          left: 143px;
          bottom: 574px;
          letter-spacing: 0.14px;
        }
        #t1m_3 {
          left: 143px;
          bottom: 554px;
          letter-spacing: 0.14px;
          word-spacing: -0.01px;
        }
        #t1n_3 {
          left: 143px;
          bottom: 533px;
          letter-spacing: 0.13px;
          word-spacing: -0.01px;
        }
        #t1o_3 {
          left: 143px;
          bottom: 514px;
          letter-spacing: -0.1px;
          word-spacing: -0.01px;
        }
        #t1p_3 {
          left: 237px;
          bottom: 515px;
          letter-spacing: -0.14px;
        }
        #t1q_3 {
          left: 143px;
          bottom: 490px;
          letter-spacing: -0.1px;
          word-spacing: -0.01px;
        }
        #t1r_3 {
          left: 354px;
          bottom: 490px;
          letter-spacing: -0.1px;
        }
        #t1s_3 {
          left: 146px;
          bottom: 455px;
          letter-spacing: 0.1px;
          word-spacing: -0.01px;
        }
        #t1t_3 {
          left: 146px;
          bottom: 441px;
          letter-spacing: 0.09px;
          word-spacing: -0.01px;
        }
        #t1u_3 {
          left: 146px;
          bottom: 428px;
          letter-spacing: 0.09px;
          word-spacing: -0.01px;
        }
        #t1v_3 {
          left: 146px;
          bottom: 400px;
          letter-spacing: 0.17px;
          word-spacing: -0.01px;
        }
        #t1w_3 {
          left: 146px;
          bottom: 383px;
          letter-spacing: 0.14px;
          word-spacing: -0.01px;
        }
        #t1x_3 {
          left: 146px;
          bottom: 365px;
          letter-spacing: 0.12px;
          word-spacing: -0.01px;
        }
        #t1y_3 {
          left: 146px;
          bottom: 343px;
          letter-spacing: 0.13px;
          word-spacing: -0.01px;
        }
        #t1z_3 {
          left: 146px;
          bottom: 325px;
          letter-spacing: 0.12px;
          word-spacing: -0.01px;
        }
        #t20_3 {
          left: 588px;
          bottom: 153px;
          letter-spacing: 0.1px;
        }
        #t21_3 {
          left: 588px;
          bottom: 196px;
          letter-spacing: 0.1px;
        }
        #t22_3 {
          left: 588px;
          bottom: 175px;
          letter-spacing: 0.13px;
        }
        #t23_3 {
          left: 725px;
          bottom: 154px;
          letter-spacing: 0.16px;
        }
        #t24_3 {
          left: 725px;
          bottom: 176px;
          letter-spacing: 0.16px;
        }
        #t25_3 {
          left: 725px;
          bottom: 198px;
          letter-spacing: 0.16px;
        }
        #t26_3 {
          left: 115px;
          bottom: 153px;
          letter-spacing: 0.11px;
        }
        #t27_3 {
          left: 115px;
          bottom: 196px;
          letter-spacing: 0.11px;
        }
        #t28_3 {
          left: 115px;
          bottom: 175px;
          letter-spacing: 0.12px;
        }
        #t29_3 {
          left: 256px;
          bottom: 198px;
          letter-spacing: 0.15px;
        }
        #t2a_3 {
          left: 256px;
          bottom: 176px;
          letter-spacing: 0.1px;
          word-spacing: -0.01px;
        }
        #t2b_3 {
          left: 256px;
          bottom: 154px;
          letter-spacing: 0.1px;
          word-spacing: -0.01px;
        }
        #t2c_3 {
          left: 670px;
          bottom: 176px;
          letter-spacing: 0.12px;
        }
        #t2d_3 {
          left: 808px;
          bottom: 176px;
          letter-spacing: 0.11px;
        }
        #t2e_3 {
          left: 800px;
          bottom: 198px;
          letter-spacing: 0.11px;
        }
        #t2f_3 {
          left: 801px;
          bottom: 154px;
          letter-spacing: 0.11px;
        }
        #t2g_3 {
          left: 127px;
          bottom: 37px;
          letter-spacing: 0.1px;
          word-spacing: -0.01px;
        }
        #t2h_3 {
          left: 109px;
          bottom: 135px;
          letter-spacing: 0.1px;
          word-spacing: -0.01px;
        }

        .s1 {
          font-size: 9px;
          font-family: sub_Arial_lsr;
          color: #fff;
        }
        .s2 {
          font-size: 15px;
          font-family: sub_Arial-Bold_lsb;
          color: #000;
        }
        .s3 {
          font-size: 12px;
          font-family: sub_Arial_lsr;
          color: #000;
        }
        .s4 {
          font-size: 15px;
          font-family: Arial-BoldMT_2-;
          color: #000;
        }
        .s5 {
          font-size: 15px;
          font-family: Arial-BoldMT_2t;
          color: #000;
        }
        .s6 {
          font-size: 12px;
          font-family: Arial-BoldMT_x;
          color: #000;
        }
        .s7 {
          font-size: 12px;
          font-family: sub_Arial-Bold_lsb;
          color: #000;
        }
        .s8 {
          font-size: 17px;
          font-family: sub_Arial-Bold_lsb;
          color: #000;
        }
        .s9 {
          font-size: 12px;
          font-family: ArialMT_2m;
          color: #000080;
        }
        .sa {
          font-size: 15px;
          font-family: sub_Arial_lsr;
          color: #000;
        }
        .sb {
          font-size: 15px;
          font-family: ArialMT_2f;
          color: #000;
        }
        .sc {
          font-size: 14px;
          font-family: sub_Arial_lsr;
          color: #000;
        }
        .sd {
          font-size: 17px;
          font-family: sub_Arial_lsr;
          color: #000;
        }
        .se {
          font-size: 12px;
          font-family: sub_Arial_lsr;
          color: #000080;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts3" type="text/css">
        @font-face {
          font-family: Arial-BoldMT_2-;
          src: url("fonts/Arial-BoldMT_2-.woff") format("woff");
        }

        @font-face {
          font-family: Arial-BoldMT_2t;
          src: url("fonts/Arial-BoldMT_2t.woff") format("woff");
        }

        @font-face {
          font-family: Arial-BoldMT_x;
          src: url("fonts/Arial-BoldMT_x.woff") format("woff");
        }

        @font-face {
          font-family: ArialMT_2f;
          src: url("fonts/ArialMT_2f.woff") format("woff");
        }

        @font-face {
          font-family: ArialMT_2m;
          src: url("fonts/ArialMT_2m.woff") format("woff");
        }

        @font-face {
          font-family: sub_Arial-Bold_lsb;
          src: url("fonts/sub_Arial-Bold_lsb.woff") format("woff");
        }

        @font-face {
          font-family: sub_Arial_lsr;
          src: url("fonts/sub_Arial_lsr.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg3Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg3" style="-webkit-user-select: none">
        <object
          width="909"
          height="1286"
          data="3/3.svg"
          type="image/svg+xml"
          id="pdf3"
          style="
            width: 909px;
            height: 1286px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_3" class="t s1">@@BETREFF BE / </span
        ><span id="t2_3" class="t s1">APSOPARTS GMBH / 306371@@ </span>
        <span id="t3_3" class="t s2">LIEFERADRESSE: </span>
        <span id="t4_3" class="t s3">Pos. </span
        ><span id="t5_3" class="t s3">Artikel-Nummer </span
        ><span id="t6_3" class="t s3">Menge </span
        ><span id="t7_3" class="t s3">Einheit </span
        ><span id="t8_3" class="t s3">% </span
        ><span id="t9_3" class="t s3">Gesamt </span
        ><span id="ta_3" class="t s3">E-Preis </span>
        <span id="tb_3" class="t s3">Bezeichnung </span
        ><span id="tc_3" class="t s3">EUR </span
        ><span id="td_3" class="t s3">EUR </span>
        <span id="te_3" class="t s4">Sly GmbH </span>
        <span id="tf_3" class="t s4">Binzmühlestrasse 99 </span
        ><span id="tg_3" class="t s4">8050 Zürich </span>
        <span id="th_3" class="t s5">APSOparts AG </span>
        <span id="ti_3" class="t s5">Leutschenbachstrasse 55 </span>
        <span id="tj_3" class="t s5">CH-8052 Zürich </span>
        <span id="tk_3" class="t s2">306371 </span
        ><span id="tl_3" class="t s6">999999 </span>
        <span id="tm_3" class="t s7">Beleg-Nr. </span
        ><span id="tn_3" class="t s7">Unsere Kd-Nr. </span>
        <span id="to_3" class="t s8">BESTELLUNG </span>
        <span id="tp_3" class="t s3">3 </span
        ><span id="tq_3" class="t s3">Seite : </span>
        <span id="tr_3" class="t s7">01.07.2019 </span>
        <span id="ts_3" class="t s7">Datum </span>
        <span id="tt_3" class="t s3">PE </span>
        <span id="tu_3" class="t s9"
          >Sly GmbH • Binzmühlestrasse 99 • 8050 Zürich
        </span>
        <span id="tv_3" class="t sa">I</span
        ><span id="tw_3" class="t sb">hr Ansprechpartner / Durchwahl </span>
        <span id="tx_3" class="t sb">Sly / 044 552 72 72 </span>
        <span id="ty_3" class="t sb">E-Mail: info@sly.ch </span>
        <span id="tz_3" class="t sb">#OCR-BE# 306371-1</span
        ><span id="t10_3" class="t sa">1 </span>
        <span id="t11_3" class="t sa">08-04-273615 </span>
        <span id="t12_3" class="t s2">PU-Zahnriemen/Meterware </span>
        <span id="t13_3" class="t sc">7 </span>
        <span id="t14_3" class="t sc">8000259133 </span>
        <span id="t15_3" class="t sa">Abmessung 75 AT 10 </span>
        <span id="t16_3" class="t sa">Stahlzugträger </span>
        <span id="t17_3" class="t sd">50,00 </span
        ><span id="t18_3" class="t sa">Meter </span
        ><span id="t19_3" class="t sd">832,50 </span
        ><span id="t1a_3" class="t sd">16,65 </span>
        <span id="t1b_3" class="t sc">Ihre Artikel-Nr. </span>
        <span id="t1c_3" class="t sa">1 </span>
        <span id="t1d_3" class="t sc">8 </span>
        <span id="t1e_3" class="t sd">100,00 </span
        ><span id="t1f_3" class="t sa">Stück </span
        ><span id="t1g_3" class="t sd">550,00 </span
        ><span id="t1h_3" class="t sd">5,50 </span
        ><span id="t1i_3" class="t sa">1 </span>
        <span id="t1j_3" class="t s8">Lieferdatum eintreffend: </span
        ><span id="t1k_3" class="t s8">17.09.2019 </span>
        <span id="t1l_3" class="t sa">08-04-271132 </span>
        <span id="t1m_3" class="t s2">Zahnriemen Synchroflex </span>
        <span id="t1n_3" class="t sa">16 AT 5 - 420 </span>
        <span id="t1o_3" class="t sc">Ihre Artikel-Nr. </span
        ><span id="t1p_3" class="t sc">0918316989 </span>
        <span id="t1q_3" class="t s8">Lieferdatum eintreffend: </span
        ><span id="t1r_3" class="t s8">17.09.2019 </span>
        <span id="t1s_3" class="t s3"
          >Grundlage der Bestellung ist die Zusicherung des
        </span>
        <span id="t1t_3" class="t s3"
          >Lieferanten, dass die von ihm gelieferten Materialien unter
        </span>
        <span id="t1u_3" class="t s3"
          >REACH vorregistriert bzw. registriert sind.
        </span>
        <span id="t1v_3" class="t s2">DIE ANGEGEBENEN TERMINE VERSTEHEN </span>
        <span id="t1w_3" class="t s2"
          >SICH EINTREFFEND! Bitte disponieren
        </span>
        <span id="t1x_3" class="t s2">Sie Ihren Versand rechtzeitig! </span>
        <span id="t1y_3" class="t s2"
          >Die Verpackung muss streng neutral (Klebe-
        </span>
        <span id="t1z_3" class="t s2">bänder/Etiketten etc.) erfolgen. </span>
        <span id="t20_3" class="t s3">Bestellwert </span>
        <span id="t21_3" class="t s3">Netto </span>
        <span id="t22_3" class="t s3">MwSt </span>
        <span id="t23_3" class="t s3">EUR </span>
        <span id="t24_3" class="t s3">EUR </span>
        <span id="t25_3" class="t s3">EUR </span>
        <span id="t26_3" class="t s3">Zahlungsbedingungen </span>
        <span id="t27_3" class="t s3">Versandart </span>
        <span id="t28_3" class="t s3">Versandbedingungen </span>
        <span id="t29_3" class="t s3">DHL </span>
        <span id="t2a_3" class="t s3">frei Haus </span>
        <span id="t2b_3" class="t s3">30 Tage 2 %, 60 Tage netto </span>
        <span id="t2c_3" class="t s3">19,00% </span
        ><span id="t2d_3" class="t s3">710,26 </span>
        <span id="t2e_3" class="t s3">3738,20 </span>
        <span id="t2f_3" class="t s3">4448,46 </span>
        <span id="t2g_3" class="t se"
          >Es gelten grundsätzlich unsere allgemeinen Geschäftsbedingungen.
          Anderslautende Bedingungen werden nicht anerkannt.
        </span>
        <span id="t2h_3" class="t s3"
          >(PE 1= pro1, 2= pro10, 3= pro100, 4= pro1.000)
        </span>
      </div>
      <!-- End text definitions -->
    </div>
  </body>
</html>
`,
  BE_310139: `
  <!DOCTYPE html>
  <html lang="en">
  <head>
  <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
  <meta charset="utf-8" />
  </head>
  
  <body style="margin: 0;">
  
  <div id="p1" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">
  
  <!-- Begin shared CSS values -->
  <style class="shared-css" type="text/css" >
  .t {
    transform-origin: bottom left;
    z-index: 2;
    position: absolute;
    white-space: pre;
    overflow: visible;
    line-height: 1.5;
  }
  .text-container {
    white-space: pre;
  }
  @supports (-webkit-touch-callout: none) {
    .text-container {
      white-space: normal;
    }
  }
  </style>
  <!-- End shared CSS values -->
  
  
  <!-- Begin inline CSS -->
  <style type="text/css" >
  
  #t1_1{left:32px;bottom:1142px;letter-spacing:0.09px;word-spacing:-0.01px;}
  #t2_1{left:118px;bottom:1142px;letter-spacing:0.1px;word-spacing:-0.01px;}
  #t3_1{left:109px;bottom:782px;letter-spacing:0.17px;}
  #t4_1{left:114px;bottom:744px;letter-spacing:0.11px;}
  #t5_1{left:147px;bottom:744px;letter-spacing:0.11px;}
  #t6_1{left:518px;bottom:744px;letter-spacing:0.13px;}
  #t7_1{left:588px;bottom:744px;letter-spacing:0.1px;}
  #t8_1{left:734px;bottom:744px;}
  #t9_1{left:781px;bottom:744px;letter-spacing:0.13px;}
  #ta_1{left:661px;bottom:744px;letter-spacing:0.1px;}
  #tb_1{left:147px;bottom:725px;letter-spacing:0.12px;}
  #tc_1{left:669px;bottom:725px;letter-spacing:0.16px;}
  #td_1{left:788px;bottom:725px;letter-spacing:0.16px;}
  #te_1{left:583px;bottom:961px;letter-spacing:0.15px;word-spacing:-0.01px;}
  #tf_1{left:245px;bottom:782px;letter-spacing:0.16px;word-spacing:-0.01px;}
  #tg_1{left:245px;bottom:762px;letter-spacing:0.13px;word-spacing:-0.01px;}
  #th_1{left:385px;bottom:762px;letter-spacing:0.13px;word-spacing:-0.01px;}
  #ti_1{left:451px;bottom:762px;letter-spacing:0.13px;}
  #tj_1{left:109px;bottom:964px;letter-spacing:0.14px;word-spacing:-0.01px;}
  #tk_1{left:109px;bottom:931px;letter-spacing:0.13px;}
  #tl_1{left:143px;bottom:931px;letter-spacing:0.13px;}
  #tm_1{left:198px;bottom:931px;letter-spacing:0.13px;}
  #tn_1{left:109px;bottom:983px;letter-spacing:0.17px;word-spacing:-0.01px;}
  #to_1{left:586px;bottom:892px;letter-spacing:0.15px;}
  #tp_1{left:676px;bottom:893px;letter-spacing:0.12px;}
  #tq_1{left:589px;bottom:869px;letter-spacing:0.11px;}
  #tr_1{left:677px;bottom:869px;letter-spacing:0.11px;word-spacing:-0.01px;}
  #ts_1{left:588px;bottom:923px;letter-spacing:-0.14px;}
  #tt_1{left:620px;bottom:809px;}
  #tu_1{left:582px;bottom:809px;letter-spacing:0.09px;word-spacing:-0.01px;}
  #tv_1{left:789px;bottom:895px;letter-spacing:0.11px;}
  #tw_1{left:790px;bottom:869px;letter-spacing:0.13px;}
  #tx_1{left:629px;bottom:744px;letter-spacing:0.15px;}
  #ty_1{left:582px;bottom:1043px;letter-spacing:0.13px;word-spacing:-0.01px;}
  #tz_1{left:582px;bottom:1024px;letter-spacing:0.12px;word-spacing:-0.01px;}
  #t10_1{left:109px;bottom:1031px;letter-spacing:0.11px;word-spacing:-0.01px;}
  #t11_1{left:582px;bottom:1003px;letter-spacing:0.13px;}
  #t12_1{left:582px;bottom:985px;letter-spacing:0.14px;}
  #t13_1{left:143px;bottom:699px;letter-spacing:0.14px;}
  #t14_1{left:143px;bottom:679px;letter-spacing:0.14px;word-spacing:-0.01px;}
  #t15_1{left:110px;bottom:701px;}
  #t16_1{left:237px;bottom:533px;letter-spacing:-0.14px;}
  #t17_1{left:143px;bottom:659px;letter-spacing:0.12px;word-spacing:-0.01px;}
  #t18_1{left:143px;bottom:642px;letter-spacing:0.13px;word-spacing:-0.01px;}
  #t19_1{left:143px;bottom:624px;letter-spacing:0.13px;word-spacing:-0.01px;}
  #t1a_1{left:143px;bottom:607px;letter-spacing:0.12px;word-spacing:-0.01px;}
  #t1b_1{left:143px;bottom:589px;letter-spacing:0.13px;word-spacing:-0.01px;}
  #t1c_1{left:143px;bottom:572px;letter-spacing:0.13px;word-spacing:-0.01px;}
  #t1d_1{left:143px;bottom:554px;letter-spacing:0.12px;word-spacing:-0.01px;}
  #t1e_1{left:531px;bottom:698px;letter-spacing:-0.1px;}
  #t1f_1{left:586px;bottom:699px;letter-spacing:0.14px;}
  #t1g_1{left:794px;bottom:698px;letter-spacing:-0.1px;}
  #t1h_1{left:685px;bottom:698px;letter-spacing:-0.1px;}
  #t1i_1{left:143px;bottom:533px;letter-spacing:-0.1px;word-spacing:-0.01px;}
  #t1j_1{left:633px;bottom:699px;}
  #t1k_1{left:143px;bottom:509px;letter-spacing:-0.1px;word-spacing:-0.01px;}
  #t1l_1{left:354px;bottom:509px;letter-spacing:-0.1px;}
  #t1m_1{left:145px;bottom:479px;letter-spacing:0.13px;word-spacing:-0.01px;}
  #t1n_1{left:145px;bottom:461px;letter-spacing:0.12px;word-spacing:-0.01px;}
  #t1o_1{left:145px;bottom:443px;letter-spacing:0.13px;word-spacing:-0.01px;}
  #t1p_1{left:146px;bottom:417px;letter-spacing:0.1px;word-spacing:-0.01px;}
  #t1q_1{left:146px;bottom:403px;letter-spacing:0.1px;word-spacing:-0.01px;}
  #t1r_1{left:146px;bottom:389px;letter-spacing:0.09px;word-spacing:-0.01px;}
  #t1s_1{left:146px;bottom:362px;letter-spacing:0.17px;word-spacing:-0.01px;}
  #t1t_1{left:146px;bottom:344px;letter-spacing:0.14px;word-spacing:-0.01px;}
  #t1u_1{left:146px;bottom:326px;letter-spacing:0.12px;word-spacing:-0.01px;}
  #t1v_1{left:146px;bottom:305px;letter-spacing:0.13px;word-spacing:-0.01px;}
  #t1w_1{left:146px;bottom:287px;letter-spacing:0.12px;word-spacing:-0.01px;}
  #t1x_1{left:588px;bottom:153px;letter-spacing:0.1px;}
  #t1y_1{left:588px;bottom:196px;letter-spacing:0.1px;}
  #t1z_1{left:588px;bottom:175px;letter-spacing:0.13px;}
  #t20_1{left:725px;bottom:154px;letter-spacing:0.16px;}
  #t21_1{left:725px;bottom:176px;letter-spacing:0.16px;}
  #t22_1{left:725px;bottom:198px;letter-spacing:0.16px;}
  #t23_1{left:115px;bottom:153px;letter-spacing:0.11px;}
  #t24_1{left:115px;bottom:196px;letter-spacing:0.11px;}
  #t25_1{left:115px;bottom:175px;letter-spacing:0.12px;}
  #t26_1{left:256px;bottom:198px;letter-spacing:0.15px;}
  #t27_1{left:256px;bottom:176px;letter-spacing:0.1px;word-spacing:-0.01px;}
  #t28_1{left:256px;bottom:154px;letter-spacing:0.1px;word-spacing:-0.01px;}
  #t29_1{left:670px;bottom:176px;letter-spacing:0.12px;}
  #t2a_1{left:814px;bottom:176px;letter-spacing:0.11px;}
  #t2b_1{left:807px;bottom:198px;letter-spacing:0.11px;}
  #t2c_1{left:808px;bottom:154px;letter-spacing:0.11px;}
  #t2d_1{left:127px;bottom:37px;letter-spacing:0.1px;word-spacing:-0.01px;}
  #t2e_1{left:109px;bottom:135px;letter-spacing:0.1px;word-spacing:-0.01px;}
  #t2f_1{left:87px;bottom:108px;letter-spacing:0.11px;}
  #t2g_1{left:87px;bottom:94px;letter-spacing:0.11px;word-spacing:-0.01px;}
  #t2h_1{left:208px;bottom:108px;letter-spacing:0.1px;}
  #t2i_1{left:208px;bottom:94px;letter-spacing:0.12px;word-spacing:-0.01px;}
  #t2j_1{left:208px;bottom:80px;letter-spacing:0.1px;word-spacing:-0.01px;}
  #t2k_1{left:208px;bottom:67px;letter-spacing:0.1px;word-spacing:-0.01px;}
  #t2l_1{left:339px;bottom:108px;letter-spacing:0.1px;}
  #t2m_1{left:339px;bottom:94px;letter-spacing:0.12px;word-spacing:-0.01px;}
  #t2n_1{left:339px;bottom:80px;letter-spacing:0.1px;word-spacing:-0.01px;}
  #t2o_1{left:339px;bottom:67px;letter-spacing:0.11px;word-spacing:-0.01px;}
  #t2p_1{left:496px;bottom:108px;letter-spacing:0.11px;word-spacing:-0.01px;}
  #t2q_1{left:496px;bottom:94px;letter-spacing:0.1px;word-spacing:-0.01px;}
  #t2r_1{left:496px;bottom:80px;letter-spacing:0.12px;word-spacing:-0.01px;}
  #t2s_1{left:496px;bottom:67px;letter-spacing:0.13px;word-spacing:-0.01px;}
  #t2t_1{left:700px;bottom:108px;letter-spacing:0.1px;word-spacing:-0.01px;}
  #t2u_1{left:700px;bottom:94px;letter-spacing:0.1px;word-spacing:-0.01px;}
  #t2v_1{left:700px;bottom:80px;letter-spacing:0.1px;}
  #t2w_1{left:700px;bottom:67px;letter-spacing:0.1px;word-spacing:-0.01px;}
  #t2x_1{left:551px;bottom:135px;letter-spacing:0.12px;}
  
  .s1{font-size:9px;font-family:sub_Arial_lsr;color:#FFF;}
  .s2{font-size:15px;font-family:sub_Arial-Bold_lsb;color:#000;}
  .s3{font-size:12px;font-family:sub_Arial_lsr;color:#000;}
  .s4{font-size:15px;font-family:sub_Arial_lsr;color:#000;}
  .s5{font-size:12px;font-family:sub_Arial-Bold_lsb;color:#000;}
  .s6{font-size:17px;font-family:sub_Arial-Bold_lsb;color:#000;}
  .s7{font-size:12px;font-family:sub_Arial_lsr;color:#000080;}
  .s8{font-size:14px;font-family:sub_Arial_lsr;color:#000;}
  .s9{font-size:17px;font-family:sub_Arial_lsr;color:#000;}
  </style>
  <!-- End inline CSS -->
  
  <!-- Begin embedded font definitions -->
  <style id="fonts1" type="text/css" >
  
  @font-face {
    font-family: sub_Arial-Bold_lsb;
    src: url("fonts/sub_Arial-Bold_lsb.woff") format("woff");
  }
  
  @font-face {
    font-family: sub_Arial_lsr;
    src: url("fonts/sub_Arial_lsr.woff") format("woff");
  }
  
  </style>
  <!-- End embedded font definitions -->
  
  <!-- Begin page background -->
  <div id="pg1Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
  <div id="pg1" style="-webkit-user-select: none;"><object width="909" height="1286" data="1/1.svg" type="image/svg+xml" id="pdf1" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
  <!-- End page background -->
  
  
  <!-- Begin text definitions (Positioned/styled in CSS) -->
  <div class="text-container"><span id="t1_1" class="t s1">@@BETREFF BE / </span><span id="t2_1" class="t s1">APSOPARTS GMBH / 310139@@ </span>
  <span id="t3_1" class="t s2">LIEFERADRESSE: </span>
  <span id="t4_1" class="t s3">Pos. </span><span id="t5_1" class="t s3">Artikel-Nummer </span><span id="t6_1" class="t s3">Menge </span><span id="t7_1" class="t s3">Einheit </span><span id="t8_1" class="t s3">% </span><span id="t9_1" class="t s3">Gesamt </span><span id="ta_1" class="t s3">E-Preis </span>
  <span id="tb_1" class="t s3">Bezeichnung </span><span id="tc_1" class="t s3">EUR </span><span id="td_1" class="t s3">EUR </span>
  <span id="te_1" class="t s4">#OCR-BE# 310139-1 </span>
  <span id="tf_1" class="t s2">TECMAT Handels GmbH </span>
  <span id="tg_1" class="t s2">Hauptstrasse 56 </span><span id="th_1" class="t s2">D 88138 </span><span id="ti_1" class="t s2">Sigmarszell-Schlachters </span>
  <span id="tj_1" class="t s2">Siemensstrasse 5 </span>
  <span id="tk_1" class="t s2">DE </span><span id="tl_1" class="t s2">70736 </span><span id="tm_1" class="t s2">Fellbach </span>
  <span id="tn_1" class="t s2">APSOparts GmbH </span>
  <span id="to_1" class="t s2">310139 </span><span id="tp_1" class="t s5">751850 </span>
  <span id="tq_1" class="t s5">Beleg-Nr. </span><span id="tr_1" class="t s5">Unsere Kd-Nr. </span>
  <span id="ts_1" class="t s6">BESTELLUNG </span>
  <span id="tt_1" class="t s3">1 </span><span id="tu_1" class="t s3">Seite : </span>
  <span id="tv_1" class="t s5">24.01.2020 </span>
  <span id="tw_1" class="t s5">Datum </span>
  <span id="tx_1" class="t s3">PE </span>
  <span id="ty_1" class="t s4">Ihr Ansprechpartner / Durchwahl </span>
  <span id="tz_1" class="t s4">Michael Dhein / (0 83 89) 9 29 49 - 13 </span>
  <span id="t10_1" class="t s7">TECMAT Handels-GmbH • Hauptstraße 56 • 88138 Sigmarszell/Schlachters </span>
  <span id="t11_1" class="t s4">E-Mail </span>
  <span id="t12_1" class="t s4">m.dhein@tecmat.de </span>
  <span id="t13_1" class="t s4">10-01-PC-401.00132 </span>
  <span id="t14_1" class="t s2">Montagefuß M10 x 25 </span>
  <span id="t15_1" class="t s8">1 </span>
  <span id="t16_1" class="t s8">1220330303 </span>
  <span id="t17_1" class="t s4">Werkstoff Elastomerteil:NR, schwarz </span>
  <span id="t18_1" class="t s4">Werkstoff Anschlussteil:S235JR </span>
  <span id="t19_1" class="t s4">Oberflächenbehandlung:verzinkt, verchromt </span>
  <span id="t1a_1" class="t s4">(Chrom VI frei) </span>
  <span id="t1b_1" class="t s4">Ausführung:DIN 95363, Typ A mittel </span>
  <span id="t1c_1" class="t s4">Härte:ca. 55 Shore A </span>
  <span id="t1d_1" class="t s4">Einsatztemperatur:von -30 bis 80 °C </span>
  <span id="t1e_1" class="t s9">200,00 </span><span id="t1f_1" class="t s4">Stück </span><span id="t1g_1" class="t s9">204,00 </span><span id="t1h_1" class="t s9">1,02 </span>
  <span id="t1i_1" class="t s8">Ihre Artikel-Nr. </span>
  <span id="t1j_1" class="t s4">1 </span>
  <span id="t1k_1" class="t s6">Lieferdatum eintreffend: </span><span id="t1l_1" class="t s6">28.01.2020 </span>
  <span id="t1m_1" class="t s2">Wir erwarten innerhalb von 24 Stunden </span>
  <span id="t1n_1" class="t s2">Ihre Auftragsbestätigung an o. g. E-Mail mit </span>
  <span id="t1o_1" class="t s2">verbindlichem, eintreffenden Liefertermin. </span>
  <span id="t1p_1" class="t s3">Grundlage der Bestellung der TECMAT GmbH ist die </span>
  <span id="t1q_1" class="t s3">Zusicherung des Lieferanten, dass die von ihm gelieferten </span>
  <span id="t1r_1" class="t s3">Materialien unter REACH vorregistriert bzw. registriert sind. </span>
  <span id="t1s_1" class="t s2">DIE ANGEGEBENEN TERMINE VERSTEHEN </span>
  <span id="t1t_1" class="t s2">SICH EINTREFFEND! Bitte disponieren </span>
  <span id="t1u_1" class="t s2">Sie Ihren Versand rechtzeitig! </span>
  <span id="t1v_1" class="t s2">Die Verpackung muss streng neutral (Klebe- </span>
  <span id="t1w_1" class="t s2">bänder/Etiketten etc.) erfolgen. </span>
  <span id="t1x_1" class="t s3">Bestellwert </span>
  <span id="t1y_1" class="t s3">Netto </span>
  <span id="t1z_1" class="t s3">MwSt </span>
  <span id="t20_1" class="t s3">EUR </span>
  <span id="t21_1" class="t s3">EUR </span>
  <span id="t22_1" class="t s3">EUR </span>
  <span id="t23_1" class="t s3">Zahlungsbedingungen </span>
  <span id="t24_1" class="t s3">Versandart </span>
  <span id="t25_1" class="t s3">Versandbedingungen </span>
  <span id="t26_1" class="t s3">DHL </span>
  <span id="t27_1" class="t s3">frei Haus </span>
  <span id="t28_1" class="t s3">30 Tage 2 %, 60 Tage netto </span>
  <span id="t29_1" class="t s3">19,00% </span><span id="t2a_1" class="t s3">38,76 </span>
  <span id="t2b_1" class="t s3">204,00 </span>
  <span id="t2c_1" class="t s3">242,76 </span>
  <span id="t2d_1" class="t s7">Es gelten grundsätzlich unsere allgemeinen Geschäftsbedingungen. Anderslautende Bedingungen werden nicht anerkannt. </span>
  <span id="t2e_1" class="t s3">(PE 1= pro1, 2= pro10, 3= pro100, 4= pro1.000) </span>
  <span id="t2f_1" class="t s7">Geschäftsführung </span>
  <span id="t2g_1" class="t s7">Hermann Zöller </span>
  <span id="t2h_1" class="t s7">Handelsregister </span>
  <span id="t2i_1" class="t s7">Kempten HRB 6382 </span>
  <span id="t2j_1" class="t s7">Erfüllungsort und </span>
  <span id="t2k_1" class="t s7">Gerichtstand Lindau </span>
  <span id="t2l_1" class="t s7">Umsatzst.Ident.Nr. </span>
  <span id="t2m_1" class="t s7">DE 182358703 </span>
  <span id="t2n_1" class="t s7">Steuer.Nr. 127/140/10105 </span>
  <span id="t2o_1" class="t s7">Finanzamt Kempten </span>
  <span id="t2p_1" class="t s7">Bayerische Bodenseebank Lindau </span>
  <span id="t2q_1" class="t s7">Konto 44 58 43 • BLZ 733 698 21 </span>
  <span id="t2r_1" class="t s7">IBAN: DE48733698210000445843 </span>
  <span id="t2s_1" class="t s7">SWIFT (BIC) GENODEF1LBB </span>
  <span id="t2t_1" class="t s7">Tel. 0049 (0)8389 / 92 94 9-0 </span>
  <span id="t2u_1" class="t s7">Fax 0049 (0)8389 / 92 94 949 </span>
  <span id="t2v_1" class="t s7">Internet: www.tecmat.de </span>
  <span id="t2w_1" class="t s7">e-mail: info@tecmat.de </span>
  <span id="t2x_1" class="t s3">82199 </span></div>
  <!-- End text definitions -->
  
  
  </div>
  </body>
  </html>
    
  `,
  Bestellung_944456_20220204: `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
      <meta charset="utf-8" />
    </head>
  
    <body style="margin: 0">
      <div
        id="p1"
        style="
          overflow: hidden;
          position: relative;
          background-color: white;
          width: 909px;
          height: 1284px;
        "
      >
        <!-- Begin shared CSS values -->
        <style class="shared-css" type="text/css">
          .t {
            -webkit-transform-origin: bottom left;
            -ms-transform-origin: bottom left;
            transform-origin: bottom left;
            -webkit-transform: scale(0.25);
            -ms-transform: scale(0.25);
            transform: scale(0.25);
            z-index: 2;
            position: absolute;
            white-space: pre;
            overflow: visible;
            line-height: 1.5;
          }
        </style>
        <!-- End shared CSS values -->
  
        <!-- Begin inline CSS -->
        <style type="text/css">
          #t1_1 {
            left: 79px;
            bottom: 1078px;
          }
          #t2_1 {
            left: 79px;
            bottom: 1046px;
          }
          #t3_1 {
            left: 79px;
            bottom: 1028px;
          }
          #t4_1 {
            left: 79px;
            bottom: 1010px;
          }
          #t5_1 {
            left: 512px;
            bottom: 1046px;
          }
          #t6_1 {
            left: 512px;
            bottom: 1028px;
          }
          #t7_1 {
            left: 512px;
            bottom: 1010px;
          }
          #t8_1 {
            left: 512px;
            bottom: 1078px;
          }
          #t9_1 {
            left: 79px;
            bottom: 874px;
          }
          #ta_1 {
            left: 79px;
            bottom: 834px;
          }
          #tb_1 {
            left: 79px;
            bottom: 817px;
          }
          #tc_1 {
            left: 225px;
            bottom: 817px;
          }
          #td_1 {
            left: 79px;
            bottom: 801px;
          }
          #te_1 {
            left: 225px;
            bottom: 801px;
          }
          #tf_1 {
            left: 79px;
            bottom: 784px;
          }
          #tg_1 {
            left: 79px;
            bottom: 767px;
          }
          #th_1 {
            left: 79px;
            bottom: 750px;
          }
          #ti_1 {
            left: 478px;
            bottom: 834px;
          }
          #tj_1 {
            left: 625px;
            bottom: 834px;
          }
          #tk_1 {
            left: 478px;
            bottom: 817px;
          }
          #tl_1 {
            left: 625px;
            bottom: 817px;
          }
          #tm_1 {
            left: 478px;
            bottom: 801px;
          }
          #tn_1 {
            left: 625px;
            bottom: 801px;
          }
          #to_1 {
            left: 478px;
            bottom: 784px;
          }
          #tp_1 {
            left: 625px;
            bottom: 784px;
          }
          #tq_1 {
            left: 478px;
            bottom: 767px;
          }
          #tr_1 {
            left: 625px;
            bottom: 767px;
          }
          #ts_1 {
            left: 478px;
            bottom: 750px;
          }
          #tt_1 {
            left: 625px;
            bottom: 750px;
          }
          #tu_1 {
            left: 625px;
            bottom: 733px;
          }
          #tv_1 {
            left: 478px;
            bottom: 733px;
          }
          #tw_1 {
            left: 225px;
            bottom: 834px;
          }
          #tx_1 {
            left: 625px;
            bottom: 883px;
          }
          #ty_1 {
            left: 700px;
            bottom: 883px;
            word-spacing: -1.5px;
          }
          #tz_1 {
            left: 79px;
            bottom: 603px;
          }
          #t10_1 {
            left: 150px;
            bottom: 603px;
          }
          #t11_1 {
            left: 462px;
            bottom: 603px;
          }
          #t12_1 {
            left: 577px;
            bottom: 603px;
          }
          #t13_1 {
            left: 630px;
            bottom: 580px;
          }
          #t14_1 {
            left: 698px;
            bottom: 603px;
          }
          #t15_1 {
            left: 756px;
            bottom: 603px;
          }
          #t16_1 {
            left: 823px;
            bottom: 580px;
          }
          #t17_1 {
            left: 79px;
            bottom: 543px;
          }
          #t18_1 {
            left: 150px;
            bottom: 543px;
          }
          #t19_1 {
            left: 509px;
            bottom: 543px;
          }
          #t1a_1 {
            left: 806px;
            bottom: 543px;
          }
          #t1b_1 {
            left: 631px;
            bottom: 543px;
          }
          #t1c_1 {
            left: 465px;
            bottom: 543px;
          }
          #t1d_1 {
            left: 150px;
            bottom: 525px;
          }
          #t1e_1 {
            left: 150px;
            bottom: 507px;
          }
          #t1f_1 {
            left: 150px;
            bottom: 490px;
          }
          #t1g_1 {
            left: 150px;
            bottom: 472px;
          }
          #t1h_1 {
            left: 150px;
            bottom: 453px;
          }
          #t1i_1 {
            left: 428px;
            bottom: 453px;
          }
          #t1j_1 {
            left: 150px;
            bottom: 433px;
          }
          #t1k_1 {
            left: 150px;
            bottom: 415px;
          }
          #t1l_1 {
            left: 295px;
            bottom: 415px;
          }
          #t1m_1 {
            left: 79px;
            bottom: 378px;
          }
          #t1n_1 {
            left: 150px;
            bottom: 378px;
          }
          #t1o_1 {
            left: 509px;
            bottom: 378px;
          }
          #t1p_1 {
            left: 823px;
            bottom: 378px;
          }
          #t1q_1 {
            left: 631px;
            bottom: 378px;
          }
          #t1r_1 {
            left: 487px;
            bottom: 378px;
          }
          #t1s_1 {
            left: 150px;
            bottom: 360px;
          }
          #t1t_1 {
            left: 150px;
            bottom: 342px;
          }
          #t1u_1 {
            left: 150px;
            bottom: 325px;
          }
          #t1v_1 {
            left: 150px;
            bottom: 307px;
          }
          #t1w_1 {
            left: 428px;
            bottom: 307px;
          }
          #t1x_1 {
            left: 150px;
            bottom: 287px;
          }
          #t1y_1 {
            left: 295px;
            bottom: 287px;
          }
          #t1z_1 {
            left: 79px;
            bottom: 250px;
          }
          #t20_1 {
            left: 150px;
            bottom: 250px;
          }
          #t21_1 {
            left: 509px;
            bottom: 250px;
          }
          #t22_1 {
            left: 823px;
            bottom: 250px;
          }
          #t23_1 {
            left: 622px;
            bottom: 250px;
          }
          #t24_1 {
            left: 495px;
            bottom: 250px;
          }
          #t25_1 {
            left: 564px;
            bottom: 85px;
          }
          #t26_1 {
            left: 564px;
            bottom: 146px;
          }
          #t27_1 {
            left: 564px;
            bottom: 162px;
          }
          #t28_1 {
            left: 564px;
            bottom: 131px;
          }
          #t29_1 {
            left: 79px;
            bottom: 70px;
          }
          #t2a_1 {
            left: 79px;
            bottom: 101px;
          }
          #t2b_1 {
            left: 79px;
            bottom: 116px;
          }
          #t2c_1 {
            left: 79px;
            bottom: 146px;
          }
          #t2d_1 {
            left: 79px;
            bottom: 131px;
          }
          #t2e_1 {
            left: 79px;
            bottom: 162px;
          }
          #t2f_1 {
            left: 241px;
            bottom: 162px;
          }
          #t2g_1 {
            left: 564px;
            bottom: 101px;
          }
          #t2h_1 {
            left: 241px;
            bottom: 146px;
          }
          #t2i_1 {
            left: 241px;
            bottom: 131px;
          }
          #t2j_1 {
            left: 241px;
            bottom: 116px;
          }
          #t2k_1 {
            left: 241px;
            bottom: 70px;
          }
          #t2l_1 {
            left: 241px;
            bottom: 85px;
          }
          #t2m_1 {
            left: 761px;
            bottom: 77px;
          }
          #t2n_1 {
            left: 403px;
            bottom: 162px;
          }
          #t2o_1 {
            left: 403px;
            bottom: 146px;
          }
          #t2p_1 {
            left: 403px;
            bottom: 131px;
          }
          #t2q_1 {
            left: 403px;
            bottom: 116px;
          }
          #t2r_1 {
            left: 79px;
            bottom: 85px;
          }
          #t2s_1 {
            left: 103px;
            bottom: 85px;
          }
          #t2t_1 {
            left: 241px;
            bottom: 101px;
          }
          #t2u_1 {
            left: 403px;
            bottom: 101px;
          }
  
          .s1_1 {
            font-size: 42.8px;
            font-family: DejaVuSansCondensed-Oblique_2p;
            color: rgb(0, 63, 133);
          }
  
          .s2_1 {
            font-size: 61.1px;
            font-family: DejaVuSansCondensed_2l;
            color: rgb(0, 0, 0);
          }
  
          .s3_1 {
            font-size: 85.6px;
            font-family: DejaVuSansCondensed-Bold_2t;
            color: rgb(0, 63, 133);
          }
  
          .s4_1 {
            font-size: 55px;
            font-family: DejaVuSansCondensed_2l;
            color: rgb(0, 0, 0);
          }
  
          .s5_1 {
            font-size: 55px;
            font-family: DejaVuSansCondensed-Bold_2t;
            color: rgb(0, 0, 0);
          }
  
          .s6_1 {
            font-size: 61.1px;
            font-family: DejaVuSansCondensed-Bold_2t;
            color: rgb(0, 63, 133);
          }
  
          .s7_1 {
            font-size: 42.8px;
            font-family: DejaVuSansCondensed_2l;
            color: rgb(0, 0, 0);
          }
  
          .s8_1 {
            font-size: 42.8px;
            font-family: DejaVuSansCondensed-Bold_2t;
            color: rgb(0, 0, 0);
          }
        </style>
        <!-- End inline CSS -->
  
        <!-- Begin embedded font definitions -->
        <style id="fonts1" type="text/css">
          @font-face {
            font-family: DejaVuSansCondensed-Bold_2t;
            src: url("fonts/DejaVuSansCondensed-Bold_2t.woff") format("woff");
          }
  
          @font-face {
            font-family: DejaVuSansCondensed-Oblique_2p;
            src: url("fonts/DejaVuSansCondensed-Oblique_2p.woff") format("woff");
          }
  
          @font-face {
            font-family: DejaVuSansCondensed_2l;
            src: url("fonts/DejaVuSansCondensed_2l.woff") format("woff");
          }
        </style>
        <!-- End embedded font definitions -->
  
        <!-- Begin page background -->
        <div
          id="pg1Overlay"
          style="
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0);
            -webkit-user-select: none;
          "
        ></div>
        <div id="pg1" style="-webkit-user-select: none">
          <object
            width="909"
            height="1284"
            data="1/1.svg"
            type="image/svg+xml"
            id="pdf1"
            style="
              width: 909px;
              height: 1284px;
              -moz-transform: scale(1);
              z-index: 0;
            "
          ></object>
        </div>
        <!-- End page background -->
  
        <!-- Begin text definitions (Positioned/styled in CSS) -->
        <div id="t1_1" class="t s1_1">
          RENA Technologies GmbH • Höhenweg 1 • 78148 Gütenbach
        </div>
        <div id="t2_1" class="t s2_1">APSOparts GmbH</div>
        <div id="t3_1" class="t s2_1">Schulze-Delitzsch-Straße 38</div>
        <div id="t4_1" class="t s2_1">70565 Stuttgart</div>
        <div id="t5_1" class="t s2_1">RENA Technologies GmbH</div>
        <div id="t6_1" class="t s2_1">Am Fohrenwald 1</div>
        <div id="t7_1" class="t s2_1">78087 Mönchweiler</div>
        <div id="t8_1" class="t s1_1">Lieferanschrift/ Lieferadresse</div>
        <div id="t9_1" class="t s3_1">Bestellung</div>
        <div id="ta_1" class="t s4_1">Nr</div>
        <div id="tb_1" class="t s4_1">vom</div>
        <div id="tc_1" class="t s4_1">04.02.2022</div>
        <div id="td_1" class="t s4_1">Lieferant</div>
        <div id="te_1" class="t s4_1">79615</div>
        <div id="tf_1" class="t s4_1">Ihr Zeichen</div>
        <div id="tg_1" class="t s4_1">Ihre Nachricht</div>
        <div id="th_1" class="t s4_1">Ihre USt-Id-Nr</div>
        <div id="ti_1" class="t s4_1">Unser Zeichen</div>
        <div id="tj_1" class="t s4_1">Monika Gallus</div>
        <div id="tk_1" class="t s4_1">Telefon</div>
        <div id="tl_1" class="t s4_1">+49 7723 9313-822</div>
        <div id="tm_1" class="t s4_1">Fax</div>
        <div id="tn_1" class="t s4_1">+49 7723 9313-50</div>
        <div id="to_1" class="t s4_1">E-Mail</div>
        <div id="tp_1" class="t s4_1">monika.gallus@rena.com</div>
        <div id="tq_1" class="t s4_1">Unsere USt-Id-Nr</div>
        <div id="tr_1" class="t s4_1">DE298543967</div>
        <div id="ts_1" class="t s4_1">Unsere Steuer-Nr</div>
        <div id="tt_1" class="t s4_1">22107/05127</div>
        <div id="tu_1" class="t s4_1">+49 1757303365</div>
        <div id="tv_1" class="t s4_1">Telefon Lieferant</div>
        <div id="tw_1" class="t s5_1">944456</div>
        <div id="tx_1" class="t s2_1">Seite</div>
        <div id="ty_1" class="t s2_1">1 / 11</div>
        <div id="tz_1" class="t s6_1">Pos</div>
        <div id="t10_1" class="t s6_1">Artikel</div>
        <div id="t11_1" class="t s6_1">Menge</div>
        <div id="t12_1" class="t s6_1">Einzelpreis</div>
        <div id="t13_1" class="t s6_1">EUR</div>
        <div id="t14_1" class="t s6_1">%</div>
        <div id="t15_1" class="t s6_1">Gesamtpreis</div>
        <div id="t16_1" class="t s6_1">EUR</div>
        <div id="t17_1" class="t s2_1">01</div>
        <div id="t18_1" class="t s2_1">2014759a</div>
        <div id="t19_1" class="t s2_1">Stück</div>
        <div id="t1a_1" class="t s2_1">101,40</div>
        <div id="t1b_1" class="t s2_1">5,07</div>
        <div id="t1c_1" class="t s2_1">2.000</div>
        <div id="t1d_1" class="t s2_1">O-Ring ø 32,92 x 3,53 EPDM Hitec-Präzision 75.5/KW76F</div>
        <div id="t1e_1" class="t s2_1">Index: a</div>
        <div id="t1f_1" class="t s2_1">Preis pro PE (1 PE = 100 Stück)</div>
        <div id="t1g_1" class="t s2_1">Werkstoff: EPDM</div>
        <div id="t1h_1" class="t s2_1">Ihre Referenz/Artikelnummer:</div>
        <div id="t1i_1" class="t s2_1">1140203520</div>
        <div id="t1j_1" class="t s2_1">Zeichnung -</div>
        <div id="t1k_1" class="t s2_1">Liefertermin:</div>
        <div id="t1l_1" class="t s2_1">11.02.2022</div>
        <div id="t1m_1" class="t s2_1">02</div>
        <div id="t1n_1" class="t s2_1">2424915</div>
        <div id="t1o_1" class="t s2_1">m</div>
        <div id="t1p_1" class="t s2_1">5,50</div>
        <div id="t1q_1" class="t s2_1">0,55</div>
        <div id="t1r_1" class="t s2_1">10</div>
        <div id="t1s_1" class="t s2_1">Rundschnur ø 6 mm Nr. 10 2611 0606 FDA</div>
        <div id="t1t_1" class="t s2_1">Hersteller Art.Nr.: 10.2611.0606</div>
        <div id="t1u_1" class="t s2_1">Werkstoff: EPDM</div>
        <div id="t1v_1" class="t s2_1">Ihre Referenz/Artikelnummer:</div>
        <div id="t1w_1" class="t s2_1">1026110606</div>
        <div id="t1x_1" class="t s2_1">Liefertermin:</div>
        <div id="t1y_1" class="t s2_1">11.02.2022</div>
        <div id="t1z_1" class="t s2_1">03</div>
        <div id="t20_1" class="t s2_1">22877</div>
        <div id="t21_1" class="t s2_1">Stück</div>
        <div id="t22_1" class="t s2_1">1,24</div>
        <div id="t23_1" class="t s2_1">41,43</div>
        <div id="t24_1" class="t s2_1">3</div>
        <div id="t25_1" class="t s7_1">UST Ident-Nr. DE298543967</div>
        <div id="t26_1" class="t s7_1">Peter Schneidewind</div>
        <div id="t27_1" class="t s8_1">Geschäftsführer</div>
        <div id="t28_1" class="t s7_1">Jürgen Ningelgen</div>
        <div id="t29_1" class="t s7_1">Fax: +49 7723 9313-50</div>
        <div id="t2a_1" class="t s7_1">Germany</div>
        <div id="t2b_1" class="t s7_1">D-78148 Gütenbach</div>
        <div id="t2c_1" class="t s7_1">Headquarters</div>
        <div id="t2d_1" class="t s7_1">Höhenweg 1</div>
        <div id="t2e_1" class="t s8_1">RENA Technologies GmbH</div>
        <div id="t2f_1" class="t s8_1">RENA Technologies GmbH</div>
        <div id="t2g_1" class="t s7_1">Amtsgericht Freiburg HRB 712734</div>
        <div id="t2h_1" class="t s7_1">Standort Berg</div>
        <div id="t2i_1" class="t s7_1">Am Röthenbühl 7</div>
        <div id="t2j_1" class="t s7_1">D-92348 Berg</div>
        <div id="t2k_1" class="t s7_1">Fax +49 9189 4404-50</div>
        <div id="t2l_1" class="t s7_1">Tel. +49 9189 4404-0</div>
        <div id="t2m_1" class="t s2_1">www.rena.com</div>
        <div id="t2n_1" class="t s8_1">RENA Technologies GmbH</div>
        <div id="t2o_1" class="t s7_1">Technologie Center Freiburg</div>
        <div id="t2p_1" class="t s7_1">Hans-Bunte-Straße 19</div>
        <div id="t2q_1" class="t s7_1">D-79108 Freiburg</div>
        <div id="t2r_1" class="t s7_1">Tel:</div>
        <div id="t2s_1" class="t s7_1">+49 7723 9313-0</div>
        <div id="t2t_1" class="t s7_1">Germany</div>
        <div id="t2u_1" class="t s7_1">Germany</div>
  
        <!-- End text definitions -->
      </div>
  
      <div
        id="p2"
        style="
          overflow: hidden;
          position: relative;
          background-color: white;
          width: 909px;
          height: 1284px;
        "
      >
        <!-- Begin shared CSS values -->
        <style class="shared-css" type="text/css">
          .t {
            -webkit-transform-origin: bottom left;
            -ms-transform-origin: bottom left;
            transform-origin: bottom left;
            -webkit-transform: scale(0.25);
            -ms-transform: scale(0.25);
            transform: scale(0.25);
            z-index: 2;
            position: absolute;
            white-space: pre;
            overflow: visible;
            line-height: 1.5;
          }
        </style>
        <!-- End shared CSS values -->
  
        <!-- Begin inline CSS -->
        <style type="text/css">
          #t1_2 {
            left: 411px;
            bottom: 1095px;
          }
          #t2_2 {
            left: 372px;
            bottom: 1095px;
          }
          #t3_2 {
            left: 610px;
            bottom: 1095px;
          }
          #t4_2 {
            left: 544px;
            bottom: 1095px;
          }
          #t5_2 {
            left: 79px;
            bottom: 1095px;
          }
          #t6_2 {
            left: 165px;
            bottom: 1095px;
          }
          #t7_2 {
            left: 705px;
            bottom: 1095px;
          }
          #t8_2 {
            left: 774px;
            bottom: 1095px;
            word-spacing: -1.5px;
          }
          #t9_2 {
            left: 79px;
            bottom: 1052px;
          }
          #ta_2 {
            left: 150px;
            bottom: 1052px;
          }
          #tb_2 {
            left: 462px;
            bottom: 1052px;
          }
          #tc_2 {
            left: 577px;
            bottom: 1052px;
          }
          #td_2 {
            left: 630px;
            bottom: 1029px;
          }
          #te_2 {
            left: 698px;
            bottom: 1052px;
          }
          #tf_2 {
            left: 756px;
            bottom: 1052px;
          }
          #tg_2 {
            left: 823px;
            bottom: 1029px;
          }
          #th_2 {
            left: 150px;
            bottom: 1002px;
          }
          #ti_2 {
            left: 150px;
            bottom: 983px;
          }
          #tj_2 {
            left: 150px;
            bottom: 965px;
          }
          #tk_2 {
            left: 150px;
            bottom: 947px;
          }
          #tl_2 {
            left: 428px;
            bottom: 947px;
          }
          #tm_2 {
            left: 150px;
            bottom: 927px;
          }
          #tn_2 {
            left: 150px;
            bottom: 909px;
          }
          #to_2 {
            left: 295px;
            bottom: 909px;
          }
          #tp_2 {
            left: 79px;
            bottom: 872px;
          }
          #tq_2 {
            left: 150px;
            bottom: 872px;
          }
          #tr_2 {
            left: 509px;
            bottom: 872px;
          }
          #ts_2 {
            left: 823px;
            bottom: 872px;
          }
          #tt_2 {
            left: 622px;
            bottom: 872px;
          }
          #tu_2 {
            left: 495px;
            bottom: 872px;
          }
          #tv_2 {
            left: 150px;
            bottom: 854px;
          }
          #tw_2 {
            left: 150px;
            bottom: 835px;
          }
          #tx_2 {
            left: 150px;
            bottom: 817px;
          }
          #ty_2 {
            left: 150px;
            bottom: 799px;
          }
          #tz_2 {
            left: 428px;
            bottom: 799px;
          }
          #t10_2 {
            left: 150px;
            bottom: 779px;
          }
          #t11_2 {
            left: 150px;
            bottom: 760px;
          }
          #t12_2 {
            left: 295px;
            bottom: 760px;
          }
          #t13_2 {
            left: 79px;
            bottom: 724px;
          }
          #t14_2 {
            left: 150px;
            bottom: 724px;
          }
          #t15_2 {
            left: 509px;
            bottom: 724px;
          }
          #t16_2 {
            left: 823px;
            bottom: 724px;
          }
          #t17_2 {
            left: 631px;
            bottom: 724px;
          }
          #t18_2 {
            left: 478px;
            bottom: 724px;
          }
          #t19_2 {
            left: 150px;
            bottom: 705px;
          }
          #t1a_2 {
            left: 150px;
            bottom: 687px;
          }
          #t1b_2 {
            left: 150px;
            bottom: 669px;
          }
          #t1c_2 {
            left: 150px;
            bottom: 650px;
          }
          #t1d_2 {
            left: 428px;
            bottom: 650px;
          }
          #t1e_2 {
            left: 150px;
            bottom: 630px;
          }
          #t1f_2 {
            left: 295px;
            bottom: 630px;
          }
          #t1g_2 {
            left: 79px;
            bottom: 594px;
          }
          #t1h_2 {
            left: 150px;
            bottom: 594px;
          }
          #t1i_2 {
            left: 509px;
            bottom: 594px;
          }
          #t1j_2 {
            left: 815px;
            bottom: 594px;
          }
          #t1k_2 {
            left: 622px;
            bottom: 594px;
          }
          #t1l_2 {
            left: 487px;
            bottom: 594px;
          }
          #t1m_2 {
            left: 150px;
            bottom: 575px;
          }
          #t1n_2 {
            left: 150px;
            bottom: 557px;
          }
          #t1o_2 {
            left: 150px;
            bottom: 539px;
          }
          #t1p_2 {
            left: 150px;
            bottom: 520px;
          }
          #t1q_2 {
            left: 428px;
            bottom: 520px;
          }
          #t1r_2 {
            left: 150px;
            bottom: 501px;
          }
          #t1s_2 {
            left: 150px;
            bottom: 482px;
          }
          #t1t_2 {
            left: 295px;
            bottom: 482px;
          }
          #t1u_2 {
            left: 79px;
            bottom: 446px;
          }
          #t1v_2 {
            left: 150px;
            bottom: 446px;
          }
          #t1w_2 {
            left: 509px;
            bottom: 446px;
          }
          #t1x_2 {
            left: 815px;
            bottom: 446px;
          }
          #t1y_2 {
            left: 613px;
            bottom: 446px;
          }
          #t1z_2 {
            left: 487px;
            bottom: 446px;
          }
          #t20_2 {
            left: 150px;
            bottom: 427px;
          }
          #t21_2 {
            left: 150px;
            bottom: 409px;
          }
          #t22_2 {
            left: 150px;
            bottom: 391px;
          }
          #t23_2 {
            left: 150px;
            bottom: 372px;
          }
          #t24_2 {
            left: 428px;
            bottom: 372px;
          }
          #t25_2 {
            left: 150px;
            bottom: 352px;
          }
          #t26_2 {
            left: 295px;
            bottom: 352px;
          }
          #t27_2 {
            left: 79px;
            bottom: 316px;
          }
          #t28_2 {
            left: 150px;
            bottom: 316px;
          }
          #t29_2 {
            left: 509px;
            bottom: 316px;
          }
          #t2a_2 {
            left: 815px;
            bottom: 316px;
          }
          #t2b_2 {
            left: 613px;
            bottom: 316px;
          }
          #t2c_2 {
            left: 487px;
            bottom: 316px;
          }
          #t2d_2 {
            left: 150px;
            bottom: 297px;
          }
          #t2e_2 {
            left: 150px;
            bottom: 279px;
          }
          #t2f_2 {
            left: 150px;
            bottom: 261px;
          }
          #t2g_2 {
            left: 150px;
            bottom: 242px;
          }
          #t2h_2 {
            left: 428px;
            bottom: 242px;
          }
          #t2i_2 {
            left: 564px;
            bottom: 85px;
          }
          #t2j_2 {
            left: 564px;
            bottom: 146px;
          }
          #t2k_2 {
            left: 564px;
            bottom: 162px;
          }
          #t2l_2 {
            left: 564px;
            bottom: 131px;
          }
          #t2m_2 {
            left: 79px;
            bottom: 70px;
          }
          #t2n_2 {
            left: 79px;
            bottom: 101px;
          }
          #t2o_2 {
            left: 79px;
            bottom: 116px;
          }
          #t2p_2 {
            left: 79px;
            bottom: 146px;
          }
          #t2q_2 {
            left: 79px;
            bottom: 131px;
          }
          #t2r_2 {
            left: 79px;
            bottom: 162px;
          }
          #t2s_2 {
            left: 241px;
            bottom: 162px;
          }
          #t2t_2 {
            left: 564px;
            bottom: 101px;
          }
          #t2u_2 {
            left: 241px;
            bottom: 146px;
          }
          #t2v_2 {
            left: 241px;
            bottom: 131px;
          }
          #t2w_2 {
            left: 241px;
            bottom: 116px;
          }
          #t2x_2 {
            left: 241px;
            bottom: 70px;
          }
          #t2y_2 {
            left: 241px;
            bottom: 85px;
          }
          #t2z_2 {
            left: 761px;
            bottom: 77px;
          }
          #t30_2 {
            left: 403px;
            bottom: 162px;
          }
          #t31_2 {
            left: 403px;
            bottom: 146px;
          }
          #t32_2 {
            left: 403px;
            bottom: 131px;
          }
          #t33_2 {
            left: 403px;
            bottom: 116px;
          }
          #t34_2 {
            left: 79px;
            bottom: 85px;
          }
          #t35_2 {
            left: 103px;
            bottom: 85px;
          }
          #t36_2 {
            left: 241px;
            bottom: 101px;
          }
          #t37_2 {
            left: 403px;
            bottom: 101px;
          }
  
          .s1_2 {
            font-size: 61.1px;
            font-family: DejaVuSansCondensed_2l;
            color: rgb(0, 0, 0);
          }
  
          .s2_2 {
            font-size: 61.1px;
            font-family: DejaVuSansCondensed-Bold_2t;
            color: rgb(0, 63, 133);
          }
  
          .s3_2 {
            font-size: 42.8px;
            font-family: DejaVuSansCondensed_2l;
            color: rgb(0, 0, 0);
          }
  
          .s4_2 {
            font-size: 42.8px;
            font-family: DejaVuSansCondensed-Bold_2t;
            color: rgb(0, 0, 0);
          }
        </style>
        <!-- End inline CSS -->
  
        <!-- Begin embedded font definitions -->
        <style id="fonts2" type="text/css">
          @font-face {
            font-family: DejaVuSansCondensed-Bold_2t;
            src: url("fonts/DejaVuSansCondensed-Bold_2t.woff") format("woff");
          }
  
          @font-face {
            font-family: DejaVuSansCondensed_2l;
            src: url("fonts/DejaVuSansCondensed_2l.woff") format("woff");
          }
        </style>
        <!-- End embedded font definitions -->
  
        <!-- Begin page background -->
        <div
          id="pg2Overlay"
          style="
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0);
            -webkit-user-select: none;
          "
        ></div>
        <div id="pg2" style="-webkit-user-select: none">
          <object
            width="909"
            height="1284"
            data="2/2.svg"
            type="image/svg+xml"
            id="pdf2"
            style="
              width: 909px;
              height: 1284px;
              -moz-transform: scale(1);
              z-index: 0;
            "
          ></object>
        </div>
        <!-- End page background -->
  
        <!-- Begin text definitions (Positioned/styled in CSS) -->
        <div id="t1_2" class="t s1_2">04.02.2022</div>
        <div id="t2_2" class="t s1_2">vom</div>
        <div id="t3_2" class="t s1_2">79615</div>
        <div id="t4_2" class="t s1_2">Lieferant</div>
        <div id="t5_2" class="t s1_2">Bestellung</div>
        <div id="t6_2" class="t s1_2">944456</div>
        <div id="t7_2" class="t s1_2">Seite</div>
        <div id="t8_2" class="t s1_2">2 / 11</div>
        <div id="t9_2" class="t s2_2">Pos</div>
        <div id="ta_2" class="t s2_2">Artikel</div>
        <div id="tb_2" class="t s2_2">Menge</div>
        <div id="tc_2" class="t s2_2">Einzelpreis</div>
        <div id="td_2" class="t s2_2">EUR</div>
        <div id="te_2" class="t s2_2">%</div>
        <div id="tf_2" class="t s2_2">Gesamtpreis</div>
        <div id="tg_2" class="t s2_2">EUR</div>
        <div id="th_2" class="t s1_2">O-Ring ø 53,64 x 2,62 FPM</div>
        <div id="ti_2" class="t s1_2">Preis pro PE (1 PE = 100 Stück)</div>
        <div id="tj_2" class="t s1_2">Werkstoff: FPM A schwarz</div>
        <div id="tk_2" class="t s1_2">Ihre Referenz/Artikelnummer:</div>
        <div id="tl_2" class="t s1_2">1140352648</div>
        <div id="tm_2" class="t s1_2">Zeichnung -</div>
        <div id="tn_2" class="t s1_2">Liefertermin:</div>
        <div id="to_2" class="t s1_2">11.02.2022</div>
        <div id="tp_2" class="t s1_2">04</div>
        <div id="tq_2" class="t s1_2">26918</div>
        <div id="tr_2" class="t s1_2">Stück</div>
        <div id="ts_2" class="t s1_2">0,90</div>
        <div id="tt_2" class="t s1_2">30,14</div>
        <div id="tu_2" class="t s1_2">3</div>
        <div id="tv_2" class="t s1_2">O-Ring ø 64,77 x 2,62 FPM</div>
        <div id="tw_2" class="t s1_2">Preis pro PE (1 PE = 100 Stück)</div>
        <div id="tx_2" class="t s1_2">Werkstoff: FPM A schwarz</div>
        <div id="ty_2" class="t s1_2">Ihre Referenz/Artikelnummer:</div>
        <div id="tz_2" class="t s1_2">1120071566</div>
        <div id="t10_2" class="t s1_2">Zeichnung -</div>
        <div id="t11_2" class="t s1_2">Liefertermin:</div>
        <div id="t12_2" class="t s1_2">11.02.2022</div>
        <div id="t13_2" class="t s1_2">05</div>
        <div id="t14_2" class="t s1_2">12525</div>
        <div id="t15_2" class="t s1_2">Stück</div>
        <div id="t16_2" class="t s1_2">0,11</div>
        <div id="t17_2" class="t s1_2">0,11</div>
        <div id="t18_2" class="t s1_2">100</div>
        <div id="t19_2" class="t s1_2">O-Ring ø 26,65 x 2,62 EPDM</div>
        <div id="t1a_2" class="t s1_2">Preis pro PE (1 PE = 100 Stück)</div>
        <div id="t1b_2" class="t s1_2">Werkstoff: EPDM</div>
        <div id="t1c_2" class="t s1_2">Ihre Referenz/Artikelnummer:</div>
        <div id="t1d_2" class="t s1_2">1140202631</div>
        <div id="t1e_2" class="t s1_2">Liefertermin:</div>
        <div id="t1f_2" class="t s1_2">11.02.2022</div>
        <div id="t1g_2" class="t s1_2">06</div>
        <div id="t1h_2" class="t s1_2">30102</div>
        <div id="t1i_2" class="t s1_2">Stück</div>
        <div id="t1j_2" class="t s1_2">26,89</div>
        <div id="t1k_2" class="t s1_2">67,22</div>
        <div id="t1l_2" class="t s1_2">40</div>
        <div id="t1m_2" class="t s1_2">O-Ring ø 13,95 x 2,62 FPM</div>
        <div id="t1n_2" class="t s1_2">Preis pro PE (1 PE = 100 Stück)</div>
        <div id="t1o_2" class="t s1_2">Werkstoff: FPM A schwarz</div>
        <div id="t1p_2" class="t s1_2">Ihre Referenz/Artikelnummer:</div>
        <div id="t1q_2" class="t s1_2">1120071488</div>
        <div id="t1r_2" class="t s1_2">Zeichnung -</div>
        <div id="t1s_2" class="t s1_2">Liefertermin:</div>
        <div id="t1t_2" class="t s1_2">05.04.2022</div>
        <div id="t1u_2" class="t s1_2">07</div>
        <div id="t1v_2" class="t s1_2">30449</div>
        <div id="t1w_2" class="t s1_2">Stück</div>
        <div id="t1x_2" class="t s1_2">94,84</div>
        <div id="t1y_2" class="t s1_2">189,68</div>
        <div id="t1z_2" class="t s1_2">50</div>
        <div id="t20_2" class="t s1_2">O-Ring ø 48,89 x 2,62 FPM</div>
        <div id="t21_2" class="t s1_2">Preis pro PE (1 PE = 100 Stück)</div>
        <div id="t22_2" class="t s1_2">Werkstoff: FPM A schwarz</div>
        <div id="t23_2" class="t s1_2">Ihre Referenz/Artikelnummer:</div>
        <div id="t24_2" class="t s1_2">1140352645</div>
        <div id="t25_2" class="t s1_2">Liefertermin:</div>
        <div id="t26_2" class="t s1_2">05.04.2022</div>
        <div id="t27_2" class="t s1_2">08</div>
        <div id="t28_2" class="t s1_2">33033</div>
        <div id="t29_2" class="t s1_2">Stück</div>
        <div id="t2a_2" class="t s1_2">51,57</div>
        <div id="t2b_2" class="t s1_2">257,87</div>
        <div id="t2c_2" class="t s1_2">20</div>
        <div id="t2d_2" class="t s1_2">O-Ring ø 171,12 x 2,62 FPM</div>
        <div id="t2e_2" class="t s1_2">Preis pro PE (1 PE = 100 Stück)</div>
        <div id="t2f_2" class="t s1_2">Werkstoff: FPM A schwarz</div>
        <div id="t2g_2" class="t s1_2">Ihre Referenz/Artikelnummer:</div>
        <div id="t2h_2" class="t s1_2">114035 2676</div>
        <div id="t2i_2" class="t s3_2">UST Ident-Nr. DE298543967</div>
        <div id="t2j_2" class="t s3_2">Peter Schneidewind</div>
        <div id="t2k_2" class="t s4_2">Geschäftsführer</div>
        <div id="t2l_2" class="t s3_2">Jürgen Ningelgen</div>
        <div id="t2m_2" class="t s3_2">Fax: +49 7723 9313-50</div>
        <div id="t2n_2" class="t s3_2">Germany</div>
        <div id="t2o_2" class="t s3_2">D-78148 Gütenbach</div>
        <div id="t2p_2" class="t s3_2">Headquarters</div>
        <div id="t2q_2" class="t s3_2">Höhenweg 1</div>
        <div id="t2r_2" class="t s4_2">RENA Technologies GmbH</div>
        <div id="t2s_2" class="t s4_2">RENA Technologies GmbH</div>
        <div id="t2t_2" class="t s3_2">Amtsgericht Freiburg HRB 712734</div>
        <div id="t2u_2" class="t s3_2">Standort Berg</div>
        <div id="t2v_2" class="t s3_2">Am Röthenbühl 7</div>
        <div id="t2w_2" class="t s3_2">D-92348 Berg</div>
        <div id="t2x_2" class="t s3_2">Fax +49 9189 4404-50</div>
        <div id="t2y_2" class="t s3_2">Tel. +49 9189 4404-0</div>
        <div id="t2z_2" class="t s1_2">www.rena.com</div>
        <div id="t30_2" class="t s4_2">RENA Technologies GmbH</div>
        <div id="t31_2" class="t s3_2">Technologie Center Freiburg</div>
        <div id="t32_2" class="t s3_2">Hans-Bunte-Straße 19</div>
        <div id="t33_2" class="t s3_2">D-79108 Freiburg</div>
        <div id="t34_2" class="t s3_2">Tel:</div>
        <div id="t35_2" class="t s3_2">+49 7723 9313-0</div>
        <div id="t36_2" class="t s3_2">Germany</div>
        <div id="t37_2" class="t s3_2">Germany</div>
  
        <!-- End text definitions -->
      </div>
  
      <div
        id="p3"
        style="
          overflow: hidden;
          position: relative;
          background-color: white;
          width: 909px;
          height: 1284px;
        "
      >
        <!-- Begin shared CSS values -->
        <style class="shared-css" type="text/css">
          .t {
            -webkit-transform-origin: bottom left;
            -ms-transform-origin: bottom left;
            transform-origin: bottom left;
            -webkit-transform: scale(0.25);
            -ms-transform: scale(0.25);
            transform: scale(0.25);
            z-index: 2;
            position: absolute;
            white-space: pre;
            overflow: visible;
            line-height: 1.5;
          }
        </style>
        <!-- End shared CSS values -->
  
        <!-- Begin inline CSS -->
        <style type="text/css">
          #t1_3 {
            left: 411px;
            bottom: 1095px;
          }
          #t2_3 {
            left: 372px;
            bottom: 1095px;
          }
          #t3_3 {
            left: 610px;
            bottom: 1095px;
          }
          #t4_3 {
            left: 544px;
            bottom: 1095px;
          }
          #t5_3 {
            left: 79px;
            bottom: 1095px;
          }
          #t6_3 {
            left: 165px;
            bottom: 1095px;
          }
          #t7_3 {
            left: 705px;
            bottom: 1095px;
          }
          #t8_3 {
            left: 774px;
            bottom: 1095px;
            word-spacing: -1.5px;
          }
          #t9_3 {
            left: 79px;
            bottom: 1052px;
          }
          #ta_3 {
            left: 150px;
            bottom: 1052px;
          }
          #tb_3 {
            left: 462px;
            bottom: 1052px;
          }
          #tc_3 {
            left: 577px;
            bottom: 1052px;
          }
          #td_3 {
            left: 630px;
            bottom: 1029px;
          }
          #te_3 {
            left: 698px;
            bottom: 1052px;
          }
          #tf_3 {
            left: 756px;
            bottom: 1052px;
          }
          #tg_3 {
            left: 823px;
            bottom: 1029px;
          }
          #th_3 {
            left: 150px;
            bottom: 1000px;
          }
          #ti_3 {
            left: 150px;
            bottom: 982px;
          }
          #tj_3 {
            left: 295px;
            bottom: 982px;
          }
          #tk_3 {
            left: 79px;
            bottom: 945px;
          }
          #tl_3 {
            left: 150px;
            bottom: 945px;
          }
          #tm_3 {
            left: 509px;
            bottom: 945px;
          }
          #tn_3 {
            left: 823px;
            bottom: 945px;
          }
          #to_3 {
            left: 622px;
            bottom: 945px;
          }
          #tp_3 {
            left: 487px;
            bottom: 945px;
          }
          #tq_3 {
            left: 150px;
            bottom: 927px;
          }
          #tr_3 {
            left: 150px;
            bottom: 909px;
          }
          #ts_3 {
            left: 150px;
            bottom: 890px;
          }
          #tt_3 {
            left: 150px;
            bottom: 872px;
          }
          #tu_3 {
            left: 428px;
            bottom: 872px;
          }
          #tv_3 {
            left: 150px;
            bottom: 852px;
          }
          #tw_3 {
            left: 150px;
            bottom: 834px;
          }
          #tx_3 {
            left: 295px;
            bottom: 834px;
          }
          #ty_3 {
            left: 79px;
            bottom: 797px;
          }
          #tz_3 {
            left: 150px;
            bottom: 797px;
          }
          #t10_3 {
            left: 509px;
            bottom: 797px;
          }
          #t11_3 {
            left: 815px;
            bottom: 797px;
          }
          #t12_3 {
            left: 622px;
            bottom: 797px;
          }
          #t13_3 {
            left: 478px;
            bottom: 797px;
          }
          #t14_3 {
            left: 150px;
            bottom: 779px;
          }
          #t15_3 {
            left: 150px;
            bottom: 760px;
          }
          #t16_3 {
            left: 150px;
            bottom: 742px;
          }
          #t17_3 {
            left: 150px;
            bottom: 724px;
          }
          #t18_3 {
            left: 428px;
            bottom: 724px;
          }
          #t19_3 {
            left: 150px;
            bottom: 704px;
          }
          #t1a_3 {
            left: 150px;
            bottom: 685px;
          }
          #t1b_3 {
            left: 295px;
            bottom: 685px;
          }
          #t1c_3 {
            left: 79px;
            bottom: 649px;
          }
          #t1d_3 {
            left: 150px;
            bottom: 649px;
          }
          #t1e_3 {
            left: 509px;
            bottom: 649px;
          }
          #t1f_3 {
            left: 815px;
            bottom: 649px;
          }
          #t1g_3 {
            left: 622px;
            bottom: 649px;
          }
          #t1h_3 {
            left: 478px;
            bottom: 649px;
          }
          #t1i_3 {
            left: 150px;
            bottom: 630px;
          }
          #t1j_3 {
            left: 150px;
            bottom: 612px;
          }
          #t1k_3 {
            left: 150px;
            bottom: 594px;
          }
          #t1l_3 {
            left: 150px;
            bottom: 575px;
          }
          #t1m_3 {
            left: 428px;
            bottom: 575px;
          }
          #t1n_3 {
            left: 150px;
            bottom: 556px;
          }
          #t1o_3 {
            left: 150px;
            bottom: 537px;
          }
          #t1p_3 {
            left: 295px;
            bottom: 537px;
          }
          #t1q_3 {
            left: 79px;
            bottom: 501px;
          }
          #t1r_3 {
            left: 150px;
            bottom: 501px;
          }
          #t1s_3 {
            left: 509px;
            bottom: 501px;
          }
          #t1t_3 {
            left: 815px;
            bottom: 501px;
          }
          #t1u_3 {
            left: 622px;
            bottom: 501px;
          }
          #t1v_3 {
            left: 478px;
            bottom: 501px;
          }
          #t1w_3 {
            left: 150px;
            bottom: 482px;
          }
          #t1x_3 {
            left: 150px;
            bottom: 464px;
          }
          #t1y_3 {
            left: 150px;
            bottom: 446px;
          }
          #t1z_3 {
            left: 150px;
            bottom: 427px;
          }
          #t20_3 {
            left: 428px;
            bottom: 427px;
          }
          #t21_3 {
            left: 150px;
            bottom: 407px;
          }
          #t22_3 {
            left: 150px;
            bottom: 389px;
          }
          #t23_3 {
            left: 295px;
            bottom: 389px;
          }
          #t24_3 {
            left: 79px;
            bottom: 352px;
          }
          #t25_3 {
            left: 150px;
            bottom: 352px;
          }
          #t26_3 {
            left: 509px;
            bottom: 352px;
          }
          #t27_3 {
            left: 815px;
            bottom: 352px;
          }
          #t28_3 {
            left: 613px;
            bottom: 352px;
          }
          #t29_3 {
            left: 487px;
            bottom: 352px;
          }
          #t2a_3 {
            left: 150px;
            bottom: 334px;
          }
          #t2b_3 {
            left: 150px;
            bottom: 316px;
          }
          #t2c_3 {
            left: 150px;
            bottom: 297px;
          }
          #t2d_3 {
            left: 150px;
            bottom: 279px;
          }
          #t2e_3 {
            left: 428px;
            bottom: 279px;
          }
          #t2f_3 {
            left: 564px;
            bottom: 85px;
          }
          #t2g_3 {
            left: 564px;
            bottom: 146px;
          }
          #t2h_3 {
            left: 564px;
            bottom: 162px;
          }
          #t2i_3 {
            left: 564px;
            bottom: 131px;
          }
          #t2j_3 {
            left: 79px;
            bottom: 70px;
          }
          #t2k_3 {
            left: 79px;
            bottom: 101px;
          }
          #t2l_3 {
            left: 79px;
            bottom: 116px;
          }
          #t2m_3 {
            left: 79px;
            bottom: 146px;
          }
          #t2n_3 {
            left: 79px;
            bottom: 131px;
          }
          #t2o_3 {
            left: 79px;
            bottom: 162px;
          }
          #t2p_3 {
            left: 241px;
            bottom: 162px;
          }
          #t2q_3 {
            left: 564px;
            bottom: 101px;
          }
          #t2r_3 {
            left: 241px;
            bottom: 146px;
          }
          #t2s_3 {
            left: 241px;
            bottom: 131px;
          }
          #t2t_3 {
            left: 241px;
            bottom: 116px;
          }
          #t2u_3 {
            left: 241px;
            bottom: 70px;
          }
          #t2v_3 {
            left: 241px;
            bottom: 85px;
          }
          #t2w_3 {
            left: 761px;
            bottom: 77px;
          }
          #t2x_3 {
            left: 403px;
            bottom: 162px;
          }
          #t2y_3 {
            left: 403px;
            bottom: 146px;
          }
          #t2z_3 {
            left: 403px;
            bottom: 131px;
          }
          #t30_3 {
            left: 403px;
            bottom: 116px;
          }
          #t31_3 {
            left: 79px;
            bottom: 85px;
          }
          #t32_3 {
            left: 103px;
            bottom: 85px;
          }
          #t33_3 {
            left: 241px;
            bottom: 101px;
          }
          #t34_3 {
            left: 403px;
            bottom: 101px;
          }
  
          .s1_3 {
            font-size: 61.1px;
            font-family: DejaVuSansCondensed_2l;
            color: rgb(0, 0, 0);
          }
  
          .s2_3 {
            font-size: 61.1px;
            font-family: DejaVuSansCondensed-Bold_2t;
            color: rgb(0, 63, 133);
          }
  
          .s3_3 {
            font-size: 42.8px;
            font-family: DejaVuSansCondensed_2l;
            color: rgb(0, 0, 0);
          }
  
          .s4_3 {
            font-size: 42.8px;
            font-family: DejaVuSansCondensed-Bold_2t;
            color: rgb(0, 0, 0);
          }
        </style>
        <!-- End inline CSS -->
  
        <!-- Begin embedded font definitions -->
        <style id="fonts3" type="text/css">
          @font-face {
            font-family: DejaVuSansCondensed-Bold_2t;
            src: url("fonts/DejaVuSansCondensed-Bold_2t.woff") format("woff");
          }
  
          @font-face {
            font-family: DejaVuSansCondensed_2l;
            src: url("fonts/DejaVuSansCondensed_2l.woff") format("woff");
          }
        </style>
        <!-- End embedded font definitions -->
  
        <!-- Begin page background -->
        <div
          id="pg3Overlay"
          style="
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0);
            -webkit-user-select: none;
          "
        ></div>
        <div id="pg3" style="-webkit-user-select: none">
          <object
            width="909"
            height="1284"
            data="3/3.svg"
            type="image/svg+xml"
            id="pdf3"
            style="
              width: 909px;
              height: 1284px;
              -moz-transform: scale(1);
              z-index: 0;
            "
          ></object>
        </div>
        <!-- End page background -->
  
        <!-- Begin text definitions (Positioned/styled in CSS) -->
        <div id="t1_3" class="t s1_3">04.02.2022</div>
        <div id="t2_3" class="t s1_3">vom</div>
        <div id="t3_3" class="t s1_3">79615</div>
        <div id="t4_3" class="t s1_3">Lieferant</div>
        <div id="t5_3" class="t s1_3">Bestellung</div>
        <div id="t6_3" class="t s1_3">944456</div>
        <div id="t7_3" class="t s1_3">Seite</div>
        <div id="t8_3" class="t s1_3">3 / 11</div>
        <div id="t9_3" class="t s2_3">Pos</div>
        <div id="ta_3" class="t s2_3">Artikel</div>
        <div id="tb_3" class="t s2_3">Menge</div>
        <div id="tc_3" class="t s2_3">Einzelpreis</div>
        <div id="td_3" class="t s2_3">EUR</div>
        <div id="te_3" class="t s2_3">%</div>
        <div id="tf_3" class="t s2_3">Gesamtpreis</div>
        <div id="tg_3" class="t s2_3">EUR</div>
        <div id="th_3" class="t s1_3">Zeichnung -</div>
        <div id="ti_3" class="t s1_3">Liefertermin:</div>
        <div id="tj_3" class="t s1_3">05.04.2022</div>
        <div id="tk_3" class="t s1_3">09</div>
        <div id="tl_3" class="t s1_3">16737</div>
        <div id="tm_3" class="t s1_3">Stück</div>
        <div id="tn_3" class="t s1_3">5,41</div>
        <div id="to_3" class="t s1_3">27,05</div>
        <div id="tp_3" class="t s1_3">20</div>
        <div id="tq_3" class="t s1_3">O-Ring ø 75,92 x 1,78 FPM</div>
        <div id="tr_3" class="t s1_3">Preis pro PE (1 PE = 100 Stück)</div>
        <div id="ts_3" class="t s1_3">Werkstoff: FPM A schwarz</div>
        <div id="tt_3" class="t s1_3">Ihre Referenz/Artikelnummer:</div>
        <div id="tu_3" class="t s1_3">1120070530</div>
        <div id="tv_3" class="t s1_3">Zeichnung -</div>
        <div id="tw_3" class="t s1_3">Liefertermin:</div>
        <div id="tx_3" class="t s1_3">05.04.2022</div>
        <div id="ty_3" class="t s1_3">10</div>
        <div id="tz_3" class="t s1_3">19915</div>
        <div id="t10_3" class="t s1_3">Stück</div>
        <div id="t11_3" class="t s1_3">18,94</div>
        <div id="t12_3" class="t s1_3">18,94</div>
        <div id="t13_3" class="t s1_3">100</div>
        <div id="t14_3" class="t s1_3">O-Ring ø 50,52 x 1,78 FPM</div>
        <div id="t15_3" class="t s1_3">Preis pro PE (1 PE = 100 Stück)</div>
        <div id="t16_3" class="t s1_3">Werkstoff: FPM A schwarz</div>
        <div id="t17_3" class="t s1_3">Ihre Referenz/Artikelnummer:</div>
        <div id="t18_3" class="t s1_3">1120070514</div>
        <div id="t19_3" class="t s1_3">Zeichnung -</div>
        <div id="t1a_3" class="t s1_3">Liefertermin:</div>
        <div id="t1b_3" class="t s1_3">05.04.2022</div>
        <div id="t1c_3" class="t s1_3">11</div>
        <div id="t1d_3" class="t s1_3">19916</div>
        <div id="t1e_3" class="t s1_3">Stück</div>
        <div id="t1f_3" class="t s1_3">26,85</div>
        <div id="t1g_3" class="t s1_3">26,85</div>
        <div id="t1h_3" class="t s1_3">100</div>
        <div id="t1i_3" class="t s1_3">O-Ring ø 55,25 x 2,62 FPM</div>
        <div id="t1j_3" class="t s1_3">Preis pro PE (1 PE = 100 Stück)</div>
        <div id="t1k_3" class="t s1_3">Werkstoff: FPM A schwarz</div>
        <div id="t1l_3" class="t s1_3">Ihre Referenz/Artikelnummer:</div>
        <div id="t1m_3" class="t s1_3">1140352649</div>
        <div id="t1n_3" class="t s1_3">Zeichnung -</div>
        <div id="t1o_3" class="t s1_3">Liefertermin:</div>
        <div id="t1p_3" class="t s1_3">05.04.2022</div>
        <div id="t1q_3" class="t s1_3">12</div>
        <div id="t1r_3" class="t s1_3">19917</div>
        <div id="t1s_3" class="t s1_3">Stück</div>
        <div id="t1t_3" class="t s1_3">35,84</div>
        <div id="t1u_3" class="t s1_3">35,84</div>
        <div id="t1v_3" class="t s1_3">100</div>
        <div id="t1w_3" class="t s1_3">O-Ring ø 75,87 x 2,62 FPM</div>
        <div id="t1x_3" class="t s1_3">Preis pro PE (1 PE = 100 Stück)</div>
        <div id="t1y_3" class="t s1_3">Werkstoff: FPM A schwarz</div>
        <div id="t1z_3" class="t s1_3">Ihre Referenz/Artikelnummer:</div>
        <div id="t20_3" class="t s1_3">1120071580</div>
        <div id="t21_3" class="t s1_3">Zeichnung -</div>
        <div id="t22_3" class="t s1_3">Liefertermin:</div>
        <div id="t23_3" class="t s1_3">05.04.2022</div>
        <div id="t24_3" class="t s1_3">13</div>
        <div id="t25_3" class="t s1_3">12523</div>
        <div id="t26_3" class="t s1_3">Stück</div>
        <div id="t27_3" class="t s1_3">45,95</div>
        <div id="t28_3" class="t s1_3">229,76</div>
        <div id="t29_3" class="t s1_3">20</div>
        <div id="t2a_3" class="t s1_3">O-Ring ø 50,52 x 1,78 EPDM</div>
        <div id="t2b_3" class="t s1_3">Preis pro PE (1 PE = 100 Stück)</div>
        <div id="t2c_3" class="t s1_3">Werkstoff: EPDM</div>
        <div id="t2d_3" class="t s1_3">Ihre Referenz/Artikelnummer:</div>
        <div id="t2e_3" class="t s1_3">1140201836</div>
        <div id="t2f_3" class="t s3_3">UST Ident-Nr. DE298543967</div>
        <div id="t2g_3" class="t s3_3">Peter Schneidewind</div>
        <div id="t2h_3" class="t s4_3">Geschäftsführer</div>
        <div id="t2i_3" class="t s3_3">Jürgen Ningelgen</div>
        <div id="t2j_3" class="t s3_3">Fax: +49 7723 9313-50</div>
        <div id="t2k_3" class="t s3_3">Germany</div>
        <div id="t2l_3" class="t s3_3">D-78148 Gütenbach</div>
        <div id="t2m_3" class="t s3_3">Headquarters</div>
        <div id="t2n_3" class="t s3_3">Höhenweg 1</div>
        <div id="t2o_3" class="t s4_3">RENA Technologies GmbH</div>
        <div id="t2p_3" class="t s4_3">RENA Technologies GmbH</div>
        <div id="t2q_3" class="t s3_3">Amtsgericht Freiburg HRB 712734</div>
        <div id="t2r_3" class="t s3_3">Standort Berg</div>
        <div id="t2s_3" class="t s3_3">Am Röthenbühl 7</div>
        <div id="t2t_3" class="t s3_3">D-92348 Berg</div>
        <div id="t2u_3" class="t s3_3">Fax +49 9189 4404-50</div>
        <div id="t2v_3" class="t s3_3">Tel. +49 9189 4404-0</div>
        <div id="t2w_3" class="t s1_3">www.rena.com</div>
        <div id="t2x_3" class="t s4_3">RENA Technologies GmbH</div>
        <div id="t2y_3" class="t s3_3">Technologie Center Freiburg</div>
        <div id="t2z_3" class="t s3_3">Hans-Bunte-Straße 19</div>
        <div id="t30_3" class="t s3_3">D-79108 Freiburg</div>
        <div id="t31_3" class="t s3_3">Tel:</div>
        <div id="t32_3" class="t s3_3">+49 7723 9313-0</div>
        <div id="t33_3" class="t s3_3">Germany</div>
        <div id="t34_3" class="t s3_3">Germany</div>
  
        <!-- End text definitions -->
      </div>
    </body>
  </html>
  
  `,
  '84231-0000302985_4500765303': `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
    <meta charset="utf-8" />
  </head>

  <body style="margin: 0">
    <div
      id="p1"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1286px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_1 {
          left: 87px;
          bottom: 1021px;
          letter-spacing: 0.22px;
        }
        #t2_1 {
          left: 87px;
          bottom: 1003px;
          letter-spacing: 0.13px;
          word-spacing: 0.01px;
        }
        #t3_1 {
          left: 87px;
          bottom: 985px;
          letter-spacing: 0.15px;
          word-spacing: -0.02px;
        }
        #t4_1 {
          left: 87px;
          bottom: 966px;
          letter-spacing: 0.18px;
          word-spacing: -0.01px;
        }
        #t5_1 {
          left: 774px;
          bottom: 741px;
          letter-spacing: 0.17px;
          word-spacing: 0.01px;
        }
        #t6_1 {
          left: 87px;
          bottom: 699px;
          letter-spacing: 0.12px;
        }
        #t7_1 {
          left: 238px;
          bottom: 704px;
          letter-spacing: 0.15px;
        }
        #t8_1 {
          left: 507px;
          bottom: 699px;
          letter-spacing: 0.13px;
          word-spacing: 0.06px;
        }
        #t9_1 {
          left: 658px;
          bottom: 704px;
          letter-spacing: 0.11px;
        }
        #ta_1 {
          left: 87px;
          bottom: 678px;
          letter-spacing: 0.12px;
          word-spacing: 0.05px;
        }
        #tb_1 {
          left: 238px;
          bottom: 683px;
          letter-spacing: 0.16px;
        }
        #tc_1 {
          left: 507px;
          bottom: 678px;
          letter-spacing: 0.15px;
        }
        #td_1 {
          left: 658px;
          bottom: 683px;
          letter-spacing: 0.16px;
        }
        #te_1 {
          left: 87px;
          bottom: 656px;
          letter-spacing: 0.13px;
        }
        #tf_1 {
          left: 238px;
          bottom: 662px;
          letter-spacing: 0.12px;
        }
        #tg_1 {
          left: 507px;
          bottom: 656px;
          letter-spacing: 0.08px;
          word-spacing: 0.11px;
        }
        #th_1 {
          left: 658px;
          bottom: 662px;
          letter-spacing: 0.09px;
        }
        #ti_1 {
          left: 690px;
          bottom: 662px;
          letter-spacing: 0.14px;
        }
        #tj_1 {
          left: 87px;
          bottom: 635px;
          letter-spacing: 0.09px;
          word-spacing: 0.11px;
        }
        #tk_1 {
          left: 238px;
          bottom: 640px;
          letter-spacing: 0.12px;
        }
        #tl_1 {
          left: 507px;
          bottom: 635px;
          letter-spacing: 0.1px;
          word-spacing: 0.1px;
        }
        #tm_1 {
          left: 658px;
          bottom: 640px;
          letter-spacing: 0.16px;
          word-spacing: 0.02px;
        }
        #tn_1 {
          left: 87px;
          bottom: 613px;
          letter-spacing: 0.13px;
        }
        #to_1 {
          left: 238px;
          bottom: 619px;
          letter-spacing: 0.13px;
        }
        #tp_1 {
          left: 507px;
          bottom: 613px;
          letter-spacing: 0.12px;
          word-spacing: 0.07px;
        }
        #tq_1 {
          left: 658px;
          bottom: 619px;
          letter-spacing: 0.17px;
        }
        #tr_1 {
          left: 87px;
          bottom: 592px;
          letter-spacing: 0.06px;
          word-spacing: 0.14px;
        }
        #ts_1 {
          left: 238px;
          bottom: 597px;
          letter-spacing: 0.17px;
        }
        #tt_1 {
          left: 87px;
          bottom: 533px;
          letter-spacing: 0.08px;
          word-spacing: 0.06px;
        }
        #tu_1 {
          left: 87px;
          bottom: 514px;
          letter-spacing: 0.18px;
          word-spacing: -0.04px;
        }
        #tv_1 {
          left: 87px;
          bottom: 496px;
          letter-spacing: 0.17px;
          word-spacing: -0.03px;
        }
        #tw_1 {
          left: 87px;
          bottom: 478px;
          letter-spacing: 0.17px;
          word-spacing: -0.04px;
        }
        #tx_1 {
          left: 87px;
          bottom: 459px;
          letter-spacing: 0.16px;
          word-spacing: -0.02px;
        }
        #ty_1 {
          left: 87px;
          bottom: 441px;
          letter-spacing: 0.15px;
          word-spacing: -0.02px;
        }
        #tz_1 {
          left: 87px;
          bottom: 423px;
          letter-spacing: 0.2px;
          word-spacing: -0.03px;
        }
        #t10_1 {
          left: 87px;
          bottom: 404px;
          letter-spacing: 0.18px;
        }
        #t11_1 {
          left: 95px;
          bottom: 327px;
          letter-spacing: 0.27px;
          word-spacing: 0.04px;
        }
        #t12_1 {
          left: 543px;
          bottom: 327px;
          letter-spacing: 0.34px;
        }
        #t13_1 {
          left: 87px;
          bottom: 287px;
          letter-spacing: 0.16px;
          word-spacing: 0.01px;
        }
        #t14_1 {
          left: 279px;
          bottom: 287px;
          letter-spacing: 0.17px;
          word-spacing: -0.02px;
        }
        #t15_1 {
          left: 544px;
          bottom: 287px;
          letter-spacing: 0.08px;
        }
        #t16_1 {
          left: 87px;
          bottom: 271px;
          letter-spacing: 0.12px;
          word-spacing: 0.09px;
        }

        .s1_1 {
          font-size: 15px;
          font-family: sub_Helvetica_lsr;
          color: #000;
        }
        .s2_1 {
          font-size: 15px;
          font-family: sub_Helvetica-Bold_lsb;
          color: #000;
        }
        .s3_1 {
          font-size: 12px;
          font-family: sub_Helvetica-Bold_lsb;
          color: #000;
        }
        .s4_1 {
          font-size: 12px;
          font-family: sub_Helvetica_lsr;
          color: #000;
        }
        .s5_1 {
          font-size: 24px;
          font-family: sub_Helvetica-Bold_lsb;
          color: #000;
        }
        .s6_1 {
          font-size: 18px;
          font-family: sub_Helvetica-Bold_lsb;
          color: #000;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts1" type="text/css">
        @font-face {
          font-family: sub_Helvetica-Bold_lsb;
          src: url("fonts/sub_Helvetica-Bold_lsb.woff") format("woff");
        }

        @font-face {
          font-family: sub_Helvetica_lsr;
          src: url("fonts/sub_Helvetica_lsr.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg1Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg1" style="-webkit-user-select: none">
        <object
          width="909"
          height="1286"
          data="1/1.svg"
          type="image/svg+xml"
          id="pdf1"
          style="
            width: 909px;
            height: 1286px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_1" class="t s1_1">Company </span>
        <span id="t2_1" class="t s1_1">Maxon Switzerland AG </span>
        <span id="t3_1" class="t s1_1">Industriestrasse 23 </span>
        <span id="t4_1" class="t s1_1">CH-6055 ALPNACH DORF </span>
        <span id="t5_1" class="t s2_1">page: 1 / 4 </span>
        <span id="t6_1" class="t s3_1">Date </span>
        <span id="t7_1" class="t s4_1">04.12.2022 </span>
        <span id="t8_1" class="t s3_1">Our reference </span>
        <span id="t9_1" class="t s4_1">Renuga-Devi </span>
        <span id="ta_1" class="t s3_1">General line / Ext. </span>
        <span id="tb_1" class="t s4_1">+60355147777/1605 </span>
        <span id="tc_1" class="t s3_1">Fax </span>
        <span id="td_1" class="t s4_1">+60351919632 </span>
        <span id="te_1" class="t s3_1">E-mail </span>
        <span id="tf_1" class="t s4_1">renuga-devi.r-ramasamy@besi.com </span>
        <span id="tg_1" class="t s3_1">Delivery terms </span>
        <span id="th_1" class="t s4_1">FCA </span
        ><span id="ti_1" class="t s4_1">Sachseln </span>
        <span id="tj_1" class="t s3_1">Delivery method </span>
        <span id="tk_1" class="t s4_1">DHL </span>
        <span id="tl_1" class="t s3_1">Payment terms </span>
        <span id="tm_1" class="t s4_1">Within 30 days due net </span>
        <span id="tn_1" class="t s3_1">Currency </span>
        <span id="to_1" class="t s4_1">CHF </span>
        <span id="tp_1" class="t s3_1">Supplier No </span>
        <span id="tq_1" class="t s4_1">302985 </span>
        <span id="tr_1" class="t s3_1">Our VAT # </span>
        <span id="ts_1" class="t s4_1">000212254720 </span>
        <span id="tt_1" class="t s2_1">Delivery Address: </span>
        <span id="tu_1" class="t s1_1">Besi APac Sdn Bhd </span>
        <span id="tv_1" class="t s1_1">@Glenmarie Site </span>
        <span id="tw_1" class="t s1_1"
          >No 22 &amp; 24, Jalan Pemaju U1/15,
        </span>
        <span id="tx_1" class="t s1_1">Section U1 </span>
        <span id="ty_1" class="t s1_1">Glenmarie Hicom Industrial Park </span>
        <span id="tz_1" class="t s1_1">40150 SHAH ALAM-SELANGOR </span>
        <span id="t10_1" class="t s1_1">MALAYSIA </span>
        <span id="t11_1" class="t s5_1">Purchase order no </span
        ><span id="t12_1" class="t s5_1">4500765303 </span>
        <span id="t13_1" class="t s6_1">We expect your order </span
        ><span id="t14_1" class="t s6_1">confirmation within the next 3 </span
        ><span id="t15_1" class="t s6_1">days! </span>
        <span id="t16_1" class="t s4_1"
          >The mentioned delivery date = The expected arrival date at BESI APac
          SDN. BHD.
        </span>
      </div>
      <!-- End text definitions -->
    </div>

    <div
      id="p2"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1286px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_2 {
          left: 74px;
          bottom: 1014px;
          letter-spacing: 0.18px;
        }
        #t2_2 {
          left: 117px;
          bottom: 1014px;
          letter-spacing: 0.12px;
          word-spacing: 0.01px;
        }
        #t3_2 {
          left: 244px;
          bottom: 1014px;
          letter-spacing: 0.15px;
        }
        #t4_2 {
          left: 482px;
          bottom: 1014px;
          letter-spacing: 0.14px;
        }
        #t5_2 {
          left: 482px;
          bottom: 996px;
          letter-spacing: 0.12px;
        }
        #t6_2 {
          left: 595px;
          bottom: 1014px;
          letter-spacing: 0.13px;
        }
        #t7_2 {
          left: 686px;
          bottom: 1014px;
          letter-spacing: 0.17px;
        }
        #t8_2 {
          left: 744px;
          bottom: 1014px;
          letter-spacing: 0.17px;
          word-spacing: -0.01px;
        }
        #t9_2 {
          left: 117px;
          bottom: 974px;
          letter-spacing: 0.14px;
        }
        #ta_2 {
          left: 244px;
          bottom: 974px;
          letter-spacing: 0.15px;
        }
        #tb_2 {
          left: 74px;
          bottom: 953px;
          letter-spacing: 0.17px;
        }
        #tc_2 {
          left: 117px;
          bottom: 953px;
          letter-spacing: 0.15px;
          word-spacing: 0.04px;
        }
        #td_2 {
          left: 244px;
          bottom: 953px;
          letter-spacing: 0.17px;
          word-spacing: 0.04px;
        }
        #te_2 {
          left: 244px;
          bottom: 934px;
          letter-spacing: 0.15px;
        }
        #tf_2 {
          left: 482px;
          bottom: 953px;
          letter-spacing: 0.15px;
        }
        #tg_2 {
          left: 595px;
          bottom: 953px;
          letter-spacing: 0.13px;
        }
        #th_2 {
          left: 682px;
          bottom: 953px;
          letter-spacing: 0.19px;
        }
        #ti_2 {
          left: 744px;
          bottom: 953px;
          letter-spacing: 0.14px;
        }
        #tj_2 {
          left: 798px;
          bottom: 953px;
          letter-spacing: 0.13px;
        }
        #tk_2 {
          left: 117px;
          bottom: 913px;
          letter-spacing: 0.16px;
        }
        #tl_2 {
          left: 117px;
          bottom: 895px;
          letter-spacing: 0.14px;
          word-spacing: 0.07px;
        }
        #tm_2 {
          left: 117px;
          bottom: 873px;
          letter-spacing: 0.17px;
        }
        #tn_2 {
          left: 239px;
          bottom: 852px;
          letter-spacing: 0.09px;
          word-spacing: 0.07px;
        }
        #to_2 {
          left: 323px;
          bottom: 852px;
          letter-spacing: 0.19px;
        }
        #tp_2 {
          left: 420px;
          bottom: 852px;
          letter-spacing: 0.14px;
        }
        #tq_2 {
          left: 465px;
          bottom: 852px;
          letter-spacing: 0.09px;
        }
        #tr_2 {
          left: 160px;
          bottom: 831px;
          letter-spacing: 0.11px;
          word-spacing: 0.07px;
        }
        #ts_2 {
          left: 290px;
          bottom: 831px;
          letter-spacing: 0.17px;
        }
        #tt_2 {
          left: 420px;
          bottom: 831px;
          letter-spacing: 0.11px;
        }
        #tu_2 {
          left: 550px;
          bottom: 831px;
          letter-spacing: 0.17px;
        }
        #tv_2 {
          left: 138px;
          bottom: 809px;
          letter-spacing: 0.13px;
          word-spacing: 0.05px;
        }
        #tw_2 {
          left: 138px;
          bottom: 791px;
          letter-spacing: 0.13px;
          word-spacing: 0.06px;
        }
        #tx_2 {
          left: 138px;
          bottom: 768px;
          letter-spacing: 0.16px;
          word-spacing: -0.01px;
        }
        #ty_2 {
          left: 138px;
          bottom: 747px;
          letter-spacing: 0.16px;
          word-spacing: 0.05px;
        }
        #tz_2 {
          left: 312px;
          bottom: 747px;
          letter-spacing: 0.12px;
        }
        #t10_2 {
          left: 355px;
          bottom: 747px;
          letter-spacing: 0.14px;
        }
        #t11_2 {
          left: 438px;
          bottom: 747px;
          letter-spacing: 0.13px;
        }
        #t12_2 {
          left: 472px;
          bottom: 747px;
          letter-spacing: 0.15px;
          word-spacing: 0.03px;
        }
        #t13_2 {
          left: 398px;
          bottom: 688px;
          letter-spacing: 0.07px;
        }
        #t14_2 {
          left: 74px;
          bottom: 667px;
          letter-spacing: 0.17px;
        }
        #t15_2 {
          left: 117px;
          bottom: 667px;
          letter-spacing: 0.15px;
          word-spacing: 0.04px;
        }
        #t16_2 {
          left: 244px;
          bottom: 667px;
          letter-spacing: 0.16px;
          word-spacing: 0.06px;
        }
        #t17_2 {
          left: 482px;
          bottom: 667px;
          letter-spacing: 0.15px;
        }
        #t18_2 {
          left: 595px;
          bottom: 667px;
        }
        #t19_2 {
          left: 682px;
          bottom: 667px;
          letter-spacing: 0.19px;
        }
        #t1a_2 {
          left: 744px;
          bottom: 667px;
          letter-spacing: 0.14px;
        }
        #t1b_2 {
          left: 788px;
          bottom: 667px;
          letter-spacing: 0.13px;
        }
        #t1c_2 {
          left: 117px;
          bottom: 646px;
          letter-spacing: 0.16px;
        }
        #t1d_2 {
          left: 117px;
          bottom: 624px;
          letter-spacing: 0.16px;
        }
        #t1e_2 {
          left: 239px;
          bottom: 603px;
          letter-spacing: 0.09px;
          word-spacing: 0.07px;
        }
        #t1f_2 {
          left: 323px;
          bottom: 603px;
          letter-spacing: 0.19px;
        }
        #t1g_2 {
          left: 420px;
          bottom: 603px;
          letter-spacing: 0.14px;
        }
        #t1h_2 {
          left: 458px;
          bottom: 603px;
          letter-spacing: 0.09px;
        }
        #t1i_2 {
          left: 138px;
          bottom: 580px;
          letter-spacing: 0.16px;
          word-spacing: -0.01px;
        }
        #t1j_2 {
          left: 138px;
          bottom: 559px;
          letter-spacing: 0.16px;
          word-spacing: 0.05px;
        }
        #t1k_2 {
          left: 312px;
          bottom: 559px;
          letter-spacing: 0.12px;
        }
        #t1l_2 {
          left: 355px;
          bottom: 559px;
          letter-spacing: 0.14px;
        }
        #t1m_2 {
          left: 438px;
          bottom: 559px;
          letter-spacing: 0.13px;
        }
        #t1n_2 {
          left: 472px;
          bottom: 559px;
          letter-spacing: 0.16px;
          word-spacing: 0.06px;
        }
        #t1o_2 {
          left: 398px;
          bottom: 500px;
          letter-spacing: 0.07px;
        }
        #t1p_2 {
          left: 74px;
          bottom: 479px;
          letter-spacing: 0.17px;
        }
        #t1q_2 {
          left: 117px;
          bottom: 479px;
          letter-spacing: 0.17px;
        }
        #t1r_2 {
          left: 244px;
          bottom: 479px;
          letter-spacing: 0.16px;
          word-spacing: 0.06px;
        }
        #t1s_2 {
          left: 482px;
          bottom: 479px;
          letter-spacing: 0.15px;
        }
        #t1t_2 {
          left: 595px;
          bottom: 479px;
          letter-spacing: 0.13px;
        }
        #t1u_2 {
          left: 682px;
          bottom: 479px;
          letter-spacing: 0.19px;
        }
        #t1v_2 {
          left: 744px;
          bottom: 479px;
          letter-spacing: 0.14px;
        }
        #t1w_2 {
          left: 798px;
          bottom: 479px;
          letter-spacing: 0.13px;
        }
        #t1x_2 {
          left: 117px;
          bottom: 458px;
          letter-spacing: 0.15px;
        }
        #t1y_2 {
          left: 117px;
          bottom: 439px;
          letter-spacing: 0.15px;
        }
        #t1z_2 {
          left: 239px;
          bottom: 418px;
          letter-spacing: 0.09px;
          word-spacing: 0.07px;
        }
        #t20_2 {
          left: 323px;
          bottom: 418px;
          letter-spacing: 0.19px;
        }
        #t21_2 {
          left: 420px;
          bottom: 418px;
          letter-spacing: 0.14px;
        }
        #t22_2 {
          left: 465px;
          bottom: 418px;
          letter-spacing: 0.09px;
        }
        #t23_2 {
          left: 138px;
          bottom: 397px;
          letter-spacing: 0.14px;
          word-spacing: 0.05px;
        }
        #t24_2 {
          left: 138px;
          bottom: 378px;
          letter-spacing: 0.15px;
        }
        #t25_2 {
          left: 398px;
          bottom: 356px;
          letter-spacing: 0.07px;
        }
        #t26_2 {
          left: 74px;
          bottom: 335px;
          letter-spacing: 0.17px;
        }
        #t27_2 {
          left: 117px;
          bottom: 335px;
          letter-spacing: 0.15px;
          word-spacing: 0.04px;
        }
        #t28_2 {
          left: 244px;
          bottom: 335px;
          letter-spacing: 0.14px;
          word-spacing: 0.07px;
        }
        #t29_2 {
          left: 482px;
          bottom: 335px;
          letter-spacing: 0.15px;
        }
        #t2a_2 {
          left: 595px;
          bottom: 335px;
          letter-spacing: 0.13px;
        }
        #t2b_2 {
          left: 682px;
          bottom: 335px;
          letter-spacing: 0.19px;
        }
        #t2c_2 {
          left: 744px;
          bottom: 335px;
          letter-spacing: 0.14px;
        }
        #t2d_2 {
          left: 798px;
          bottom: 335px;
          letter-spacing: 0.13px;
        }
        #t2e_2 {
          left: 117px;
          bottom: 313px;
          letter-spacing: 0.17px;
        }
        #t2f_2 {
          left: 117px;
          bottom: 292px;
          letter-spacing: 0.17px;
        }
        #t2g_2 {
          left: 239px;
          bottom: 271px;
          letter-spacing: 0.09px;
          word-spacing: 0.07px;
        }
        #t2h_2 {
          left: 323px;
          bottom: 271px;
          letter-spacing: 0.19px;
        }
        #t2i_2 {
          left: 420px;
          bottom: 271px;
          letter-spacing: 0.14px;
        }
        #t2j_2 {
          left: 465px;
          bottom: 271px;
          letter-spacing: 0.09px;
        }
        #t2k_2 {
          left: 160px;
          bottom: 249px;
          letter-spacing: 0.11px;
          word-spacing: 0.07px;
        }
        #t2l_2 {
          left: 290px;
          bottom: 249px;
          letter-spacing: 0.17px;
        }
        #t2m_2 {
          left: 420px;
          bottom: 249px;
          letter-spacing: 0.11px;
        }
        #t2n_2 {
          left: 550px;
          bottom: 249px;
          letter-spacing: 0.17px;
        }
        #t2o_2 {
          left: 138px;
          bottom: 228px;
          letter-spacing: 0.12px;
          word-spacing: 0.05px;
        }
        #t2p_2 {
          left: 138px;
          bottom: 210px;
          letter-spacing: 0.19px;
          word-spacing: 0.02px;
        }
        #t2q_2 {
          left: 138px;
          bottom: 191px;
          letter-spacing: 0.15px;
          word-spacing: 0.08px;
        }
        #t2r_2 {
          left: 398px;
          bottom: 168px;
          letter-spacing: 0.07px;
        }
        #t2s_2 {
          left: 74px;
          bottom: 148px;
          letter-spacing: 0.17px;
        }
        #t2t_2 {
          left: 117px;
          bottom: 148px;
          letter-spacing: 0.15px;
          word-spacing: 0.04px;
        }
        #t2u_2 {
          left: 244px;
          bottom: 148px;
          letter-spacing: 0.14px;
          word-spacing: 0.07px;
        }
        #t2v_2 {
          left: 482px;
          bottom: 148px;
          letter-spacing: 0.15px;
        }
        #t2w_2 {
          left: 595px;
          bottom: 148px;
          letter-spacing: 0.13px;
        }
        #t2x_2 {
          left: 682px;
          bottom: 148px;
          letter-spacing: 0.19px;
        }
        #t2y_2 {
          left: 744px;
          bottom: 148px;
          letter-spacing: 0.14px;
        }
        #t2z_2 {
          left: 798px;
          bottom: 148px;
          letter-spacing: 0.13px;
        }
        #t30_2 {
          left: 117px;
          bottom: 126px;
          letter-spacing: 0.15px;
          word-spacing: 0.07px;
        }
        #t31_2 {
          left: 65px;
          bottom: 1152px;
          letter-spacing: 0.18px;
          word-spacing: -0.03px;
        }
        #t32_2 {
          left: 726px;
          bottom: 1061px;
          letter-spacing: 0.17px;
          word-spacing: 0.05px;
        }

        .s1_2 {
          font-size: 15px;
          font-family: sub_Helvetica-Bold_lsb;
          color: #000;
        }
        .s2_2 {
          font-size: 12px;
          font-family: sub_Helvetica_lsr;
          color: #000;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts2" type="text/css">
        @font-face {
          font-family: sub_Helvetica-Bold_lsb;
          src: url("fonts/sub_Helvetica-Bold_lsb.woff") format("woff");
        }

        @font-face {
          font-family: sub_Helvetica_lsr;
          src: url("fonts/sub_Helvetica_lsr.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg2Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg2" style="-webkit-user-select: none">
        <object
          width="909"
          height="1286"
          data="2/2.svg"
          type="image/svg+xml"
          id="pdf2"
          style="
            width: 909px;
            height: 1286px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_2" class="t s1_2">Pos. </span
        ><span id="t2_2" class="t s1_2">Item no./Rev no. </span
        ><span id="t3_2" class="t s1_2">Description </span
        ><span id="t4_2" class="t s1_2">Date of </span>
        <span id="t5_2" class="t s1_2">delivery </span>
        <span id="t6_2" class="t s1_2">Unit </span
        ><span id="t7_2" class="t s1_2">UN </span
        ><span id="t8_2" class="t s1_2">Pos amount </span>
        <span id="t9_2" class="t s1_2">Supp. item no. </span
        ><span id="ta_2" class="t s1_2">Quotation number </span>
        <span id="tb_2" class="t s2_2">00010 </span
        ><span id="tc_2" class="t s2_2">130.0350.1 / 01 </span
        ><span id="td_2" class="t s2_2">MAX. MOTOR 42V 90W PLAN. GEAR. </span>
        <span id="te_2" class="t s2_2">14:1 </span>
        <span id="tf_2" class="t s2_2">23.12.2022 </span
        ><span id="tg_2" class="t s2_2">36 </span
        ><span id="th_2" class="t s2_2">PC </span
        ><span id="ti_2" class="t s2_2">7.419,60 </span
        ><span id="tj_2" class="t s2_2">CHF </span>
        <span id="tk_2" class="t s2_2">166131=118778+1661 </span>
        <span id="tl_2" class="t s2_2">58, MAXON </span>
        <span id="tm_2" class="t s2_2">166131 </span>
        <span id="tn_2" class="t s2_2">Net price / </span
        ><span id="to_2" class="t s2_2">1PC </span
        ><span id="tp_2" class="t s2_2">206,10 </span
        ><span id="tq_2" class="t s2_2">CHF </span>
        <span id="tr_2" class="t s2_2">Quantity Contract No. </span
        ><span id="ts_2" class="t s2_2">4600018685 </span
        ><span id="tt_2" class="t s2_2">Position </span
        ><span id="tu_2" class="t s2_2">00010 </span>
        <span id="tv_2" class="t s2_2">Estimate 1st call off Dec'22 </span>
        <span id="tw_2" class="t s2_2">LT: 6-8 weeks </span>
        <span id="tx_2" class="t s1_2">related documents: </span>
        <span id="ty_2" class="t s2_2">PLM Document </span
        ><span id="tz_2" class="t s2_2">ZE5 </span
        ><span id="t10_2" class="t s2_2">130.0350.1 </span
        ><span id="t11_2" class="t s2_2">01 </span
        ><span id="t12_2" class="t s2_2">Max.Mo 42V 90W Plan.Getr. 14:1 </span>
        <span id="t13_2" class="t s1_2">---***--- </span>
        <span id="t14_2" class="t s2_2">00030 </span
        ><span id="t15_2" class="t s2_2">220.0875.1 / 02 </span
        ><span id="t16_2" class="t s2_2">ENCODERKABEL ZU 3409 85MM </span
        ><span id="t17_2" class="t s2_2">23.12.2022 </span
        ><span id="t18_2" class="t s2_2">5 </span
        ><span id="t19_2" class="t s2_2">PC </span
        ><span id="t1a_2" class="t s2_2">164,50 </span
        ><span id="t1b_2" class="t s2_2">CHF </span>
        <span id="t1c_2" class="t s2_2">M96286 </span>
        <span id="t1d_2" class="t s2_2">M96286 </span>
        <span id="t1e_2" class="t s2_2">Net price / </span
        ><span id="t1f_2" class="t s2_2">1PC </span
        ><span id="t1g_2" class="t s2_2">32,90 </span
        ><span id="t1h_2" class="t s2_2">CHF </span>
        <span id="t1i_2" class="t s1_2">related documents: </span>
        <span id="t1j_2" class="t s2_2">PLM Document </span
        ><span id="t1k_2" class="t s2_2">ZE5 </span
        ><span id="t1l_2" class="t s2_2">220.0875.1 </span
        ><span id="t1m_2" class="t s2_2">02 </span
        ><span id="t1n_2" class="t s2_2">ENCODERKABEL ZU 3409 85MM </span>
        <span id="t1o_2" class="t s1_2">---***--- </span>
        <span id="t1p_2" class="t s2_2">00040 </span
        ><span id="t1q_2" class="t s2_2">32109109/A </span
        ><span id="t1r_2" class="t s2_2">FEEDER/PUSHER MOTOR </span
        ><span id="t1s_2" class="t s2_2">23.12.2022 </span
        ><span id="t1t_2" class="t s2_2">10 </span
        ><span id="t1u_2" class="t s2_2">PC </span
        ><span id="t1v_2" class="t s2_2">2.748,00 </span
        ><span id="t1w_2" class="t s2_2">CHF </span>
        <span id="t1x_2" class="t s2_2">MAXON:249831 </span>
        <span id="t1y_2" class="t s2_2">(236647+166161) </span>
        <span id="t1z_2" class="t s2_2">Net price / </span
        ><span id="t20_2" class="t s2_2">1PC </span
        ><span id="t21_2" class="t s2_2">274,80 </span
        ><span id="t22_2" class="t s2_2">CHF </span>
        <span id="t23_2" class="t s2_2">Consist: 236647 + 166161 </span>
        <span id="t24_2" class="t s2_2">Quotation:QUO-2018-60407-T7G0Q9 </span>
        <span id="t25_2" class="t s1_2">---***--- </span>
        <span id="t26_2" class="t s2_2">00050 </span
        ><span id="t27_2" class="t s2_2">5002-8912 / 00 </span
        ><span id="t28_2" class="t s2_2">DC-MOTOR ASSY MAXON EC 22 20W </span
        ><span id="t29_2" class="t s2_2">23.12.2022 </span
        ><span id="t2a_2" class="t s2_2">20 </span
        ><span id="t2b_2" class="t s2_2">PC </span
        ><span id="t2c_2" class="t s2_2">4.603,00 </span
        ><span id="t2d_2" class="t s2_2">CHF </span>
        <span id="t2e_2" class="t s2_2">544346 </span>
        <span id="t2f_2" class="t s2_2">544346 </span>
        <span id="t2g_2" class="t s2_2">Net price / </span
        ><span id="t2h_2" class="t s2_2">1PC </span
        ><span id="t2i_2" class="t s2_2">230,15 </span
        ><span id="t2j_2" class="t s2_2">CHF </span>
        <span id="t2k_2" class="t s2_2">Quantity Contract No. </span
        ><span id="t2l_2" class="t s2_2">4600018347 </span
        ><span id="t2m_2" class="t s2_2">Position </span
        ><span id="t2n_2" class="t s2_2">00010 </span>
        <span id="t2o_2" class="t s2_2">consists of: </span>
        <span id="t2p_2" class="t s2_2"
          >169008 EC motor EC22 BL Y 20W KL 1WE A
        </span>
        <span id="t2q_2" class="t s2_2"
          >143295 tacho ENC HEDL 5560 1000IMP 2K
        </span>
        <span id="t2r_2" class="t s1_2">---***--- </span>
        <span id="t2s_2" class="t s2_2">00060 </span
        ><span id="t2t_2" class="t s2_2">5005-3004 / 00 </span
        ><span id="t2u_2" class="t s2_2">MOTOR , DC MAX22 + GEAR GP22A </span
        ><span id="t2v_2" class="t s2_2">23.12.2022 </span
        ><span id="t2w_2" class="t s2_2">10 </span
        ><span id="t2x_2" class="t s2_2">PC </span
        ><span id="t2y_2" class="t s2_2">1.157,50 </span
        ><span id="t2z_2" class="t s2_2">CHF </span>
        <span id="t30_2" class="t s2_2">MAXON MPN#625621 </span>
        <span id="t31_2" class="t s1_2">Purchase order no 4500765303 </span>
        <span id="t32_2" class="t s1_2">page: 2 / 4 </span>
      </div>
      <!-- End text definitions -->
    </div>

    <div
      id="p3"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1286px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_3 {
          left: 74px;
          bottom: 1014px;
          letter-spacing: 0.18px;
        }
        #t2_3 {
          left: 117px;
          bottom: 1014px;
          letter-spacing: 0.12px;
          word-spacing: 0.01px;
        }
        #t3_3 {
          left: 244px;
          bottom: 1014px;
          letter-spacing: 0.15px;
        }
        #t4_3 {
          left: 482px;
          bottom: 1014px;
          letter-spacing: 0.14px;
        }
        #t5_3 {
          left: 482px;
          bottom: 996px;
          letter-spacing: 0.12px;
        }
        #t6_3 {
          left: 595px;
          bottom: 1014px;
          letter-spacing: 0.13px;
        }
        #t7_3 {
          left: 686px;
          bottom: 1014px;
          letter-spacing: 0.17px;
        }
        #t8_3 {
          left: 744px;
          bottom: 1014px;
          letter-spacing: 0.17px;
          word-spacing: -0.01px;
        }
        #t9_3 {
          left: 117px;
          bottom: 974px;
          letter-spacing: 0.14px;
        }
        #ta_3 {
          left: 244px;
          bottom: 974px;
          letter-spacing: 0.15px;
        }
        #tb_3 {
          left: 117px;
          bottom: 953px;
          letter-spacing: 0.17px;
        }
        #tc_3 {
          left: 239px;
          bottom: 931px;
          letter-spacing: 0.09px;
          word-spacing: 0.07px;
        }
        #td_3 {
          left: 323px;
          bottom: 931px;
          letter-spacing: 0.19px;
        }
        #te_3 {
          left: 420px;
          bottom: 931px;
          letter-spacing: 0.14px;
        }
        #tf_3 {
          left: 465px;
          bottom: 931px;
          letter-spacing: 0.09px;
        }
        #tg_3 {
          left: 160px;
          bottom: 910px;
          letter-spacing: 0.11px;
          word-spacing: 0.07px;
        }
        #th_3 {
          left: 290px;
          bottom: 910px;
          letter-spacing: 0.17px;
        }
        #ti_3 {
          left: 420px;
          bottom: 910px;
          letter-spacing: 0.11px;
        }
        #tj_3 {
          left: 550px;
          bottom: 910px;
          letter-spacing: 0.17px;
        }
        #tk_3 {
          left: 138px;
          bottom: 889px;
          letter-spacing: 0.13px;
          word-spacing: 0.05px;
        }
        #tl_3 {
          left: 138px;
          bottom: 866px;
          letter-spacing: 0.15px;
        }
        #tm_3 {
          left: 750px;
          bottom: 866px;
          letter-spacing: 0.18px;
          word-spacing: -0.02px;
        }
        #tn_3 {
          left: 65px;
          bottom: 790px;
          letter-spacing: 0.08px;
          word-spacing: 0.09px;
        }
        #to_3 {
          left: 65px;
          bottom: 772px;
          letter-spacing: 0.11px;
          word-spacing: 0.1px;
        }
        #tp_3 {
          left: 65px;
          bottom: 735px;
          letter-spacing: 0.15px;
        }
        #tq_3 {
          left: 65px;
          bottom: 1152px;
          letter-spacing: 0.18px;
          word-spacing: -0.03px;
        }
        #tr_3 {
          left: 726px;
          bottom: 1061px;
          letter-spacing: 0.17px;
          word-spacing: 0.05px;
        }

        .s1_3 {
          font-size: 15px;
          font-family: sub_Helvetica-Bold_lsb;
          color: #000;
        }
        .s2_3 {
          font-size: 12px;
          font-family: sub_Helvetica_lsr;
          color: #000;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts3" type="text/css">
        @font-face {
          font-family: sub_Helvetica-Bold_lsb;
          src: url("fonts/sub_Helvetica-Bold_lsb.woff") format("woff");
        }

        @font-face {
          font-family: sub_Helvetica_lsr;
          src: url("fonts/sub_Helvetica_lsr.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg3Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg3" style="-webkit-user-select: none">
        <object
          width="909"
          height="1286"
          data="3/3.svg"
          type="image/svg+xml"
          id="pdf3"
          style="
            width: 909px;
            height: 1286px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_3" class="t s1_3">Pos. </span
        ><span id="t2_3" class="t s1_3">Item no./Rev no. </span
        ><span id="t3_3" class="t s1_3">Description </span
        ><span id="t4_3" class="t s1_3">Date of </span>
        <span id="t5_3" class="t s1_3">delivery </span>
        <span id="t6_3" class="t s1_3">Unit </span
        ><span id="t7_3" class="t s1_3">UN </span
        ><span id="t8_3" class="t s1_3">Pos amount </span>
        <span id="t9_3" class="t s1_3">Supp. item no. </span
        ><span id="ta_3" class="t s1_3">Quotation number </span>
        <span id="tb_3" class="t s2_3">625621 </span>
        <span id="tc_3" class="t s2_3">Net price / </span
        ><span id="td_3" class="t s2_3">1PC </span
        ><span id="te_3" class="t s2_3">115,75 </span
        ><span id="tf_3" class="t s2_3">CHF </span>
        <span id="tg_3" class="t s2_3">Quantity Contract No. </span
        ><span id="th_3" class="t s2_3">4600018414 </span
        ><span id="ti_3" class="t s2_3">Position </span
        ><span id="tj_3" class="t s2_3">00010 </span>
        <span id="tk_3" class="t s2_3">Estimate 1st call off Aug'22 </span>
        <span id="tl_3" class="t s1_3">Total amount </span
        ><span id="tm_3" class="t s1_3">16.092,60 CHF </span>
        <span id="tn_3" class="t s2_3"
          >Delivery Monday - Friday, strictly follow delivery date.
        </span>
        <span id="to_3" class="t s2_3"
          >All modules, material and component shall follow strictly to the
          BOM,engineering drawings and work instruction provided.
        </span>
        <span id="tp_3" class="t s2_3"
          >THIS IS A COMPUTER GENERATED DOCUMENT AND IT DOES NOT REQUIRE A
          SIGNATURE.
        </span>
        <span id="tq_3" class="t s1_3">Purchase order no 4500765303 </span>
        <span id="tr_3" class="t s1_3">page: 3 / 4 </span>
      </div>
      <!-- End text definitions -->
    </div>

    <div
      id="p4"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1286px;
      "
    >
      <!-- Begin page background -->
      <div
        id="pg4Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg4" style="-webkit-user-select: none">
        <object
          width="909"
          height="1286"
          data="4/4.svg"
          type="image/svg+xml"
          id="pdf4"
          style="
            width: 909px;
            height: 1286px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->
    </div>
  </body>
</html>

  `,
  '84231-20230223_4500773830_BESI_rev1': `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
    <meta charset="utf-8" />
  </head>

  <body style="margin: 0">
    <div
      id="p1"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1286px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_1 {
          left: 87px;
          bottom: 1021px;
          letter-spacing: 0.16px;
        }
        #t2_1 {
          left: 87px;
          bottom: 1003px;
          letter-spacing: 0.13px;
          word-spacing: 0.01px;
        }
        #t3_1 {
          left: 87px;
          bottom: 985px;
          letter-spacing: 0.12px;
        }
        #t4_1 {
          left: 87px;
          bottom: 966px;
          letter-spacing: 0.15px;
          word-spacing: 0.02px;
        }
        #t5_1 {
          left: 774px;
          bottom: 741px;
          letter-spacing: 0.12px;
          word-spacing: 0.05px;
        }
        #t6_1 {
          left: 87px;
          bottom: 699px;
          letter-spacing: 0.12px;
        }
        #t7_1 {
          left: 238px;
          bottom: 704px;
          letter-spacing: 0.11px;
        }
        #t8_1 {
          left: 507px;
          bottom: 699px;
          letter-spacing: 0.11px;
        }
        #t9_1 {
          left: 658px;
          bottom: 704px;
          letter-spacing: 0.11px;
        }
        #ta_1 {
          left: 87px;
          bottom: 678px;
          letter-spacing: 0.1px;
        }
        #tb_1 {
          left: 238px;
          bottom: 683px;
          letter-spacing: 0.11px;
        }
        #tc_1 {
          left: 507px;
          bottom: 678px;
          letter-spacing: 0.12px;
        }
        #td_1 {
          left: 658px;
          bottom: 683px;
          letter-spacing: 0.11px;
        }
        #te_1 {
          left: 87px;
          bottom: 656px;
          letter-spacing: 0.12px;
        }
        #tf_1 {
          left: 238px;
          bottom: 662px;
          letter-spacing: 0.11px;
        }
        #tg_1 {
          left: 507px;
          bottom: 656px;
          letter-spacing: 0.11px;
        }
        #th_1 {
          left: 658px;
          bottom: 662px;
          letter-spacing: 0.09px;
        }
        #ti_1 {
          left: 689px;
          bottom: 662px;
          letter-spacing: 0.1px;
        }
        #tj_1 {
          left: 87px;
          bottom: 635px;
          letter-spacing: 0.11px;
        }
        #tk_1 {
          left: 238px;
          bottom: 640px;
          letter-spacing: 0.12px;
        }
        #tl_1 {
          left: 507px;
          bottom: 635px;
          letter-spacing: 0.12px;
        }
        #tm_1 {
          left: 658px;
          bottom: 640px;
          letter-spacing: 0.09px;
          word-spacing: 0.01px;
        }
        #tn_1 {
          left: 87px;
          bottom: 613px;
          letter-spacing: 0.11px;
        }
        #to_1 {
          left: 238px;
          bottom: 619px;
          letter-spacing: 0.13px;
        }
        #tp_1 {
          left: 507px;
          bottom: 613px;
          letter-spacing: 0.11px;
        }
        #tq_1 {
          left: 658px;
          bottom: 619px;
          letter-spacing: 0.11px;
        }
        #tr_1 {
          left: 87px;
          bottom: 592px;
          letter-spacing: 0.11px;
          word-spacing: 0.01px;
        }
        #ts_1 {
          left: 238px;
          bottom: 597px;
          letter-spacing: 0.11px;
        }
        #tt_1 {
          left: 87px;
          bottom: 533px;
          letter-spacing: 0.13px;
          word-spacing: 0.01px;
        }
        #tu_1 {
          left: 87px;
          bottom: 514px;
          letter-spacing: 0.12px;
          word-spacing: 0.02px;
        }
        #tv_1 {
          left: 87px;
          bottom: 496px;
          letter-spacing: 0.14px;
        }
        #tw_1 {
          left: 87px;
          bottom: 478px;
          letter-spacing: 0.12px;
          word-spacing: 0.01px;
        }
        #tx_1 {
          left: 87px;
          bottom: 459px;
          letter-spacing: 0.12px;
          word-spacing: 0.01px;
        }
        #ty_1 {
          left: 87px;
          bottom: 441px;
          letter-spacing: 0.12px;
        }
        #tz_1 {
          left: 87px;
          bottom: 423px;
          letter-spacing: 0.16px;
          word-spacing: 0.01px;
        }
        #t10_1 {
          left: 87px;
          bottom: 404px;
          letter-spacing: 0.16px;
        }
        #t11_1 {
          left: 95px;
          bottom: 327px;
          letter-spacing: 0.22px;
          word-spacing: 0.02px;
        }
        #t12_1 {
          left: 543px;
          bottom: 327px;
          letter-spacing: 0.22px;
        }
        #t13_1 {
          left: 87px;
          bottom: 287px;
          letter-spacing: 0.15px;
          word-spacing: 0.01px;
        }
        #t14_1 {
          left: 279px;
          bottom: 287px;
          letter-spacing: 0.16px;
          word-spacing: -0.01px;
        }
        #t15_1 {
          left: 544px;
          bottom: 287px;
          letter-spacing: 0.16px;
        }
        #t16_1 {
          left: 87px;
          bottom: 271px;
          letter-spacing: 0.1px;
        }
        #t17_1 {
          left: 6px;
          bottom: 0px;
          letter-spacing: 0.11px;
        }

        .s1_1 {
          font-size: 15px;
          font-family: Helvetica_1a;
          color: #000;
        }
        .s2_1 {
          font-size: 15px;
          font-family: Helvetica-Bold_1b;
          color: #000;
        }
        .s3_1 {
          font-size: 12px;
          font-family: Helvetica-Bold_1b;
          color: #000;
        }
        .s4_1 {
          font-size: 12px;
          font-family: Helvetica_1a;
          color: #000;
        }
        .s5_1 {
          font-size: 24px;
          font-family: Helvetica-Bold_1b;
          color: #000;
        }
        .s6_1 {
          font-size: 18px;
          font-family: Helvetica-Bold_1b;
          color: #000;
        }
        .s7_1 {
          font-size: 12px;
          font-family: Helvetica, Arial, sans-serif;
          color: #000;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts1" type="text/css">
        @font-face {
          font-family: Helvetica-Bold_1b;
          src: url("fonts/Helvetica-Bold_1b.woff") format("woff");
        }

        @font-face {
          font-family: Helvetica_1a;
          src: url("fonts/Helvetica_1a.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg1Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg1" style="-webkit-user-select: none">
        <object
          width="909"
          height="1286"
          data="1/1.svg"
          type="image/svg+xml"
          id="pdf1"
          style="
            width: 909px;
            height: 1286px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_1" class="t s1_1">Company </span>
        <span id="t2_1" class="t s1_1">Maxon Switzerland AG </span>
        <span id="t3_1" class="t s1_1">Industriestrasse 23 </span>
        <span id="t4_1" class="t s1_1">CH-6055 ALPNACH DORF </span>
        <span id="t5_1" class="t s2_1">page: 1 / 4 </span>
        <span id="t6_1" class="t s3_1">Date </span>
        <span id="t7_1" class="t s4_1">29.01.2023 </span>
        <span id="t8_1" class="t s3_1">Our reference </span>
        <span id="t9_1" class="t s4_1">Renuga-Devi </span>
        <span id="ta_1" class="t s3_1">General line / Ext. </span>
        <span id="tb_1" class="t s4_1">+60355147777/1605 </span>
        <span id="tc_1" class="t s3_1">Fax </span>
        <span id="td_1" class="t s4_1">+60351919632 </span>
        <span id="te_1" class="t s3_1">E-mail </span>
        <span id="tf_1" class="t s4_1">renuga-devi.r-ramasamy@besi.com </span>
        <span id="tg_1" class="t s3_1">Delivery terms </span>
        <span id="th_1" class="t s4_1">FCA </span
        ><span id="ti_1" class="t s4_1">Sachsein </span>
        <span id="tj_1" class="t s3_1">Delivery method </span>
        <span id="tk_1" class="t s4_1">DHL </span>
        <span id="tl_1" class="t s3_1">Payment terms </span>
        <span id="tm_1" class="t s4_1">Within 60 days due net </span>
        <span id="tn_1" class="t s3_1">Currency </span>
        <span id="to_1" class="t s4_1">CHF </span>
        <span id="tp_1" class="t s3_1">Supplier No </span>
        <span id="tq_1" class="t s4_1">302985 </span>
        <span id="tr_1" class="t s3_1">Our VAT # </span>
        <span id="ts_1" class="t s4_1">000212254720 </span>
        <span id="tt_1" class="t s2_1">Delivery Address: </span>
        <span id="tu_1" class="t s1_1">Besi APac Sdn Bhd </span>
        <span id="tv_1" class="t s1_1">@Glenmarie Site </span>
        <span id="tw_1" class="t s1_1"
          >No 22 &amp; 24, Jalan Pemaju U1/15,
        </span>
        <span id="tx_1" class="t s1_1">Section U1 </span>
        <span id="ty_1" class="t s1_1">Glenmarie Hicom Industrial Park </span>
        <span id="tz_1" class="t s1_1">40150 SHAH ALAM-SELANGOR </span>
        <span id="t10_1" class="t s1_1">MALAYSIA </span>
        <span id="t11_1" class="t s5_1">Purchase order no </span
        ><span id="t12_1" class="t s5_1">4500773830 </span>
        <span id="t13_1" class="t s6_1">We expect your order </span
        ><span id="t14_1" class="t s6_1">confirmation within the next 3 </span
        ><span id="t15_1" class="t s6_1">days! </span>
        <span id="t16_1" class="t s4_1"
          >The mentioned delivery date = The expected arrival date at BESI APac
          SDN. BHD.
        </span>
        <span id="t17_1" class="t s7_1"
          >Printed: 2023-04-11 11:01:11 Document: 9982502/en/01/Released
        </span>
      </div>
      <!-- End text definitions -->
    </div>

    <div
      id="p2"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1286px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_2 {
          left: 74px;
          bottom: 1014px;
          letter-spacing: 0.13px;
        }
        #t2_2 {
          left: 117px;
          bottom: 1014px;
          letter-spacing: 0.13px;
        }
        #t3_2 {
          left: 244px;
          bottom: 1014px;
          letter-spacing: 0.14px;
        }
        #t4_2 {
          left: 482px;
          bottom: 1014px;
          letter-spacing: 0.13px;
          word-spacing: 0.01px;
        }
        #t5_2 {
          left: 482px;
          bottom: 996px;
          letter-spacing: 0.13px;
        }
        #t6_2 {
          left: 595px;
          bottom: 1014px;
          letter-spacing: 0.13px;
        }
        #t7_2 {
          left: 686px;
          bottom: 1014px;
          letter-spacing: 0.17px;
        }
        #t8_2 {
          left: 744px;
          bottom: 1014px;
          letter-spacing: 0.15px;
          word-spacing: 0.01px;
        }
        #t9_2 {
          left: 117px;
          bottom: 974px;
          letter-spacing: 0.14px;
        }
        #ta_2 {
          left: 244px;
          bottom: 974px;
          letter-spacing: 0.15px;
        }
        #tb_2 {
          left: 74px;
          bottom: 953px;
          letter-spacing: 0.11px;
        }
        #tc_2 {
          left: 117px;
          bottom: 953px;
          letter-spacing: 0.1px;
          word-spacing: 0.01px;
        }
        #td_2 {
          left: 244px;
          bottom: 953px;
          letter-spacing: 0.14px;
        }
        #te_2 {
          left: 482px;
          bottom: 953px;
          letter-spacing: 0.11px;
        }
        #tf_2 {
          left: 595px;
          bottom: 953px;
          letter-spacing: 0.07px;
        }
        #tg_2 {
          left: 682px;
          bottom: 953px;
          letter-spacing: 0.13px;
        }
        #th_2 {
          left: 744px;
          bottom: 953px;
          letter-spacing: 0.09px;
        }
        #ti_2 {
          left: 798px;
          bottom: 953px;
          letter-spacing: 0.13px;
        }
        #tj_2 {
          left: 117px;
          bottom: 931px;
          letter-spacing: 0.12px;
        }
        #tk_2 {
          left: 117px;
          bottom: 910px;
          letter-spacing: 0.11px;
        }
        #tl_2 {
          left: 239px;
          bottom: 889px;
          letter-spacing: 0.09px;
        }
        #tm_2 {
          left: 336px;
          bottom: 889px;
          letter-spacing: 0.13px;
        }
        #tn_2 {
          left: 420px;
          bottom: 889px;
          letter-spacing: 0.09px;
        }
        #to_2 {
          left: 464px;
          bottom: 889px;
          letter-spacing: 0.09px;
        }
        #tp_2 {
          left: 239px;
          bottom: 867px;
          letter-spacing: 0.08px;
        }
        #tq_2 {
          left: 322px;
          bottom: 867px;
          letter-spacing: 0.13px;
        }
        #tr_2 {
          left: 420px;
          bottom: 867px;
          letter-spacing: 0.09px;
        }
        #ts_2 {
          left: 464px;
          bottom: 867px;
          letter-spacing: 0.09px;
        }
        #tt_2 {
          left: 160px;
          bottom: 846px;
          letter-spacing: 0.1px;
        }
        #tu_2 {
          left: 290px;
          bottom: 846px;
          letter-spacing: 0.11px;
        }
        #tv_2 {
          left: 420px;
          bottom: 846px;
          letter-spacing: 0.09px;
        }
        #tw_2 {
          left: 550px;
          bottom: 846px;
          letter-spacing: 0.11px;
        }
        #tx_2 {
          left: 138px;
          bottom: 824px;
          letter-spacing: 0.11px;
          word-spacing: -0.12px;
        }
        #ty_2 {
          left: 138px;
          bottom: 806px;
          letter-spacing: 0.1px;
        }
        #tz_2 {
          left: 138px;
          bottom: 783px;
          letter-spacing: 0.14px;
        }
        #t10_2 {
          left: 138px;
          bottom: 763px;
          letter-spacing: 0.12px;
          word-spacing: 0.01px;
        }
        #t11_2 {
          left: 312px;
          bottom: 763px;
          letter-spacing: 0.08px;
        }
        #t12_2 {
          left: 354px;
          bottom: 763px;
          letter-spacing: 0.1px;
        }
        #t13_2 {
          left: 436px;
          bottom: 763px;
          letter-spacing: 0.07px;
        }
        #t14_2 {
          left: 470px;
          bottom: 763px;
          letter-spacing: 0.13px;
        }
        #t15_2 {
          left: 398px;
          bottom: 703px;
          letter-spacing: 0.12px;
        }
        #t16_2 {
          left: 74px;
          bottom: 682px;
          letter-spacing: 0.11px;
        }
        #t17_2 {
          left: 117px;
          bottom: 682px;
          letter-spacing: 0.1px;
          word-spacing: 0.01px;
        }
        #t18_2 {
          left: 244px;
          bottom: 682px;
          letter-spacing: 0.16px;
        }
        #t19_2 {
          left: 482px;
          bottom: 682px;
          letter-spacing: 0.11px;
        }
        #t1a_2 {
          left: 595px;
          bottom: 682px;
        }
        #t1b_2 {
          left: 682px;
          bottom: 682px;
          letter-spacing: 0.13px;
        }
        #t1c_2 {
          left: 744px;
          bottom: 682px;
          letter-spacing: 0.09px;
        }
        #t1d_2 {
          left: 788px;
          bottom: 682px;
          letter-spacing: 0.13px;
        }
        #t1e_2 {
          left: 117px;
          bottom: 661px;
          letter-spacing: 0.11px;
        }
        #t1f_2 {
          left: 117px;
          bottom: 640px;
          letter-spacing: 0.11px;
        }
        #t1g_2 {
          left: 239px;
          bottom: 618px;
          letter-spacing: 0.09px;
        }
        #t1h_2 {
          left: 336px;
          bottom: 618px;
          letter-spacing: 0.13px;
        }
        #t1i_2 {
          left: 420px;
          bottom: 618px;
          letter-spacing: 0.09px;
        }
        #t1j_2 {
          left: 464px;
          bottom: 618px;
          letter-spacing: 0.09px;
        }
        #t1k_2 {
          left: 239px;
          bottom: 597px;
          letter-spacing: 0.08px;
        }
        #t1l_2 {
          left: 322px;
          bottom: 597px;
          letter-spacing: 0.13px;
        }
        #t1m_2 {
          left: 420px;
          bottom: 597px;
          letter-spacing: 0.09px;
        }
        #t1n_2 {
          left: 464px;
          bottom: 597px;
          letter-spacing: 0.09px;
        }
        #t1o_2 {
          left: 160px;
          bottom: 575px;
          letter-spacing: 0.1px;
        }
        #t1p_2 {
          left: 290px;
          bottom: 575px;
          letter-spacing: 0.11px;
        }
        #t1q_2 {
          left: 420px;
          bottom: 575px;
          letter-spacing: 0.09px;
        }
        #t1r_2 {
          left: 550px;
          bottom: 575px;
          letter-spacing: 0.11px;
        }
        #t1s_2 {
          left: 138px;
          bottom: 554px;
          letter-spacing: 0.13px;
          word-spacing: -0.11px;
        }
        #t1t_2 {
          left: 138px;
          bottom: 531px;
          letter-spacing: 0.14px;
        }
        #t1u_2 {
          left: 138px;
          bottom: 511px;
          letter-spacing: 0.12px;
          word-spacing: 0.01px;
        }
        #t1v_2 {
          left: 312px;
          bottom: 511px;
          letter-spacing: 0.08px;
        }
        #t1w_2 {
          left: 354px;
          bottom: 511px;
          letter-spacing: 0.1px;
        }
        #t1x_2 {
          left: 433px;
          bottom: 511px;
          letter-spacing: 0.07px;
        }
        #t1y_2 {
          left: 467px;
          bottom: 511px;
          letter-spacing: 0.13px;
          word-spacing: -0.02px;
        }
        #t1z_2 {
          left: 398px;
          bottom: 451px;
          letter-spacing: 0.12px;
        }
        #t20_2 {
          left: 74px;
          bottom: 430px;
          letter-spacing: 0.11px;
        }
        #t21_2 {
          left: 117px;
          bottom: 430px;
          letter-spacing: 0.1px;
        }
        #t22_2 {
          left: 244px;
          bottom: 430px;
          letter-spacing: 0.11px;
          word-spacing: -0.11px;
        }
        #t23_2 {
          left: 595px;
          bottom: 430px;
          letter-spacing: 0.07px;
        }
        #t24_2 {
          left: 682px;
          bottom: 430px;
          letter-spacing: 0.13px;
        }
        #t25_2 {
          left: 744px;
          bottom: 430px;
          letter-spacing: 0.09px;
        }
        #t26_2 {
          left: 798px;
          bottom: 430px;
          letter-spacing: 0.13px;
        }
        #t27_2 {
          left: 117px;
          bottom: 409px;
          letter-spacing: 0.11px;
        }
        #t28_2 {
          left: 244px;
          bottom: 409px;
          letter-spacing: 0.11px;
        }
        #t29_2 {
          left: 117px;
          bottom: 388px;
          letter-spacing: 0.11px;
        }
        #t2a_2 {
          left: 239px;
          bottom: 366px;
          letter-spacing: 0.09px;
        }
        #t2b_2 {
          left: 336px;
          bottom: 366px;
          letter-spacing: 0.13px;
        }
        #t2c_2 {
          left: 420px;
          bottom: 366px;
          letter-spacing: 0.09px;
        }
        #t2d_2 {
          left: 464px;
          bottom: 366px;
          letter-spacing: 0.09px;
        }
        #t2e_2 {
          left: 239px;
          bottom: 345px;
          letter-spacing: 0.08px;
        }
        #t2f_2 {
          left: 322px;
          bottom: 345px;
          letter-spacing: 0.13px;
        }
        #t2g_2 {
          left: 420px;
          bottom: 345px;
          letter-spacing: 0.09px;
        }
        #t2h_2 {
          left: 464px;
          bottom: 345px;
          letter-spacing: 0.09px;
        }
        #t2i_2 {
          left: 138px;
          bottom: 322px;
          letter-spacing: 0.14px;
        }
        #t2j_2 {
          left: 138px;
          bottom: 301px;
          letter-spacing: 0.12px;
          word-spacing: 0.01px;
        }
        #t2k_2 {
          left: 312px;
          bottom: 301px;
          letter-spacing: 0.08px;
        }
        #t2l_2 {
          left: 354px;
          bottom: 301px;
          letter-spacing: 0.1px;
        }
        #t2m_2 {
          left: 436px;
          bottom: 301px;
          letter-spacing: 0.07px;
        }
        #t2n_2 {
          left: 470px;
          bottom: 301px;
          letter-spacing: 0.13px;
          word-spacing: -0.05px;
        }
        #t2o_2 {
          left: 138px;
          bottom: 280px;
          letter-spacing: 0.12px;
          word-spacing: 0.01px;
        }
        #t2p_2 {
          left: 312px;
          bottom: 280px;
          letter-spacing: 0.08px;
        }
        #t2q_2 {
          left: 354px;
          bottom: 280px;
          letter-spacing: 0.1px;
        }
        #t2r_2 {
          left: 436px;
          bottom: 280px;
          letter-spacing: 0.07px;
        }
        #t2s_2 {
          left: 470px;
          bottom: 280px;
          letter-spacing: 0.13px;
          word-spacing: -0.05px;
        }
        #t2t_2 {
          left: 398px;
          bottom: 220px;
          letter-spacing: 0.12px;
        }
        #t2u_2 {
          left: 74px;
          bottom: 200px;
          letter-spacing: 0.11px;
        }
        #t2v_2 {
          left: 117px;
          bottom: 200px;
          letter-spacing: 0.1px;
        }
        #t2w_2 {
          left: 244px;
          bottom: 200px;
          letter-spacing: 0.12px;
          word-spacing: 0.01px;
        }
        #t2x_2 {
          left: 244px;
          bottom: 181px;
          letter-spacing: 0.11px;
        }
        #t2y_2 {
          left: 482px;
          bottom: 200px;
          letter-spacing: 0.11px;
        }
        #t2z_2 {
          left: 595px;
          bottom: 200px;
          letter-spacing: 0.07px;
        }
        #t30_2 {
          left: 682px;
          bottom: 200px;
          letter-spacing: 0.13px;
        }
        #t31_2 {
          left: 744px;
          bottom: 200px;
          letter-spacing: 0.09px;
        }
        #t32_2 {
          left: 798px;
          bottom: 200px;
          letter-spacing: 0.13px;
        }
        #t33_2 {
          left: 117px;
          bottom: 160px;
          letter-spacing: 0.11px;
        }
        #t34_2 {
          left: 117px;
          bottom: 142px;
          letter-spacing: 0.13px;
        }
        #t35_2 {
          left: 117px;
          bottom: 120px;
          letter-spacing: 0.11px;
        }
        #t36_2 {
          left: 65px;
          bottom: 1152px;
          letter-spacing: 0.13px;
          word-spacing: 0.01px;
        }
        #t37_2 {
          left: 726px;
          bottom: 1061px;
          letter-spacing: 0.12px;
          word-spacing: 0.09px;
        }
        #t38_2 {
          left: 6px;
          bottom: 0px;
          letter-spacing: 0.11px;
        }

        .s1_2 {
          font-size: 15px;
          font-family: Helvetica-Bold_1b;
          color: #000;
        }
        .s2_2 {
          font-size: 12px;
          font-family: Helvetica_1a;
          color: #000;
        }
        .s3_2 {
          font-size: 12px;
          font-family: Helvetica, Arial, sans-serif;
          color: #000;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts2" type="text/css">
        @font-face {
          font-family: Helvetica-Bold_1b;
          src: url("fonts/Helvetica-Bold_1b.woff") format("woff");
        }

        @font-face {
          font-family: Helvetica_1a;
          src: url("fonts/Helvetica_1a.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg2Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg2" style="-webkit-user-select: none">
        <object
          width="909"
          height="1286"
          data="2/2.svg"
          type="image/svg+xml"
          id="pdf2"
          style="
            width: 909px;
            height: 1286px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_2" class="t s1_2">Pos. </span
        ><span id="t2_2" class="t s1_2">Item no./Rev no. </span
        ><span id="t3_2" class="t s1_2">Description </span
        ><span id="t4_2" class="t s1_2">Date of </span>
        <span id="t5_2" class="t s1_2">delivery </span>
        <span id="t6_2" class="t s1_2">Unit </span
        ><span id="t7_2" class="t s1_2">UN </span
        ><span id="t8_2" class="t s1_2">Pos amount </span>
        <span id="t9_2" class="t s1_2">Supp. item no. </span
        ><span id="ta_2" class="t s1_2">Quotation number </span>
        <span id="tb_2" class="t s2_2">00010 </span
        ><span id="tc_2" class="t s2_2">102389014 / 01 </span
        ><span id="td_2" class="t s2_2">MOTOR MAXON 383934 </span
        ><span id="te_2" class="t s2_2">28.04.2023 </span
        ><span id="tf_2" class="t s2_2">15 </span
        ><span id="tg_2" class="t s2_2">PC </span
        ><span id="th_2" class="t s2_2">2.925,75 </span
        ><span id="ti_2" class="t s2_2">CHF </span>
        <span id="tj_2" class="t s2_2">383934, MAXON </span>
        <span id="tk_2" class="t s2_2">383934 </span>
        <span id="tl_2" class="t s2_2">Gross price / </span
        ><span id="tm_2" class="t s2_2">1PC </span
        ><span id="tn_2" class="t s2_2">195,05 </span
        ><span id="to_2" class="t s2_2">CHF </span>
        <span id="tp_2" class="t s2_2">Net price / </span
        ><span id="tq_2" class="t s2_2">1PC </span
        ><span id="tr_2" class="t s2_2">195,05 </span
        ><span id="ts_2" class="t s2_2">CHF </span>
        <span id="tt_2" class="t s2_2">Quantity Contract No. </span
        ><span id="tu_2" class="t s2_2">4600018277 </span
        ><span id="tv_2" class="t s2_2">Position </span
        ><span id="tw_2" class="t s2_2">00010 </span>
        <span id="tx_2" class="t s2_2">Quotation#: QUO-2015-22099-K3K5N5 </span>
        <span id="ty_2" class="t s2_2">LT: 6-8 weeks </span>
        <span id="tz_2" class="t s1_2">related documents: </span>
        <span id="t10_2" class="t s2_2">PLM Document </span
        ><span id="t11_2" class="t s2_2">ZE5 </span
        ><span id="t12_2" class="t s2_2">300415461 </span
        ><span id="t13_2" class="t s2_2">02 </span
        ><span id="t14_2" class="t s2_2">MOTOR MAXON RE013MR 67:1 </span>
        <span id="t15_2" class="t s1_2">---***--- </span>
        <span id="t16_2" class="t s2_2">00020 </span
        ><span id="t17_2" class="t s2_2">1000-8332 / 02 </span
        ><span id="t18_2" class="t s2_2">DC-MOTOR </span
        ><span id="t19_2" class="t s2_2">21.04.2023 </span
        ><span id="t1a_2" class="t s2_2">5 </span
        ><span id="t1b_2" class="t s2_2">PC </span
        ><span id="t1c_2" class="t s2_2">740,25 </span
        ><span id="t1d_2" class="t s2_2">CHF </span>
        <span id="t1e_2" class="t s2_2">262079 </span>
        <span id="t1f_2" class="t s2_2">262079 </span>
        <span id="t1g_2" class="t s2_2">Gross price / </span
        ><span id="t1h_2" class="t s2_2">1PC </span
        ><span id="t1i_2" class="t s2_2">148,05 </span
        ><span id="t1j_2" class="t s2_2">CHF </span>
        <span id="t1k_2" class="t s2_2">Net price / </span
        ><span id="t1l_2" class="t s2_2">1PC </span
        ><span id="t1m_2" class="t s2_2">148,05 </span
        ><span id="t1n_2" class="t s2_2">CHF </span>
        <span id="t1o_2" class="t s2_2">Quantity Contract No. </span
        ><span id="t1p_2" class="t s2_2">4600017162 </span
        ><span id="t1q_2" class="t s2_2">Position </span
        ><span id="t1r_2" class="t s2_2">00081 </span>
        <span id="t1s_2" class="t s2_2"
          >QUOTATION: QUO-2015-22821-C7W9C4-REV06
        </span>
        <span id="t1t_2" class="t s1_2">related documents: </span>
        <span id="t1u_2" class="t s2_2">PLM Document </span
        ><span id="t1v_2" class="t s2_2">ZE5 </span
        ><span id="t1w_2" class="t s2_2">1000-8332 </span
        ><span id="t1x_2" class="t s2_2">02 </span
        ><span id="t1y_2" class="t s2_2">DC-MOTOR MAXON RE016MR 84:1 </span>
        <span id="t1z_2" class="t s1_2">---***--- </span>
        <span id="t20_2" class="t s2_2">00030 </span
        ><span id="t21_2" class="t s2_2">130.0167.1 / 01 </span
        ><span id="t22_2" class="t s2_2"
          >MOTOR RE25 / TACHO ENC22 3419 CPL. 21.04.2023 </span
        ><span id="t23_2" class="t s2_2">20 </span
        ><span id="t24_2" class="t s2_2">PC </span
        ><span id="t25_2" class="t s2_2">4.569,00 </span
        ><span id="t26_2" class="t s2_2">CHF </span>
        <span id="t27_2" class="t s2_2">135675 </span
        ><span id="t28_2" class="t s2_2">202022821C </span>
        <span id="t29_2" class="t s2_2">135675 </span>
        <span id="t2a_2" class="t s2_2">Gross price / </span
        ><span id="t2b_2" class="t s2_2">1PC </span
        ><span id="t2c_2" class="t s2_2">228,45 </span
        ><span id="t2d_2" class="t s2_2">CHF </span>
        <span id="t2e_2" class="t s2_2">Net price / </span
        ><span id="t2f_2" class="t s2_2">1PC </span
        ><span id="t2g_2" class="t s2_2">228,45 </span
        ><span id="t2h_2" class="t s2_2">CHF </span>
        <span id="t2i_2" class="t s1_2">related documents: </span>
        <span id="t2j_2" class="t s2_2">PLM Document </span
        ><span id="t2k_2" class="t s2_2">ZE5 </span
        ><span id="t2l_2" class="t s2_2">130.0167.1 </span
        ><span id="t2m_2" class="t s2_2">01 </span
        ><span id="t2n_2" class="t s2_2"
          >KOMBI MOTOR RE25+TACHO ENC22 3419
        </span>
        <span id="t2o_2" class="t s2_2">PLM Document </span
        ><span id="t2p_2" class="t s2_2">ZE5 </span
        ><span id="t2q_2" class="t s2_2">130.0167.1 </span
        ><span id="t2r_2" class="t s2_2">01 </span
        ><span id="t2s_2" class="t s2_2"
          >KOMBI MOTOR RE25+TACHO ENC22 3419
        </span>
        <span id="t2t_2" class="t s1_2">---***--- </span>
        <span id="t2u_2" class="t s2_2">00050 </span
        ><span id="t2v_2" class="t s2_2">130.0350.1 / 01 </span
        ><span id="t2w_2" class="t s2_2">MAX. MOTOR 42V 90W PLAN. GEAR. </span>
        <span id="t2x_2" class="t s2_2">14:1 </span>
        <span id="t2y_2" class="t s2_2">21.04.2023 </span
        ><span id="t2z_2" class="t s2_2">18 </span
        ><span id="t30_2" class="t s2_2">PC </span
        ><span id="t31_2" class="t s2_2">3.709,80 </span
        ><span id="t32_2" class="t s2_2">CHF </span>
        <span id="t33_2" class="t s2_2">166131=118778+1661 </span>
        <span id="t34_2" class="t s2_2">58, MAXON </span>
        <span id="t35_2" class="t s2_2">166131 </span>
        <span id="t36_2" class="t s1_2">Purchase order no 4500773830 </span>
        <span id="t37_2" class="t s1_2">page: 2 / 4 </span>
        <span id="t38_2" class="t s3_2"
          >Printed: 2023-04-11 11:01:11 Document: 9982502/en/01/Released
        </span>
      </div>
      <!-- End text definitions -->
    </div>

    <div
      id="p3"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1286px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_3 {
          left: 74px;
          bottom: 1014px;
          letter-spacing: 0.13px;
        }
        #t2_3 {
          left: 117px;
          bottom: 1014px;
          letter-spacing: 0.13px;
        }
        #t3_3 {
          left: 244px;
          bottom: 1014px;
          letter-spacing: 0.14px;
        }
        #t4_3 {
          left: 482px;
          bottom: 1014px;
          letter-spacing: 0.13px;
          word-spacing: 0.01px;
        }
        #t5_3 {
          left: 482px;
          bottom: 996px;
          letter-spacing: 0.13px;
        }
        #t6_3 {
          left: 595px;
          bottom: 1014px;
          letter-spacing: 0.13px;
        }
        #t7_3 {
          left: 686px;
          bottom: 1014px;
          letter-spacing: 0.17px;
        }
        #t8_3 {
          left: 744px;
          bottom: 1014px;
          letter-spacing: 0.15px;
          word-spacing: 0.01px;
        }
        #t9_3 {
          left: 117px;
          bottom: 974px;
          letter-spacing: 0.14px;
        }
        #ta_3 {
          left: 244px;
          bottom: 974px;
          letter-spacing: 0.15px;
        }
        #tb_3 {
          left: 239px;
          bottom: 953px;
          letter-spacing: 0.09px;
        }
        #tc_3 {
          left: 336px;
          bottom: 953px;
          letter-spacing: 0.13px;
        }
        #td_3 {
          left: 420px;
          bottom: 953px;
          letter-spacing: 0.09px;
        }
        #te_3 {
          left: 464px;
          bottom: 953px;
          letter-spacing: 0.09px;
        }
        #tf_3 {
          left: 239px;
          bottom: 931px;
          letter-spacing: 0.08px;
        }
        #tg_3 {
          left: 322px;
          bottom: 931px;
          letter-spacing: 0.13px;
        }
        #th_3 {
          left: 420px;
          bottom: 931px;
          letter-spacing: 0.09px;
        }
        #ti_3 {
          left: 464px;
          bottom: 931px;
          letter-spacing: 0.09px;
        }
        #tj_3 {
          left: 160px;
          bottom: 910px;
          letter-spacing: 0.1px;
        }
        #tk_3 {
          left: 290px;
          bottom: 910px;
          letter-spacing: 0.11px;
        }
        #tl_3 {
          left: 420px;
          bottom: 910px;
          letter-spacing: 0.09px;
        }
        #tm_3 {
          left: 550px;
          bottom: 910px;
          letter-spacing: 0.11px;
        }
        #tn_3 {
          left: 138px;
          bottom: 889px;
          letter-spacing: 0.11px;
          word-spacing: -0.01px;
        }
        #to_3 {
          left: 138px;
          bottom: 870px;
          letter-spacing: 0.1px;
        }
        #tp_3 {
          left: 138px;
          bottom: 848px;
          letter-spacing: 0.14px;
        }
        #tq_3 {
          left: 138px;
          bottom: 827px;
          letter-spacing: 0.12px;
          word-spacing: 0.01px;
        }
        #tr_3 {
          left: 312px;
          bottom: 827px;
          letter-spacing: 0.08px;
        }
        #ts_3 {
          left: 354px;
          bottom: 827px;
          letter-spacing: 0.1px;
        }
        #tt_3 {
          left: 436px;
          bottom: 827px;
          letter-spacing: 0.07px;
        }
        #tu_3 {
          left: 470px;
          bottom: 827px;
          letter-spacing: 0.11px;
          word-spacing: -0.13px;
        }
        #tv_3 {
          left: 398px;
          bottom: 767px;
          letter-spacing: 0.12px;
        }
        #tw_3 {
          left: 74px;
          bottom: 747px;
          letter-spacing: 0.11px;
        }
        #tx_3 {
          left: 117px;
          bottom: 747px;
          letter-spacing: 0.1px;
        }
        #ty_3 {
          left: 244px;
          bottom: 747px;
          letter-spacing: 0.14px;
          word-spacing: 0.01px;
        }
        #tz_3 {
          left: 482px;
          bottom: 747px;
          letter-spacing: 0.11px;
        }
        #t10_3 {
          left: 595px;
          bottom: 747px;
          letter-spacing: 0.07px;
        }
        #t11_3 {
          left: 682px;
          bottom: 747px;
          letter-spacing: 0.13px;
        }
        #t12_3 {
          left: 744px;
          bottom: 747px;
          letter-spacing: 0.09px;
        }
        #t13_3 {
          left: 798px;
          bottom: 747px;
          letter-spacing: 0.13px;
        }
        #t14_3 {
          left: 117px;
          bottom: 725px;
          letter-spacing: 0.1px;
          word-spacing: 0.01px;
        }
        #t15_3 {
          left: 117px;
          bottom: 704px;
          letter-spacing: 0.11px;
        }
        #t16_3 {
          left: 239px;
          bottom: 682px;
          letter-spacing: 0.09px;
        }
        #t17_3 {
          left: 336px;
          bottom: 682px;
          letter-spacing: 0.13px;
        }
        #t18_3 {
          left: 420px;
          bottom: 682px;
          letter-spacing: 0.09px;
        }
        #t19_3 {
          left: 464px;
          bottom: 682px;
          letter-spacing: 0.09px;
        }
        #t1a_3 {
          left: 239px;
          bottom: 661px;
          letter-spacing: 0.08px;
        }
        #t1b_3 {
          left: 322px;
          bottom: 661px;
          letter-spacing: 0.13px;
        }
        #t1c_3 {
          left: 420px;
          bottom: 661px;
          letter-spacing: 0.09px;
        }
        #t1d_3 {
          left: 464px;
          bottom: 661px;
          letter-spacing: 0.09px;
        }
        #t1e_3 {
          left: 138px;
          bottom: 640px;
          letter-spacing: 0.09px;
          word-spacing: 0.01px;
        }
        #t1f_3 {
          left: 138px;
          bottom: 621px;
          letter-spacing: 0.12px;
        }
        #t1g_3 {
          left: 138px;
          bottom: 603px;
          letter-spacing: 0.11px;
          word-spacing: 0.01px;
        }
        #t1h_3 {
          left: 138px;
          bottom: 585px;
          letter-spacing: 0.12px;
        }
        #t1i_3 {
          left: 138px;
          bottom: 562px;
          letter-spacing: 0.14px;
        }
        #t1j_3 {
          left: 138px;
          bottom: 541px;
          letter-spacing: 0.12px;
          word-spacing: 0.01px;
        }
        #t1k_3 {
          left: 312px;
          bottom: 541px;
          letter-spacing: 0.08px;
        }
        #t1l_3 {
          left: 354px;
          bottom: 541px;
          letter-spacing: 0.1px;
        }
        #t1m_3 {
          left: 436px;
          bottom: 541px;
          letter-spacing: 0.07px;
        }
        #t1n_3 {
          left: 470px;
          bottom: 541px;
          letter-spacing: 0.14px;
          word-spacing: 0.01px;
        }
        #t1o_3 {
          left: 398px;
          bottom: 482px;
          letter-spacing: 0.12px;
        }
        #t1p_3 {
          left: 74px;
          bottom: 461px;
          letter-spacing: 0.11px;
        }
        #t1q_3 {
          left: 117px;
          bottom: 461px;
          letter-spacing: 0.1px;
          word-spacing: 0.01px;
        }
        #t1r_3 {
          left: 244px;
          bottom: 461px;
          letter-spacing: 0.13px;
          word-spacing: 0.01px;
        }
        #t1s_3 {
          left: 482px;
          bottom: 461px;
          letter-spacing: 0.11px;
        }
        #t1t_3 {
          left: 595px;
          bottom: 461px;
          letter-spacing: 0.07px;
        }
        #t1u_3 {
          left: 682px;
          bottom: 461px;
          letter-spacing: 0.13px;
        }
        #t1v_3 {
          left: 744px;
          bottom: 461px;
          letter-spacing: 0.09px;
        }
        #t1w_3 {
          left: 798px;
          bottom: 461px;
          letter-spacing: 0.13px;
        }
        #t1x_3 {
          left: 117px;
          bottom: 439px;
          letter-spacing: 0.13px;
          word-spacing: 0.01px;
        }
        #t1y_3 {
          left: 117px;
          bottom: 418px;
          letter-spacing: 0.11px;
        }
        #t1z_3 {
          left: 239px;
          bottom: 397px;
          letter-spacing: 0.09px;
        }
        #t20_3 {
          left: 336px;
          bottom: 397px;
          letter-spacing: 0.13px;
        }
        #t21_3 {
          left: 420px;
          bottom: 397px;
          letter-spacing: 0.09px;
        }
        #t22_3 {
          left: 457px;
          bottom: 397px;
          letter-spacing: 0.09px;
        }
        #t23_3 {
          left: 239px;
          bottom: 375px;
          letter-spacing: 0.08px;
        }
        #t24_3 {
          left: 322px;
          bottom: 375px;
          letter-spacing: 0.13px;
        }
        #t25_3 {
          left: 420px;
          bottom: 375px;
          letter-spacing: 0.09px;
        }
        #t26_3 {
          left: 457px;
          bottom: 375px;
          letter-spacing: 0.09px;
        }
        #t27_3 {
          left: 160px;
          bottom: 354px;
          letter-spacing: 0.1px;
        }
        #t28_3 {
          left: 290px;
          bottom: 354px;
          letter-spacing: 0.11px;
        }
        #t29_3 {
          left: 420px;
          bottom: 354px;
          letter-spacing: 0.09px;
        }
        #t2a_3 {
          left: 550px;
          bottom: 354px;
          letter-spacing: 0.11px;
        }
        #t2b_3 {
          left: 138px;
          bottom: 331px;
          letter-spacing: 0.14px;
        }
        #t2c_3 {
          left: 750px;
          bottom: 331px;
          letter-spacing: 0.12px;
        }
        #t2d_3 {
          left: 827px;
          bottom: 331px;
          letter-spacing: 0.16px;
        }
        #t2e_3 {
          left: 65px;
          bottom: 255px;
          letter-spacing: 0.09px;
          word-spacing: 0.01px;
        }
        #t2f_3 {
          left: 65px;
          bottom: 237px;
          letter-spacing: 0.09px;
          word-spacing: 0.01px;
        }
        #t2g_3 {
          left: 65px;
          bottom: 200px;
          letter-spacing: 0.12px;
          word-spacing: 0.07px;
        }
        #t2h_3 {
          left: 65px;
          bottom: 1152px;
          letter-spacing: 0.13px;
          word-spacing: 0.01px;
        }
        #t2i_3 {
          left: 726px;
          bottom: 1061px;
          letter-spacing: 0.12px;
          word-spacing: 0.09px;
        }
        #t2j_3 {
          left: 6px;
          bottom: 0px;
          letter-spacing: 0.11px;
        }

        .s1_3 {
          font-size: 15px;
          font-family: Helvetica-Bold_1b;
          color: #000;
        }
        .s2_3 {
          font-size: 12px;
          font-family: Helvetica_1a;
          color: #000;
        }
        .s3_3 {
          font-size: 12px;
          font-family: Helvetica, Arial, sans-serif;
          color: #000;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts3" type="text/css">
        @font-face {
          font-family: Helvetica-Bold_1b;
          src: url("fonts/Helvetica-Bold_1b.woff") format("woff");
        }

        @font-face {
          font-family: Helvetica_1a;
          src: url("fonts/Helvetica_1a.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg3Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg3" style="-webkit-user-select: none">
        <object
          width="909"
          height="1286"
          data="3/3.svg"
          type="image/svg+xml"
          id="pdf3"
          style="
            width: 909px;
            height: 1286px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_3" class="t s1_3">Pos. </span
        ><span id="t2_3" class="t s1_3">Item no./Rev no. </span
        ><span id="t3_3" class="t s1_3">Description </span
        ><span id="t4_3" class="t s1_3">Date of </span>
        <span id="t5_3" class="t s1_3">delivery </span>
        <span id="t6_3" class="t s1_3">Unit </span
        ><span id="t7_3" class="t s1_3">UN </span
        ><span id="t8_3" class="t s1_3">Pos amount </span>
        <span id="t9_3" class="t s1_3">Supp. item no. </span
        ><span id="ta_3" class="t s1_3">Quotation number </span>
        <span id="tb_3" class="t s2_3">Gross price / </span
        ><span id="tc_3" class="t s2_3">1PC </span
        ><span id="td_3" class="t s2_3">206,10 </span
        ><span id="te_3" class="t s2_3">CHF </span>
        <span id="tf_3" class="t s2_3">Net price / </span
        ><span id="tg_3" class="t s2_3">1PC </span
        ><span id="th_3" class="t s2_3">206,10 </span
        ><span id="ti_3" class="t s2_3">CHF </span>
        <span id="tj_3" class="t s2_3">Quantity Contract No. </span
        ><span id="tk_3" class="t s2_3">4600018685 </span
        ><span id="tl_3" class="t s2_3">Position </span
        ><span id="tm_3" class="t s2_3">00010 </span>
        <span id="tn_3" class="t s2_3">Estimate 1st call off Dec'22 </span>
        <span id="to_3" class="t s2_3">LT: 6-8 weeks </span>
        <span id="tp_3" class="t s1_3">related documents: </span>
        <span id="tq_3" class="t s2_3">PLM Document </span
        ><span id="tr_3" class="t s2_3">ZE5 </span
        ><span id="ts_3" class="t s2_3">130.0350.1 </span
        ><span id="tt_3" class="t s2_3">01 </span
        ><span id="tu_3" class="t s2_3">Max.Mo 42V 90W Plan.Getr. 14:1 </span>
        <span id="tv_3" class="t s1_3">---***--- </span>
        <span id="tw_3" class="t s2_3">00060 </span
        ><span id="tx_3" class="t s2_3">130.0403.1 / 01 </span
        ><span id="ty_3" class="t s2_3">DC-MOTOR MAXON RE015 </span
        ><span id="tz_3" class="t s2_3">21.04.2023 </span
        ><span id="t10_3" class="t s2_3">25 </span
        ><span id="t11_3" class="t s2_3">PC </span
        ><span id="t12_3" class="t s2_3">5.897,50 </span
        ><span id="t13_3" class="t s2_3">CHF </span>
        <span id="t14_3" class="t s2_3">275841 (ALT: 790733) </span>
        <span id="t15_3" class="t s2_3">275841 </span>
        <span id="t16_3" class="t s2_3">Gross price / </span
        ><span id="t17_3" class="t s2_3">1PC </span
        ><span id="t18_3" class="t s2_3">235,90 </span
        ><span id="t19_3" class="t s2_3">CHF </span>
        <span id="t1a_3" class="t s2_3">Net price / </span
        ><span id="t1b_3" class="t s2_3">1PC </span
        ><span id="t1c_3" class="t s2_3">235,90 </span
        ><span id="t1d_3" class="t s2_3">CHF </span>
        <span id="t1e_3" class="t s2_3">Consists of </span>
        <span id="t1f_3" class="t s2_3"
          >118648 DC motor RE15 EBCLL 1.6W SL 1WE
        </span>
        <span id="t1g_3" class="t s2_3"
          >201937 tacho MR 512IMP 2K 32P ASIC
        </span>
        <span id="t1h_3" class="t s2_3">Quotation:QUO-2015-22821-C7W9C4 </span>
        <span id="t1i_3" class="t s1_3">related documents: </span>
        <span id="t1j_3" class="t s2_3">PLM Document </span
        ><span id="t1k_3" class="t s2_3">ZE5 </span
        ><span id="t1l_3" class="t s2_3">130.0403.1 </span
        ><span id="t1m_3" class="t s2_3">01 </span
        ><span id="t1n_3" class="t s2_3">DC-MOTOR MAXON RE015 </span>
        <span id="t1o_3" class="t s1_3">---***--- </span>
        <span id="t1p_3" class="t s2_3">00070 </span
        ><span id="t1q_3" class="t s2_3">85500010300 / 01 </span
        ><span id="t1r_3" class="t s2_3">FLAT MOTOR EC32 </span
        ><span id="t1s_3" class="t s2_3">21.04.2023 </span
        ><span id="t1t_3" class="t s2_3">59 </span
        ><span id="t1u_3" class="t s2_3">PC </span
        ><span id="t1v_3" class="t s2_3">5.133,00 </span
        ><span id="t1w_3" class="t s2_3">CHF </span>
        <span id="t1x_3" class="t s2_3">MAXON 342863 </span>
        <span id="t1y_3" class="t s2_3">342863 </span>
        <span id="t1z_3" class="t s2_3">Gross price / </span
        ><span id="t20_3" class="t s2_3">1PC </span
        ><span id="t21_3" class="t s2_3">87,00 </span
        ><span id="t22_3" class="t s2_3">CHF </span>
        <span id="t23_3" class="t s2_3">Net price / </span
        ><span id="t24_3" class="t s2_3">1PC </span
        ><span id="t25_3" class="t s2_3">87,00 </span
        ><span id="t26_3" class="t s2_3">CHF </span>
        <span id="t27_3" class="t s2_3">Quantity Contract No. </span
        ><span id="t28_3" class="t s2_3">4600018619 </span
        ><span id="t29_3" class="t s2_3">Position </span
        ><span id="t2a_3" class="t s2_3">00010 </span>
        <span id="t2b_3" class="t s1_3">Total amount </span
        ><span id="t2c_3" class="t s1_3">22.975,30 </span
        ><span id="t2d_3" class="t s1_3">CHF </span>
        <span id="t2e_3" class="t s2_3"
          >Delivery Monday - Friday, strictly follow delivery date.
        </span>
        <span id="t2f_3" class="t s2_3"
          >All modules, material and component shall follow strictly to the
          BOM,engineering drawings and work instruction provided.
        </span>
        <span id="t2g_3" class="t s2_3"
          >THIS IS A COMPUTER GENERATED DOCUMENT AND IT DOES NOT REQUIRE A
          SIGNATURE.
        </span>
        <span id="t2h_3" class="t s1_3">Purchase order no 4500773830 </span>
        <span id="t2i_3" class="t s1_3">page: 3 / 4 </span>
        <span id="t2j_3" class="t s3_3"
          >Printed: 2023-04-11 11:01:11 Document: 9982502/en/01/Released
        </span>
      </div>
      <!-- End text definitions -->
    </div>

    <div
      id="p4"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1286px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_4 {
          left: 6px;
          bottom: 0px;
          letter-spacing: 0.11px;
        }

        .s1 {
          font-size: 12px;
          font-family: Helvetica, Arial, sans-serif;
          color: #000;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin page background -->
      <div
        id="pg4Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg4" style="-webkit-user-select: none">
        <object
          width="909"
          height="1286"
          data="4/4.svg"
          type="image/svg+xml"
          id="pdf4"
          style="
            width: 909px;
            height: 1286px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_4" class="t s1"
          >Printed: 2023-04-11 11:01:11 Document: 9982502/en/01/Released
        </span>
      </div>
      <!-- End text definitions -->
    </div>
  </body>
</html>
  `,
  2765: `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
      <meta charset="utf-8" />
    </head>
  
    <body style="margin: 0">
      <div
        id="p1"
        style="
          overflow: hidden;
          position: relative;
          background-color: white;
          width: 909px;
          height: 1287px;
        "
      >
        <!-- Begin shared CSS values -->
        <style class="shared-css" type="text/css">
          .t {
            transform-origin: bottom left;
            z-index: 2;
            position: absolute;
            white-space: pre;
            overflow: visible;
            line-height: 1.5;
          }
          .text-container {
            white-space: pre;
          }
          @supports (-webkit-touch-callout: none) {
            .text-container {
              white-space: normal;
            }
          }
        </style>
        <!-- End shared CSS values -->
  
        <!-- Begin inline CSS -->
        <style type="text/css">
          #t1_1 {
            left: 104px;
            bottom: 801px;
            letter-spacing: 0.1px;
          }
          #t2_1 {
            left: 221px;
            bottom: 801px;
            letter-spacing: 0.12px;
          }
          #t3_1 {
            left: 472px;
            bottom: 801px;
            letter-spacing: 0.11px;
          }
          #t4_1 {
            left: 104px;
            bottom: 784px;
            letter-spacing: 0.11px;
          }
          #t5_1 {
            left: 221px;
            bottom: 784px;
            letter-spacing: 0.1px;
          }
          #t6_1 {
            left: 472px;
            bottom: 784px;
            letter-spacing: 0.1px;
          }
          #t7_1 {
            left: 104px;
            bottom: 766px;
            letter-spacing: 0.12px;
          }
          #t8_1 {
            left: 221px;
            bottom: 766px;
            letter-spacing: 0.11px;
          }
          #t9_1 {
            left: 472px;
            bottom: 766px;
            letter-spacing: 0.1px;
          }
          #ta_1 {
            left: 589px;
            bottom: 766px;
            letter-spacing: 0.1px;
          }
          #tb_1 {
            left: 104px;
            bottom: 748px;
            letter-spacing: 0.12px;
          }
          #tc_1 {
            left: 472px;
            bottom: 748px;
            letter-spacing: 0.1px;
          }
          #td_1 {
            left: 104px;
            bottom: 731px;
            letter-spacing: 0.1px;
          }
          #te_1 {
            left: 221px;
            bottom: 731px;
            letter-spacing: 0.11px;
          }
          #tf_1 {
            left: 472px;
            bottom: 731px;
            letter-spacing: 0.1px;
          }
          #tg_1 {
            left: 589px;
            bottom: 731px;
            letter-spacing: 0.12px;
          }
          #th_1 {
            left: 104px;
            bottom: 692px;
            letter-spacing: 0.11px;
          }
          #ti_1 {
            left: 104px;
            bottom: 648px;
            letter-spacing: 0.13px;
          }
          #tj_1 {
            left: 139px;
            bottom: 648px;
            letter-spacing: 0.11px;
          }
          #tk_1 {
            left: 390px;
            bottom: 648px;
            letter-spacing: 0.14px;
          }
          #tl_1 {
            left: 434px;
            bottom: 648px;
            letter-spacing: 0.17px;
          }
          #tm_1 {
            left: 472px;
            bottom: 648px;
            letter-spacing: 0.1px;
          }
          #tn_1 {
            left: 594px;
            bottom: 648px;
            letter-spacing: 0.11px;
          }
          #to_1 {
            left: 683px;
            bottom: 648px;
            letter-spacing: 0.12px;
          }
          #tp_1 {
            left: 761px;
            bottom: 648px;
            letter-spacing: 0.14px;
          }
          #tq_1 {
            left: 346px;
            bottom: 633px;
            letter-spacing: 0.12px;
          }
          #tr_1 {
            left: 104px;
            bottom: 595px;
            letter-spacing: 0.12px;
          }
          #ts_1 {
            left: 139px;
            bottom: 595px;
            letter-spacing: 0.13px;
          }
          #tt_1 {
            left: 382px;
            bottom: 595px;
            letter-spacing: 0.1px;
          }
          #tu_1 {
            left: 434px;
            bottom: 595px;
            letter-spacing: 0.1px;
          }
          #tv_1 {
            left: 505px;
            bottom: 595px;
            letter-spacing: 0.11px;
          }
          #tw_1 {
            left: 683px;
            bottom: 595px;
            letter-spacing: 0.11px;
          }
          #tx_1 {
            left: 788px;
            bottom: 595px;
            letter-spacing: 0.15px;
          }
          #ty_1 {
            left: 139px;
            bottom: 578px;
            letter-spacing: 0.11px;
            word-spacing: 0.06px;
          }
          #tz_1 {
            left: 139px;
            bottom: 564px;
            letter-spacing: 0.11px;
            word-spacing: 0.06px;
          }
          #t10_1 {
            left: 382px;
            bottom: 578px;
            letter-spacing: 0.1px;
          }
          #t11_1 {
            left: 557px;
            bottom: 578px;
            letter-spacing: 0.1px;
          }
          #t12_1 {
            left: 675px;
            bottom: 578px;
            letter-spacing: 0.11px;
          }
          #t13_1 {
            left: 139px;
            bottom: 550px;
            letter-spacing: 0.13px;
            word-spacing: -0.24px;
          }
          #t14_1 {
            left: 139px;
            bottom: 537px;
            letter-spacing: 0.1px;
          }
          #t15_1 {
            left: 205px;
            bottom: 537px;
            letter-spacing: 0.11px;
          }
          #t16_1 {
            left: 139px;
            bottom: 523px;
            letter-spacing: 0.12px;
          }
          #t17_1 {
            left: 139px;
            bottom: 509px;
            letter-spacing: 0.11px;
            word-spacing: -0.01px;
          }
          #t18_1 {
            left: 139px;
            bottom: 495px;
            letter-spacing: 0.11px;
          }
          #t19_1 {
            left: 139px;
            bottom: 478px;
            letter-spacing: 0.11px;
          }
          #t1a_1 {
            left: 104px;
            bottom: 451px;
            letter-spacing: 0.11px;
          }
          #t1b_1 {
            left: 682px;
            bottom: 451px;
            letter-spacing: 0.11px;
          }
          #t1c_1 {
            left: 104px;
            bottom: 387px;
            letter-spacing: 0.11px;
          }
          #t1d_1 {
            left: 266px;
            bottom: 387px;
            letter-spacing: 0.16px;
          }
          #t1e_1 {
            left: 104px;
            bottom: 370px;
            letter-spacing: 0.11px;
          }
          #t1f_1 {
            left: 266px;
            bottom: 370px;
            letter-spacing: 0.1px;
          }
          #t1g_1 {
            left: 104px;
            bottom: 352px;
            letter-spacing: 0.11px;
          }
          #t1h_1 {
            left: 266px;
            bottom: 352px;
            letter-spacing: 0.1px;
          }
          #t1i_1 {
            left: 104px;
            bottom: 313px;
            letter-spacing: 0.09px;
          }
          #t1j_1 {
            left: 178px;
            bottom: 313px;
          }
          #t1k_1 {
            left: 182px;
            bottom: 313px;
            letter-spacing: 0.11px;
          }
          #t1l_1 {
            left: 370px;
            bottom: 313px;
          }
          #t1m_1 {
            left: 378px;
            bottom: 313px;
            letter-spacing: 0.13px;
          }
          #t1n_1 {
            left: 104px;
            bottom: 285px;
            letter-spacing: 0.1px;
          }
          #t1o_1 {
            left: 104px;
            bottom: 272px;
            letter-spacing: 0.1px;
          }
          #t1p_1 {
            left: 104px;
            bottom: 244px;
          }
          #t1q_1 {
            left: 112px;
            bottom: 244px;
            letter-spacing: 0.1px;
          }
          #t1r_1 {
            left: 104px;
            bottom: 230px;
          }
          #t1s_1 {
            left: 112px;
            bottom: 230px;
            letter-spacing: 0.09px;
            word-spacing: 0.04px;
          }
          #t1t_1 {
            left: 104px;
            bottom: 217px;
          }
          #t1u_1 {
            left: 112px;
            bottom: 217px;
            letter-spacing: 0.11px;
            word-spacing: -0.32px;
          }
          #t1v_1 {
            left: 104px;
            bottom: 203px;
          }
          #t1w_1 {
            left: 112px;
            bottom: 203px;
            letter-spacing: 0.11px;
            word-spacing: 0.02px;
          }
          #t1x_1 {
            left: 104px;
            bottom: 189px;
          }
          #t1y_1 {
            left: 112px;
            bottom: 189px;
            letter-spacing: 0.1px;
            word-spacing: -0.01px;
          }
          #t1z_1 {
            left: 104px;
            bottom: 175px;
          }
          #t20_1 {
            left: 112px;
            bottom: 175px;
            letter-spacing: 0.11px;
          }
          #t21_1 {
            left: 180px;
            bottom: 175px;
          }
          #t22_1 {
            left: 186px;
            bottom: 175px;
            letter-spacing: 0.12px;
          }
          #t23_1 {
            left: 104px;
            bottom: 162px;
            letter-spacing: 0.1px;
            word-spacing: 0.03px;
          }
          #t24_1 {
            left: 247px;
            bottom: 162px;
            letter-spacing: 0.1px;
          }
          #t25_1 {
            left: 104px;
            bottom: 148px;
            letter-spacing: 0.11px;
          }
          #t26_1 {
            left: 104px;
            bottom: 120px;
            letter-spacing: 0.1px;
          }
          #t27_1 {
            left: 104px;
            bottom: 93px;
            letter-spacing: 0.09px;
          }
          #t28_1 {
            left: 340px;
            bottom: 93px;
            letter-spacing: 0.1px;
          }
          #t29_1 {
            left: 104px;
            bottom: 65px;
            letter-spacing: 0.1px;
          }
          #t2a_1 {
            left: 494px;
            bottom: 1227px;
            letter-spacing: 0.13px;
          }
          #t2b_1 {
            left: 494px;
            bottom: 1209px;
            letter-spacing: 0.11px;
          }
          #t2c_1 {
            left: 494px;
            bottom: 1196px;
            letter-spacing: 0.11px;
          }
          #t2d_1 {
            left: 494px;
            bottom: 1182px;
            letter-spacing: 0.1px;
          }
          #t2e_1 {
            left: 648px;
            bottom: 1227px;
            letter-spacing: 0.11px;
          }
          #t2f_1 {
            left: 708px;
            bottom: 1227px;
            letter-spacing: 0.11px;
          }
          #t2g_1 {
            left: 708px;
            bottom: 1209px;
            letter-spacing: 0.12px;
          }
          #t2h_1 {
            left: 648px;
            bottom: 1192px;
            letter-spacing: 0.15px;
          }
          #t2i_1 {
            left: 708px;
            bottom: 1192px;
            letter-spacing: 0.13px;
          }
          #t2j_1 {
            left: 708px;
            bottom: 1174px;
            letter-spacing: 0.13px;
          }
          #t2k_1 {
            left: 648px;
            bottom: 1174px;
            letter-spacing: 0.13px;
          }
          #t2l_1 {
            left: 648px;
            bottom: 1209px;
            letter-spacing: 0.1px;
          }
          #t2m_1 {
            left: 494px;
            bottom: 1050px;
            letter-spacing: 0.11px;
          }
          #t2n_1 {
            left: 494px;
            bottom: 1036px;
            letter-spacing: 0.1px;
          }
          #t2o_1 {
            left: 494px;
            bottom: 1022px;
            letter-spacing: 0.11px;
          }
          #t2p_1 {
            left: 494px;
            bottom: 1008px;
            letter-spacing: 0.12px;
          }
          #t2q_1 {
            left: 104px;
            bottom: 859px;
            letter-spacing: 0.2px;
          }
          #t2r_1 {
            left: 104px;
            bottom: 835px;
            letter-spacing: 0.1px;
          }
          #t2s_1 {
            left: 104px;
            bottom: 818px;
            letter-spacing: 0.13px;
          }
          #t2t_1 {
            left: 221px;
            bottom: 818px;
            letter-spacing: 0.11px;
          }
          #t2u_1 {
            left: 221px;
            bottom: 835px;
          }
          #t2v_1 {
            left: 238px;
            bottom: 835px;
            letter-spacing: 0.08px;
          }
          #t2w_1 {
            left: 472px;
            bottom: 837px;
            letter-spacing: 0.11px;
          }
  
          .s1_1 {
            font-size: 12px;
            font-family: Helvetica, Arial, sans-serif;
            color: #000;
          }
          .s2_1 {
            font-size: 12px;
            font-family: Arial_d;
            color: #000;
          }
          .s3_1 {
            font-size: 12px;
            font-family: Helvetica, Arial, sans-serif;
            color: #000;
            font-weight: bold;
          }
          .s4_1 {
            font-size: 12px;
            font-family: Arial-Bold_f;
            color: #000;
          }
          .s5_1 {
            font-size: 12px;
            font-family: SegoeUI-Bold_h;
            color: #000;
          }
          .s6_1 {
            font-size: 21px;
            font-family: Helvetica, Arial, sans-serif;
            color: #000;
            font-weight: bold;
          }
        </style>
        <!-- End inline CSS -->
  
        <!-- Begin embedded font definitions -->
        <style id="fonts1" type="text/css">
          @font-face {
            font-family: Arial-Bold_f;
            src: url("fonts/Arial-Bold_f.woff") format("woff");
          }
  
          @font-face {
            font-family: Arial_d;
            src: url("fonts/Arial_d.woff") format("woff");
          }
  
          @font-face {
            font-family: SegoeUI-Bold_h;
            src: url("fonts/SegoeUI-Bold_h.woff") format("woff");
          }
        </style>
        <!-- End embedded font definitions -->
  
        <!-- Begin page background -->
        <div
          id="pg1Overlay"
          style="
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0);
            -webkit-user-select: none;
          "
        ></div>
        <div id="pg1" style="-webkit-user-select: none">
          <object
            width="909"
            height="1287"
            data="1/1.svg"
            type="image/svg+xml"
            id="pdf1"
            style="
              width: 909px;
              height: 1287px;
              -moz-transform: scale(1);
              z-index: 0;
            "
          ></object>
        </div>
        <!-- End page background -->
  
        <!-- Begin text definitions (Positioned/styled in CSS) -->
        <div class="text-container">
          <span id="t1_1" class="t s1_1">Kreditoren-Nr. </span
          ><span id="t2_1" class="t s1_1">001-000268 </span
          ><span id="t3_1" class="t s1_1">Angebots-Nr. </span>
          <span id="t4_1" class="t s1_1">Sachbearbeiter </span
          ><span id="t5_1" class="t s1_1">Daniel Berger </span
          ><span id="t6_1" class="t s1_1">Ihre Referenz </span>
          <span id="t7_1" class="t s1_1">Durchwahl </span
          ><span id="t8_1" class="t s1_1">+41 71 353 87 28 </span
          ><span id="t9_1" class="t s1_1">Ihre Telefon-Nr. </span
          ><span id="ta_1" class="t s1_1">+41 (0)41 662 95 95 </span>
          <span id="tb_1" class="t s1_1">Fax </span
          ><span id="tc_1" class="t s1_1">Ihre Fax-Nr. </span>
          <span id="td_1" class="t s1_1">E-mail </span
          ><span id="te_1" class="t s1_1">daniel.berger@metrohm.com </span
          ><span id="tf_1" class="t s1_1">Ihre E-mail </span
          ><span id="tg_1" class="t s1_1">sales.ch@maxongroup.com </span>
          <span id="th_1" class="t s2_1"
            >Es gelten die Bedingungen gemäss Liefervertrag zwischen Maxon Motor
            AG und Metrohm AG vom 10.10.2017.
          </span>
          <span id="ti_1" class="t s3_1">Pos </span
          ><span id="tj_1" class="t s3_1">Teilenummer / Text </span
          ><span id="tk_1" class="t s3_1">Menge </span
          ><span id="tl_1" class="t s3_1">ME </span
          ><span id="tm_1" class="t s3_1">Preis/Einheit </span
          ><span id="tn_1" class="t s3_1">Rabatt </span
          ><span id="to_1" class="t s3_1">Pos.Wert </span
          ><span id="tp_1" class="t s4_1">Währung </span>
          <span id="tq_1" class="t s5_1">Offene Menge </span>
          <span id="tr_1" class="t s1_1">10 </span
          ><span id="ts_1" class="t s1_1">M5010112 </span
          ><span id="tt_1" class="t s1_1">2'000.00 </span
          ><span id="tu_1" class="t s1_1">Stk. </span
          ><span id="tv_1" class="t s1_1">43.10/1 </span
          ><span id="tw_1" class="t s1_1">86'200.00 </span
          ><span id="tx_1" class="t s1_1">CHF </span>
          <span id="ty_1" class="t s1_1">Gleichstrommotor 9V </span>
          <span id="tz_1" class="t s1_1">Gleichstrommotor 9V </span>
          <span id="t10_1" class="t s1_1">2'000.00 </span
          ><span id="t11_1" class="t s1_1">Anliefertermin </span
          ><span id="t12_1" class="t s1_1">05.04.2023 </span>
          <span id="t13_1" class="t s1_1">A-max26EBCLL 4W SL 2WE K </span>
          <span id="t14_1" class="t s2_1">Ausführung </span
          ><span id="t15_1" class="t s1_1">9V mit 2 Wellen </span>
          <span id="t16_1" class="t s1_1">Fabrikat MAXON </span>
          <span id="t17_1" class="t s1_1">VPE 20 Stk./Karton </span>
          <span id="t18_1" class="t s1_1">Ihre Artikelnummer 205635 </span>
          <span id="t19_1" class="t s1_1">Zeichnung Version 1.1 </span>
          <span id="t1a_1" class="t s3_1">Total exkl. MwSt CHF </span
          ><span id="t1b_1" class="t s3_1">86'200.00 </span>
          <span id="t1c_1" class="t s1_1">Versandart </span
          ><span id="t1d_1" class="t s1_1">LKW </span>
          <span id="t1e_1" class="t s1_1">Lieferbedingungen </span
          ><span id="t1f_1" class="t s1_1"
            >DAP Herisau,unversichert (Incoterms 2020)
          </span>
          <span id="t1g_1" class="t s1_1">Zahlungsbedingungen </span
          ><span id="t1h_1" class="t s1_1">30 Tage netto </span>
          <span id="t1i_1" class="t s1_1">Es gelten die </span
          ><span id="t1j_1" class="t s2_1">„</span
          ><span id="t1k_1" class="t s1_1">Allgemeinen Einkaufsbedingungen</span
          ><span id="t1l_1" class="t s2_1">“ </span
          ><span id="t1m_1" class="t s1_1">der METROHM AG. </span>
          <span id="t1n_1" class="t s1_1"
            >Mit der Annahme der Bestellung bitten wir Sie die Einhaltung der
            gesetzlichen Bestimmungen unaufgefordert mitzuteilen und die
          </span>
          <span id="t1o_1" class="t s2_1"
            >relevanten Informationen in der Auftragsbestätigung und Rechnung
            auszuweisen:
          </span>
          <span id="t1p_1" class="t s2_1">• </span
          ><span id="t1q_1" class="t s1_1"
            >Ursprungsland und Zolltarifnummer
          </span>
          <span id="t1r_1" class="t s2_1">• </span
          ><span id="t1s_1" class="t s1_1"
            >Richtlinie 2011/65/EU (RoHS) und (EU) 2015/863 (RoHS)
          </span>
          <span id="t1t_1" class="t s2_1">• </span
          ><span id="t1u_1" class="t s1_1"
            >Verordnung (EG) Nr. 1907/2006 (REACH)
          </span>
          <span id="t1v_1" class="t s2_1">• </span
          ><span id="t1w_1" class="t s1_1"
            >Richtlinie 93/68/EWG (CE Kennzeichnung)
          </span>
          <span id="t1x_1" class="t s2_1">• </span
          ><span id="t1y_1" class="t s1_1"
            >Section 1502 US Dodd-Frank Act (conflict mineral)
          </span>
          <span id="t1z_1" class="t s2_1">• </span
          ><span id="t20_1" class="t s1_1">Datenschutz</span
          ><span id="t21_1" class="t s2_1">–</span
          ><span id="t22_1" class="t s1_1">Grundverordnung (DSGVO) </span>
          <span id="t23_1" class="t s2_1">• Güterkontrollverordnung </span
          ><span id="t24_1" class="t s1_1"
            >946.202.1 (inkl. der entsprechenden
            Exportkontrollklassifizierungs-nummer (EKN) bzw. Export Control
          </span>
          <span id="t25_1" class="t s1_1">Classification Number (ECCN)) </span>
          <span id="t26_1" class="t s2_1"
            >Bitte vermerken Sie auf Auftragsbestätigung, Lieferschein und
            Rechnung immer unsere Bestell- und Artikelnummer.
          </span>
          <span id="t27_1" class="t s2_1"
            >Bitte bestätigen Sie diese Bestellung innert </span
          ><span id="t28_1" class="t s1_1">3 Arbeitstagen. </span>
          <span id="t29_1" class="t s2_1"
            >Dieses Dokument wurde digital erstellt und ist ohne Unterschrift
            gültig.
          </span>
          <span id="t2a_1" class="t s3_1">Metrohm AG </span>
          <span id="t2b_1" class="t s1_1">Ionenstrasse </span>
          <span id="t2c_1" class="t s1_1">9100 Herisau </span>
          <span id="t2d_1" class="t s1_1">Switzerland </span>
          <span id="t2e_1" class="t s1_1">Telefon </span
          ><span id="t2f_1" class="t s1_1">+41 71 353 85 85 </span>
          <span id="t2g_1" class="t s1_1">info@metrohm.com </span>
          <span id="t2h_1" class="t s1_1">Web </span
          ><span id="t2i_1" class="t s1_1">www.metrohm.com </span>
          <span id="t2j_1" class="t s1_1">CHE-105.928.996 MWST </span
          ><span id="t2k_1" class="t s1_1">UID </span>
          <span id="t2l_1" class="t s1_1">E-mail </span>
          <span id="t2m_1" class="t s1_1">Maxon Switzerland AG </span>
          <span id="t2n_1" class="t s1_1">Industriestrasse 23 </span>
          <span id="t2o_1" class="t s1_1">6055 Alpnach Dorf </span>
          <span id="t2p_1" class="t s1_1">Schweiz </span>
          <span id="t2q_1" class="t s6_1">Bestellung 001-084046 </span>
          <span id="t2r_1" class="t s1_1">Seite </span>
          <span id="t2s_1" class="t s1_1">Datum </span
          ><span id="t2t_1" class="t s1_1">12.12.2022 </span>
          <span id="t2u_1" class="t s1_1">1 </span
          ><span id="t2v_1" class="t s1_1">/ 2 </span>
          <span id="t2w_1" class="t s1_1">Bestellung 001-084046-1 </span>
        </div>
        <!-- End text definitions -->
      </div>
  
      <div
        id="p2"
        style="
          overflow: hidden;
          position: relative;
          background-color: white;
          width: 909px;
          height: 1287px;
        "
      >
        <!-- Begin shared CSS values -->
        <style class="shared-css" type="text/css">
          .t {
            transform-origin: bottom left;
            z-index: 2;
            position: absolute;
            white-space: pre;
            overflow: visible;
            line-height: 1.5;
          }
          .text-container {
            white-space: pre;
          }
          @supports (-webkit-touch-callout: none) {
            .text-container {
              white-space: normal;
            }
          }
        </style>
        <!-- End shared CSS values -->
  
        <!-- Begin inline CSS -->
        <style type="text/css">
          #t1_2 {
            left: 104px;
            bottom: 806px;
            letter-spacing: 0.2px;
          }
          #t2_2 {
            left: 104px;
            bottom: 788px;
            letter-spacing: 0.18px;
          }
          #t3_2 {
            left: 104px;
            bottom: 778px;
            letter-spacing: 0.18px;
          }
          #t4_2 {
            left: 104px;
            bottom: 769px;
            letter-spacing: 0.18px;
          }
          #t5_2 {
            left: 104px;
            bottom: 760px;
            letter-spacing: 0.19px;
          }
          #t6_2 {
            left: 104px;
            bottom: 741px;
            letter-spacing: 0.17px;
            word-spacing: 0.01px;
          }
          #t7_2 {
            left: 104px;
            bottom: 732px;
            letter-spacing: 0.19px;
          }
          #t8_2 {
            left: 104px;
            bottom: 713px;
            letter-spacing: 0.19px;
          }
          #t9_2 {
            left: 104px;
            bottom: 704px;
            letter-spacing: 0.17px;
          }
          #ta_2 {
            left: 104px;
            bottom: 694px;
            letter-spacing: 0.18px;
          }
          #tb_2 {
            left: 104px;
            bottom: 685px;
            letter-spacing: 0.2px;
            word-spacing: -0.04px;
          }
          #tc_2 {
            left: 127px;
            bottom: 685px;
            letter-spacing: 0.19px;
          }
          #td_2 {
            left: 104px;
            bottom: 676px;
            letter-spacing: 0.19px;
          }
          #te_2 {
            left: 104px;
            bottom: 666px;
            letter-spacing: 0.18px;
          }
          #tf_2 {
            left: 104px;
            bottom: 657px;
            letter-spacing: 0.2px;
          }
          #tg_2 {
            left: 104px;
            bottom: 638px;
            letter-spacing: 0.18px;
          }
          #th_2 {
            left: 104px;
            bottom: 629px;
            letter-spacing: 0.18px;
          }
          #ti_2 {
            left: 104px;
            bottom: 619px;
            letter-spacing: 0.18px;
          }
          #tj_2 {
            left: 104px;
            bottom: 610px;
            letter-spacing: 0.2px;
          }
          #tk_2 {
            left: 104px;
            bottom: 601px;
            letter-spacing: 0.18px;
          }
          #tl_2 {
            left: 104px;
            bottom: 591px;
            letter-spacing: 0.19px;
          }
          #tm_2 {
            left: 104px;
            bottom: 582px;
            letter-spacing: 0.18px;
          }
          #tn_2 {
            left: 104px;
            bottom: 573px;
            letter-spacing: 0.18px;
          }
          #to_2 {
            left: 104px;
            bottom: 563px;
            letter-spacing: 0.18px;
          }
          #tp_2 {
            left: 104px;
            bottom: 554px;
            letter-spacing: 0.18px;
          }
          #tq_2 {
            left: 104px;
            bottom: 535px;
            letter-spacing: 0.17px;
          }
          #tr_2 {
            left: 104px;
            bottom: 526px;
            letter-spacing: 0.18px;
          }
          #ts_2 {
            left: 104px;
            bottom: 517px;
            letter-spacing: 0.19px;
          }
          #tt_2 {
            left: 104px;
            bottom: 498px;
            letter-spacing: 0.19px;
          }
          #tu_2 {
            left: 104px;
            bottom: 489px;
            letter-spacing: 0.18px;
          }
          #tv_2 {
            left: 104px;
            bottom: 479px;
            letter-spacing: 0.18px;
          }
          #tw_2 {
            left: 104px;
            bottom: 470px;
            letter-spacing: 0.19px;
          }
          #tx_2 {
            left: 104px;
            bottom: 451px;
          }
          #ty_2 {
            left: 109px;
            bottom: 451px;
            letter-spacing: 0.19px;
          }
          #tz_2 {
            left: 104px;
            bottom: 442px;
            letter-spacing: 0.17px;
          }
          #t10_2 {
            left: 226px;
            bottom: 442px;
            letter-spacing: 0.22px;
          }
          #t11_2 {
            left: 238px;
            bottom: 442px;
            letter-spacing: 0.19px;
          }
          #t12_2 {
            left: 104px;
            bottom: 432px;
            letter-spacing: 0.18px;
          }
          #t13_2 {
            left: 104px;
            bottom: 423px;
            letter-spacing: 0.18px;
          }
          #t14_2 {
            left: 104px;
            bottom: 414px;
            letter-spacing: 0.18px;
          }
          #t15_2 {
            left: 104px;
            bottom: 404px;
            letter-spacing: 0.19px;
          }
          #t16_2 {
            left: 104px;
            bottom: 395px;
            letter-spacing: 0.18px;
          }
          #t17_2 {
            left: 104px;
            bottom: 386px;
            letter-spacing: 0.19px;
          }
          #t18_2 {
            left: 104px;
            bottom: 376px;
            letter-spacing: 0.19px;
          }
          #t19_2 {
            left: 104px;
            bottom: 367px;
            letter-spacing: 0.18px;
          }
          #t1a_2 {
            left: 104px;
            bottom: 348px;
            letter-spacing: 0.17px;
          }
          #t1b_2 {
            left: 104px;
            bottom: 339px;
            letter-spacing: 0.18px;
            word-spacing: 0.01px;
          }
          #t1c_2 {
            left: 794px;
            bottom: 339px;
            letter-spacing: 0.17px;
          }
          #t1d_2 {
            left: 104px;
            bottom: 330px;
            letter-spacing: 0.19px;
          }
          #t1e_2 {
            left: 104px;
            bottom: 311px;
            letter-spacing: 0.19px;
          }
          #t1f_2 {
            left: 104px;
            bottom: 302px;
            letter-spacing: 0.18px;
          }
          #t1g_2 {
            left: 104px;
            bottom: 292px;
            letter-spacing: 0.18px;
          }
          #t1h_2 {
            left: 104px;
            bottom: 283px;
            letter-spacing: 0.18px;
          }
          #t1i_2 {
            left: 104px;
            bottom: 274px;
            letter-spacing: 0.18px;
          }
          #t1j_2 {
            left: 104px;
            bottom: 264px;
            letter-spacing: 0.18px;
          }
          #t1k_2 {
            left: 104px;
            bottom: 255px;
            letter-spacing: 0.19px;
          }
          #t1l_2 {
            left: 104px;
            bottom: 236px;
            letter-spacing: 0.21px;
          }
          #t1m_2 {
            left: 104px;
            bottom: 227px;
            letter-spacing: 0.19px;
          }
          #t1n_2 {
            left: 104px;
            bottom: 217px;
            letter-spacing: 0.19px;
          }
          #t1o_2 {
            left: 104px;
            bottom: 208px;
            letter-spacing: 0.19px;
          }
          #t1p_2 {
            left: 104px;
            bottom: 189px;
            letter-spacing: 0.22px;
          }
          #t1q_2 {
            left: 113px;
            bottom: 189px;
            letter-spacing: 0.18px;
          }
          #t1r_2 {
            left: 104px;
            bottom: 180px;
            letter-spacing: 0.19px;
          }
          #t1s_2 {
            left: 659px;
            bottom: 180px;
            letter-spacing: 0.22px;
          }
          #t1t_2 {
            left: 671px;
            bottom: 180px;
            letter-spacing: 0.17px;
          }
          #t1u_2 {
            left: 104px;
            bottom: 171px;
            letter-spacing: 0.17px;
          }
          #t1v_2 {
            left: 267px;
            bottom: 171px;
            letter-spacing: 0.22px;
          }
          #t1w_2 {
            left: 279px;
            bottom: 171px;
            letter-spacing: 0.19px;
          }
          #t1x_2 {
            left: 104px;
            bottom: 152px;
            letter-spacing: 0.19px;
          }
          #t1y_2 {
            left: 104px;
            bottom: 143px;
            letter-spacing: 0.19px;
          }
          #t1z_2 {
            left: 104px;
            bottom: 133px;
            letter-spacing: 0.18px;
          }
          #t20_2 {
            left: 567px;
            bottom: 133px;
            letter-spacing: 0.2px;
          }
          #t21_2 {
            left: 104px;
            bottom: 124px;
            letter-spacing: 0.18px;
          }
          #t22_2 {
            left: 104px;
            bottom: 105px;
            letter-spacing: 0.2px;
          }
          #t23_2 {
            left: 104px;
            bottom: 96px;
            letter-spacing: 0.18px;
          }
          #t24_2 {
            left: 104px;
            bottom: 983px;
            letter-spacing: 0.2px;
          }
          #t25_2 {
            left: 104px;
            bottom: 960px;
            letter-spacing: 0.1px;
          }
          #t26_2 {
            left: 104px;
            bottom: 942px;
            letter-spacing: 0.13px;
          }
          #t27_2 {
            left: 221px;
            bottom: 942px;
            letter-spacing: 0.11px;
          }
          #t28_2 {
            left: 221px;
            bottom: 960px;
          }
          #t29_2 {
            left: 238px;
            bottom: 960px;
            letter-spacing: 0.08px;
          }
          #t2a_2 {
            left: 472px;
            bottom: 961px;
            letter-spacing: 0.11px;
          }
  
          .s1 {
            font-size: 8px;
            font-family: Helvetica, Arial, sans-serif;
            color: #000;
          }
          .s2 {
            font-size: 8px;
            font-family: Arial_d;
            color: #000;
          }
          .s3 {
            font-size: 21px;
            font-family: Helvetica, Arial, sans-serif;
            color: #000;
            font-weight: bold;
          }
          .s4 {
            font-size: 12px;
            font-family: Helvetica, Arial, sans-serif;
            color: #000;
          }
        </style>
        <!-- End inline CSS -->
  
        <!-- Begin embedded font definitions -->
        <style id="fonts2" type="text/css">
          @font-face {
            font-family: Arial_d;
            src: url("fonts/Arial_d.woff") format("woff");
          }
        </style>
        <!-- End embedded font definitions -->
  
        <!-- Begin page background -->
        <div
          id="pg2Overlay"
          style="
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0);
            -webkit-user-select: none;
          "
        ></div>
        <div id="pg2" style="-webkit-user-select: none">
          <object
            width="909"
            height="1287"
            data="2/2.svg"
            type="image/svg+xml"
            id="pdf2"
            style="
              width: 909px;
              height: 1287px;
              -moz-transform: scale(1);
              z-index: 0;
            "
          ></object>
        </div>
        <!-- End page background -->
  
        <!-- Begin text definitions (Positioned/styled in CSS) -->
        <div class="text-container">
          <span id="t1_2" class="t s1"
            >Allgemeine Einkaufsbedingungen der Metrohm AG
          </span>
          <span id="t2_2" class="t s1">1. Allgemeines </span>
          <span id="t3_2" class="t s2"
            >Diese Allgemeinen Einkaufsbedingungen gelten auch für alle
            zukünftigen Geschäfte mit dem Lieferanten. Mit Bestätigung der
            Bestellung akzeptiert der Lieferant die vorliegenden Allgemeinen
          </span>
          <span id="t4_2" class="t s2"
            >Einkaufsbedingungen. Diese gehen jedenfalls anderslautenden
            Lieferbedingungen des Lieferanten vor. Letztere haben nur Gültigkeit,
            wenn sie von Metrohm AG ausdrücklich und schriftlich
          </span>
          <span id="t5_2" class="t s2"
            >angenommen worden sind. Dasselbe gilt insbesondere auch für
            anderslautende, in den Angeboten und Auftragsbestätigungen des
            Lieferanten enthaltenen Bedingungen.
          </span>
          <span id="t6_2" class="t s1">2. Preise </span>
          <span id="t7_2" class="t s2"
            >Der im Rahmenvertrag und/oder in der Bestellung ausgewiesene Preis
            ist ein Festpreis und ist für die gesamte Abnahmemenge bindend.
          </span>
          <span id="t8_2" class="t s1">3. Bestellungen, Dokumente </span>
          <span id="t9_2" class="t s2"
            >Nur schriftlich erteilte Bestellungen sind gültig. Eine
            Unterzeichnung ist nicht erforderlich. Die Schriftform ist gewährt,
            wenn die Übermitt-lung mittels Telefax, Email oder einer anderen Form
            der
          </span>
          <span id="ta_2" class="t s2"
            >Übermittlung, die den Nachweis der Bestellung durch Text ermöglicht,
            erfolgt. Die Bestätigung der Bestellung hat unverzüglich nach deren
            Empfang zu erfolgen. Erfolgt diese nicht innert einer Frist
          </span>
          <span id="tb_2" class="t s1">von 5 </span
          ><span id="tc_2" class="t s2"
            >Tagen seit Bestelldatum, so wird vermutet, dass die Bestellung
            unverändert angenommen worden ist.
          </span>
          <span id="td_2" class="t s2"
            >Massgebend sind immer die von Metrohm AG dem Lieferanten zuletzt
            abgegebenen Dokumente (Qualitätsanforderungen, Zeichnungen,
            Spezifikationen, elektronische Daten, Labelling- und
          </span>
          <span id="te_2" class="t s2"
            >Verpackungsinstruktionen etc.). Metrohm AG wird den Lieferanten
            rechtzeitig schriftlich informieren, wenn sich an den Dokumenten etwas
            ändert. Der Lieferant ist verpflichtet, die von Metrohm AG
          </span>
          <span id="tf_2" class="t s2"
            >gemachten Angaben zu überprüfen und bei Unklarheiten mit Metrohm AG
            Rücksprache zu nehmen.
          </span>
          <span id="tg_2" class="t s1"
            >4. Lieferkonditionen, Versand - und Verpackungsinstruktionen
          </span>
          <span id="th_2" class="t s2"
            >Erfüllungsort für die Lieferung ist der in der Bestellung genannte
            Übergabeort. Die Lieferung hat gemäss der Bestellung im vereinbarten
            Umfang und zum angegebenen Zeitpunkt zu erfolgen. Der
          </span>
          <span id="ti_2" class="t s2"
            >Lieferant hat die vereinbarte Transportart einzuhalten. Nutzen und
            Gefahr der Bestellung gehen in jedem Fall erst mit Ablieferung an dem
            auf der Bestellung vermerkten Erfüllungsort auf Metrohm
          </span>
          <span id="tj_2" class="t s2">AG über. </span>
          <span id="tk_2" class="t s2"
            >Der Lieferant ist verpflichtet, die Lieferung an dem kalendermässig
            bestimmten Termin bzw. innerhalb der vereinbarten Frist vorzunehmen.
            Bei Termin- bzw. Fristüberschreitung kommt der Lieferan
          </span>
          <span id="tl_2" class="t s1">ohne Mahnung in Lieferverzug. </span>
          <span id="tm_2" class="t s2"
            >Die Label- und Verpackungsinstruktion der Metrohm AG ist zu beachten.
            Die Metrohm AG ist nicht verpflichtet, die über die bestellte Menge
            hinaus gelieferte Produkte anzunehmen und zu
          </span>
          <span id="tn_2" class="t s2"
            >bezahlen. Vielmehr ist die Metrohm AG berechtigt, alle über die
            Bestellung hinausgehend gelieferten Produkte auf Kosten und Gefahr des
            Lieferanten zurückzusenden. Der Lieferant trägt auch die
          </span>
          <span id="to_2" class="t s2"
            >Kosten für die Retournierung von falsch gelieferter Ware.
          </span>
          <span id="tp_2" class="t s2"
            >Teil- und Restsendungen sind zwingend als solche zu bezeichnen. Jeder
            Sendung ist ein Lieferschein beizufügen, sofern Metrohm AG keine
            anderslautenden Instruktionen erteilt hat.
          </span>
          <span id="tq_2" class="t s1">5. Lieferverzug </span>
          <span id="tr_2" class="t s2"
            >Im Fall der Überschreitung des Liefertermins ist Metrohm AG
            berechtigt, auf Erfüllung zu bestehen oder nach Fristansetzung auf
            nachträgliche Lieferung zu verzichten. In jedem Fall wird der
          </span>
          <span id="ts_2" class="t s2"
            >Lieferant gegenüber Metrohm AG schadenersatzpflichtig.
          </span>
          <span id="tt_2" class="t s1">6. Patente und Schutzrechte </span>
          <span id="tu_2" class="t s1"
            >Der Lieferant garantiert, dass durch die Lieferung oder Benutzung des
            von ihm gelieferten Vertragsinhaltes keine Patent- oder andere
            Schutzrechte Dritter verletzt werden und verpflichtet sich,
          </span>
          <span id="tv_2" class="t s2"
            >Metrohm AG von allfälligen Ansprüchen Dritter vollumfänglich schadlos
            zu halten. Im Fall einer Verletzung von Patent- und Schutzrechten
            Dritter steht Metrohm AG unabhängig vom Verschulden
          </span>
          <span id="tw_2" class="t s2"
            >des Lieferanten das Recht zu, nach freiem Ermessen vom Vertrag
            zurückzutreten und/oder gegenüber dem Lieferanten
            Schadenersatzansprüche geltend zu machen.
          </span>
          <span id="tx_2" class="t s1">7</span
          ><span id="ty_2" class="t s2">. Gewährleistung </span>
          <span id="tz_2" class="t s2">Die Gewährleistungsfrist beträgt </span
          ><span id="t10_2" class="t s1">24 </span
          ><span id="t11_2" class="t s2"
            >Monate und beginnt mit der Auslieferung an Metrohm AG. Mängel der
            Lieferung können während der ganzen Gewährleistungsfrist jederzeit,
            vor und / oder nach
          </span>
          <span id="t12_2" class="t s2"
            >der Verarbeitung und / oder dem Weiterverkauf gerügt werden. Der
            Lieferant verzichtet auf die Einrede der verspäteten Mängelrüge.
          </span>
          <span id="t13_2" class="t s2"
            >Liegt ein Fall der Gewährleistung wegen Mängel der Lieferung vor, so
            hat Metrohm AG freie Wahl, Wandlung, Minderung, Nachbesserung oder
            Ersatzlieferung der Bestellung zu verlangen. In
          </span>
          <span id="t14_2" class="t s2"
            >dringenden Fällen oder wenn der Lieferant nicht in der Lage oder
            gewillt ist, den Mangel nach den Wünschen der Metrohm AG zu
            beseitigen, steht der Metrohm AG das Recht zu, festgestellte
          </span>
          <span id="t15_2" class="t s2"
            >Mängel nach schriftlicher Anzeige an den Lieferanten auf Kosten des
            Lieferanten zu beheben oder durch Dritte beheben zu lassen. Die
            Metrohm AG kann dabei von diesen Ansprüchen für die
          </span>
          <span id="t16_2" class="t s2"
            >gesamte Bestellung einheitlichen Gebrauch machen oder sie je für
            einen bestimmten Teil der Bestellung anwenden. Bei Nachbesserungen
            oder Ersatzlieferungen beginnt die Gewährleistungsfrist
          </span>
          <span id="t17_2" class="t s1">von neuem zu laufen. </span>
          <span id="t18_2" class="t s2"
            >Die Metrohm AG ist berechtigt, sämtliche Aufwendungen, die ihr im
            Zusammenhang mit der Erledigung von Gewährleistungsfällen als Folge
            von schadhaften bzw. mangelhaften Lieferungen
          </span>
          <span id="t19_2" class="t s2"
            >entstanden sind, auf den Lieferanten zu überwälzen.
          </span>
          <span id="t1a_2" class="t s1">8. Produktehaftpflicht </span>
          <span id="t1b_2" class="t s2"
            >Der Lieferant hält die Metrohm AG für sämtliche
            Schadenersatzansprüche Dritter - namentlich für Ansprüche aus
            Produktehaftpflichtrecht - schadlos, die im Zusammenhang mit mangel– </span
          ><span id="t1c_2" class="t s1">bzw. </span>
          <span id="t1d_2" class="t s2"
            >fehlerhaften Lieferungen gegenüber der Metrohm AG geltend gemacht
            werden.
          </span>
          <span id="t1e_2" class="t s1">9. Geheimhaltung </span>
          <span id="t1f_2" class="t s2"
            >Der Lieferant verpflichtet sich und ihre Mitarbeiter zur
            Geheimhaltung aller technischen, kaufmännischen und anderen
            Informationen, die ihm im Rahmen der Vertragserfüllung zukommen. Der
          </span>
          <span id="t1g_2" class="t s2"
            >Lieferant verpflichtet sich, die ihm im Rahmen der Abwicklung er
            Bestellung übergebenen Unterlagen ausschliesslich im Interesse der
            Metrohm AG zu verwenden, sie weder für eigene noch
          </span>
          <span id="t1h_2" class="t s2"
            >vertragsfremde Zwecke zu nutzen und sie weder ganz noch teilweise
            Dritten zu übergeben oder sie im Interesse Dritter zu verwenden.
          </span>
          <span id="t1i_2" class="t s2"
            >Wenn der Lieferant eine Zulieferfirma einschaltet, muss die Metrohm
            AG mit der Bekanntgabe seiner Spezifikation der entsprechenden Teile
            gegenüber der Zulieferfirma einverstanden sein. Das
          </span>
          <span id="t1j_2" class="t s2"
            >Einverständnis hat schriftlich zu erfolgen. Der Lieferant hat
            diesbezüglich der Zulieferfirma ebenfalls die
            Geheimhaltungsverpflichtungen aufzuerlegen.
          </span>
          <span id="t1k_2" class="t s1"
            >Die Verwendung der im Rahmen der Bestellung gelieferten Produkte zu
            Werbezwecken ist nur mit der vorhergehenden schriftlichen Einwilligung
            der Metrohm AG gestattet.
          </span>
          <span id="t1l_2" class="t s1">10. Eigentum Metrohm AG </span>
          <span id="t1m_2" class="t s2"
            >Sämtliche von Metrohm AG dem Lieferanten übergebenen Unterlagen,
            elektronischen Daten, beigestellte oder bezahlte Werkzeuge,
            Vorrichtungen, Transportsysteme, Prüfmittel etc. sind samt den
          </span>
          <span id="t1n_2" class="t s2"
            >Urheberrechten Eigentum von Metrohm AG und sind als solche zu
            kennzeichnen. Sie sind vom Lieferanten sorgfältig zu behandeln und
            aufzubewahren und Metrohm AG auf entsprechende
          </span>
          <span id="t1o_2" class="t s2"
            >Aufforderung zurückzugeben. Ausgenommen sind Dokumente für den
            einmaligen Gebrauch, die zu vernichten sind.
          </span>
          <span id="t1p_2" class="t s1">11</span
          ><span id="t1q_2" class="t s2"
            >. Rechnungsstellung und Fälligkeit
          </span>
          <span id="t1r_2" class="t s2"
            >Ohne abweichende schriftliche Vereinbarung sowie unter dem Vorbehalt
            der vertragsgemässen und mängelfreien Lieferung wird der Kaufpreis
            innert </span
          ><span id="t1s_2" class="t s1">30 </span
          ><span id="t1t_2" class="t s2"
            >Tagen netto zur Zahlung fällig. Im Fall von
          </span>
          <span id="t1u_2" class="t s2"
            >mangelhaften Lieferungen tritt die Fälligkeit </span
          ><span id="t1v_2" class="t s1">30 </span
          ><span id="t1w_2" class="t s2"
            >Tage nach ordnungsgemässer Mängelbehebung ein. Erfüllungsort für die
            Zahlung ist Herisau.
          </span>
          <span id="t1x_2" class="t s1"
            >12. Einhaltung der geltenden gesetzlichen Bestimmungen
          </span>
          <span id="t1y_2" class="t s1"
            >Der Lieferant garantiert, dass die am Produktionsort geltenden und
            international anwendbare Gesetze und Bestimmungen eingehalten werden,
            insbesondere solche betreffend Umweltschutz,
          </span>
          <span id="t1z_2" class="t s2"
            >Nachhaltigkeit, Produktsicherheit, Arbeitssicherheit &amp;
            Gesundheitsschutz, Güterkontrollverordnung / Dual Use,
            Datenschutz–</span
          ><span id="t20_2" class="t s1"
            >Grundverordnung (DSGVO), Menschenrechte und Kinderarbeit.
          </span>
          <span id="t21_2" class="t s1"
            >Werden diese vom Lieferanten verletzt, so ist Metrohm AG sofort
            schriftlich zu benachrichtigen und die Verletzung ist umgehend zu
            beheben.
          </span>
          <span id="t22_2" class="t s1"
            >13. Gerichtsstand und anwendbares Recht
          </span>
          <span id="t23_2" class="t s1"
            >Gerichtsstand ist ausschliesslich Herisau. Anwendbar ist
            ausschliesslich schweizerisches Recht, unter Ausschluss des
            UN-Kaufrechts und des Kollisionsrechts.
          </span>
          <span id="t24_2" class="t s3">Bestellung 001-084046 </span>
          <span id="t25_2" class="t s4">Seite </span>
          <span id="t26_2" class="t s4">Datum </span
          ><span id="t27_2" class="t s4">12.12.2022 </span>
          <span id="t28_2" class="t s4">2 </span
          ><span id="t29_2" class="t s4">/ 2 </span>
          <span id="t2a_2" class="t s4">Bestellung 001-084046-1 </span>
        </div>
        <!-- End text definitions -->
      </div>
    </body>
  </html>
  
  `,
  '2765b': `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
      <meta charset="utf-8" />
    </head>
  
    <body style="margin: 0">
      <div
        id="p1"
        style="
          overflow: hidden;
          position: relative;
          background-color: white;
          width: 909px;
          height: 1287px;
        "
      >
        <!-- Begin shared CSS values -->
        <style class="shared-css" type="text/css">
          .t {
            transform-origin: bottom left;
            z-index: 2;
            position: absolute;
            white-space: pre;
            overflow: visible;
            line-height: 1.5;
          }
          .text-container {
            white-space: pre;
          }
          @supports (-webkit-touch-callout: none) {
            .text-container {
              white-space: normal;
            }
          }
        </style>
        <!-- End shared CSS values -->
  
        <!-- Begin inline CSS -->
        <style type="text/css">
          #t1_1 {
            left: 104px;
            bottom: 801px;
            letter-spacing: 0.1px;
          }
          #t2_1 {
            left: 221px;
            bottom: 801px;
            letter-spacing: 0.12px;
          }
          #t3_1 {
            left: 472px;
            bottom: 801px;
            letter-spacing: 0.11px;
          }
          #t4_1 {
            left: 104px;
            bottom: 784px;
            letter-spacing: 0.11px;
          }
          #t5_1 {
            left: 221px;
            bottom: 784px;
            letter-spacing: 0.1px;
          }
          #t6_1 {
            left: 472px;
            bottom: 784px;
            letter-spacing: 0.1px;
          }
          #t7_1 {
            left: 104px;
            bottom: 766px;
            letter-spacing: 0.12px;
          }
          #t8_1 {
            left: 221px;
            bottom: 766px;
            letter-spacing: 0.11px;
          }
          #t9_1 {
            left: 472px;
            bottom: 766px;
            letter-spacing: 0.1px;
          }
          #ta_1 {
            left: 589px;
            bottom: 766px;
            letter-spacing: 0.1px;
          }
          #tb_1 {
            left: 104px;
            bottom: 748px;
            letter-spacing: 0.12px;
          }
          #tc_1 {
            left: 472px;
            bottom: 748px;
            letter-spacing: 0.1px;
          }
          #td_1 {
            left: 104px;
            bottom: 731px;
            letter-spacing: 0.1px;
          }
          #te_1 {
            left: 221px;
            bottom: 731px;
            letter-spacing: 0.11px;
          }
          #tf_1 {
            left: 472px;
            bottom: 731px;
            letter-spacing: 0.1px;
          }
          #tg_1 {
            left: 589px;
            bottom: 731px;
            letter-spacing: 0.12px;
          }
          #th_1 {
            left: 104px;
            bottom: 692px;
            letter-spacing: 0.11px;
          }
          #ti_1 {
            left: 104px;
            bottom: 648px;
            letter-spacing: 0.13px;
          }
          #tj_1 {
            left: 139px;
            bottom: 648px;
            letter-spacing: 0.11px;
          }
          #tk_1 {
            left: 390px;
            bottom: 648px;
            letter-spacing: 0.14px;
          }
          #tl_1 {
            left: 434px;
            bottom: 648px;
            letter-spacing: 0.17px;
          }
          #tm_1 {
            left: 472px;
            bottom: 648px;
            letter-spacing: 0.1px;
          }
          #tn_1 {
            left: 594px;
            bottom: 648px;
            letter-spacing: 0.11px;
          }
          #to_1 {
            left: 683px;
            bottom: 648px;
            letter-spacing: 0.12px;
          }
          #tp_1 {
            left: 761px;
            bottom: 648px;
            letter-spacing: 0.14px;
          }
          #tq_1 {
            left: 346px;
            bottom: 633px;
            letter-spacing: 0.12px;
          }
          #tr_1 {
            left: 104px;
            bottom: 595px;
            letter-spacing: 0.12px;
          }
          #ts_1 {
            left: 139px;
            bottom: 595px;
            letter-spacing: 0.13px;
          }
          #tt_1 {
            left: 391px;
            bottom: 595px;
            letter-spacing: 0.11px;
          }
          #tu_1 {
            left: 434px;
            bottom: 595px;
            letter-spacing: 0.1px;
          }
          #tv_1 {
            left: 505px;
            bottom: 595px;
            letter-spacing: 0.11px;
          }
          #tw_1 {
            left: 682px;
            bottom: 595px;
            letter-spacing: 0.11px;
          }
          #tx_1 {
            left: 788px;
            bottom: 595px;
            letter-spacing: 0.15px;
          }
          #ty_1 {
            left: 139px;
            bottom: 578px;
            letter-spacing: 0.12px;
            word-spacing: 0.01px;
          }
          #tz_1 {
            left: 139px;
            bottom: 564px;
            letter-spacing: 0.12px;
            word-spacing: 0.01px;
          }
          #t10_1 {
            left: 391px;
            bottom: 578px;
            letter-spacing: 0.11px;
          }
          #t11_1 {
            left: 549px;
            bottom: 578px;
            letter-spacing: 0.11px;
          }
          #t12_1 {
            left: 675px;
            bottom: 578px;
            letter-spacing: 0.11px;
          }
          #t13_1 {
            left: 139px;
            bottom: 550px;
            letter-spacing: 0.14px;
            word-spacing: -0.11px;
          }
          #t14_1 {
            left: 139px;
            bottom: 537px;
            letter-spacing: 0.11px;
          }
          #t15_1 {
            left: 211px;
            bottom: 537px;
            letter-spacing: 0.11px;
            word-spacing: 0.01px;
          }
          #t16_1 {
            left: 139px;
            bottom: 523px;
            letter-spacing: 0.12px;
          }
          #t17_1 {
            left: 139px;
            bottom: 509px;
            letter-spacing: 0.11px;
            word-spacing: -0.01px;
          }
          #t18_1 {
            left: 139px;
            bottom: 495px;
            letter-spacing: 0.11px;
          }
          #t19_1 {
            left: 494px;
            bottom: 1227px;
            letter-spacing: 0.13px;
          }
          #t1a_1 {
            left: 494px;
            bottom: 1209px;
            letter-spacing: 0.11px;
          }
          #t1b_1 {
            left: 494px;
            bottom: 1196px;
            letter-spacing: 0.11px;
          }
          #t1c_1 {
            left: 494px;
            bottom: 1182px;
            letter-spacing: 0.12px;
          }
          #t1d_1 {
            left: 648px;
            bottom: 1227px;
            letter-spacing: 0.11px;
          }
          #t1e_1 {
            left: 708px;
            bottom: 1227px;
            letter-spacing: 0.11px;
          }
          #t1f_1 {
            left: 708px;
            bottom: 1209px;
            letter-spacing: 0.12px;
          }
          #t1g_1 {
            left: 648px;
            bottom: 1192px;
            letter-spacing: 0.15px;
          }
          #t1h_1 {
            left: 708px;
            bottom: 1192px;
            letter-spacing: 0.13px;
          }
          #t1i_1 {
            left: 708px;
            bottom: 1174px;
            letter-spacing: 0.13px;
          }
          #t1j_1 {
            left: 648px;
            bottom: 1174px;
            letter-spacing: 0.13px;
          }
          #t1k_1 {
            left: 648px;
            bottom: 1209px;
            letter-spacing: 0.1px;
          }
          #t1l_1 {
            left: 494px;
            bottom: 1050px;
            letter-spacing: 0.11px;
          }
          #t1m_1 {
            left: 494px;
            bottom: 1036px;
            letter-spacing: 0.1px;
          }
          #t1n_1 {
            left: 494px;
            bottom: 1022px;
            letter-spacing: 0.11px;
          }
          #t1o_1 {
            left: 494px;
            bottom: 1008px;
            letter-spacing: 0.12px;
          }
          #t1p_1 {
            left: 104px;
            bottom: 859px;
            letter-spacing: 0.21px;
          }
          #t1q_1 {
            left: 104px;
            bottom: 835px;
            letter-spacing: 0.1px;
          }
          #t1r_1 {
            left: 104px;
            bottom: 818px;
            letter-spacing: 0.13px;
          }
          #t1s_1 {
            left: 221px;
            bottom: 818px;
            letter-spacing: 0.11px;
          }
          #t1t_1 {
            left: 221px;
            bottom: 835px;
          }
          #t1u_1 {
            left: 238px;
            bottom: 835px;
            letter-spacing: 0.08px;
          }
          #t1v_1 {
            left: 472px;
            bottom: 837px;
            letter-spacing: 0.12px;
          }
  
          .s1_1 {
            font-size: 12px;
            font-family: Helvetica, Arial, sans-serif;
            color: #000;
          }
          .s2_1 {
            font-size: 12px;
            font-family: Arial_f;
            color: #000;
          }
          .s3_1 {
            font-size: 12px;
            font-family: Helvetica, Arial, sans-serif;
            color: #000;
            font-weight: bold;
          }
          .s4_1 {
            font-size: 12px;
            font-family: Arial-Bold_h;
            color: #000;
          }
          .s5_1 {
            font-size: 12px;
            font-family: SegoeUI-Bold_j;
            color: #000;
          }
          .s6_1 {
            font-size: 21px;
            font-family: Arial-Bold_h;
            color: #000;
          }
        </style>
        <!-- End inline CSS -->
  
        <!-- Begin embedded font definitions -->
        <style id="fonts1" type="text/css">
          @font-face {
            font-family: Arial-Bold_h;
            src: url("fonts/Arial-Bold_h.woff") format("woff");
          }
  
          @font-face {
            font-family: Arial_f;
            src: url("fonts/Arial_f.woff") format("woff");
          }
  
          @font-face {
            font-family: SegoeUI-Bold_j;
            src: url("fonts/SegoeUI-Bold_j.woff") format("woff");
          }
        </style>
        <!-- End embedded font definitions -->
  
        <!-- Begin page background -->
        <div
          id="pg1Overlay"
          style="
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0);
            -webkit-user-select: none;
          "
        ></div>
        <div id="pg1" style="-webkit-user-select: none">
          <object
            width="909"
            height="1287"
            data="1/1.svg"
            type="image/svg+xml"
            id="pdf1"
            style="
              width: 909px;
              height: 1287px;
              -moz-transform: scale(1);
              z-index: 0;
            "
          ></object>
        </div>
        <!-- End page background -->
  
        <!-- Begin text definitions (Positioned/styled in CSS) -->
        <div class="text-container">
          <span id="t1_1" class="t s1_1">Kreditoren-Nr. </span
          ><span id="t2_1" class="t s1_1">001-000268 </span
          ><span id="t3_1" class="t s1_1">Angebots-Nr. </span>
          <span id="t4_1" class="t s1_1">Sachbearbeiter </span
          ><span id="t5_1" class="t s1_1">Jacqueline Rutz </span
          ><span id="t6_1" class="t s1_1">Ihre Referenz </span>
          <span id="t7_1" class="t s1_1">Durchwahl </span
          ><span id="t8_1" class="t s1_1">+41 71 353 88 35 </span
          ><span id="t9_1" class="t s1_1">Ihre Telefon-Nr. </span
          ><span id="ta_1" class="t s1_1">+41 (0)41 662 95 95 </span>
          <span id="tb_1" class="t s1_1">Fax </span
          ><span id="tc_1" class="t s1_1">Ihre Fax-Nr. </span>
          <span id="td_1" class="t s1_1">E-mail </span
          ><span id="te_1" class="t s1_1">jacqueline.rutz@metrohm.com </span
          ><span id="tf_1" class="t s1_1">Ihre E-mail </span
          ><span id="tg_1" class="t s1_1">sales.ch@maxongroup.com </span>
          <span id="th_1" class="t s2_1"
            >Es gelten die Bedingungen gemäss Liefervertrag zwischen Maxon Motor
            AG und Metrohm AG vom 10.10.2017.
          </span>
          <span id="ti_1" class="t s3_1">Pos </span
          ><span id="tj_1" class="t s3_1">Teilenummer / Text </span
          ><span id="tk_1" class="t s3_1">Menge </span
          ><span id="tl_1" class="t s3_1">ME </span
          ><span id="tm_1" class="t s3_1">Preis/Einheit </span
          ><span id="tn_1" class="t s3_1">Rabatt </span
          ><span id="to_1" class="t s3_1">Pos.Wert </span
          ><span id="tp_1" class="t s4_1">Währung </span>
          <span id="tq_1" class="t s5_1">Offene Menge </span>
          <span id="tr_1" class="t s3_1">10 </span
          ><span id="ts_1" class="t s3_1">M5010112 </span
          ><span id="tt_1" class="t s3_1">400.00 </span
          ><span id="tu_1" class="t s3_1">Stk. </span
          ><span id="tv_1" class="t s3_1">43.10/1 </span
          ><span id="tw_1" class="t s3_1">17'240.00 </span
          ><span id="tx_1" class="t s3_1">CHF </span>
          <span id="ty_1" class="t s3_1">Gleichstrommotor 9V </span>
          <span id="tz_1" class="t s3_1">Gleichstrommotor 9V </span>
          <span id="t10_1" class="t s1_1">400.00 </span
          ><span id="t11_1" class="t s3_1">Anliefertermin </span
          ><span id="t12_1" class="t s3_1">19.04.2023 </span>
          <span id="t13_1" class="t s3_1">A-max26EBCLL 4W SL 2WE K </span>
          <span id="t14_1" class="t s4_1">Ausführung </span
          ><span id="t15_1" class="t s3_1">9V mit 2 Wellen </span>
          <span id="t16_1" class="t s3_1">Fabrikat MAXON </span>
          <span id="t17_1" class="t s3_1">VPE 20 Stk./Karton </span>
          <span id="t18_1" class="t s3_1">Ihre Artikelnummer 123321 </span>
          <span id="t19_1" class="t s3_1">Metrohm AG </span>
          <span id="t1a_1" class="t s1_1">Ionenstrasse </span>
          <span id="t1b_1" class="t s1_1">9100 Herisau </span>
          <span id="t1c_1" class="t s1_1">Schweiz </span>
          <span id="t1d_1" class="t s1_1">Telefon </span
          ><span id="t1e_1" class="t s1_1">+41 71 353 85 85 </span>
          <span id="t1f_1" class="t s1_1">info@metrohm.com </span>
          <span id="t1g_1" class="t s1_1">Web </span
          ><span id="t1h_1" class="t s1_1">www.metrohm.com </span>
          <span id="t1i_1" class="t s1_1">CHE-105.928.996 MWST </span
          ><span id="t1j_1" class="t s1_1">UID </span>
          <span id="t1k_1" class="t s1_1">E-mail </span>
          <span id="t1l_1" class="t s1_1">Maxon Switzerland AG </span>
          <span id="t1m_1" class="t s1_1">Industriestrasse 23 </span>
          <span id="t1n_1" class="t s1_1">6055 Alpnach Dorf </span>
          <span id="t1o_1" class="t s1_1">Schweiz </span>
          <span id="t1p_1" class="t s6_1">Bestellung 001-079682 </span>
          <span id="t1q_1" class="t s1_1">Seite </span>
          <span id="t1r_1" class="t s1_1">Datum </span
          ><span id="t1s_1" class="t s1_1">19.09.2022 </span>
          <span id="t1t_1" class="t s1_1">1 </span
          ><span id="t1u_1" class="t s1_1">/ 3 </span>
          <span id="t1v_1" class="t s2_1"
            >Bestellung - ÄNDERUNG 001-079682-2
          </span>
        </div>
        <!-- End text definitions -->
      </div>
  
      <div
        id="p2"
        style="
          overflow: hidden;
          position: relative;
          background-color: white;
          width: 909px;
          height: 1287px;
        "
      >
        <!-- Begin shared CSS values -->
        <style class="shared-css" type="text/css">
          .t {
            transform-origin: bottom left;
            z-index: 2;
            position: absolute;
            white-space: pre;
            overflow: visible;
            line-height: 1.5;
          }
          .text-container {
            white-space: pre;
          }
          @supports (-webkit-touch-callout: none) {
            .text-container {
              white-space: normal;
            }
          }
        </style>
        <!-- End shared CSS values -->
  
        <!-- Begin inline CSS -->
        <style type="text/css">
          #t1_2 {
            left: 104px;
            bottom: 780px;
            letter-spacing: 0.11px;
          }
          #t2_2 {
            left: 104px;
            bottom: 736px;
            letter-spacing: 0.13px;
          }
          #t3_2 {
            left: 139px;
            bottom: 736px;
            letter-spacing: 0.11px;
          }
          #t4_2 {
            left: 390px;
            bottom: 736px;
            letter-spacing: 0.14px;
          }
          #t5_2 {
            left: 434px;
            bottom: 736px;
            letter-spacing: 0.17px;
          }
          #t6_2 {
            left: 472px;
            bottom: 736px;
            letter-spacing: 0.1px;
          }
          #t7_2 {
            left: 594px;
            bottom: 736px;
            letter-spacing: 0.11px;
          }
          #t8_2 {
            left: 683px;
            bottom: 736px;
            letter-spacing: 0.12px;
          }
          #t9_2 {
            left: 761px;
            bottom: 736px;
            letter-spacing: 0.14px;
          }
          #ta_2 {
            left: 346px;
            bottom: 721px;
            letter-spacing: 0.12px;
          }
          #tb_2 {
            left: 139px;
            bottom: 705px;
            letter-spacing: 0.11px;
          }
          #tc_2 {
            left: 104px;
            bottom: 678px;
            letter-spacing: 0.11px;
          }
          #td_2 {
            left: 682px;
            bottom: 678px;
            letter-spacing: 0.11px;
          }
          #te_2 {
            left: 104px;
            bottom: 615px;
            letter-spacing: 0.11px;
          }
          #tf_2 {
            left: 266px;
            bottom: 615px;
            letter-spacing: 0.16px;
          }
          #tg_2 {
            left: 104px;
            bottom: 597px;
            letter-spacing: 0.11px;
          }
          #th_2 {
            left: 266px;
            bottom: 597px;
            letter-spacing: 0.1px;
          }
          #ti_2 {
            left: 104px;
            bottom: 580px;
            letter-spacing: 0.11px;
          }
          #tj_2 {
            left: 266px;
            bottom: 580px;
            letter-spacing: 0.1px;
          }
          #tk_2 {
            left: 104px;
            bottom: 540px;
            letter-spacing: 0.09px;
          }
          #tl_2 {
            left: 178px;
            bottom: 540px;
          }
          #tm_2 {
            left: 182px;
            bottom: 540px;
            letter-spacing: 0.11px;
          }
          #tn_2 {
            left: 370px;
            bottom: 540px;
          }
          #to_2 {
            left: 378px;
            bottom: 540px;
            letter-spacing: 0.13px;
          }
          #tp_2 {
            left: 104px;
            bottom: 513px;
            letter-spacing: 0.1px;
          }
          #tq_2 {
            left: 104px;
            bottom: 499px;
            letter-spacing: 0.1px;
          }
          #tr_2 {
            left: 104px;
            bottom: 472px;
          }
          #ts_2 {
            left: 112px;
            bottom: 472px;
            letter-spacing: 0.1px;
          }
          #tt_2 {
            left: 104px;
            bottom: 458px;
          }
          #tu_2 {
            left: 112px;
            bottom: 458px;
            letter-spacing: 0.09px;
            word-spacing: 0.04px;
          }
          #tv_2 {
            left: 104px;
            bottom: 444px;
          }
          #tw_2 {
            left: 112px;
            bottom: 444px;
            letter-spacing: 0.11px;
            word-spacing: -0.32px;
          }
          #tx_2 {
            left: 104px;
            bottom: 430px;
          }
          #ty_2 {
            left: 112px;
            bottom: 430px;
            letter-spacing: 0.11px;
            word-spacing: 0.02px;
          }
          #tz_2 {
            left: 104px;
            bottom: 417px;
          }
          #t10_2 {
            left: 112px;
            bottom: 417px;
            letter-spacing: 0.1px;
            word-spacing: -0.01px;
          }
          #t11_2 {
            left: 104px;
            bottom: 403px;
          }
          #t12_2 {
            left: 112px;
            bottom: 403px;
            letter-spacing: 0.11px;
          }
          #t13_2 {
            left: 180px;
            bottom: 403px;
          }
          #t14_2 {
            left: 186px;
            bottom: 403px;
            letter-spacing: 0.12px;
          }
          #t15_2 {
            left: 104px;
            bottom: 389px;
            letter-spacing: 0.1px;
            word-spacing: 0.03px;
          }
          #t16_2 {
            left: 247px;
            bottom: 389px;
            letter-spacing: 0.1px;
          }
          #t17_2 {
            left: 104px;
            bottom: 375px;
            letter-spacing: 0.11px;
          }
          #t18_2 {
            left: 104px;
            bottom: 348px;
            letter-spacing: 0.1px;
          }
          #t19_2 {
            left: 104px;
            bottom: 320px;
            letter-spacing: 0.09px;
          }
          #t1a_2 {
            left: 340px;
            bottom: 320px;
            letter-spacing: 0.1px;
          }
          #t1b_2 {
            left: 104px;
            bottom: 293px;
            letter-spacing: 0.1px;
          }
          #t1c_2 {
            left: 104px;
            bottom: 230px;
            letter-spacing: 0.11px;
          }
          #t1d_2 {
            left: 104px;
            bottom: 216px;
            letter-spacing: 0.13px;
          }
          #t1e_2 {
            left: 104px;
            bottom: 147px;
            letter-spacing: 0.09px;
          }
          #t1f_2 {
            left: 307px;
            bottom: 147px;
            letter-spacing: 0.09px;
          }
          #t1g_2 {
            left: 104px;
            bottom: 134px;
            letter-spacing: 0.1px;
          }
          #t1h_2 {
            left: 309px;
            bottom: 134px;
            letter-spacing: 0.1px;
          }
          #t1i_2 {
            left: 104px;
            bottom: 983px;
            letter-spacing: 0.21px;
          }
          #t1j_2 {
            left: 104px;
            bottom: 960px;
            letter-spacing: 0.1px;
          }
          #t1k_2 {
            left: 104px;
            bottom: 942px;
            letter-spacing: 0.13px;
          }
          #t1l_2 {
            left: 221px;
            bottom: 942px;
            letter-spacing: 0.11px;
          }
          #t1m_2 {
            left: 221px;
            bottom: 960px;
          }
          #t1n_2 {
            left: 238px;
            bottom: 960px;
            letter-spacing: 0.08px;
          }
          #t1o_2 {
            left: 472px;
            bottom: 961px;
            letter-spacing: 0.12px;
          }
  
          .s1_2 {
            font-size: 12px;
            font-family: Arial_f;
            color: #000;
          }
          .s2_2 {
            font-size: 12px;
            font-family: Helvetica, Arial, sans-serif;
            color: #000;
            font-weight: bold;
          }
          .s3_2 {
            font-size: 12px;
            font-family: Arial-Bold_h;
            color: #000;
          }
          .s4_2 {
            font-size: 12px;
            font-family: SegoeUI-Bold_j;
            color: #000;
          }
          .s5_2 {
            font-size: 12px;
            font-family: Helvetica, Arial, sans-serif;
            color: #000;
          }
          .s6_2 {
            font-size: 21px;
            font-family: Arial-Bold_h;
            color: #000;
          }
        </style>
        <!-- End inline CSS -->
  
        <!-- Begin embedded font definitions -->
        <style id="fonts2" type="text/css">
          @font-face {
            font-family: Arial-Bold_h;
            src: url("fonts/Arial-Bold_h.woff") format("woff");
          }
  
          @font-face {
            font-family: Arial_f;
            src: url("fonts/Arial_f.woff") format("woff");
          }
  
          @font-face {
            font-family: SegoeUI-Bold_j;
            src: url("fonts/SegoeUI-Bold_j.woff") format("woff");
          }
        </style>
        <!-- End embedded font definitions -->
  
        <!-- Begin page background -->
        <div
          id="pg2Overlay"
          style="
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0);
            -webkit-user-select: none;
          "
        ></div>
        <div id="pg2" style="-webkit-user-select: none">
          <object
            width="909"
            height="1287"
            data="2/2.svg"
            type="image/svg+xml"
            id="pdf2"
            style="
              width: 909px;
              height: 1287px;
              -moz-transform: scale(1);
              z-index: 0;
            "
          ></object>
        </div>
        <!-- End page background -->
  
        <!-- Begin text definitions (Positioned/styled in CSS) -->
        <div class="text-container">
          <span id="t1_2" class="t s1_2"
            >Es gelten die Bedingungen gemäss Liefervertrag zwischen Maxon Motor
            AG und Metrohm AG vom 10.10.2017.
          </span>
          <span id="t2_2" class="t s2_2">Pos </span
          ><span id="t3_2" class="t s2_2">Teilenummer / Text </span
          ><span id="t4_2" class="t s2_2">Menge </span
          ><span id="t5_2" class="t s2_2">ME </span
          ><span id="t6_2" class="t s2_2">Preis/Einheit </span
          ><span id="t7_2" class="t s2_2">Rabatt </span
          ><span id="t8_2" class="t s2_2">Pos.Wert </span
          ><span id="t9_2" class="t s3_2">Währung </span>
          <span id="ta_2" class="t s4_2">Offene Menge </span>
          <span id="tb_2" class="t s2_2">Zeichnung Version 1.1 </span>
          <span id="tc_2" class="t s2_2">Total exkl. MwSt CHF </span
          ><span id="td_2" class="t s2_2">17'240.00 </span>
          <span id="te_2" class="t s5_2">Versandart </span
          ><span id="tf_2" class="t s5_2">LKW </span>
          <span id="tg_2" class="t s5_2">Lieferbedingungen </span
          ><span id="th_2" class="t s5_2"
            >DAP Herisau,unversichert (Incoterms 2010)
          </span>
          <span id="ti_2" class="t s5_2">Zahlungsbedingungen </span
          ><span id="tj_2" class="t s5_2">30 Tage netto </span>
          <span id="tk_2" class="t s5_2">Es gelten die </span
          ><span id="tl_2" class="t s1_2">„</span
          ><span id="tm_2" class="t s5_2">Allgemeinen Einkaufsbedingungen</span
          ><span id="tn_2" class="t s1_2">“ </span
          ><span id="to_2" class="t s5_2">der METROHM AG. </span>
          <span id="tp_2" class="t s5_2"
            >Mit der Annahme der Bestellung bitten wir Sie die Einhaltung der
            gesetzlichen Bestimmungen unaufgefordert mitzuteilen und die
          </span>
          <span id="tq_2" class="t s1_2"
            >relevanten Informationen in der Auftragsbestätigung und Rechnung
            auszuweisen:
          </span>
          <span id="tr_2" class="t s1_2">• </span
          ><span id="ts_2" class="t s5_2"
            >Ursprungsland und Zolltarifnummer
          </span>
          <span id="tt_2" class="t s1_2">• </span
          ><span id="tu_2" class="t s5_2"
            >Richtlinie 2011/65/EU (RoHS) und (EU) 2015/863 (RoHS)
          </span>
          <span id="tv_2" class="t s1_2">• </span
          ><span id="tw_2" class="t s5_2"
            >Verordnung (EG) Nr. 1907/2006 (REACH)
          </span>
          <span id="tx_2" class="t s1_2">• </span
          ><span id="ty_2" class="t s5_2"
            >Richtlinie 93/68/EWG (CE Kennzeichnung)
          </span>
          <span id="tz_2" class="t s1_2">• </span
          ><span id="t10_2" class="t s5_2"
            >Section 1502 US Dodd-Frank Act (conflict mineral)
          </span>
          <span id="t11_2" class="t s1_2">• </span
          ><span id="t12_2" class="t s5_2">Datenschutz</span
          ><span id="t13_2" class="t s1_2">–</span
          ><span id="t14_2" class="t s5_2">Grundverordnung (DSGVO) </span>
          <span id="t15_2" class="t s1_2">• Güterkontrollverordnung </span
          ><span id="t16_2" class="t s5_2"
            >946.202.1 (inkl. der entsprechenden
            Exportkontrollklassifizierungs-nummer (EKN) bzw. Export Control
          </span>
          <span id="t17_2" class="t s5_2">Classification Number (ECCN)) </span>
          <span id="t18_2" class="t s1_2"
            >Bitte vermerken Sie auf Auftragsbestätigung, Lieferschein und
            Rechnung immer unsere Bestell- und Artikelnummer.
          </span>
          <span id="t19_2" class="t s1_2"
            >Bitte bestätigen Sie diese Bestellung innert </span
          ><span id="t1a_2" class="t s5_2">3 Arbeitstagen. </span>
          <span id="t1b_2" class="t s1_2"
            >Dieses Dokument wurde digital erstellt und ist ohne Unterschrift
            gültig.
          </span>
          <span id="t1c_2" class="t s1_2">Freundliche Grüsse </span>
          <span id="t1d_2" class="t s5_2">Metrohm AG </span>
          <span id="t1e_2" class="t s1_2">i.V. René Kläger </span
          ><span id="t1f_2" class="t s1_2">i.V. Daniel Berger </span>
          <span id="t1g_2" class="t s5_2">Lead Buyer </span
          ><span id="t1h_2" class="t s5_2">Operational Buyer </span>
          <span id="t1i_2" class="t s6_2">Bestellung - ÄNDERUNG 001-079682 </span>
          <span id="t1j_2" class="t s5_2">Seite </span>
          <span id="t1k_2" class="t s5_2">Datum </span
          ><span id="t1l_2" class="t s5_2">19.09.2022 </span>
          <span id="t1m_2" class="t s5_2">2 </span
          ><span id="t1n_2" class="t s5_2">/ 3 </span>
          <span id="t1o_2" class="t s1_2"
            >Bestellung - ÄNDERUNG 001-079682-2
          </span>
        </div>
        <!-- End text definitions -->
      </div>
    </body>
  </html>
  
  `,
  '53835-1': `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
    <meta charset="utf-8" />
  </head>

  <body style="margin: 0">
    <div
      id="p1"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1284px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_1 {
          left: 64px;
          bottom: 1049px;
          letter-spacing: -0.18px;
        }
        #t2_1 {
          left: 64px;
          bottom: 1031px;
          letter-spacing: -0.16px;
          word-spacing: -0.39px;
        }
        #t3_1 {
          left: 64px;
          bottom: 1014px;
          letter-spacing: -0.18px;
          word-spacing: 0.23px;
        }
        #t4_1 {
          left: 64px;
          bottom: 979px;
          letter-spacing: -0.18px;
        }
        #t5_1 {
          left: 426px;
          bottom: 956px;
          letter-spacing: -0.03px;
          word-spacing: 0.05px;
        }
        #t6_1 {
          left: 584px;
          bottom: 956px;
          letter-spacing: -0.44px;
        }
        #t7_1 {
          left: 618px;
          bottom: 956px;
          letter-spacing: -0.2px;
        }
        #t8_1 {
          left: 426px;
          bottom: 915px;
          letter-spacing: 0.06px;
          word-spacing: 0.11px;
        }
        #t9_1 {
          left: 426px;
          bottom: 897px;
          letter-spacing: 0.17px;
        }
        #ta_1 {
          left: 426px;
          bottom: 878px;
          letter-spacing: 0.2px;
        }
        #tb_1 {
          left: 426px;
          bottom: 860px;
          letter-spacing: 0.17px;
        }
        #tc_1 {
          left: 426px;
          bottom: 842px;
          letter-spacing: 0.19px;
        }
        #td_1 {
          left: 426px;
          bottom: 824px;
          letter-spacing: 0.19px;
        }
        #te_1 {
          left: 426px;
          bottom: 806px;
          letter-spacing: 0.18px;
        }
        #tf_1 {
          left: 426px;
          bottom: 787px;
          letter-spacing: 0.17px;
          word-spacing: -0.53px;
        }
        #tg_1 {
          left: 426px;
          bottom: 769px;
          letter-spacing: 0.19px;
        }
        #th_1 {
          left: 596px;
          bottom: 915px;
          letter-spacing: -0.48px;
        }
        #ti_1 {
          left: 596px;
          bottom: 898px;
          letter-spacing: -0.18px;
        }
        #tj_1 {
          left: 596px;
          bottom: 880px;
          letter-spacing: -0.18px;
        }
        #tk_1 {
          left: 596px;
          bottom: 863px;
          letter-spacing: -0.2px;
        }
        #tl_1 {
          left: 596px;
          bottom: 845px;
          letter-spacing: -0.16px;
        }
        #tm_1 {
          left: 596px;
          bottom: 828px;
          letter-spacing: -0.17px;
        }
        #tn_1 {
          left: 596px;
          bottom: 811px;
          letter-spacing: -0.15px;
        }
        #to_1 {
          left: 596px;
          bottom: 793px;
          letter-spacing: -0.18px;
        }
        #tp_1 {
          left: 596px;
          bottom: 776px;
          letter-spacing: -0.16px;
        }
        #tq_1 {
          left: 64px;
          bottom: 1073px;
          letter-spacing: 0.11px;
        }
        #tr_1 {
          left: 64px;
          bottom: 752px;
          letter-spacing: -0.37px;
        }
        #ts_1 {
          left: 147px;
          bottom: 752px;
          letter-spacing: -0.16px;
        }
        #tt_1 {
          left: 64px;
          bottom: 734px;
          letter-spacing: -0.14px;
        }
        #tu_1 {
          left: 147px;
          bottom: 734px;
          letter-spacing: -0.16px;
        }
        #tv_1 {
          left: 426px;
          bottom: 678px;
          letter-spacing: -0.16px;
        }
        #tw_1 {
          left: 426px;
          bottom: 643px;
          letter-spacing: -0.12px;
        }
        #tx_1 {
          left: 469px;
          bottom: 643px;
        }
        #ty_1 {
          left: 64px;
          bottom: 576px;
          letter-spacing: 0.19px;
        }
        #tz_1 {
          left: 64px;
          bottom: 555px;
          letter-spacing: -0.18px;
          word-spacing: 0.01px;
        }
        #t10_1 {
          left: 64px;
          bottom: 520px;
          letter-spacing: -0.17px;
        }
        #t11_1 {
          left: 64px;
          bottom: 503px;
          letter-spacing: -0.63px;
          word-spacing: 0.54px;
        }
        #t12_1 {
          left: 64px;
          bottom: 485px;
          letter-spacing: -0.16px;
        }
        #t13_1 {
          left: 64px;
          bottom: 442px;
          letter-spacing: -0.15px;
          word-spacing: 0.01px;
        }
        #t14_1 {
          left: 64px;
          bottom: 424px;
          letter-spacing: -0.16px;
        }
        #t15_1 {
          left: 64px;
          bottom: 407px;
          letter-spacing: -0.18px;
        }
        #t16_1 {
          left: 64px;
          bottom: 390px;
          letter-spacing: -0.14px;
          word-spacing: 0.02px;
        }
        #t17_1 {
          left: 64px;
          bottom: 372px;
          letter-spacing: -0.15px;
        }
        #t18_1 {
          left: 64px;
          bottom: 337px;
        }
        #t19_1 {
          left: 127px;
          bottom: 298px;
          letter-spacing: -0.13px;
        }
        #t1a_1 {
          left: 64px;
          bottom: 298px;
          letter-spacing: -0.19px;
        }
        #t1b_1 {
          left: 490px;
          bottom: 298px;
          letter-spacing: -0.2px;
        }
        #t1c_1 {
          left: 639px;
          bottom: 298px;
          letter-spacing: -0.15px;
        }
        #t1d_1 {
          left: 793px;
          bottom: 298px;
          letter-spacing: -0.19px;
        }
        #t1e_1 {
          left: 127px;
          bottom: 252px;
          letter-spacing: -0.16px;
          word-spacing: -0.37px;
        }
        #t1f_1 {
          left: 127px;
          bottom: 234px;
          letter-spacing: -0.17px;
        }
        #t1g_1 {
          left: 127px;
          bottom: 217px;
          letter-spacing: -0.15px;
        }
        #t1h_1 {
          left: 127px;
          bottom: 199px;
          letter-spacing: -0.13px;
        }
        #t1i_1 {
          left: 127px;
          bottom: 182px;
          letter-spacing: -0.14px;
        }
        #t1j_1 {
          left: 486px;
          bottom: 251px;
          letter-spacing: -0.16px;
        }
        #t1k_1 {
          left: 671px;
          bottom: 252px;
          letter-spacing: -0.16px;
        }
        #t1l_1 {
          left: 643px;
          bottom: 234px;
          letter-spacing: -0.17px;
        }
        #t1m_1 {
          left: 788px;
          bottom: 251px;
          letter-spacing: -0.16px;
        }
        #t1n_1 {
          left: 64px;
          bottom: 251px;
        }
        #t1o_1 {
          left: 783px;
          bottom: 147px;
          letter-spacing: -0.13px;
        }
        #t1p_1 {
          left: 65px;
          bottom: 90px;
          letter-spacing: -0.04px;
        }
        #t1q_1 {
          left: 65px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #t1r_1 {
          left: 65px;
          bottom: 62px;
          letter-spacing: -0.03px;
          word-spacing: -0.23px;
        }
        #t1s_1 {
          left: 189px;
          bottom: 90px;
          letter-spacing: -0.05px;
        }
        #t1t_1 {
          left: 189px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #t1u_1 {
          left: 189px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #t1v_1 {
          left: 189px;
          bottom: 47px;
          letter-spacing: -0.04px;
        }
        #t1w_1 {
          left: 337px;
          bottom: 90px;
          letter-spacing: -0.11px;
          word-spacing: 0.15px;
        }
        #t1x_1 {
          left: 337px;
          bottom: 76px;
          letter-spacing: -0.09px;
          word-spacing: 0.13px;
        }
        #t1y_1 {
          left: 337px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #t1z_1 {
          left: 337px;
          bottom: 47px;
          letter-spacing: -0.04px;
        }
        #t20_1 {
          left: 477px;
          bottom: 90px;
          letter-spacing: -0.04px;
        }
        #t21_1 {
          left: 477px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #t22_1 {
          left: 477px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #t23_1 {
          left: 477px;
          bottom: 47px;
          letter-spacing: -0.04px;
        }
        #t24_1 {
          left: 638px;
          bottom: 90px;
          letter-spacing: -0.04px;
        }
        #t25_1 {
          left: 638px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #t26_1 {
          left: 638px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #t27_1 {
          left: 638px;
          bottom: 47px;
          letter-spacing: -0.04px;
        }
        #t28_1 {
          left: 638px;
          bottom: 33px;
          letter-spacing: -0.04px;
        }

        .s1_1 {
          font-size: 14px;
          font-family: Arial_q;
          color: #000;
        }
        .s2_1 {
          font-size: 16px;
          font-family: Arial-Bold_w;
          color: #000;
        }
        .s3_1 {
          font-size: 13px;
          font-family: Arial-Bold_w;
          color: #000;
        }
        .s4_1 {
          font-size: 10px;
          font-family: Arial_q;
          color: #646464;
        }
        .s5_1 {
          font-size: 11px;
          font-family: Arial_q;
          color: #646464;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts1" type="text/css">
        @font-face {
          font-family: Arial-Bold_w;
          src: url("fonts/Arial-Bold_w.woff") format("woff");
        }

        @font-face {
          font-family: Arial_q;
          src: url("fonts/Arial_q.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg1Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg1" style="-webkit-user-select: none">
        <object
          width="909"
          height="1284"
          data="1/1.svg"
          type="image/svg+xml"
          id="pdf1"
          style="
            width: 909px;
            height: 1284px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_1" class="t s1_1">Maxon Motor GmbH </span>
        <span id="t2_1" class="t s1_1">Frau Ana-Maria Ganga </span>
        <span id="t3_1" class="t s1_1">Truderinger Straße 210 </span>
        <span id="t4_1" class="t s1_1">81825 München </span>
        <span id="t5_1" class="t s2_1">B E S T E L L U N G </span
        ><span id="t6_1" class="t s2_1">Nr. </span
        ><span id="t7_1" class="t s2_1">APOB11157 </span>
        <span id="t8_1" class="t s3_1">Vorgang Nr. </span>
        <span id="t9_1" class="t s3_1">Adress-Nr. </span>
        <span id="ta_1" class="t s3_1">Unsere Kunden-Nr. </span>
        <span id="tb_1" class="t s3_1">Bearbeiter </span>
        <span id="tc_1" class="t s3_1">Durchwahl </span>
        <span id="td_1" class="t s3_1">E-Mailadresse </span>
        <span id="te_1" class="t s3_1">Unser Zeichen </span>
        <span id="tf_1" class="t s3_1">Ihr Angebot </span>
        <span id="tg_1" class="t s3_1">vom </span>
        <span id="th_1" class="t s1_1">APOB11157 </span>
        <span id="ti_1" class="t s1_1">2153 </span>
        <span id="tj_1" class="t s1_1">53835 </span>
        <span id="tk_1" class="t s1_1">RUCKI </span>
        <span id="tl_1" class="t s1_1">-47 </span>
        <span id="tm_1" class="t s1_1">bernd.rucki@knapp.com </span>
        <span id="tn_1" class="t s1_1">ru </span>
        <span id="to_1" class="t s1_1">22VIKL367 </span>
        <span id="tp_1" class="t s1_1">06.12.2022 </span>
        <span id="tq_1" class="t s4_1"
          >KNAPP Smart Solutions GmbH, Uferstraße 10, 45881 Gelsenkirchen
        </span>
        <span id="tr_1" class="t s1_1">Telefon </span
        ><span id="ts_1" class="t s1_1">089 / 420493-0 </span>
        <span id="tt_1" class="t s1_1">Fax </span
        ><span id="tu_1" class="t s1_1">089 / 420493-40 </span>
        <span id="tv_1" class="t s1_1">Gelsenkirchen, 08. Dezember 2022 </span>
        <span id="tw_1" class="t s1_1">Seite </span
        ><span id="tx_1" class="t s1_1">1 </span>
        <span id="ty_1" class="t s3_1">Lieferungsempfänger: </span>
        <span id="tz_1" class="t s1_1">KNAPP Smart Solutions GmbH </span>
        <span id="t10_1" class="t s1_1">Am Bugapark 3c </span>
        <span id="t11_1" class="t s1_1">Tor 6 </span>
        <span id="t12_1" class="t s1_1">45899 Gelsenkirchen </span>
        <span id="t13_1" class="t s1_1"
          >Wir bitten um eine Auftragsbestätigung an die E-Mail-Adresse
          „kso.einkauf@knapp.com“ spätestens drei Tage
        </span>
        <span id="t14_1" class="t s1_1"
          >nach Bestelleingang. Rechnungen werden bevorzugt digital auf
          „kso.accounting@knapp.com“ empfangen.
        </span>
        <span id="t15_1" class="t s1_1">############################ </span>
        <span id="t16_1" class="t s1_1"
          >Achtung! Unsere Verpackungsrichtlinie, die für sämtliche
          Materialbestellungen gilt, wurde am 1.02.2022 aktualisiert:
        </span>
        <span id="t17_1" class="t s1_1"
          >https://apostore.imgix.net/20220118-KnappSmartSolutions_Verpackungsrichtlinie_v1_0.pdf
        </span>
        <span id="t18_1" class="t s1_1">. </span>
        <span id="t19_1" class="t s1_1">Artikel </span
        ><span id="t1a_1" class="t s1_1">Pos </span
        ><span id="t1b_1" class="t s1_1">Menge </span
        ><span id="t1c_1" class="t s1_1">Einzelpreis </span
        ><span id="t1d_1" class="t s1_1">Gesamt </span>
        <span id="t1e_1" class="t s1_1">Unsere Artikelnr.: 21.117.07 </span>
        <span id="t1f_1" class="t s1_1">Signal-Kabel, MACS6 </span>
        <span id="t1g_1" class="t s1_1">10-polig, 500 mm, </span>
        <span id="t1h_1" class="t s1_1">einseitig konfektioniert </span>
        <span id="t1i_1" class="t s1_1">Artikelnr.: 696837 </span>
        <span id="t1j_1" class="t s1_1">50 Stck </span
        ><span id="t1k_1" class="t s1_1">21,90 </span>
        <span id="t1l_1" class="t s1_1">EUR/ Stck </span>
        <span id="t1m_1" class="t s1_1">1.095,00 </span
        ><span id="t1n_1" class="t s1_1">1 </span>
        <span id="t1o_1" class="t s1_1">... Seite 2 </span>
        <span id="t1p_1" class="t s5_1">Geschäftsführer </span>
        <span id="t1q_1" class="t s5_1">Christian Bauer </span>
        <span id="t1r_1" class="t s5_1">Christopher Thielen </span>
        <span id="t1s_1" class="t s5_1">KNAPP </span>
        <span id="t1t_1" class="t s5_1">Smart Solutions GmbH </span>
        <span id="t1u_1" class="t s5_1">Uferstraße 10 </span>
        <span id="t1v_1" class="t s5_1">45881 Gelsenkirchen </span>
        <span id="t1w_1" class="t s5_1">T +49 209 94 117 - 0 </span>
        <span id="t1x_1" class="t s5_1">F +49 209 94 117 - 17 </span>
        <span id="t1y_1" class="t s5_1">info@apostore.com </span>
        <span id="t1z_1" class="t s5_1">www.apostore.com </span>
        <span id="t20_1" class="t s5_1">Amtsgericht Gelsenkirchen </span>
        <span id="t21_1" class="t s5_1">HRB 2709 </span>
        <span id="t22_1" class="t s5_1">USt-Id: DE 212 267 442 </span>
        <span id="t23_1" class="t s5_1">CHE-357.244.481 MWST </span>
        <span id="t24_1" class="t s5_1">Commerzbank Kamen </span>
        <span id="t25_1" class="t s5_1">BLZ 440 800 50 </span>
        <span id="t26_1" class="t s5_1">Konto Nr.: 375 008 500 </span>
        <span id="t27_1" class="t s5_1">IBAN DE75 4408 0050 0375 0085 00 </span>
        <span id="t28_1" class="t s5_1">BIC/SWIFT-Code DRESDEFF440 </span>
      </div>
      <!-- End text definitions -->
    </div>

    <div
      id="p2"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1284px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_2 {
          left: 426px;
          bottom: 1031px;
          letter-spacing: -0.12px;
        }
        #t2_2 {
          left: 469px;
          bottom: 1031px;
        }
        #t3_2 {
          left: 64px;
          bottom: 984px;
          letter-spacing: -0.03px;
          word-spacing: 0.05px;
        }
        #t4_2 {
          left: 222px;
          bottom: 984px;
          letter-spacing: -0.44px;
        }
        #t5_2 {
          left: 256px;
          bottom: 984px;
          letter-spacing: -0.2px;
        }
        #t6_2 {
          left: 127px;
          bottom: 910px;
          letter-spacing: -0.13px;
        }
        #t7_2 {
          left: 64px;
          bottom: 910px;
          letter-spacing: -0.19px;
        }
        #t8_2 {
          left: 490px;
          bottom: 910px;
          letter-spacing: -0.2px;
        }
        #t9_2 {
          left: 639px;
          bottom: 910px;
          letter-spacing: -0.15px;
        }
        #ta_2 {
          left: 793px;
          bottom: 910px;
          letter-spacing: -0.19px;
        }
        #tb_2 {
          left: 127px;
          bottom: 846px;
          letter-spacing: -0.16px;
          word-spacing: -0.37px;
        }
        #tc_2 {
          left: 127px;
          bottom: 829px;
          letter-spacing: -0.17px;
        }
        #td_2 {
          left: 127px;
          bottom: 811px;
          letter-spacing: -0.14px;
        }
        #te_2 {
          left: 127px;
          bottom: 794px;
          letter-spacing: -0.13px;
        }
        #tf_2 {
          left: 127px;
          bottom: 777px;
          letter-spacing: -0.14px;
        }
        #tg_2 {
          left: 486px;
          bottom: 846px;
          letter-spacing: -0.16px;
        }
        #th_2 {
          left: 671px;
          bottom: 846px;
          letter-spacing: -0.16px;
        }
        #ti_2 {
          left: 643px;
          bottom: 829px;
          letter-spacing: -0.17px;
        }
        #tj_2 {
          left: 788px;
          bottom: 846px;
          letter-spacing: -0.16px;
        }
        #tk_2 {
          left: 64px;
          bottom: 846px;
        }
        #tl_2 {
          left: 127px;
          bottom: 724px;
          letter-spacing: -0.16px;
          word-spacing: -0.37px;
        }
        #tm_2 {
          left: 127px;
          bottom: 707px;
          letter-spacing: -0.18px;
        }
        #tn_2 {
          left: 127px;
          bottom: 689px;
          letter-spacing: -0.16px;
        }
        #to_2 {
          left: 127px;
          bottom: 672px;
          letter-spacing: -0.13px;
        }
        #tp_2 {
          left: 127px;
          bottom: 655px;
          letter-spacing: -0.14px;
        }
        #tq_2 {
          left: 478px;
          bottom: 724px;
          letter-spacing: -0.16px;
        }
        #tr_2 {
          left: 671px;
          bottom: 724px;
          letter-spacing: -0.16px;
        }
        #ts_2 {
          left: 643px;
          bottom: 707px;
          letter-spacing: -0.17px;
        }
        #tt_2 {
          left: 788px;
          bottom: 724px;
          letter-spacing: -0.16px;
        }
        #tu_2 {
          left: 64px;
          bottom: 724px;
        }
        #tv_2 {
          left: 127px;
          bottom: 602px;
          letter-spacing: -0.16px;
          word-spacing: -0.37px;
        }
        #tw_2 {
          left: 127px;
          bottom: 585px;
          letter-spacing: -0.17px;
        }
        #tx_2 {
          left: 127px;
          bottom: 567px;
          letter-spacing: -0.14px;
        }
        #ty_2 {
          left: 127px;
          bottom: 550px;
          letter-spacing: -0.13px;
        }
        #tz_2 {
          left: 127px;
          bottom: 532px;
          letter-spacing: -0.14px;
        }
        #t10_2 {
          left: 486px;
          bottom: 602px;
          letter-spacing: -0.16px;
        }
        #t11_2 {
          left: 671px;
          bottom: 602px;
          letter-spacing: -0.16px;
        }
        #t12_2 {
          left: 643px;
          bottom: 585px;
          letter-spacing: -0.17px;
        }
        #t13_2 {
          left: 799px;
          bottom: 602px;
          letter-spacing: -0.17px;
        }
        #t14_2 {
          left: 64px;
          bottom: 602px;
        }
        #t15_2 {
          left: 127px;
          bottom: 480px;
          letter-spacing: -0.16px;
          word-spacing: -0.37px;
        }
        #t16_2 {
          left: 127px;
          bottom: 463px;
          letter-spacing: -0.17px;
        }
        #t17_2 {
          left: 127px;
          bottom: 445px;
          letter-spacing: -0.16px;
        }
        #t18_2 {
          left: 127px;
          bottom: 428px;
          letter-spacing: -0.13px;
        }
        #t19_2 {
          left: 127px;
          bottom: 410px;
          letter-spacing: -0.14px;
        }
        #t1a_2 {
          left: 478px;
          bottom: 480px;
          letter-spacing: -0.16px;
        }
        #t1b_2 {
          left: 671px;
          bottom: 480px;
          letter-spacing: -0.16px;
        }
        #t1c_2 {
          left: 643px;
          bottom: 463px;
          letter-spacing: -0.17px;
        }
        #t1d_2 {
          left: 788px;
          bottom: 480px;
          letter-spacing: -0.16px;
        }
        #t1e_2 {
          left: 64px;
          bottom: 480px;
        }
        #t1f_2 {
          left: 127px;
          bottom: 358px;
          letter-spacing: -0.16px;
          word-spacing: -0.37px;
        }
        #t1g_2 {
          left: 127px;
          bottom: 341px;
          letter-spacing: -0.17px;
        }
        #t1h_2 {
          left: 127px;
          bottom: 323px;
          letter-spacing: -0.14px;
        }
        #t1i_2 {
          left: 127px;
          bottom: 306px;
          letter-spacing: -0.13px;
        }
        #t1j_2 {
          left: 127px;
          bottom: 288px;
          letter-spacing: -0.14px;
        }
        #t1k_2 {
          left: 486px;
          bottom: 358px;
          letter-spacing: -0.16px;
        }
        #t1l_2 {
          left: 671px;
          bottom: 358px;
          letter-spacing: -0.16px;
        }
        #t1m_2 {
          left: 643px;
          bottom: 341px;
          letter-spacing: -0.17px;
        }
        #t1n_2 {
          left: 788px;
          bottom: 358px;
          letter-spacing: -0.16px;
        }
        #t1o_2 {
          left: 64px;
          bottom: 358px;
        }
        #t1p_2 {
          left: 431px;
          bottom: 249px;
          letter-spacing: 0.21px;
        }
        #t1q_2 {
          left: 788px;
          bottom: 249px;
          letter-spacing: -0.16px;
        }
        #t1r_2 {
          left: 788px;
          bottom: 227px;
          letter-spacing: -0.16px;
        }
        #t1s_2 {
          left: 435px;
          bottom: 227px;
          letter-spacing: 0.18px;
        }
        #t1t_2 {
          left: 540px;
          bottom: 227px;
          letter-spacing: 0.18px;
        }
        #t1u_2 {
          left: 431px;
          bottom: 201px;
          letter-spacing: 0.22px;
        }
        #t1v_2 {
          left: 534px;
          bottom: 201px;
          letter-spacing: 0.26px;
        }
        #t1w_2 {
          left: 781px;
          bottom: 201px;
          letter-spacing: -0.29px;
        }
        #t1x_2 {
          left: 783px;
          bottom: 147px;
          letter-spacing: -0.13px;
        }
        #t1y_2 {
          left: 65px;
          bottom: 90px;
          letter-spacing: -0.04px;
        }
        #t1z_2 {
          left: 65px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #t20_2 {
          left: 65px;
          bottom: 62px;
          letter-spacing: -0.03px;
          word-spacing: -0.23px;
        }
        #t21_2 {
          left: 189px;
          bottom: 90px;
          letter-spacing: -0.05px;
        }
        #t22_2 {
          left: 189px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #t23_2 {
          left: 189px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #t24_2 {
          left: 189px;
          bottom: 47px;
          letter-spacing: -0.04px;
        }
        #t25_2 {
          left: 337px;
          bottom: 90px;
          letter-spacing: -0.11px;
          word-spacing: 0.15px;
        }
        #t26_2 {
          left: 337px;
          bottom: 76px;
          letter-spacing: -0.09px;
          word-spacing: 0.13px;
        }
        #t27_2 {
          left: 337px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #t28_2 {
          left: 337px;
          bottom: 47px;
          letter-spacing: -0.04px;
        }
        #t29_2 {
          left: 477px;
          bottom: 90px;
          letter-spacing: -0.04px;
        }
        #t2a_2 {
          left: 477px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #t2b_2 {
          left: 477px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #t2c_2 {
          left: 477px;
          bottom: 47px;
          letter-spacing: -0.04px;
        }
        #t2d_2 {
          left: 638px;
          bottom: 90px;
          letter-spacing: -0.04px;
        }
        #t2e_2 {
          left: 638px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #t2f_2 {
          left: 638px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #t2g_2 {
          left: 638px;
          bottom: 47px;
          letter-spacing: -0.04px;
        }
        #t2h_2 {
          left: 638px;
          bottom: 33px;
          letter-spacing: -0.04px;
        }

        .s1_2 {
          font-size: 14px;
          font-family: Arial_q;
          color: #000;
        }
        .s2_2 {
          font-size: 16px;
          font-family: Arial-Bold_w;
          color: #000;
        }
        .s3_2 {
          font-size: 13px;
          font-family: Arial-Bold_w;
          color: #000;
        }
        .s4_2 {
          font-size: 11px;
          font-family: Arial_q;
          color: #646464;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts2" type="text/css">
        @font-face {
          font-family: Arial-Bold_w;
          src: url("fonts/Arial-Bold_w.woff") format("woff");
        }

        @font-face {
          font-family: Arial_q;
          src: url("fonts/Arial_q.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg2Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg2" style="-webkit-user-select: none">
        <object
          width="909"
          height="1284"
          data="2/2.svg"
          type="image/svg+xml"
          id="pdf2"
          style="
            width: 909px;
            height: 1284px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_2" class="t s1_2">Seite </span
        ><span id="t2_2" class="t s1_2">2 </span>
        <span id="t3_2" class="t s2_2">B E S T E L L U N G </span
        ><span id="t4_2" class="t s2_2">Nr. </span
        ><span id="t5_2" class="t s2_2">APOB11157 </span>
        <span id="t6_2" class="t s1_2">Artikel </span
        ><span id="t7_2" class="t s1_2">Pos </span
        ><span id="t8_2" class="t s1_2">Menge </span
        ><span id="t9_2" class="t s1_2">Einzelpreis </span
        ><span id="ta_2" class="t s1_2">Gesamt </span>
        <span id="tb_2" class="t s1_2">Unsere Artikelnr.: 21.117.06 </span>
        <span id="tc_2" class="t s1_2">Signal-Kabel, MACS6 </span>
        <span id="td_2" class="t s1_2">8-polig, 500 mm, </span>
        <span id="te_2" class="t s1_2">einseitig konfektioniert </span>
        <span id="tf_2" class="t s1_2">Artikelnr.: 520853 </span>
        <span id="tg_2" class="t s1_2">92 Stck </span
        ><span id="th_2" class="t s1_2">12,60 </span>
        <span id="ti_2" class="t s1_2">EUR/ Stck </span>
        <span id="tj_2" class="t s1_2">1.159,20 </span
        ><span id="tk_2" class="t s1_2">2 </span>
        <span id="tl_2" class="t s1_2">Unsere Artikelnr.: 21.117.10 </span>
        <span id="tm_2" class="t s1_2">Geber-Kabel, MACS6 </span>
        <span id="tn_2" class="t s1_2">500 mm, </span>
        <span id="to_2" class="t s1_2">einseitig konfektioniert </span>
        <span id="tp_2" class="t s1_2">Artikelnr.: 520852 </span>
        <span id="tq_2" class="t s1_2">140 Stck </span
        ><span id="tr_2" class="t s1_2">15,60 </span>
        <span id="ts_2" class="t s1_2">EUR/ Stck </span>
        <span id="tt_2" class="t s1_2">2.184,00 </span
        ><span id="tu_2" class="t s1_2">3 </span>
        <span id="tv_2" class="t s1_2"> </span>
        <span id="tw_2" class="t s1_2">Power-Kabel, Steuerung, MACS6 </span>
        <span id="tx_2" class="t s1_2">2-polig, 500 mm, </span>
        <span id="ty_2" class="t s1_2">einseitig konfektioniert </span>
        <span id="tz_2" class="t s1_2">Artikelnr.: 275829 </span>
        <span id="t10_2" class="t s1_2">50 Stck </span
        ><span id="t11_2" class="t s1_2">10,20 </span>
        <span id="t12_2" class="t s1_2">EUR/ Stck </span>
        <span id="t13_2" class="t s1_2">510,00 </span
        ><span id="t14_2" class="t s1_2">4 </span>
        <span id="t15_2" class="t s1_2">Unsere Artikelnr.: 21.117.09 </span>
        <span id="t16_2" class="t s1_2">Motor-Kabel, MACS6 </span>
        <span id="t17_2" class="t s1_2">500 mm, </span>
        <span id="t18_2" class="t s1_2">einseitig konfektioniert </span>
        <span id="t19_2" class="t s1_2">Artikelnr.: 275851 </span>
        <span id="t1a_2" class="t s1_2">140 Stck </span
        ><span id="t1b_2" class="t s1_2">22,60 </span>
        <span id="t1c_2" class="t s1_2">EUR/ Stck </span>
        <span id="t1d_2" class="t s1_2">3.164,00 </span
        ><span id="t1e_2" class="t s1_2">5 </span>
        <span id="t1f_2" class="t s1_2">Unsere Artikelnr.: 21.117.08 </span>
        <span id="t1g_2" class="t s1_2">Power-Kabel, Motor, MACS6 </span>
        <span id="t1h_2" class="t s1_2">4-polig, 500 mm, </span>
        <span id="t1i_2" class="t s1_2">einseitig konfektioniert </span>
        <span id="t1j_2" class="t s1_2">Artikelnr.: 699485 </span>
        <span id="t1k_2" class="t s1_2">50 Stck </span
        ><span id="t1l_2" class="t s1_2">35,10 </span>
        <span id="t1m_2" class="t s1_2">EUR/ Stck </span>
        <span id="t1n_2" class="t s1_2">1.755,00 </span
        ><span id="t1o_2" class="t s1_2">6 </span>
        <span id="t1p_2" class="t s3_2">Zwischensumme </span
        ><span id="t1q_2" class="t s1_2">9.867,20 </span>
        <span id="t1r_2" class="t s1_2">1.874,77 </span
        ><span id="t1s_2" class="t s3_2">+ 19,00% Mwst. </span
        ><span id="t1t_2" class="t s3_2">von 9.867,20 </span>
        <span id="t1u_2" class="t s3_2">Gesamtsumme </span
        ><span id="t1v_2" class="t s3_2">EUR </span
        ><span id="t1w_2" class="t s1_2">11.741,97 </span>
        <span id="t1x_2" class="t s1_2">... Seite 3 </span>
        <span id="t1y_2" class="t s4_2">Geschäftsführer </span>
        <span id="t1z_2" class="t s4_2">Christian Bauer </span>
        <span id="t20_2" class="t s4_2">Christopher Thielen </span>
        <span id="t21_2" class="t s4_2">KNAPP </span>
        <span id="t22_2" class="t s4_2">Smart Solutions GmbH </span>
        <span id="t23_2" class="t s4_2">Uferstraße 10 </span>
        <span id="t24_2" class="t s4_2">45881 Gelsenkirchen </span>
        <span id="t25_2" class="t s4_2">T +49 209 94 117 - 0 </span>
        <span id="t26_2" class="t s4_2">F +49 209 94 117 - 17 </span>
        <span id="t27_2" class="t s4_2">info@apostore.com </span>
        <span id="t28_2" class="t s4_2">www.apostore.com </span>
        <span id="t29_2" class="t s4_2">Amtsgericht Gelsenkirchen </span>
        <span id="t2a_2" class="t s4_2">HRB 2709 </span>
        <span id="t2b_2" class="t s4_2">USt-Id: DE 212 267 442 </span>
        <span id="t2c_2" class="t s4_2">CHE-357.244.481 MWST </span>
        <span id="t2d_2" class="t s4_2">Commerzbank Kamen </span>
        <span id="t2e_2" class="t s4_2">BLZ 440 800 50 </span>
        <span id="t2f_2" class="t s4_2">Konto Nr.: 375 008 500 </span>
        <span id="t2g_2" class="t s4_2">IBAN DE75 4408 0050 0375 0085 00 </span>
        <span id="t2h_2" class="t s4_2">BIC/SWIFT-Code DRESDEFF440 </span>
      </div>
      <!-- End text definitions -->
    </div>

    <div
      id="p3"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1284px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_3 {
          left: 426px;
          bottom: 1031px;
          letter-spacing: -0.12px;
        }
        #t2_3 {
          left: 469px;
          bottom: 1031px;
        }
        #t3_3 {
          left: 64px;
          bottom: 984px;
          letter-spacing: -0.03px;
          word-spacing: 0.05px;
        }
        #t4_3 {
          left: 222px;
          bottom: 984px;
          letter-spacing: -0.44px;
        }
        #t5_3 {
          left: 256px;
          bottom: 984px;
          letter-spacing: -0.2px;
        }
        #t6_3 {
          left: 64px;
          bottom: 906px;
          letter-spacing: -0.13px;
          word-spacing: -0.02px;
        }
        #t7_3 {
          left: 64px;
          bottom: 888px;
          letter-spacing: -0.15px;
        }
        #t8_3 {
          left: 64px;
          bottom: 871px;
          letter-spacing: -0.15px;
        }
        #t9_3 {
          left: 64px;
          bottom: 854px;
          letter-spacing: -0.14px;
          word-spacing: 0.07px;
        }
        #ta_3 {
          left: 64px;
          bottom: 836px;
          letter-spacing: -0.15px;
          word-spacing: 0.05px;
        }
        #tb_3 {
          left: 64px;
          bottom: 819px;
          letter-spacing: -0.15px;
          word-spacing: 0.09px;
        }
        #tc_3 {
          left: 64px;
          bottom: 801px;
          letter-spacing: -0.15px;
          word-spacing: -0.05px;
        }
        #td_3 {
          left: 64px;
          bottom: 784px;
          letter-spacing: -0.16px;
        }
        #te_3 {
          left: 64px;
          bottom: 745px;
          letter-spacing: -0.16px;
        }
        #tf_3 {
          left: 64px;
          bottom: 727px;
          letter-spacing: -0.15px;
        }
        #tg_3 {
          left: 64px;
          bottom: 710px;
          letter-spacing: -0.23px;
        }
        #th_3 {
          left: 64px;
          bottom: 693px;
          letter-spacing: -0.17px;
        }
        #ti_3 {
          left: 235px;
          bottom: 745px;
          letter-spacing: -0.22px;
          word-spacing: 0.05px;
        }
        #tj_3 {
          left: 235px;
          bottom: 727px;
          letter-spacing: -0.16px;
        }
        #tk_3 {
          left: 235px;
          bottom: 710px;
          letter-spacing: -0.19px;
          word-spacing: 0.03px;
        }
        #tl_3 {
          left: 235px;
          bottom: 693px;
          letter-spacing: -0.26px;
          word-spacing: 0.24px;
        }
        #tm_3 {
          left: 64px;
          bottom: 653px;
          letter-spacing: -0.14px;
        }
        #tn_3 {
          left: 64px;
          bottom: 614px;
          letter-spacing: -0.15px;
        }
        #to_3 {
          left: 65px;
          bottom: 90px;
          letter-spacing: -0.04px;
        }
        #tp_3 {
          left: 65px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #tq_3 {
          left: 65px;
          bottom: 62px;
          letter-spacing: -0.03px;
          word-spacing: -0.23px;
        }
        #tr_3 {
          left: 189px;
          bottom: 90px;
          letter-spacing: -0.05px;
        }
        #ts_3 {
          left: 189px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #tt_3 {
          left: 189px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #tu_3 {
          left: 189px;
          bottom: 47px;
          letter-spacing: -0.04px;
        }
        #tv_3 {
          left: 337px;
          bottom: 90px;
          letter-spacing: -0.11px;
          word-spacing: 0.15px;
        }
        #tw_3 {
          left: 337px;
          bottom: 76px;
          letter-spacing: -0.09px;
          word-spacing: 0.13px;
        }
        #tx_3 {
          left: 337px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #ty_3 {
          left: 337px;
          bottom: 47px;
          letter-spacing: -0.04px;
        }
        #tz_3 {
          left: 477px;
          bottom: 90px;
          letter-spacing: -0.04px;
        }
        #t10_3 {
          left: 477px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #t11_3 {
          left: 477px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #t12_3 {
          left: 477px;
          bottom: 47px;
          letter-spacing: -0.04px;
        }
        #t13_3 {
          left: 638px;
          bottom: 90px;
          letter-spacing: -0.04px;
        }
        #t14_3 {
          left: 638px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #t15_3 {
          left: 638px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #t16_3 {
          left: 638px;
          bottom: 47px;
          letter-spacing: -0.04px;
        }
        #t17_3 {
          left: 638px;
          bottom: 33px;
          letter-spacing: -0.04px;
        }

        .s1 {
          font-size: 14px;
          font-family: Arial_q;
          color: #000;
        }
        .s2 {
          font-size: 16px;
          font-family: Arial-Bold_w;
          color: #000;
        }
        .s3 {
          font-size: 11px;
          font-family: Arial_q;
          color: #646464;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts3" type="text/css">
        @font-face {
          font-family: Arial-Bold_w;
          src: url("fonts/Arial-Bold_w.woff") format("woff");
        }

        @font-face {
          font-family: Arial_q;
          src: url("fonts/Arial_q.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg3Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg3" style="-webkit-user-select: none">
        <object
          width="909"
          height="1284"
          data="3/3.svg"
          type="image/svg+xml"
          id="pdf3"
          style="
            width: 909px;
            height: 1284px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_3" class="t s1">Seite </span
        ><span id="t2_3" class="t s1">3 </span>
        <span id="t3_3" class="t s2">B E S T E L L U N G </span
        ><span id="t4_3" class="t s2">Nr. </span
        ><span id="t5_3" class="t s2">APOB11157 </span>
        <span id="t6_3" class="t s1"
          >Wir bestellen ausschließlich auf Basis der „Allgemeine
          Einkaufsbedingungen der KNAPP Smart Solutions GmbH“
        </span>
        <span id="t7_3" class="t s1"
          >(KSO) in der jeweils gültigen Fassung, aktuell einzusehen unter
          https://www.apostore.com/einkaufsbedingungen .
        </span>
        <span id="t8_3" class="t s1"
          >Etwaige Geschäftsbedingungen des Lieferanten entfalten, vorbehaltlich
          einer ausdrücklichen unterfertigten
        </span>
        <span id="t9_3" class="t s1"
          >schriftlichen Zustimmung von KSO im Einzelfall, zwischen den
          Vertragsparteien keinerlei Rechtswirkung. Dies gilt
        </span>
        <span id="ta_3" class="t s1"
          >ungeachtet der Art oder dem Zeitpunkt ihrer Übermittlung (inklusive
          einer mitunter von technischen Systemen
        </span>
        <span id="tb_3" class="t s1"
          >bedingten automatischen Übermittlung) und unabhängig davon, ob in den
          Angebotsunterlagen darauf verwiesen
        </span>
        <span id="tc_3" class="t s1"
          >wird. Eine Bezugnahme von KSO in der Bestellung auf
          Angebotsunterlagen des Lieferanten bedeuten keine
        </span>
        <span id="td_3" class="t s1"
          >Anerkennung der Geschäftsbedingungen des Lieferanten.
        </span>
        <span id="te_3" class="t s1">Lieferbedingung </span>
        <span id="tf_3" class="t s1">gew. Liefertermin </span>
        <span id="tg_3" class="t s1">Verpackung </span>
        <span id="th_3" class="t s1">Zahlungsbedingung </span>
        <span id="ti_3" class="t s1">Ab Werk </span>
        <span id="tj_3" class="t s1">04.01.2023 </span>
        <span id="tk_3" class="t s1">auschließlich Verpackung </span>
        <span id="tl_3" class="t s1">14 Tage/3% Skonto-30 Tage/netto </span>
        <span id="tm_3" class="t s1"
          >Dieses Schreiben wurde digital erstellt und ist auch ohne
          Unterschrift gültig.
        </span>
        <span id="tn_3" class="t s1">Mit freundlichen Grüßen </span>
        <span id="to_3" class="t s3">Geschäftsführer </span>
        <span id="tp_3" class="t s3">Christian Bauer </span>
        <span id="tq_3" class="t s3">Christopher Thielen </span>
        <span id="tr_3" class="t s3">KNAPP </span>
        <span id="ts_3" class="t s3">Smart Solutions GmbH </span>
        <span id="tt_3" class="t s3">Uferstraße 10 </span>
        <span id="tu_3" class="t s3">45881 Gelsenkirchen </span>
        <span id="tv_3" class="t s3">T +49 209 94 117 - 0 </span>
        <span id="tw_3" class="t s3">F +49 209 94 117 - 17 </span>
        <span id="tx_3" class="t s3">info@apostore.com </span>
        <span id="ty_3" class="t s3">www.apostore.com </span>
        <span id="tz_3" class="t s3">Amtsgericht Gelsenkirchen </span>
        <span id="t10_3" class="t s3">HRB 2709 </span>
        <span id="t11_3" class="t s3">USt-Id: DE 212 267 442 </span>
        <span id="t12_3" class="t s3">CHE-357.244.481 MWST </span>
        <span id="t13_3" class="t s3">Commerzbank Kamen </span>
        <span id="t14_3" class="t s3">BLZ 440 800 50 </span>
        <span id="t15_3" class="t s3">Konto Nr.: 375 008 500 </span>
        <span id="t16_3" class="t s3">IBAN DE75 4408 0050 0375 0085 00 </span>
        <span id="t17_3" class="t s3">BIC/SWIFT-Code DRESDEFF440 </span>
      </div>
      <!-- End text definitions -->
    </div>
  </body>
</html>
  `,
  '53835-2-Bestellung APOB11427': `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
    <meta charset="utf-8" />
  </head>

  <body style="margin: 0">
    <div
      id="p1"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1284px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_1 {
          left: 64px;
          bottom: 1049px;
          letter-spacing: -0.18px;
        }
        #t2_1 {
          left: 64px;
          bottom: 1031px;
          letter-spacing: -0.16px;
          word-spacing: -0.39px;
        }
        #t3_1 {
          left: 64px;
          bottom: 1014px;
          letter-spacing: -0.18px;
          word-spacing: 0.23px;
        }
        #t4_1 {
          left: 64px;
          bottom: 979px;
          letter-spacing: -0.18px;
        }
        #t5_1 {
          left: 426px;
          bottom: 956px;
          letter-spacing: -0.03px;
          word-spacing: 0.05px;
        }
        #t6_1 {
          left: 584px;
          bottom: 956px;
          letter-spacing: -0.44px;
        }
        #t7_1 {
          left: 618px;
          bottom: 956px;
          letter-spacing: -0.09px;
        }
        #t8_1 {
          left: 426px;
          bottom: 915px;
          letter-spacing: 0.06px;
          word-spacing: 0.11px;
        }
        #t9_1 {
          left: 426px;
          bottom: 897px;
          letter-spacing: 0.17px;
        }
        #ta_1 {
          left: 426px;
          bottom: 878px;
          letter-spacing: 0.2px;
        }
        #tb_1 {
          left: 426px;
          bottom: 860px;
          letter-spacing: 0.17px;
        }
        #tc_1 {
          left: 426px;
          bottom: 842px;
          letter-spacing: 0.19px;
        }
        #td_1 {
          left: 426px;
          bottom: 824px;
          letter-spacing: 0.19px;
        }
        #te_1 {
          left: 426px;
          bottom: 806px;
          letter-spacing: 0.18px;
        }
        #tf_1 {
          left: 426px;
          bottom: 787px;
          letter-spacing: 0.17px;
          word-spacing: -0.53px;
        }
        #tg_1 {
          left: 426px;
          bottom: 769px;
          letter-spacing: 0.19px;
        }
        #th_1 {
          left: 596px;
          bottom: 915px;
          letter-spacing: -0.35px;
        }
        #ti_1 {
          left: 596px;
          bottom: 898px;
          letter-spacing: -0.18px;
        }
        #tj_1 {
          left: 596px;
          bottom: 880px;
          letter-spacing: -0.18px;
        }
        #tk_1 {
          left: 596px;
          bottom: 863px;
          letter-spacing: -0.2px;
        }
        #tl_1 {
          left: 596px;
          bottom: 845px;
          letter-spacing: -0.16px;
        }
        #tm_1 {
          left: 596px;
          bottom: 828px;
          letter-spacing: -0.17px;
        }
        #tn_1 {
          left: 596px;
          bottom: 811px;
          letter-spacing: -0.15px;
        }
        #to_1 {
          left: 596px;
          bottom: 793px;
          letter-spacing: -0.18px;
        }
        #tp_1 {
          left: 596px;
          bottom: 776px;
          letter-spacing: -0.16px;
        }
        #tq_1 {
          left: 64px;
          bottom: 1073px;
          letter-spacing: 0.11px;
        }
        #tr_1 {
          left: 64px;
          bottom: 752px;
          letter-spacing: -0.37px;
        }
        #ts_1 {
          left: 147px;
          bottom: 752px;
          letter-spacing: -0.16px;
        }
        #tt_1 {
          left: 64px;
          bottom: 734px;
          letter-spacing: -0.14px;
        }
        #tu_1 {
          left: 147px;
          bottom: 734px;
          letter-spacing: -0.16px;
        }
        #tv_1 {
          left: 426px;
          bottom: 678px;
          letter-spacing: -0.16px;
        }
        #tw_1 {
          left: 426px;
          bottom: 643px;
          letter-spacing: -0.12px;
        }
        #tx_1 {
          left: 469px;
          bottom: 643px;
        }
        #ty_1 {
          left: 64px;
          bottom: 576px;
          letter-spacing: 0.19px;
        }
        #tz_1 {
          left: 64px;
          bottom: 555px;
          letter-spacing: -0.18px;
          word-spacing: 0.01px;
        }
        #t10_1 {
          left: 64px;
          bottom: 520px;
          letter-spacing: -0.17px;
        }
        #t11_1 {
          left: 64px;
          bottom: 503px;
          letter-spacing: -0.63px;
          word-spacing: 0.54px;
        }
        #t12_1 {
          left: 64px;
          bottom: 485px;
          letter-spacing: -0.16px;
        }
        #t13_1 {
          left: 64px;
          bottom: 442px;
          letter-spacing: -0.17px;
          word-spacing: -0.18px;
        }
        #t14_1 {
          left: 64px;
          bottom: 424px;
          letter-spacing: -0.15px;
        }
        #t15_1 {
          left: 64px;
          bottom: 407px;
          letter-spacing: -0.15px;
          word-spacing: -0.03px;
        }
        #t16_1 {
          left: 64px;
          bottom: 390px;
          letter-spacing: -0.15px;
        }
        #t17_1 {
          left: 64px;
          bottom: 372px;
          letter-spacing: -0.15px;
        }
        #t18_1 {
          left: 64px;
          bottom: 355px;
          letter-spacing: -0.15px;
        }
        #t19_1 {
          left: 64px;
          bottom: 337px;
          letter-spacing: -0.18px;
        }
        #t1a_1 {
          left: 64px;
          bottom: 320px;
          letter-spacing: -0.15px;
          word-spacing: 0.01px;
        }
        #t1b_1 {
          left: 64px;
          bottom: 303px;
          letter-spacing: -0.16px;
        }
        #t1c_1 {
          left: 64px;
          bottom: 268px;
        }
        #t1d_1 {
          left: 127px;
          bottom: 228px;
          letter-spacing: -0.13px;
        }
        #t1e_1 {
          left: 64px;
          bottom: 228px;
          letter-spacing: -0.19px;
        }
        #t1f_1 {
          left: 490px;
          bottom: 228px;
          letter-spacing: -0.2px;
        }
        #t1g_1 {
          left: 639px;
          bottom: 228px;
          letter-spacing: -0.15px;
        }
        #t1h_1 {
          left: 793px;
          bottom: 228px;
          letter-spacing: -0.19px;
        }
        #t1i_1 {
          left: 783px;
          bottom: 147px;
          letter-spacing: -0.13px;
        }
        #t1j_1 {
          left: 65px;
          bottom: 90px;
          letter-spacing: -0.04px;
        }
        #t1k_1 {
          left: 65px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #t1l_1 {
          left: 65px;
          bottom: 62px;
          letter-spacing: -0.03px;
          word-spacing: -0.23px;
        }
        #t1m_1 {
          left: 189px;
          bottom: 90px;
          letter-spacing: -0.05px;
        }
        #t1n_1 {
          left: 189px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #t1o_1 {
          left: 189px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #t1p_1 {
          left: 189px;
          bottom: 47px;
          letter-spacing: -0.04px;
        }
        #t1q_1 {
          left: 337px;
          bottom: 90px;
          letter-spacing: -0.11px;
          word-spacing: 0.15px;
        }
        #t1r_1 {
          left: 337px;
          bottom: 76px;
          letter-spacing: -0.09px;
          word-spacing: 0.13px;
        }
        #t1s_1 {
          left: 337px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #t1t_1 {
          left: 337px;
          bottom: 47px;
          letter-spacing: -0.04px;
        }
        #t1u_1 {
          left: 477px;
          bottom: 90px;
          letter-spacing: -0.04px;
        }
        #t1v_1 {
          left: 477px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #t1w_1 {
          left: 477px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #t1x_1 {
          left: 638px;
          bottom: 90px;
          letter-spacing: -0.04px;
        }
        #t1y_1 {
          left: 638px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #t1z_1 {
          left: 638px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #t20_1 {
          left: 638px;
          bottom: 47px;
          letter-spacing: -0.04px;
        }
        #t21_1 {
          left: 638px;
          bottom: 33px;
          letter-spacing: -0.04px;
        }

        .s1_1 {
          font-size: 14px;
          font-family: Arial_q;
          color: #000;
        }
        .s2_1 {
          font-size: 16px;
          font-family: Arial-Bold_w;
          color: #000;
        }
        .s3_1 {
          font-size: 13px;
          font-family: Arial-Bold_w;
          color: #000;
        }
        .s4_1 {
          font-size: 10px;
          font-family: Arial_q;
          color: #646464;
        }
        .s5_1 {
          font-size: 11px;
          font-family: Arial_q;
          color: #646464;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts1" type="text/css">
        @font-face {
          font-family: Arial-Bold_w;
          src: url("fonts/Arial-Bold_w.woff") format("woff");
        }

        @font-face {
          font-family: Arial_q;
          src: url("fonts/Arial_q.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg1Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg1" style="-webkit-user-select: none">
        <object
          width="909"
          height="1284"
          data="1/1.svg"
          type="image/svg+xml"
          id="pdf1"
          style="
            width: 909px;
            height: 1284px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_1" class="t s1_1">Maxon Motor GmbH </span>
        <span id="t2_1" class="t s1_1">Frau Ana-Maria Ganga </span>
        <span id="t3_1" class="t s1_1">Truderinger Straße 210 </span>
        <span id="t4_1" class="t s1_1">81825 München </span>
        <span id="t5_1" class="t s2_1">B E S T E L L U N G </span
        ><span id="t6_1" class="t s2_1">Nr. </span
        ><span id="t7_1" class="t s2_1">APOB11427 </span>
        <span id="t8_1" class="t s3_1">Vorgang Nr. </span>
        <span id="t9_1" class="t s3_1">Adress-Nr. </span>
        <span id="ta_1" class="t s3_1">Unsere Kunden-Nr. </span>
        <span id="tb_1" class="t s3_1">Bearbeiter </span>
        <span id="tc_1" class="t s3_1">Durchwahl </span>
        <span id="td_1" class="t s3_1">E-Mailadresse </span>
        <span id="te_1" class="t s3_1">Unser Zeichen </span>
        <span id="tf_1" class="t s3_1">Ihr Angebot </span>
        <span id="tg_1" class="t s3_1">vom </span>
        <span id="th_1" class="t s1_1">APOB11427 </span>
        <span id="ti_1" class="t s1_1">2153 </span>
        <span id="tj_1" class="t s1_1">53835 </span>
        <span id="tk_1" class="t s1_1">RUCKI </span>
        <span id="tl_1" class="t s1_1">-47 </span>
        <span id="tm_1" class="t s1_1">bernd.rucki@knapp.com </span>
        <span id="tn_1" class="t s1_1">ru </span>
        <span id="to_1" class="t s1_1">23VIKL090 </span>
        <span id="tp_1" class="t s1_1">24.03.2023 </span>
        <span id="tq_1" class="t s4_1"
          >KNAPP Smart Solutions GmbH, Uferstraße 10, 45881 Gelsenkirchen
        </span>
        <span id="tr_1" class="t s1_1">Telefon </span
        ><span id="ts_1" class="t s1_1">089 / 420493-0 </span>
        <span id="tt_1" class="t s1_1">Fax </span
        ><span id="tu_1" class="t s1_1">089 / 420493-40 </span>
        <span id="tv_1" class="t s1_1">Gelsenkirchen, 20. März 2023 </span>
        <span id="tw_1" class="t s1_1">Seite </span
        ><span id="tx_1" class="t s1_1">1 </span>
        <span id="ty_1" class="t s3_1">Lieferungsempfänger: </span>
        <span id="tz_1" class="t s1_1">KNAPP Smart Solutions GmbH </span>
        <span id="t10_1" class="t s1_1">Am Bugapark 3c </span>
        <span id="t11_1" class="t s1_1">Tor 6 </span>
        <span id="t12_1" class="t s1_1">45899 Gelsenkirchen </span>
        <span id="t13_1" class="t s1_1"
          >Achtung! Durch die Verschmelzung der bisherigen
          Schwestergesellschaften KHT , KSO und AdCommander am
        </span>
        <span id="t14_1" class="t s1_1"
          >22.12.2022 haben sich die Firmenbezeichnung und
          Umsatzsteueridentifikationsnummer (USt-Id.) geändert. Zudem
        </span>
        <span id="t15_1" class="t s1_1"
          >wurden die AGB, Lieferadresse, Verpackungsrichtlinie und
          Email-Adressen der Gesellschaften zusammengeführt.
        </span>
        <span id="t16_1" class="t s1_1"
          >Aus diesem Grund bitten wir Sie, die neue Firmenbezeichnung und
          USt-Id. ab sofort in den Rechnungen
        </span>
        <span id="t17_1" class="t s1_1"
          >anzugeben. Details entnehmen Sie bitte unserem postalischen Schreiben
          oder erfragen Sie bei Ihrem zuständigen
        </span>
        <span id="t18_1" class="t s1_1">Einkaufskontakt. </span>
        <span id="t19_1" class="t s1_1">############################ </span>
        <span id="t1a_1" class="t s1_1"
          >Wir bitten um eine Auftragsbestätigung an die E-Mail-Adresse
          „kso.einkauf@knapp.com“ spätestens drei Tage
        </span>
        <span id="t1b_1" class="t s1_1"
          >nach Bestelleingang. Rechnungen werden bevorzugt digital auf
          „kso.accounting@knapp.com“ empfangen.
        </span>
        <span id="t1c_1" class="t s1_1">. </span>
        <span id="t1d_1" class="t s1_1">Artikel </span
        ><span id="t1e_1" class="t s1_1">Pos </span
        ><span id="t1f_1" class="t s1_1">Menge </span
        ><span id="t1g_1" class="t s1_1">Einzelpreis </span
        ><span id="t1h_1" class="t s1_1">Gesamt </span>
        <span id="t1i_1" class="t s1_1">... Seite 2 </span>
        <span id="t1j_1" class="t s5_1">Geschäftsführer </span>
        <span id="t1k_1" class="t s5_1">Christian Bauer </span>
        <span id="t1l_1" class="t s5_1">Christopher Thielen </span>
        <span id="t1m_1" class="t s5_1">KNAPP </span>
        <span id="t1n_1" class="t s5_1">Smart Solutions GmbH </span>
        <span id="t1o_1" class="t s5_1">Uferstraße 10 </span>
        <span id="t1p_1" class="t s5_1">45881 Gelsenkirchen </span>
        <span id="t1q_1" class="t s5_1">T +49 209 94 117 - 0 </span>
        <span id="t1r_1" class="t s5_1">F +49 209 94 117 - 17 </span>
        <span id="t1s_1" class="t s5_1">kso.info@knapp.com </span>
        <span id="t1t_1" class="t s5_1">www.knapp.com </span>
        <span id="t1u_1" class="t s5_1">Amtsgericht Gelsenkirchen </span>
        <span id="t1v_1" class="t s5_1">HRB 17444 </span>
        <span id="t1w_1" class="t s5_1">USt-Id: DE 300 827 208 </span>
        <span id="t1x_1" class="t s5_1">Commerzbank Kamen </span>
        <span id="t1y_1" class="t s5_1">BLZ 440 800 50 </span>
        <span id="t1z_1" class="t s5_1">Konto Nr.: 375 008 500 </span>
        <span id="t20_1" class="t s5_1">IBAN DE75 4408 0050 0375 0085 00 </span>
        <span id="t21_1" class="t s5_1">BIC/SWIFT-Code DRESDEFF440 </span>
      </div>
      <!-- End text definitions -->
    </div>

    <div
      id="p2"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1284px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_2 {
          left: 426px;
          bottom: 1031px;
          letter-spacing: -0.12px;
        }
        #t2_2 {
          left: 469px;
          bottom: 1031px;
        }
        #t3_2 {
          left: 64px;
          bottom: 984px;
          letter-spacing: -0.03px;
          word-spacing: 0.05px;
        }
        #t4_2 {
          left: 222px;
          bottom: 984px;
          letter-spacing: -0.44px;
        }
        #t5_2 {
          left: 256px;
          bottom: 984px;
          letter-spacing: -0.09px;
        }
        #t6_2 {
          left: 127px;
          bottom: 910px;
          letter-spacing: -0.13px;
        }
        #t7_2 {
          left: 64px;
          bottom: 910px;
          letter-spacing: -0.19px;
        }
        #t8_2 {
          left: 490px;
          bottom: 910px;
          letter-spacing: -0.2px;
        }
        #t9_2 {
          left: 639px;
          bottom: 910px;
          letter-spacing: -0.15px;
        }
        #ta_2 {
          left: 793px;
          bottom: 910px;
          letter-spacing: -0.19px;
        }
        #tb_2 {
          left: 127px;
          bottom: 881px;
          letter-spacing: -0.16px;
          word-spacing: -0.37px;
        }
        #tc_2 {
          left: 127px;
          bottom: 864px;
          letter-spacing: -0.17px;
        }
        #td_2 {
          left: 127px;
          bottom: 846px;
          letter-spacing: -0.14px;
        }
        #te_2 {
          left: 127px;
          bottom: 829px;
          letter-spacing: -0.13px;
        }
        #tf_2 {
          left: 127px;
          bottom: 812px;
          letter-spacing: -0.14px;
        }
        #tg_2 {
          left: 486px;
          bottom: 881px;
          letter-spacing: -0.16px;
        }
        #th_2 {
          left: 671px;
          bottom: 881px;
          letter-spacing: -0.16px;
        }
        #ti_2 {
          left: 643px;
          bottom: 864px;
          letter-spacing: -0.17px;
        }
        #tj_2 {
          left: 799px;
          bottom: 881px;
          letter-spacing: -0.17px;
        }
        #tk_2 {
          left: 64px;
          bottom: 881px;
        }
        #tl_2 {
          left: 127px;
          bottom: 759px;
          letter-spacing: -0.16px;
          word-spacing: -0.37px;
        }
        #tm_2 {
          left: 127px;
          bottom: 742px;
          letter-spacing: -0.17px;
        }
        #tn_2 {
          left: 127px;
          bottom: 724px;
          letter-spacing: -0.14px;
        }
        #to_2 {
          left: 127px;
          bottom: 707px;
          letter-spacing: -0.13px;
        }
        #tp_2 {
          left: 127px;
          bottom: 689px;
          letter-spacing: -0.14px;
        }
        #tq_2 {
          left: 486px;
          bottom: 759px;
          letter-spacing: -0.16px;
        }
        #tr_2 {
          left: 673px;
          bottom: 759px;
          letter-spacing: -0.41px;
        }
        #ts_2 {
          left: 643px;
          bottom: 742px;
          letter-spacing: -0.17px;
        }
        #tt_2 {
          left: 799px;
          bottom: 759px;
          letter-spacing: -0.17px;
        }
        #tu_2 {
          left: 64px;
          bottom: 759px;
        }
        #tv_2 {
          left: 127px;
          bottom: 637px;
          letter-spacing: -0.16px;
          word-spacing: -0.37px;
        }
        #tw_2 {
          left: 127px;
          bottom: 620px;
          letter-spacing: -0.17px;
        }
        #tx_2 {
          left: 127px;
          bottom: 602px;
          letter-spacing: -0.16px;
        }
        #ty_2 {
          left: 127px;
          bottom: 585px;
          letter-spacing: -0.13px;
        }
        #tz_2 {
          left: 127px;
          bottom: 567px;
          letter-spacing: -0.14px;
        }
        #t10_2 {
          left: 478px;
          bottom: 637px;
          letter-spacing: -0.16px;
        }
        #t11_2 {
          left: 671px;
          bottom: 637px;
          letter-spacing: -0.16px;
        }
        #t12_2 {
          left: 643px;
          bottom: 620px;
          letter-spacing: -0.17px;
        }
        #t13_2 {
          left: 788px;
          bottom: 637px;
          letter-spacing: -0.16px;
        }
        #t14_2 {
          left: 64px;
          bottom: 637px;
        }
        #t15_2 {
          left: 127px;
          bottom: 515px;
          letter-spacing: -0.16px;
          word-spacing: -0.37px;
        }
        #t16_2 {
          left: 127px;
          bottom: 498px;
          letter-spacing: -0.18px;
        }
        #t17_2 {
          left: 127px;
          bottom: 480px;
          letter-spacing: -0.16px;
        }
        #t18_2 {
          left: 127px;
          bottom: 463px;
          letter-spacing: -0.13px;
        }
        #t19_2 {
          left: 127px;
          bottom: 445px;
          letter-spacing: -0.14px;
        }
        #t1a_2 {
          left: 478px;
          bottom: 515px;
          letter-spacing: -0.16px;
        }
        #t1b_2 {
          left: 671px;
          bottom: 515px;
          letter-spacing: -0.16px;
        }
        #t1c_2 {
          left: 643px;
          bottom: 498px;
          letter-spacing: -0.17px;
        }
        #t1d_2 {
          left: 788px;
          bottom: 515px;
          letter-spacing: -0.16px;
        }
        #t1e_2 {
          left: 64px;
          bottom: 515px;
        }
        #t1f_2 {
          left: 127px;
          bottom: 393px;
          letter-spacing: -0.16px;
          word-spacing: -0.37px;
        }
        #t1g_2 {
          left: 127px;
          bottom: 376px;
          letter-spacing: -0.17px;
        }
        #t1h_2 {
          left: 127px;
          bottom: 358px;
          letter-spacing: -0.15px;
        }
        #t1i_2 {
          left: 127px;
          bottom: 341px;
          letter-spacing: -0.13px;
        }
        #t1j_2 {
          left: 127px;
          bottom: 323px;
          letter-spacing: -0.14px;
        }
        #t1k_2 {
          left: 486px;
          bottom: 393px;
          letter-spacing: -0.16px;
        }
        #t1l_2 {
          left: 671px;
          bottom: 393px;
          letter-spacing: -0.16px;
        }
        #t1m_2 {
          left: 643px;
          bottom: 376px;
          letter-spacing: -0.17px;
        }
        #t1n_2 {
          left: 788px;
          bottom: 393px;
          letter-spacing: -0.16px;
        }
        #t1o_2 {
          left: 64px;
          bottom: 393px;
        }
        #t1p_2 {
          left: 127px;
          bottom: 271px;
          letter-spacing: -0.16px;
          word-spacing: -0.37px;
        }
        #t1q_2 {
          left: 127px;
          bottom: 253px;
          letter-spacing: -0.17px;
        }
        #t1r_2 {
          left: 127px;
          bottom: 236px;
          letter-spacing: -0.14px;
        }
        #t1s_2 {
          left: 127px;
          bottom: 219px;
          letter-spacing: -0.13px;
        }
        #t1t_2 {
          left: 127px;
          bottom: 201px;
          letter-spacing: -0.14px;
        }
        #t1u_2 {
          left: 486px;
          bottom: 271px;
          letter-spacing: -0.16px;
        }
        #t1v_2 {
          left: 671px;
          bottom: 271px;
          letter-spacing: -0.16px;
        }
        #t1w_2 {
          left: 643px;
          bottom: 253px;
          letter-spacing: -0.17px;
        }
        #t1x_2 {
          left: 788px;
          bottom: 271px;
          letter-spacing: -0.16px;
        }
        #t1y_2 {
          left: 64px;
          bottom: 271px;
        }
        #t1z_2 {
          left: 783px;
          bottom: 147px;
          letter-spacing: -0.13px;
        }
        #t20_2 {
          left: 65px;
          bottom: 90px;
          letter-spacing: -0.04px;
        }
        #t21_2 {
          left: 65px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #t22_2 {
          left: 65px;
          bottom: 62px;
          letter-spacing: -0.03px;
          word-spacing: -0.23px;
        }
        #t23_2 {
          left: 189px;
          bottom: 90px;
          letter-spacing: -0.05px;
        }
        #t24_2 {
          left: 189px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #t25_2 {
          left: 189px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #t26_2 {
          left: 189px;
          bottom: 47px;
          letter-spacing: -0.04px;
        }
        #t27_2 {
          left: 337px;
          bottom: 90px;
          letter-spacing: -0.11px;
          word-spacing: 0.15px;
        }
        #t28_2 {
          left: 337px;
          bottom: 76px;
          letter-spacing: -0.09px;
          word-spacing: 0.13px;
        }
        #t29_2 {
          left: 337px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #t2a_2 {
          left: 337px;
          bottom: 47px;
          letter-spacing: -0.04px;
        }
        #t2b_2 {
          left: 477px;
          bottom: 90px;
          letter-spacing: -0.04px;
        }
        #t2c_2 {
          left: 477px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #t2d_2 {
          left: 477px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #t2e_2 {
          left: 638px;
          bottom: 90px;
          letter-spacing: -0.04px;
        }
        #t2f_2 {
          left: 638px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #t2g_2 {
          left: 638px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #t2h_2 {
          left: 638px;
          bottom: 47px;
          letter-spacing: -0.04px;
        }
        #t2i_2 {
          left: 638px;
          bottom: 33px;
          letter-spacing: -0.04px;
        }

        .s1_2 {
          font-size: 14px;
          font-family: Arial_q;
          color: #000;
        }
        .s2_2 {
          font-size: 16px;
          font-family: Arial-Bold_w;
          color: #000;
        }
        .s3_2 {
          font-size: 11px;
          font-family: Arial_q;
          color: #646464;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts2" type="text/css">
        @font-face {
          font-family: Arial-Bold_w;
          src: url("fonts/Arial-Bold_w.woff") format("woff");
        }

        @font-face {
          font-family: Arial_q;
          src: url("fonts/Arial_q.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg2Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg2" style="-webkit-user-select: none">
        <object
          width="909"
          height="1284"
          data="2/2.svg"
          type="image/svg+xml"
          id="pdf2"
          style="
            width: 909px;
            height: 1284px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_2" class="t s1_2">Seite </span
        ><span id="t2_2" class="t s1_2">2 </span>
        <span id="t3_2" class="t s2_2">B E S T E L L U N G </span
        ><span id="t4_2" class="t s2_2">Nr. </span
        ><span id="t5_2" class="t s2_2">APOB11427 </span>
        <span id="t6_2" class="t s1_2">Artikel </span
        ><span id="t7_2" class="t s1_2">Pos </span
        ><span id="t8_2" class="t s1_2">Menge </span
        ><span id="t9_2" class="t s1_2">Einzelpreis </span
        ><span id="ta_2" class="t s1_2">Gesamt </span>
        <span id="tb_2" class="t s1_2">Unsere Artikelnr.: 21.117.06 </span>
        <span id="tc_2" class="t s1_2">Signal-Kabel, MACS6 </span>
        <span id="td_2" class="t s1_2">8-polig, 500 mm, </span>
        <span id="te_2" class="t s1_2">einseitig konfektioniert </span>
        <span id="tf_2" class="t s1_2">Artikelnr.: 520853 </span>
        <span id="tg_2" class="t s1_2">40 Stck </span
        ><span id="th_2" class="t s1_2">14,60 </span>
        <span id="ti_2" class="t s1_2">EUR/ Stck </span>
        <span id="tj_2" class="t s1_2">584,00 </span
        ><span id="tk_2" class="t s1_2">1 </span>
        <span id="tl_2" class="t s1_2">Unsere Artikelnr.: 21.117.04 </span>
        <span id="tm_2" class="t s1_2">Power-Kabel, Steuerung, MACS6 </span>
        <span id="tn_2" class="t s1_2">2-polig, 500 mm, </span>
        <span id="to_2" class="t s1_2">einseitig konfektioniert </span>
        <span id="tp_2" class="t s1_2">Artikelnr.: 275829 </span>
        <span id="tq_2" class="t s1_2">50 Stck </span
        ><span id="tr_2" class="t s1_2">11,80 </span>
        <span id="ts_2" class="t s1_2">EUR/ Stck </span>
        <span id="tt_2" class="t s1_2">590,00 </span
        ><span id="tu_2" class="t s1_2">2 </span>
        <span id="tv_2" class="t s1_2">Unsere Artikelnr.: 21.117.09 </span>
        <span id="tw_2" class="t s1_2">Motor-Kabel, MACS6 </span>
        <span id="tx_2" class="t s1_2">500 mm, </span>
        <span id="ty_2" class="t s1_2">einseitig konfektioniert </span>
        <span id="tz_2" class="t s1_2">Artikelnr.: 275851 </span>
        <span id="t10_2" class="t s1_2">120 Stck </span
        ><span id="t11_2" class="t s1_2">25,30 </span>
        <span id="t12_2" class="t s1_2">EUR/ Stck </span>
        <span id="t13_2" class="t s1_2">3.036,00 </span
        ><span id="t14_2" class="t s1_2">3 </span>
        <span id="t15_2" class="t s1_2">Unsere Artikelnr.: 21.117.10 </span>
        <span id="t16_2" class="t s1_2">Geber-Kabel, MACS6 </span>
        <span id="t17_2" class="t s1_2">500 mm, </span>
        <span id="t18_2" class="t s1_2">einseitig konfektioniert </span>
        <span id="t19_2" class="t s1_2">Artikelnr.: 520852 </span>
        <span id="t1a_2" class="t s1_2">120 Stck </span
        ><span id="t1b_2" class="t s1_2">17,40 </span>
        <span id="t1c_2" class="t s1_2">EUR/ Stck </span>
        <span id="t1d_2" class="t s1_2">2.088,00 </span
        ><span id="t1e_2" class="t s1_2">4 </span>
        <span id="t1f_2" class="t s1_2">Unsere Artikelnr.: 21.117.07 </span>
        <span id="t1g_2" class="t s1_2">Signal-Kabel, MACS6 </span>
        <span id="t1h_2" class="t s1_2">10-polig, 500 mm, </span>
        <span id="t1i_2" class="t s1_2">einseitig konfektioniert </span>
        <span id="t1j_2" class="t s1_2">Artikelnr.: 696837 </span>
        <span id="t1k_2" class="t s1_2">50 Stck </span
        ><span id="t1l_2" class="t s1_2">25,30 </span>
        <span id="t1m_2" class="t s1_2">EUR/ Stck </span>
        <span id="t1n_2" class="t s1_2">1.265,00 </span
        ><span id="t1o_2" class="t s1_2">5 </span>
        <span id="t1p_2" class="t s1_2">Unsere Artikelnr.: 21.117.08 </span>
        <span id="t1q_2" class="t s1_2">Power-Kabel, Motor, MACS6 </span>
        <span id="t1r_2" class="t s1_2">4-polig, 500 mm, </span>
        <span id="t1s_2" class="t s1_2">einseitig konfektioniert </span>
        <span id="t1t_2" class="t s1_2">Artikelnr.: 699485 </span>
        <span id="t1u_2" class="t s1_2">50 Stck </span
        ><span id="t1v_2" class="t s1_2">40,60 </span>
        <span id="t1w_2" class="t s1_2">EUR/ Stck </span>
        <span id="t1x_2" class="t s1_2">2.030,00 </span
        ><span id="t1y_2" class="t s1_2">6 </span>
        <span id="t1z_2" class="t s1_2">... Seite 3 </span>
        <span id="t20_2" class="t s3_2">Geschäftsführer </span>
        <span id="t21_2" class="t s3_2">Christian Bauer </span>
        <span id="t22_2" class="t s3_2">Christopher Thielen </span>
        <span id="t23_2" class="t s3_2">KNAPP </span>
        <span id="t24_2" class="t s3_2">Smart Solutions GmbH </span>
        <span id="t25_2" class="t s3_2">Uferstraße 10 </span>
        <span id="t26_2" class="t s3_2">45881 Gelsenkirchen </span>
        <span id="t27_2" class="t s3_2">T +49 209 94 117 - 0 </span>
        <span id="t28_2" class="t s3_2">F +49 209 94 117 - 17 </span>
        <span id="t29_2" class="t s3_2">kso.info@knapp.com </span>
        <span id="t2a_2" class="t s3_2">www.knapp.com </span>
        <span id="t2b_2" class="t s3_2">Amtsgericht Gelsenkirchen </span>
        <span id="t2c_2" class="t s3_2">HRB 17444 </span>
        <span id="t2d_2" class="t s3_2">USt-Id: DE 300 827 208 </span>
        <span id="t2e_2" class="t s3_2">Commerzbank Kamen </span>
        <span id="t2f_2" class="t s3_2">BLZ 440 800 50 </span>
        <span id="t2g_2" class="t s3_2">Konto Nr.: 375 008 500 </span>
        <span id="t2h_2" class="t s3_2">IBAN DE75 4408 0050 0375 0085 00 </span>
        <span id="t2i_2" class="t s3_2">BIC/SWIFT-Code DRESDEFF440 </span>
      </div>
      <!-- End text definitions -->
    </div>

    <div
      id="p3"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1284px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_3 {
          left: 426px;
          bottom: 1031px;
          letter-spacing: -0.12px;
        }
        #t2_3 {
          left: 469px;
          bottom: 1031px;
        }
        #t3_3 {
          left: 64px;
          bottom: 984px;
          letter-spacing: -0.03px;
          word-spacing: 0.05px;
        }
        #t4_3 {
          left: 222px;
          bottom: 984px;
          letter-spacing: -0.44px;
        }
        #t5_3 {
          left: 256px;
          bottom: 984px;
          letter-spacing: -0.09px;
        }
        #t6_3 {
          left: 431px;
          bottom: 910px;
          letter-spacing: 0.21px;
        }
        #t7_3 {
          left: 788px;
          bottom: 910px;
          letter-spacing: -0.16px;
        }
        #t8_3 {
          left: 788px;
          bottom: 888px;
          letter-spacing: -0.16px;
        }
        #t9_3 {
          left: 435px;
          bottom: 888px;
          letter-spacing: 0.18px;
        }
        #ta_3 {
          left: 540px;
          bottom: 888px;
          letter-spacing: 0.18px;
        }
        #tb_3 {
          left: 431px;
          bottom: 862px;
          letter-spacing: 0.22px;
        }
        #tc_3 {
          left: 534px;
          bottom: 862px;
          letter-spacing: 0.26px;
        }
        #td_3 {
          left: 781px;
          bottom: 862px;
          letter-spacing: -0.29px;
        }
        #te_3 {
          left: 64px;
          bottom: 811px;
          letter-spacing: -0.13px;
          word-spacing: -0.02px;
        }
        #tf_3 {
          left: 64px;
          bottom: 794px;
          letter-spacing: -0.15px;
        }
        #tg_3 {
          left: 64px;
          bottom: 777px;
          letter-spacing: -0.15px;
        }
        #th_3 {
          left: 64px;
          bottom: 759px;
          letter-spacing: -0.14px;
          word-spacing: 0.07px;
        }
        #ti_3 {
          left: 64px;
          bottom: 742px;
          letter-spacing: -0.15px;
          word-spacing: 0.05px;
        }
        #tj_3 {
          left: 64px;
          bottom: 724px;
          letter-spacing: -0.15px;
          word-spacing: 0.09px;
        }
        #tk_3 {
          left: 64px;
          bottom: 707px;
          letter-spacing: -0.15px;
          word-spacing: -0.05px;
        }
        #tl_3 {
          left: 64px;
          bottom: 690px;
          letter-spacing: -0.16px;
        }
        #tm_3 {
          left: 64px;
          bottom: 672px;
          letter-spacing: -0.18px;
        }
        #tn_3 {
          left: 64px;
          bottom: 655px;
          letter-spacing: -0.13px;
          word-spacing: -0.01px;
        }
        #to_3 {
          left: 64px;
          bottom: 637px;
          letter-spacing: -0.15px;
        }
        #tp_3 {
          left: 64px;
          bottom: 598px;
          letter-spacing: -0.16px;
        }
        #tq_3 {
          left: 64px;
          bottom: 581px;
          letter-spacing: -0.15px;
        }
        #tr_3 {
          left: 64px;
          bottom: 563px;
          letter-spacing: -0.17px;
        }
        #ts_3 {
          left: 235px;
          bottom: 598px;
          letter-spacing: -0.22px;
          word-spacing: 0.05px;
        }
        #tt_3 {
          left: 235px;
          bottom: 581px;
          letter-spacing: -0.16px;
        }
        #tu_3 {
          left: 235px;
          bottom: 563px;
          letter-spacing: -0.23px;
          word-spacing: 0.1px;
        }
        #tv_3 {
          left: 64px;
          bottom: 524px;
          letter-spacing: -0.14px;
        }
        #tw_3 {
          left: 64px;
          bottom: 485px;
          letter-spacing: -0.15px;
        }
        #tx_3 {
          left: 65px;
          bottom: 90px;
          letter-spacing: -0.04px;
        }
        #ty_3 {
          left: 65px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #tz_3 {
          left: 65px;
          bottom: 62px;
          letter-spacing: -0.03px;
          word-spacing: -0.23px;
        }
        #t10_3 {
          left: 189px;
          bottom: 90px;
          letter-spacing: -0.05px;
        }
        #t11_3 {
          left: 189px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #t12_3 {
          left: 189px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #t13_3 {
          left: 189px;
          bottom: 47px;
          letter-spacing: -0.04px;
        }
        #t14_3 {
          left: 337px;
          bottom: 90px;
          letter-spacing: -0.11px;
          word-spacing: 0.15px;
        }
        #t15_3 {
          left: 337px;
          bottom: 76px;
          letter-spacing: -0.09px;
          word-spacing: 0.13px;
        }
        #t16_3 {
          left: 337px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #t17_3 {
          left: 337px;
          bottom: 47px;
          letter-spacing: -0.04px;
        }
        #t18_3 {
          left: 477px;
          bottom: 90px;
          letter-spacing: -0.04px;
        }
        #t19_3 {
          left: 477px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #t1a_3 {
          left: 477px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #t1b_3 {
          left: 638px;
          bottom: 90px;
          letter-spacing: -0.04px;
        }
        #t1c_3 {
          left: 638px;
          bottom: 76px;
          letter-spacing: -0.04px;
        }
        #t1d_3 {
          left: 638px;
          bottom: 62px;
          letter-spacing: -0.04px;
        }
        #t1e_3 {
          left: 638px;
          bottom: 47px;
          letter-spacing: -0.04px;
        }
        #t1f_3 {
          left: 638px;
          bottom: 33px;
          letter-spacing: -0.04px;
        }

        .s1 {
          font-size: 14px;
          font-family: Arial_q;
          color: #000;
        }
        .s2 {
          font-size: 16px;
          font-family: Arial-Bold_w;
          color: #000;
        }
        .s3 {
          font-size: 13px;
          font-family: Arial-Bold_w;
          color: #000;
        }
        .s4 {
          font-size: 11px;
          font-family: Arial_q;
          color: #646464;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts3" type="text/css">
        @font-face {
          font-family: Arial-Bold_w;
          src: url("fonts/Arial-Bold_w.woff") format("woff");
        }

        @font-face {
          font-family: Arial_q;
          src: url("fonts/Arial_q.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg3Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg3" style="-webkit-user-select: none">
        <object
          width="909"
          height="1284"
          data="3/3.svg"
          type="image/svg+xml"
          id="pdf3"
          style="
            width: 909px;
            height: 1284px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_3" class="t s1">Seite </span
        ><span id="t2_3" class="t s1">3 </span>
        <span id="t3_3" class="t s2">B E S T E L L U N G </span
        ><span id="t4_3" class="t s2">Nr. </span
        ><span id="t5_3" class="t s2">APOB11427 </span>
        <span id="t6_3" class="t s3">Zwischensumme </span
        ><span id="t7_3" class="t s1">9.593,00 </span>
        <span id="t8_3" class="t s1">1.822,67 </span
        ><span id="t9_3" class="t s3">+ 19,00% Mwst. </span
        ><span id="ta_3" class="t s3">von 9.593,00 </span>
        <span id="tb_3" class="t s3">Gesamtsumme </span
        ><span id="tc_3" class="t s3">EUR </span
        ><span id="td_3" class="t s1">11.415,67 </span>
        <span id="te_3" class="t s1"
          >Wir bestellen ausschließlich auf Basis der „Allgemeine
          Einkaufsbedingungen der KNAPP Smart Solutions GmbH“
        </span>
        <span id="tf_3" class="t s1"
          >(KSO) in der jeweils gültigen Fassung, aktuell einzusehen unter
          https://www.apostore.com/einkaufsbedingungen .
        </span>
        <span id="tg_3" class="t s1"
          >Etwaige Geschäftsbedingungen des Lieferanten entfalten, vorbehaltlich
          einer ausdrücklichen unterfertigten
        </span>
        <span id="th_3" class="t s1"
          >schriftlichen Zustimmung von KSO im Einzelfall, zwischen den
          Vertragsparteien keinerlei Rechtswirkung. Dies gilt
        </span>
        <span id="ti_3" class="t s1"
          >ungeachtet der Art oder dem Zeitpunkt ihrer Übermittlung (inklusive
          einer mitunter von technischen Systemen
        </span>
        <span id="tj_3" class="t s1"
          >bedingten automatischen Übermittlung) und unabhängig davon, ob in den
          Angebotsunterlagen darauf verwiesen
        </span>
        <span id="tk_3" class="t s1"
          >wird. Eine Bezugnahme von KSO in der Bestellung auf
          Angebotsunterlagen des Lieferanten bedeuten keine
        </span>
        <span id="tl_3" class="t s1"
          >Anerkennung der Geschäftsbedingungen des Lieferanten.
        </span>
        <span id="tm_3" class="t s1">############################ </span>
        <span id="tn_3" class="t s1"
          >Bitte beachten Sie auch unsere Verpackungsrichtlinie, die für
          sämtliche Materialbestellungen gilt:
        </span>
        <span id="to_3" class="t s1"
          >https://apostore.imgix.net/20220118-KnappSmartSolutions_Verpackungsrichtlinie_v1_0.pdf
        </span>
        <span id="tp_3" class="t s1">Lieferbedingung </span>
        <span id="tq_3" class="t s1">gew. Liefertermin </span>
        <span id="tr_3" class="t s1">Zahlungsbedingung </span>
        <span id="ts_3" class="t s1">Ab Werk </span>
        <span id="tt_3" class="t s1">31.03.2023 </span>
        <span id="tu_3" class="t s1">14 Tage netto ohne Skonto </span>
        <span id="tv_3" class="t s1"
          >Dieses Schreiben wurde digital erstellt und ist auch ohne
          Unterschrift gültig.
        </span>
        <span id="tw_3" class="t s1">Mit freundlichen Grüßen </span>
        <span id="tx_3" class="t s4">Geschäftsführer </span>
        <span id="ty_3" class="t s4">Christian Bauer </span>
        <span id="tz_3" class="t s4">Christopher Thielen </span>
        <span id="t10_3" class="t s4">KNAPP </span>
        <span id="t11_3" class="t s4">Smart Solutions GmbH </span>
        <span id="t12_3" class="t s4">Uferstraße 10 </span>
        <span id="t13_3" class="t s4">45881 Gelsenkirchen </span>
        <span id="t14_3" class="t s4">T +49 209 94 117 - 0 </span>
        <span id="t15_3" class="t s4">F +49 209 94 117 - 17 </span>
        <span id="t16_3" class="t s4">kso.info@knapp.com </span>
        <span id="t17_3" class="t s4">www.knapp.com </span>
        <span id="t18_3" class="t s4">Amtsgericht Gelsenkirchen </span>
        <span id="t19_3" class="t s4">HRB 17444 </span>
        <span id="t1a_3" class="t s4">USt-Id: DE 300 827 208 </span>
        <span id="t1b_3" class="t s4">Commerzbank Kamen </span>
        <span id="t1c_3" class="t s4">BLZ 440 800 50 </span>
        <span id="t1d_3" class="t s4">Konto Nr.: 375 008 500 </span>
        <span id="t1e_3" class="t s4">IBAN DE75 4408 0050 0375 0085 00 </span>
        <span id="t1f_3" class="t s4">BIC/SWIFT-Code DRESDEFF440 </span>
      </div>
      <!-- End text definitions -->
    </div>
  </body>
</html>

  `,
  146: `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
    <meta charset="utf-8" />
  </head>

  <body style="margin: 0">
    <div
      id="p1"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1286px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_1 {
          left: 87px;
          bottom: 1081px;
          letter-spacing: -0.13px;
        }
        #t2_1 {
          left: 87px;
          bottom: 1065px;
          letter-spacing: -0.11px;
        }
        #t3_1 {
          left: 87px;
          bottom: 1050px;
          letter-spacing: -0.12px;
        }
        #t4_1 {
          left: 87px;
          bottom: 1034px;
          letter-spacing: -0.13px;
        }
        #t5_1 {
          left: 87px;
          bottom: 953px;
          letter-spacing: -0.14px;
        }
        #t6_1 {
          left: 239px;
          bottom: 953px;
        }
        #t7_1 {
          left: 233px;
          bottom: 953px;
        }
        #t8_1 {
          left: 228px;
          bottom: 953px;
        }
        #t9_1 {
          left: 222px;
          bottom: 953px;
        }
        #ta_1 {
          left: 216px;
          bottom: 953px;
        }
        #tb_1 {
          left: 210px;
          bottom: 953px;
        }
        #tc_1 {
          left: 205px;
          bottom: 953px;
        }
        #td_1 {
          left: 199px;
          bottom: 953px;
        }
        #te_1 {
          left: 193px;
          bottom: 953px;
        }
        #tf_1 {
          left: 187px;
          bottom: 953px;
        }
        #tg_1 {
          left: 181px;
          bottom: 953px;
        }
        #th_1 {
          left: 176px;
          bottom: 953px;
        }
        #ti_1 {
          left: 170px;
          bottom: 953px;
        }
        #tj_1 {
          left: 164px;
          bottom: 953px;
        }
        #tk_1 {
          left: 159px;
          bottom: 953px;
        }
        #tl_1 {
          left: 153px;
          bottom: 953px;
        }
        #tm_1 {
          left: 147px;
          bottom: 953px;
        }
        #tn_1 {
          left: 141px;
          bottom: 953px;
        }
        #to_1 {
          left: 135px;
          bottom: 953px;
        }
        #tp_1 {
          left: 130px;
          bottom: 953px;
        }
        #tq_1 {
          left: 247px;
          bottom: 953px;
          letter-spacing: -0.11px;
        }
        #tr_1 {
          left: 87px;
          bottom: 938px;
          letter-spacing: -0.12px;
        }
        #ts_1 {
          left: 239px;
          bottom: 938px;
        }
        #tt_1 {
          left: 233px;
          bottom: 938px;
        }
        #tu_1 {
          left: 228px;
          bottom: 938px;
        }
        #tv_1 {
          left: 222px;
          bottom: 938px;
        }
        #tw_1 {
          left: 216px;
          bottom: 938px;
        }
        #tx_1 {
          left: 210px;
          bottom: 938px;
        }
        #ty_1 {
          left: 205px;
          bottom: 938px;
        }
        #tz_1 {
          left: 199px;
          bottom: 938px;
        }
        #t10_1 {
          left: 193px;
          bottom: 938px;
        }
        #t11_1 {
          left: 187px;
          bottom: 938px;
        }
        #t12_1 {
          left: 181px;
          bottom: 938px;
        }
        #t13_1 {
          left: 176px;
          bottom: 938px;
        }
        #t14_1 {
          left: 170px;
          bottom: 938px;
        }
        #t15_1 {
          left: 164px;
          bottom: 938px;
        }
        #t16_1 {
          left: 159px;
          bottom: 938px;
        }
        #t17_1 {
          left: 247px;
          bottom: 938px;
          letter-spacing: -0.13px;
        }
        #t18_1 {
          left: 87px;
          bottom: 922px;
          letter-spacing: -0.15px;
        }
        #t19_1 {
          left: 239px;
          bottom: 922px;
        }
        #t1a_1 {
          left: 233px;
          bottom: 922px;
        }
        #t1b_1 {
          left: 228px;
          bottom: 922px;
        }
        #t1c_1 {
          left: 222px;
          bottom: 922px;
        }
        #t1d_1 {
          left: 216px;
          bottom: 922px;
        }
        #t1e_1 {
          left: 210px;
          bottom: 922px;
        }
        #t1f_1 {
          left: 205px;
          bottom: 922px;
        }
        #t1g_1 {
          left: 199px;
          bottom: 922px;
        }
        #t1h_1 {
          left: 193px;
          bottom: 922px;
        }
        #t1i_1 {
          left: 187px;
          bottom: 922px;
        }
        #t1j_1 {
          left: 181px;
          bottom: 922px;
        }
        #t1k_1 {
          left: 176px;
          bottom: 922px;
        }
        #t1l_1 {
          left: 170px;
          bottom: 922px;
        }
        #t1m_1 {
          left: 164px;
          bottom: 922px;
        }
        #t1n_1 {
          left: 159px;
          bottom: 922px;
        }
        #t1o_1 {
          left: 153px;
          bottom: 922px;
        }
        #t1p_1 {
          left: 247px;
          bottom: 922px;
          letter-spacing: -0.14px;
        }
        #t1q_1 {
          left: 87px;
          bottom: 867px;
          letter-spacing: 0.23px;
        }
        #t1r_1 {
          left: 87px;
          bottom: 854px;
          letter-spacing: -0.16px;
        }
        #t1s_1 {
          left: 239px;
          bottom: 854px;
        }
        #t1t_1 {
          left: 233px;
          bottom: 854px;
        }
        #t1u_1 {
          left: 228px;
          bottom: 854px;
        }
        #t1v_1 {
          left: 222px;
          bottom: 854px;
        }
        #t1w_1 {
          left: 216px;
          bottom: 854px;
        }
        #t1x_1 {
          left: 210px;
          bottom: 854px;
        }
        #t1y_1 {
          left: 205px;
          bottom: 854px;
        }
        #t1z_1 {
          left: 199px;
          bottom: 854px;
        }
        #t20_1 {
          left: 193px;
          bottom: 854px;
        }
        #t21_1 {
          left: 187px;
          bottom: 854px;
        }
        #t22_1 {
          left: 181px;
          bottom: 854px;
        }
        #t23_1 {
          left: 176px;
          bottom: 854px;
        }
        #t24_1 {
          left: 170px;
          bottom: 854px;
        }
        #t25_1 {
          left: 164px;
          bottom: 854px;
        }
        #t26_1 {
          left: 159px;
          bottom: 854px;
        }
        #t27_1 {
          left: 153px;
          bottom: 854px;
        }
        #t28_1 {
          left: 147px;
          bottom: 854px;
        }
        #t29_1 {
          left: 247px;
          bottom: 854px;
          letter-spacing: -0.13px;
        }
        #t2a_1 {
          left: 537px;
          bottom: 886px;
          letter-spacing: -0.13px;
        }
        #t2b_1 {
          left: 538px;
          bottom: 870px;
          letter-spacing: -0.11px;
        }
        #t2c_1 {
          left: 580px;
          bottom: 870px;
        }
        #t2d_1 {
          left: 609px;
          bottom: 870px;
          letter-spacing: -0.13px;
        }
        #t2e_1 {
          left: 639px;
          bottom: 870px;
        }
        #t2f_1 {
          left: 87px;
          bottom: 839px;
          letter-spacing: -0.12px;
        }
        #t2g_1 {
          left: 239px;
          bottom: 839px;
        }
        #t2h_1 {
          left: 233px;
          bottom: 839px;
        }
        #t2i_1 {
          left: 228px;
          bottom: 839px;
        }
        #t2j_1 {
          left: 222px;
          bottom: 839px;
        }
        #t2k_1 {
          left: 216px;
          bottom: 839px;
        }
        #t2l_1 {
          left: 210px;
          bottom: 839px;
        }
        #t2m_1 {
          left: 205px;
          bottom: 839px;
        }
        #t2n_1 {
          left: 199px;
          bottom: 839px;
        }
        #t2o_1 {
          left: 193px;
          bottom: 839px;
        }
        #t2p_1 {
          left: 247px;
          bottom: 839px;
          letter-spacing: -0.14px;
        }
        #t2q_1 {
          left: 537px;
          bottom: 839px;
          letter-spacing: -0.12px;
        }
        #t2r_1 {
          left: 87px;
          bottom: 823px;
          letter-spacing: -0.13px;
        }
        #t2s_1 {
          left: 239px;
          bottom: 823px;
        }
        #t2t_1 {
          left: 233px;
          bottom: 823px;
        }
        #t2u_1 {
          left: 228px;
          bottom: 823px;
        }
        #t2v_1 {
          left: 222px;
          bottom: 823px;
        }
        #t2w_1 {
          left: 216px;
          bottom: 823px;
        }
        #t2x_1 {
          left: 210px;
          bottom: 823px;
        }
        #t2y_1 {
          left: 205px;
          bottom: 823px;
        }
        #t2z_1 {
          left: 199px;
          bottom: 823px;
        }
        #t30_1 {
          left: 247px;
          bottom: 823px;
          letter-spacing: -0.14px;
        }
        #t31_1 {
          left: 87px;
          bottom: 807px;
          letter-spacing: -0.12px;
        }
        #t32_1 {
          left: 239px;
          bottom: 807px;
        }
        #t33_1 {
          left: 233px;
          bottom: 807px;
        }
        #t34_1 {
          left: 228px;
          bottom: 807px;
        }
        #t35_1 {
          left: 222px;
          bottom: 807px;
        }
        #t36_1 {
          left: 216px;
          bottom: 807px;
        }
        #t37_1 {
          left: 210px;
          bottom: 807px;
        }
        #t38_1 {
          left: 205px;
          bottom: 807px;
        }
        #t39_1 {
          left: 199px;
          bottom: 807px;
        }
        #t3a_1 {
          left: 193px;
          bottom: 807px;
        }
        #t3b_1 {
          left: 187px;
          bottom: 807px;
        }
        #t3c_1 {
          left: 181px;
          bottom: 807px;
        }
        #t3d_1 {
          left: 247px;
          bottom: 807px;
          letter-spacing: -0.13px;
        }
        #t3e_1 {
          left: 87px;
          bottom: 791px;
          letter-spacing: -0.11px;
        }
        #t3f_1 {
          left: 239px;
          bottom: 791px;
        }
        #t3g_1 {
          left: 233px;
          bottom: 791px;
        }
        #t3h_1 {
          left: 228px;
          bottom: 791px;
        }
        #t3i_1 {
          left: 222px;
          bottom: 791px;
        }
        #t3j_1 {
          left: 216px;
          bottom: 791px;
        }
        #t3k_1 {
          left: 210px;
          bottom: 791px;
        }
        #t3l_1 {
          left: 205px;
          bottom: 791px;
        }
        #t3m_1 {
          left: 199px;
          bottom: 791px;
        }
        #t3n_1 {
          left: 193px;
          bottom: 791px;
        }
        #t3o_1 {
          left: 537px;
          bottom: 822px;
          letter-spacing: -0.13px;
        }
        #t3p_1 {
          left: 537px;
          bottom: 806px;
          letter-spacing: -0.12px;
        }
        #t3q_1 {
          left: 537px;
          bottom: 791px;
          letter-spacing: -0.13px;
        }
        #t3r_1 {
          left: 537px;
          bottom: 775px;
          letter-spacing: -0.14px;
        }
        #t3s_1 {
          left: 87px;
          bottom: 755px;
          letter-spacing: -0.12px;
        }
        #t3t_1 {
          left: 225px;
          bottom: 755px;
          letter-spacing: -0.12px;
        }
        #t3u_1 {
          left: 434px;
          bottom: 755px;
          letter-spacing: -0.12px;
        }
        #t3v_1 {
          left: 546px;
          bottom: 755px;
          letter-spacing: -0.15px;
        }
        #t3w_1 {
          left: 595px;
          bottom: 755px;
          letter-spacing: -0.11px;
        }
        #t3x_1 {
          left: 649px;
          bottom: 755px;
          letter-spacing: -0.11px;
        }
        #t3y_1 {
          left: 738px;
          bottom: 755px;
          letter-spacing: -0.11px;
        }
        #t3z_1 {
          left: 825px;
          bottom: 755px;
          letter-spacing: -0.12px;
        }
        #t40_1 {
          left: 87px;
          bottom: 722px;
          letter-spacing: -0.14px;
        }
        #t41_1 {
          left: 225px;
          bottom: 722px;
          letter-spacing: -0.14px;
        }
        #t42_1 {
          left: 344px;
          bottom: 722px;
          letter-spacing: -0.13px;
        }
        #t43_1 {
          left: 225px;
          bottom: 707px;
          letter-spacing: -0.13px;
        }
        #t44_1 {
          left: 434px;
          bottom: 722px;
          letter-spacing: -0.13px;
        }
        #t45_1 {
          left: 553px;
          bottom: 722px;
          letter-spacing: -0.13px;
        }
        #t46_1 {
          left: 595px;
          bottom: 722px;
          letter-spacing: -0.12px;
        }
        #t47_1 {
          left: 684px;
          bottom: 722px;
          letter-spacing: -0.13px;
        }
        #t48_1 {
          left: 773px;
          bottom: 722px;
        }
        #t49_1 {
          left: 812px;
          bottom: 722px;
          letter-spacing: -0.12px;
        }
        #t4a_1 {
          left: 102px;
          bottom: 683px;
          letter-spacing: -0.14px;
        }
        #t4b_1 {
          left: 102px;
          bottom: 652px;
          letter-spacing: -0.12px;
        }
        #t4c_1 {
          left: 87px;
          bottom: 628px;
          letter-spacing: -0.14px;
        }
        #t4d_1 {
          left: 87px;
          bottom: 607px;
          letter-spacing: -0.12px;
        }
        #t4e_1 {
          left: 812px;
          bottom: 607px;
          letter-spacing: -0.12px;
        }
        #t4f_1 {
          left: 87px;
          bottom: 592px;
          letter-spacing: -0.12px;
        }
        #t4g_1 {
          left: 823px;
          bottom: 592px;
          letter-spacing: -0.13px;
        }
        #t4h_1 {
          left: 87px;
          bottom: 559px;
          letter-spacing: -0.12px;
        }
        #t4i_1 {
          left: 698px;
          bottom: 559px;
          letter-spacing: -0.17px;
        }
        #t4j_1 {
          left: 812px;
          bottom: 559px;
          letter-spacing: -0.12px;
        }
        #t4k_1 {
          left: 87px;
          bottom: 520px;
          letter-spacing: -0.13px;
        }
        #t4l_1 {
          left: 239px;
          bottom: 520px;
        }
        #t4m_1 {
          left: 233px;
          bottom: 520px;
        }
        #t4n_1 {
          left: 247px;
          bottom: 520px;
          letter-spacing: -0.12px;
        }
        #t4o_1 {
          left: 87px;
          bottom: 504px;
          letter-spacing: -0.12px;
        }
        #t4p_1 {
          left: 239px;
          bottom: 504px;
        }
        #t4q_1 {
          left: 233px;
          bottom: 504px;
        }
        #t4r_1 {
          left: 228px;
          bottom: 504px;
        }
        #t4s_1 {
          left: 222px;
          bottom: 504px;
        }
        #t4t_1 {
          left: 216px;
          bottom: 504px;
        }
        #t4u_1 {
          left: 210px;
          bottom: 504px;
        }
        #t4v_1 {
          left: 247px;
          bottom: 504px;
          letter-spacing: -0.12px;
        }
        #t4w_1 {
          left: 537px;
          bottom: 473px;
          letter-spacing: -0.12px;
        }
        #t4x_1 {
          left: 537px;
          bottom: 441px;
          letter-spacing: -0.16px;
        }
        #t4y_1 {
          left: 87px;
          bottom: 347px;
          letter-spacing: -0.11px;
        }
        #t4z_1 {
          left: 87px;
          bottom: 331px;
          letter-spacing: -0.14px;
        }
        #t50_1 {
          left: 87px;
          bottom: 300px;
          letter-spacing: -0.11px;
        }
        #t51_1 {
          left: 87px;
          bottom: 268px;
          letter-spacing: -0.12px;
        }
        #t52_1 {
          left: 87px;
          bottom: 236px;
          letter-spacing: -0.12px;
        }
        #t53_1 {
          left: 87px;
          bottom: 221px;
          letter-spacing: -0.11px;
        }
        #t54_1 {
          left: 65px;
          bottom: 1229px;
          letter-spacing: 0.01px;
          word-spacing: -0.55px;
        }
        #t55_1 {
          left: 65px;
          bottom: 1212px;
          letter-spacing: -0.02px;
          word-spacing: -0.5px;
        }
        #t56_1 {
          left: 176px;
          bottom: 1212px;
          letter-spacing: -0.17px;
          word-spacing: -0.83px;
        }
        #t57_1 {
          left: 297px;
          bottom: 1212px;
          letter-spacing: 0.04px;
        }
        #t58_1 {
          left: 65px;
          bottom: 1195px;
          letter-spacing: -0.3px;
          word-spacing: -0.9px;
        }
        #t59_1 {
          left: 171px;
          bottom: 1195px;
          letter-spacing: 0.01px;
        }

        .s1 {
          font-size: 14px;
          font-family: Arial-Bold_d;
          color: #000;
        }
        .s2 {
          font-size: 14px;
          font-family: Arial_e;
          color: #000;
        }
        .s3 {
          font-size: 27px;
          font-family: Arial_e;
          color: #000;
        }
        .s4 {
          font-size: 12px;
          font-family: Univers-Bold_v;
          color: #231f20;
        }
        .s5 {
          font-size: 12px;
          font-family: Univers_z;
          color: #231f20;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts1" type="text/css">
        @font-face {
          font-family: Arial-Bold_d;
          src: url("fonts/Arial-Bold_d.woff") format("woff");
        }

        @font-face {
          font-family: Arial_e;
          src: url("fonts/Arial_e.woff") format("woff");
        }

        @font-face {
          font-family: Univers-Bold_v;
          src: url("fonts/Univers-Bold_v.woff") format("woff");
        }

        @font-face {
          font-family: Univers_z;
          src: url("fonts/Univers_z.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg1Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg1" style="-webkit-user-select: none">
        <object
          width="909"
          height="1286"
          data="1/1.svg"
          type="image/svg+xml"
          id="pdf1"
          style="
            width: 909px;
            height: 1286px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_1" class="t s1">maxon Switzerland ag </span>
        <span id="t2_1" class="t s2">Industriestrasse 23 </span>
        <span id="t3_1" class="t s2">Postfach 263 </span>
        <span id="t4_1" class="t s2">CH-6055 Alpnach Dorf </span>
        <span id="t5_1" class="t s2">Bank </span
        ><span id="t6_1" class="t s2">: </span
        ><span id="t7_1" class="t s2">. </span
        ><span id="t8_1" class="t s2">. </span
        ><span id="t9_1" class="t s2">. </span
        ><span id="ta_1" class="t s2">. </span
        ><span id="tb_1" class="t s2">. </span
        ><span id="tc_1" class="t s2">. </span
        ><span id="td_1" class="t s2">. </span
        ><span id="te_1" class="t s2">. </span
        ><span id="tf_1" class="t s2">. </span
        ><span id="tg_1" class="t s2">. </span
        ><span id="th_1" class="t s2">. </span
        ><span id="ti_1" class="t s2">. </span
        ><span id="tj_1" class="t s2">. </span
        ><span id="tk_1" class="t s2">. </span
        ><span id="tl_1" class="t s2">. </span
        ><span id="tm_1" class="t s2">. </span
        ><span id="tn_1" class="t s2">. </span
        ><span id="to_1" class="t s2">. </span
        ><span id="tp_1" class="t s2">. </span
        ><span id="tq_1" class="t s2">Credit Suisse, Effretikon, CHF </span>
        <span id="tr_1" class="t s2">Girokonto </span
        ><span id="ts_1" class="t s2">: </span
        ><span id="tt_1" class="t s2">. </span
        ><span id="tu_1" class="t s2">. </span
        ><span id="tv_1" class="t s2">. </span
        ><span id="tw_1" class="t s2">. </span
        ><span id="tx_1" class="t s2">. </span
        ><span id="ty_1" class="t s2">. </span
        ><span id="tz_1" class="t s2">. </span
        ><span id="t10_1" class="t s2">. </span
        ><span id="t11_1" class="t s2">. </span
        ><span id="t12_1" class="t s2">. </span
        ><span id="t13_1" class="t s2">. </span
        ><span id="t14_1" class="t s2">. </span
        ><span id="t15_1" class="t s2">. </span
        ><span id="t16_1" class="t s2">. </span
        ><span id="t17_1" class="t s2">CH80 0483 5082 2321 01 </span>
        <span id="t18_1" class="t s2">MWSt-Nr </span
        ><span id="t19_1" class="t s2">: </span
        ><span id="t1a_1" class="t s2">. </span
        ><span id="t1b_1" class="t s2">. </span
        ><span id="t1c_1" class="t s2">. </span
        ><span id="t1d_1" class="t s2">. </span
        ><span id="t1e_1" class="t s2">. </span
        ><span id="t1f_1" class="t s2">. </span
        ><span id="t1g_1" class="t s2">. </span
        ><span id="t1h_1" class="t s2">. </span
        ><span id="t1i_1" class="t s2">. </span
        ><span id="t1j_1" class="t s2">. </span
        ><span id="t1k_1" class="t s2">. </span
        ><span id="t1l_1" class="t s2">. </span
        ><span id="t1m_1" class="t s2">. </span
        ><span id="t1n_1" class="t s2">. </span
        ><span id="t1o_1" class="t s2">. </span
        ><span id="t1p_1" class="t s2">CHE-102.601.607 MWST </span>
        <span id="t1q_1" class="t s3">Bestellung </span>
        <span id="t1r_1" class="t s2">Nummer </span
        ><span id="t1s_1" class="t s2">: </span
        ><span id="t1t_1" class="t s2">. </span
        ><span id="t1u_1" class="t s2">. </span
        ><span id="t1v_1" class="t s2">. </span
        ><span id="t1w_1" class="t s2">. </span
        ><span id="t1x_1" class="t s2">. </span
        ><span id="t1y_1" class="t s2">. </span
        ><span id="t1z_1" class="t s2">. </span
        ><span id="t20_1" class="t s2">. </span
        ><span id="t21_1" class="t s2">. </span
        ><span id="t22_1" class="t s2">. </span
        ><span id="t23_1" class="t s2">. </span
        ><span id="t24_1" class="t s2">. </span
        ><span id="t25_1" class="t s2">. </span
        ><span id="t26_1" class="t s2">. </span
        ><span id="t27_1" class="t s2">. </span
        ><span id="t28_1" class="t s2">. </span
        ><span id="t29_1" class="t s2">932646-1 </span>
        <span id="t2a_1" class="t s2">Tagelswangen, 25.11.2022 </span>
        <span id="t2b_1" class="t s2">Seite </span
        ><span id="t2c_1" class="t s2">1 </span
        ><span id="t2d_1" class="t s2">von </span
        ><span id="t2e_1" class="t s2">1 </span>
        <span id="t2f_1" class="t s2">Kreditorenkonto </span
        ><span id="t2g_1" class="t s2">: </span
        ><span id="t2h_1" class="t s2">. </span
        ><span id="t2i_1" class="t s2">. </span
        ><span id="t2j_1" class="t s2">. </span
        ><span id="t2k_1" class="t s2">. </span
        ><span id="t2l_1" class="t s2">. </span
        ><span id="t2m_1" class="t s2">. </span
        ><span id="t2n_1" class="t s2">. </span
        ><span id="t2o_1" class="t s2">. </span
        ><span id="t2p_1" class="t s2">1227 </span
        ><span id="t2q_1" class="t s2">Lieferadresse </span>
        <span id="t2r_1" class="t s2">Unsere Referenz </span
        ><span id="t2s_1" class="t s2">: </span
        ><span id="t2t_1" class="t s2">. </span
        ><span id="t2u_1" class="t s2">. </span
        ><span id="t2v_1" class="t s2">. </span
        ><span id="t2w_1" class="t s2">. </span
        ><span id="t2x_1" class="t s2">. </span
        ><span id="t2y_1" class="t s2">. </span
        ><span id="t2z_1" class="t s2">. </span
        ><span id="t30_1" class="t s2">Hugo Thode </span>
        <span id="t31_1" class="t s2">Ihre Referenz </span
        ><span id="t32_1" class="t s2">: </span
        ><span id="t33_1" class="t s2">. </span
        ><span id="t34_1" class="t s2">. </span
        ><span id="t35_1" class="t s2">. </span
        ><span id="t36_1" class="t s2">. </span
        ><span id="t37_1" class="t s2">. </span
        ><span id="t38_1" class="t s2">. </span
        ><span id="t39_1" class="t s2">. </span
        ><span id="t3a_1" class="t s2">. </span
        ><span id="t3b_1" class="t s2">. </span
        ><span id="t3c_1" class="t s2">. </span
        ><span id="t3d_1" class="t s2">Herr Clemens Gander </span>
        <span id="t3e_1" class="t s2">Kreditorreferenz </span
        ><span id="t3f_1" class="t s2">: </span
        ><span id="t3g_1" class="t s2">. </span
        ><span id="t3h_1" class="t s2">. </span
        ><span id="t3i_1" class="t s2">. </span
        ><span id="t3j_1" class="t s2">. </span
        ><span id="t3k_1" class="t s2">. </span
        ><span id="t3l_1" class="t s2">. </span
        ><span id="t3m_1" class="t s2">. </span
        ><span id="t3n_1" class="t s2">. </span>
        <span id="t3o_1" class="t s1">Micronel AG </span>
        <span id="t3p_1" class="t s1">Klein Ventilatoren </span>
        <span id="t3q_1" class="t s1">Zürcherstrasse 51 </span>
        <span id="t3r_1" class="t s1">CH-8317 Tagelswangen </span>
        <span id="t3s_1" class="t s2">Artikelnummer </span
        ><span id="t3t_1" class="t s2">Text </span
        ><span id="t3u_1" class="t s2">Lieferung </span
        ><span id="t3v_1" class="t s2">Menge </span
        ><span id="t3w_1" class="t s2">Einheit </span
        ><span id="t3x_1" class="t s2">Preis je Einhe </span
        ><span id="t3y_1" class="t s2">Einheit </span
        ><span id="t3z_1" class="t s2">Betrag </span>
        <span id="t40_1" class="t s2">4826E-93750-M </span
        ><span id="t41_1" class="t s2">24VDC KL SCHN. </span
        ><span id="t42_1" class="t s2">2326.937- </span>
        <span id="t43_1" class="t s2">10.246-101 </span>
        <span id="t44_1" class="t s2">01.04.2023 </span
        ><span id="t45_1" class="t s2">60.00 </span
        ><span id="t46_1" class="t s2">Stk </span
        ><span id="t47_1" class="t s2">47.500 </span
        ><span id="t48_1" class="t s2">1 </span
        ><span id="t49_1" class="t s2">2,850.00 </span>
        <span id="t4a_1" class="t s2">FA 519408 </span>
        <span id="t4b_1" class="t s2"
          >Die Stecker V601X-051A9 werden von Micronel beigestellt.
        </span>
        <span id="t4c_1" class="t s2"
          >______________________________________________________________________________________________________
        </span>
        <span id="t4d_1" class="t s2">Netto </span
        ><span id="t4e_1" class="t s2">2,850.00 </span>
        <span id="t4f_1" class="t s2">Mehrwertsteuer </span
        ><span id="t4g_1" class="t s2">219.45 </span>
        <span id="t4h_1" class="t s1">Total </span
        ><span id="t4i_1" class="t s1">CHF </span
        ><span id="t4j_1" class="t s1">3,069.45 </span>
        <span id="t4k_1" class="t s2">Zahlungsbedingungen </span
        ><span id="t4l_1" class="t s2">: </span
        ><span id="t4m_1" class="t s2">. </span
        ><span id="t4n_1" class="t s2">60 Tage netto </span>
        <span id="t4o_1" class="t s2">Lieferbedingungen </span
        ><span id="t4p_1" class="t s2">: </span
        ><span id="t4q_1" class="t s2">. </span
        ><span id="t4r_1" class="t s2">. </span
        ><span id="t4s_1" class="t s2">. </span
        ><span id="t4t_1" class="t s2">. </span
        ><span id="t4u_1" class="t s2">. </span
        ><span id="t4v_1" class="t s2">Incoterms 2010, FCA </span>
        <span id="t4w_1" class="t s2">Freundliche Grüsse </span>
        <span id="t4x_1" class="t s2">MICRONEL AG </span>
        <span id="t4y_1" class="t s2"
          >Wir erwarten Ihre Auftragsbestätigung in den nächsten drei
          Abeitstagen.
        </span>
        <span id="t4z_1" class="t s2"
          >______________________________________________________________________________________
        </span>
        <span id="t50_1" class="t s2"
          >Zusätzlich verlangte Informationen:
        </span>
        <span id="t51_1" class="t s2"
          >Auf dem Lieferschein muss die Verwendung des vorgeschriebenen
          Materials bestätigt werden inklusive der Materialangabe.
        </span>
        <span id="t52_1" class="t s2"
          >Bitte geben sie auf sämtlichen Auftragsdokumenten unsere
          Bestellnummer, Artikelnummer und Zeichnungsnummer sowie
        </span>
        <span id="t53_1" class="t s2"
          >den Zolltarif und das Ursprungsland inkl. Ursprungserklärung an.
        </span>
        <span id="t54_1" class="t s4">Micronel AG </span>
        <span id="t55_1" class="t s5">Zürcherstrasse 51 </span
        ><span id="t56_1" class="t s5">8317 Tagelswangen </span
        ><span id="t57_1" class="t s5">Switzerland </span>
        <span id="t58_1" class="t s5">T +41 52 355 16 16 </span
        ><span id="t59_1" class="t s5">micronel.com </span>
      </div>
      <!-- End text definitions -->
    </div>
  </body>
</html>
  `,
  '146b': `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
      <meta charset="utf-8" />
    </head>
  
    <body style="margin: 0">
      <div
        id="p1"
        style="
          overflow: hidden;
          position: relative;
          background-color: white;
          width: 909px;
          height: 1286px;
        "
      >
        <!-- Begin shared CSS values -->
        <style class="shared-css" type="text/css">
          .t {
            transform-origin: bottom left;
            z-index: 2;
            position: absolute;
            white-space: pre;
            overflow: visible;
            line-height: 1.5;
          }
          .text-container {
            white-space: pre;
          }
          @supports (-webkit-touch-callout: none) {
            .text-container {
              white-space: normal;
            }
          }
        </style>
        <!-- End shared CSS values -->
  
        <!-- Begin inline CSS -->
        <style type="text/css">
          #t1_1 {
            left: 87px;
            bottom: 1081px;
            letter-spacing: -0.13px;
          }
          #t2_1 {
            left: 87px;
            bottom: 1065px;
            letter-spacing: -0.11px;
          }
          #t3_1 {
            left: 87px;
            bottom: 1050px;
            letter-spacing: -0.12px;
          }
          #t4_1 {
            left: 87px;
            bottom: 1034px;
            letter-spacing: -0.13px;
          }
          #t5_1 {
            left: 87px;
            bottom: 953px;
            letter-spacing: -0.14px;
          }
          #t6_1 {
            left: 239px;
            bottom: 953px;
          }
          #t7_1 {
            left: 233px;
            bottom: 953px;
          }
          #t8_1 {
            left: 228px;
            bottom: 953px;
          }
          #t9_1 {
            left: 222px;
            bottom: 953px;
          }
          #ta_1 {
            left: 216px;
            bottom: 953px;
          }
          #tb_1 {
            left: 210px;
            bottom: 953px;
          }
          #tc_1 {
            left: 205px;
            bottom: 953px;
          }
          #td_1 {
            left: 199px;
            bottom: 953px;
          }
          #te_1 {
            left: 193px;
            bottom: 953px;
          }
          #tf_1 {
            left: 187px;
            bottom: 953px;
          }
          #tg_1 {
            left: 181px;
            bottom: 953px;
          }
          #th_1 {
            left: 176px;
            bottom: 953px;
          }
          #ti_1 {
            left: 170px;
            bottom: 953px;
          }
          #tj_1 {
            left: 164px;
            bottom: 953px;
          }
          #tk_1 {
            left: 159px;
            bottom: 953px;
          }
          #tl_1 {
            left: 153px;
            bottom: 953px;
          }
          #tm_1 {
            left: 147px;
            bottom: 953px;
          }
          #tn_1 {
            left: 141px;
            bottom: 953px;
          }
          #to_1 {
            left: 135px;
            bottom: 953px;
          }
          #tp_1 {
            left: 130px;
            bottom: 953px;
          }
          #tq_1 {
            left: 247px;
            bottom: 953px;
            letter-spacing: -0.11px;
          }
          #tr_1 {
            left: 87px;
            bottom: 938px;
            letter-spacing: -0.12px;
          }
          #ts_1 {
            left: 239px;
            bottom: 938px;
          }
          #tt_1 {
            left: 233px;
            bottom: 938px;
          }
          #tu_1 {
            left: 228px;
            bottom: 938px;
          }
          #tv_1 {
            left: 222px;
            bottom: 938px;
          }
          #tw_1 {
            left: 216px;
            bottom: 938px;
          }
          #tx_1 {
            left: 210px;
            bottom: 938px;
          }
          #ty_1 {
            left: 205px;
            bottom: 938px;
          }
          #tz_1 {
            left: 199px;
            bottom: 938px;
          }
          #t10_1 {
            left: 193px;
            bottom: 938px;
          }
          #t11_1 {
            left: 187px;
            bottom: 938px;
          }
          #t12_1 {
            left: 181px;
            bottom: 938px;
          }
          #t13_1 {
            left: 176px;
            bottom: 938px;
          }
          #t14_1 {
            left: 170px;
            bottom: 938px;
          }
          #t15_1 {
            left: 164px;
            bottom: 938px;
          }
          #t16_1 {
            left: 159px;
            bottom: 938px;
          }
          #t17_1 {
            left: 247px;
            bottom: 938px;
            letter-spacing: -0.13px;
          }
          #t18_1 {
            left: 87px;
            bottom: 922px;
            letter-spacing: -0.15px;
          }
          #t19_1 {
            left: 239px;
            bottom: 922px;
          }
          #t1a_1 {
            left: 233px;
            bottom: 922px;
          }
          #t1b_1 {
            left: 228px;
            bottom: 922px;
          }
          #t1c_1 {
            left: 222px;
            bottom: 922px;
          }
          #t1d_1 {
            left: 216px;
            bottom: 922px;
          }
          #t1e_1 {
            left: 210px;
            bottom: 922px;
          }
          #t1f_1 {
            left: 205px;
            bottom: 922px;
          }
          #t1g_1 {
            left: 199px;
            bottom: 922px;
          }
          #t1h_1 {
            left: 193px;
            bottom: 922px;
          }
          #t1i_1 {
            left: 187px;
            bottom: 922px;
          }
          #t1j_1 {
            left: 181px;
            bottom: 922px;
          }
          #t1k_1 {
            left: 176px;
            bottom: 922px;
          }
          #t1l_1 {
            left: 170px;
            bottom: 922px;
          }
          #t1m_1 {
            left: 164px;
            bottom: 922px;
          }
          #t1n_1 {
            left: 159px;
            bottom: 922px;
          }
          #t1o_1 {
            left: 153px;
            bottom: 922px;
          }
          #t1p_1 {
            left: 247px;
            bottom: 922px;
            letter-spacing: -0.14px;
          }
          #t1q_1 {
            left: 87px;
            bottom: 867px;
            letter-spacing: 0.23px;
          }
          #t1r_1 {
            left: 87px;
            bottom: 854px;
            letter-spacing: -0.16px;
          }
          #t1s_1 {
            left: 239px;
            bottom: 854px;
          }
          #t1t_1 {
            left: 233px;
            bottom: 854px;
          }
          #t1u_1 {
            left: 228px;
            bottom: 854px;
          }
          #t1v_1 {
            left: 222px;
            bottom: 854px;
          }
          #t1w_1 {
            left: 216px;
            bottom: 854px;
          }
          #t1x_1 {
            left: 210px;
            bottom: 854px;
          }
          #t1y_1 {
            left: 205px;
            bottom: 854px;
          }
          #t1z_1 {
            left: 199px;
            bottom: 854px;
          }
          #t20_1 {
            left: 193px;
            bottom: 854px;
          }
          #t21_1 {
            left: 187px;
            bottom: 854px;
          }
          #t22_1 {
            left: 181px;
            bottom: 854px;
          }
          #t23_1 {
            left: 176px;
            bottom: 854px;
          }
          #t24_1 {
            left: 170px;
            bottom: 854px;
          }
          #t25_1 {
            left: 164px;
            bottom: 854px;
          }
          #t26_1 {
            left: 159px;
            bottom: 854px;
          }
          #t27_1 {
            left: 153px;
            bottom: 854px;
          }
          #t28_1 {
            left: 147px;
            bottom: 854px;
          }
          #t29_1 {
            left: 247px;
            bottom: 854px;
            letter-spacing: -0.13px;
          }
          #t2a_1 {
            left: 87px;
            bottom: 839px;
            letter-spacing: -0.12px;
          }
          #t2b_1 {
            left: 239px;
            bottom: 839px;
          }
          #t2c_1 {
            left: 233px;
            bottom: 839px;
          }
          #t2d_1 {
            left: 228px;
            bottom: 839px;
          }
          #t2e_1 {
            left: 222px;
            bottom: 839px;
          }
          #t2f_1 {
            left: 216px;
            bottom: 839px;
          }
          #t2g_1 {
            left: 210px;
            bottom: 839px;
          }
          #t2h_1 {
            left: 205px;
            bottom: 839px;
          }
          #t2i_1 {
            left: 199px;
            bottom: 839px;
          }
          #t2j_1 {
            left: 193px;
            bottom: 839px;
          }
          #t2k_1 {
            left: 247px;
            bottom: 839px;
            letter-spacing: -0.14px;
          }
          #t2l_1 {
            left: 537px;
            bottom: 839px;
            letter-spacing: -0.12px;
          }
          #t2m_1 {
            left: 87px;
            bottom: 823px;
            letter-spacing: -0.13px;
          }
          #t2n_1 {
            left: 239px;
            bottom: 823px;
          }
          #t2o_1 {
            left: 233px;
            bottom: 823px;
          }
          #t2p_1 {
            left: 228px;
            bottom: 823px;
          }
          #t2q_1 {
            left: 222px;
            bottom: 823px;
          }
          #t2r_1 {
            left: 216px;
            bottom: 823px;
          }
          #t2s_1 {
            left: 210px;
            bottom: 823px;
          }
          #t2t_1 {
            left: 205px;
            bottom: 823px;
          }
          #t2u_1 {
            left: 199px;
            bottom: 823px;
          }
          #t2v_1 {
            left: 247px;
            bottom: 823px;
            letter-spacing: -0.14px;
          }
          #t2w_1 {
            left: 87px;
            bottom: 807px;
            letter-spacing: -0.12px;
          }
          #t2x_1 {
            left: 239px;
            bottom: 807px;
          }
          #t2y_1 {
            left: 233px;
            bottom: 807px;
          }
          #t2z_1 {
            left: 228px;
            bottom: 807px;
          }
          #t30_1 {
            left: 222px;
            bottom: 807px;
          }
          #t31_1 {
            left: 216px;
            bottom: 807px;
          }
          #t32_1 {
            left: 210px;
            bottom: 807px;
          }
          #t33_1 {
            left: 205px;
            bottom: 807px;
          }
          #t34_1 {
            left: 199px;
            bottom: 807px;
          }
          #t35_1 {
            left: 193px;
            bottom: 807px;
          }
          #t36_1 {
            left: 187px;
            bottom: 807px;
          }
          #t37_1 {
            left: 181px;
            bottom: 807px;
          }
          #t38_1 {
            left: 247px;
            bottom: 807px;
            letter-spacing: -0.13px;
          }
          #t39_1 {
            left: 87px;
            bottom: 791px;
            letter-spacing: -0.11px;
          }
          #t3a_1 {
            left: 239px;
            bottom: 791px;
          }
          #t3b_1 {
            left: 233px;
            bottom: 791px;
          }
          #t3c_1 {
            left: 228px;
            bottom: 791px;
          }
          #t3d_1 {
            left: 222px;
            bottom: 791px;
          }
          #t3e_1 {
            left: 216px;
            bottom: 791px;
          }
          #t3f_1 {
            left: 210px;
            bottom: 791px;
          }
          #t3g_1 {
            left: 205px;
            bottom: 791px;
          }
          #t3h_1 {
            left: 199px;
            bottom: 791px;
          }
          #t3i_1 {
            left: 193px;
            bottom: 791px;
          }
          #t3j_1 {
            left: 537px;
            bottom: 822px;
            letter-spacing: -0.13px;
          }
          #t3k_1 {
            left: 537px;
            bottom: 806px;
            letter-spacing: -0.12px;
          }
          #t3l_1 {
            left: 537px;
            bottom: 791px;
            letter-spacing: -0.13px;
          }
          #t3m_1 {
            left: 537px;
            bottom: 775px;
            letter-spacing: -0.14px;
          }
          #t3n_1 {
            left: 87px;
            bottom: 755px;
            letter-spacing: -0.12px;
          }
          #t3o_1 {
            left: 225px;
            bottom: 755px;
            letter-spacing: -0.12px;
          }
          #t3p_1 {
            left: 434px;
            bottom: 755px;
            letter-spacing: -0.12px;
          }
          #t3q_1 {
            left: 546px;
            bottom: 755px;
            letter-spacing: -0.15px;
          }
          #t3r_1 {
            left: 595px;
            bottom: 755px;
            letter-spacing: -0.11px;
          }
          #t3s_1 {
            left: 649px;
            bottom: 755px;
            letter-spacing: -0.11px;
          }
          #t3t_1 {
            left: 738px;
            bottom: 755px;
            letter-spacing: -0.11px;
          }
          #t3u_1 {
            left: 826px;
            bottom: 755px;
            letter-spacing: -0.12px;
          }
          #t3v_1 {
            left: 87px;
            bottom: 722px;
            letter-spacing: -0.14px;
          }
          #t3w_1 {
            left: 225px;
            bottom: 722px;
            letter-spacing: -0.15px;
          }
          #t3x_1 {
            left: 225px;
            bottom: 707px;
            letter-spacing: -0.13px;
          }
          #t3y_1 {
            left: 225px;
            bottom: 691px;
            letter-spacing: -0.1px;
          }
          #t3z_1 {
            left: 225px;
            bottom: 675px;
            letter-spacing: -0.13px;
          }
          #t40_1 {
            left: 225px;
            bottom: 659px;
            letter-spacing: -0.14px;
          }
          #t41_1 {
            left: 87px;
            bottom: 644px;
            letter-spacing: -0.12px;
          }
          #t42_1 {
            left: 102px;
            bottom: 604px;
            letter-spacing: -0.14px;
          }
          #t43_1 {
            left: 102px;
            bottom: 573px;
            letter-spacing: -0.12px;
          }
          #t44_1 {
            left: 87px;
            bottom: 549px;
            letter-spacing: -0.14px;
          }
          #t45_1 {
            left: 87px;
            bottom: 529px;
            letter-spacing: -0.12px;
          }
          #t46_1 {
            left: 812px;
            bottom: 529px;
            letter-spacing: -0.12px;
          }
          #t47_1 {
            left: 87px;
            bottom: 513px;
            letter-spacing: -0.12px;
          }
          #t48_1 {
            left: 824px;
            bottom: 513px;
            letter-spacing: -0.13px;
          }
          #t49_1 {
            left: 87px;
            bottom: 480px;
            letter-spacing: -0.12px;
          }
          #t4a_1 {
            left: 698px;
            bottom: 480px;
            letter-spacing: -0.17px;
          }
          #t4b_1 {
            left: 812px;
            bottom: 480px;
            letter-spacing: -0.12px;
          }
          #t4c_1 {
            left: 87px;
            bottom: 441px;
            letter-spacing: -0.13px;
          }
          #t4d_1 {
            left: 239px;
            bottom: 441px;
          }
          #t4e_1 {
            left: 233px;
            bottom: 441px;
          }
          #t4f_1 {
            left: 247px;
            bottom: 441px;
            letter-spacing: -0.12px;
          }
          #t4g_1 {
            left: 87px;
            bottom: 426px;
            letter-spacing: -0.12px;
          }
          #t4h_1 {
            left: 239px;
            bottom: 426px;
          }
          #t4i_1 {
            left: 233px;
            bottom: 426px;
          }
          #t4j_1 {
            left: 228px;
            bottom: 426px;
          }
          #t4k_1 {
            left: 222px;
            bottom: 426px;
          }
          #t4l_1 {
            left: 216px;
            bottom: 426px;
          }
          #t4m_1 {
            left: 210px;
            bottom: 426px;
          }
          #t4n_1 {
            left: 247px;
            bottom: 426px;
            letter-spacing: -0.12px;
          }
          #t4o_1 {
            left: 537px;
            bottom: 394px;
            letter-spacing: -0.12px;
          }
          #t4p_1 {
            left: 537px;
            bottom: 363px;
            letter-spacing: -0.16px;
          }
          #t4q_1 {
            left: 87px;
            bottom: 268px;
            letter-spacing: -0.11px;
          }
          #t4r_1 {
            left: 87px;
            bottom: 252px;
            letter-spacing: -0.14px;
          }
          #t4s_1 {
            left: 87px;
            bottom: 221px;
            letter-spacing: -0.11px;
          }
          #t4t_1 {
            left: 87px;
            bottom: 189px;
            letter-spacing: -0.12px;
          }
          #t4u_1 {
            left: 87px;
            bottom: 158px;
            letter-spacing: -0.12px;
          }
          #t4v_1 {
            left: 65px;
            bottom: 1229px;
            letter-spacing: 0.01px;
            word-spacing: -0.55px;
          }
          #t4w_1 {
            left: 65px;
            bottom: 1212px;
            letter-spacing: -0.02px;
            word-spacing: -0.5px;
          }
          #t4x_1 {
            left: 176px;
            bottom: 1212px;
            letter-spacing: -0.17px;
            word-spacing: -0.83px;
          }
          #t4y_1 {
            left: 297px;
            bottom: 1212px;
            letter-spacing: 0.04px;
          }
          #t4z_1 {
            left: 65px;
            bottom: 1195px;
            letter-spacing: -0.3px;
            word-spacing: -0.9px;
          }
          #t50_1 {
            left: 171px;
            bottom: 1195px;
            letter-spacing: 0.01px;
          }
          #t51_1 {
            left: 537px;
            bottom: 886px;
            letter-spacing: -0.13px;
          }
          #t52_1 {
            left: 537px;
            bottom: 870px;
            letter-spacing: -0.11px;
          }
          #t53_1 {
            left: 580px;
            bottom: 870px;
          }
          #t54_1 {
            left: 609px;
            bottom: 870px;
            letter-spacing: -0.13px;
          }
          #t55_1 {
            left: 639px;
            bottom: 870px;
          }
          #t56_1 {
            left: 434px;
            bottom: 723px;
            letter-spacing: -0.14px;
          }
          #t57_1 {
            left: 449px;
            bottom: 723px;
            letter-spacing: -0.12px;
          }
          #t58_1 {
            left: 545px;
            bottom: 723px;
            letter-spacing: -0.13px;
            word-spacing: 4.59px;
          }
          #t59_1 {
            left: 684px;
            bottom: 723px;
            letter-spacing: -0.13px;
          }
          #t5a_1 {
            left: 773px;
            bottom: 723px;
          }
          #t5b_1 {
            left: 812px;
            bottom: 723px;
            letter-spacing: -0.12px;
          }
          #t5c_1 {
            left: 87px;
            bottom: 142px;
            letter-spacing: -0.11px;
          }
  
          .s1 {
            font-size: 14px;
            font-family: Arial-Bold_h;
            color: #000;
          }
          .s2 {
            font-size: 14px;
            font-family: Arial_i;
            color: #000;
          }
          .s3 {
            font-size: 27px;
            font-family: Arial_i;
            color: #000;
          }
          .s4 {
            font-size: 12px;
            font-family: Univers-Bold_-;
            color: #231f20;
          }
          .s5 {
            font-size: 12px;
            font-family: Univers_13;
            color: #231f20;
          }
          .s6 {
            font-size: 14px;
            font-family: ArialMT_40;
            color: #000;
          }
          .s7 {
            font-size: 14px;
            font-family: Arial_3s;
            color: #000;
          }
        </style>
        <!-- End inline CSS -->
  
        <!-- Begin embedded font definitions -->
        <style id="fonts1" type="text/css">
          @font-face {
            font-family: Arial-Bold_h;
            src: url("fonts/Arial-Bold_h.woff") format("woff");
          }
  
          @font-face {
            font-family: ArialMT_40;
            src: url("fonts/ArialMT_40.woff") format("woff");
          }
  
          @font-face {
            font-family: Arial_3s;
            src: url("fonts/Arial_3s.woff") format("woff");
          }
  
          @font-face {
            font-family: Arial_i;
            src: url("fonts/Arial_i.woff") format("woff");
          }
  
          @font-face {
            font-family: Univers-Bold_-;
            src: url("fonts/Univers-Bold_-.woff") format("woff");
          }
  
          @font-face {
            font-family: Univers_13;
            src: url("fonts/Univers_13.woff") format("woff");
          }
        </style>
        <!-- End embedded font definitions -->
  
        <!-- Begin page background -->
        <div
          id="pg1Overlay"
          style="
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0);
            -webkit-user-select: none;
          "
        ></div>
        <div id="pg1" style="-webkit-user-select: none">
          <object
            width="909"
            height="1286"
            data="1/1.svg"
            type="image/svg+xml"
            id="pdf1"
            style="
              width: 909px;
              height: 1286px;
              -moz-transform: scale(1);
              z-index: 0;
            "
          ></object>
        </div>
        <!-- End page background -->
  
        <!-- Begin text definitions (Positioned/styled in CSS) -->
        <div class="text-container">
          <span id="t1_1" class="t s1">maxon Switzerland ag </span>
          <span id="t2_1" class="t s2">Industriestrasse 23 </span>
          <span id="t3_1" class="t s2">Postfach 263 </span>
          <span id="t4_1" class="t s2">CH-6055 Alpnach Dorf </span>
          <span id="t5_1" class="t s2">Bank </span
          ><span id="t6_1" class="t s2">: </span
          ><span id="t7_1" class="t s2">. </span
          ><span id="t8_1" class="t s2">. </span
          ><span id="t9_1" class="t s2">. </span
          ><span id="ta_1" class="t s2">. </span
          ><span id="tb_1" class="t s2">. </span
          ><span id="tc_1" class="t s2">. </span
          ><span id="td_1" class="t s2">. </span
          ><span id="te_1" class="t s2">. </span
          ><span id="tf_1" class="t s2">. </span
          ><span id="tg_1" class="t s2">. </span
          ><span id="th_1" class="t s2">. </span
          ><span id="ti_1" class="t s2">. </span
          ><span id="tj_1" class="t s2">. </span
          ><span id="tk_1" class="t s2">. </span
          ><span id="tl_1" class="t s2">. </span
          ><span id="tm_1" class="t s2">. </span
          ><span id="tn_1" class="t s2">. </span
          ><span id="to_1" class="t s2">. </span
          ><span id="tp_1" class="t s2">. </span
          ><span id="tq_1" class="t s2">Credit Suisse, Effretikon, CHF </span>
          <span id="tr_1" class="t s2">Girokonto </span
          ><span id="ts_1" class="t s2">: </span
          ><span id="tt_1" class="t s2">. </span
          ><span id="tu_1" class="t s2">. </span
          ><span id="tv_1" class="t s2">. </span
          ><span id="tw_1" class="t s2">. </span
          ><span id="tx_1" class="t s2">. </span
          ><span id="ty_1" class="t s2">. </span
          ><span id="tz_1" class="t s2">. </span
          ><span id="t10_1" class="t s2">. </span
          ><span id="t11_1" class="t s2">. </span
          ><span id="t12_1" class="t s2">. </span
          ><span id="t13_1" class="t s2">. </span
          ><span id="t14_1" class="t s2">. </span
          ><span id="t15_1" class="t s2">. </span
          ><span id="t16_1" class="t s2">. </span
          ><span id="t17_1" class="t s2">CH80 0483 5082 2321 01 </span>
          <span id="t18_1" class="t s2">MWSt-Nr </span
          ><span id="t19_1" class="t s2">: </span
          ><span id="t1a_1" class="t s2">. </span
          ><span id="t1b_1" class="t s2">. </span
          ><span id="t1c_1" class="t s2">. </span
          ><span id="t1d_1" class="t s2">. </span
          ><span id="t1e_1" class="t s2">. </span
          ><span id="t1f_1" class="t s2">. </span
          ><span id="t1g_1" class="t s2">. </span
          ><span id="t1h_1" class="t s2">. </span
          ><span id="t1i_1" class="t s2">. </span
          ><span id="t1j_1" class="t s2">. </span
          ><span id="t1k_1" class="t s2">. </span
          ><span id="t1l_1" class="t s2">. </span
          ><span id="t1m_1" class="t s2">. </span
          ><span id="t1n_1" class="t s2">. </span
          ><span id="t1o_1" class="t s2">. </span
          ><span id="t1p_1" class="t s2">CHE-102.601.607 MWST </span>
          <span id="t1q_1" class="t s3">Bestellung </span>
          <span id="t1r_1" class="t s2">Nummer </span
          ><span id="t1s_1" class="t s2">: </span
          ><span id="t1t_1" class="t s2">. </span
          ><span id="t1u_1" class="t s2">. </span
          ><span id="t1v_1" class="t s2">. </span
          ><span id="t1w_1" class="t s2">. </span
          ><span id="t1x_1" class="t s2">. </span
          ><span id="t1y_1" class="t s2">. </span
          ><span id="t1z_1" class="t s2">. </span
          ><span id="t20_1" class="t s2">. </span
          ><span id="t21_1" class="t s2">. </span
          ><span id="t22_1" class="t s2">. </span
          ><span id="t23_1" class="t s2">. </span
          ><span id="t24_1" class="t s2">. </span
          ><span id="t25_1" class="t s2">. </span
          ><span id="t26_1" class="t s2">. </span
          ><span id="t27_1" class="t s2">. </span
          ><span id="t28_1" class="t s2">. </span
          ><span id="t29_1" class="t s2">932779-1 </span>
          <span id="t2a_1" class="t s2">Kreditorenkonto </span
          ><span id="t2b_1" class="t s2">: </span
          ><span id="t2c_1" class="t s2">. </span
          ><span id="t2d_1" class="t s2">. </span
          ><span id="t2e_1" class="t s2">. </span
          ><span id="t2f_1" class="t s2">. </span
          ><span id="t2g_1" class="t s2">. </span
          ><span id="t2h_1" class="t s2">. </span
          ><span id="t2i_1" class="t s2">. </span
          ><span id="t2j_1" class="t s2">. </span
          ><span id="t2k_1" class="t s2">1227 </span
          ><span id="t2l_1" class="t s2">Lieferadresse </span>
          <span id="t2m_1" class="t s2">Unsere Referenz </span
          ><span id="t2n_1" class="t s2">: </span
          ><span id="t2o_1" class="t s2">. </span
          ><span id="t2p_1" class="t s2">. </span
          ><span id="t2q_1" class="t s2">. </span
          ><span id="t2r_1" class="t s2">. </span
          ><span id="t2s_1" class="t s2">. </span
          ><span id="t2t_1" class="t s2">. </span
          ><span id="t2u_1" class="t s2">. </span
          ><span id="t2v_1" class="t s2">Hugo Thode </span>
          <span id="t2w_1" class="t s2">Ihre Referenz </span
          ><span id="t2x_1" class="t s2">: </span
          ><span id="t2y_1" class="t s2">. </span
          ><span id="t2z_1" class="t s2">. </span
          ><span id="t30_1" class="t s2">. </span
          ><span id="t31_1" class="t s2">. </span
          ><span id="t32_1" class="t s2">. </span
          ><span id="t33_1" class="t s2">. </span
          ><span id="t34_1" class="t s2">. </span
          ><span id="t35_1" class="t s2">. </span
          ><span id="t36_1" class="t s2">. </span
          ><span id="t37_1" class="t s2">. </span
          ><span id="t38_1" class="t s2">Herr Clemens Gander </span>
          <span id="t39_1" class="t s2">Kreditorreferenz </span
          ><span id="t3a_1" class="t s2">: </span
          ><span id="t3b_1" class="t s2">. </span
          ><span id="t3c_1" class="t s2">. </span
          ><span id="t3d_1" class="t s2">. </span
          ><span id="t3e_1" class="t s2">. </span
          ><span id="t3f_1" class="t s2">. </span
          ><span id="t3g_1" class="t s2">. </span
          ><span id="t3h_1" class="t s2">. </span
          ><span id="t3i_1" class="t s2">. </span>
          <span id="t3j_1" class="t s1">Micronel AG </span>
          <span id="t3k_1" class="t s1">Klein Ventilatoren </span>
          <span id="t3l_1" class="t s1">Zürcherstrasse 51 </span>
          <span id="t3m_1" class="t s1">CH-8317 Tagelswangen </span>
          <span id="t3n_1" class="t s2">Artikelnummer </span
          ><span id="t3o_1" class="t s2">Text </span
          ><span id="t3p_1" class="t s2">Lieferung </span
          ><span id="t3q_1" class="t s2">Menge </span
          ><span id="t3r_1" class="t s2">Einheit </span
          ><span id="t3s_1" class="t s2">Preis je Einhe </span
          ><span id="t3t_1" class="t s2">Einheit </span
          ><span id="t3u_1" class="t s2">Betrag </span>
          <span id="t3v_1" class="t s2">4645E-80450-M </span
          ><span id="t3w_1" class="t s2">MONTAGE EC 045 </span>
          <span id="t3x_1" class="t s2">Stand./12V/BASIC 302620 </span>
          <span id="t3y_1" class="t s2">Anlieferteil Platine </span>
          <span id="t3z_1" class="t s2">Micronel M425X-505A9 </span>
          <span id="t40_1" class="t s2">Maxon 272808 </span>
          <span id="t41_1" class="t s2">Maxon Art. 302620 </span>
          <span id="t42_1" class="t s2">FA 519619 </span>
          <span id="t43_1" class="t s2"
            >Die Platinen M425X-505A9 werden von Micronel beigestellt.
          </span>
          <span id="t44_1" class="t s2"
            >______________________________________________________________________________________________________
          </span>
          <span id="t45_1" class="t s2">Netto </span
          ><span id="t46_1" class="t s2">3,890.00 </span>
          <span id="t47_1" class="t s2">Mehrwertsteuer </span
          ><span id="t48_1" class="t s2">299.53 </span>
          <span id="t49_1" class="t s1">Total </span
          ><span id="t4a_1" class="t s1">CHF </span
          ><span id="t4b_1" class="t s1">4,189.53 </span>
          <span id="t4c_1" class="t s2">Zahlungsbedingungen </span
          ><span id="t4d_1" class="t s2">: </span
          ><span id="t4e_1" class="t s2">. </span
          ><span id="t4f_1" class="t s2">60 Tage netto </span>
          <span id="t4g_1" class="t s2">Lieferbedingungen </span
          ><span id="t4h_1" class="t s2">: </span
          ><span id="t4i_1" class="t s2">. </span
          ><span id="t4j_1" class="t s2">. </span
          ><span id="t4k_1" class="t s2">. </span
          ><span id="t4l_1" class="t s2">. </span
          ><span id="t4m_1" class="t s2">. </span
          ><span id="t4n_1" class="t s2">Incoterms 2010, FCA </span>
          <span id="t4o_1" class="t s2">Freundliche Grüsse </span>
          <span id="t4p_1" class="t s2">MICRONEL AG </span>
          <span id="t4q_1" class="t s2"
            >Wir erwarten Ihre Auftragsbestätigung in den nächsten drei
            Abeitstagen.
          </span>
          <span id="t4r_1" class="t s2"
            >______________________________________________________________________________________
          </span>
          <span id="t4s_1" class="t s2"
            >Zusätzlich verlangte Informationen:
          </span>
          <span id="t4t_1" class="t s2"
            >Auf dem Lieferschein muss die Verwendung des vorgeschriebenen
            Materials bestätigt werden inklusive der Materialangabe.
          </span>
          <span id="t4u_1" class="t s2"
            >Bitte geben sie auf sämtlichen Auftragsdokumenten unsere
            Bestellnummer, Artikelnummer und Zeichnungsnummer sowie den
          </span>
          <span id="t4v_1" class="t s4">Micronel AG </span>
          <span id="t4w_1" class="t s5">Zürcherstrasse 51 </span
          ><span id="t4x_1" class="t s5">8317 Tagelswangen </span
          ><span id="t4y_1" class="t s5">Switzerland </span>
          <span id="t4z_1" class="t s5">T +41 52 355 16 16 </span
          ><span id="t50_1" class="t s5">micronel.com </span>
          <span id="t51_1" class="t s2">Tagelswangen, 11.01.2023 </span>
          <span id="t52_1" class="t s2">Seite </span
          ><span id="t53_1" class="t s2">1 </span
          ><span id="t54_1" class="t s2">von </span
          ><span id="t55_1" class="t s6">1 </span>
          <span id="t56_1" class="t s6">22</span
          ><span id="t57_1" class="t s2">.04.2023 </span
          ><span id="t58_1" class="t s2">100.00 Stk </span
          ><span id="t59_1" class="t s2">38.900 </span
          ><span id="t5a_1" class="t s2">1 </span
          ><span id="t5b_1" class="t s2">3,890.00 </span>
          <span id="t5c_1" class="t s7"
            >Zolltarif und das Ursprungsland inkl. Ursprungserklärung an.
          </span>
        </div>
        <!-- End text definitions -->
      </div>
    </body>
  </html>
  `,
  2192: `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
    <meta charset="utf-8" />
  </head>

  <body style="margin: 0">
    <div
      id="p1"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1286px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_1 {
          left: 105px;
          bottom: 1034px;
          letter-spacing: 0.08px;
        }
        #t2_1 {
          left: 518px;
          bottom: 1161px;
          letter-spacing: 0.13px;
        }
        #t3_1 {
          left: 514px;
          bottom: 1192px;
          letter-spacing: 0.1px;
        }
        #t4_1 {
          left: 721px;
          bottom: 1161px;
          letter-spacing: 0.14px;
        }
        #t5_1 {
          left: 826px;
          bottom: 1161px;
          letter-spacing: 0.11px;
        }
        #t6_1 {
          left: 831px;
          bottom: 1144px;
          letter-spacing: 0.09px;
        }
        #t7_1 {
          left: 721px;
          bottom: 1144px;
          letter-spacing: 0.11px;
        }
        #t8_1 {
          left: 518px;
          bottom: 1144px;
          letter-spacing: 0.12px;
        }
        #t9_1 {
          left: 107px;
          bottom: 1006px;
          letter-spacing: 0.14px;
        }
        #ta_1 {
          left: 107px;
          bottom: 988px;
          letter-spacing: 0.12px;
        }
        #tb_1 {
          left: 107px;
          bottom: 970px;
          letter-spacing: 0.12px;
        }
        #tc_1 {
          left: 149px;
          bottom: 970px;
          letter-spacing: 0.13px;
        }
        #td_1 {
          left: 106px;
          bottom: 68px;
          letter-spacing: 0.12px;
        }
        #te_1 {
          left: 106px;
          bottom: 51px;
          letter-spacing: 0.11px;
        }
        #tf_1 {
          left: 106px;
          bottom: 33px;
          letter-spacing: 0.13px;
        }
        #tg_1 {
          left: 301px;
          bottom: 68px;
          letter-spacing: 0.1px;
        }
        #th_1 {
          left: 301px;
          bottom: 51px;
          letter-spacing: 0.11px;
        }
        #ti_1 {
          left: 301px;
          bottom: 33px;
          letter-spacing: 0.1px;
        }
        #tj_1 {
          left: 405px;
          bottom: 68px;
          letter-spacing: 0.09px;
        }
        #tk_1 {
          left: 565px;
          bottom: 68px;
          letter-spacing: 0.12px;
        }
        #tl_1 {
          left: 405px;
          bottom: 51px;
          letter-spacing: 0.12px;
        }
        #tm_1 {
          left: 565px;
          bottom: 51px;
          letter-spacing: 0.12px;
        }
        #tn_1 {
          left: 656px;
          bottom: 68px;
          letter-spacing: 0.12px;
        }
        #to_1 {
          left: 704px;
          bottom: 68px;
          letter-spacing: 0.11px;
        }
        #tp_1 {
          left: 656px;
          bottom: 51px;
          letter-spacing: 0.11px;
        }
        #tq_1 {
          left: 656px;
          bottom: 33px;
          letter-spacing: 0.12px;
        }
        #tr_1 {
          left: 704px;
          bottom: 51px;
          letter-spacing: 0.15px;
        }
        #ts_1 {
          left: 704px;
          bottom: 33px;
          letter-spacing: 0.13px;
        }
        #tt_1 {
          left: 448px;
          bottom: 68px;
          letter-spacing: 0.11px;
        }
        #tu_1 {
          left: 448px;
          bottom: 51px;
          letter-spacing: 0.11px;
        }
        #tv_1 {
          left: 106px;
          bottom: 145px;
          letter-spacing: -0.11px;
        }
        #tw_1 {
          left: 106px;
          bottom: 129px;
          letter-spacing: -0.12px;
        }
        #tx_1 {
          left: 106px;
          bottom: 113px;
          letter-spacing: -0.12px;
        }
        #ty_1 {
          left: 106px;
          bottom: 97px;
          letter-spacing: -0.11px;
        }
        #tz_1 {
          left: 518px;
          bottom: 1118px;
          letter-spacing: 0.12px;
        }
        #t10_1 {
          left: 518px;
          bottom: 1097px;
          letter-spacing: 0.12px;
        }
        #t11_1 {
          left: 518px;
          bottom: 1083px;
          letter-spacing: 0.11px;
        }
        #t12_1 {
          left: 721px;
          bottom: 1118px;
          letter-spacing: 0.1px;
        }
        #t13_1 {
          left: 721px;
          bottom: 1090px;
          letter-spacing: 0.11px;
        }
        #t14_1 {
          left: 518px;
          bottom: 1062px;
          letter-spacing: 0.11px;
        }
        #t15_1 {
          left: 518px;
          bottom: 1044px;
          letter-spacing: 0.13px;
        }
        #t16_1 {
          left: 721px;
          bottom: 1062px;
          letter-spacing: 0.11px;
        }
        #t17_1 {
          left: 721px;
          bottom: 1044px;
          letter-spacing: 0.12px;
        }
        #t18_1 {
          left: 518px;
          bottom: 1019px;
          letter-spacing: 0.1px;
        }
        #t19_1 {
          left: 518px;
          bottom: 975px;
          letter-spacing: 0.11px;
        }
        #t1a_1 {
          left: 521px;
          bottom: 958px;
          letter-spacing: 0.11px;
        }
        #t1b_1 {
          left: 518px;
          bottom: 932px;
          letter-spacing: 0.11px;
        }
        #t1c_1 {
          left: 518px;
          bottom: 910px;
          letter-spacing: 0.12px;
        }
        #t1d_1 {
          left: 518px;
          bottom: 896px;
          letter-spacing: 0.1px;
        }
        #t1e_1 {
          left: 518px;
          bottom: 882px;
          letter-spacing: 0.1px;
        }
        #t1f_1 {
          left: 552px;
          bottom: 882px;
          letter-spacing: 0.1px;
        }
        #t1g_1 {
          left: 106px;
          bottom: 812px;
          letter-spacing: 0.12px;
        }
        #t1h_1 {
          left: 792px;
          bottom: 812px;
          letter-spacing: 0.12px;
        }
        #t1i_1 {
          left: 574px;
          bottom: 812px;
          letter-spacing: 0.14px;
        }
        #t1j_1 {
          left: 635px;
          bottom: 812px;
          letter-spacing: 0.11px;
        }
        #t1k_1 {
          left: 409px;
          bottom: 812px;
          letter-spacing: 0.12px;
        }
        #t1l_1 {
          left: 90px;
          bottom: 769px;
        }
        #t1m_1 {
          left: 571px;
          bottom: 767px;
          letter-spacing: -0.13px;
        }
        #t1n_1 {
          left: 657px;
          bottom: 767px;
          letter-spacing: -0.13px;
        }
        #t1o_1 {
          left: 799px;
          bottom: 767px;
          letter-spacing: -0.12px;
        }
        #t1p_1 {
          left: 477px;
          bottom: 767px;
          letter-spacing: -0.12px;
        }
        #t1q_1 {
          left: 106px;
          bottom: 769px;
          letter-spacing: -0.13px;
        }
        #t1r_1 {
          left: 106px;
          bottom: 753px;
          letter-spacing: 0.1px;
        }
        #t1s_1 {
          left: 106px;
          bottom: 740px;
          letter-spacing: 0.1px;
        }
        #t1t_1 {
          left: 589px;
          bottom: 753px;
          letter-spacing: 0.14px;
        }
        #t1u_1 {
          left: 106px;
          bottom: 727px;
          letter-spacing: 0.1px;
        }
        #t1v_1 {
          left: 241px;
          bottom: 727px;
          letter-spacing: 0.1px;
        }
        #t1w_1 {
          left: 106px;
          bottom: 709px;
          letter-spacing: 0.12px;
        }
        #t1x_1 {
          left: 309px;
          bottom: 709px;
          letter-spacing: 0.12px;
        }
        #t1y_1 {
          left: 439px;
          bottom: 709px;
          letter-spacing: 0.12px;
        }
        #t1z_1 {
          left: 603px;
          bottom: 709px;
          letter-spacing: 0.13px;
        }
        #t20_1 {
          left: 180px;
          bottom: 691px;
          letter-spacing: 0.12px;
        }
        #t21_1 {
          left: 336px;
          bottom: 691px;
          letter-spacing: 0.11px;
        }
        #t22_1 {
          left: 483px;
          bottom: 691px;
          letter-spacing: 0.11px;
        }
        #t23_1 {
          left: 654px;
          bottom: 691px;
        }
        #t24_1 {
          left: 505px;
          bottom: 312px;
          letter-spacing: -0.23px;
        }
        #t25_1 {
          left: 805px;
          bottom: 312px;
          letter-spacing: -0.19px;
        }
        #t26_1 {
          left: 804px;
          bottom: 286px;
          letter-spacing: -0.19px;
        }
        #t27_1 {
          left: 505px;
          bottom: 286px;
          letter-spacing: -0.19px;
        }
        #t28_1 {
          left: 812px;
          bottom: 263px;
          letter-spacing: -0.19px;
        }
        #t29_1 {
          left: 505px;
          bottom: 263px;
          letter-spacing: -0.2px;
        }
        #t2a_1 {
          left: 505px;
          bottom: 236px;
          letter-spacing: -0.14px;
        }
        #t2b_1 {
          left: 799px;
          bottom: 236px;
          letter-spacing: -0.12px;
        }
        #t2c_1 {
          left: 106px;
          bottom: 195px;
          letter-spacing: -0.19px;
        }
        #t2d_1 {
          left: 219px;
          bottom: 195px;
          letter-spacing: -0.19px;
        }
        #t2e_1 {
          left: 357px;
          bottom: 195px;
          letter-spacing: -0.17px;
        }
        #t2f_1 {
          left: 470px;
          bottom: 195px;
          letter-spacing: -0.26px;
        }
        #t2g_1 {
          left: 570px;
          bottom: 195px;
          letter-spacing: -0.19px;
        }
        #t2h_1 {
          left: 682px;
          bottom: 195px;
          letter-spacing: -0.19px;
        }

        .s1 {
          font-size: 9px;
          font-family: ArialMT_8;
          color: #000;
        }
        .s2 {
          font-size: 12px;
          font-family: Arial-BoldMT_c;
          color: #000;
        }
        .s3 {
          font-size: 17px;
          font-family: Arial-BoldMT_c;
          color: #000;
        }
        .s4 {
          font-size: 12px;
          font-family: ArialMT_8;
          color: #000;
        }
        .s5 {
          font-size: 15px;
          font-family: ArialMT_8;
          color: #000;
        }
        .s6 {
          font-size: 14px;
          font-family: ArialMT_8;
          color: #000;
        }
        .s7 {
          font-size: 14px;
          font-family: Arial-BoldMT_c;
          color: #000;
        }
        .s8 {
          font-size: 13px;
          font-family: ArialMT_8;
          color: #696969;
        }
        .s9 {
          font-size: 13px;
          font-family: ArialMT_8;
          color: #000;
        }
        .sa {
          font-size: 13px;
          font-family: Arial-BoldMT_c;
          color: #000;
        }
        .sb {
          font-size: 14px;
          font-family: Arial-BoldMT_c;
          color: #696969;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts1" type="text/css">
        @font-face {
          font-family: Arial-BoldMT_c;
          src: url("fonts/Arial-BoldMT_c.woff") format("woff");
        }

        @font-face {
          font-family: ArialMT_8;
          src: url("fonts/ArialMT_8.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg1Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg1" style="-webkit-user-select: none">
        <object
          width="909"
          height="1286"
          data="1/1.svg"
          type="image/svg+xml"
          id="pdf1"
          style="
            width: 909px;
            height: 1286px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_1" class="t s1"
          >INTEGRA Biosciences AG, Tardisstrasse 201, 7205 Zizers
        </span>
        <span id="t2_1" class="t s2">Belegnummer </span>
        <span id="t3_1" class="t s3">Bestellung </span>
        <span id="t4_1" class="t s2">Datum </span
        ><span id="t5_1" class="t s2">Seite </span>
        <span id="t6_1" class="t s4">1 / 1 </span
        ><span id="t7_1" class="t s4">31.08.2022 </span
        ><span id="t8_1" class="t s4">225103028 </span>
        <span id="t9_1" class="t s5">Maxon Switzerland AG </span>
        <span id="ta_1" class="t s5">Industriestrasse 23 </span>
        <span id="tb_1" class="t s5">6055 </span
        ><span id="tc_1" class="t s5">Alpnach Dorf </span>
        <span id="td_1" class="t s4">INTEGRA Biosciences AG </span>
        <span id="te_1" class="t s4">www.integra-biosciences.com </span>
        <span id="tf_1" class="t s4">MWST: CHE-116.348.219 MWST </span>
        <span id="tg_1" class="t s4">Tardisstrasse 201 </span>
        <span id="th_1" class="t s4">CH-7205 Zizers </span>
        <span id="ti_1" class="t s4">Switzerland </span>
        <span id="tj_1" class="t s4">Tel. </span
        ><span id="tk_1" class="t s4">Bank: UBS AG </span>
        <span id="tl_1" class="t s4">Fax </span
        ><span id="tm_1" class="t s4">CH-7000 Chur </span>
        <span id="tn_1" class="t s4">BC 208 </span
        ><span id="to_1" class="t s4">Konto: 927241.01N </span>
        <span id="tp_1" class="t s4">BIC: </span>
        <span id="tq_1" class="t s4">IBAN: </span>
        <span id="tr_1" class="t s4">UBSWCHZH80A </span>
        <span id="ts_1" class="t s4">CH620020820892724101N </span>
        <span id="tt_1" class="t s4">+41(0)81 286 95 30 </span>
        <span id="tu_1" class="t s4">+41(0)81 286 95 33 </span>
        <span id="tv_1" class="t s6"
          >Bitte senden Sie uns Ihre Auftragsbestätigung innerhalb von 3 Tagen
          an:
        </span>
        <span id="tw_1" class="t s6">purchase-ch@integra-biosciences.com </span>
        <span id="tx_1" class="t s6"
          >Wir bitten um die Angabe unserer Auftragsnummer in allen Ihren
          Dokumenten
        </span>
        <span id="ty_1" class="t s6"
          >Dieses Dokument ist digital erstellt und ohne Unterschrift
          rechtswirksam
        </span>
        <span id="tz_1" class="t s2">Unsere Referenz </span>
        <span id="t10_1" class="t s4">Vanessa Gnos </span>
        <span id="t11_1" class="t s4">vgn@integra-biosciences.com </span>
        <span id="t12_1" class="t s2">Tel. </span>
        <span id="t13_1" class="t s4">+41 81 286 96 65 </span>
        <span id="t14_1" class="t s2">Lieferanten-Nr. </span>
        <span id="t15_1" class="t s4">V10000360 </span>
        <span id="t16_1" class="t s2">Ust-ID-Nr. Kunde </span>
        <span id="t17_1" class="t s4">464684 </span>
        <span id="t18_1" class="t s2">Referenz Nr. Lieferant </span>
        <span id="t19_1" class="t s2">Kontaktperson Lieferant </span>
        <span id="t1a_1" class="t s4">Auftragsbearbeitung </span>
        <span id="t1b_1" class="t s2">Lieferadresse </span>
        <span id="t1c_1" class="t s4">INTEGRA Biosciences AG </span>
        <span id="t1d_1" class="t s4">Tardisstrasse 201 </span>
        <span id="t1e_1" class="t s4">7205 </span
        ><span id="t1f_1" class="t s4">Zizers </span>
        <span id="t1g_1" class="t s2">Beschreibung </span
        ><span id="t1h_1" class="t s2">Betrag CHF </span
        ><span id="t1i_1" class="t s2">Menge </span
        ><span id="t1j_1" class="t s2">Einzelpreis </span
        ><span id="t1k_1" class="t s2">Lieferdatum Eingang </span>
        <span id="t1l_1" class="t s7">1 </span>
        <span id="t1m_1" class="t s6">300.00 </span
        ><span id="t1n_1" class="t s6">133.20 </span
        ><span id="t1o_1" class="t s6">39'960.00 </span
        ><span id="t1p_1" class="t s6">19.07.24 </span>
        <span id="t1q_1" class="t s7"
          >Motor Maxon EC 45 flat with Encoder
        </span>
        <span id="t1r_1" class="t s2">Artikel Nr.: 129750 </span>
        <span id="t1s_1" class="t s4">Lieferantenkatalog-Nr.: 693230 </span>
        <span id="t1t_1" class="t s4">STK </span>
        <span id="t1u_1" class="t s4">Zeichnung Nr.: 129750 </span
        ><span id="t1v_1" class="t s4">Revision Nr.: 00 </span>
        <span id="t1w_1" class="t s2">Blanket Agreement No. </span
        ><span id="t1x_1" class="t s2">QTY Total </span
        ><span id="t1y_1" class="t s2">QTY ordered </span
        ><span id="t1z_1" class="t s2">QTY open </span>
        <span id="t20_1" class="t s4">210100642 </span
        ><span id="t21_1" class="t s4">2 000 </span
        ><span id="t22_1" class="t s4">2 000 </span
        ><span id="t23_1" class="t s4">0 </span>
        <span id="t24_1" class="t s8">Zwischensumme </span
        ><span id="t25_1" class="t s9">39'960.00 </span>
        <span id="t26_1" class="t sa">39'960.00 </span
        ><span id="t27_1" class="t s8">Nettobetrag </span>
        <span id="t28_1" class="t s9">3'076.92 </span
        ><span id="t29_1" class="t s8">MWST 7.7% auf 39'960.00 </span>
        <span id="t2a_1" class="t sb">Betrag CHF </span
        ><span id="t2b_1" class="t s7">43'036.92 </span>
        <span id="t2c_1" class="t s8">Zahlungskondition </span
        ><span id="t2d_1" class="t s9">Net 30 days </span
        ><span id="t2e_1" class="t s8">Lieferkonditionen: </span
        ><span id="t2f_1" class="t s9">FCA </span
        ><span id="t2g_1" class="t s8">Versandart: </span
        ><span id="t2h_1" class="t s9">Roadfreight </span>
      </div>
      <!-- End text definitions -->
    </div>
  </body>
</html>
  `,
  '2192b': `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
    <meta charset="utf-8" />
  </head>

  <body style="margin: 0">
    <div
      id="p1"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1286px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_1 {
          left: 105px;
          bottom: 1034px;
          letter-spacing: 0.08px;
        }
        #t2_1 {
          left: 518px;
          bottom: 1161px;
          letter-spacing: 0.13px;
        }
        #t3_1 {
          left: 514px;
          bottom: 1192px;
          letter-spacing: 0.1px;
        }
        #t4_1 {
          left: 721px;
          bottom: 1161px;
          letter-spacing: 0.14px;
        }
        #t5_1 {
          left: 826px;
          bottom: 1161px;
          letter-spacing: 0.11px;
        }
        #t6_1 {
          left: 831px;
          bottom: 1144px;
          letter-spacing: 0.09px;
        }
        #t7_1 {
          left: 721px;
          bottom: 1144px;
          letter-spacing: 0.11px;
        }
        #t8_1 {
          left: 518px;
          bottom: 1144px;
          letter-spacing: 0.12px;
        }
        #t9_1 {
          left: 107px;
          bottom: 1006px;
          letter-spacing: 0.14px;
        }
        #ta_1 {
          left: 107px;
          bottom: 988px;
          letter-spacing: 0.12px;
        }
        #tb_1 {
          left: 107px;
          bottom: 970px;
          letter-spacing: 0.12px;
        }
        #tc_1 {
          left: 149px;
          bottom: 970px;
          letter-spacing: 0.13px;
        }
        #td_1 {
          left: 106px;
          bottom: 68px;
          letter-spacing: 0.12px;
        }
        #te_1 {
          left: 106px;
          bottom: 51px;
          letter-spacing: 0.11px;
        }
        #tf_1 {
          left: 106px;
          bottom: 33px;
          letter-spacing: 0.13px;
        }
        #tg_1 {
          left: 301px;
          bottom: 68px;
          letter-spacing: 0.1px;
        }
        #th_1 {
          left: 301px;
          bottom: 51px;
          letter-spacing: 0.11px;
        }
        #ti_1 {
          left: 301px;
          bottom: 33px;
          letter-spacing: 0.1px;
        }
        #tj_1 {
          left: 405px;
          bottom: 68px;
          letter-spacing: 0.09px;
        }
        #tk_1 {
          left: 565px;
          bottom: 68px;
          letter-spacing: 0.12px;
        }
        #tl_1 {
          left: 405px;
          bottom: 51px;
          letter-spacing: 0.12px;
        }
        #tm_1 {
          left: 565px;
          bottom: 51px;
          letter-spacing: 0.12px;
        }
        #tn_1 {
          left: 656px;
          bottom: 68px;
          letter-spacing: 0.12px;
        }
        #to_1 {
          left: 704px;
          bottom: 68px;
          letter-spacing: 0.11px;
        }
        #tp_1 {
          left: 656px;
          bottom: 51px;
          letter-spacing: 0.11px;
        }
        #tq_1 {
          left: 656px;
          bottom: 33px;
          letter-spacing: 0.12px;
        }
        #tr_1 {
          left: 704px;
          bottom: 51px;
          letter-spacing: 0.15px;
        }
        #ts_1 {
          left: 704px;
          bottom: 33px;
          letter-spacing: 0.13px;
        }
        #tt_1 {
          left: 448px;
          bottom: 68px;
          letter-spacing: 0.11px;
        }
        #tu_1 {
          left: 448px;
          bottom: 51px;
          letter-spacing: 0.11px;
        }
        #tv_1 {
          left: 106px;
          bottom: 145px;
          letter-spacing: -0.11px;
        }
        #tw_1 {
          left: 106px;
          bottom: 129px;
          letter-spacing: -0.12px;
        }
        #tx_1 {
          left: 106px;
          bottom: 113px;
          letter-spacing: -0.12px;
        }
        #ty_1 {
          left: 106px;
          bottom: 97px;
          letter-spacing: -0.11px;
        }
        #tz_1 {
          left: 518px;
          bottom: 1118px;
          letter-spacing: 0.12px;
        }
        #t10_1 {
          left: 518px;
          bottom: 1097px;
          letter-spacing: 0.12px;
        }
        #t11_1 {
          left: 518px;
          bottom: 1083px;
          letter-spacing: 0.11px;
        }
        #t12_1 {
          left: 721px;
          bottom: 1118px;
          letter-spacing: 0.1px;
        }
        #t13_1 {
          left: 721px;
          bottom: 1090px;
          letter-spacing: 0.11px;
        }
        #t14_1 {
          left: 518px;
          bottom: 1062px;
          letter-spacing: 0.11px;
        }
        #t15_1 {
          left: 518px;
          bottom: 1044px;
          letter-spacing: 0.13px;
        }
        #t16_1 {
          left: 721px;
          bottom: 1062px;
          letter-spacing: 0.11px;
        }
        #t17_1 {
          left: 721px;
          bottom: 1044px;
          letter-spacing: 0.12px;
        }
        #t18_1 {
          left: 518px;
          bottom: 1019px;
          letter-spacing: 0.1px;
        }
        #t19_1 {
          left: 518px;
          bottom: 975px;
          letter-spacing: 0.11px;
        }
        #t1a_1 {
          left: 521px;
          bottom: 958px;
          letter-spacing: 0.11px;
        }
        #t1b_1 {
          left: 518px;
          bottom: 932px;
          letter-spacing: 0.11px;
        }
        #t1c_1 {
          left: 518px;
          bottom: 910px;
          letter-spacing: 0.12px;
        }
        #t1d_1 {
          left: 518px;
          bottom: 896px;
          letter-spacing: 0.1px;
        }
        #t1e_1 {
          left: 518px;
          bottom: 882px;
          letter-spacing: 0.1px;
        }
        #t1f_1 {
          left: 552px;
          bottom: 882px;
          letter-spacing: 0.1px;
        }
        #t1g_1 {
          left: 106px;
          bottom: 812px;
          letter-spacing: 0.12px;
        }
        #t1h_1 {
          left: 792px;
          bottom: 812px;
          letter-spacing: 0.12px;
        }
        #t1i_1 {
          left: 574px;
          bottom: 812px;
          letter-spacing: 0.14px;
        }
        #t1j_1 {
          left: 635px;
          bottom: 812px;
          letter-spacing: 0.11px;
        }
        #t1k_1 {
          left: 409px;
          bottom: 812px;
          letter-spacing: 0.12px;
        }
        #t1l_1 {
          left: 90px;
          bottom: 769px;
        }
        #t1m_1 {
          left: 571px;
          bottom: 767px;
          letter-spacing: -0.13px;
        }
        #t1n_1 {
          left: 657px;
          bottom: 767px;
          letter-spacing: -0.13px;
        }
        #t1o_1 {
          left: 799px;
          bottom: 767px;
          letter-spacing: -0.12px;
        }
        #t1p_1 {
          left: 477px;
          bottom: 767px;
          letter-spacing: -0.12px;
        }
        #t1q_1 {
          left: 106px;
          bottom: 769px;
          letter-spacing: -0.14px;
        }
        #t1r_1 {
          left: 106px;
          bottom: 753px;
          letter-spacing: 0.1px;
        }
        #t1s_1 {
          left: 106px;
          bottom: 740px;
          letter-spacing: 0.1px;
        }
        #t1t_1 {
          left: 589px;
          bottom: 753px;
          letter-spacing: 0.14px;
        }
        #t1u_1 {
          left: 106px;
          bottom: 727px;
          letter-spacing: 0.1px;
        }
        #t1v_1 {
          left: 241px;
          bottom: 727px;
          letter-spacing: 0.1px;
        }
        #t1w_1 {
          left: 108px;
          bottom: 711px;
          letter-spacing: 0.11px;
        }
        #t1x_1 {
          left: 108px;
          bottom: 697px;
          letter-spacing: 0.12px;
        }
        #t1y_1 {
          left: 108px;
          bottom: 683px;
          letter-spacing: 0.12px;
        }
        #t1z_1 {
          left: 106px;
          bottom: 667px;
          letter-spacing: 0.12px;
        }
        #t20_1 {
          left: 309px;
          bottom: 667px;
          letter-spacing: 0.12px;
        }
        #t21_1 {
          left: 439px;
          bottom: 667px;
          letter-spacing: 0.12px;
        }
        #t22_1 {
          left: 603px;
          bottom: 667px;
          letter-spacing: 0.13px;
        }
        #t23_1 {
          left: 180px;
          bottom: 649px;
          letter-spacing: 0.12px;
        }
        #t24_1 {
          left: 336px;
          bottom: 649px;
          letter-spacing: 0.11px;
        }
        #t25_1 {
          left: 493px;
          bottom: 649px;
          letter-spacing: 0.12px;
        }
        #t26_1 {
          left: 640px;
          bottom: 649px;
          letter-spacing: 0.12px;
        }
        #t27_1 {
          left: 505px;
          bottom: 312px;
          letter-spacing: -0.23px;
        }
        #t28_1 {
          left: 805px;
          bottom: 312px;
          letter-spacing: -0.19px;
        }
        #t29_1 {
          left: 804px;
          bottom: 286px;
          letter-spacing: -0.19px;
        }
        #t2a_1 {
          left: 505px;
          bottom: 286px;
          letter-spacing: -0.19px;
        }
        #t2b_1 {
          left: 821px;
          bottom: 263px;
          letter-spacing: -0.2px;
        }
        #t2c_1 {
          left: 505px;
          bottom: 263px;
          letter-spacing: -0.2px;
        }
        #t2d_1 {
          left: 505px;
          bottom: 236px;
          letter-spacing: -0.14px;
        }
        #t2e_1 {
          left: 799px;
          bottom: 236px;
          letter-spacing: -0.12px;
        }
        #t2f_1 {
          left: 106px;
          bottom: 195px;
          letter-spacing: -0.19px;
        }
        #t2g_1 {
          left: 219px;
          bottom: 195px;
          letter-spacing: -0.19px;
        }
        #t2h_1 {
          left: 357px;
          bottom: 195px;
          letter-spacing: -0.17px;
        }
        #t2i_1 {
          left: 470px;
          bottom: 195px;
          letter-spacing: -0.26px;
        }
        #t2j_1 {
          left: 570px;
          bottom: 195px;
          letter-spacing: -0.19px;
        }
        #t2k_1 {
          left: 682px;
          bottom: 195px;
          letter-spacing: -0.19px;
        }

        .s1 {
          font-size: 9px;
          font-family: ArialMT_c;
          color: #000;
        }
        .s2 {
          font-size: 12px;
          font-family: Arial-BoldMT_8;
          color: #000;
        }
        .s3 {
          font-size: 17px;
          font-family: Arial-BoldMT_8;
          color: #000;
        }
        .s4 {
          font-size: 12px;
          font-family: ArialMT_c;
          color: #000;
        }
        .s5 {
          font-size: 15px;
          font-family: ArialMT_c;
          color: #000;
        }
        .s6 {
          font-size: 14px;
          font-family: ArialMT_c;
          color: #000;
        }
        .s7 {
          font-size: 14px;
          font-family: Arial-BoldMT_8;
          color: #000;
        }
        .s8 {
          font-size: 13px;
          font-family: ArialMT_c;
          color: #696969;
        }
        .s9 {
          font-size: 13px;
          font-family: ArialMT_c;
          color: #000;
        }
        .sa {
          font-size: 13px;
          font-family: Arial-BoldMT_8;
          color: #000;
        }
        .sb {
          font-size: 14px;
          font-family: Arial-BoldMT_8;
          color: #696969;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts1" type="text/css">
        @font-face {
          font-family: Arial-BoldMT_8;
          src: url("fonts/Arial-BoldMT_8.woff") format("woff");
        }

        @font-face {
          font-family: ArialMT_c;
          src: url("fonts/ArialMT_c.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg1Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg1" style="-webkit-user-select: none">
        <object
          width="909"
          height="1286"
          data="1/1.svg"
          type="image/svg+xml"
          id="pdf1"
          style="
            width: 909px;
            height: 1286px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_1" class="t s1"
          >INTEGRA Biosciences AG, Tardisstrasse 201, 7205 Zizers
        </span>
        <span id="t2_1" class="t s2">Belegnummer </span>
        <span id="t3_1" class="t s3">Bestellung </span>
        <span id="t4_1" class="t s2">Datum </span
        ><span id="t5_1" class="t s2">Seite </span>
        <span id="t6_1" class="t s4">1 / 1 </span
        ><span id="t7_1" class="t s4">28.09.2022 </span
        ><span id="t8_1" class="t s4">225103442 </span>
        <span id="t9_1" class="t s5">Maxon Switzerland AG </span>
        <span id="ta_1" class="t s5">Industriestrasse 23 </span>
        <span id="tb_1" class="t s5">6055 </span
        ><span id="tc_1" class="t s5">Alpnach Dorf </span>
        <span id="td_1" class="t s4">INTEGRA Biosciences AG </span>
        <span id="te_1" class="t s4">www.integra-biosciences.com </span>
        <span id="tf_1" class="t s4">MWST: CHE-116.348.219 MWST </span>
        <span id="tg_1" class="t s4">Tardisstrasse 201 </span>
        <span id="th_1" class="t s4">CH-7205 Zizers </span>
        <span id="ti_1" class="t s4">Switzerland </span>
        <span id="tj_1" class="t s4">Tel. </span
        ><span id="tk_1" class="t s4">Bank: UBS AG </span>
        <span id="tl_1" class="t s4">Fax </span
        ><span id="tm_1" class="t s4">CH-7000 Chur </span>
        <span id="tn_1" class="t s4">BC 208 </span
        ><span id="to_1" class="t s4">Konto: 927241.01N </span>
        <span id="tp_1" class="t s4">BIC: </span>
        <span id="tq_1" class="t s4">IBAN: </span>
        <span id="tr_1" class="t s4">UBSWCHZH80A </span>
        <span id="ts_1" class="t s4">CH620020820892724101N </span>
        <span id="tt_1" class="t s4">+41(0)81 286 95 30 </span>
        <span id="tu_1" class="t s4">+41(0)81 286 95 33 </span>
        <span id="tv_1" class="t s6"
          >Bitte senden Sie uns Ihre Auftragsbestätigung innerhalb von 3 Tagen
          an:
        </span>
        <span id="tw_1" class="t s6">purchase-ch@integra-biosciences.com </span>
        <span id="tx_1" class="t s6"
          >Wir bitten um die Angabe unserer Auftragsnummer in allen Ihren
          Dokumenten
        </span>
        <span id="ty_1" class="t s6"
          >Dieses Dokument ist digital erstellt und ohne Unterschrift
          rechtswirksam
        </span>
        <span id="tz_1" class="t s2">Unsere Referenz </span>
        <span id="t10_1" class="t s4">Vanessa Gnos </span>
        <span id="t11_1" class="t s4">vgn@integra-biosciences.com </span>
        <span id="t12_1" class="t s2">Tel. </span>
        <span id="t13_1" class="t s4">+41 81 286 96 65 </span>
        <span id="t14_1" class="t s2">Lieferanten-Nr. </span>
        <span id="t15_1" class="t s4">V10000360 </span>
        <span id="t16_1" class="t s2">Ust-ID-Nr. Kunde </span>
        <span id="t17_1" class="t s4">464684 </span>
        <span id="t18_1" class="t s2">Referenz Nr. Lieferant </span>
        <span id="t19_1" class="t s2">Kontaktperson Lieferant </span>
        <span id="t1a_1" class="t s4">Auftragsbearbeitung </span>
        <span id="t1b_1" class="t s2">Lieferadresse </span>
        <span id="t1c_1" class="t s4">INTEGRA Biosciences AG </span>
        <span id="t1d_1" class="t s4">Tardisstrasse 201 </span>
        <span id="t1e_1" class="t s4">7205 </span
        ><span id="t1f_1" class="t s4">Zizers </span>
        <span id="t1g_1" class="t s2">Beschreibung </span
        ><span id="t1h_1" class="t s2">Betrag CHF </span
        ><span id="t1i_1" class="t s2">Menge </span
        ><span id="t1j_1" class="t s2">Einzelpreis </span
        ><span id="t1k_1" class="t s2">Lieferdatum Eingang </span>
        <span id="t1l_1" class="t s7">1 </span>
        <span id="t1m_1" class="t s6">100.00 </span
        ><span id="t1n_1" class="t s6">126.60 </span
        ><span id="t1o_1" class="t s6">12'660.00 </span
        ><span id="t1p_1" class="t s6">20.02.23 </span>
        <span id="t1q_1" class="t s7"
          >DC-Motor Maxon ECmax30 40W Tacho Encoder
        </span>
        <span id="t1r_1" class="t s2">Artikel Nr.: 125702 </span>
        <span id="t1s_1" class="t s4">Lieferantenkatalog-Nr.: 402756 </span>
        <span id="t1t_1" class="t s4">STK </span>
        <span id="t1u_1" class="t s4">Zeichnung Nr.: 125702 </span
        ><span id="t1v_1" class="t s4">Revision Nr.: 00 </span>
        <span id="t1w_1" class="t s4">bestehend aus: </span>
        <span id="t1x_1" class="t s4"
          >402698 maxon EC-max30 BL D 40W KL 2WE A (272770)
        </span>
        <span id="t1y_1" class="t s4"
          >402733 maxon tacho MR 1000IMP 3K 50P ASIC (225780)
        </span>
        <span id="t1z_1" class="t s2">Blanket Agreement No. </span
        ><span id="t20_1" class="t s2">QTY Total </span
        ><span id="t21_1" class="t s2">QTY ordered </span
        ><span id="t22_1" class="t s2">QTY open </span>
        <span id="t23_1" class="t s4">210100577 </span
        ><span id="t24_1" class="t s4">1 000 </span
        ><span id="t25_1" class="t s4">500 </span
        ><span id="t26_1" class="t s4">500 </span>
        <span id="t27_1" class="t s8">Zwischensumme </span
        ><span id="t28_1" class="t s9">12'660.00 </span>
        <span id="t29_1" class="t sa">12'660.00 </span
        ><span id="t2a_1" class="t s8">Nettobetrag </span>
        <span id="t2b_1" class="t s9">974.82 </span
        ><span id="t2c_1" class="t s8">MWST 7.7% auf 12'660.00 </span>
        <span id="t2d_1" class="t sb">Betrag CHF </span
        ><span id="t2e_1" class="t s7">13'634.82 </span>
        <span id="t2f_1" class="t s8">Zahlungskondition </span
        ><span id="t2g_1" class="t s9">Net 30 days </span
        ><span id="t2h_1" class="t s8">Lieferkonditionen: </span
        ><span id="t2i_1" class="t s9">FCA </span
        ><span id="t2j_1" class="t s8">Versandart: </span
        ><span id="t2k_1" class="t s9">Roadfreight </span>
      </div>
      <!-- End text definitions -->
    </div>
  </body>
</html>

  `,
  104353: `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
    <meta charset="utf-8" />
  </head>

  <body style="margin: 0">
    <div
      id="p1"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1286px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_1 {
          left: 65px;
          bottom: 611px;
          letter-spacing: 0.1px;
          word-spacing: -0.02px;
        }
        #t2_1 {
          left: 65px;
          bottom: 593px;
          letter-spacing: 0.09px;
          word-spacing: -0.02px;
        }
        #t3_1 {
          left: 65px;
          bottom: 574px;
          letter-spacing: 7.92px;
        }
        #t4_1 {
          left: 141px;
          bottom: 574px;
          letter-spacing: 7.92px;
        }
        #t5_1 {
          left: 202px;
          bottom: 574px;
          letter-spacing: 7.92px;
        }
        #t6_1 {
          left: 248px;
          bottom: 574px;
          letter-spacing: 7.92px;
        }
        #t7_1 {
          left: 355px;
          bottom: 574px;
          letter-spacing: 0.12px;
        }
        #t8_1 {
          left: 389px;
          bottom: 574px;
          letter-spacing: 7.92px;
        }
        #t9_1 {
          left: 496px;
          bottom: 574px;
          letter-spacing: 7.92px;
        }
        #ta_1 {
          left: 557px;
          bottom: 574px;
          letter-spacing: 7.92px;
        }
        #tb_1 {
          left: 679px;
          bottom: 574px;
          letter-spacing: 7.92px;
        }
        #tc_1 {
          left: 65px;
          bottom: 566px;
          letter-spacing: -0.15px;
        }
        #td_1 {
          left: 65px;
          bottom: 547px;
          letter-spacing: 0.12px;
        }
        #te_1 {
          left: 130px;
          bottom: 547px;
          letter-spacing: 0.11px;
        }
        #tf_1 {
          left: 260px;
          bottom: 547px;
          letter-spacing: 0.13px;
        }
        #tg_1 {
          left: 368px;
          bottom: 547px;
          letter-spacing: 0.11px;
        }
        #th_1 {
          left: 130px;
          bottom: 529px;
          letter-spacing: 0.1px;
        }
        #ti_1 {
          left: 260px;
          bottom: 529px;
          letter-spacing: 0.1px;
          word-spacing: -0.01px;
        }
        #tj_1 {
          left: 368px;
          bottom: 529px;
          letter-spacing: 0.1px;
        }
        #tk_1 {
          left: 455px;
          bottom: 529px;
          letter-spacing: 0.09px;
          word-spacing: -0.01px;
        }
        #tl_1 {
          left: 563px;
          bottom: 529px;
          letter-spacing: 0.1px;
          word-spacing: -0.01px;
        }
        #tm_1 {
          left: 65px;
          bottom: 520px;
          letter-spacing: -0.15px;
        }
        #tn_1 {
          left: 65px;
          bottom: 501px;
          letter-spacing: 0.12px;
        }
        #to_1 {
          left: 130px;
          bottom: 501px;
          letter-spacing: 0.12px;
        }
        #tp_1 {
          left: 260px;
          bottom: 501px;
          letter-spacing: 0.16px;
        }
        #tq_1 {
          left: 368px;
          bottom: 501px;
          letter-spacing: 0.13px;
          word-spacing: -0.02px;
        }
        #tr_1 {
          left: 256px;
          bottom: 483px;
          letter-spacing: 0.12px;
        }
        #ts_1 {
          left: 346px;
          bottom: 483px;
          letter-spacing: 0.13px;
        }
        #tt_1 {
          left: 429px;
          bottom: 483px;
          letter-spacing: 0.1px;
        }
        #tu_1 {
          left: 538px;
          bottom: 483px;
          letter-spacing: 0.1px;
        }
        #tv_1 {
          left: 65px;
          bottom: 464px;
          letter-spacing: 0.12px;
          word-spacing: -0.02px;
        }
        #tw_1 {
          left: 606px;
          bottom: 464px;
          letter-spacing: 0.08px;
          word-spacing: -0.01px;
        }
        #tx_1 {
          left: 65px;
          bottom: 446px;
          letter-spacing: 0.1px;
          word-spacing: -0.02px;
        }
        #ty_1 {
          left: 346px;
          bottom: 446px;
          letter-spacing: 0.1px;
          word-spacing: -0.02px;
        }
        #tz_1 {
          left: 606px;
          bottom: 446px;
          letter-spacing: 0.09px;
        }
        #t10_1 {
          left: 65px;
          bottom: 428px;
          letter-spacing: 0.1px;
          word-spacing: -0.02px;
        }
        #t11_1 {
          left: 65px;
          bottom: 409px;
          letter-spacing: 0.12px;
          word-spacing: -0.02px;
        }
        #t12_1 {
          left: 65px;
          bottom: 364px;
          letter-spacing: -0.15px;
        }
        #t13_1 {
          left: 274px;
          bottom: 345px;
          letter-spacing: 0.1px;
          word-spacing: -0.02px;
        }
        #t14_1 {
          left: 538px;
          bottom: 345px;
          letter-spacing: 0.1px;
        }
        #t15_1 {
          left: 65px;
          bottom: 290px;
          letter-spacing: 0.11px;
          word-spacing: -0.02px;
        }
        #t16_1 {
          left: 60px;
          bottom: 1027px;
          letter-spacing: 0.09px;
          word-spacing: -0.01px;
        }
        #t17_1 {
          left: 203px;
          bottom: 1027px;
          letter-spacing: 0.09px;
          word-spacing: -0.01px;
        }
        #t18_1 {
          left: 60px;
          bottom: 1012px;
          letter-spacing: 0.07px;
        }
        #t19_1 {
          left: 203px;
          bottom: 1012px;
          letter-spacing: 0.08px;
          word-spacing: -0.01px;
        }
        #t1a_1 {
          left: 60px;
          bottom: 997px;
          letter-spacing: 0.09px;
        }
        #t1b_1 {
          left: 203px;
          bottom: 997px;
          letter-spacing: 0.09px;
        }
        #t1c_1 {
          left: 60px;
          bottom: 982px;
          letter-spacing: 0.08px;
          word-spacing: -0.01px;
        }
        #t1d_1 {
          left: 60px;
          bottom: 967px;
          letter-spacing: 0.09px;
          word-spacing: -0.01px;
        }
        #t1e_1 {
          left: 203px;
          bottom: 967px;
          letter-spacing: 0.1px;
        }
        #t1f_1 {
          left: 60px;
          bottom: 952px;
          letter-spacing: 0.08px;
        }
        #t1g_1 {
          left: 203px;
          bottom: 952px;
          letter-spacing: 0.03px;
        }
        #t1h_1 {
          left: 60px;
          bottom: 938px;
          letter-spacing: 0.07px;
        }
        #t1i_1 {
          left: 65px;
          bottom: 1216px;
          letter-spacing: 0.12px;
          word-spacing: -0.02px;
        }
        #t1j_1 {
          left: 433px;
          bottom: 903px;
          letter-spacing: 0.08px;
          word-spacing: -0.01px;
        }
        #t1k_1 {
          left: 433px;
          bottom: 883px;
          letter-spacing: 0.13px;
        }
        #t1l_1 {
          left: 433px;
          bottom: 864px;
          letter-spacing: 0.11px;
          word-spacing: -0.02px;
        }
        #t1m_1 {
          left: 433px;
          bottom: 791px;
          letter-spacing: 0.1px;
        }
        #t1n_1 {
          left: 433px;
          bottom: 754px;
          letter-spacing: 0.09px;
        }
        #t1o_1 {
          left: 498px;
          bottom: 755px;
          letter-spacing: 0.15px;
          word-spacing: -0.02px;
        }
        #t1p_1 {
          left: 433px;
          bottom: 739px;
          letter-spacing: 0.09px;
          word-spacing: -0.01px;
        }
        #t1q_1 {
          left: 433px;
          bottom: 724px;
          letter-spacing: 0.08px;
          word-spacing: -0.01px;
        }
        #t1r_1 {
          left: 433px;
          bottom: 710px;
          letter-spacing: 0.08px;
          word-spacing: -0.01px;
        }
        #t1s_1 {
          left: 433px;
          bottom: 695px;
          letter-spacing: 0.08px;
          word-spacing: -0.01px;
        }
        #t1t_1 {
          left: 433px;
          bottom: 680px;
          letter-spacing: 0.08px;
          word-spacing: -0.01px;
        }
        #t1u_1 {
          left: 433px;
          bottom: 666px;
          letter-spacing: 0.08px;
          word-spacing: -0.01px;
        }
        #t1v_1 {
          left: 65px;
          bottom: 903px;
          letter-spacing: 0.07px;
          word-spacing: -0.01px;
        }
        #t1w_1 {
          left: 65px;
          bottom: 883px;
          letter-spacing: 0.1px;
          word-spacing: -0.02px;
        }
        #t1x_1 {
          left: 736px;
          bottom: 114px;
          letter-spacing: 0.11px;
        }
        #t1y_1 {
          left: 770px;
          bottom: 113px;
          letter-spacing: 0.05px;
          word-spacing: -0.01px;
        }
        #t1z_1 {
          left: 485px;
          bottom: 1082px;
          letter-spacing: 0.07px;
        }
        #t20_1 {
          left: 485px;
          bottom: 1068px;
          letter-spacing: 0.06px;
          word-spacing: -0.01px;
        }
        #t21_1 {
          left: 485px;
          bottom: 1054px;
          letter-spacing: 0.06px;
          word-spacing: -0.01px;
        }
        #t22_1 {
          left: 485px;
          bottom: 1040px;
          letter-spacing: 0.07px;
          word-spacing: -0.01px;
        }
        #t23_1 {
          left: 485px;
          bottom: 1027px;
          letter-spacing: 0.07px;
          word-spacing: -0.01px;
        }
        #t24_1 {
          left: 485px;
          bottom: 974px;
          letter-spacing: 0.14px;
        }
        #t25_1 {
          left: 485px;
          bottom: 955px;
          letter-spacing: 0.13px;
          word-spacing: -0.02px;
        }
        #t26_1 {
          left: 485px;
          bottom: 937px;
          letter-spacing: 0.13px;
          word-spacing: -0.02px;
        }
        #t27_1 {
          left: 65px;
          bottom: 754px;
          letter-spacing: 0.09px;
          word-spacing: -0.01px;
        }
        #t28_1 {
          left: 65px;
          bottom: 739px;
          letter-spacing: 0.09px;
          word-spacing: -0.01px;
        }
        #t29_1 {
          left: 65px;
          bottom: 724px;
          letter-spacing: 0.08px;
        }
        #t2a_1 {
          left: 65px;
          bottom: 710px;
          letter-spacing: 0.08px;
        }
        #t2b_1 {
          left: 65px;
          bottom: 695px;
          letter-spacing: 0.08px;
          word-spacing: -0.01px;
        }
        #t2c_1 {
          left: 65px;
          bottom: 680px;
          letter-spacing: 0.08px;
          word-spacing: -0.01px;
        }
        #t2d_1 {
          left: 65px;
          bottom: 666px;
          letter-spacing: 0.09px;
          word-spacing: -0.01px;
        }
        #t2e_1 {
          left: 65px;
          bottom: 1115px;
          letter-spacing: 0.16px;
          word-spacing: 0.06px;
        }
        #t2f_1 {
          left: 65px;
          bottom: 1074px;
          letter-spacing: 0.12px;
          word-spacing: -0.02px;
        }
        #t2g_1 {
          left: 208px;
          bottom: 1074px;
          letter-spacing: 0.11px;
        }
        #t2h_1 {
          left: 65px;
          bottom: 1059px;
          letter-spacing: 0.12px;
          word-spacing: -0.02px;
        }
        #t2i_1 {
          left: 208px;
          bottom: 1059px;
          letter-spacing: 0.1px;
        }

        .s1 {
          font-size: 15px;
          font-family: sub_Helvetica_lsr;
          color: #000;
        }
        .s2 {
          font-size: 15px;
          font-family: sub_KozMinPro-Regular-Acro_ur;
          color: #000;
        }
        .s3 {
          font-size: 14px;
          font-family: sub_Courier_tr;
          color: #000;
        }
        .s4 {
          font-size: 15px;
          font-family: sub_Helvetica-Bold_lsb;
          color: #000;
        }
        .s5 {
          font-size: 12px;
          font-family: sub_Helvetica-Bold_lsb;
          color: #000;
        }
        .s6 {
          font-size: 10px;
          font-family: sub_Helvetica_lsr;
          color: #000;
        }
        .s7 {
          font-size: 12px;
          font-family: sub_Helvetica_lsr;
          color: #000;
        }
        .s8 {
          font-size: 18px;
          font-family: sub_Helvetica-Bold_lsb;
          color: #000;
        }
        .s9 {
          font-size: 9px;
          font-family: sub_Helvetica_lsr;
          color: #000;
        }
        .sa {
          font-size: 21px;
          font-family: sub_Helvetica-Bold_lsb;
          color: #000;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts1" type="text/css">
        @font-face {
          font-family: sub_Courier_tr;
          src: url("fonts/sub_Courier_tr.woff") format("woff");
        }

        @font-face {
          font-family: sub_Helvetica-Bold_lsb;
          src: url("fonts/sub_Helvetica-Bold_lsb.woff") format("woff");
        }

        @font-face {
          font-family: sub_Helvetica_lsr;
          src: url("fonts/sub_Helvetica_lsr.woff") format("woff");
        }

        @font-face {
          font-family: sub_KozMinPro-Regular-Acro_ur;
          src: url("fonts/sub_KozMinPro-Regular-Acro_ur.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg1Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg1" style="-webkit-user-select: none">
        <object
          width="909"
          height="1286"
          data="1/1.svg"
          type="image/svg+xml"
          id="pdf1"
          style="
            width: 909px;
            height: 1286px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_1" class="t s1"
          >This Purchasing order is subject to the terms and conditions of the
          Frame Contract valid in [2023] between the Buyer and
        </span>
        <span id="t2_1" class="t s1">the Seller. </span>
        <span id="t3_1" class="t s2">�� </span
        ><span id="t4_1" class="t s2">�� </span
        ><span id="t5_1" class="t s2">�� </span
        ><span id="t6_1" class="t s2">�������</span
        ><span id="t7_1" class="t s1">2023</span
        ><span id="t8_1" class="t s2">������ </span
        ><span id="t9_1" class="t s2">�� </span
        ><span id="ta_1" class="t s2">������� </span
        ><span id="tb_1" class="t s2">�� </span>
        <span id="tc_1" class="t s3"
          >_________________________________________________________________________________________
        </span>
        <span id="td_1" class="t s4">Item </span
        ><span id="te_1" class="t s4">Material </span
        ><span id="tf_1" class="t s4">Grp </span
        ><span id="tg_1" class="t s4">Description </span>
        <span id="th_1" class="t s4">Deliv.date </span
        ><span id="ti_1" class="t s4">Order qty. </span
        ><span id="tj_1" class="t s4">Unit </span
        ><span id="tk_1" class="t s4">Price per unit </span
        ><span id="tl_1" class="t s4">Net value] </span>
        <span id="tm_1" class="t s3"
          >_________________________________________________________________________________________
        </span>
        <span id="tn_1" class="t s1">00010 </span
        ><span id="to_1" class="t s1">59350600-001 </span
        ><span id="tp_1" class="t s1">H4 </span
        ><span id="tq_1" class="t s1">Door Motor DDE V35 </span>
        <span id="tr_1" class="t s1">120 </span
        ><span id="ts_1" class="t s1">Piece </span
        ><span id="tt_1" class="t s1">231,90/1 </span
        ><span id="tu_1" class="t s1">27.828,00 </span>
        <span id="tv_1" class="t s1">Document: E41350600+2+000 </span
        ><span id="tw_1" class="t s1">Install. Location: </span>
        <span id="tx_1" class="t s1">Prod./insp. memo: </span
        ><span id="ty_1" class="t s1">Trans Type: </span
        ><span id="tz_1" class="t s1">D/E: </span>
        <span id="t10_1" class="t s1">Product Family: </span>
        <span id="t11_1" class="t s1">SCM Size/dimensions: 167*165*66 </span>
        <span id="t12_1" class="t s3"
          >_________________________________________________________________________________________
        </span>
        <span id="t13_1" class="t s1">Total net value excl. VAT USD </span
        ><span id="t14_1" class="t s1">27.828,00 </span>
        <span id="t15_1" class="t s1"
          >This document was electronically approved.
        </span>
        <span id="t16_1" class="t s5">Contact person: </span
        ><span id="t17_1" class="t s6">Irina Huang(O) </span>
        <span id="t18_1" class="t s5">Tel/Fax: </span
        ><span id="t19_1" class="t s7">021-67095920 / </span>
        <span id="t1a_1" class="t s5">E-mail: </span
        ><span id="t1b_1" class="t s7">irina.huang@schindler.com </span>
        <span id="t1c_1" class="t s5">Contact supplier: </span>
        <span id="t1d_1" class="t s5">Supplier number: </span
        ><span id="t1e_1" class="t s7">1205195 </span>
        <span id="t1f_1" class="t s5">Fax: </span
        ><span id="t1g_1" class="t s7">... </span>
        <span id="t1h_1" class="t s5">Tel: </span>
        <span id="t1i_1" class="t s8"
          >Schindler (China) Elevator Co., Ltd.
        </span>
        <span id="t1j_1" class="t s5">Delivery address: </span>
        <span id="t1k_1" class="t s1">ShangHai </span>
        <span id="t1l_1" class="t s1">No.588 Xing Shun Road,Jiading Di </span>
        <span id="t1m_1" class="t s1">Plant:3260 </span>
        <span id="t1n_1" class="t s5">Incoterms: </span
        ><span id="t1o_1" class="t s6">EXW JDW </span>
        <span id="t1p_1" class="t s5">Payment conditions: </span>
        <span id="t1q_1" class="t s7">Payable within 90 days </span>
        <span id="t1r_1" class="t s5">Our VAT Reg. No.: </span>
        <span id="t1s_1" class="t s5">Acct w/vendor: </span>
        <span id="t1t_1" class="t s5">Delivery date: 13.01.2023 </span>
        <span id="t1u_1" class="t s5">Print Date: 10.01.2023 </span>
        <span id="t1v_1" class="t s5">Invoice to: </span>
        <span id="t1w_1" class="t s1"
          >Schindler (China) Elevator Co.,Ltd.
        </span>
        <span id="t1x_1" class="t s5">Page </span
        ><span id="t1y_1" class="t s1">1 / 1 </span>
        <span id="t1z_1" class="t s9">DocumentNo.:TE-92100-002 </span>
        <span id="t20_1" class="t s9"
          >No.555 Shunxin Road, JiaDing 201800 ,ShangHai ,China
        </span>
        <span id="t21_1" class="t s9">Tele: 021-67095606 </span>
        <span id="t22_1" class="t s9">Material Planning FAX:021-67095606 </span>
        <span id="t23_1" class="t s9">Postcode: 201800 </span>
        <span id="t24_1" class="t s1">Company </span>
        <span id="t25_1" class="t s1">MAXON Switzerland ag </span>
        <span id="t26_1" class="t s1">PO Box 6055 </span>
        <span id="t27_1" class="t s5">Your reference: </span>
        <span id="t28_1" class="t s5">Our reference: </span>
        <span id="t29_1" class="t s5">Recipient: </span>
        <span id="t2a_1" class="t s5">Unloading point: </span>
        <span id="t2b_1" class="t s5">Cost collector: </span>
        <span id="t2c_1" class="t s5">Quotation date: </span>
        <span id="t2d_1" class="t s5">Quotation number: </span>
        <span id="t2e_1" class="t sa">Purchase Order </span>
        <span id="t2f_1" class="t s1">PO number/Group: </span
        ><span id="t2g_1" class="t s4">4536190110/T86 </span>
        <span id="t2h_1" class="t s1">Document Date: </span
        ><span id="t2i_1" class="t s4">10.01.2023 </span>
      </div>
      <!-- End text definitions -->
    </div>
  </body>
</html>

  `,
  111536: `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
    <meta charset="utf-8" />
  </head>

  <body style="margin: 0">
    <div
      id="p1"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 1286px;
        height: 909px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_1 {
          left: 1005px;
          bottom: 829px;
          letter-spacing: -0.27px;
        }
        #t2_1 {
          left: 1005px;
          bottom: 794px;
          letter-spacing: -0.26px;
        }
        #t3_1 {
          left: 1117px;
          bottom: 794px;
          letter-spacing: -0.3px;
        }
        #t4_1 {
          left: 1005px;
          bottom: 684px;
          letter-spacing: -0.25px;
        }
        #t5_1 {
          left: 1117px;
          bottom: 721px;
          letter-spacing: -0.3px;
        }
        #t6_1 {
          left: 1117px;
          bottom: 684px;
          letter-spacing: -0.3px;
        }
        #t7_1 {
          left: 1005px;
          bottom: 721px;
          letter-spacing: -0.27px;
        }
        #t8_1 {
          left: 1018px;
          bottom: 840px;
          letter-spacing: -0.08px;
          word-spacing: 0.01px;
        }
        #t9_1 {
          left: 35px;
          bottom: 546px;
          letter-spacing: 0.17px;
        }
        #ta_1 {
          left: 169px;
          bottom: 546px;
          letter-spacing: 0.19px;
        }
        #tb_1 {
          left: 659px;
          bottom: 546px;
          letter-spacing: 0.2px;
        }
        #tc_1 {
          left: 749px;
          bottom: 546px;
          letter-spacing: 0.19px;
        }
        #td_1 {
          left: 862px;
          bottom: 546px;
          letter-spacing: 0.18px;
        }
        #te_1 {
          left: 955px;
          bottom: 546px;
          letter-spacing: 0.17px;
        }
        #tf_1 {
          left: 1104px;
          bottom: 546px;
          letter-spacing: 0.2px;
        }
        #tg_1 {
          left: 1226px;
          bottom: 546px;
          letter-spacing: 0.2px;
        }
        #th_1 {
          left: 30px;
          bottom: 634px;
          letter-spacing: -0.24px;
        }
        #ti_1 {
          left: 879px;
          bottom: 137px;
          letter-spacing: -0.08px;
        }
        #tj_1 {
          left: 862px;
          bottom: 30px;
          letter-spacing: -0.27px;
        }
        #tk_1 {
          left: 201px;
          bottom: 634px;
          letter-spacing: -0.28px;
        }
        #tl_1 {
          left: 119px;
          bottom: 634px;
          letter-spacing: -0.27px;
        }
        #tm_1 {
          left: 383px;
          bottom: 634px;
          letter-spacing: -0.29px;
        }
        #tn_1 {
          left: 723px;
          bottom: 634px;
          letter-spacing: -0.23px;
        }
        #to_1 {
          left: 30px;
          bottom: 597px;
          letter-spacing: -0.26px;
        }
        #tp_1 {
          left: 539px;
          bottom: 597px;
          letter-spacing: -0.26px;
        }
        #tq_1 {
          left: 862px;
          bottom: 82px;
          letter-spacing: -0.27px;
        }
        #tr_1 {
          left: 1018px;
          bottom: 757px;
          letter-spacing: 0.13px;
        }
        #ts_1 {
          left: 1018px;
          bottom: 746px;
          letter-spacing: 0.12px;
        }
        #tt_1 {
          left: 1018px;
          bottom: 735px;
          letter-spacing: 0.13px;
        }
        #tu_1 {
          left: 582px;
          bottom: 862px;
          letter-spacing: 0.16px;
        }
        #tv_1 {
          left: 35px;
          bottom: 758px;
          letter-spacing: 0.2px;
        }
        #tw_1 {
          left: 582px;
          bottom: 758px;
          letter-spacing: 0.15px;
        }
        #tx_1 {
          left: 35px;
          bottom: 150px;
          letter-spacing: 0.19px;
        }
        #ty_1 {
          left: 1100px;
          bottom: 597px;
          letter-spacing: -0.26px;
        }
        #tz_1 {
          left: 35px;
          bottom: 128px;
          letter-spacing: -0.12px;
        }
        #t10_1 {
          left: 35px;
          bottom: 109px;
          letter-spacing: -0.12px;
        }
        #t11_1 {
          left: 35px;
          bottom: 91px;
          letter-spacing: -0.12px;
        }
        #t12_1 {
          left: 35px;
          bottom: 72px;
          letter-spacing: -0.12px;
        }
        #t13_1 {
          left: 35px;
          bottom: 54px;
          letter-spacing: -0.12px;
        }
        #t14_1 {
          left: 35px;
          bottom: 35px;
          letter-spacing: -0.12px;
        }
        #t15_1 {
          left: 39px;
          bottom: 741px;
          letter-spacing: 0.14px;
        }
        #t16_1 {
          left: 39px;
          bottom: 728px;
          letter-spacing: 0.13px;
        }
        #t17_1 {
          left: 39px;
          bottom: 715px;
          letter-spacing: 0.13px;
        }
        #t18_1 {
          left: 39px;
          bottom: 702px;
          letter-spacing: 0.1px;
        }
        #t19_1 {
          left: 589px;
          bottom: 715px;
          letter-spacing: 0.11px;
        }
        #t1a_1 {
          left: 589px;
          bottom: 702px;
          letter-spacing: 0.11px;
        }
        #t1b_1 {
          left: 589px;
          bottom: 689px;
          letter-spacing: 0.11px;
        }
        #t1c_1 {
          left: 589px;
          bottom: 847px;
          letter-spacing: 0.11px;
        }
        #t1d_1 {
          left: 589px;
          bottom: 808px;
          letter-spacing: 0.11px;
        }
        #t1e_1 {
          left: 1005px;
          bottom: 773px;
        }
        #t1f_1 {
          left: 1005px;
          bottom: 697px;
          letter-spacing: -0.13px;
        }
        #t1g_1 {
          left: 1117px;
          bottom: 697px;
          letter-spacing: -0.14px;
        }
        #t1h_1 {
          left: 1005px;
          bottom: 807px;
          letter-spacing: 0.16px;
        }
        #t1i_1 {
          left: 732px;
          bottom: 610px;
          letter-spacing: -0.13px;
        }
        #t1j_1 {
          left: 204px;
          bottom: 610px;
          letter-spacing: -0.14px;
        }
        #t1k_1 {
          left: 119px;
          bottom: 610px;
          letter-spacing: -0.14px;
        }
        #t1l_1 {
          left: 30px;
          bottom: 574px;
          letter-spacing: -0.16px;
        }
        #t1m_1 {
          left: 1096px;
          bottom: 574px;
          letter-spacing: -0.16px;
        }
        #t1n_1 {
          left: 1124px;
          bottom: 137px;
          letter-spacing: 0.11px;
        }
        #t1o_1 {
          left: 1139px;
          bottom: 773px;
          letter-spacing: -0.11px;
        }
        #t1p_1 {
          left: 440px;
          bottom: 591px;
          letter-spacing: -0.11px;
        }
        #t1q_1 {
          left: 336px;
          bottom: 574px;
          letter-spacing: -0.13px;
        }
        #t1r_1 {
          left: 589px;
          bottom: 741px;
          letter-spacing: 0.11px;
        }
        #t1s_1 {
          left: 385px;
          bottom: 610px;
          letter-spacing: -0.13px;
        }
        #t1t_1 {
          left: 589px;
          bottom: 821px;
          letter-spacing: 0.11px;
        }
        #t1u_1 {
          left: 589px;
          bottom: 834px;
          letter-spacing: 0.11px;
        }
        #t1v_1 {
          left: 589px;
          bottom: 728px;
          letter-spacing: 0.11px;
        }
        #t1w_1 {
          left: 1256.1px;
          bottom: 162.2px;
          letter-spacing: 0.08px;
        }
        #t1x_1 {
          left: 76px;
          bottom: 509px;
          letter-spacing: -0.12px;
        }
        #t1y_1 {
          left: 35px;
          bottom: 484px;
        }
        #t1z_1 {
          left: 76px;
          bottom: 484px;
          letter-spacing: -0.16px;
        }
        #t20_1 {
          left: 803px;
          bottom: 484px;
          letter-spacing: -0.14px;
        }
        #t21_1 {
          left: 831px;
          bottom: 484px;
          letter-spacing: -0.17px;
        }
        #t22_1 {
          left: 1012px;
          bottom: 484px;
          letter-spacing: -0.13px;
        }
        #t23_1 {
          left: 1161px;
          bottom: 484px;
          letter-spacing: -0.12px;
        }
        #t24_1 {
          left: 1234px;
          bottom: 484px;
        }
        #t25_1 {
          left: 648px;
          bottom: 484px;
          letter-spacing: -0.13px;
        }
        #t26_1 {
          left: 654px;
          bottom: 515px;
          letter-spacing: -0.16px;
        }
        #t27_1 {
          left: 654px;
          bottom: 503px;
          letter-spacing: -0.17px;
        }
        #t28_1 {
          left: 76px;
          bottom: 467px;
          letter-spacing: -0.09px;
        }
        #t29_1 {
          left: 119px;
          bottom: 467px;
          letter-spacing: -0.16px;
        }
        #t2a_1 {
          left: 327px;
          bottom: 467px;
          letter-spacing: -0.13px;
        }
        #t2b_1 {
          left: 76px;
          bottom: 451px;
          letter-spacing: -0.13px;
        }
        #t2c_1 {
          left: 129px;
          bottom: 451px;
          letter-spacing: -0.12px;
        }
        #t2d_1 {
          left: 297px;
          bottom: 451px;
          letter-spacing: -0.11px;
        }
        #t2e_1 {
          left: 76px;
          bottom: 435px;
          letter-spacing: -0.13px;
        }
        #t2f_1 {
          left: 76px;
          bottom: 405px;
          letter-spacing: -0.13px;
        }
        #t2g_1 {
          left: 210px;
          bottom: 405px;
          letter-spacing: -0.14px;
        }
        #t2h_1 {
          left: 648px;
          bottom: 388px;
          letter-spacing: -0.13px;
        }
        #t2i_1 {
          left: 651px;
          bottom: 420px;
          letter-spacing: -0.16px;
        }
        #t2j_1 {
          left: 654px;
          bottom: 407px;
          letter-spacing: -0.17px;
        }
        #t2k_1 {
          left: 76px;
          bottom: 372px;
          letter-spacing: -0.13px;
        }
        #t2l_1 {
          left: 245px;
          bottom: 372px;
          letter-spacing: -0.18px;
        }
        #t2m_1 {
          left: 1196px;
          bottom: 150px;
          letter-spacing: -0.12px;
        }

        .s1_1 {
          font-size: 10px;
          font-family: sub_ArialMT_lsr;
          color: #000;
        }
        .s2_1 {
          font-size: 22px;
          font-family: sub_Arial-BoldItalicMT_lsbi;
          color: #fff;
        }
        .s3_1 {
          font-size: 12px;
          font-family: sub_ArialMT_lsr;
          color: #fff;
        }
        .s4_1 {
          font-size: 22px;
          font-family: sub_Arial-BoldMT_lsb;
          color: #fff;
        }
        .s5_1 {
          font-size: 8px;
          font-family: sub_ArialMT_lsr;
          color: #fff;
        }
        .s6_1 {
          font-size: 12px;
          font-family: sub_Arial-BoldMT_lsb;
          color: #000;
        }
        .s7_1 {
          font-size: 14px;
          font-family: sub_Arial-BoldMT_lsb;
          color: #000;
        }
        .s8_1 {
          font-size: 12px;
          font-family: sub_ArialMT_lsr;
          color: #000;
        }
        .s9_1 {
          font-size: 14px;
          font-family: sub_ArialMT_lsr;
          color: #000;
        }
        .sa_1 {
          font-size: 15px;
          font-family: sub_Arial-BoldMT_lsb;
          color: #000;
        }
        .sb_1 {
          font-size: 9px;
          font-family: sub_ArialMT_lsr;
          color: #000;
        }
        .sc_1 {
          font-size: 11px;
          font-family: sub_Arial-BoldMT_lsb;
          color: #000;
        }
        .t.m0_1 {
          transform: matrix(0, 1, -1, 0, 0, 0);
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts1" type="text/css">
        @font-face {
          font-family: sub_Arial-BoldItalicMT_lsbi;
          src: url("fonts/sub_Arial-BoldItalicMT_lsbi.woff") format("woff");
        }

        @font-face {
          font-family: sub_Arial-BoldMT_lsb;
          src: url("fonts/sub_Arial-BoldMT_lsb.woff") format("woff");
        }

        @font-face {
          font-family: sub_ArialMT_lsr;
          src: url("fonts/sub_ArialMT_lsr.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg1Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg1" style="-webkit-user-select: none">
        <object
          width="1286"
          height="909"
          data="1/1.svg"
          type="image/svg+xml"
          id="pdf1"
          style="
            width: 1286px;
            height: 909px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_1" class="t s1_1">PURCHASE ORDER NO. </span>
        <span id="t2_1" class="t s1_1">REVISION </span
        ><span id="t3_1" class="t s1_1">PAGE </span>
        <span id="t4_1" class="t s1_1">DATE OF REVISION </span>
        <span id="t5_1" class="t s1_1">BUYER </span>
        <span id="t6_1" class="t s1_1">BUYER </span>
        <span id="t7_1" class="t s1_1">DATE OF ORDER </span>
        <span id="t8_1" class="t s2_1">PURCHASE ORDER </span>
        <span id="t9_1" class="t s3_1">LINE </span
        ><span id="ta_1" class="t s3_1">PART NUMBER/DESCRIPTION </span
        ><span id="tb_1" class="t s3_1">DATES </span
        ><span id="tc_1" class="t s3_1">QUANTITY </span
        ><span id="td_1" class="t s3_1">UNIT </span
        ><span id="te_1" class="t s3_1">UNIT PRICE </span
        ><span id="tf_1" class="t s3_1">EXTENSION </span
        ><span id="tg_1" class="t s3_1">TAX </span>
        <span id="th_1" class="t s1_1">CUST. ACCT. NO. </span>
        <span id="ti_1" class="t s4_1">TOTAL </span>
        <span id="tj_1" class="t s1_1">AUTHORIZED SIGNATURE </span>
        <span id="tk_1" class="t s1_1">PAYMENT TERMS </span
        ><span id="tl_1" class="t s1_1">VENDOR NO. </span
        ><span id="tm_1" class="t s1_1">INCOTERM </span
        ><span id="tn_1" class="t s1_1">SHIP VIA </span>
        <span id="to_1" class="t s1_1">CONFIRM TO/ TELEPHONE </span
        ><span id="tp_1" class="t s1_1">REQUESTER/ DELIVER TO </span>
        <span id="tq_1" class="t s1_1">AUTHORIZED SIGNATURE </span>
        <span id="tr_1" class="t s5_1"
          >THIS PURCHASE ORDER NUMBER MUST APPEAR ON
        </span>
        <span id="ts_1" class="t s5_1"
          >ALL INVOICES, PACKING LISTS, CARTONS AND
        </span>
        <span id="tt_1" class="t s5_1"
          >CORRESPONDENCE RELATED TO THIS ORDER.
        </span>
        <span id="tu_1" class="t s3_1">SHIP TO: </span>
        <span id="tv_1" class="t s3_1">VENDOR: </span
        ><span id="tw_1" class="t s3_1">BILL TO: </span>
        <span id="tx_1" class="t s6_1"
          >PURCHASE ORDER AND SANMINA PART # MUST APPEAR ON ALL PACKAGES
        </span>
        <span id="ty_1" class="t s1_1">SERVICE LEVEL </span>
        <span id="tz_1" class="t s7_1"
          >Your acceptance of this purchase order, via performance, the sending
          of an acknowledgment or otherwise, is
        </span>
        <span id="t10_1" class="t s7_1"
          >conditioned on your acceptance of the terms and conditions set forth
          on both sides of this order, together with all
        </span>
        <span id="t11_1" class="t s7_1"
          >attachments and exhibits attached hereto and all specifications,
          drawings, notes, instructions, and other written
        </span>
        <span id="t12_1" class="t s7_1"
          >materials and information referred to therein (the "Terms"). If you
          do not receive or cannot read these Terms, or have
        </span>
        <span id="t13_1" class="t s7_1"
          >any questions regarding these Terms, please call Buyer's legal
          department at (408) 964-3500 or view them on Sanmina's
        </span>
        <span id="t14_1" class="t s7_1"
          >website at
          http://www.sanmina.com/partners-access/supplier-requirements/index.php.
        </span>
        <span id="t15_1" class="t s8_1">MAXON SWITZERLAND AG </span>
        <span id="t16_1" class="t s8_1">INDUSTRIESTRASSE 23 </span>
        <span id="t17_1" class="t s8_1">ALPNACH, 6055 </span>
        <span id="t18_1" class="t s8_1">Switzerland </span>
        <span id="t19_1" class="t s8_1"
          >312 Qing Yang South Rd, Economic and Tech Dev. Zone, K
        </span>
        <span id="t1a_1" class="t s8_1">Jiangsu, 215300 </span>
        <span id="t1b_1" class="t s8_1">China </span>
        <span id="t1c_1" class="t s8_1">312, Qing Yang South Road </span>
        <span id="t1d_1" class="t s8_1">China </span>
        <span id="t1e_1" class="t s9_1">0 </span>
        <span id="t1f_1" class="t s9_1">11-JAN-23 </span
        ><span id="t1g_1" class="t s9_1">M,Zhang </span>
        <span id="t1h_1" class="t sa_1">ME85E1550079 </span>
        <span id="t1i_1" class="t s9_1">PO BAX - [AIR]-[STANDARD] </span
        ><span id="t1j_1" class="t s9_1">NET 30 </span
        ><span id="t1k_1" class="t s9_1">105088 </span>
        <span id="t1l_1" class="t s9_1">ROBIN CAMENZIND </span
        ><span id="t1m_1" class="t s9_1">STD+ </span>
        <span id="t1n_1" class="t s8_1">Currency: Swiss Franc </span>
        <span id="t1o_1" class="t s9_1">1(1) </span>
        <span id="t1p_1" class="t s9_1">Vendor Fax: () </span>
        <span id="t1q_1" class="t s9_1">Vendor Phone: (4141) 6661865 </span>
        <span id="t1r_1" class="t s8_1"
          >Sanmina-SCI Systems (Kunshan) Co Ltd
        </span>
        <span id="t1s_1" class="t s9_1">FCA </span>
        <span id="t1t_1" class="t s8_1">Jiangsu Sheng, 215300 </span>
        <span id="t1u_1" class="t s8_1"
          >Economic and Technical Development Zone, Kunshan
        </span>
        <span id="t1v_1" class="t s8_1"
          >c/o Sanmina-SCI Shared Services -North China
        </span>
        <span id="t1w_1" class="t m0_1 sb_1"
          >[Form:4bb:5.6.2:E85:273729515]
        </span>
        <span id="t1x_1" class="t s7_1"
          >All prices and amounts on this order are expressed in: Swiss Franc
        </span>
        <span id="t1y_1" class="t s9_1">1 </span
        ><span id="t1z_1" class="t s9_1">LFMDROC07729910001A </span
        ><span id="t20_1" class="t s9_1">60 </span
        ><span id="t21_1" class="t s9_1">EACH </span
        ><span id="t22_1" class="t s9_1">132.79 </span
        ><span id="t23_1" class="t s9_1">7,967.40 </span
        ><span id="t24_1" class="t s9_1">N </span
        ><span id="t25_1" class="t s9_1">07-JUL-23 </span>
        <span id="t26_1" class="t sc_1">Promised </span>
        <span id="t27_1" class="t sc_1">Dock Date </span>
        <span id="t28_1" class="t s9_1">Mfr#: </span
        ><span id="t29_1" class="t s9_1">MAXON MOTOR </span
        ><span id="t2a_1" class="t s9_1">Customer Rev: A </span>
        <span id="t2b_1" class="t s9_1">MPN#: </span
        ><span id="t2c_1" class="t s9_1">515785 </span
        ><span id="t2d_1" class="t s9_1">Internal Rev: 000 </span>
        <span id="t2e_1" class="t s9_1"
          >DC-Motor,16mm,2W,21V,104:1,256inc,kon
        </span>
        <span id="t2f_1" class="t s9_1">RoHS Requirement: </span
        ><span id="t2g_1" class="t s9_1">NO RoHS Requirements </span>
        <span id="t2h_1" class="t s9_1">07-JUL-23 </span>
        <span id="t2i_1" class="t sc_1">Requested </span>
        <span id="t2j_1" class="t sc_1">Dock Date </span>
        <span id="t2k_1" class="t s9_1">MPN RoHS Compliancy: </span
        ><span id="t2l_1" class="t s9_1">RX </span>
        <span id="t2m_1" class="t s9_1">7,967.40 </span>
      </div>
      <!-- End text definitions -->
    </div>

    <div
      id="p2"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 1286px;
        height: 909px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_2 {
          left: 35px;
          bottom: 857px;
          letter-spacing: -0.09px;
        }
        #t2_2 {
          left: 51px;
          bottom: 857px;
          letter-spacing: -0.21px;
        }
        #t3_2 {
          left: 103px;
          bottom: 857px;
          letter-spacing: -0.14px;
          word-spacing: 0.82px;
        }
        #t4_2 {
          left: 35px;
          bottom: 845px;
          letter-spacing: -0.1px;
        }
        #t5_2 {
          left: 59px;
          bottom: 845px;
          letter-spacing: -0.14px;
          word-spacing: 0.98px;
        }
        #t6_2 {
          left: 35px;
          bottom: 834px;
          letter-spacing: -0.14px;
          word-spacing: 2.38px;
        }
        #t7_2 {
          left: 35px;
          bottom: 822px;
          letter-spacing: -0.14px;
          word-spacing: 1px;
        }
        #t8_2 {
          left: 136px;
          bottom: 822px;
          letter-spacing: -0.14px;
        }
        #t9_2 {
          left: 491px;
          bottom: 822px;
          letter-spacing: -0.12px;
          word-spacing: 0.99px;
        }
        #ta_2 {
          left: 35px;
          bottom: 811px;
          letter-spacing: -0.13px;
          word-spacing: 0.4px;
        }
        #tb_2 {
          left: 35px;
          bottom: 799px;
          letter-spacing: -0.14px;
          word-spacing: 1px;
        }
        #tc_2 {
          left: 327px;
          bottom: 799px;
          letter-spacing: -0.14px;
          word-spacing: 1px;
        }
        #td_2 {
          left: 35px;
          bottom: 788px;
          letter-spacing: -0.14px;
          word-spacing: 0.79px;
        }
        #te_2 {
          left: 35px;
          bottom: 776px;
          letter-spacing: -0.14px;
          word-spacing: 2.23px;
        }
        #tf_2 {
          left: 35px;
          bottom: 765px;
          letter-spacing: -0.14px;
          word-spacing: 1.06px;
        }
        #tg_2 {
          left: 35px;
          bottom: 753px;
          letter-spacing: -0.11px;
        }
        #th_2 {
          left: 75px;
          bottom: 753px;
          letter-spacing: -0.13px;
          word-spacing: 0.38px;
        }
        #ti_2 {
          left: 35px;
          bottom: 742px;
          letter-spacing: -0.15px;
          word-spacing: 2.08px;
        }
        #tj_2 {
          left: 35px;
          bottom: 730px;
          letter-spacing: -0.14px;
          word-spacing: 0.84px;
        }
        #tk_2 {
          left: 284px;
          bottom: 730px;
          letter-spacing: -0.14px;
          word-spacing: 0.84px;
        }
        #tl_2 {
          left: 35px;
          bottom: 719px;
          letter-spacing: -0.14px;
          word-spacing: 0.62px;
        }
        #tm_2 {
          left: 35px;
          bottom: 707px;
          letter-spacing: -0.14px;
          word-spacing: 0.38px;
        }
        #tn_2 {
          left: 108px;
          bottom: 707px;
          letter-spacing: -0.14px;
          word-spacing: 0.38px;
        }
        #to_2 {
          left: 35px;
          bottom: 696px;
          letter-spacing: -0.14px;
          word-spacing: 0.4px;
        }
        #tp_2 {
          left: 333px;
          bottom: 696px;
          letter-spacing: -0.14px;
          word-spacing: 0.4px;
        }
        #tq_2 {
          left: 35px;
          bottom: 684px;
          letter-spacing: -0.13px;
        }
        #tr_2 {
          left: 35px;
          bottom: 673px;
          letter-spacing: -0.09px;
        }
        #ts_2 {
          left: 50px;
          bottom: 673px;
          letter-spacing: -0.18px;
          word-spacing: 0.35px;
        }
        #tt_2 {
          left: 335px;
          bottom: 673px;
        }
        #tu_2 {
          left: 345px;
          bottom: 673px;
          letter-spacing: -0.13px;
          word-spacing: 0.36px;
        }
        #tv_2 {
          left: 35px;
          bottom: 662px;
          letter-spacing: -0.13px;
          word-spacing: 0.65px;
        }
        #tw_2 {
          left: 35px;
          bottom: 650px;
          letter-spacing: -0.13px;
          word-spacing: 0.31px;
        }
        #tx_2 {
          left: 35px;
          bottom: 639px;
          letter-spacing: -0.14px;
          word-spacing: 0.59px;
        }
        #ty_2 {
          left: 35px;
          bottom: 627px;
          letter-spacing: -0.13px;
          word-spacing: 0.54px;
        }
        #tz_2 {
          left: 317px;
          bottom: 627px;
          letter-spacing: -0.14px;
          word-spacing: 0.54px;
        }
        #t10_2 {
          left: 35px;
          bottom: 616px;
          letter-spacing: -0.13px;
          word-spacing: 0.22px;
        }
        #t11_2 {
          left: 35px;
          bottom: 604px;
          letter-spacing: -0.14px;
          word-spacing: 1.02px;
        }
        #t12_2 {
          left: 416px;
          bottom: 604px;
          letter-spacing: -0.14px;
          word-spacing: 1.02px;
        }
        #t13_2 {
          left: 35px;
          bottom: 593px;
          letter-spacing: -0.13px;
          word-spacing: 0.67px;
        }
        #t14_2 {
          left: 35px;
          bottom: 581px;
          letter-spacing: -0.14px;
          word-spacing: 2.55px;
        }
        #t15_2 {
          left: 35px;
          bottom: 570px;
          letter-spacing: -0.13px;
          word-spacing: 1.22px;
        }
        #t16_2 {
          left: 35px;
          bottom: 558px;
          letter-spacing: -0.14px;
          word-spacing: 1.36px;
        }
        #t17_2 {
          left: 382px;
          bottom: 558px;
          letter-spacing: -0.14px;
          word-spacing: 1.36px;
        }
        #t18_2 {
          left: 35px;
          bottom: 547px;
          letter-spacing: -0.14px;
          word-spacing: 1.71px;
        }
        #t19_2 {
          left: 379px;
          bottom: 547px;
          letter-spacing: -0.13px;
          word-spacing: 1.7px;
        }
        #t1a_2 {
          left: 35px;
          bottom: 535px;
          letter-spacing: -0.13px;
          word-spacing: 0.51px;
        }
        #t1b_2 {
          left: 486px;
          bottom: 535px;
          letter-spacing: -0.14px;
          word-spacing: 0.51px;
        }
        #t1c_2 {
          left: 35px;
          bottom: 524px;
          letter-spacing: -0.14px;
          word-spacing: 1.93px;
        }
        #t1d_2 {
          left: 35px;
          bottom: 512px;
          letter-spacing: -0.14px;
        }
        #t1e_2 {
          left: 87px;
          bottom: 512px;
          letter-spacing: -0.14px;
          word-spacing: 1.19px;
        }
        #t1f_2 {
          left: 35px;
          bottom: 501px;
          letter-spacing: -0.14px;
        }
        #t1g_2 {
          left: 74px;
          bottom: 501px;
          letter-spacing: -0.13px;
          word-spacing: 0.22px;
        }
        #t1h_2 {
          left: 35px;
          bottom: 489px;
          letter-spacing: -0.13px;
          word-spacing: 1px;
        }
        #t1i_2 {
          left: 419px;
          bottom: 489px;
          letter-spacing: -0.14px;
          word-spacing: 1px;
        }
        #t1j_2 {
          left: 35px;
          bottom: 478px;
          letter-spacing: -0.13px;
          word-spacing: 0.9px;
        }
        #t1k_2 {
          left: 35px;
          bottom: 467px;
          letter-spacing: -0.13px;
          word-spacing: 0.45px;
        }
        #t1l_2 {
          left: 331px;
          bottom: 467px;
          letter-spacing: -0.13px;
          word-spacing: 0.45px;
        }
        #t1m_2 {
          left: 35px;
          bottom: 455px;
          letter-spacing: -0.13px;
          word-spacing: 0.55px;
        }
        #t1n_2 {
          left: 35px;
          bottom: 444px;
          letter-spacing: -0.14px;
          word-spacing: 0.66px;
        }
        #t1o_2 {
          left: 35px;
          bottom: 432px;
          letter-spacing: -0.13px;
        }
        #t1p_2 {
          left: 35px;
          bottom: 421px;
          letter-spacing: -0.09px;
        }
        #t1q_2 {
          left: 50px;
          bottom: 421px;
          letter-spacing: -0.18px;
        }
        #t1r_2 {
          left: 495px;
          bottom: 421px;
        }
        #t1s_2 {
          left: 35px;
          bottom: 409px;
          letter-spacing: -0.09px;
        }
        #t1t_2 {
          left: 56px;
          bottom: 409px;
        }
        #t1u_2 {
          left: 63px;
          bottom: 410px;
        }
        #t1v_2 {
          left: 65px;
          bottom: 410px;
        }
        #t1w_2 {
          left: 72px;
          bottom: 410px;
        }
        #t1x_2 {
          left: 81px;
          bottom: 410px;
        }
        #t1y_2 {
          left: 83px;
          bottom: 410px;
        }
        #t1z_2 {
          left: 92px;
          bottom: 410px;
        }
        #t20_2 {
          left: 98px;
          bottom: 410px;
        }
        #t21_2 {
          left: 107px;
          bottom: 410px;
        }
        #t22_2 {
          left: 112px;
          bottom: 410px;
        }
        #t23_2 {
          left: 118px;
          bottom: 410px;
        }
        #t24_2 {
          left: 127px;
          bottom: 410px;
        }
        #t25_2 {
          left: 132px;
          bottom: 410px;
        }
        #t26_2 {
          left: 138px;
          bottom: 410px;
        }
        #t27_2 {
          left: 144px;
          bottom: 410px;
        }
        #t28_2 {
          left: 150px;
          bottom: 410px;
        }
        #t29_2 {
          left: 156px;
          bottom: 410px;
        }
        #t2a_2 {
          left: 162px;
          bottom: 410px;
        }
        #t2b_2 {
          left: 171px;
          bottom: 410px;
        }
        #t2c_2 {
          left: 177px;
          bottom: 410px;
        }
        #t2d_2 {
          left: 185px;
          bottom: 410px;
        }
        #t2e_2 {
          left: 191px;
          bottom: 410px;
        }
        #t2f_2 {
          left: 197px;
          bottom: 410px;
        }
        #t2g_2 {
          left: 199px;
          bottom: 410px;
        }
        #t2h_2 {
          left: 208px;
          bottom: 409px;
        }
        #t2i_2 {
          left: 216px;
          bottom: 410px;
        }
        #t2j_2 {
          left: 222px;
          bottom: 410px;
        }
        #t2k_2 {
          left: 229px;
          bottom: 410px;
        }
        #t2l_2 {
          left: 234px;
          bottom: 410px;
        }
        #t2m_2 {
          left: 249px;
          bottom: 409px;
        }
        #t2n_2 {
          left: 272px;
          bottom: 409px;
          letter-spacing: -0.14px;
          word-spacing: 1.16px;
        }
        #t2o_2 {
          left: 35px;
          bottom: 398px;
          letter-spacing: -0.14px;
          word-spacing: 1.43px;
        }
        #t2p_2 {
          left: 309px;
          bottom: 398px;
          letter-spacing: -0.13px;
          word-spacing: 1.42px;
        }
        #t2q_2 {
          left: 35px;
          bottom: 386px;
          letter-spacing: -0.13px;
          word-spacing: 0.31px;
        }
        #t2r_2 {
          left: 35px;
          bottom: 375px;
          letter-spacing: -0.13px;
          word-spacing: 0.74px;
        }
        #t2s_2 {
          left: 354px;
          bottom: 375px;
          letter-spacing: -0.14px;
          word-spacing: 0.74px;
        }
        #t2t_2 {
          left: 35px;
          bottom: 363px;
          letter-spacing: -0.13px;
          word-spacing: 0.13px;
        }
        #t2u_2 {
          left: 215px;
          bottom: 363px;
          letter-spacing: -0.13px;
          word-spacing: 0.13px;
        }
        #t2v_2 {
          left: 35px;
          bottom: 352px;
          letter-spacing: -0.13px;
          word-spacing: 0.84px;
        }
        #t2w_2 {
          left: 35px;
          bottom: 340px;
          letter-spacing: -0.14px;
          word-spacing: 1.41px;
        }
        #t2x_2 {
          left: 35px;
          bottom: 329px;
          letter-spacing: -0.14px;
          word-spacing: 1.76px;
        }
        #t2y_2 {
          left: 35px;
          bottom: 317px;
          letter-spacing: -0.14px;
          word-spacing: 1.45px;
        }
        #t2z_2 {
          left: 35px;
          bottom: 306px;
          letter-spacing: -0.11px;
        }
        #t30_2 {
          left: 71px;
          bottom: 306px;
          letter-spacing: -0.13px;
          word-spacing: 0.14px;
        }
        #t31_2 {
          left: 35px;
          bottom: 294px;
          letter-spacing: -0.14px;
          word-spacing: 0.2px;
        }
        #t32_2 {
          left: 35px;
          bottom: 283px;
          letter-spacing: -0.13px;
        }
        #t33_2 {
          left: 35px;
          bottom: 272px;
          letter-spacing: -0.09px;
        }
        #t34_2 {
          left: 56px;
          bottom: 272px;
          letter-spacing: -0.14px;
          word-spacing: 0.94px;
        }
        #t35_2 {
          left: 35px;
          bottom: 260px;
          letter-spacing: -0.14px;
          word-spacing: 1.75px;
        }
        #t36_2 {
          left: 130px;
          bottom: 260px;
          letter-spacing: -0.14px;
          word-spacing: 1.75px;
        }
        #t37_2 {
          left: 35px;
          bottom: 249px;
          letter-spacing: -0.14px;
          word-spacing: 2.46px;
        }
        #t38_2 {
          left: 529px;
          bottom: 249px;
          letter-spacing: -0.13px;
          word-spacing: 2.46px;
        }
        #t39_2 {
          left: 35px;
          bottom: 237px;
          letter-spacing: -0.14px;
          word-spacing: 1.25px;
        }
        #t3a_2 {
          left: 35px;
          bottom: 226px;
          letter-spacing: -0.13px;
        }
        #t3b_2 {
          left: 35px;
          bottom: 214px;
          letter-spacing: -0.09px;
        }
        #t3c_2 {
          left: 56px;
          bottom: 214px;
          letter-spacing: -0.14px;
          word-spacing: 1.32px;
        }
        #t3d_2 {
          left: 35px;
          bottom: 203px;
          letter-spacing: -0.14px;
          word-spacing: 1.76px;
        }
        #t3e_2 {
          left: 35px;
          bottom: 191px;
          letter-spacing: -0.14px;
          word-spacing: 1.3px;
        }
        #t3f_2 {
          left: 35px;
          bottom: 180px;
          letter-spacing: -0.14px;
          word-spacing: 0.95px;
        }
        #t3g_2 {
          left: 35px;
          bottom: 168px;
          letter-spacing: -0.14px;
          word-spacing: 1.11px;
        }
        #t3h_2 {
          left: 35px;
          bottom: 157px;
          letter-spacing: -0.15px;
          word-spacing: 1.44px;
        }
        #t3i_2 {
          left: 35px;
          bottom: 145px;
          letter-spacing: -0.14px;
          word-spacing: 0.89px;
        }
        #t3j_2 {
          left: 35px;
          bottom: 134px;
          letter-spacing: -0.14px;
          word-spacing: 0.62px;
        }
        #t3k_2 {
          left: 35px;
          bottom: 122px;
          letter-spacing: -0.13px;
        }
        #t3l_2 {
          left: 35px;
          bottom: 111px;
          letter-spacing: -0.09px;
        }
        #t3m_2 {
          left: 54px;
          bottom: 111px;
          letter-spacing: -0.13px;
        }
        #t3n_2 {
          left: 35px;
          bottom: 99px;
          letter-spacing: -0.09px;
        }
        #t3o_2 {
          left: 54px;
          bottom: 99px;
          letter-spacing: -0.13px;
          word-spacing: 0.21px;
        }
        #t3p_2 {
          left: 35px;
          bottom: 88px;
          letter-spacing: -0.14px;
        }
        #t3q_2 {
          left: 35px;
          bottom: 77px;
          letter-spacing: -0.09px;
        }
        #t3r_2 {
          left: 50px;
          bottom: 77px;
          letter-spacing: -0.22px;
        }
        #t3s_2 {
          left: 112px;
          bottom: 77px;
        }
        #t3t_2 {
          left: 35px;
          bottom: 65px;
          letter-spacing: -0.09px;
        }
        #t3u_2 {
          left: 55px;
          bottom: 65px;
          letter-spacing: -0.14px;
          word-spacing: 0.83px;
        }
        #t3v_2 {
          left: 35px;
          bottom: 54px;
          letter-spacing: -0.13px;
        }
        #t3w_2 {
          left: 35px;
          bottom: 42px;
          letter-spacing: -0.14px;
        }
        #t3x_2 {
          left: 632px;
          bottom: 857px;
          letter-spacing: -0.14px;
          word-spacing: 2.3px;
        }
        #t3y_2 {
          left: 1045px;
          bottom: 857px;
          letter-spacing: -0.15px;
          word-spacing: 2.3px;
        }
        #t3z_2 {
          left: 632px;
          bottom: 845px;
          letter-spacing: -0.17px;
        }
        #t40_2 {
          left: 754px;
          bottom: 845px;
          letter-spacing: -0.11px;
        }
        #t41_2 {
          left: 789px;
          bottom: 846px;
          letter-spacing: 0.08px;
        }
        #t42_2 {
          left: 1178px;
          bottom: 845px;
        }
        #t43_2 {
          left: 1182px;
          bottom: 845px;
          letter-spacing: -0.12px;
          word-spacing: 5.4px;
        }
        #t44_2 {
          left: 632px;
          bottom: 833px;
          letter-spacing: -0.14px;
          word-spacing: 2.59px;
        }
        #t45_2 {
          left: 632px;
          bottom: 822px;
          letter-spacing: -0.15px;
          word-spacing: 3.35px;
        }
        #t46_2 {
          left: 750px;
          bottom: 822px;
          letter-spacing: -0.15px;
          word-spacing: 3.35px;
        }
        #t47_2 {
          left: 632px;
          bottom: 810px;
          letter-spacing: -0.13px;
          word-spacing: 0.37px;
        }
        #t48_2 {
          left: 632px;
          bottom: 799px;
          letter-spacing: -0.14px;
          word-spacing: 1.36px;
        }
        #t49_2 {
          left: 1173px;
          bottom: 799px;
          letter-spacing: -0.14px;
        }
        #t4a_2 {
          left: 632px;
          bottom: 787px;
          letter-spacing: -0.13px;
        }
        #t4b_2 {
          left: 632px;
          bottom: 775px;
          letter-spacing: -0.09px;
        }
        #t4c_2 {
          left: 653px;
          bottom: 775px;
          letter-spacing: -0.13px;
          word-spacing: 0.82px;
        }
        #t4d_2 {
          left: 632px;
          bottom: 764px;
          letter-spacing: -0.14px;
          word-spacing: 1.11px;
        }
        #t4e_2 {
          left: 632px;
          bottom: 752px;
          letter-spacing: -0.14px;
          word-spacing: 0.7px;
        }
        #t4f_2 {
          left: 632px;
          bottom: 741px;
          letter-spacing: -0.16px;
          word-spacing: 2.83px;
        }
        #t4g_2 {
          left: 1082px;
          bottom: 741px;
        }
        #t4h_2 {
          left: 1086px;
          bottom: 741px;
          letter-spacing: -0.21px;
        }
        #t4i_2 {
          left: 1123px;
          bottom: 741px;
        }
        #t4j_2 {
          left: 1132px;
          bottom: 741px;
          letter-spacing: -0.16px;
          word-spacing: 2.83px;
        }
        #t4k_2 {
          left: 632px;
          bottom: 729px;
          letter-spacing: -0.14px;
          word-spacing: 1.36px;
        }
        #t4l_2 {
          left: 1043px;
          bottom: 729px;
        }
        #t4m_2 {
          left: 1049px;
          bottom: 729px;
          letter-spacing: -0.15px;
          word-spacing: 1.36px;
        }
        #t4n_2 {
          left: 1151px;
          bottom: 729px;
          letter-spacing: -0.13px;
          word-spacing: 1.36px;
        }
        #t4o_2 {
          left: 632px;
          bottom: 717px;
          letter-spacing: -0.16px;
          word-spacing: 2.76px;
        }
        #t4p_2 {
          left: 632px;
          bottom: 706px;
          letter-spacing: -0.13px;
          word-spacing: 0.59px;
        }
        #t4q_2 {
          left: 632px;
          bottom: 694px;
          letter-spacing: -0.14px;
          word-spacing: 0.76px;
        }
        #t4r_2 {
          left: 632px;
          bottom: 683px;
          letter-spacing: -0.13px;
        }
        #t4s_2 {
          left: 632px;
          bottom: 671px;
          letter-spacing: -0.09px;
        }
        #t4t_2 {
          left: 651px;
          bottom: 671px;
          letter-spacing: -0.13px;
          word-spacing: 0.13px;
        }
        #t4u_2 {
          left: 632px;
          bottom: 660px;
          letter-spacing: -0.13px;
          word-spacing: 1.09px;
        }
        #t4v_2 {
          left: 632px;
          bottom: 648px;
          letter-spacing: -0.13px;
          word-spacing: 0.45px;
        }
        #t4w_2 {
          left: 632px;
          bottom: 636px;
          letter-spacing: -0.13px;
          word-spacing: 1.04px;
        }
        #t4x_2 {
          left: 632px;
          bottom: 625px;
          letter-spacing: -0.14px;
          word-spacing: 2.54px;
        }
        #t4y_2 {
          left: 970px;
          bottom: 625px;
          letter-spacing: -0.14px;
          word-spacing: 2.54px;
        }
        #t4z_2 {
          left: 632px;
          bottom: 613px;
          letter-spacing: -0.14px;
          word-spacing: 0.59px;
        }
        #t50_2 {
          left: 1073px;
          bottom: 613px;
          letter-spacing: -0.14px;
          word-spacing: 0.59px;
        }
        #t51_2 {
          left: 632px;
          bottom: 602px;
          letter-spacing: -0.13px;
          word-spacing: 0.59px;
        }
        #t52_2 {
          left: 632px;
          bottom: 590px;
          letter-spacing: -0.14px;
          word-spacing: 0.77px;
        }
        #t53_2 {
          left: 632px;
          bottom: 578px;
          letter-spacing: -0.13px;
        }
        #t54_2 {
          left: 632px;
          bottom: 567px;
          letter-spacing: -0.09px;
        }
        #t55_2 {
          left: 653px;
          bottom: 567px;
          letter-spacing: -0.14px;
          word-spacing: 0.71px;
        }
        #t56_2 {
          left: 632px;
          bottom: 555px;
          letter-spacing: -0.14px;
        }
        #t57_2 {
          left: 632px;
          bottom: 544px;
          letter-spacing: -0.09px;
        }
        #t58_2 {
          left: 661px;
          bottom: 544px;
          letter-spacing: -0.15px;
          word-spacing: 4.61px;
        }
        #t59_2 {
          left: 632px;
          bottom: 532px;
          letter-spacing: -0.14px;
          word-spacing: 1.19px;
        }
        #t5a_2 {
          left: 632px;
          bottom: 520px;
          letter-spacing: -0.14px;
          word-spacing: 1.5px;
        }
        #t5b_2 {
          left: 632px;
          bottom: 509px;
          letter-spacing: -0.16px;
          word-spacing: 3.32px;
        }
        #t5c_2 {
          left: 632px;
          bottom: 497px;
          letter-spacing: -0.15px;
          word-spacing: 2.68px;
        }
        #t5d_2 {
          left: 632px;
          bottom: 486px;
          letter-spacing: -0.14px;
          word-spacing: 0.84px;
        }
        #t5e_2 {
          left: 632px;
          bottom: 474px;
          letter-spacing: -0.14px;
          word-spacing: 1.04px;
        }
        #t5f_2 {
          left: 1178px;
          bottom: 474px;
          letter-spacing: -0.11px;
        }
        #t5g_2 {
          left: 632px;
          bottom: 462px;
          letter-spacing: -0.15px;
          word-spacing: 4.54px;
        }
        #t5h_2 {
          left: 632px;
          bottom: 451px;
          letter-spacing: -0.15px;
        }
        #t5i_2 {
          left: 632px;
          bottom: 439px;
          letter-spacing: -0.09px;
        }
        #t5j_2 {
          left: 650px;
          bottom: 439px;
          letter-spacing: -0.19px;
          word-spacing: 1.47px;
        }
        #t5k_2 {
          left: 834px;
          bottom: 439px;
          letter-spacing: -0.14px;
          word-spacing: 1.47px;
        }
        #t5l_2 {
          left: 632px;
          bottom: 428px;
          letter-spacing: -0.14px;
          word-spacing: 0.45px;
        }
        #t5m_2 {
          left: 632px;
          bottom: 416px;
          letter-spacing: -0.13px;
          word-spacing: 0.11px;
        }
        #t5n_2 {
          left: 1001px;
          bottom: 416px;
          letter-spacing: -0.14px;
          word-spacing: 0.11px;
        }
        #t5o_2 {
          left: 632px;
          bottom: 405px;
          letter-spacing: -0.15px;
          word-spacing: 2.01px;
        }
        #t5p_2 {
          left: 632px;
          bottom: 393px;
          letter-spacing: -0.14px;
          word-spacing: 3.14px;
        }
        #t5q_2 {
          left: 632px;
          bottom: 381px;
          letter-spacing: -0.13px;
          word-spacing: 0.42px;
        }
        #t5r_2 {
          left: 764px;
          bottom: 381px;
          letter-spacing: -0.13px;
          word-spacing: 0.42px;
        }
        #t5s_2 {
          left: 632px;
          bottom: 370px;
          letter-spacing: -0.13px;
          word-spacing: 0.25px;
        }
        #t5t_2 {
          left: 632px;
          bottom: 358px;
          letter-spacing: -0.13px;
          word-spacing: 1.05px;
        }
        #t5u_2 {
          left: 899px;
          bottom: 358px;
          letter-spacing: -0.14px;
          word-spacing: 1.05px;
        }
        #t5v_2 {
          left: 632px;
          bottom: 347px;
          letter-spacing: -0.14px;
          word-spacing: 1.81px;
        }
        #t5w_2 {
          left: 632px;
          bottom: 335px;
          letter-spacing: -0.13px;
          word-spacing: 0.31px;
        }
        #t5x_2 {
          left: 632px;
          bottom: 323px;
          letter-spacing: -0.13px;
          word-spacing: 1.14px;
        }
        #t5y_2 {
          left: 632px;
          bottom: 312px;
          letter-spacing: -0.13px;
          word-spacing: 0.37px;
        }
        #t5z_2 {
          left: 833px;
          bottom: 312px;
          letter-spacing: -0.13px;
          word-spacing: 0.37px;
        }
        #t60_2 {
          left: 632px;
          bottom: 300px;
          letter-spacing: -0.13px;
        }
        #t61_2 {
          left: 632px;
          bottom: 289px;
          letter-spacing: -0.09px;
        }
        #t62_2 {
          left: 650px;
          bottom: 289px;
          letter-spacing: -0.22px;
        }
        #t63_2 {
          left: 703px;
          bottom: 289px;
        }
        #t64_2 {
          left: 715px;
          bottom: 289px;
          letter-spacing: -0.14px;
          word-spacing: 1.4px;
        }
        #t65_2 {
          left: 632px;
          bottom: 277px;
          letter-spacing: -0.14px;
          word-spacing: 2.19px;
        }
        #t66_2 {
          left: 938px;
          bottom: 277px;
          letter-spacing: -0.14px;
          word-spacing: 2.2px;
        }
        #t67_2 {
          left: 632px;
          bottom: 265px;
          letter-spacing: -0.14px;
          word-spacing: 1.91px;
        }
        #t68_2 {
          left: 1063px;
          bottom: 265px;
          letter-spacing: -0.14px;
          word-spacing: 1.91px;
        }
        #t69_2 {
          left: 632px;
          bottom: 254px;
          letter-spacing: -0.14px;
          word-spacing: 0.2px;
        }
        #t6a_2 {
          left: 928px;
          bottom: 254px;
          letter-spacing: -0.14px;
          word-spacing: 0.2px;
        }
        #t6b_2 {
          left: 632px;
          bottom: 242px;
          letter-spacing: -0.13px;
          word-spacing: 0.36px;
        }
        #t6c_2 {
          left: 973px;
          bottom: 242px;
          letter-spacing: -0.13px;
          word-spacing: 0.36px;
        }
        #t6d_2 {
          left: 632px;
          bottom: 231px;
          letter-spacing: -0.13px;
          word-spacing: 0.92px;
        }
        #t6e_2 {
          left: 968px;
          bottom: 231px;
          letter-spacing: -0.14px;
          word-spacing: 0.92px;
        }
        #t6f_2 {
          left: 632px;
          bottom: 219px;
          letter-spacing: -0.15px;
          word-spacing: 3.01px;
        }
        #t6g_2 {
          left: 632px;
          bottom: 207px;
          letter-spacing: -0.13px;
          word-spacing: 0.36px;
        }
        #t6h_2 {
          left: 1049px;
          bottom: 207px;
          letter-spacing: -0.14px;
          word-spacing: 0.36px;
        }
        #t6i_2 {
          left: 632px;
          bottom: 196px;
          letter-spacing: -0.14px;
          word-spacing: 1.22px;
        }
        #t6j_2 {
          left: 632px;
          bottom: 184px;
          letter-spacing: -0.13px;
          word-spacing: 0.4px;
        }
        #t6k_2 {
          left: 632px;
          bottom: 173px;
          letter-spacing: -0.13px;
        }
        #t6l_2 {
          left: 632px;
          bottom: 161px;
          letter-spacing: -0.09px;
        }
        #t6m_2 {
          left: 652px;
          bottom: 161px;
          letter-spacing: -0.17px;
        }
        #t6n_2 {
          left: 762px;
          bottom: 161px;
          letter-spacing: -0.14px;
          word-spacing: 2.65px;
        }
        #t6o_2 {
          left: 632px;
          bottom: 150px;
          letter-spacing: -0.14px;
          word-spacing: 1.8px;
        }
        #t6p_2 {
          left: 925px;
          bottom: 150px;
          letter-spacing: -0.15px;
          word-spacing: 1.8px;
        }
        #t6q_2 {
          left: 632px;
          bottom: 138px;
          letter-spacing: -0.14px;
          word-spacing: 1.34px;
        }
        #t6r_2 {
          left: 632px;
          bottom: 126px;
          letter-spacing: -0.14px;
          word-spacing: 2.02px;
        }
        #t6s_2 {
          left: 632px;
          bottom: 115px;
          letter-spacing: -0.14px;
          word-spacing: 0.83px;
        }
        #t6t_2 {
          left: 632px;
          bottom: 103px;
          letter-spacing: -0.14px;
          word-spacing: 1px;
        }
        #t6u_2 {
          left: 632px;
          bottom: 92px;
          letter-spacing: -0.14px;
          word-spacing: 1.19px;
        }
        #t6v_2 {
          left: 632px;
          bottom: 80px;
          letter-spacing: -0.14px;
          word-spacing: 1.23px;
        }
        #t6w_2 {
          left: 632px;
          bottom: 68px;
          letter-spacing: -0.13px;
        }
        #t6x_2 {
          left: 632px;
          bottom: 57px;
          letter-spacing: -0.13px;
          word-spacing: 0.27px;
        }
        #t6y_2 {
          left: 632px;
          bottom: 45px;
          letter-spacing: -0.13px;
        }
        #t6z_2 {
          left: 535px;
          bottom: 874px;
          letter-spacing: -0.19px;
        }
        #t70_2 {
          left: 1109px;
          bottom: 869px;
          letter-spacing: 0.11px;
        }
        #t71_2 {
          left: 1116px;
          bottom: 25px;
          letter-spacing: -0.15px;
        }

        .s1_2 {
          font-size: 11px;
          font-family: sub_ArialMT_lsr;
          color: #000;
        }
        .s2_2 {
          font-size: 11px;
          font-family: sub_Arial-BoldMT_lsb;
          color: #000;
        }
        .s3_2 {
          font-size: 11px;
          font-family: sub_ArialMT_lsr;
          color: #0c0cf4;
        }
        .s4_2 {
          font-size: 9px;
          font-family: sub_ArialMT_lsr;
          color: #000;
        }
        .s5_2 {
          font-size: 9px;
          font-family: sub_ArialMT_lsr;
          color: #0c0cf4;
        }
        .s6_2 {
          font-size: 11px;
          font-family: ArialMT_l;
          color: #000;
        }
        .s7_2 {
          font-size: 12px;
          font-family: sub_Arial-BoldMT_lsb;
          color: #000;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts2" type="text/css">
        @font-face {
          font-family: ArialMT_l;
          src: url("fonts/ArialMT_l.woff") format("woff");
        }

        @font-face {
          font-family: sub_Arial-BoldMT_lsb;
          src: url("fonts/sub_Arial-BoldMT_lsb.woff") format("woff");
        }

        @font-face {
          font-family: sub_ArialMT_lsr;
          src: url("fonts/sub_ArialMT_lsr.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg2Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg2" style="-webkit-user-select: none">
        <object
          width="1286"
          height="909"
          data="2/2.svg"
          type="image/svg+xml"
          id="pdf2"
          style="
            width: 1286px;
            height: 909px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_2" class="t s1_2">1. </span
        ><span id="t2_2" class="t s2_2">GENERAL</span
        ><span id="t3_2" class="t s1_2"
          >. The terms and conditions set forth below, together with the written
          information contained on the face of
        </span>
        <span id="t4_2" class="t s1_2">this </span
        ><span id="t5_2" class="t s1_2"
          >purchase order ("Order") and all attachments and exhibits attached
          hereto and all specifications, drawings, notes,
        </span>
        <span id="t6_2" class="t s1_2"
          >instructions, and other written materials and information including
          those that can be found on the Supplier Partner
        </span>
        <span id="t7_2" class="t s1_2">Access Page ( Link: </span
        ><span id="t8_2" class="t s3_2"
          >http://www.sanmina.com/partners-access/supplier-requirements/index.php </span
        ><span id="t9_2" class="t s1_2">) referred to therein (the </span>
        <span id="ta_2" class="t s1_2"
          >"Terms") shall apply to the purchase of the products and/or services
          described in this Order (collectively "Products") and
        </span>
        <span id="tb_2" class="t s1_2"
          >are incorporated herein and are made a part of this Order. </span
        ><span id="tc_2" class="t s1_2"
          >Seller may accept this Order either by performance or the
        </span>
        <span id="td_2" class="t s1_2"
          >sending of an acknowledgement; provided, however, that any additional
          terms and conditions contained in Sellers offer
        </span>
        <span id="te_2" class="t s1_2"
          >or counteroffer documents (including any acknowledgment or invoice)
          shall not apply to this Order unless they are
        </span>
        <span id="tf_2" class="t s1_2"
          >specifically accepted by an individual of Buyer having the title of
          Vice President or higher ("Buyers Representative") in
        </span>
        <span id="tg_2" class="t s1_2">writing. </span
        ><span id="th_2" class="t s1_2"
          >In the event this Order is deemed to be an acceptance of a Seller
          quotation (rather than an offer or counteroffer
        </span>
        <span id="ti_2" class="t s1_2"
          >accepted by Seller by performance or acknowledgement), then Buyers
          acceptance of Sellers quotation is expressly
        </span>
        <span id="tj_2" class="t s1_2"
          >conditioned on Sellers agreement to these Terms. </span
        ><span id="tk_2" class="t s1_2"
          >If a purchase agreement (signed by a Buyer Representative) exists
        </span>
        <span id="tl_2" class="t s1_2"
          >between Seller and Buyer with respect to the Products, the terms of
          such agreement shall prevail over any inconsistent
        </span>
        <span id="tm_2" class="t s1_2">Terms herein. </span
        ><span id="tn_2" class="t s1_2"
          >In no event shall Buyers silence in response to any document
          containing Sellers terms and conditions be
        </span>
        <span id="to_2" class="t s1_2"
          >construed as an acceptance of any such terms or conditions. </span
        ><span id="tp_2" class="t s1_2"
          >The Order is by and between the Seller and the particular
        </span>
        <span id="tq_2" class="t s1_2"
          >Buyer entity set forth on the front of this document, and does not
          bind any other entity, including any Affiliate of Buyer.
        </span>
        <span id="tr_2" class="t s1_2">2. </span
        ><span id="ts_2" class="t s2_2"
          >PRICE, INVOICES, PAYMENT TERMS, AND DISCOUNTS</span
        ><span id="tt_2" class="t s1_2">. </span
        ><span id="tu_2" class="t s1_2"
          >Prices for Products shall be in the currency set forth on
        </span>
        <span id="tv_2" class="t s1_2"
          >the front of this Order, or if no currency is specified thereon, the
          currency of the country in which Buyer is incorporated.
        </span>
        <span id="tw_2" class="t s1_2"
          >Seller warrants that the prices offered for the Products hereunder
          are the lowest prices for these or similar Products sold
        </span>
        <span id="tx_2" class="t s1_2"
          >by Seller to other customers, and in the event of any price reduction
          between the acceptance of this Order and delivery
        </span>
        <span id="ty_2" class="t s1_2"
          >of the Products, Buyer shall be entitled to such reduction. </span
        ><span id="tz_2" class="t s1_2"
          >Seller shall submit invoices which include, at a minimum, the
        </span>
        <span id="t10_2" class="t s1_2"
          >following information: Order number, item number, description of
          item, size of item, quantity of item, unit price, applicable
        </span>
        <span id="t11_2" class="t s1_2"
          >taxes, extended totals, and any other information specified elsewhere
          herein. </span
        ><span id="t12_2" class="t s1_2"
          >A Bill of Lading or express receipt must
        </span>
        <span id="t13_2" class="t s1_2"
          >accompany each invoice. The Product price includes all applicable
          foreign, federal, state, and local taxes, tariffs, import
        </span>
        <span id="t14_2" class="t s1_2"
          >duties, commissions, and all shipping, freight, transportation,
          packing and handling charges required to deliver the
        </span>
        <span id="t15_2" class="t s1_2"
          >Product to the delivery point in accordance with Section 3(b);
          provided, however, that all freight, transportation, duties
        </span>
        <span id="t16_2" class="t s1_2"
          >and taxes (including value added taxes) shall be separately itemized. </span
        ><span id="t17_2" class="t s1_2"
          >Payment terms for any valid and non-disputed
        </span>
        <span id="t18_2" class="t s1_2"
          >invoice are net ninety (90) days from the date of receipt of product. </span
        ><span id="t19_2" class="t s1_2"
          >In the event Buyer disputes any invoice, it will
        </span>
        <span id="t1a_2" class="t s1_2"
          >promptly notify Seller, and the parties shall use their respective
          efforts to resolve the dispute. </span
        ><span id="t1b_2" class="t s1_2">Payment of invoices shall </span>
        <span id="t1c_2" class="t s1_2"
          >be deemed correct unless Seller notifies Buyer of any payment
          discrepancies within thirty (30) days after receipt of
        </span>
        <span id="t1d_2" class="t s1_2">payment. </span
        ><span id="t1e_2" class="t s1_2"
          >Buyers delay in paying any disputed portion of any invoice shall not
          constitute a breach or default of these
        </span>
        <span id="t1f_2" class="t s1_2">Terms. </span
        ><span id="t1g_2" class="t s1_2"
          >Buyer may at any time set off any amount owing from Seller or any of
          its Affiliates to Buyer or any of its Affiliates
        </span>
        <span id="t1h_2" class="t s1_2"
          >against any amount payable by Buyer or its Affiliates to Seller or
          its Affiliates. </span
        ><span id="t1i_2" class="t s1_2"
          >For the purpose of this Order, the term
        </span>
        <span id="t1j_2" class="t s1_2"
          >"Affiliate" of a party shall mean and include any entity which
          controls (by owning the majority of voting securities of), is
        </span>
        <span id="t1k_2" class="t s1_2"
          >controlled by, or is under common control of Buyer or Seller. </span
        ><span id="t1l_2" class="t s1_2"
          >Time in connection with any discount offered by Seller will
        </span>
        <span id="t1m_2" class="t s1_2"
          >be computed from the latest of (i) the scheduled delivery date, (ii)
          the date of actual delivery, or (iii) the date on which a
        </span>
        <span id="t1n_2" class="t s1_2"
          >correct invoice is received; for the purpose of earning the discount,
          payment will be deemed to have been made on the
        </span>
        <span id="t1o_2" class="t s1_2"
          >date of mailing of Buyer's Payment.
        </span>
        <span id="t1p_2" class="t s1_2">3. </span
        ><span id="t1q_2" class="t s2_2"
          >DELIVERY; INCOTERMS; RISK OF LOSS; PACKING AND SHIPMENT;
          OVERSHIPMENTS</span
        ><span id="t1r_2" class="t s1_2">. </span>
        <span id="t1s_2" class="t s1_2">(a) </span
        ><span id="t1t_2" class="t s1_2">T </span
        ><span id="t1u_2" class="t s4_2">I </span
        ><span id="t1v_2" class="t s4_2">M </span
        ><span id="t1w_2" class="t s4_2">E </span
        ><span id="t1x_2" class="t s4_2">I </span
        ><span id="t1y_2" class="t s4_2">S </span
        ><span id="t1z_2" class="t s4_2">O </span
        ><span id="t20_2" class="t s4_2">F </span
        ><span id="t21_2" class="t s4_2">T </span
        ><span id="t22_2" class="t s4_2">H </span
        ><span id="t23_2" class="t s4_2">E </span
        ><span id="t24_2" class="t s4_2">E </span
        ><span id="t25_2" class="t s4_2">S </span
        ><span id="t26_2" class="t s4_2">S </span
        ><span id="t27_2" class="t s4_2">E </span
        ><span id="t28_2" class="t s4_2">N </span
        ><span id="t29_2" class="t s4_2">C </span
        ><span id="t2a_2" class="t s4_2">E </span
        ><span id="t2b_2" class="t s4_2">O </span
        ><span id="t2c_2" class="t s4_2">F </span
        ><span id="t2d_2" class="t s4_2">T </span
        ><span id="t2e_2" class="t s4_2">H </span
        ><span id="t2f_2" class="t s4_2">I </span
        ><span id="t2g_2" class="t s4_2">S </span
        ><span id="t2h_2" class="t s1_2">O </span
        ><span id="t2i_2" class="t s4_2">R </span
        ><span id="t2j_2" class="t s4_2">D </span
        ><span id="t2k_2" class="t s4_2">E </span
        ><span id="t2l_2" class="t s4_2">R </span
        ><span id="t2m_2" class="t s1_2">. </span
        ><span id="t2n_2" class="t s1_2"
          >The delivery date shall be that which is specified in the Order
          unless
        </span>
        <span id="t2o_2" class="t s1_2"
          >agreed otherwise in writing between Buyer and Seller. </span
        ><span id="t2p_2" class="t s1_2"
          >Seller shall immediately notify Buyer in the event that Sellers
        </span>
        <span id="t2q_2" class="t s1_2"
          >timely performance under this Order is delayed or likely to be
          delayed, in whole or in part, and Seller shall provide Buyer
        </span>
        <span id="t2r_2" class="t s1_2"
          >with all available information regarding the reasons for the delay. </span
        ><span id="t2s_2" class="t s1_2"
          >Such notice shall not constitute a waiver by Buyer of
        </span>
        <span id="t2t_2" class="t s1_2"
          >any of Sellers obligations hereunder. </span
        ><span id="t2u_2" class="t s1_2"
          >If delivery of the Products is not timely completed, Buyer reserves
          the right, without
        </span>
        <span id="t2v_2" class="t s1_2"
          >liability and in addition to all its other rights and remedies: (1)
          to terminate this Order by notice effective when received
        </span>
        <span id="t2w_2" class="t s1_2"
          >by Seller as to Product not yet shipped, and to purchase substitute
          Products elsewhere and at Buyers option charge
        </span>
        <span id="t2x_2" class="t s1_2"
          >Seller with any loss incurred, (2) to request that Seller, at its
          expense, ship Product by expedited means and (3) to
        </span>
        <span id="t2y_2" class="t s1_2"
          >recover from Seller any damages it incurs, including but not limited
          to any damages it is required to pay to any third
        </span>
        <span id="t2z_2" class="t s1_2">party. </span
        ><span id="t30_2" class="t s1_2"
          >Neither partial shipments nor shipments prior to the delivery date
          shall be permitted unless agreed to in writing by
        </span>
        <span id="t31_2" class="t s1_2"
          >Buyer. Product delivered to Buyer more than three (3) days in advance
          of the delivery date herein or which are in excess
        </span>
        <span id="t32_2" class="t s1_2"
          >of the ordered quantities may be returned at Sellers expense.
        </span>
        <span id="t33_2" class="t s1_2">(b) </span
        ><span id="t34_2" class="t s1_2"
          >Unless otherwise specifically provided on the face of this Order, the
          Products will be delivered DAP Buyers facility
        </span>
        <span id="t35_2" class="t s1_2">(Incoterms 2010). </span
        ><span id="t36_2" class="t s1_2"
          >In the event this Order includes the delivery of equipment which
          requires installation, Seller shall
        </span>
        <span id="t37_2" class="t s1_2"
          >install such equipment (at Sellers expense) at Buyer's designated
          site upon request from buyer. </span
        ><span id="t38_2" class="t s1_2">Title and risk in </span>
        <span id="t39_2" class="t s1_2"
          >Products shall remain with Seller until they are delivered at the
          point specified in the Order and transferred to Buyers
        </span>
        <span id="t3a_2" class="t s1_2"
          >possession, at which time title and risk in the Products shall
          transfer to Buyer.
        </span>
        <span id="t3b_2" class="t s1_2">(c) </span
        ><span id="t3c_2" class="t s1_2"
          >Unless otherwise specified in the Order or in another written
          communication from Buyer to Seller, (i) all Products
        </span>
        <span id="t3d_2" class="t s1_2"
          >shall be packed in accordance with good commercial practices; (ii)
          Seller shall attach a complete packing list to the
        </span>
        <span id="t3e_2" class="t s1_2"
          >outside of each packing container; (iii) Seller shall mark all
          containers or packages with necessary lifting, loading and
        </span>
        <span id="t3f_2" class="t s1_2"
          >shipping information; (iv) Seller shall mark all shipments with bar
          code labels meeting Electronic Industries Association
        </span>
        <span id="t3g_2" class="t s1_2"
          >Outer Shipping Container Bar Code label Standard EIA-556-A; (v)
          Seller shall ensure that all packaging complies with
        </span>
        <span id="t3h_2" class="t s1_2"
          >laws on packaging and waste as implemented by the various member
          states of the European Union, as well as with
        </span>
        <span id="t3i_2" class="t s1_2"
          >similar environmental laws in other jurisdictions and shall include
          all information required by RoHS, WEEE, or REACH;
        </span>
        <span id="t3j_2" class="t s1_2"
          >(vi) Seller shall ship Product in a manner which complies with all
          laws, including ICC regulations and which is adequate
        </span>
        <span id="t3k_2" class="t s1_2"
          >to insure safe arrival of the Product at the destination.
        </span>
        <span id="t3l_2" class="t s1_2">(d) </span
        ><span id="t3m_2" class="t s1_2"
          >Unless otherwise agreed in writing, Seller shall be the exporter of
          record for Product and technical data.
        </span>
        <span id="t3n_2" class="t s1_2">(e) </span
        ><span id="t3o_2" class="t s1_2"
          >All information held by or reasonably available to Seller regarding
          any potential hazards known or believed to exist in
        </span>
        <span id="t3p_2" class="t s1_2"
          >the transport, handling or use of the Products shall be promptly
          communicated to Buyer.
        </span>
        <span id="t3q_2" class="t s1_2">4. </span
        ><span id="t3r_2" class="t s2_2">WARRANTY</span
        ><span id="t3s_2" class="t s1_2">. </span>
        <span id="t3t_2" class="t s1_2">(a) </span
        ><span id="t3u_2" class="t s1_2"
          >In addition to any express or implied warranties, Seller warrants
          that Products will be (1) new and unused, (2) free
        </span>
        <span id="t3v_2" class="t s1_2"
          >from all defects, including defects in workmanship, material, design
          and manufacture, (3) of merchantable quality and fit
        </span>
        <span id="t3w_2" class="t s1_2"
          >for the purposes intended by Buyer, (4) in conformance with any
          drawings or specifications provided to Seller or any
        </span>
        <span id="t3x_2" class="t s1_2"
          >samples furnished by Seller, and all Seller quality requirements as
          defined in the </span
        ><span id="t3y_2" class="t s2_2">Supplier Quality Requirements </span>
        <span id="t3z_2" class="t s2_2">Document(QAR-0001-C)</span
        ><span id="t40_2" class="t s1_2">(Link: </span
        ><span id="t41_2" class="t s5_2"
          >https://www.sanmina.com/wp-content/uploads/2020/12/QAR-0001-C_Supplier_Requirement.pdf</span
        ><span id="t42_2" class="t s3_2">)</span
        ><span id="t43_2" class="t s1_2">, (5) </span>
        <span id="t44_2" class="t s1_2"
          >free from infringement of any third party intellectual property, and
          (6) if services, performed in a professional and
        </span>
        <span id="t45_2" class="t s1_2">workmanlike manner. </span
        ><span id="t46_2" class="t s1_2"
          >The foregoing warranties shall (i) survive the delivery and
          inspection of the Product and
        </span>
        <span id="t47_2" class="t s1_2"
          >acceptance or payment by Buyer, (ii) be in effect for as long as
          Buyer warrants its product (containing the Product) to its
        </span>
        <span id="t48_2" class="t s1_2"
          >customer, but no longer than five years from delivery, and (iii) run
          to the benefit of Buyer and its customers. </span
        ><span id="t49_2" class="t s1_2">Buyers </span>
        <span id="t4a_2" class="t s1_2"
          >approval of Sellers materials or design will not relieve Seller of
          any warranties.
        </span>
        <span id="t4b_2" class="t s1_2">(b) </span
        ><span id="t4c_2" class="t s1_2"
          >IIn addition to the warranties set forth in (a), in the event Buyer
          orders Product which it specifies must comply with
        </span>
        <span id="t4d_2" class="t s1_2"
          >the RoHS, WEEE, REACH, and/or similar environmental laws and
          restrictions in other nations, then Seller represents,
        </span>
        <span id="t4e_2" class="t s1_2"
          >warrants and certifies that all Product complies with the RoHS, WEEE,
          REACH, or other specified environmental laws.
        </span>
        <span id="t4f_2" class="t s1_2"
          >"RoHS" means EU Directive 2011/65/EC, "WEEE" means EU Directive
          2002/96/EC and </span
        ><span id="t4g_2" class="t s6_2">“</span
        ><span id="t4h_2" class="t s1_2">REACH</span
        ><span id="t4i_2" class="t s6_2">” </span
        ><span id="t4j_2" class="t s1_2">means the EU </span>
        <span id="t4k_2" class="t s1_2"
          >regulations concerning the Registration, Evaluation, and
          Authorization of Chemicals </span
        ><span id="t4l_2" class="t s6_2">–</span
        ><span id="t4m_2" class="t s1_2">each, as amended. </span
        ><span id="t4n_2" class="t s1_2">The parties </span>
        <span id="t4o_2" class="t s1_2"
          >acknowledge that Buyer may specify RoHS, WEEE, REACH, or other
          environmental compliance in various ways,
        </span>
        <span id="t4p_2" class="t s1_2"
          >including but not limited to relying on Sellers published Product
          specifications or any other statement from Seller stating
        </span>
        <span id="t4q_2" class="t s1_2"
          >that the Product complies with the RoHS, WEEE, REACH, or other
          environmental laws, or by advising the Seller either
        </span>
        <span id="t4r_2" class="t s1_2"
          >in a request for quotation, on its Order(s), or otherwise.
        </span>
        <span id="t4s_2" class="t s1_2">(c) </span
        ><span id="t4t_2" class="t s1_2"
          >In addition to any other rights Buyer may have, if Products delivered
          pursuant to this Order do not meet the foregoing
        </span>
        <span id="t4u_2" class="t s1_2"
          >warranties, Buyer shall, at its sole option, have the right to (i)
          require Seller to correct any defective or nonconforming
        </span>
        <span id="t4v_2" class="t s1_2"
          >Product by repair or replacement at no cost to Buyer; (ii) return
          such defective Product to Seller, at Sellers expense and
        </span>
        <span id="t4w_2" class="t s1_2"
          >sole risk, for a credit at the price the Products were originally
          purchased or (iii) correct the defective or nonconforming
        </span>
        <span id="t4x_2" class="t s1_2"
          >Product itself and charge Seller with the cost of such correction. </span
        ><span id="t4y_2" class="t s1_2"
          >In addition, Seller shall indemnify and hold the
        </span>
        <span id="t4z_2" class="t s1_2"
          >Buyer-Indemnified Parties harmless from and against any Claims as set
          forth in Section 7. </span
        ><span id="t50_2" class="t s1_2">In the event Buyer requests </span>
        <span id="t51_2" class="t s1_2"
          >a return for replacement, Seller shall (a) provide Buyer with a
          return material authorization number within 24 hours after
        </span>
        <span id="t52_2" class="t s1_2"
          >Buyers request, (b) replace or rework and ship the nonconforming
          returned Products within five (5) business days after
        </span>
        <span id="t53_2" class="t s1_2"
          >receipt and (c) shall bear all shipping costs required to effectuate
          the replacement.
        </span>
        <span id="t54_2" class="t s1_2">(d) </span
        ><span id="t55_2" class="t s1_2"
          >Any repaired Product shall be warranted as set forth herein to the
          same extent and duration as the Product initially
        </span>
        <span id="t56_2" class="t s1_2">furnished. </span>
        <span id="t57_2" class="t s1_2">(e) </span
        ><span id="t58_2" class="t s1_2"
          >In addition to the foregoing warranties, Seller warrants that the
          Product, Sellers business (including its
        </span>
        <span id="t59_2" class="t s1_2"
          >manufacturing, operating and hiring processes), and the Product
          documentation comply with all international, national,
        </span>
        <span id="t5a_2" class="t s1_2"
          >federal, state, and local ordinances, rules and regulations
          (including but not limited to the Fair Labor Act of 1938, as
        </span>
        <span id="t5b_2" class="t s1_2"
          >amended, the Occupational Safety and Health Act of 1970, the Toxic
          Substances Control Act of 1976 and the
        </span>
        <span id="t5c_2" class="t s1_2"
          >Transportation Safety Act of 1974, as amended, all regulations of the
          Food and Drug Administration, the Federal
        </span>
        <span id="t5d_2" class="t s1_2"
          >Acquisition Regulations, and any similar law outside of the United
          States, as such laws have been amended, modified,
        </span>
        <span id="t5e_2" class="t s1_2"
          >or implemented (collectively "Laws") and that the manufacture and
          sale of the Product complies with all Laws. </span
        ><span id="t5f_2" class="t s1_2">Seller </span>
        <span id="t5g_2" class="t s1_2"
          >further warrants the accuracy of all Product documentation it
          provides to Buyer, including but not limited to
        </span>
        <span id="t5h_2" class="t s1_2"
          >customs-related documents and MSDS and safety-related documents.
        </span>
        <span id="t5i_2" class="t s1_2">5. </span
        ><span id="t5j_2" class="t s2_2">INSPECTION AND ACCEPTANCE. </span
        ><span id="t5k_2" class="t s1_2"
          >Payment for the Product does not constitute acceptance of the Product
          and
        </span>
        <span id="t5l_2" class="t s1_2"
          >Buyer reserves the right to take an adjustment (by means of a credit
          memo or otherwise) for errors, shortages, defect in
        </span>
        <span id="t5m_2" class="t s1_2"
          >the Products or other failure of Seller to meet the requirements of
          this Order. </span
        ><span id="t5n_2" class="t s1_2"
          >Product will only be deemed accepted after
        </span>
        <span id="t5o_2" class="t s1_2"
          >it has actually been counted, inspected and tested by Buyer and
          determined to be in conformance with this Order.
        </span>
        <span id="t5p_2" class="t s1_2"
          >Notwithstanding the foregoing, Buyers failure to inspect or test the
          Product shall not relieve Seller of any of its
        </span>
        <span id="t5q_2" class="t s1_2">responsibilities hereunder. </span
        ><span id="t5r_2" class="t s1_2"
          >In case any Product is not in conformity with the requirements set
          forth in the Order or these
        </span>
        <span id="t5s_2" class="t s1_2"
          >Terms, Buyer will have (in addition to charging Seller for the
          inspection of the Products) the right to reject it, to require its
        </span>
        <span id="t5t_2" class="t s1_2"
          >correction, or to accept it with an adjustment in price. </span
        ><span id="t5u_2" class="t s1_2"
          >Any Product that has been rejected or required to be corrected
        </span>
        <span id="t5v_2" class="t s1_2"
          >must be replaced or corrected by and at the expense of Seller within
          five business days after notice. If, after being
        </span>
        <span id="t5w_2" class="t s1_2"
          >requested by Buyer, Seller fails to timely replace or correct any
          defective Product or element of service, then Buyer shall
        </span>
        <span id="t5x_2" class="t s1_2"
          >have the right (a) to exercise any remedy set forth in Section 4(c)
          above, (b) to cancel this Order for default, or (c) to
        </span>
        <span id="t5y_2" class="t s1_2"
          >require an appropriate reduction in price. </span
        ><span id="t5z_2" class="t s1_2"
          >Buyer (on behalf of itself and its customer) reserves the right to
          inspect, at the
        </span>
        <span id="t60_2" class="t s1_2"
          >Sellers facility or at any other location, any or all of the Products
          and any records relating to the products.
        </span>
        <span id="t61_2" class="t s1_2">6. </span
        ><span id="t62_2" class="t s2_2">CHANGES</span
        ><span id="t63_2" class="t s1_2">. </span
        ><span id="t64_2" class="t s1_2"
          >Buyer shall have the right at any time to suspend performance
          hereunder, increase or decrease the
        </span>
        <span id="t65_2" class="t s1_2"
          >ordered quantities, change the delivery date of the Product </span
        ><span id="t66_2" class="t s1_2"
          >or make changes in drawings, designs, specifications,
        </span>
        <span id="t67_2" class="t s1_2"
          >materials, packaging, place of delivery and/or method of
          transportation ("Change(s)"). </span
        ><span id="t68_2" class="t s1_2">Seller agrees to accept such </span>
        <span id="t69_2" class="t s1_2"
          >Changes as though the Changes were included in the Order. </span
        ><span id="t6a_2" class="t s1_2"
          >If any Changes cause an increase or decrease in the cost,
        </span>
        <span id="t6b_2" class="t s1_2"
          >or the time required for performance, Seller shall notify Buyer, and
          the </span
        ><span id="t6c_2" class="t s1_2"
          >parties shall agree on an equitable adjustment in
        </span>
        <span id="t6d_2" class="t s1_2"
          >the price and/or delivery date, and shall modify the Order in
          writing. </span
        ><span id="t6e_2" class="t s1_2"
          >No claim by Seller for such an adjustment will be
        </span>
        <span id="t6f_2" class="t s1_2"
          >valid unless asserted in writing within twenty (20) days (or such
          longer period as agreed to in writing by Buyer
        </span>
        <span id="t6g_2" class="t s1_2"
          >Representative) after the date on which Seller received the
          notification of the Change. </span
        ><span id="t6h_2" class="t s1_2"
          >Except in the event of a Change,
        </span>
        <span id="t6i_2" class="t s1_2"
          >Seller shall not make any changes to the form, fit, function to any
          Products or any changes to the drawings, designs,
        </span>
        <span id="t6j_2" class="t s1_2"
          >specifications, materials, process, packaging, time and place of
          delivery or method of transportation without Buyers prior
        </span>
        <span id="t6k_2" class="t s1_2">written consent. </span>
        <span id="t6l_2" class="t s1_2">7. </span
        ><span id="t6m_2" class="t s2_2">INDEMNIFICATION. </span
        ><span id="t6n_2" class="t s1_2"
          >Seller shall indemnify, defend and hold Buyer, its officers,
          directors, employees, agents,
        </span>
        <span id="t6o_2" class="t s1_2"
          >customers, and affiliates (the "Buyer-Indemnified Parties") </span
        ><span id="t6p_2" class="t s1_2"
          >harmless from and against any and all demands, claims,
        </span>
        <span id="t6q_2" class="t s1_2"
          >actions, causes of action, proceedings, suits, assessments, losses,
          damages, liabilities, settlements, judgments, fines,
        </span>
        <span id="t6r_2" class="t s1_2"
          >penalties, interest, costs and expenses (including fees and
          disbursements of counsel and, in the case of breach of
        </span>
        <span id="t6s_2" class="t s1_2"
          >warranty, including the internal or external costs of any recall
          (including shipping costs to and from Buyers customer to
        </span>
        <span id="t6t_2" class="t s1_2"
          >Buyer), the costs incurred by Buyer to determine the cause of the
          failure, the technical support labor costs in handling
        </span>
        <span id="t6u_2" class="t s1_2"
          >customer relationships and the costs incurred by Buyer to repair the
          product which incorporates the Product) of every
        </span>
        <span id="t6v_2" class="t s1_2"
          >kind ("Claim") (i) which Claim alleges that the Product, the use or
          sale of the Product, or the equipment or processes
        </span>
        <span id="t6w_2" class="t s1_2"
          >used to manufacture and/or assemble the Product infringe the patent,
          copyright, trademark, trade secret or other form of
        </span>
        <span id="t6x_2" class="t s1_2"
          >intellectual property right belonging to a third party or (2) Seller
          has engaged in unfair competition as a result of similarity
        </span>
        <span id="t6y_2" class="t s1_2"
          >in design, trademark or appearance of the Product; (ii) based upon
          personal injury or death or injury to property to the
        </span>
        <span id="t6z_2" class="t s2_2">TERMS AND CONDITIONS </span>
        <span id="t70_2" class="t s7_2">Page 1 of 2 </span>
        <span id="t71_2" class="t s1_2">Rev. May 10, 2018 </span>
      </div>
      <!-- End text definitions -->
    </div>

    <div
      id="p3"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 1286px;
        height: 909px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_3 {
          left: 35px;
          bottom: 846px;
          letter-spacing: -0.14px;
          word-spacing: 1.61px;
        }
        #t2_3 {
          left: 35px;
          bottom: 834px;
          letter-spacing: -0.14px;
          word-spacing: 0.76px;
        }
        #t3_3 {
          left: 35px;
          bottom: 823px;
          letter-spacing: -0.13px;
          word-spacing: 0.4px;
        }
        #t4_3 {
          left: 35px;
          bottom: 812px;
          letter-spacing: -0.15px;
          word-spacing: 1.67px;
        }
        #t5_3 {
          left: 581px;
          bottom: 812px;
          letter-spacing: -0.11px;
        }
        #t6_3 {
          left: 35px;
          bottom: 800px;
          letter-spacing: -0.14px;
          word-spacing: 0.37px;
        }
        #t7_3 {
          left: 35px;
          bottom: 789px;
          letter-spacing: -0.14px;
          word-spacing: 0.51px;
        }
        #t8_3 {
          left: 478px;
          bottom: 789px;
          letter-spacing: -0.13px;
          word-spacing: 0.5px;
        }
        #t9_3 {
          left: 35px;
          bottom: 778px;
          letter-spacing: -0.13px;
          word-spacing: 0.23px;
        }
        #ta_3 {
          left: 488px;
          bottom: 778px;
          letter-spacing: -0.12px;
          word-spacing: 0.23px;
        }
        #tb_3 {
          left: 35px;
          bottom: 766px;
          letter-spacing: -0.14px;
          word-spacing: 1.05px;
        }
        #tc_3 {
          left: 480px;
          bottom: 766px;
          letter-spacing: -0.13px;
          word-spacing: 1.05px;
        }
        #td_3 {
          left: 35px;
          bottom: 755px;
          letter-spacing: -0.13px;
        }
        #te_3 {
          left: 35px;
          bottom: 744px;
          letter-spacing: -0.09px;
        }
        #tf_3 {
          left: 50px;
          bottom: 744px;
          letter-spacing: -0.19px;
        }
        #tg_3 {
          left: 237px;
          bottom: 744px;
        }
        #th_3 {
          left: 35px;
          bottom: 732px;
          letter-spacing: -0.09px;
        }
        #ti_3 {
          left: 55px;
          bottom: 732px;
          letter-spacing: -0.13px;
          word-spacing: 0.44px;
        }
        #tj_3 {
          left: 35px;
          bottom: 721px;
          letter-spacing: -0.13px;
          word-spacing: 0.08px;
        }
        #tk_3 {
          left: 35px;
          bottom: 710px;
          letter-spacing: -0.14px;
          word-spacing: 0.73px;
        }
        #tl_3 {
          left: 35px;
          bottom: 698px;
          letter-spacing: -0.13px;
          word-spacing: 0.69px;
        }
        #tm_3 {
          left: 165px;
          bottom: 698px;
          letter-spacing: -0.13px;
          word-spacing: 0.69px;
        }
        #tn_3 {
          left: 35px;
          bottom: 687px;
          letter-spacing: -0.12px;
          word-spacing: 0.36px;
        }
        #to_3 {
          left: 35px;
          bottom: 675px;
          letter-spacing: -0.13px;
          word-spacing: 0.62px;
        }
        #tp_3 {
          left: 555px;
          bottom: 675px;
          letter-spacing: -0.12px;
          word-spacing: 0.62px;
        }
        #tq_3 {
          left: 35px;
          bottom: 664px;
          letter-spacing: -0.13px;
          word-spacing: 0.41px;
        }
        #tr_3 {
          left: 35px;
          bottom: 653px;
          letter-spacing: -0.14px;
          word-spacing: 1.39px;
        }
        #ts_3 {
          left: 35px;
          bottom: 641px;
          letter-spacing: -0.14px;
        }
        #tt_3 {
          left: 35px;
          bottom: 630px;
          letter-spacing: -0.09px;
        }
        #tu_3 {
          left: 55px;
          bottom: 630px;
          letter-spacing: -0.13px;
          word-spacing: 0.77px;
        }
        #tv_3 {
          left: 502px;
          bottom: 630px;
          letter-spacing: -0.13px;
          word-spacing: 0.77px;
        }
        #tw_3 {
          left: 35px;
          bottom: 619px;
          letter-spacing: -0.14px;
          word-spacing: 0.86px;
        }
        #tx_3 {
          left: 35px;
          bottom: 607px;
          letter-spacing: -0.14px;
          word-spacing: 0.14px;
        }
        #ty_3 {
          left: 418px;
          bottom: 607px;
          letter-spacing: -0.13px;
          word-spacing: 0.14px;
        }
        #tz_3 {
          left: 35px;
          bottom: 596px;
          letter-spacing: -0.14px;
          word-spacing: 1.33px;
        }
        #t10_3 {
          left: 35px;
          bottom: 585px;
          letter-spacing: -0.14px;
          word-spacing: 1.06px;
        }
        #t11_3 {
          left: 35px;
          bottom: 573px;
          letter-spacing: -0.14px;
          word-spacing: 1.69px;
        }
        #t12_3 {
          left: 35px;
          bottom: 562px;
          letter-spacing: -0.14px;
          word-spacing: 1.9px;
        }
        #t13_3 {
          left: 35px;
          bottom: 551px;
          letter-spacing: -0.14px;
          word-spacing: 0.63px;
        }
        #t14_3 {
          left: 503px;
          bottom: 551px;
          letter-spacing: -0.13px;
          word-spacing: 0.63px;
        }
        #t15_3 {
          left: 35px;
          bottom: 539px;
          letter-spacing: -0.14px;
          word-spacing: 1.66px;
        }
        #t16_3 {
          left: 35px;
          bottom: 528px;
          letter-spacing: -0.13px;
          word-spacing: 2.42px;
        }
        #t17_3 {
          left: 213px;
          bottom: 528px;
          letter-spacing: -0.14px;
          word-spacing: 2.42px;
        }
        #t18_3 {
          left: 35px;
          bottom: 517px;
          letter-spacing: -0.14px;
          word-spacing: 1.54px;
        }
        #t19_3 {
          left: 575px;
          bottom: 517px;
          letter-spacing: -0.13px;
        }
        #t1a_3 {
          left: 35px;
          bottom: 505px;
          letter-spacing: -0.14px;
          word-spacing: 1.71px;
        }
        #t1b_3 {
          left: 35px;
          bottom: 494px;
          letter-spacing: -0.14px;
          word-spacing: 0.65px;
        }
        #t1c_3 {
          left: 35px;
          bottom: 482px;
          letter-spacing: -0.14px;
          word-spacing: 0.22px;
        }
        #t1d_3 {
          left: 35px;
          bottom: 471px;
          letter-spacing: -0.15px;
          word-spacing: 2.34px;
        }
        #t1e_3 {
          left: 35px;
          bottom: 460px;
          letter-spacing: -0.14px;
          word-spacing: 2.17px;
        }
        #t1f_3 {
          left: 35px;
          bottom: 448px;
          letter-spacing: -0.14px;
          word-spacing: 3.35px;
        }
        #t1g_3 {
          left: 301px;
          bottom: 448px;
          letter-spacing: -0.15px;
          word-spacing: 3.35px;
        }
        #t1h_3 {
          left: 35px;
          bottom: 437px;
          letter-spacing: -0.14px;
          word-spacing: 0.57px;
        }
        #t1i_3 {
          left: 446px;
          bottom: 437px;
          letter-spacing: -0.14px;
          word-spacing: 0.57px;
        }
        #t1j_3 {
          left: 35px;
          bottom: 426px;
          letter-spacing: -0.14px;
          word-spacing: 0.37px;
        }
        #t1k_3 {
          left: 35px;
          bottom: 414px;
          letter-spacing: -0.13px;
        }
        #t1l_3 {
          left: 35px;
          bottom: 403px;
          letter-spacing: -0.09px;
        }
        #t1m_3 {
          left: 58px;
          bottom: 403px;
          letter-spacing: -0.14px;
          word-spacing: 2.36px;
        }
        #t1n_3 {
          left: 35px;
          bottom: 392px;
          letter-spacing: -0.14px;
        }
        #t1o_3 {
          left: 35px;
          bottom: 380px;
          letter-spacing: -0.09px;
        }
        #t1p_3 {
          left: 52px;
          bottom: 380px;
          letter-spacing: -0.18px;
          word-spacing: 1.19px;
        }
        #t1q_3 {
          left: 236px;
          bottom: 380px;
          letter-spacing: -0.13px;
        }
        #t1r_3 {
          left: 253px;
          bottom: 380px;
          letter-spacing: -0.13px;
          word-spacing: 1.19px;
        }
        #t1s_3 {
          left: 35px;
          bottom: 369px;
          letter-spacing: -0.14px;
          word-spacing: 1.48px;
        }
        #t1t_3 {
          left: 35px;
          bottom: 358px;
          letter-spacing: -0.14px;
          word-spacing: 0.62px;
        }
        #t1u_3 {
          left: 364px;
          bottom: 358px;
          letter-spacing: -0.13px;
          word-spacing: 0.62px;
        }
        #t1v_3 {
          left: 35px;
          bottom: 346px;
          letter-spacing: -0.14px;
          word-spacing: 2.21px;
        }
        #t1w_3 {
          left: 35px;
          bottom: 335px;
          letter-spacing: -0.14px;
          word-spacing: 1.86px;
        }
        #t1x_3 {
          left: 35px;
          bottom: 324px;
          letter-spacing: -0.15px;
          word-spacing: 2.98px;
        }
        #t1y_3 {
          left: 35px;
          bottom: 312px;
          letter-spacing: -0.15px;
          word-spacing: 4.79px;
        }
        #t1z_3 {
          left: 35px;
          bottom: 301px;
          letter-spacing: -0.14px;
          word-spacing: 2.38px;
        }
        #t20_3 {
          left: 252px;
          bottom: 301px;
          letter-spacing: -0.15px;
          word-spacing: 2.38px;
        }
        #t21_3 {
          left: 35px;
          bottom: 289px;
          letter-spacing: -0.14px;
          word-spacing: 0.71px;
        }
        #t22_3 {
          left: 35px;
          bottom: 278px;
          letter-spacing: -0.14px;
          word-spacing: 1.95px;
        }
        #t23_3 {
          left: 35px;
          bottom: 267px;
          letter-spacing: -0.14px;
        }
        #t24_3 {
          left: 35px;
          bottom: 255px;
          letter-spacing: -0.14px;
        }
        #t25_3 {
          left: 35px;
          bottom: 244px;
          letter-spacing: -0.14px;
        }
        #t26_3 {
          left: 35px;
          bottom: 233px;
          letter-spacing: -0.14px;
        }
        #t27_3 {
          left: 35px;
          bottom: 221px;
          letter-spacing: -0.14px;
        }
        #t28_3 {
          left: 35px;
          bottom: 210px;
          letter-spacing: -0.14px;
        }
        #t29_3 {
          left: 35px;
          bottom: 199px;
          letter-spacing: -0.15px;
        }
        #t2a_3 {
          left: 35px;
          bottom: 187px;
          letter-spacing: -0.14px;
        }
        #t2b_3 {
          left: 35px;
          bottom: 176px;
          letter-spacing: -0.14px;
        }
        #t2c_3 {
          left: 35px;
          bottom: 165px;
          letter-spacing: -0.14px;
        }
        #t2d_3 {
          left: 35px;
          bottom: 153px;
          letter-spacing: -0.15px;
        }
        #t2e_3 {
          left: 35px;
          bottom: 142px;
          letter-spacing: -0.09px;
        }
        #t2f_3 {
          left: 51px;
          bottom: 142px;
          letter-spacing: -0.14px;
        }
        #t2g_3 {
          left: 35px;
          bottom: 131px;
          letter-spacing: -0.13px;
        }
        #t2h_3 {
          left: 93px;
          bottom: 131px;
          letter-spacing: -0.13px;
        }
        #t2i_3 {
          left: 35px;
          bottom: 119px;
          letter-spacing: -0.13px;
        }
        #t2j_3 {
          left: 52px;
          bottom: 119px;
          letter-spacing: -0.14px;
          word-spacing: 1.19px;
        }
        #t2k_3 {
          left: 413px;
          bottom: 119px;
        }
        #t2l_3 {
          left: 417px;
          bottom: 119px;
          letter-spacing: -0.13px;
          word-spacing: 1.19px;
        }
        #t2m_3 {
          left: 495px;
          bottom: 119px;
        }
        #t2n_3 {
          left: 498px;
          bottom: 119px;
          letter-spacing: -0.13px;
          word-spacing: 1.19px;
        }
        #t2o_3 {
          left: 35px;
          bottom: 108px;
          letter-spacing: -0.14px;
          word-spacing: 1.86px;
        }
        #t2p_3 {
          left: 35px;
          bottom: 97px;
          letter-spacing: -0.13px;
        }
        #t2q_3 {
          left: 35px;
          bottom: 85px;
          letter-spacing: -0.11px;
        }
        #t2r_3 {
          left: 55px;
          bottom: 85px;
          letter-spacing: -0.19px;
        }
        #t2s_3 {
          left: 313px;
          bottom: 85px;
        }
        #t2t_3 {
          left: 322px;
          bottom: 85px;
          letter-spacing: -0.14px;
        }
        #t2u_3 {
          left: 35px;
          bottom: 74px;
          letter-spacing: -0.13px;
        }
        #t2v_3 {
          left: 91px;
          bottom: 74px;
          letter-spacing: -0.11px;
        }
        #t2w_3 {
          left: 162px;
          bottom: 74px;
        }
        #t2x_3 {
          left: 207px;
          bottom: 74px;
          letter-spacing: -0.13px;
        }
        #t2y_3 {
          left: 268px;
          bottom: 74px;
          letter-spacing: -0.09px;
        }
        #t2z_3 {
          left: 316px;
          bottom: 74px;
          letter-spacing: -0.13px;
        }
        #t30_3 {
          left: 382px;
          bottom: 74px;
          letter-spacing: -0.15px;
        }
        #t31_3 {
          left: 441px;
          bottom: 74px;
          letter-spacing: -0.11px;
        }
        #t32_3 {
          left: 492px;
          bottom: 74px;
          letter-spacing: -0.13px;
        }
        #t33_3 {
          left: 558px;
          bottom: 74px;
          letter-spacing: -0.08px;
        }
        #t34_3 {
          left: 35px;
          bottom: 62px;
          letter-spacing: -0.14px;
        }
        #t35_3 {
          left: 438px;
          bottom: 62px;
          letter-spacing: -0.13px;
          word-spacing: 1.87px;
        }
        #t36_3 {
          left: 35px;
          bottom: 51px;
          letter-spacing: -0.13px;
          word-spacing: 0.27px;
        }
        #t37_3 {
          left: 35px;
          bottom: 40px;
          letter-spacing: -0.13px;
          word-spacing: 1.28px;
        }
        #t38_3 {
          left: 247px;
          bottom: 40px;
          letter-spacing: -0.14px;
          word-spacing: 1.28px;
        }
        #t39_3 {
          left: 35px;
          bottom: 28px;
          letter-spacing: -0.13px;
        }
        #t3a_3 {
          left: 526px;
          bottom: 865px;
          letter-spacing: 0.14px;
        }
        #t3b_3 {
          left: 1117px;
          bottom: 865px;
          letter-spacing: 0.11px;
        }
        #t3c_3 {
          left: 632px;
          bottom: 846px;
          letter-spacing: -0.11px;
        }
        #t3d_3 {
          left: 653px;
          bottom: 846px;
          letter-spacing: -0.17px;
        }
        #t3e_3 {
          left: 717px;
          bottom: 846px;
          letter-spacing: -0.21px;
        }
        #t3f_3 {
          left: 735px;
          bottom: 846px;
          letter-spacing: -0.16px;
        }
        #t3g_3 {
          left: 786px;
          bottom: 846px;
        }
        #t3h_3 {
          left: 795px;
          bottom: 846px;
          letter-spacing: -0.18px;
        }
        #t3i_3 {
          left: 632px;
          bottom: 834px;
          letter-spacing: -0.18px;
        }
        #t3j_3 {
          left: 632px;
          bottom: 823px;
          letter-spacing: -0.19px;
          word-spacing: 4.53px;
        }
        #t3k_3 {
          left: 632px;
          bottom: 812px;
          letter-spacing: -0.17px;
        }
        #t3l_3 {
          left: 729px;
          bottom: 812px;
          letter-spacing: -0.19px;
          word-spacing: 3.71px;
        }
        #t3m_3 {
          left: 632px;
          bottom: 800px;
          letter-spacing: -0.19px;
          word-spacing: 0.69px;
        }
        #t3n_3 {
          left: 911px;
          bottom: 800px;
          letter-spacing: -0.18px;
          word-spacing: 0.69px;
        }
        #t3o_3 {
          left: 632px;
          bottom: 789px;
          letter-spacing: -0.17px;
        }
        #t3p_3 {
          left: 632px;
          bottom: 778px;
          letter-spacing: -0.11px;
        }
        #t3q_3 {
          left: 654px;
          bottom: 778px;
          letter-spacing: -0.18px;
        }
        #t3r_3 {
          left: 750px;
          bottom: 778px;
        }
        #t3s_3 {
          left: 760px;
          bottom: 778px;
          letter-spacing: -0.14px;
          word-spacing: 0.31px;
        }
        #t3t_3 {
          left: 632px;
          bottom: 766px;
          letter-spacing: -0.14px;
          word-spacing: 0.13px;
        }
        #t3u_3 {
          left: 632px;
          bottom: 755px;
          letter-spacing: -0.14px;
          word-spacing: 1.5px;
        }
        #t3v_3 {
          left: 632px;
          bottom: 744px;
          letter-spacing: -0.15px;
          word-spacing: 0.88px;
        }
        #t3w_3 {
          left: 850px;
          bottom: 744px;
          letter-spacing: -0.15px;
        }
        #t3x_3 {
          left: 1142px;
          bottom: 744px;
        }
        #t3y_3 {
          left: 1153px;
          bottom: 744px;
          letter-spacing: -0.12px;
          word-spacing: 0.88px;
        }
        #t3z_3 {
          left: 632px;
          bottom: 732px;
          letter-spacing: -0.13px;
        }
        #t40_3 {
          left: 632px;
          bottom: 721px;
          letter-spacing: -0.11px;
        }
        #t41_3 {
          left: 654px;
          bottom: 721px;
          letter-spacing: -0.21px;
        }
        #t42_3 {
          left: 694px;
          bottom: 721px;
          letter-spacing: -0.2px;
          word-spacing: 0.3px;
        }
        #t43_3 {
          left: 819px;
          bottom: 721px;
        }
        #t44_3 {
          left: 828px;
          bottom: 721px;
          letter-spacing: -0.13px;
          word-spacing: 0.3px;
        }
        #t45_3 {
          left: 632px;
          bottom: 710px;
          letter-spacing: -0.14px;
          word-spacing: 0.54px;
        }
        #t46_3 {
          left: 632px;
          bottom: 698px;
          letter-spacing: -0.14px;
          word-spacing: 0.59px;
        }
        #t47_3 {
          left: 632px;
          bottom: 687px;
          letter-spacing: -0.13px;
          word-spacing: 1.35px;
        }
        #t48_3 {
          left: 810px;
          bottom: 687px;
          letter-spacing: -0.14px;
          word-spacing: 1.35px;
        }
        #t49_3 {
          left: 632px;
          bottom: 675px;
          letter-spacing: -0.14px;
          word-spacing: 1.02px;
        }
        #t4a_3 {
          left: 632px;
          bottom: 664px;
          letter-spacing: -0.14px;
        }
        #t4b_3 {
          left: 632px;
          bottom: 653px;
          letter-spacing: -0.11px;
        }
        #t4c_3 {
          left: 653px;
          bottom: 653px;
          letter-spacing: -0.19px;
          word-spacing: 0.19px;
        }
        #t4d_3 {
          left: 970px;
          bottom: 653px;
        }
        #t4e_3 {
          left: 979px;
          bottom: 653px;
          letter-spacing: -0.13px;
          word-spacing: 0.2px;
        }
        #t4f_3 {
          left: 632px;
          bottom: 641px;
          letter-spacing: -0.15px;
          word-spacing: 1.78px;
        }
        #t4g_3 {
          left: 632px;
          bottom: 630px;
          letter-spacing: -0.13px;
          word-spacing: 0.4px;
        }
        #t4h_3 {
          left: 632px;
          bottom: 619px;
          letter-spacing: -0.16px;
          word-spacing: 4.27px;
        }
        #t4i_3 {
          left: 632px;
          bottom: 607px;
          letter-spacing: -0.15px;
          word-spacing: 3.86px;
        }
        #t4j_3 {
          left: 632px;
          bottom: 596px;
          letter-spacing: -0.14px;
          word-spacing: 0.83px;
        }
        #t4k_3 {
          left: 632px;
          bottom: 585px;
          letter-spacing: -0.15px;
          word-spacing: 2.06px;
        }
        #t4l_3 {
          left: 632px;
          bottom: 573px;
          letter-spacing: -0.14px;
          word-spacing: 1.68px;
        }
        #t4m_3 {
          left: 759px;
          bottom: 573px;
          letter-spacing: -0.14px;
          word-spacing: 1.68px;
        }
        #t4n_3 {
          left: 632px;
          bottom: 562px;
          letter-spacing: -0.14px;
        }
        #t4o_3 {
          left: 632px;
          bottom: 551px;
          letter-spacing: -0.11px;
        }
        #t4p_3 {
          left: 654px;
          bottom: 551px;
          letter-spacing: -0.19px;
          word-spacing: 0.2px;
        }
        #t4q_3 {
          left: 746px;
          bottom: 551px;
        }
        #t4r_3 {
          left: 755px;
          bottom: 551px;
          letter-spacing: -0.13px;
          word-spacing: 0.21px;
        }
        #t4s_3 {
          left: 632px;
          bottom: 539px;
          letter-spacing: -0.14px;
          word-spacing: 1.49px;
        }
        #t4t_3 {
          left: 632px;
          bottom: 528px;
          letter-spacing: -0.15px;
          word-spacing: 2.89px;
        }
        #t4u_3 {
          left: 632px;
          bottom: 517px;
          letter-spacing: -0.14px;
          word-spacing: 2.13px;
        }
        #t4v_3 {
          left: 1194px;
          bottom: 517px;
          letter-spacing: -0.09px;
        }
        #t4w_3 {
          left: 632px;
          bottom: 505px;
          letter-spacing: -0.14px;
          word-spacing: 0.23px;
        }
        #t4x_3 {
          left: 632px;
          bottom: 494px;
          letter-spacing: -0.13px;
          word-spacing: 1.79px;
        }
        #t4y_3 {
          left: 632px;
          bottom: 482px;
          letter-spacing: -0.16px;
        }
        #t4z_3 {
          left: 632px;
          bottom: 471px;
          letter-spacing: -0.11px;
        }
        #t50_3 {
          left: 653px;
          bottom: 471px;
          letter-spacing: -0.2px;
        }
        #t51_3 {
          left: 632px;
          bottom: 460px;
          letter-spacing: -0.09px;
        }
        #t52_3 {
          left: 653px;
          bottom: 460px;
          letter-spacing: -0.14px;
          word-spacing: 1.09px;
        }
        #t53_3 {
          left: 632px;
          bottom: 448px;
          letter-spacing: -0.14px;
          word-spacing: 1.24px;
        }
        #t54_3 {
          left: 858px;
          bottom: 448px;
          letter-spacing: -0.14px;
          word-spacing: 1.24px;
        }
        #t55_3 {
          left: 632px;
          bottom: 437px;
          letter-spacing: -0.13px;
        }
        #t56_3 {
          left: 632px;
          bottom: 426px;
          letter-spacing: -0.09px;
        }
        #t57_3 {
          left: 652px;
          bottom: 426px;
          letter-spacing: -0.14px;
        }
        #t58_3 {
          left: 724px;
          bottom: 426px;
          letter-spacing: -0.13px;
          word-spacing: 0.54px;
        }
        #t59_3 {
          left: 632px;
          bottom: 414px;
          letter-spacing: -0.14px;
          word-spacing: 0.57px;
        }
        #t5a_3 {
          left: 984px;
          bottom: 414px;
          letter-spacing: -0.14px;
          word-spacing: 0.57px;
        }
        #t5b_3 {
          left: 632px;
          bottom: 403px;
          letter-spacing: -0.12px;
        }
        #t5c_3 {
          left: 632px;
          bottom: 392px;
          letter-spacing: -0.09px;
        }
        #t5d_3 {
          left: 657px;
          bottom: 392px;
          letter-spacing: -0.14px;
          word-spacing: 3.04px;
        }
        #t5e_3 {
          left: 787px;
          bottom: 392px;
          letter-spacing: -0.15px;
          word-spacing: 3.04px;
        }
        #t5f_3 {
          left: 632px;
          bottom: 380px;
          letter-spacing: -0.14px;
          word-spacing: 3.48px;
        }
        #t5g_3 {
          left: 632px;
          bottom: 369px;
          letter-spacing: -0.14px;
          word-spacing: 1px;
        }
        #t5h_3 {
          left: 632px;
          bottom: 358px;
          letter-spacing: -0.13px;
        }
        #t5i_3 {
          left: 632px;
          bottom: 346px;
          letter-spacing: -0.09px;
        }
        #t5j_3 {
          left: 653px;
          bottom: 346px;
          letter-spacing: -0.14px;
          word-spacing: 0.94px;
        }
        #t5k_3 {
          left: 748px;
          bottom: 346px;
          letter-spacing: -0.14px;
          word-spacing: 0.94px;
        }
        #t5l_3 {
          left: 632px;
          bottom: 335px;
          letter-spacing: -0.14px;
          word-spacing: 0.99px;
        }
        #t5m_3 {
          left: 632px;
          bottom: 324px;
          letter-spacing: -0.14px;
          word-spacing: 0.38px;
        }
        #t5n_3 {
          left: 632px;
          bottom: 312px;
          letter-spacing: -0.14px;
        }
        #t5o_3 {
          left: 632px;
          bottom: 301px;
          letter-spacing: -0.09px;
        }
        #t5p_3 {
          left: 653px;
          bottom: 301px;
          letter-spacing: -0.14px;
          word-spacing: 1.06px;
        }
        #t5q_3 {
          left: 737px;
          bottom: 301px;
          letter-spacing: -0.13px;
          word-spacing: 1.06px;
        }
        #t5r_3 {
          left: 632px;
          bottom: 289px;
          letter-spacing: -0.13px;
          word-spacing: 0.75px;
        }
        #t5s_3 {
          left: 632px;
          bottom: 278px;
          letter-spacing: -0.14px;
          word-spacing: 1.4px;
        }
        #t5t_3 {
          left: 632px;
          bottom: 267px;
          letter-spacing: -0.14px;
          word-spacing: 0.65px;
        }
        #t5u_3 {
          left: 632px;
          bottom: 255px;
          letter-spacing: -0.14px;
          word-spacing: 1.97px;
        }
        #t5v_3 {
          left: 632px;
          bottom: 244px;
          letter-spacing: -0.12px;
        }
        #t5w_3 {
          left: 682px;
          bottom: 244px;
          letter-spacing: -0.14px;
          word-spacing: 1.44px;
        }
        #t5x_3 {
          left: 632px;
          bottom: 233px;
          letter-spacing: -0.13px;
          word-spacing: 0.39px;
        }
        #t5y_3 {
          left: 632px;
          bottom: 221px;
          letter-spacing: -0.13px;
          word-spacing: 1.15px;
        }
        #t5z_3 {
          left: 1048px;
          bottom: 221px;
          letter-spacing: -0.14px;
          word-spacing: 1.15px;
        }
        #t60_3 {
          left: 632px;
          bottom: 210px;
          letter-spacing: -0.14px;
          word-spacing: 0.37px;
        }
        #t61_3 {
          left: 632px;
          bottom: 199px;
          letter-spacing: -0.14px;
          word-spacing: 2.45px;
        }
        #t62_3 {
          left: 632px;
          bottom: 187px;
          letter-spacing: -0.14px;
          word-spacing: 1.21px;
        }
        #t63_3 {
          left: 632px;
          bottom: 176px;
          letter-spacing: -0.13px;
          word-spacing: 0.21px;
        }
        #t64_3 {
          left: 632px;
          bottom: 165px;
          letter-spacing: -0.15px;
          word-spacing: 2.1px;
        }
        #t65_3 {
          left: 1044px;
          bottom: 165px;
          letter-spacing: -0.14px;
          word-spacing: 2.1px;
        }
        #t66_3 {
          left: 632px;
          bottom: 153px;
          letter-spacing: -0.14px;
          word-spacing: 3.19px;
        }
        #t67_3 {
          left: 1185px;
          bottom: 153px;
          letter-spacing: -0.15px;
        }
        #t68_3 {
          left: 632px;
          bottom: 142px;
          letter-spacing: -0.19px;
          word-spacing: 1.25px;
        }
        #t69_3 {
          left: 632px;
          bottom: 131px;
          letter-spacing: -0.18px;
        }
        #t6a_3 {
          left: 632px;
          bottom: 119px;
          letter-spacing: -0.07px;
        }
        #t6b_3 {
          left: 649px;
          bottom: 119px;
          letter-spacing: -0.12px;
          word-spacing: 0.57px;
        }
        #t6c_3 {
          left: 712px;
          bottom: 119px;
          letter-spacing: -0.13px;
          word-spacing: 0.57px;
        }
        #t6d_3 {
          left: 632px;
          bottom: 108px;
          letter-spacing: -0.14px;
        }
        #t6e_3 {
          left: 632px;
          bottom: 97px;
          letter-spacing: -0.09px;
        }
        #t6f_3 {
          left: 653px;
          bottom: 97px;
          letter-spacing: -0.14px;
          word-spacing: 1.07px;
        }
        #t6g_3 {
          left: 737px;
          bottom: 97px;
          letter-spacing: -0.14px;
          word-spacing: 1.07px;
        }
        #t6h_3 {
          left: 632px;
          bottom: 85px;
          letter-spacing: -0.13px;
          word-spacing: 0.03px;
        }
        #t6i_3 {
          left: 632px;
          bottom: 74px;
          letter-spacing: -0.09px;
        }
        #t6j_3 {
          left: 654px;
          bottom: 74px;
          letter-spacing: -0.14px;
          word-spacing: 1.29px;
        }
        #t6k_3 {
          left: 632px;
          bottom: 62px;
          letter-spacing: -0.13px;
          word-spacing: 0.63px;
        }
        #t6l_3 {
          left: 632px;
          bottom: 51px;
          letter-spacing: -0.13px;
        }
        #t6m_3 {
          left: 1116px;
          bottom: 29px;
          letter-spacing: -0.15px;
        }

        .s1 {
          font-size: 11px;
          font-family: sub_ArialMT_lsr;
          color: #000;
        }
        .s2 {
          font-size: 11px;
          font-family: sub_Arial-BoldMT_lsb;
          color: #000;
        }
        .s3 {
          font-size: 11px;
          font-family: ArialMT_l;
          color: #000;
        }
        .s4 {
          font-size: 11px;
          font-family: sub_ArialMT_lsr;
          color: #0c0ce9;
        }
        .s5 {
          font-size: 12px;
          font-family: sub_Arial-BoldMT_lsb;
          color: #000;
        }
        .s6 {
          font-size: 11px;
          font-family: sub_ArialMT_lsr;
          color: #0c0cf4;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts3" type="text/css">
        @font-face {
          font-family: ArialMT_l;
          src: url("fonts/ArialMT_l.woff") format("woff");
        }

        @font-face {
          font-family: sub_Arial-BoldMT_lsb;
          src: url("fonts/sub_Arial-BoldMT_lsb.woff") format("woff");
        }

        @font-face {
          font-family: sub_ArialMT_lsr;
          src: url("fonts/sub_ArialMT_lsr.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg3Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg3" style="-webkit-user-select: none">
        <object
          width="1286"
          height="909"
          data="3/3.svg"
          type="image/svg+xml"
          id="pdf3"
          style="
            width: 1286px;
            height: 909px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_3" class="t s1"
          >extent any of the foregoing is proximately caused either by a
          defective Product, or by the negligent or willful acts or
        </span>
        <span id="t2_3" class="t s1"
          >omissions of Seller or its officers, employees, subcontractors or
          agents; and (iii) based on Sellers breach of the Terms,
        </span>
        <span id="t3_3" class="t s1"
          >including but not limited to any breach of the warranties provided in
          Section 4 (including any failure of the Product or the
        </span>
        <span id="t4_3" class="t s1"
          >manufacture thereof to comply with the RoHS, WEEE, REACH or other
          specified environmental compliance). </span
        ><span id="t5_3" class="t s1">Seller </span>
        <span id="t6_3" class="t s1"
          >agrees upon receipt of notification from Buyer to provide and to
          promptly assume responsibility for defense of any claim,
        </span>
        <span id="t7_3" class="t s1"
          >suit or proceeding which may be alleged or brought against the
          Buyer-Indemnified Parties. </span
        ><span id="t8_3" class="t s1">At its option, Buyer may be </span>
        <span id="t9_3" class="t s1"
          >represented by and actively participate, through its own counsel in
          such Claim at Buyer's cost. </span
        ><span id="ta_3" class="t s1">Seller shall not settle any </span>
        <span id="tb_3" class="t s1"
          >Claim without Buyers written approval, which approval shall not be
          unreasonably withheld. </span
        ><span id="tc_3" class="t s1">The rights pursuant to this </span>
        <span id="td_3" class="t s1"
          >Section are in addition to any other rights (of indemnification or
          otherwise) provided by law.
        </span>
        <span id="te_3" class="t s1">8. </span
        ><span id="tf_3" class="t s2">TERMINATION AND CANCELLATION </span
        ><span id="tg_3" class="t s1">. </span>
        <span id="th_3" class="t s1">(a) </span
        ><span id="ti_3" class="t s1"
          >Buyer shall have the right to terminate this Order or any portion
          thereof "for cause" and without any liability to Buyer
        </span>
        <span id="tj_3" class="t s1"
          >(i) in the event Seller breaches any of these Terms or (ii) Buyer
          believes in good faith that Seller will be unable to perform
        </span>
        <span id="tk_3" class="t s1"
          >its obligations hereunder, requests that the Seller give it adequate
          assurances of performance, and Seller fails to do so
        </span>
        <span id="tl_3" class="t s1">within five business days. </span
        ><span id="tm_3" class="t s1"
          >In addition, this Order shall automatically terminate for cause and
          without liability to Buyer in
        </span>
        <span id="tn_3" class="t s1"
          >the event Seller assigns substantially all of its assets to a third
          party for the benefit of its creditors, files for bankruptcy or
        </span>
        <span id="to_3" class="t s1"
          >has a bankruptcy proceeding filed against it which proceeding is not
          dismissed within sixty days after filing. </span
        ><span id="tp_3" class="t s1">Seller shall </span>
        <span id="tq_3" class="t s1"
          >indemnify the Buyer-Indemnified Parties for all Claims resulting from
          Buyers termination for cause, including the costs of
        </span>
        <span id="tr_3" class="t s1"
          >transferring production to a third party, the procurement of
          substitute product, line down charges and any other costs
        </span>
        <span id="ts_3" class="t s1">incurred by Buyer. </span>
        <span id="tt_3" class="t s1">(b) </span
        ><span id="tu_3" class="t s1"
          >Buyer shall have the right to terminate the Order or any portion
          thereof for its convenience. </span
        ><span id="tv_3" class="t s1">In the event of such a </span>
        <span id="tw_3" class="t s1"
          >termination, Seller shall immediately stop all affected work
          hereunder, place no further orders for materials to complete
        </span>
        <span id="tx_3" class="t s1"
          >the affected work, and observe any instructions by Buyer as to work
          in process. </span
        ><span id="ty_3" class="t s1"
          >In addition, if requested by Buyer, Seller
        </span>
        <span id="tz_3" class="t s1"
          >will assign to Buyer all Sellers interests under any affected
          subcontracts and orders, settle all claims thereunder after
        </span>
        <span id="t10_3" class="t s1"
          >obtaining Buyers written approval, protect all property in which
          Buyer has or may acquire an interest, and transfer title
        </span>
        <span id="t11_3" class="t s1"
          >and make delivery to Buyer of all articles, materials, work in
          process, and other things held or acquired by Seller in
        </span>
        <span id="t12_3" class="t s1"
          >connection with the terminated portion of this Order. Seller will
          proceed promptly to comply with Buyers instructions
        </span>
        <span id="t13_3" class="t s1"
          >respecting each of the foregoing without awaiting settlement or
          payment of its termination claim. </span
        ><span id="t14_3" class="t s1">Within thirty (30) days </span>
        <span id="t15_3" class="t s1"
          >after such termination, Seller may submit to Buyer its written claim
          for termination charges, in the form and with the
        </span>
        <span id="t16_3" class="t s1">certifications prescribed by Buyer. </span
        ><span id="t17_3" class="t s1"
          >Sellers failure to submit the claim within thirty (30) days after
          termination shall
        </span>
        <span id="t18_3" class="t s1"
          >constitute a waiver of such claim and a release of any potential
          Buyer liability arising out of the termination. </span
        ><span id="t19_3" class="t s1">Unless </span>
        <span id="t1a_3" class="t s1"
          >otherwise agreed, the termination charges will be limited to (i) the
          contract price, for Product completed (or services
        </span>
        <span id="t1b_3" class="t s1"
          >rendered) in accordance with this Order and not previously paid for
          (provided that the Product was not manufactured in
        </span>
        <span id="t1c_3" class="t s1"
          >advance of the leadtime previously approved by Buyer); and (ii) the
          cost of the components plus a reasonable charge for
        </span>
        <span id="t1d_3" class="t s1"
          >the labor expended for all work in process (provided that the work
          was not performed in advance of the leadtime
        </span>
        <span id="t1e_3" class="t s1"
          >previously approved by Buyer); and (iii) reasonable cancellation
          charges paid to third party suppliers, provided that
        </span>
        <span id="t1f_3" class="t s1"
          >Seller has obtained Buyers prior written approval. </span
        ><span id="t1g_3" class="t s1"
          >Payments under Sections (i)-(iii) above may not exceed the
        </span>
        <span id="t1h_3" class="t s1"
          >aggregate price specified in this Order less payment otherwise made
          or to be made. </span
        ><span id="t1i_3" class="t s1">Any amounts payable by Seller to </span>
        <span id="t1j_3" class="t s1"
          >Buyer for property lost, damaged, stolen or destroyed prior to
          delivery to Buyer will be excluded from amounts otherwise
        </span>
        <span id="t1k_3" class="t s1"
          >payable to Seller under this Section.
        </span>
        <span id="t1l_3" class="t s1">(c) </span
        ><span id="t1m_3" class="t s1"
          >In the event a court determines that Buyer has improperly terminated
          this Order or a portion for cause, such
        </span>
        <span id="t1n_3" class="t s1"
          >termination shall be deemed to be for Buyers convenience.
        </span>
        <span id="t1o_3" class="t s1">9. </span
        ><span id="t1p_3" class="t s2">U.S. GOVERNMENT PROVISIONS. </span
        ><span id="t1q_3" class="t s2">(a) </span
        ><span id="t1r_3" class="t s1"
          >If required, Seller shall comply with Section 12 of the Federal
          Acquisition
        </span>
        <span id="t1s_3" class="t s1"
          >Regulations ("FAR") (Acquisition of Commercial Items), and all such
          regulations (including the regulations set forth in
        </span>
        <span id="t1t_3" class="t s1"
          >FAR 53.301) are hereby incorporated by reference into this Order. </span
        ><span id="t1u_3" class="t s1"
          >In the alternative, if a contract number is shown on
        </span>
        <span id="t1v_3" class="t s1"
          >the face hereof, the following special terms and conditions shall
          apply: (1) all applicable provisions of any contract
        </span>
        <span id="t1w_3" class="t s1"
          >between Buyer and the government prime contractor or subcontractor,
          and all statutes regulations, orders or similar
        </span>
        <span id="t1x_3" class="t s1"
          >Government contracting provisions which by law or regulation are
          required to be made a part of the Order, are
        </span>
        <span id="t1y_3" class="t s1"
          >incorporated herein by reference, and shall be flowed down by Seller
          to any subcontractor, sub-supplier or
        </span>
        <span id="t1z_3" class="t s1"
          >sub-manufacturer being utilized by Seller. </span
        ><span id="t20_3" class="t s1"
          >By accepting this Order, Seller assumes toward the government, prime
        </span>
        <span id="t21_3" class="t s1"
          >contractor and subcontractor the same obligations toward Buyer that
          Buyer has assumed in the contract or subcontract
        </span>
        <span id="t22_3" class="t s1"
          >with the government, prime contractor or subcontractor, and (2) any
          additional government contracting provisions of
        </span>
        <span id="t23_3" class="t s1"
          >which Buyer has advised Seller shall be incorporated herein by
          reference.
        </span>
        <span id="t24_3" class="t s2"
          >(b) Buyer is an equal opportunity employer and federal contractor. To
          the extent applicable, the parties will comply
        </span>
        <span id="t25_3" class="t s2"
          >with the following laws, which are incorporated herein by reference:
          41 CFR 60-300.5(a), 41 CFR 60-1.4(a), and 41
        </span>
        <span id="t26_3" class="t s2"
          >CFR 60-741.5(a). These Regulations prohibit discrimination against
          qualified individuals based on their status as
        </span>
        <span id="t27_3" class="t s2"
          >protected veterans and individuals with disabilities, and prohibit
          discrimination against all individuals based on
        </span>
        <span id="t28_3" class="t s2"
          >their race, color, religion, sex, sexual orientation, gender identity
          or national origin. These regulations require
        </span>
        <span id="t29_3" class="t s2"
          >affirmative action by covered prime contractors and subcontractors to
          employ and advance in employment
        </span>
        <span id="t2a_3" class="t s2"
          >individuals without regard to race, color, religion, sex, sexual
          orientation, gender identity, national origin,
        </span>
        <span id="t2b_3" class="t s2"
          >protected veteran status or disability. The parties also agree that,
          as applicable, they will abide by the
        </span>
        <span id="t2c_3" class="t s2"
          >requirements of Executive Order 13496 (29 CFR Part 471, Appendix A to
          Subpart A), relating to the notice of
        </span>
        <span id="t2d_3" class="t s2"
          >employee rights under federal labor laws.
        </span>
        <span id="t2e_3" class="t s2">(c) </span
        ><span id="t2f_3" class="t s1"
          >Seller has been advised of the existence of the United States Foreign
          Corrupt Practices Act of 1977, as amended (15
        </span>
        <span id="t2g_3" class="t s1">U.S.C. § 78 </span
        ><span id="t2h_3" class="t s1"
          >et seq.) and acknowledges that it is familiar with the terms of the
          FCPA and agrees to comply with its terms.
        </span>
        <span id="t2i_3" class="t s2">(d) </span
        ><span id="t2j_3" class="t s1"
          >Seller agrees to conduct a reasonable country of origin inquiry
          relating to </span
        ><span id="t2k_3" class="t s3">“</span
        ><span id="t2l_3" class="t s1">conflict minerals</span
        ><span id="t2m_3" class="t s3">”</span
        ><span id="t2n_3" class="t s1">, as defined in Section </span>
        <span id="t2o_3" class="t s1"
          >1502 of the Dodd-Frank Act and its implementing regulations and shall
          comply with Buyers requests for information
        </span>
        <span id="t2p_3" class="t s1">about Sellers inquiry. </span>
        <span id="t2q_3" class="t s1">10. </span
        ><span id="t2r_3" class="t s2"
          >COMPLIANCE WITH BUYERS CODE OF CONDUCT</span
        ><span id="t2s_3" class="t s1">. </span
        ><span id="t2t_3" class="t s1"
          >Seller will comply with Buyers Business Code of Conduct
        </span>
        <span id="t2u_3" class="t s1">and </span
        ><span id="t2v_3" class="t s1">Ethics, </span
        ><span id="t2w_3" class="t s1">a </span
        ><span id="t2x_3" class="t s1">copy </span
        ><span id="t2y_3" class="t s1">of </span
        ><span id="t2z_3" class="t s1">which </span
        ><span id="t30_3" class="t s1">may </span
        ><span id="t31_3" class="t s1">be </span
        ><span id="t32_3" class="t s1">found </span
        ><span id="t33_3" class="t s1">at: </span>
        <span id="t34_3" class="t s4"
          >https://www.sanmina.com/wp-content/uploads/2016/04/code_of_conduct_ethics.pdf. </span
        ><span id="t35_3" class="t s1"
          >In particular, Seller shall not make
        </span>
        <span id="t36_3" class="t s1"
          >or offer a gratuity or gift of any kind to Buyers employees or their
          families that could be viewed as relating to an actual or
        </span>
        <span id="t37_3" class="t s1"
          >potential business relationship with Buyer. </span
        ><span id="t38_3" class="t s1"
          >Gifts include entertainment, personal services, favors, discounts and
          other
        </span>
        <span id="t39_3" class="t s1"
          >preferential treatment of any kind.
        </span>
        <span id="t3a_3" class="t s5">TERMS AND CONDITIONS </span
        ><span id="t3b_3" class="t s5">Page 2 of 2 </span>
        <span id="t3c_3" class="t s1">11. </span
        ><span id="t3d_3" class="t s2">LIMITATION </span
        ><span id="t3e_3" class="t s2">OF </span
        ><span id="t3f_3" class="t s2">LIABILITY</span
        ><span id="t3g_3" class="t s1">. </span
        ><span id="t3h_3" class="t s1"
          >IN NO EVENT SHALL BUYER BE LIABLE TO SELLER OR A THIRD PARTY FOR
        </span>
        <span id="t3i_3" class="t s1"
          >ANY SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES, WHETHER
          BASED UPON CONTRACT,
        </span>
        <span id="t3j_3" class="t s1"
          >TORT, OR ANY OTHER LEGAL THEORY (INCLUDING WITHOUT LIMITATION LOST
          PROFITS AND LOST
        </span>
        <span id="t3k_3" class="t s1">OPPORTUNITY). </span
        ><span id="t3l_3" class="t s1"
          >IN NO EVENT SHALL BUYER'S LIABILITY FOR ANY PRODUCT ORDERED HEREUNDER
        </span>
        <span id="t3m_3" class="t s1"
          >EXCEED THE PURCHASE PRICE OF THE PRODUCT. </span
        ><span id="t3n_3" class="t s1"
          >NOTHING HEREIN SHALL BE CONSTRUED TO LIMIT THE
        </span>
        <span id="t3o_3" class="t s1"
          >LIABILITY OF SELLER UNDER THE ORDER.
        </span>
        <span id="t3p_3" class="t s1">12. </span
        ><span id="t3q_3" class="t s2">CONFIDENTIALITY</span
        ><span id="t3r_3" class="t s1">. </span
        ><span id="t3s_3" class="t s1"
          >Seller agrees to keep information provided by Buyer confidential in
          accordance with the terms
        </span>
        <span id="t3t_3" class="t s1"
          >and conditions of the nondisclosure agreement previously executed
          between the parties, or, in the event the parties have
        </span>
        <span id="t3u_3" class="t s1"
          >not previously executed a nondisclosure agreement, in accordance with
          the terms and conditions of Buyers standard
        </span>
        <span id="t3v_3" class="t s1"
          >Vendor Nondisclosure Agreement located at </span
        ><span id="t3w_3" class="t s6"
          >http://www.sanmina.com/wp-content/uploads/2016/04/nda.pdf</span
        ><span id="t3x_3" class="t s1">. </span
        ><span id="t3y_3" class="t s1">In addition, </span>
        <span id="t3z_3" class="t s1"
          >the parties agree that the prices at which Seller sells the Product
          to Buyer shall be kept strictly confidential.
        </span>
        <span id="t40_3" class="t s1">13. </span
        ><span id="t41_3" class="t s2">BUYER </span
        ><span id="t42_3" class="t s2">FURNISHED PROPERTY</span
        ><span id="t43_3" class="t s1">. </span
        ><span id="t44_3" class="t s1"
          >All products, tools, equipment and other materials furnished by Buyer
          for use in
        </span>
        <span id="t45_3" class="t s1"
          >the performance of this Order ("Equipment") shall remain the property
          of the Buyer, shall be used by Seller solely in the
        </span>
        <span id="t46_3" class="t s1"
          >performance of this Order, and shall be returned to Buyer within
          three (3) business days after completion or termination
        </span>
        <span id="t47_3" class="t s1">of this Order or of Buyers request. </span
        ><span id="t48_3" class="t s1"
          >Seller shall maintain, with a reputable insurance company, sufficient
          insurance to
        </span>
        <span id="t49_3" class="t s1"
          >cover the replacement cost of the Equipment, which policy shall name
          Buyer as an additional insured and loss payee.
        </span>
        <span id="t4a_3" class="t s1"
          >Upon request from Buyer, Seller shall provide evidence of such
          insurance.
        </span>
        <span id="t4b_3" class="t s1">14. </span
        ><span id="t4c_3" class="t s2"
          >BUYERS PROTECTION FOR WORK PERFORMED AT ITS SITE</span
        ><span id="t4d_3" class="t s1">. </span
        ><span id="t4e_3" class="t s1"
          >Seller shall take all steps as may be reasonably
        </span>
        <span id="t4f_3" class="t s1"
          >necessary to prevent personal injury or property damage during any
          work hereunder that may be performed by any
        </span>
        <span id="t4g_3" class="t s1"
          >employees, agents or subcontractors of Seller at Buyers site
          (including, without limitation, installation of equipment), and
        </span>
        <span id="t4h_3" class="t s1"
          >Seller shall indemnify and hold harmless Buyer from and against
          Claims from any such employee, agent or
        </span>
        <span id="t4i_3" class="t s1"
          >subcontractor, and Seller shall maintain such insurance against
          public liability and property damage, and such
        </span>
        <span id="t4j_3" class="t s1"
          >employees liability and compensation insurance as will protect Buyer
          against the aforementioned risks and against any
        </span>
        <span id="t4k_3" class="t s1"
          >claims under any Workers Compensation and Occupational Safety and
          Health Acts and any other applicable labor,
        </span>
        <span id="t4l_3" class="t s1">health and safety Laws. </span
        ><span id="t4m_3" class="t s1"
          >Sellers employees, agents, and subcontractors shall at all times
          conduct themselves in full
        </span>
        <span id="t4n_3" class="t s1"
          >compliance with Buyers safety and security regulations and shall
          immediately report to Buyer any accidents.
        </span>
        <span id="t4o_3" class="t s1">15. </span
        ><span id="t4p_3" class="t s2">PATENT LICENSE</span
        ><span id="t4q_3" class="t s1">. </span
        ><span id="t4r_3" class="t s1"
          >Seller, as consideration for this Order and without further cost to
          Buyer, hereby grants to Buyer
        </span>
        <span id="t4s_3" class="t s1"
          >(and, to the extent requested by Buyer, to the government) an
          irrevocable, non-exclusive, royalty-free license to use,
        </span>
        <span id="t4t_3" class="t s1"
          >have used, sell, have sold, manufacture, and cause to be manufactured
          products embodying any inventions and
        </span>
        <span id="t4u_3" class="t s1"
          >discoveries made, conceived, or actually reduced to practice in
          connection with the performance of this Order. </span
        ><span id="t4v_3" class="t s1">All </span>
        <span id="t4w_3" class="t s1"
          >intellectual property contained in any services performed for Buyer
          shall belong to the Buyer, and (at Buyers request and
        </span>
        <span id="t4x_3" class="t s1"
          >expense), Seller agrees to carry out all formalities to legally vest
          ownership of all such intellectual property rights in
        </span>
        <span id="t4y_3" class="t s1">Buyers name. </span>
        <span id="t4z_3" class="t s1">16. </span
        ><span id="t50_3" class="t s2">MISCELLANEOUS </span>
        <span id="t51_3" class="t s1">(a) </span
        ><span id="t52_3" class="t s1"
          >Waiver. No waiver of any breach of this Order or the terms and
          conditions thereof by Buyer shall be held to be a
        </span>
        <span id="t53_3" class="t s1"
          >waiver of any other or a subsequent breach. </span
        ><span id="t54_3" class="t s1"
          >All rights and remedies afforded Buyer in this Order shall be taken
          and
        </span>
        <span id="t55_3" class="t s1"
          >construed as cumulative, that is, in addition to every other right
          and remedy provided under the Order or by law.
        </span>
        <span id="t56_3" class="t s1">(b) </span
        ><span id="t57_3" class="t s1">Assignments. </span
        ><span id="t58_3" class="t s1"
          >No right or obligation under this Order may be assigned by Seller
          without the prior written consent of
        </span>
        <span id="t59_3" class="t s1"
          >Buyer, and any purported assignment without such consent will be
          void. </span
        ><span id="t5a_3" class="t s1"
          >Buyer may assign this Order at any time upon
        </span>
        <span id="t5b_3" class="t s1">notice to Seller. </span>
        <span id="t5c_3" class="t s1">(c) </span
        ><span id="t5d_3" class="t s1">Independent Contractor. </span
        ><span id="t5e_3" class="t s1"
          >The relationship of Buyer and Seller established by this purchase
          order is that of
        </span>
        <span id="t5f_3" class="t s1"
          >independent contractors and nothing herein shall constitute the
          parties as partner, joint venturers, co-owners or
        </span>
        <span id="t5g_3" class="t s1"
          >otherwise as participants in a common undertaking or allow either
          party to create any obligation on behalf of the other
        </span>
        <span id="t5h_3" class="t s1">party. </span>
        <span id="t5i_3" class="t s1">(d) </span
        ><span id="t5j_3" class="t s1">Entire Agreement. </span
        ><span id="t5k_3" class="t s1"
          >These Terms set forth the entire agreement between parties with
          respect to the subject matter
        </span>
        <span id="t5l_3" class="t s1"
          >hereof and supersedes all prior agreements and discussions between
          them. No modification or amendment hereof will
        </span>
        <span id="t5m_3" class="t s1"
          >be effective unless in writing and signed by a duly authorized
          representative of each party. Any terms and conditions set
        </span>
        <span id="t5n_3" class="t s1"
          >forth in any order confirmation or acknowledgment or any other
          documents shall be of no force or effect whatsoever.
        </span>
        <span id="t5o_3" class="t s1">(e) </span
        ><span id="t5p_3" class="t s1">Applicable Law. </span
        ><span id="t5q_3" class="t s1"
          >If all of the parties to this Order have principle places of business
          in the United States, the state
        </span>
        <span id="t5r_3" class="t s1"
          >courts of Santa Clara County, California and the federal courts
          located in the Northern District of the State of California
        </span>
        <span id="t5s_3" class="t s1"
          >shall have exclusive jurisdiction and venue to adjudicate any and all
          disputes arising out of or in connection with this
        </span>
        <span id="t5t_3" class="t s1"
          >Order. The parties consent to the exercise by such courts of personal
          jurisdiction over them and each party waives any
        </span>
        <span id="t5u_3" class="t s1"
          >objection it might otherwise have to venue, personal jurisdiction,
          inconvenience of forum, and any similar or related
        </span>
        <span id="t5v_3" class="t s1">doctrine. </span
        ><span id="t5w_3" class="t s1"
          >If any of the parties to this Order has its principle place of
          business outside the United States, any and all
        </span>
        <span id="t5x_3" class="t s1"
          >disputes arising out of or in connection with this Order shall be
          decided by arbitration in San Jose, California pursuant to
        </span>
        <span id="t5y_3" class="t s1"
          >the International Arbitration Rules of the International Centre for
          Dispute Resolution. </span
        ><span id="t5z_3" class="t s1">Arbitration may be compelled by </span>
        <span id="t60_3" class="t s1"
          >the Santa Clara County Superior Court pursuant to the California
          Arbitration Act, and the parties consent to the personal
        </span>
        <span id="t61_3" class="t s1"
          >jurisdiction of the Santa Clara County Superior Court to enforce this
          agreement to arbitrate. Any arbitration award
        </span>
        <span id="t62_3" class="t s1"
          >hereunder must be (1) issued as a reasoned award or it will be of no
          force or effect and will be deemed an award in
        </span>
        <span id="t63_3" class="t s1"
          >excess of the arbitrators jurisdiction, and (2) subject to plenary
          review on appeal to the same extent that review would be
        </span>
        <span id="t64_3" class="t s1"
          >available if the award were rendered by the Santa Clara County
          Superior Court. </span
        ><span id="t65_3" class="t s1">This Order shall be construed in </span>
        <span id="t66_3" class="t s1"
          >accordance with the substantive laws of the State of California
          (excluding its conflicts of laws principles). </span
        ><span id="t67_3" class="t s1">THE </span>
        <span id="t68_3" class="t s1"
          >PROVISIONS OF THE UNITED NATIONS CONVENTIONS ON CONTRACTS FOR THE
          INTERNATIONAL SALE OF
        </span>
        <span id="t69_3" class="t s1"
          >GOODS SHALL NOT APPLY TO THIS ORDER.
        </span>
        <span id="t6a_3" class="t s1">(f) </span
        ><span id="t6b_3" class="t s1">End of Life. </span
        ><span id="t6c_3" class="t s1"
          >Seller shall provide Buyer with a written one hundred eighty (180)
          day notice of its intent to discontinue
        </span>
        <span id="t6d_3" class="t s1"
          >the sale of any Product in order that Buyer, at Buyers option, may
          make an end of life purchase.
        </span>
        <span id="t6e_3" class="t s1">(g) </span
        ><span id="t6f_3" class="t s1">Attorneys Fees. </span
        ><span id="t6g_3" class="t s1"
          >The prevailing party shall be entitled to recover its costs and
          reasonable attorneys fees from the
        </span>
        <span id="t6h_3" class="t s1"
          >non-prevailing party in any action brought to enforce this Order.
        </span>
        <span id="t6i_3" class="t s1">(h) </span
        ><span id="t6j_3" class="t s1"
          >C-TPAT. Supplier represents that it (i) is currently U.S.
          Customs-Trade Partnership Against Terrorism ("C-TPAT")
        </span>
        <span id="t6k_3" class="t s1"
          >certified or (ii) shall make commercially reasonable efforts to work
          towards C-TPAT certification, or (iii) is in the process
        </span>
        <span id="t6l_3" class="t s1">of obtaining C-TPAT certification. </span>
        <span id="t6m_3" class="t s1">Rev. May 10, 2018 </span>
      </div>
      <!-- End text definitions -->
    </div>
  </body>
</html>
  `,
  '84231b-20230223_4500773830_BESI_rev1': `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
    <meta charset="utf-8" />
  </head>

  <body style="margin: 0">
    <div
      id="p1"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1286px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_1 {
          left: 87px;
          bottom: 1021px;
          letter-spacing: 0.16px;
        }
        #t2_1 {
          left: 87px;
          bottom: 1003px;
          letter-spacing: 0.13px;
          word-spacing: 0.01px;
        }
        #t3_1 {
          left: 87px;
          bottom: 985px;
          letter-spacing: 0.12px;
        }
        #t4_1 {
          left: 87px;
          bottom: 966px;
          letter-spacing: 0.15px;
          word-spacing: 0.02px;
        }
        #t5_1 {
          left: 774px;
          bottom: 741px;
          letter-spacing: 0.12px;
          word-spacing: 0.05px;
        }
        #t6_1 {
          left: 87px;
          bottom: 699px;
          letter-spacing: 0.12px;
        }
        #t7_1 {
          left: 238px;
          bottom: 704px;
          letter-spacing: 0.11px;
        }
        #t8_1 {
          left: 507px;
          bottom: 699px;
          letter-spacing: 0.11px;
        }
        #t9_1 {
          left: 658px;
          bottom: 704px;
          letter-spacing: 0.11px;
        }
        #ta_1 {
          left: 87px;
          bottom: 678px;
          letter-spacing: 0.1px;
        }
        #tb_1 {
          left: 238px;
          bottom: 683px;
          letter-spacing: 0.11px;
        }
        #tc_1 {
          left: 507px;
          bottom: 678px;
          letter-spacing: 0.12px;
        }
        #td_1 {
          left: 658px;
          bottom: 683px;
          letter-spacing: 0.11px;
        }
        #te_1 {
          left: 87px;
          bottom: 656px;
          letter-spacing: 0.12px;
        }
        #tf_1 {
          left: 238px;
          bottom: 662px;
          letter-spacing: 0.11px;
        }
        #tg_1 {
          left: 507px;
          bottom: 656px;
          letter-spacing: 0.11px;
        }
        #th_1 {
          left: 658px;
          bottom: 662px;
          letter-spacing: 0.09px;
        }
        #ti_1 {
          left: 689px;
          bottom: 662px;
          letter-spacing: 0.1px;
        }
        #tj_1 {
          left: 87px;
          bottom: 635px;
          letter-spacing: 0.11px;
        }
        #tk_1 {
          left: 238px;
          bottom: 640px;
          letter-spacing: 0.12px;
        }
        #tl_1 {
          left: 507px;
          bottom: 635px;
          letter-spacing: 0.12px;
        }
        #tm_1 {
          left: 658px;
          bottom: 640px;
          letter-spacing: 0.09px;
          word-spacing: 0.01px;
        }
        #tn_1 {
          left: 87px;
          bottom: 613px;
          letter-spacing: 0.11px;
        }
        #to_1 {
          left: 238px;
          bottom: 619px;
          letter-spacing: 0.13px;
        }
        #tp_1 {
          left: 507px;
          bottom: 613px;
          letter-spacing: 0.11px;
        }
        #tq_1 {
          left: 658px;
          bottom: 619px;
          letter-spacing: 0.11px;
        }
        #tr_1 {
          left: 87px;
          bottom: 592px;
          letter-spacing: 0.11px;
          word-spacing: 0.01px;
        }
        #ts_1 {
          left: 238px;
          bottom: 597px;
          letter-spacing: 0.11px;
        }
        #tt_1 {
          left: 87px;
          bottom: 533px;
          letter-spacing: 0.13px;
          word-spacing: 0.01px;
        }
        #tu_1 {
          left: 87px;
          bottom: 514px;
          letter-spacing: 0.12px;
          word-spacing: 0.02px;
        }
        #tv_1 {
          left: 87px;
          bottom: 496px;
          letter-spacing: 0.14px;
        }
        #tw_1 {
          left: 87px;
          bottom: 478px;
          letter-spacing: 0.12px;
          word-spacing: 0.01px;
        }
        #tx_1 {
          left: 87px;
          bottom: 459px;
          letter-spacing: 0.12px;
          word-spacing: 0.01px;
        }
        #ty_1 {
          left: 87px;
          bottom: 441px;
          letter-spacing: 0.12px;
        }
        #tz_1 {
          left: 87px;
          bottom: 423px;
          letter-spacing: 0.16px;
          word-spacing: 0.01px;
        }
        #t10_1 {
          left: 87px;
          bottom: 404px;
          letter-spacing: 0.16px;
        }
        #t11_1 {
          left: 95px;
          bottom: 327px;
          letter-spacing: 0.22px;
          word-spacing: 0.02px;
        }
        #t12_1 {
          left: 543px;
          bottom: 327px;
          letter-spacing: 0.22px;
        }
        #t13_1 {
          left: 87px;
          bottom: 287px;
          letter-spacing: 0.15px;
          word-spacing: 0.01px;
        }
        #t14_1 {
          left: 279px;
          bottom: 287px;
          letter-spacing: 0.16px;
          word-spacing: -0.01px;
        }
        #t15_1 {
          left: 544px;
          bottom: 287px;
          letter-spacing: 0.16px;
        }
        #t16_1 {
          left: 87px;
          bottom: 271px;
          letter-spacing: 0.1px;
        }
        #t17_1 {
          left: 6px;
          bottom: 0px;
          letter-spacing: 0.11px;
        }

        .s1_1 {
          font-size: 15px;
          font-family: Helvetica_1a;
          color: #000;
        }
        .s2_1 {
          font-size: 15px;
          font-family: Helvetica-Bold_1b;
          color: #000;
        }
        .s3_1 {
          font-size: 12px;
          font-family: Helvetica-Bold_1b;
          color: #000;
        }
        .s4_1 {
          font-size: 12px;
          font-family: Helvetica_1a;
          color: #000;
        }
        .s5_1 {
          font-size: 24px;
          font-family: Helvetica-Bold_1b;
          color: #000;
        }
        .s6_1 {
          font-size: 18px;
          font-family: Helvetica-Bold_1b;
          color: #000;
        }
        .s7_1 {
          font-size: 12px;
          font-family: Helvetica, Arial, sans-serif;
          color: #000;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts1" type="text/css">
        @font-face {
          font-family: Helvetica-Bold_1b;
          src: url("fonts/Helvetica-Bold_1b.woff") format("woff");
        }

        @font-face {
          font-family: Helvetica_1a;
          src: url("fonts/Helvetica_1a.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg1Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg1" style="-webkit-user-select: none">
        <object
          width="909"
          height="1286"
          data="1/1.svg"
          type="image/svg+xml"
          id="pdf1"
          style="
            width: 909px;
            height: 1286px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_1" class="t s1_1">Company </span>
        <span id="t2_1" class="t s1_1">Maxon Switzerland AG </span>
        <span id="t3_1" class="t s1_1">Industriestrasse 23 </span>
        <span id="t4_1" class="t s1_1">CH-6055 ALPNACH DORF </span>
        <span id="t5_1" class="t s2_1">page: 1 / 4 </span>
        <span id="t6_1" class="t s3_1">Date </span>
        <span id="t7_1" class="t s4_1">29.01.2023 </span>
        <span id="t8_1" class="t s3_1">Our reference </span>
        <span id="t9_1" class="t s4_1">Renuga-Devi </span>
        <span id="ta_1" class="t s3_1">General line / Ext. </span>
        <span id="tb_1" class="t s4_1">+60355147777/1605 </span>
        <span id="tc_1" class="t s3_1">Fax </span>
        <span id="td_1" class="t s4_1">+60351919632 </span>
        <span id="te_1" class="t s3_1">E-mail </span>
        <span id="tf_1" class="t s4_1">renuga-devi.r-ramasamy@besi.com </span>
        <span id="tg_1" class="t s3_1">Delivery terms </span>
        <span id="th_1" class="t s4_1">FCA </span
        ><span id="ti_1" class="t s4_1">Sachsein </span>
        <span id="tj_1" class="t s3_1">Delivery method </span>
        <span id="tk_1" class="t s4_1">DHL </span>
        <span id="tl_1" class="t s3_1">Payment terms </span>
        <span id="tm_1" class="t s4_1">Within 60 days due net </span>
        <span id="tn_1" class="t s3_1">Currency </span>
        <span id="to_1" class="t s4_1">CHF </span>
        <span id="tp_1" class="t s3_1">Supplier No </span>
        <span id="tq_1" class="t s4_1">302985 </span>
        <span id="tr_1" class="t s3_1">Our VAT # </span>
        <span id="ts_1" class="t s4_1">000212254720 </span>
        <span id="tt_1" class="t s2_1">Delivery Address: </span>
        <span id="tu_1" class="t s1_1">Besi APac Sdn Bhd </span>
        <span id="tv_1" class="t s1_1">@Glenmarie Site </span>
        <span id="tw_1" class="t s1_1"
          >No 22 &amp; 24, Jalan Pemaju U1/15,
        </span>
        <span id="tx_1" class="t s1_1">Section U1 </span>
        <span id="ty_1" class="t s1_1">Glenmarie Hicom Industrial Park </span>
        <span id="tz_1" class="t s1_1">40150 SHAH ALAM-SELANGOR </span>
        <span id="t10_1" class="t s1_1">MALAYSIA </span>
        <span id="t11_1" class="t s5_1">Purchase order no </span
        ><span id="t12_1" class="t s5_1">4500773830 </span>
        <span id="t13_1" class="t s6_1">We expect your order </span
        ><span id="t14_1" class="t s6_1">confirmation within the next 3 </span
        ><span id="t15_1" class="t s6_1">days! </span>
        <span id="t16_1" class="t s4_1"
          >The mentioned delivery date = The expected arrival date at BESI APac
          SDN. BHD.
        </span>
        <span id="t17_1" class="t s7_1"
          >Printed: 2023-04-11 11:01:11 Document: 9982502/en/01/Released
        </span>
      </div>
      <!-- End text definitions -->
    </div>

    <div
      id="p2"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1286px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_2 {
          left: 74px;
          bottom: 1014px;
          letter-spacing: 0.13px;
        }
        #t2_2 {
          left: 117px;
          bottom: 1014px;
          letter-spacing: 0.13px;
        }
        #t3_2 {
          left: 244px;
          bottom: 1014px;
          letter-spacing: 0.14px;
        }
        #t4_2 {
          left: 482px;
          bottom: 1014px;
          letter-spacing: 0.13px;
          word-spacing: 0.01px;
        }
        #t5_2 {
          left: 482px;
          bottom: 996px;
          letter-spacing: 0.13px;
        }
        #t6_2 {
          left: 595px;
          bottom: 1014px;
          letter-spacing: 0.13px;
        }
        #t7_2 {
          left: 686px;
          bottom: 1014px;
          letter-spacing: 0.17px;
        }
        #t8_2 {
          left: 744px;
          bottom: 1014px;
          letter-spacing: 0.15px;
          word-spacing: 0.01px;
        }
        #t9_2 {
          left: 117px;
          bottom: 974px;
          letter-spacing: 0.14px;
        }
        #ta_2 {
          left: 244px;
          bottom: 974px;
          letter-spacing: 0.15px;
        }
        #tb_2 {
          left: 74px;
          bottom: 953px;
          letter-spacing: 0.11px;
        }
        #tc_2 {
          left: 117px;
          bottom: 953px;
          letter-spacing: 0.1px;
          word-spacing: 0.01px;
        }
        #td_2 {
          left: 244px;
          bottom: 953px;
          letter-spacing: 0.14px;
        }
        #te_2 {
          left: 482px;
          bottom: 953px;
          letter-spacing: 0.11px;
        }
        #tf_2 {
          left: 595px;
          bottom: 953px;
          letter-spacing: 0.07px;
        }
        #tg_2 {
          left: 682px;
          bottom: 953px;
          letter-spacing: 0.13px;
        }
        #th_2 {
          left: 744px;
          bottom: 953px;
          letter-spacing: 0.09px;
        }
        #ti_2 {
          left: 798px;
          bottom: 953px;
          letter-spacing: 0.13px;
        }
        #tj_2 {
          left: 117px;
          bottom: 931px;
          letter-spacing: 0.12px;
        }
        #tk_2 {
          left: 117px;
          bottom: 910px;
          letter-spacing: 0.11px;
        }
        #tl_2 {
          left: 239px;
          bottom: 889px;
          letter-spacing: 0.09px;
        }
        #tm_2 {
          left: 336px;
          bottom: 889px;
          letter-spacing: 0.13px;
        }
        #tn_2 {
          left: 420px;
          bottom: 889px;
          letter-spacing: 0.09px;
        }
        #to_2 {
          left: 464px;
          bottom: 889px;
          letter-spacing: 0.09px;
        }
        #tp_2 {
          left: 239px;
          bottom: 867px;
          letter-spacing: 0.08px;
        }
        #tq_2 {
          left: 322px;
          bottom: 867px;
          letter-spacing: 0.13px;
        }
        #tr_2 {
          left: 420px;
          bottom: 867px;
          letter-spacing: 0.09px;
        }
        #ts_2 {
          left: 464px;
          bottom: 867px;
          letter-spacing: 0.09px;
        }
        #tt_2 {
          left: 160px;
          bottom: 846px;
          letter-spacing: 0.1px;
        }
        #tu_2 {
          left: 290px;
          bottom: 846px;
          letter-spacing: 0.11px;
        }
        #tv_2 {
          left: 420px;
          bottom: 846px;
          letter-spacing: 0.09px;
        }
        #tw_2 {
          left: 550px;
          bottom: 846px;
          letter-spacing: 0.11px;
        }
        #tx_2 {
          left: 138px;
          bottom: 824px;
          letter-spacing: 0.11px;
          word-spacing: -0.12px;
        }
        #ty_2 {
          left: 138px;
          bottom: 806px;
          letter-spacing: 0.1px;
        }
        #tz_2 {
          left: 138px;
          bottom: 783px;
          letter-spacing: 0.14px;
        }
        #t10_2 {
          left: 138px;
          bottom: 763px;
          letter-spacing: 0.12px;
          word-spacing: 0.01px;
        }
        #t11_2 {
          left: 312px;
          bottom: 763px;
          letter-spacing: 0.08px;
        }
        #t12_2 {
          left: 354px;
          bottom: 763px;
          letter-spacing: 0.1px;
        }
        #t13_2 {
          left: 436px;
          bottom: 763px;
          letter-spacing: 0.07px;
        }
        #t14_2 {
          left: 470px;
          bottom: 763px;
          letter-spacing: 0.13px;
        }
        #t15_2 {
          left: 398px;
          bottom: 703px;
          letter-spacing: 0.12px;
        }
        #t16_2 {
          left: 74px;
          bottom: 682px;
          letter-spacing: 0.11px;
        }
        #t17_2 {
          left: 117px;
          bottom: 682px;
          letter-spacing: 0.1px;
          word-spacing: 0.01px;
        }
        #t18_2 {
          left: 244px;
          bottom: 682px;
          letter-spacing: 0.16px;
        }
        #t19_2 {
          left: 482px;
          bottom: 682px;
          letter-spacing: 0.11px;
        }
        #t1a_2 {
          left: 595px;
          bottom: 682px;
        }
        #t1b_2 {
          left: 682px;
          bottom: 682px;
          letter-spacing: 0.13px;
        }
        #t1c_2 {
          left: 744px;
          bottom: 682px;
          letter-spacing: 0.09px;
        }
        #t1d_2 {
          left: 788px;
          bottom: 682px;
          letter-spacing: 0.13px;
        }
        #t1e_2 {
          left: 117px;
          bottom: 661px;
          letter-spacing: 0.11px;
        }
        #t1f_2 {
          left: 117px;
          bottom: 640px;
          letter-spacing: 0.11px;
        }
        #t1g_2 {
          left: 239px;
          bottom: 618px;
          letter-spacing: 0.09px;
        }
        #t1h_2 {
          left: 336px;
          bottom: 618px;
          letter-spacing: 0.13px;
        }
        #t1i_2 {
          left: 420px;
          bottom: 618px;
          letter-spacing: 0.09px;
        }
        #t1j_2 {
          left: 464px;
          bottom: 618px;
          letter-spacing: 0.09px;
        }
        #t1k_2 {
          left: 239px;
          bottom: 597px;
          letter-spacing: 0.08px;
        }
        #t1l_2 {
          left: 322px;
          bottom: 597px;
          letter-spacing: 0.13px;
        }
        #t1m_2 {
          left: 420px;
          bottom: 597px;
          letter-spacing: 0.09px;
        }
        #t1n_2 {
          left: 464px;
          bottom: 597px;
          letter-spacing: 0.09px;
        }
        #t1o_2 {
          left: 160px;
          bottom: 575px;
          letter-spacing: 0.1px;
        }
        #t1p_2 {
          left: 290px;
          bottom: 575px;
          letter-spacing: 0.11px;
        }
        #t1q_2 {
          left: 420px;
          bottom: 575px;
          letter-spacing: 0.09px;
        }
        #t1r_2 {
          left: 550px;
          bottom: 575px;
          letter-spacing: 0.11px;
        }
        #t1s_2 {
          left: 138px;
          bottom: 554px;
          letter-spacing: 0.13px;
          word-spacing: -0.11px;
        }
        #t1t_2 {
          left: 138px;
          bottom: 531px;
          letter-spacing: 0.14px;
        }
        #t1u_2 {
          left: 138px;
          bottom: 511px;
          letter-spacing: 0.12px;
          word-spacing: 0.01px;
        }
        #t1v_2 {
          left: 312px;
          bottom: 511px;
          letter-spacing: 0.08px;
        }
        #t1w_2 {
          left: 354px;
          bottom: 511px;
          letter-spacing: 0.1px;
        }
        #t1x_2 {
          left: 433px;
          bottom: 511px;
          letter-spacing: 0.07px;
        }
        #t1y_2 {
          left: 467px;
          bottom: 511px;
          letter-spacing: 0.13px;
          word-spacing: -0.02px;
        }
        #t1z_2 {
          left: 398px;
          bottom: 451px;
          letter-spacing: 0.12px;
        }
        #t20_2 {
          left: 74px;
          bottom: 430px;
          letter-spacing: 0.11px;
        }
        #t21_2 {
          left: 117px;
          bottom: 430px;
          letter-spacing: 0.1px;
        }
        #t22_2 {
          left: 244px;
          bottom: 430px;
          letter-spacing: 0.11px;
          word-spacing: -0.11px;
        }
        #t23_2 {
          left: 595px;
          bottom: 430px;
          letter-spacing: 0.07px;
        }
        #t24_2 {
          left: 682px;
          bottom: 430px;
          letter-spacing: 0.13px;
        }
        #t25_2 {
          left: 744px;
          bottom: 430px;
          letter-spacing: 0.09px;
        }
        #t26_2 {
          left: 798px;
          bottom: 430px;
          letter-spacing: 0.13px;
        }
        #t27_2 {
          left: 117px;
          bottom: 409px;
          letter-spacing: 0.11px;
        }
        #t28_2 {
          left: 244px;
          bottom: 409px;
          letter-spacing: 0.11px;
        }
        #t29_2 {
          left: 117px;
          bottom: 388px;
          letter-spacing: 0.11px;
        }
        #t2a_2 {
          left: 239px;
          bottom: 366px;
          letter-spacing: 0.09px;
        }
        #t2b_2 {
          left: 336px;
          bottom: 366px;
          letter-spacing: 0.13px;
        }
        #t2c_2 {
          left: 420px;
          bottom: 366px;
          letter-spacing: 0.09px;
        }
        #t2d_2 {
          left: 464px;
          bottom: 366px;
          letter-spacing: 0.09px;
        }
        #t2e_2 {
          left: 239px;
          bottom: 345px;
          letter-spacing: 0.08px;
        }
        #t2f_2 {
          left: 322px;
          bottom: 345px;
          letter-spacing: 0.13px;
        }
        #t2g_2 {
          left: 420px;
          bottom: 345px;
          letter-spacing: 0.09px;
        }
        #t2h_2 {
          left: 464px;
          bottom: 345px;
          letter-spacing: 0.09px;
        }
        #t2i_2 {
          left: 138px;
          bottom: 322px;
          letter-spacing: 0.14px;
        }
        #t2j_2 {
          left: 138px;
          bottom: 301px;
          letter-spacing: 0.12px;
          word-spacing: 0.01px;
        }
        #t2k_2 {
          left: 312px;
          bottom: 301px;
          letter-spacing: 0.08px;
        }
        #t2l_2 {
          left: 354px;
          bottom: 301px;
          letter-spacing: 0.1px;
        }
        #t2m_2 {
          left: 436px;
          bottom: 301px;
          letter-spacing: 0.07px;
        }
        #t2n_2 {
          left: 470px;
          bottom: 301px;
          letter-spacing: 0.13px;
          word-spacing: -0.05px;
        }
        #t2o_2 {
          left: 138px;
          bottom: 280px;
          letter-spacing: 0.12px;
          word-spacing: 0.01px;
        }
        #t2p_2 {
          left: 312px;
          bottom: 280px;
          letter-spacing: 0.08px;
        }
        #t2q_2 {
          left: 354px;
          bottom: 280px;
          letter-spacing: 0.1px;
        }
        #t2r_2 {
          left: 436px;
          bottom: 280px;
          letter-spacing: 0.07px;
        }
        #t2s_2 {
          left: 470px;
          bottom: 280px;
          letter-spacing: 0.13px;
          word-spacing: -0.05px;
        }
        #t2t_2 {
          left: 398px;
          bottom: 220px;
          letter-spacing: 0.12px;
        }
        #t2u_2 {
          left: 74px;
          bottom: 200px;
          letter-spacing: 0.11px;
        }
        #t2v_2 {
          left: 117px;
          bottom: 200px;
          letter-spacing: 0.1px;
        }
        #t2w_2 {
          left: 244px;
          bottom: 200px;
          letter-spacing: 0.12px;
          word-spacing: 0.01px;
        }
        #t2x_2 {
          left: 244px;
          bottom: 181px;
          letter-spacing: 0.11px;
        }
        #t2y_2 {
          left: 482px;
          bottom: 200px;
          letter-spacing: 0.11px;
        }
        #t2z_2 {
          left: 595px;
          bottom: 200px;
          letter-spacing: 0.07px;
        }
        #t30_2 {
          left: 682px;
          bottom: 200px;
          letter-spacing: 0.13px;
        }
        #t31_2 {
          left: 744px;
          bottom: 200px;
          letter-spacing: 0.09px;
        }
        #t32_2 {
          left: 798px;
          bottom: 200px;
          letter-spacing: 0.13px;
        }
        #t33_2 {
          left: 117px;
          bottom: 160px;
          letter-spacing: 0.11px;
        }
        #t34_2 {
          left: 117px;
          bottom: 142px;
          letter-spacing: 0.13px;
        }
        #t35_2 {
          left: 117px;
          bottom: 120px;
          letter-spacing: 0.11px;
        }
        #t36_2 {
          left: 65px;
          bottom: 1152px;
          letter-spacing: 0.13px;
          word-spacing: 0.01px;
        }
        #t37_2 {
          left: 726px;
          bottom: 1061px;
          letter-spacing: 0.12px;
          word-spacing: 0.09px;
        }
        #t38_2 {
          left: 6px;
          bottom: 0px;
          letter-spacing: 0.11px;
        }

        .s1_2 {
          font-size: 15px;
          font-family: Helvetica-Bold_1b;
          color: #000;
        }
        .s2_2 {
          font-size: 12px;
          font-family: Helvetica_1a;
          color: #000;
        }
        .s3_2 {
          font-size: 12px;
          font-family: Helvetica, Arial, sans-serif;
          color: #000;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts2" type="text/css">
        @font-face {
          font-family: Helvetica-Bold_1b;
          src: url("fonts/Helvetica-Bold_1b.woff") format("woff");
        }

        @font-face {
          font-family: Helvetica_1a;
          src: url("fonts/Helvetica_1a.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg2Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg2" style="-webkit-user-select: none">
        <object
          width="909"
          height="1286"
          data="2/2.svg"
          type="image/svg+xml"
          id="pdf2"
          style="
            width: 909px;
            height: 1286px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_2" class="t s1_2">Pos. </span
        ><span id="t2_2" class="t s1_2">Item no./Rev no. </span
        ><span id="t3_2" class="t s1_2">Description </span
        ><span id="t4_2" class="t s1_2">Date of </span>
        <span id="t5_2" class="t s1_2">delivery </span>
        <span id="t6_2" class="t s1_2">Unit </span
        ><span id="t7_2" class="t s1_2">UN </span
        ><span id="t8_2" class="t s1_2">Pos amount </span>
        <span id="t9_2" class="t s1_2">Supp. item no. </span
        ><span id="ta_2" class="t s1_2">Quotation number </span>
        <span id="tb_2" class="t s2_2">00010 </span
        ><span id="tc_2" class="t s2_2">102389014 / 01 </span
        ><span id="td_2" class="t s2_2">MOTOR MAXON 383934 </span
        ><span id="te_2" class="t s2_2">28.04.2023 </span
        ><span id="tf_2" class="t s2_2">15 </span
        ><span id="tg_2" class="t s2_2">PC </span
        ><span id="th_2" class="t s2_2">2.925,75 </span
        ><span id="ti_2" class="t s2_2">CHF </span>
        <span id="tj_2" class="t s2_2">383934, MAXON </span>
        <span id="tk_2" class="t s2_2">383934 </span>
        <span id="tl_2" class="t s2_2">Gross price / </span
        ><span id="tm_2" class="t s2_2">1PC </span
        ><span id="tn_2" class="t s2_2">195,05 </span
        ><span id="to_2" class="t s2_2">CHF </span>
        <span id="tp_2" class="t s2_2">Net price / </span
        ><span id="tq_2" class="t s2_2">1PC </span
        ><span id="tr_2" class="t s2_2">195,05 </span
        ><span id="ts_2" class="t s2_2">CHF </span>
        <span id="tt_2" class="t s2_2">Quantity Contract No. </span
        ><span id="tu_2" class="t s2_2">4600018277 </span
        ><span id="tv_2" class="t s2_2">Position </span
        ><span id="tw_2" class="t s2_2">00010 </span>
        <span id="tx_2" class="t s2_2">Quotation#: QUO-2015-22099-K3K5N5 </span>
        <span id="ty_2" class="t s2_2">LT: 6-8 weeks </span>
        <span id="tz_2" class="t s1_2">related documents: </span>
        <span id="t10_2" class="t s2_2">PLM Document </span
        ><span id="t11_2" class="t s2_2">ZE5 </span
        ><span id="t12_2" class="t s2_2">300415461 </span
        ><span id="t13_2" class="t s2_2">02 </span
        ><span id="t14_2" class="t s2_2">MOTOR MAXON RE013MR 67:1 </span>
        <span id="t15_2" class="t s1_2">---***--- </span>
        <span id="t16_2" class="t s2_2">00020 </span
        ><span id="t17_2" class="t s2_2">1000-8332 / 02 </span
        ><span id="t18_2" class="t s2_2">DC-MOTOR </span
        ><span id="t19_2" class="t s2_2">21.04.2023 </span
        ><span id="t1a_2" class="t s2_2">5 </span
        ><span id="t1b_2" class="t s2_2">PC </span
        ><span id="t1c_2" class="t s2_2">740,25 </span
        ><span id="t1d_2" class="t s2_2">CHF </span>
        <span id="t1e_2" class="t s2_2">262079 </span>
        <span id="t1f_2" class="t s2_2">262079 </span>
        <span id="t1g_2" class="t s2_2">Gross price / </span
        ><span id="t1h_2" class="t s2_2">1PC </span
        ><span id="t1i_2" class="t s2_2">148,05 </span
        ><span id="t1j_2" class="t s2_2">CHF </span>
        <span id="t1k_2" class="t s2_2">Net price / </span
        ><span id="t1l_2" class="t s2_2">1PC </span
        ><span id="t1m_2" class="t s2_2">148,05 </span
        ><span id="t1n_2" class="t s2_2">CHF </span>
        <span id="t1o_2" class="t s2_2">Quantity Contract No. </span
        ><span id="t1p_2" class="t s2_2">4600017162 </span
        ><span id="t1q_2" class="t s2_2">Position </span
        ><span id="t1r_2" class="t s2_2">00081 </span>
        <span id="t1s_2" class="t s2_2"
          >QUOTATION: QUO-2015-22821-C7W9C4-REV06
        </span>
        <span id="t1t_2" class="t s1_2">related documents: </span>
        <span id="t1u_2" class="t s2_2">PLM Document </span
        ><span id="t1v_2" class="t s2_2">ZE5 </span
        ><span id="t1w_2" class="t s2_2">1000-8332 </span
        ><span id="t1x_2" class="t s2_2">02 </span
        ><span id="t1y_2" class="t s2_2">DC-MOTOR MAXON RE016MR 84:1 </span>
        <span id="t1z_2" class="t s1_2">---***--- </span>
        <span id="t20_2" class="t s2_2">00030 </span
        ><span id="t21_2" class="t s2_2">130.0167.1 / 01 </span
        ><span id="t22_2" class="t s2_2"
          >MOTOR RE25 / TACHO ENC22 3419 CPL. 21.04.2023 </span
        ><span id="t23_2" class="t s2_2">20 </span
        ><span id="t24_2" class="t s2_2">PC </span
        ><span id="t25_2" class="t s2_2">4.569,00 </span
        ><span id="t26_2" class="t s2_2">CHF </span>
        <span id="t27_2" class="t s2_2">135675 </span
        ><span id="t28_2" class="t s2_2">202022821C </span>
        <span id="t29_2" class="t s2_2">135675 </span>
        <span id="t2a_2" class="t s2_2">Gross price / </span
        ><span id="t2b_2" class="t s2_2">1PC </span
        ><span id="t2c_2" class="t s2_2">228,45 </span
        ><span id="t2d_2" class="t s2_2">CHF </span>
        <span id="t2e_2" class="t s2_2">Net price / </span
        ><span id="t2f_2" class="t s2_2">1PC </span
        ><span id="t2g_2" class="t s2_2">228,45 </span
        ><span id="t2h_2" class="t s2_2">CHF </span>
        <span id="t2i_2" class="t s1_2">related documents: </span>
        <span id="t2j_2" class="t s2_2">PLM Document </span
        ><span id="t2k_2" class="t s2_2">ZE5 </span
        ><span id="t2l_2" class="t s2_2">130.0167.1 </span
        ><span id="t2m_2" class="t s2_2">01 </span
        ><span id="t2n_2" class="t s2_2"
          >KOMBI MOTOR RE25+TACHO ENC22 3419
        </span>
        <span id="t2o_2" class="t s2_2">PLM Document </span
        ><span id="t2p_2" class="t s2_2">ZE5 </span
        ><span id="t2q_2" class="t s2_2">130.0167.1 </span
        ><span id="t2r_2" class="t s2_2">01 </span
        ><span id="t2s_2" class="t s2_2"
          >KOMBI MOTOR RE25+TACHO ENC22 3419
        </span>
        <span id="t2t_2" class="t s1_2">---***--- </span>
        <span id="t2u_2" class="t s2_2">00050 </span
        ><span id="t2v_2" class="t s2_2">130.0350.1 / 01 </span
        ><span id="t2w_2" class="t s2_2">MAX. MOTOR 42V 90W PLAN. GEAR. </span>
        <span id="t2x_2" class="t s2_2">14:1 </span>
        <span id="t2y_2" class="t s2_2">21.04.2023 </span
        ><span id="t2z_2" class="t s2_2">18 </span
        ><span id="t30_2" class="t s2_2">PC </span
        ><span id="t31_2" class="t s2_2">3.709,80 </span
        ><span id="t32_2" class="t s2_2">CHF </span>
        <span id="t33_2" class="t s2_2">166131=118778+1661 </span>
        <span id="t34_2" class="t s2_2">58, MAXON </span>
        <span id="t35_2" class="t s2_2">166131 </span>
        <span id="t36_2" class="t s1_2">Purchase order no 4500773830 </span>
        <span id="t37_2" class="t s1_2">page: 2 / 4 </span>
        <span id="t38_2" class="t s3_2"
          >Printed: 2023-04-11 11:01:11 Document: 9982502/en/01/Released
        </span>
      </div>
      <!-- End text definitions -->
    </div>

    <div
      id="p3"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1286px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_3 {
          left: 74px;
          bottom: 1014px;
          letter-spacing: 0.13px;
        }
        #t2_3 {
          left: 117px;
          bottom: 1014px;
          letter-spacing: 0.13px;
        }
        #t3_3 {
          left: 244px;
          bottom: 1014px;
          letter-spacing: 0.14px;
        }
        #t4_3 {
          left: 482px;
          bottom: 1014px;
          letter-spacing: 0.13px;
          word-spacing: 0.01px;
        }
        #t5_3 {
          left: 482px;
          bottom: 996px;
          letter-spacing: 0.13px;
        }
        #t6_3 {
          left: 595px;
          bottom: 1014px;
          letter-spacing: 0.13px;
        }
        #t7_3 {
          left: 686px;
          bottom: 1014px;
          letter-spacing: 0.17px;
        }
        #t8_3 {
          left: 744px;
          bottom: 1014px;
          letter-spacing: 0.15px;
          word-spacing: 0.01px;
        }
        #t9_3 {
          left: 117px;
          bottom: 974px;
          letter-spacing: 0.14px;
        }
        #ta_3 {
          left: 244px;
          bottom: 974px;
          letter-spacing: 0.15px;
        }
        #tb_3 {
          left: 239px;
          bottom: 953px;
          letter-spacing: 0.09px;
        }
        #tc_3 {
          left: 336px;
          bottom: 953px;
          letter-spacing: 0.13px;
        }
        #td_3 {
          left: 420px;
          bottom: 953px;
          letter-spacing: 0.09px;
        }
        #te_3 {
          left: 464px;
          bottom: 953px;
          letter-spacing: 0.09px;
        }
        #tf_3 {
          left: 239px;
          bottom: 931px;
          letter-spacing: 0.08px;
        }
        #tg_3 {
          left: 322px;
          bottom: 931px;
          letter-spacing: 0.13px;
        }
        #th_3 {
          left: 420px;
          bottom: 931px;
          letter-spacing: 0.09px;
        }
        #ti_3 {
          left: 464px;
          bottom: 931px;
          letter-spacing: 0.09px;
        }
        #tj_3 {
          left: 160px;
          bottom: 910px;
          letter-spacing: 0.1px;
        }
        #tk_3 {
          left: 290px;
          bottom: 910px;
          letter-spacing: 0.11px;
        }
        #tl_3 {
          left: 420px;
          bottom: 910px;
          letter-spacing: 0.09px;
        }
        #tm_3 {
          left: 550px;
          bottom: 910px;
          letter-spacing: 0.11px;
        }
        #tn_3 {
          left: 138px;
          bottom: 889px;
          letter-spacing: 0.11px;
          word-spacing: -0.01px;
        }
        #to_3 {
          left: 138px;
          bottom: 870px;
          letter-spacing: 0.1px;
        }
        #tp_3 {
          left: 138px;
          bottom: 848px;
          letter-spacing: 0.14px;
        }
        #tq_3 {
          left: 138px;
          bottom: 827px;
          letter-spacing: 0.12px;
          word-spacing: 0.01px;
        }
        #tr_3 {
          left: 312px;
          bottom: 827px;
          letter-spacing: 0.08px;
        }
        #ts_3 {
          left: 354px;
          bottom: 827px;
          letter-spacing: 0.1px;
        }
        #tt_3 {
          left: 436px;
          bottom: 827px;
          letter-spacing: 0.07px;
        }
        #tu_3 {
          left: 470px;
          bottom: 827px;
          letter-spacing: 0.11px;
          word-spacing: -0.13px;
        }
        #tv_3 {
          left: 398px;
          bottom: 767px;
          letter-spacing: 0.12px;
        }
        #tw_3 {
          left: 74px;
          bottom: 747px;
          letter-spacing: 0.11px;
        }
        #tx_3 {
          left: 117px;
          bottom: 747px;
          letter-spacing: 0.1px;
        }
        #ty_3 {
          left: 244px;
          bottom: 747px;
          letter-spacing: 0.14px;
          word-spacing: 0.01px;
        }
        #tz_3 {
          left: 482px;
          bottom: 747px;
          letter-spacing: 0.11px;
        }
        #t10_3 {
          left: 595px;
          bottom: 747px;
          letter-spacing: 0.07px;
        }
        #t11_3 {
          left: 682px;
          bottom: 747px;
          letter-spacing: 0.13px;
        }
        #t12_3 {
          left: 744px;
          bottom: 747px;
          letter-spacing: 0.09px;
        }
        #t13_3 {
          left: 798px;
          bottom: 747px;
          letter-spacing: 0.13px;
        }
        #t14_3 {
          left: 117px;
          bottom: 725px;
          letter-spacing: 0.1px;
          word-spacing: 0.01px;
        }
        #t15_3 {
          left: 117px;
          bottom: 704px;
          letter-spacing: 0.11px;
        }
        #t16_3 {
          left: 239px;
          bottom: 682px;
          letter-spacing: 0.09px;
        }
        #t17_3 {
          left: 336px;
          bottom: 682px;
          letter-spacing: 0.13px;
        }
        #t18_3 {
          left: 420px;
          bottom: 682px;
          letter-spacing: 0.09px;
        }
        #t19_3 {
          left: 464px;
          bottom: 682px;
          letter-spacing: 0.09px;
        }
        #t1a_3 {
          left: 239px;
          bottom: 661px;
          letter-spacing: 0.08px;
        }
        #t1b_3 {
          left: 322px;
          bottom: 661px;
          letter-spacing: 0.13px;
        }
        #t1c_3 {
          left: 420px;
          bottom: 661px;
          letter-spacing: 0.09px;
        }
        #t1d_3 {
          left: 464px;
          bottom: 661px;
          letter-spacing: 0.09px;
        }
        #t1e_3 {
          left: 138px;
          bottom: 640px;
          letter-spacing: 0.09px;
          word-spacing: 0.01px;
        }
        #t1f_3 {
          left: 138px;
          bottom: 621px;
          letter-spacing: 0.12px;
        }
        #t1g_3 {
          left: 138px;
          bottom: 603px;
          letter-spacing: 0.11px;
          word-spacing: 0.01px;
        }
        #t1h_3 {
          left: 138px;
          bottom: 585px;
          letter-spacing: 0.12px;
        }
        #t1i_3 {
          left: 138px;
          bottom: 562px;
          letter-spacing: 0.14px;
        }
        #t1j_3 {
          left: 138px;
          bottom: 541px;
          letter-spacing: 0.12px;
          word-spacing: 0.01px;
        }
        #t1k_3 {
          left: 312px;
          bottom: 541px;
          letter-spacing: 0.08px;
        }
        #t1l_3 {
          left: 354px;
          bottom: 541px;
          letter-spacing: 0.1px;
        }
        #t1m_3 {
          left: 436px;
          bottom: 541px;
          letter-spacing: 0.07px;
        }
        #t1n_3 {
          left: 470px;
          bottom: 541px;
          letter-spacing: 0.14px;
          word-spacing: 0.01px;
        }
        #t1o_3 {
          left: 398px;
          bottom: 482px;
          letter-spacing: 0.12px;
        }
        #t1p_3 {
          left: 74px;
          bottom: 461px;
          letter-spacing: 0.11px;
        }
        #t1q_3 {
          left: 117px;
          bottom: 461px;
          letter-spacing: 0.1px;
          word-spacing: 0.01px;
        }
        #t1r_3 {
          left: 244px;
          bottom: 461px;
          letter-spacing: 0.13px;
          word-spacing: 0.01px;
        }
        #t1s_3 {
          left: 482px;
          bottom: 461px;
          letter-spacing: 0.11px;
        }
        #t1t_3 {
          left: 595px;
          bottom: 461px;
          letter-spacing: 0.07px;
        }
        #t1u_3 {
          left: 682px;
          bottom: 461px;
          letter-spacing: 0.13px;
        }
        #t1v_3 {
          left: 744px;
          bottom: 461px;
          letter-spacing: 0.09px;
        }
        #t1w_3 {
          left: 798px;
          bottom: 461px;
          letter-spacing: 0.13px;
        }
        #t1x_3 {
          left: 117px;
          bottom: 439px;
          letter-spacing: 0.13px;
          word-spacing: 0.01px;
        }
        #t1y_3 {
          left: 117px;
          bottom: 418px;
          letter-spacing: 0.11px;
        }
        #t1z_3 {
          left: 239px;
          bottom: 397px;
          letter-spacing: 0.09px;
        }
        #t20_3 {
          left: 336px;
          bottom: 397px;
          letter-spacing: 0.13px;
        }
        #t21_3 {
          left: 420px;
          bottom: 397px;
          letter-spacing: 0.09px;
        }
        #t22_3 {
          left: 457px;
          bottom: 397px;
          letter-spacing: 0.09px;
        }
        #t23_3 {
          left: 239px;
          bottom: 375px;
          letter-spacing: 0.08px;
        }
        #t24_3 {
          left: 322px;
          bottom: 375px;
          letter-spacing: 0.13px;
        }
        #t25_3 {
          left: 420px;
          bottom: 375px;
          letter-spacing: 0.09px;
        }
        #t26_3 {
          left: 457px;
          bottom: 375px;
          letter-spacing: 0.09px;
        }
        #t27_3 {
          left: 160px;
          bottom: 354px;
          letter-spacing: 0.1px;
        }
        #t28_3 {
          left: 290px;
          bottom: 354px;
          letter-spacing: 0.11px;
        }
        #t29_3 {
          left: 420px;
          bottom: 354px;
          letter-spacing: 0.09px;
        }
        #t2a_3 {
          left: 550px;
          bottom: 354px;
          letter-spacing: 0.11px;
        }
        #t2b_3 {
          left: 138px;
          bottom: 331px;
          letter-spacing: 0.14px;
        }
        #t2c_3 {
          left: 750px;
          bottom: 331px;
          letter-spacing: 0.12px;
        }
        #t2d_3 {
          left: 827px;
          bottom: 331px;
          letter-spacing: 0.16px;
        }
        #t2e_3 {
          left: 65px;
          bottom: 255px;
          letter-spacing: 0.09px;
          word-spacing: 0.01px;
        }
        #t2f_3 {
          left: 65px;
          bottom: 237px;
          letter-spacing: 0.09px;
          word-spacing: 0.01px;
        }
        #t2g_3 {
          left: 65px;
          bottom: 200px;
          letter-spacing: 0.12px;
          word-spacing: 0.07px;
        }
        #t2h_3 {
          left: 65px;
          bottom: 1152px;
          letter-spacing: 0.13px;
          word-spacing: 0.01px;
        }
        #t2i_3 {
          left: 726px;
          bottom: 1061px;
          letter-spacing: 0.12px;
          word-spacing: 0.09px;
        }
        #t2j_3 {
          left: 6px;
          bottom: 0px;
          letter-spacing: 0.11px;
        }

        .s1_3 {
          font-size: 15px;
          font-family: Helvetica-Bold_1b;
          color: #000;
        }
        .s2_3 {
          font-size: 12px;
          font-family: Helvetica_1a;
          color: #000;
        }
        .s3_3 {
          font-size: 12px;
          font-family: Helvetica, Arial, sans-serif;
          color: #000;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin embedded font definitions -->
      <style id="fonts3" type="text/css">
        @font-face {
          font-family: Helvetica-Bold_1b;
          src: url("fonts/Helvetica-Bold_1b.woff") format("woff");
        }

        @font-face {
          font-family: Helvetica_1a;
          src: url("fonts/Helvetica_1a.woff") format("woff");
        }
      </style>
      <!-- End embedded font definitions -->

      <!-- Begin page background -->
      <div
        id="pg3Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg3" style="-webkit-user-select: none">
        <object
          width="909"
          height="1286"
          data="3/3.svg"
          type="image/svg+xml"
          id="pdf3"
          style="
            width: 909px;
            height: 1286px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_3" class="t s1_3">Pos. </span
        ><span id="t2_3" class="t s1_3">Item no./Rev no. </span
        ><span id="t3_3" class="t s1_3">Description </span
        ><span id="t4_3" class="t s1_3">Date of </span>
        <span id="t5_3" class="t s1_3">delivery </span>
        <span id="t6_3" class="t s1_3">Unit </span
        ><span id="t7_3" class="t s1_3">UN </span
        ><span id="t8_3" class="t s1_3">Pos amount </span>
        <span id="t9_3" class="t s1_3">Supp. item no. </span
        ><span id="ta_3" class="t s1_3">Quotation number </span>
        <span id="tb_3" class="t s2_3">Gross price / </span
        ><span id="tc_3" class="t s2_3">1PC </span
        ><span id="td_3" class="t s2_3">206,10 </span
        ><span id="te_3" class="t s2_3">CHF </span>
        <span id="tf_3" class="t s2_3">Net price / </span
        ><span id="tg_3" class="t s2_3">1PC </span
        ><span id="th_3" class="t s2_3">206,10 </span
        ><span id="ti_3" class="t s2_3">CHF </span>
        <span id="tj_3" class="t s2_3">Quantity Contract No. </span
        ><span id="tk_3" class="t s2_3">4600018685 </span
        ><span id="tl_3" class="t s2_3">Position </span
        ><span id="tm_3" class="t s2_3">00010 </span>
        <span id="tn_3" class="t s2_3">Estimate 1st call off Dec'22 </span>
        <span id="to_3" class="t s2_3">LT: 6-8 weeks </span>
        <span id="tp_3" class="t s1_3">related documents: </span>
        <span id="tq_3" class="t s2_3">PLM Document </span
        ><span id="tr_3" class="t s2_3">ZE5 </span
        ><span id="ts_3" class="t s2_3">130.0350.1 </span
        ><span id="tt_3" class="t s2_3">01 </span
        ><span id="tu_3" class="t s2_3">Max.Mo 42V 90W Plan.Getr. 14:1 </span>
        <span id="tv_3" class="t s1_3">---***--- </span>
        <span id="tw_3" class="t s2_3">00060 </span
        ><span id="tx_3" class="t s2_3">130.0403.1 / 01 </span
        ><span id="ty_3" class="t s2_3">DC-MOTOR MAXON RE015 </span
        ><span id="tz_3" class="t s2_3">21.04.2023 </span
        ><span id="t10_3" class="t s2_3">25 </span
        ><span id="t11_3" class="t s2_3">PC </span
        ><span id="t12_3" class="t s2_3">5.897,50 </span
        ><span id="t13_3" class="t s2_3">CHF </span>
        <span id="t14_3" class="t s2_3">275841 (ALT: 790733) </span>
        <span id="t15_3" class="t s2_3">275841 </span>
        <span id="t16_3" class="t s2_3">Gross price / </span
        ><span id="t17_3" class="t s2_3">1PC </span
        ><span id="t18_3" class="t s2_3">235,90 </span
        ><span id="t19_3" class="t s2_3">CHF </span>
        <span id="t1a_3" class="t s2_3">Net price / </span
        ><span id="t1b_3" class="t s2_3">1PC </span
        ><span id="t1c_3" class="t s2_3">235,90 </span
        ><span id="t1d_3" class="t s2_3">CHF </span>
        <span id="t1e_3" class="t s2_3">Consists of </span>
        <span id="t1f_3" class="t s2_3"
          >118648 DC motor RE15 EBCLL 1.6W SL 1WE
        </span>
        <span id="t1g_3" class="t s2_3"
          >201937 tacho MR 512IMP 2K 32P ASIC
        </span>
        <span id="t1h_3" class="t s2_3">Quotation:QUO-2015-22821-C7W9C4 </span>
        <span id="t1i_3" class="t s1_3">related documents: </span>
        <span id="t1j_3" class="t s2_3">PLM Document </span
        ><span id="t1k_3" class="t s2_3">ZE5 </span
        ><span id="t1l_3" class="t s2_3">130.0403.1 </span
        ><span id="t1m_3" class="t s2_3">01 </span
        ><span id="t1n_3" class="t s2_3">DC-MOTOR MAXON RE015 </span>
        <span id="t1o_3" class="t s1_3">---***--- </span>
        <span id="t1p_3" class="t s2_3">00070 </span
        ><span id="t1q_3" class="t s2_3">85500010300 / 01 </span
        ><span id="t1r_3" class="t s2_3">FLAT MOTOR EC32 </span
        ><span id="t1s_3" class="t s2_3">21.04.2023 </span
        ><span id="t1t_3" class="t s2_3">59 </span
        ><span id="t1u_3" class="t s2_3">PC </span
        ><span id="t1v_3" class="t s2_3">5.133,00 </span
        ><span id="t1w_3" class="t s2_3">CHF </span>
        <span id="t1x_3" class="t s2_3">MAXON 342863 </span>
        <span id="t1y_3" class="t s2_3">342863 </span>
        <span id="t1z_3" class="t s2_3">Gross price / </span
        ><span id="t20_3" class="t s2_3">1PC </span
        ><span id="t21_3" class="t s2_3">87,00 </span
        ><span id="t22_3" class="t s2_3">CHF </span>
        <span id="t23_3" class="t s2_3">Net price / </span
        ><span id="t24_3" class="t s2_3">1PC </span
        ><span id="t25_3" class="t s2_3">87,00 </span
        ><span id="t26_3" class="t s2_3">CHF </span>
        <span id="t27_3" class="t s2_3">Quantity Contract No. </span
        ><span id="t28_3" class="t s2_3">4600018619 </span
        ><span id="t29_3" class="t s2_3">Position </span
        ><span id="t2a_3" class="t s2_3">00010 </span>
        <span id="t2b_3" class="t s1_3">Total amount </span
        ><span id="t2c_3" class="t s1_3">22.975,30 </span
        ><span id="t2d_3" class="t s1_3">CHF </span>
        <span id="t2e_3" class="t s2_3"
          >Delivery Monday - Friday, strictly follow delivery date.
        </span>
        <span id="t2f_3" class="t s2_3"
          >All modules, material and component shall follow strictly to the
          BOM,engineering drawings and work instruction provided.
        </span>
        <span id="t2g_3" class="t s2_3"
          >THIS IS A COMPUTER GENERATED DOCUMENT AND IT DOES NOT REQUIRE A
          SIGNATURE.
        </span>
        <span id="t2h_3" class="t s1_3">Purchase order no 4500773830 </span>
        <span id="t2i_3" class="t s1_3">page: 3 / 4 </span>
        <span id="t2j_3" class="t s3_3"
          >Printed: 2023-04-11 11:01:11 Document: 9982502/en/01/Released
        </span>
      </div>
      <!-- End text definitions -->
    </div>

    <div
      id="p4"
      style="
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 909px;
        height: 1286px;
      "
    >
      <!-- Begin shared CSS values -->
      <style class="shared-css" type="text/css">
        .t {
          transform-origin: bottom left;
          z-index: 2;
          position: absolute;
          white-space: pre;
          overflow: visible;
          line-height: 1.5;
        }
        .text-container {
          white-space: pre;
        }
        @supports (-webkit-touch-callout: none) {
          .text-container {
            white-space: normal;
          }
        }
      </style>
      <!-- End shared CSS values -->

      <!-- Begin inline CSS -->
      <style type="text/css">
        #t1_4 {
          left: 6px;
          bottom: 0px;
          letter-spacing: 0.11px;
        }

        .s1 {
          font-size: 12px;
          font-family: Helvetica, Arial, sans-serif;
          color: #000;
        }
      </style>
      <!-- End inline CSS -->

      <!-- Begin page background -->
      <div
        id="pg4Overlay"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0);
          -webkit-user-select: none;
        "
      ></div>
      <div id="pg4" style="-webkit-user-select: none">
        <object
          width="909"
          height="1286"
          data="4/4.svg"
          type="image/svg+xml"
          id="pdf4"
          style="
            width: 909px;
            height: 1286px;
            -moz-transform: scale(1);
            z-index: 0;
          "
        ></object>
      </div>
      <!-- End page background -->

      <!-- Begin text definitions (Positioned/styled in CSS) -->
      <div class="text-container">
        <span id="t1_4" class="t s1"
          >Printed: 2023-04-11 11:01:11 Document: 9982502/en/01/Released
        </span>
      </div>
      <!-- End text definitions -->
    </div>
  </body>
</html>

  `,
  'Bestellung-92016-APSO': `
  <!DOCTYPE html>
<html lang="en">
<head>
<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
<meta charset="utf-8" />
</head>

<body style="margin: 0;">

<div id="p1" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_1{left:593px;bottom:997px;letter-spacing:0.12px;}
#t2_1{left:74px;bottom:779px;letter-spacing:-0.53px;}
#t3_1{left:124px;bottom:779px;letter-spacing:-0.19px;}
#t4_1{left:173px;bottom:779px;letter-spacing:-0.19px;}
#t5_1{left:247px;bottom:779px;letter-spacing:-0.17px;}
#t6_1{left:600px;bottom:779px;letter-spacing:-0.14px;word-spacing:-0.01px;}
#t7_1{left:745px;bottom:779px;letter-spacing:-0.16px;word-spacing:-0.02px;}
#t8_1{left:74px;bottom:886px;letter-spacing:-0.08px;}
#t9_1{left:225px;bottom:886px;letter-spacing:0.15px;}
#ta_1{left:593px;bottom:821px;letter-spacing:0.05px;}
#tb_1{left:677px;bottom:821px;}
#tc_1{left:507px;bottom:1063px;letter-spacing:-0.21px;}
#td_1{left:507px;bottom:1041px;letter-spacing:-0.41px;}
#te_1{left:593px;bottom:1041px;letter-spacing:-0.18px;}
#tf_1{left:675px;bottom:1041px;letter-spacing:-0.25px;}
#tg_1{left:593px;bottom:1063px;letter-spacing:-0.22px;word-spacing:0.03px;}
#th_1{left:593px;bottom:1020px;letter-spacing:-0.23px;}
#ti_1{left:507px;bottom:1020px;letter-spacing:-0.18px;}
#tj_1{left:593px;bottom:954px;letter-spacing:0.14px;}
#tk_1{left:507px;bottom:998px;letter-spacing:-0.28px;}
#tl_1{left:507px;bottom:976px;letter-spacing:-0.31px;}
#tm_1{left:593px;bottom:975px;letter-spacing:0.14px;}
#tn_1{left:507px;bottom:955px;letter-spacing:-0.58px;}
#to_1{left:507px;bottom:933px;letter-spacing:-0.55px;}
#tp_1{left:74px;bottom:817px;letter-spacing:0.07px;word-spacing:0.06px;}
#tq_1{left:507px;bottom:886px;letter-spacing:-0.43px;}
#tr_1{left:74px;bottom:951px;letter-spacing:0.01px;word-spacing:0.08px;}
#ts_1{left:74px;bottom:1059px;letter-spacing:0.14px;word-spacing:-0.02px;}
#tt_1{left:74px;bottom:864px;letter-spacing:0.09px;word-spacing:0.05px;}
#tu_1{left:699px;bottom:821px;}
#tv_1{left:735px;bottom:821px;}
#tw_1{left:74px;bottom:994px;letter-spacing:-0.02px;word-spacing:0.15px;}
#tx_1{left:593px;bottom:886px;letter-spacing:0.13px;}
#ty_1{left:74px;bottom:1084px;letter-spacing:0.11px;}
#tz_1{left:333px;bottom:528px;letter-spacing:0.14px;}
#t10_1{left:708px;bottom:680px;letter-spacing:0.13px;}
#t11_1{left:689px;bottom:615px;letter-spacing:0.12px;}
#t12_1{left:74px;bottom:720px;letter-spacing:0.08px;}
#t13_1{left:154px;bottom:720px;letter-spacing:0.1px;}
#t14_1{left:182px;bottom:720px;letter-spacing:-0.39px;}
#t15_1{left:708px;bottom:701px;letter-spacing:0.13px;}
#t16_1{left:693px;bottom:720px;letter-spacing:0.13px;}
#t17_1{left:710px;bottom:589px;letter-spacing:0.15px;}
#t18_1{left:710px;bottom:545px;letter-spacing:0.15px;}
#t19_1{left:95px;bottom:571px;letter-spacing:0.04px;}
#t1a_1{left:95px;bottom:550px;letter-spacing:0.06px;word-spacing:0.02px;}
#t1b_1{left:225px;bottom:550px;letter-spacing:0.14px;}
#t1c_1{left:538px;bottom:720px;letter-spacing:0.08px;}
#t1d_1{left:528px;bottom:615px;letter-spacing:0.08px;}
#t1e_1{left:710px;bottom:567px;letter-spacing:0.15px;}
#t1f_1{left:333px;bottom:463px;letter-spacing:0.15px;}
#t1g_1{left:169px;bottom:463px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1h_1{left:225px;bottom:528px;letter-spacing:-0.03px;}
#t1i_1{left:333px;bottom:506px;letter-spacing:0.14px;}
#t1j_1{left:225px;bottom:506px;word-spacing:0.13px;}
#t1k_1{left:476px;bottom:508px;letter-spacing:-0.25px;}
#t1l_1{left:333px;bottom:485px;letter-spacing:0.13px;}
#t1m_1{left:476px;bottom:486px;letter-spacing:-0.23px;word-spacing:0.63px;}
#t1n_1{left:727px;bottom:615px;letter-spacing:0.11px;}
#t1o_1{left:199px;bottom:485px;letter-spacing:0.09px;}
#t1p_1{left:794px;bottom:720px;letter-spacing:0.08px;}
#t1q_1{left:312px;bottom:550px;letter-spacing:0.11px;word-spacing:-0.03px;}
#t1r_1{left:333px;bottom:615px;letter-spacing:0.14px;}
#t1s_1{left:225px;bottom:615px;letter-spacing:0.01px;}
#t1t_1{left:225px;bottom:722px;letter-spacing:0.11px;word-spacing:0.02px;}
#t1u_1{left:225px;bottom:661px;letter-spacing:0.12px;word-spacing:0.02px;}
#t1v_1{left:95px;bottom:593px;letter-spacing:0.03px;}
#t1w_1{left:351px;bottom:592px;letter-spacing:-0.18px;}
#t1x_1{left:333px;bottom:225px;letter-spacing:0.14px;}
#t1y_1{left:708px;bottom:376px;letter-spacing:0.13px;}
#t1z_1{left:673px;bottom:312px;letter-spacing:0.11px;}
#t20_1{left:74px;bottom:417px;letter-spacing:0.08px;}
#t21_1{left:166px;bottom:417px;}
#t22_1{left:182px;bottom:417px;letter-spacing:-0.39px;}
#t23_1{left:708px;bottom:398px;letter-spacing:0.13px;}
#t24_1{left:674px;bottom:417px;letter-spacing:0.09px;}
#t25_1{left:710px;bottom:286px;letter-spacing:0.15px;}
#t26_1{left:710px;bottom:242px;letter-spacing:0.15px;}
#t27_1{left:95px;bottom:268px;letter-spacing:0.04px;}
#t28_1{left:95px;bottom:247px;letter-spacing:0.06px;word-spacing:0.02px;}
#t29_1{left:225px;bottom:247px;letter-spacing:0.14px;}
#t2a_1{left:538px;bottom:417px;letter-spacing:0.08px;}
#t2b_1{left:528px;bottom:312px;letter-spacing:0.08px;}
#t2c_1{left:710px;bottom:264px;letter-spacing:0.15px;}
#t2d_1{left:333px;bottom:160px;}
#t2e_1{left:169px;bottom:160px;letter-spacing:0.1px;word-spacing:0.03px;}
#t2f_1{left:225px;bottom:225px;letter-spacing:-0.03px;}
#t2g_1{left:333px;bottom:203px;letter-spacing:0.14px;}
#t2h_1{left:225px;bottom:203px;word-spacing:0.13px;}
#t2i_1{left:476px;bottom:204px;letter-spacing:-0.25px;}
#t2j_1{left:333px;bottom:182px;letter-spacing:0.14px;}
#t2k_1{left:476px;bottom:183px;letter-spacing:-0.23px;word-spacing:0.63px;}
#t2l_1{left:727px;bottom:312px;letter-spacing:0.11px;}
#t2m_1{left:199px;bottom:182px;letter-spacing:0.09px;}
#t2n_1{left:791px;bottom:417px;letter-spacing:0.11px;}
#t2o_1{left:312px;bottom:247px;letter-spacing:0.11px;word-spacing:-0.03px;}
#t2p_1{left:333px;bottom:312px;letter-spacing:0.15px;}
#t2q_1{left:225px;bottom:312px;letter-spacing:0.01px;}
#t2r_1{left:225px;bottom:419px;letter-spacing:0.11px;word-spacing:0.03px;}
#t2s_1{left:225px;bottom:358px;letter-spacing:0.13px;word-spacing:0.02px;}
#t2t_1{left:95px;bottom:290px;letter-spacing:0.03px;}
#t2u_1{left:351px;bottom:289px;letter-spacing:-0.18px;}
#t2v_1{left:847.4px;bottom:65.9px;letter-spacing:0.11px;}

.s1_1{font-size:12px;font-family:LibreFranklin-Regular_14;color:#000080;}
.s2_1{font-size:14px;font-family:LibreFranklin-Regular_14;color:#000;}
.s3_1{font-size:15px;font-family:LibreFranklin-Medium_15;color:#000;}
.s4_1{font-size:15px;font-family:LibreFranklin-Regular_14;color:#000;}
.s5_1{font-size:11px;font-family:LibreFranklin-Regular_14;color:#000;}
.s6_1{font-size:11px;font-family:LibreFranklin-Regular_14;color:#000080;}
.s7_1{font-size:9px;font-family:LibreFranklin-Regular_14;color:#000;}
.s8_1{font-size:12px;font-family:LibreFranklin-Regular_14;color:#000;}
.s9_1{font-size:12px;font-family:LibreFranklin-Medium_15;color:#000;}
.sa_1{font-size:14px;font-family:Monospac821BT_16;color:#00F;}
.t.m0_1{transform:matrix(0,-1,1,0,0,0);}
</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts1" type="text/css" >

@font-face {
	font-family: LibreFranklin-Medium_15;
	src: url("fonts/LibreFranklin-Medium_15.woff") format("woff");
}

@font-face {
	font-family: LibreFranklin-Regular_14;
	src: url("fonts/LibreFranklin-Regular_14.woff") format("woff");
}

@font-face {
	font-family: Monospac821BT_16;
	src: url("fonts/Monospac821BT_16.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg1Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg1" style="-webkit-user-select: none;"><object width="909" height="1286" data="1/1.svg" type="image/svg+xml" id="pdf1" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div class="text-container"><span id="t1_1" class="t s1_1">662433 </span>
<span id="t2_1" class="t s2_1">Pos </span><span id="t3_1" class="t s2_1">Menge </span><span id="t4_1" class="t s2_1">ME </span><span id="t5_1" class="t s2_1">Artikel </span><span id="t6_1" class="t s2_1">EP und Zuschläge </span><span id="t7_1" class="t s2_1">Summe EUR </span>
<span id="t8_1" class="t s3_1">BESTELL-Nr.: </span><span id="t9_1" class="t s3_1">92016 </span>
<span id="ta_1" class="t s4_1">Seite </span><span id="tb_1" class="t s4_1">1 </span>
<span id="tc_1" class="t s5_1">Sachbearbeiter </span>
<span id="td_1" class="t s5_1">Telefon </span><span id="te_1" class="t s5_1">05641 - 9006 - </span><span id="tf_1" class="t s5_1">81 </span>
<span id="tg_1" class="t s5_1">Michael Henke </span>
<span id="th_1" class="t s5_1">michael.henke@prg-gmbh.de </span><span id="ti_1" class="t s5_1">Email Sachbearb. </span>
<span id="tj_1" class="t s1_1">0711-4899922580 </span>
<span id="tk_1" class="t s6_1">Liefer.-Info </span>
<span id="tl_1" class="t s6_1">L-Kundennr </span><span id="tm_1" class="t s1_1">749490 </span>
<span id="tn_1" class="t s6_1">L-Telefon </span>
<span id="to_1" class="t s6_1">L-Telefax </span>
<span id="tp_1" class="t s4_1">Wir bestellen bei Ihnen folgende Artikel: </span>
<span id="tq_1" class="t s3_1">DATUM </span>
<span id="tr_1" class="t s3_1">70736 Fellbach </span>
<span id="ts_1" class="t s3_1">APSOparts GmbH </span>
<span id="tt_1" class="t s4_1">Ihr Angebot </span>
<span id="tu_1" class="t s4_1">/ </span><span id="tv_1" class="t s4_1">6 </span>
<span id="tw_1" class="t s4_1">Siemensstr. 5 </span>
<span id="tx_1" class="t s3_1">12.07.2024 </span>
<span id="ty_1" class="t s7_1">662433 </span>
<span id="tz_1" class="t s8_1">204-00164 </span>
<span id="t10_1" class="t s8_1">0,00 </span>
<span id="t11_1" class="t s8_1">65,93 </span>
<span id="t12_1" class="t s4_1">1,0 </span><span id="t13_1" class="t s4_1">20 </span><span id="t14_1" class="t s4_1">St </span>
<span id="t15_1" class="t s8_1">0,00 </span>
<span id="t16_1" class="t s4_1">65,93 </span>
<span id="t17_1" class="t s8_1">0,00% </span>
<span id="t18_1" class="t s8_1">0,00% </span>
<span id="t19_1" class="t s8_1">Kommission: </span>
<span id="t1a_1" class="t s9_1">Liefertermin an PRG: </span><span id="t1b_1" class="t s9_1">29/2024 </span>
<span id="t1c_1" class="t s4_1">Einzelpreis </span>
<span id="t1d_1" class="t s8_1">Einkaufspreis </span>
<span id="t1e_1" class="t s8_1">0,00% </span>
<span id="t1f_1" class="t s8_1">20 </span><span id="t1g_1" class="t s8_1">in PRG - LME </span>
<span id="t1h_1" class="t s8_1">Teilenummer </span>
<span id="t1i_1" class="t s8_1">0,020 </span><span id="t1j_1" class="t s8_1">Pos in Kg </span><span id="t1k_1" class="t s5_1">kg </span>
<span id="t1l_1" class="t s8_1">0,001 </span><span id="t1m_1" class="t s5_1">kg / St </span>
<span id="t1n_1" class="t s8_1">Einheit </span>
<span id="t1o_1" class="t s8_1">kg/ME </span>
<span id="t1p_1" class="t s4_1">13,19 </span>
<span id="t1q_1" class="t s9_1">Mittwoch - 17-Jul-2024 </span>
<span id="t1r_1" class="t s8_1">1120073256 </span><span id="t1s_1" class="t s8_1">Lief-.Best-Nr.: </span>
<span id="t1t_1" class="t s4_1">18,64x3,53 Viton O-Ring </span>
<span id="t1u_1" class="t s8_1">NORMATEC® O-Ring FKM 75.00-01 </span>
<span id="t1v_1" class="t s1_1">ZeichnungsNr.: </span><span id="t1w_1" class="t sa_1">INDEX </span>
<span id="t1x_1" class="t s8_1">204-01054 </span>
<span id="t1y_1" class="t s8_1">0,00 </span>
<span id="t1z_1" class="t s8_1">1.083,27 </span>
<span id="t20_1" class="t s4_1">2,0 </span><span id="t21_1" class="t s4_1">1 </span><span id="t22_1" class="t s4_1">St </span>
<span id="t23_1" class="t s8_1">0,00 </span>
<span id="t24_1" class="t s4_1">1.083,27 </span>
<span id="t25_1" class="t s8_1">0,00% </span>
<span id="t26_1" class="t s8_1">0,00% </span>
<span id="t27_1" class="t s8_1">Kommission: </span>
<span id="t28_1" class="t s9_1">Liefertermin an PRG: </span><span id="t29_1" class="t s9_1">29/2024 </span>
<span id="t2a_1" class="t s4_1">Einzelpreis </span>
<span id="t2b_1" class="t s8_1">Einkaufspreis </span>
<span id="t2c_1" class="t s8_1">0,00% </span>
<span id="t2d_1" class="t s8_1">1 </span><span id="t2e_1" class="t s8_1">in PRG - LME </span>
<span id="t2f_1" class="t s8_1">Teilenummer </span>
<span id="t2g_1" class="t s8_1">0,000 </span><span id="t2h_1" class="t s8_1">Pos in Kg </span><span id="t2i_1" class="t s5_1">kg </span>
<span id="t2j_1" class="t s8_1">0,000 </span><span id="t2k_1" class="t s5_1">kg / St </span>
<span id="t2l_1" class="t s8_1">Einheit </span>
<span id="t2m_1" class="t s8_1">kg/ME </span>
<span id="t2n_1" class="t s4_1">10,83 </span>
<span id="t2o_1" class="t s9_1">Mittwoch - 17-Jul-2024 </span>
<span id="t2p_1" class="t s8_1">1120075976 </span><span id="t2q_1" class="t s8_1">Lief-.Best-Nr.: </span>
<span id="t2r_1" class="t s4_1">253,37x5,33 Viton O-Ring </span>
<span id="t2s_1" class="t s8_1">NORMATEC FKM 75.00-01 </span>
<span id="t2t_1" class="t s1_1">ZeichnungsNr.: </span><span id="t2u_1" class="t sa_1">INDEX </span>
<span id="t2v_1" class="t m0_1 s7_1">F90 </span></div>
<!-- End text definitions -->


</div>


<div id="p2" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_2{left:74px;bottom:996px;letter-spacing:-0.53px;}
#t2_2{left:124px;bottom:996px;letter-spacing:-0.18px;}
#t3_2{left:177px;bottom:996px;letter-spacing:-0.18px;}
#t4_2{left:225px;bottom:1038px;letter-spacing:0.16px;}
#t5_2{left:74px;bottom:1038px;letter-spacing:-0.08px;}
#t6_2{left:593px;bottom:1038px;letter-spacing:0.08px;}
#t7_2{left:665px;bottom:1038px;}
#t8_2{left:359px;bottom:1038px;letter-spacing:0.13px;}
#t9_2{left:320px;bottom:1038px;letter-spacing:-0.05px;}
#ta_2{left:74px;bottom:1081px;letter-spacing:0.06px;}
#tb_2{left:160px;bottom:1086px;letter-spacing:0.11px;}
#tc_2{left:596px;bottom:996px;letter-spacing:-0.14px;word-spacing:-0.01px;}
#td_2{left:745px;bottom:996px;letter-spacing:-0.16px;word-spacing:-0.02px;}
#te_2{left:225px;bottom:996px;letter-spacing:-0.17px;}
#tf_2{left:225px;bottom:1081px;letter-spacing:0.13px;word-spacing:0.03px;}
#tg_2{left:697px;bottom:1038px;}
#th_2{left:722px;bottom:1038px;}
#ti_2{left:333px;bottom:751px;letter-spacing:0.14px;}
#tj_2{left:708px;bottom:902px;letter-spacing:0.13px;}
#tk_2{left:689px;bottom:837px;letter-spacing:0.1px;}
#tl_2{left:74px;bottom:943px;letter-spacing:0.09px;}
#tm_2{left:143px;bottom:943px;letter-spacing:0.11px;}
#tn_2{left:182px;bottom:943px;letter-spacing:-0.39px;}
#to_2{left:708px;bottom:924px;letter-spacing:0.13px;}
#tp_2{left:694px;bottom:943px;letter-spacing:0.1px;}
#tq_2{left:710px;bottom:811px;letter-spacing:0.15px;}
#tr_2{left:710px;bottom:768px;letter-spacing:0.15px;}
#ts_2{left:95px;bottom:794px;letter-spacing:0.04px;}
#tt_2{left:95px;bottom:772px;letter-spacing:0.06px;word-spacing:0.02px;}
#tu_2{left:225px;bottom:772px;letter-spacing:0.14px;}
#tv_2{left:538px;bottom:943px;letter-spacing:0.08px;}
#tw_2{left:528px;bottom:837px;letter-spacing:0.08px;}
#tx_2{left:710px;bottom:790px;letter-spacing:0.15px;}
#ty_2{left:333px;bottom:686px;letter-spacing:0.16px;}
#tz_2{left:169px;bottom:686px;letter-spacing:0.1px;word-spacing:0.03px;}
#t10_2{left:225px;bottom:751px;letter-spacing:-0.03px;}
#t11_2{left:333px;bottom:729px;letter-spacing:0.14px;}
#t12_2{left:225px;bottom:729px;word-spacing:0.13px;}
#t13_2{left:476px;bottom:730px;letter-spacing:-0.25px;}
#t14_2{left:333px;bottom:707px;letter-spacing:0.14px;}
#t15_2{left:476px;bottom:709px;letter-spacing:-0.23px;word-spacing:0.63px;}
#t16_2{left:727px;bottom:837px;letter-spacing:0.11px;}
#t17_2{left:199px;bottom:707px;letter-spacing:0.09px;}
#t18_2{left:782px;bottom:943px;letter-spacing:0.1px;}
#t19_2{left:312px;bottom:772px;letter-spacing:0.11px;word-spacing:-0.03px;}
#t1a_2{left:333px;bottom:837px;letter-spacing:0.12px;}
#t1b_2{left:225px;bottom:837px;letter-spacing:0.01px;}
#t1c_2{left:225px;bottom:945px;letter-spacing:0.12px;word-spacing:0.03px;}
#t1d_2{left:225px;bottom:926px;letter-spacing:0.1px;word-spacing:0.02px;}
#t1e_2{left:225px;bottom:884px;letter-spacing:0.13px;word-spacing:0.02px;}
#t1f_2{left:225px;bottom:816px;letter-spacing:0.09px;}
#t1g_2{left:331px;bottom:816px;}
#t1h_2{left:95px;bottom:816px;letter-spacing:0.03px;}
#t1i_2{left:351px;bottom:814px;letter-spacing:-0.18px;}
#t1j_2{left:333px;bottom:447px;letter-spacing:0.13px;}
#t1k_2{left:708px;bottom:599px;letter-spacing:0.13px;}
#t1l_2{left:688px;bottom:534px;letter-spacing:0.1px;}
#t1m_2{left:74px;bottom:640px;letter-spacing:0.11px;}
#t1n_2{left:154px;bottom:640px;letter-spacing:0.1px;}
#t1o_2{left:182px;bottom:640px;letter-spacing:-0.39px;}
#t1p_2{left:708px;bottom:621px;letter-spacing:0.13px;}
#t1q_2{left:693px;bottom:640px;letter-spacing:0.12px;}
#t1r_2{left:710px;bottom:508px;letter-spacing:0.15px;}
#t1s_2{left:710px;bottom:465px;letter-spacing:0.15px;}
#t1t_2{left:95px;bottom:491px;letter-spacing:0.04px;}
#t1u_2{left:95px;bottom:469px;letter-spacing:0.06px;word-spacing:0.02px;}
#t1v_2{left:225px;bottom:469px;letter-spacing:0.14px;}
#t1w_2{left:538px;bottom:640px;letter-spacing:0.08px;}
#t1x_2{left:528px;bottom:534px;letter-spacing:0.08px;}
#t1y_2{left:710px;bottom:486px;letter-spacing:0.15px;}
#t1z_2{left:333px;bottom:383px;letter-spacing:0.15px;}
#t20_2{left:169px;bottom:383px;letter-spacing:0.1px;word-spacing:0.03px;}
#t21_2{left:225px;bottom:447px;letter-spacing:-0.03px;}
#t22_2{left:333px;bottom:426px;letter-spacing:0.13px;}
#t23_2{left:225px;bottom:426px;word-spacing:0.13px;}
#t24_2{left:476px;bottom:427px;letter-spacing:-0.25px;}
#t25_2{left:333px;bottom:404px;letter-spacing:0.14px;}
#t26_2{left:476px;bottom:405px;letter-spacing:-0.23px;word-spacing:0.63px;}
#t27_2{left:727px;bottom:534px;letter-spacing:0.11px;}
#t28_2{left:199px;bottom:404px;letter-spacing:0.09px;}
#t29_2{left:799px;bottom:640px;letter-spacing:0.12px;}
#t2a_2{left:312px;bottom:469px;letter-spacing:0.11px;word-spacing:-0.03px;}
#t2b_2{left:333px;bottom:534px;letter-spacing:0.13px;}
#t2c_2{left:225px;bottom:534px;letter-spacing:0.01px;}
#t2d_2{left:225px;bottom:642px;letter-spacing:0.12px;word-spacing:0.03px;}
#t2e_2{left:225px;bottom:623px;letter-spacing:0.1px;word-spacing:0.03px;}
#t2f_2{left:225px;bottom:581px;letter-spacing:0.13px;word-spacing:0.02px;}
#t2g_2{left:225px;bottom:512px;letter-spacing:0.09px;}
#t2h_2{left:331px;bottom:512px;}
#t2i_2{left:95px;bottom:512px;letter-spacing:0.03px;}
#t2j_2{left:351px;bottom:511px;letter-spacing:-0.18px;}
#t2k_2{left:708px;bottom:296px;letter-spacing:0.13px;}
#t2l_2{left:74px;bottom:337px;letter-spacing:0.12px;}
#t2m_2{left:154px;bottom:337px;letter-spacing:0.1px;}
#t2n_2{left:182px;bottom:337px;letter-spacing:-0.39px;}
#t2o_2{left:708px;bottom:318px;letter-spacing:0.13px;}
#t2p_2{left:686px;bottom:337px;letter-spacing:0.12px;}
#t2q_2{left:538px;bottom:337px;letter-spacing:0.08px;}
#t2r_2{left:792px;bottom:337px;letter-spacing:0.11px;}
#t2s_2{left:225px;bottom:338px;letter-spacing:0.12px;word-spacing:0.03px;}
#t2t_2{left:225px;bottom:320px;letter-spacing:0.1px;word-spacing:0.03px;}
#t2u_2{left:225px;bottom:278px;letter-spacing:0.13px;word-spacing:0.02px;}
#t2v_2{left:847.4px;bottom:65.9px;letter-spacing:0.11px;}

.s1_2{font-size:14px;font-family:LibreFranklin-Regular_14;color:#000;}
.s2_2{font-size:15px;font-family:LibreFranklin-Medium_15;color:#000;}
.s3_2{font-size:15px;font-family:LibreFranklin-Regular_14;color:#000;}
.s4_2{font-size:9px;font-family:LibreFranklin-Regular_14;color:#000;}
.s5_2{font-size:12px;font-family:LibreFranklin-Regular_14;color:#000;}
.s6_2{font-size:12px;font-family:LibreFranklin-Medium_15;color:#000;}
.s7_2{font-size:11px;font-family:LibreFranklin-Regular_14;color:#000;}
.s8_2{font-size:12px;font-family:LibreFranklin-Regular_14;color:#000080;}
.s9_2{font-size:14px;font-family:Monospac821BT_16;color:#00F;}
.t.m0_2{transform:matrix(0,-1,1,0,0,0);}
</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts2" type="text/css" >

@font-face {
	font-family: LibreFranklin-Medium_15;
	src: url("fonts/LibreFranklin-Medium_15.woff") format("woff");
}

@font-face {
	font-family: LibreFranklin-Regular_14;
	src: url("fonts/LibreFranklin-Regular_14.woff") format("woff");
}

@font-face {
	font-family: Monospac821BT_16;
	src: url("fonts/Monospac821BT_16.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg2Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg2" style="-webkit-user-select: none;"><object width="909" height="1286" data="2/2.svg" type="image/svg+xml" id="pdf2" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div class="text-container"><span id="t1_2" class="t s1_2">Pos </span><span id="t2_2" class="t s1_2">Menge </span><span id="t3_2" class="t s1_2">ME </span>
<span id="t4_2" class="t s2_2">92016 </span><span id="t5_2" class="t s2_2">BESTELL-Nr.: </span><span id="t6_2" class="t s2_2">Seite </span><span id="t7_2" class="t s2_2">6 </span><span id="t8_2" class="t s2_2">12.07.2024 </span><span id="t9_2" class="t s2_2">vom: </span>
<span id="ta_2" class="t s3_2">Lieferant: </span>
<span id="tb_2" class="t s4_2">662433 </span>
<span id="tc_2" class="t s1_2">EP und Zuschläge </span><span id="td_2" class="t s1_2">Summe EUR </span><span id="te_2" class="t s1_2">Artikel </span>
<span id="tf_2" class="t s3_2">APSOparts GmbH </span>
<span id="tg_2" class="t s2_2">/ </span><span id="th_2" class="t s2_2">2 </span>
<span id="ti_2" class="t s5_2">204-02172 </span>
<span id="tj_2" class="t s5_2">0,00 </span>
<span id="tk_2" class="t s5_2">73,65 </span>
<span id="tl_2" class="t s3_2">3,0 </span><span id="tm_2" class="t s3_2">200 </span><span id="tn_2" class="t s3_2">St </span>
<span id="to_2" class="t s5_2">0,00 </span>
<span id="tp_2" class="t s3_2">73,65 </span>
<span id="tq_2" class="t s5_2">0,00% </span>
<span id="tr_2" class="t s5_2">0,00% </span>
<span id="ts_2" class="t s5_2">Kommission: </span>
<span id="tt_2" class="t s6_2">Liefertermin an PRG: </span><span id="tu_2" class="t s6_2">29/2024 </span>
<span id="tv_2" class="t s3_2">Einzelpreis </span>
<span id="tw_2" class="t s5_2">Einkaufspreis </span>
<span id="tx_2" class="t s5_2">0,00% </span>
<span id="ty_2" class="t s5_2">200 </span><span id="tz_2" class="t s5_2">in PRG - LME </span>
<span id="t10_2" class="t s5_2">Teilenummer </span>
<span id="t11_2" class="t s5_2">0,000 </span><span id="t12_2" class="t s5_2">Pos in Kg </span><span id="t13_2" class="t s7_2">kg </span>
<span id="t14_2" class="t s5_2">0,000 </span><span id="t15_2" class="t s7_2">kg / St </span>
<span id="t16_2" class="t s5_2">Einheit </span>
<span id="t17_2" class="t s5_2">kg/ME </span>
<span id="t18_2" class="t s3_2">147,30 </span>
<span id="t19_2" class="t s6_2">Mittwoch - 17-Jul-2024 </span>
<span id="t1a_2" class="t s5_2">1140013559 </span><span id="t1b_2" class="t s5_2">Lief-.Best-Nr.: </span>
<span id="t1c_2" class="t s3_2">78,97x3,53 EPDM FDA, O-Ring USP </span>
<span id="t1d_2" class="t s3_2">Class VI, EG 1935 </span>
<span id="t1e_2" class="t s5_2">HITEC DW EPDM 70.503-00 </span>
<span id="t1f_2" class="t s8_2">Z-ORing-EP_5598</span><span id="t1g_2" class="t s8_2">5 </span><span id="t1h_2" class="t s8_2">ZeichnungsNr.: </span><span id="t1i_2" class="t s9_2">INDEX </span>
<span id="t1j_2" class="t s5_2">204-02353 </span>
<span id="t1k_2" class="t s5_2">0,00 </span>
<span id="t1l_2" class="t s5_2">38,66 </span>
<span id="t1m_2" class="t s3_2">4,0 </span><span id="t1n_2" class="t s3_2">25 </span><span id="t1o_2" class="t s3_2">St </span>
<span id="t1p_2" class="t s5_2">0,00 </span>
<span id="t1q_2" class="t s3_2">38,66 </span>
<span id="t1r_2" class="t s5_2">0,00% </span>
<span id="t1s_2" class="t s5_2">0,00% </span>
<span id="t1t_2" class="t s5_2">Kommission: </span>
<span id="t1u_2" class="t s6_2">Liefertermin an PRG: </span><span id="t1v_2" class="t s6_2">29/2024 </span>
<span id="t1w_2" class="t s3_2">Einzelpreis </span>
<span id="t1x_2" class="t s5_2">Einkaufspreis </span>
<span id="t1y_2" class="t s5_2">0,00% </span>
<span id="t1z_2" class="t s5_2">25 </span><span id="t20_2" class="t s5_2">in PRG - LME </span>
<span id="t21_2" class="t s5_2">Teilenummer </span>
<span id="t22_2" class="t s5_2">0,010 </span><span id="t23_2" class="t s5_2">Pos in Kg </span><span id="t24_2" class="t s7_2">kg </span>
<span id="t25_2" class="t s5_2">0,000 </span><span id="t26_2" class="t s7_2">kg / St </span>
<span id="t27_2" class="t s5_2">Einheit </span>
<span id="t28_2" class="t s5_2">kg/ME </span>
<span id="t29_2" class="t s3_2">9,67 </span>
<span id="t2a_2" class="t s6_2">Mittwoch - 17-Jul-2024 </span>
<span id="t2b_2" class="t s5_2">1140012624 </span><span id="t2c_2" class="t s5_2">Lief-.Best-Nr.: </span>
<span id="t2d_2" class="t s3_2">20,29x2,62 EPDM FDA O-Ring USP </span>
<span id="t2e_2" class="t s3_2">Class VI, EG1935 </span>
<span id="t2f_2" class="t s5_2">HITEC® DW EPDM 70.503-00 </span>
<span id="t2g_2" class="t s8_2">Z-ORing-EP_5598</span><span id="t2h_2" class="t s8_2">5 </span><span id="t2i_2" class="t s8_2">ZeichnungsNr.: </span><span id="t2j_2" class="t s9_2">INDEX </span>
<span id="t2k_2" class="t s5_2">0,00 </span>
<span id="t2l_2" class="t s3_2">5,0 </span><span id="t2m_2" class="t s3_2">25 </span><span id="t2n_2" class="t s3_2">St </span>
<span id="t2o_2" class="t s5_2">0,00 </span>
<span id="t2p_2" class="t s3_2">134,44 </span><span id="t2q_2" class="t s3_2">Einzelpreis </span><span id="t2r_2" class="t s3_2">33,61 </span>
<span id="t2s_2" class="t s3_2">91,67x3,53 EPDM FDA O-Ring USP </span>
<span id="t2t_2" class="t s3_2">Class VI, EG1935 </span>
<span id="t2u_2" class="t s5_2">HITEC® DW EPDM 70.503-00 </span>
<span id="t2v_2" class="t m0_2 s4_2">F90 </span></div>
<!-- End text definitions -->


</div>


<div id="p3" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_3{left:74px;bottom:996px;letter-spacing:-0.53px;}
#t2_3{left:124px;bottom:996px;letter-spacing:-0.18px;}
#t3_3{left:177px;bottom:996px;letter-spacing:-0.18px;}
#t4_3{left:225px;bottom:1038px;letter-spacing:0.16px;}
#t5_3{left:74px;bottom:1038px;letter-spacing:-0.08px;}
#t6_3{left:593px;bottom:1038px;letter-spacing:0.08px;}
#t7_3{left:665px;bottom:1038px;}
#t8_3{left:359px;bottom:1038px;letter-spacing:0.13px;}
#t9_3{left:320px;bottom:1038px;letter-spacing:-0.05px;}
#ta_3{left:74px;bottom:1081px;letter-spacing:0.06px;}
#tb_3{left:160px;bottom:1086px;letter-spacing:0.11px;}
#tc_3{left:596px;bottom:996px;letter-spacing:-0.14px;word-spacing:-0.01px;}
#td_3{left:745px;bottom:996px;letter-spacing:-0.16px;word-spacing:-0.02px;}
#te_3{left:225px;bottom:996px;letter-spacing:-0.17px;}
#tf_3{left:225px;bottom:1081px;letter-spacing:0.13px;word-spacing:0.03px;}
#tg_3{left:697px;bottom:1038px;}
#th_3{left:722px;bottom:1038px;}
#ti_3{left:333px;bottom:870px;letter-spacing:0.14px;}
#tj_3{left:683px;bottom:956px;letter-spacing:0.08px;}
#tk_3{left:710px;bottom:931px;letter-spacing:0.15px;}
#tl_3{left:710px;bottom:887px;letter-spacing:0.15px;}
#tm_3{left:95px;bottom:913px;letter-spacing:0.04px;}
#tn_3{left:95px;bottom:892px;letter-spacing:0.06px;word-spacing:0.02px;}
#to_3{left:225px;bottom:892px;letter-spacing:0.14px;}
#tp_3{left:528px;bottom:956px;letter-spacing:0.08px;}
#tq_3{left:710px;bottom:909px;letter-spacing:0.15px;}
#tr_3{left:333px;bottom:805px;letter-spacing:0.15px;}
#ts_3{left:169px;bottom:805px;letter-spacing:0.1px;word-spacing:0.03px;}
#tt_3{left:225px;bottom:870px;letter-spacing:-0.03px;}
#tu_3{left:333px;bottom:848px;letter-spacing:0.14px;}
#tv_3{left:225px;bottom:848px;word-spacing:0.13px;}
#tw_3{left:476px;bottom:849px;letter-spacing:-0.25px;}
#tx_3{left:333px;bottom:827px;letter-spacing:0.14px;}
#ty_3{left:476px;bottom:828px;letter-spacing:-0.23px;word-spacing:0.63px;}
#tz_3{left:727px;bottom:956px;letter-spacing:0.11px;}
#t10_3{left:199px;bottom:827px;letter-spacing:0.09px;}
#t11_3{left:312px;bottom:892px;letter-spacing:0.11px;word-spacing:-0.03px;}
#t12_3{left:333px;bottom:956px;letter-spacing:0.13px;}
#t13_3{left:225px;bottom:956px;letter-spacing:0.01px;}
#t14_3{left:225px;bottom:935px;letter-spacing:0.09px;}
#t15_3{left:331px;bottom:935px;}
#t16_3{left:95px;bottom:935px;letter-spacing:0.03px;}
#t17_3{left:351px;bottom:933px;letter-spacing:-0.18px;}
#t18_3{left:333px;bottom:567px;letter-spacing:0.15px;}
#t19_3{left:708px;bottom:718px;letter-spacing:0.13px;}
#t1a_3{left:684px;bottom:653px;letter-spacing:0.09px;}
#t1b_3{left:74px;bottom:759px;letter-spacing:0.12px;}
#t1c_3{left:154px;bottom:759px;letter-spacing:0.1px;}
#t1d_3{left:182px;bottom:759px;letter-spacing:-0.39px;}
#t1e_3{left:708px;bottom:740px;letter-spacing:0.13px;}
#t1f_3{left:687px;bottom:759px;letter-spacing:0.11px;}
#t1g_3{left:710px;bottom:627px;letter-spacing:0.15px;}
#t1h_3{left:710px;bottom:584px;letter-spacing:0.15px;}
#t1i_3{left:95px;bottom:610px;letter-spacing:0.04px;}
#t1j_3{left:95px;bottom:588px;letter-spacing:0.06px;word-spacing:0.02px;}
#t1k_3{left:225px;bottom:588px;letter-spacing:0.14px;}
#t1l_3{left:538px;bottom:759px;letter-spacing:0.08px;}
#t1m_3{left:528px;bottom:653px;letter-spacing:0.08px;}
#t1n_3{left:710px;bottom:606px;letter-spacing:0.15px;}
#t1o_3{left:333px;bottom:502px;letter-spacing:0.15px;}
#t1p_3{left:169px;bottom:502px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1q_3{left:225px;bottom:567px;letter-spacing:-0.03px;}
#t1r_3{left:333px;bottom:545px;letter-spacing:0.14px;}
#t1s_3{left:225px;bottom:545px;word-spacing:0.13px;}
#t1t_3{left:476px;bottom:546px;letter-spacing:-0.25px;}
#t1u_3{left:333px;bottom:523px;letter-spacing:0.14px;}
#t1v_3{left:476px;bottom:525px;letter-spacing:-0.23px;word-spacing:0.63px;}
#t1w_3{left:727px;bottom:653px;letter-spacing:0.11px;}
#t1x_3{left:199px;bottom:523px;letter-spacing:0.09px;}
#t1y_3{left:789px;bottom:759px;letter-spacing:0.1px;}
#t1z_3{left:312px;bottom:588px;letter-spacing:0.11px;word-spacing:-0.03px;}
#t20_3{left:333px;bottom:653px;letter-spacing:0.12px;}
#t21_3{left:225px;bottom:653px;letter-spacing:0.01px;}
#t22_3{left:225px;bottom:761px;letter-spacing:0.11px;word-spacing:0.03px;}
#t23_3{left:225px;bottom:742px;letter-spacing:0.1px;word-spacing:0.03px;}
#t24_3{left:225px;bottom:700px;letter-spacing:0.11px;word-spacing:0.02px;}
#t25_3{left:225px;bottom:632px;letter-spacing:0.08px;}
#t26_3{left:95px;bottom:632px;letter-spacing:0.03px;}
#t27_3{left:351px;bottom:630px;letter-spacing:-0.18px;}
#t28_3{left:333px;bottom:264px;letter-spacing:0.15px;}
#t29_3{left:708px;bottom:415px;letter-spacing:0.13px;}
#t2a_3{left:689px;bottom:350px;letter-spacing:0.1px;}
#t2b_3{left:74px;bottom:456px;letter-spacing:0.08px;}
#t2c_3{left:153px;bottom:456px;letter-spacing:0.19px;}
#t2d_3{left:182px;bottom:456px;letter-spacing:-0.39px;}
#t2e_3{left:708px;bottom:437px;letter-spacing:0.13px;}
#t2f_3{left:694px;bottom:456px;letter-spacing:0.1px;}
#t2g_3{left:710px;bottom:324px;letter-spacing:0.15px;}
#t2h_3{left:710px;bottom:281px;letter-spacing:0.15px;}
#t2i_3{left:95px;bottom:307px;letter-spacing:0.04px;}
#t2j_3{left:95px;bottom:285px;letter-spacing:0.06px;word-spacing:0.02px;}
#t2k_3{left:225px;bottom:285px;letter-spacing:0.14px;}
#t2l_3{left:538px;bottom:456px;letter-spacing:0.08px;}
#t2m_3{left:528px;bottom:350px;letter-spacing:0.08px;}
#t2n_3{left:710px;bottom:303px;letter-spacing:0.15px;}
#t2o_3{left:333px;bottom:199px;letter-spacing:0.13px;}
#t2p_3{left:169px;bottom:199px;letter-spacing:0.1px;word-spacing:0.03px;}
#t2q_3{left:225px;bottom:264px;letter-spacing:-0.03px;}
#t2r_3{left:333px;bottom:242px;letter-spacing:0.14px;}
#t2s_3{left:225px;bottom:242px;word-spacing:0.13px;}
#t2t_3{left:476px;bottom:243px;letter-spacing:-0.25px;}
#t2u_3{left:333px;bottom:220px;letter-spacing:0.14px;}
#t2v_3{left:476px;bottom:222px;letter-spacing:-0.23px;word-spacing:0.63px;}
#t2w_3{left:727px;bottom:350px;letter-spacing:0.11px;}
#t2x_3{left:199px;bottom:220px;letter-spacing:0.09px;}
#t2y_3{left:789px;bottom:456px;letter-spacing:0.12px;}
#t2z_3{left:312px;bottom:285px;letter-spacing:0.11px;word-spacing:-0.03px;}
#t30_3{left:333px;bottom:350px;letter-spacing:0.12px;}
#t31_3{left:225px;bottom:350px;letter-spacing:0.01px;}
#t32_3{left:225px;bottom:458px;letter-spacing:0.12px;word-spacing:0.03px;}
#t33_3{left:225px;bottom:439px;letter-spacing:0.1px;word-spacing:0.03px;}
#t34_3{left:225px;bottom:397px;letter-spacing:0.13px;word-spacing:0.02px;}
#t35_3{left:225px;bottom:329px;letter-spacing:0.09px;}
#t36_3{left:331px;bottom:329px;}
#t37_3{left:95px;bottom:329px;letter-spacing:0.03px;}
#t38_3{left:351px;bottom:327px;letter-spacing:-0.18px;}
#t39_3{left:847.4px;bottom:65.9px;letter-spacing:0.11px;}

.s1_3{font-size:14px;font-family:LibreFranklin-Regular_14;color:#000;}
.s2_3{font-size:15px;font-family:LibreFranklin-Medium_15;color:#000;}
.s3_3{font-size:15px;font-family:LibreFranklin-Regular_14;color:#000;}
.s4_3{font-size:9px;font-family:LibreFranklin-Regular_14;color:#000;}
.s5_3{font-size:12px;font-family:LibreFranklin-Regular_14;color:#000;}
.s6_3{font-size:12px;font-family:LibreFranklin-Medium_15;color:#000;}
.s7_3{font-size:11px;font-family:LibreFranklin-Regular_14;color:#000;}
.s8_3{font-size:12px;font-family:LibreFranklin-Regular_14;color:#000080;}
.s9_3{font-size:14px;font-family:Monospac821BT_16;color:#00F;}
.t.m0_3{transform:matrix(0,-1,1,0,0,0);}
</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts3" type="text/css" >

@font-face {
	font-family: LibreFranklin-Medium_15;
	src: url("fonts/LibreFranklin-Medium_15.woff") format("woff");
}

@font-face {
	font-family: LibreFranklin-Regular_14;
	src: url("fonts/LibreFranklin-Regular_14.woff") format("woff");
}

@font-face {
	font-family: Monospac821BT_16;
	src: url("fonts/Monospac821BT_16.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg3Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg3" style="-webkit-user-select: none;"><object width="909" height="1286" data="3/3.svg" type="image/svg+xml" id="pdf3" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div class="text-container"><span id="t1_3" class="t s1_3">Pos </span><span id="t2_3" class="t s1_3">Menge </span><span id="t3_3" class="t s1_3">ME </span>
<span id="t4_3" class="t s2_3">92016 </span><span id="t5_3" class="t s2_3">BESTELL-Nr.: </span><span id="t6_3" class="t s2_3">Seite </span><span id="t7_3" class="t s2_3">6 </span><span id="t8_3" class="t s2_3">12.07.2024 </span><span id="t9_3" class="t s2_3">vom: </span>
<span id="ta_3" class="t s3_3">Lieferant: </span>
<span id="tb_3" class="t s4_3">662433 </span>
<span id="tc_3" class="t s1_3">EP und Zuschläge </span><span id="td_3" class="t s1_3">Summe EUR </span><span id="te_3" class="t s1_3">Artikel </span>
<span id="tf_3" class="t s3_3">APSOparts GmbH </span>
<span id="tg_3" class="t s2_3">/ </span><span id="th_3" class="t s2_3">3 </span>
<span id="ti_3" class="t s5_3">204-02467 </span>
<span id="tj_3" class="t s5_3">134,44 </span>
<span id="tk_3" class="t s5_3">0,00% </span>
<span id="tl_3" class="t s5_3">0,00% </span>
<span id="tm_3" class="t s5_3">Kommission: </span>
<span id="tn_3" class="t s6_3">Liefertermin an PRG: </span><span id="to_3" class="t s6_3">29/2024 </span>
<span id="tp_3" class="t s5_3">Einkaufspreis </span>
<span id="tq_3" class="t s5_3">0,00% </span>
<span id="tr_3" class="t s5_3">25 </span><span id="ts_3" class="t s5_3">in PRG - LME </span>
<span id="tt_3" class="t s5_3">Teilenummer </span>
<span id="tu_3" class="t s5_3">0,088 </span><span id="tv_3" class="t s5_3">Pos in Kg </span><span id="tw_3" class="t s7_3">kg </span>
<span id="tx_3" class="t s5_3">0,004 </span><span id="ty_3" class="t s7_3">kg / St </span>
<span id="tz_3" class="t s5_3">Einheit </span>
<span id="t10_3" class="t s5_3">kg/ME </span>
<span id="t11_3" class="t s6_3">Mittwoch - 17-Jul-2024 </span>
<span id="t12_3" class="t s5_3">1140013563 </span><span id="t13_3" class="t s5_3">Lief-.Best-Nr.: </span>
<span id="t14_3" class="t s8_3">Z-ORing-EP_5598</span><span id="t15_3" class="t s8_3">5 </span><span id="t16_3" class="t s8_3">ZeichnungsNr.: </span><span id="t17_3" class="t s9_3">INDEX </span>
<span id="t18_3" class="t s5_3">204-02606 </span>
<span id="t19_3" class="t s5_3">0,00 </span>
<span id="t1a_3" class="t s5_3">137,45 </span>
<span id="t1b_3" class="t s3_3">6,0 </span><span id="t1c_3" class="t s3_3">25 </span><span id="t1d_3" class="t s3_3">St </span>
<span id="t1e_3" class="t s5_3">0,00 </span>
<span id="t1f_3" class="t s3_3">137,45 </span>
<span id="t1g_3" class="t s5_3">0,00% </span>
<span id="t1h_3" class="t s5_3">0,00% </span>
<span id="t1i_3" class="t s5_3">Kommission: </span>
<span id="t1j_3" class="t s6_3">Liefertermin an PRG: </span><span id="t1k_3" class="t s6_3">29/2024 </span>
<span id="t1l_3" class="t s3_3">Einzelpreis </span>
<span id="t1m_3" class="t s5_3">Einkaufspreis </span>
<span id="t1n_3" class="t s5_3">0,00% </span>
<span id="t1o_3" class="t s5_3">25 </span><span id="t1p_3" class="t s5_3">in PRG - LME </span>
<span id="t1q_3" class="t s5_3">Teilenummer </span>
<span id="t1r_3" class="t s5_3">0,000 </span><span id="t1s_3" class="t s5_3">Pos in Kg </span><span id="t1t_3" class="t s7_3">kg </span>
<span id="t1u_3" class="t s5_3">0,000 </span><span id="t1v_3" class="t s7_3">kg / St </span>
<span id="t1w_3" class="t s5_3">Einheit </span>
<span id="t1x_3" class="t s5_3">kg/ME </span>
<span id="t1y_3" class="t s3_3">34,36 </span>
<span id="t1z_3" class="t s6_3">Mittwoch - 17-Jul-2024 </span>
<span id="t20_3" class="t s5_3">1140353516 </span><span id="t21_3" class="t s5_3">Lief-.Best-Nr.: </span>
<span id="t22_3" class="t s3_3">26,57x3,53 FKM FDA O-Ring Temp. - </span>
<span id="t23_3" class="t s3_3">20/+200 Grad C USP Class VI, EG1935 </span>
<span id="t24_3" class="t s5_3">HITEC® O-Ring FKM 75.16-04 </span>
<span id="t25_3" class="t s8_3">Z-ORing-FKM-5149 </span><span id="t26_3" class="t s8_3">ZeichnungsNr.: </span><span id="t27_3" class="t s9_3">INDEX </span>
<span id="t28_3" class="t s5_3">204-02608 </span>
<span id="t29_3" class="t s5_3">0,00 </span>
<span id="t2a_3" class="t s5_3">53,29 </span>
<span id="t2b_3" class="t s3_3">7,0 </span><span id="t2c_3" class="t s3_3">50 </span><span id="t2d_3" class="t s3_3">St </span>
<span id="t2e_3" class="t s5_3">0,00 </span>
<span id="t2f_3" class="t s3_3">53,29 </span>
<span id="t2g_3" class="t s5_3">0,00% </span>
<span id="t2h_3" class="t s5_3">0,00% </span>
<span id="t2i_3" class="t s5_3">Kommission: </span>
<span id="t2j_3" class="t s6_3">Liefertermin an PRG: </span><span id="t2k_3" class="t s6_3">29/2024 </span>
<span id="t2l_3" class="t s3_3">Einzelpreis </span>
<span id="t2m_3" class="t s5_3">Einkaufspreis </span>
<span id="t2n_3" class="t s5_3">0,00% </span>
<span id="t2o_3" class="t s5_3">50 </span><span id="t2p_3" class="t s5_3">in PRG - LME </span>
<span id="t2q_3" class="t s5_3">Teilenummer </span>
<span id="t2r_3" class="t s5_3">0,000 </span><span id="t2s_3" class="t s5_3">Pos in Kg </span><span id="t2t_3" class="t s7_3">kg </span>
<span id="t2u_3" class="t s5_3">0,000 </span><span id="t2v_3" class="t s7_3">kg / St </span>
<span id="t2w_3" class="t s5_3">Einheit </span>
<span id="t2x_3" class="t s5_3">kg/ME </span>
<span id="t2y_3" class="t s3_3">26,65 </span>
<span id="t2z_3" class="t s6_3">Mittwoch - 17-Jul-2024 </span>
<span id="t30_3" class="t s5_3">1140013519 </span><span id="t31_3" class="t s5_3">Lief-.Best-Nr.: </span>
<span id="t32_3" class="t s3_3">31,34x3,53 EPDM FDA O-Ring USP </span>
<span id="t33_3" class="t s3_3">Class VI, EG1935 </span>
<span id="t34_3" class="t s5_3">HITEC DW EPDM 70.503-00 </span>
<span id="t35_3" class="t s8_3">Z-ORing-EP_5598</span><span id="t36_3" class="t s8_3">5 </span><span id="t37_3" class="t s8_3">ZeichnungsNr.: </span><span id="t38_3" class="t s9_3">INDEX </span>
<span id="t39_3" class="t m0_3 s4_3">F90 </span></div>
<!-- End text definitions -->


</div>

<div id="p4" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_4{left:74px;bottom:996px;letter-spacing:-0.53px;}
#t2_4{left:124px;bottom:996px;letter-spacing:-0.18px;}
#t3_4{left:177px;bottom:996px;letter-spacing:-0.18px;}
#t4_4{left:225px;bottom:1038px;letter-spacing:0.16px;}
#t5_4{left:74px;bottom:1038px;letter-spacing:-0.08px;}
#t6_4{left:593px;bottom:1038px;letter-spacing:0.08px;}
#t7_4{left:665px;bottom:1038px;}
#t8_4{left:359px;bottom:1038px;letter-spacing:0.13px;}
#t9_4{left:320px;bottom:1038px;letter-spacing:-0.05px;}
#ta_4{left:74px;bottom:1081px;letter-spacing:0.06px;}
#tb_4{left:160px;bottom:1086px;letter-spacing:0.11px;}
#tc_4{left:596px;bottom:996px;letter-spacing:-0.14px;word-spacing:-0.01px;}
#td_4{left:745px;bottom:996px;letter-spacing:-0.16px;word-spacing:-0.02px;}
#te_4{left:225px;bottom:996px;letter-spacing:-0.17px;}
#tf_4{left:225px;bottom:1081px;letter-spacing:0.13px;word-spacing:0.03px;}
#tg_4{left:697px;bottom:1038px;}
#th_4{left:722px;bottom:1038px;}
#ti_4{left:333px;bottom:762px;letter-spacing:0.14px;}
#tj_4{left:708px;bottom:913px;letter-spacing:0.13px;}
#tk_4{left:683px;bottom:848px;letter-spacing:0.12px;}
#tl_4{left:74px;bottom:954px;letter-spacing:0.11px;}
#tm_4{left:154px;bottom:954px;letter-spacing:0.1px;}
#tn_4{left:182px;bottom:954px;letter-spacing:-0.39px;}
#to_4{left:708px;bottom:935px;letter-spacing:0.13px;}
#tp_4{left:686px;bottom:954px;letter-spacing:0.1px;}
#tq_4{left:710px;bottom:822px;letter-spacing:0.15px;}
#tr_4{left:710px;bottom:779px;letter-spacing:0.15px;}
#ts_4{left:95px;bottom:805px;letter-spacing:0.04px;}
#tt_4{left:95px;bottom:783px;letter-spacing:0.06px;word-spacing:0.02px;}
#tu_4{left:225px;bottom:783px;letter-spacing:0.14px;}
#tv_4{left:538px;bottom:954px;letter-spacing:0.08px;}
#tw_4{left:528px;bottom:848px;letter-spacing:0.08px;}
#tx_4{left:710px;bottom:801px;letter-spacing:0.15px;}
#ty_4{left:333px;bottom:697px;letter-spacing:0.15px;}
#tz_4{left:169px;bottom:697px;letter-spacing:0.1px;word-spacing:0.03px;}
#t10_4{left:225px;bottom:762px;letter-spacing:-0.03px;}
#t11_4{left:333px;bottom:740px;letter-spacing:0.12px;}
#t12_4{left:225px;bottom:740px;word-spacing:0.13px;}
#t13_4{left:476px;bottom:741px;letter-spacing:-0.25px;}
#t14_4{left:333px;bottom:718px;letter-spacing:0.14px;}
#t15_4{left:476px;bottom:720px;letter-spacing:-0.23px;word-spacing:0.63px;}
#t16_4{left:727px;bottom:848px;letter-spacing:0.11px;}
#t17_4{left:199px;bottom:718px;letter-spacing:0.09px;}
#t18_4{left:789px;bottom:954px;letter-spacing:0.12px;}
#t19_4{left:312px;bottom:783px;letter-spacing:0.11px;word-spacing:-0.03px;}
#t1a_4{left:333px;bottom:848px;letter-spacing:0.14px;}
#t1b_4{left:225px;bottom:848px;letter-spacing:0.01px;}
#t1c_4{left:225px;bottom:956px;letter-spacing:0.12px;word-spacing:0.03px;}
#t1d_4{left:225px;bottom:937px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1e_4{left:225px;bottom:895px;letter-spacing:0.13px;word-spacing:0.01px;}
#t1f_4{left:225px;bottom:827px;letter-spacing:0.09px;}
#t1g_4{left:331px;bottom:827px;}
#t1h_4{left:95px;bottom:827px;letter-spacing:0.03px;}
#t1i_4{left:351px;bottom:825px;letter-spacing:-0.18px;}
#t1j_4{left:333px;bottom:458px;letter-spacing:0.14px;}
#t1k_4{left:708px;bottom:610px;letter-spacing:0.13px;}
#t1l_4{left:689px;bottom:545px;letter-spacing:0.12px;}
#t1m_4{left:74px;bottom:651px;letter-spacing:0.09px;}
#t1n_4{left:153px;bottom:651px;letter-spacing:0.19px;}
#t1o_4{left:182px;bottom:651px;letter-spacing:-0.39px;}
#t1p_4{left:708px;bottom:632px;letter-spacing:0.13px;}
#t1q_4{left:693px;bottom:651px;letter-spacing:0.11px;}
#t1r_4{left:710px;bottom:519px;letter-spacing:0.15px;}
#t1s_4{left:710px;bottom:476px;letter-spacing:0.15px;}
#t1t_4{left:95px;bottom:502px;letter-spacing:0.04px;}
#t1u_4{left:95px;bottom:480px;letter-spacing:0.06px;word-spacing:0.02px;}
#t1v_4{left:225px;bottom:480px;letter-spacing:0.14px;}
#t1w_4{left:538px;bottom:651px;letter-spacing:0.08px;}
#t1x_4{left:528px;bottom:545px;letter-spacing:0.08px;}
#t1y_4{left:710px;bottom:497px;letter-spacing:0.15px;}
#t1z_4{left:333px;bottom:394px;letter-spacing:0.13px;}
#t20_4{left:169px;bottom:394px;letter-spacing:0.1px;word-spacing:0.03px;}
#t21_4{left:225px;bottom:458px;letter-spacing:-0.03px;}
#t22_4{left:333px;bottom:437px;letter-spacing:0.14px;}
#t23_4{left:225px;bottom:437px;word-spacing:0.13px;}
#t24_4{left:476px;bottom:438px;letter-spacing:-0.25px;}
#t25_4{left:333px;bottom:415px;letter-spacing:0.14px;}
#t26_4{left:476px;bottom:416px;letter-spacing:-0.23px;word-spacing:0.63px;}
#t27_4{left:727px;bottom:545px;letter-spacing:0.11px;}
#t28_4{left:199px;bottom:415px;letter-spacing:0.09px;}
#t29_4{left:789px;bottom:651px;letter-spacing:0.1px;}
#t2a_4{left:312px;bottom:480px;letter-spacing:0.11px;word-spacing:-0.03px;}
#t2b_4{left:333px;bottom:545px;letter-spacing:0.13px;}
#t2c_4{left:225px;bottom:545px;letter-spacing:0.01px;}
#t2d_4{left:225px;bottom:653px;letter-spacing:0.13px;word-spacing:0.02px;}
#t2e_4{left:225px;bottom:634px;letter-spacing:0.1px;word-spacing:0.03px;}
#t2f_4{left:225px;bottom:592px;letter-spacing:0.13px;word-spacing:0.01px;}
#t2g_4{left:225px;bottom:523px;letter-spacing:0.09px;}
#t2h_4{left:331px;bottom:523px;}
#t2i_4{left:95px;bottom:523px;letter-spacing:0.03px;}
#t2j_4{left:351px;bottom:522px;letter-spacing:-0.18px;}
#t2k_4{left:708px;bottom:307px;letter-spacing:0.13px;}
#t2l_4{left:74px;bottom:348px;letter-spacing:0.09px;}
#t2m_4{left:156px;bottom:348px;letter-spacing:0.11px;}
#t2n_4{left:182px;bottom:348px;letter-spacing:-0.39px;}
#t2o_4{left:708px;bottom:329px;letter-spacing:0.13px;}
#t2p_4{left:687px;bottom:348px;letter-spacing:0.1px;}
#t2q_4{left:538px;bottom:348px;letter-spacing:0.08px;}
#t2r_4{left:791px;bottom:348px;letter-spacing:0.11px;}
#t2s_4{left:225px;bottom:349px;letter-spacing:0.12px;word-spacing:0.02px;}
#t2t_4{left:225px;bottom:331px;letter-spacing:0.1px;word-spacing:0.03px;}
#t2u_4{left:225px;bottom:289px;letter-spacing:0.12px;word-spacing:0.02px;}
#t2v_4{left:847.4px;bottom:65.9px;letter-spacing:0.11px;}

.s1_4{font-size:14px;font-family:LibreFranklin-Regular_14;color:#000;}
.s2_4{font-size:15px;font-family:LibreFranklin-Medium_15;color:#000;}
.s3_4{font-size:15px;font-family:LibreFranklin-Regular_14;color:#000;}
.s4_4{font-size:9px;font-family:LibreFranklin-Regular_14;color:#000;}
.s5_4{font-size:12px;font-family:LibreFranklin-Regular_14;color:#000;}
.s6_4{font-size:12px;font-family:LibreFranklin-Medium_15;color:#000;}
.s7_4{font-size:11px;font-family:LibreFranklin-Regular_14;color:#000;}
.s8_4{font-size:12px;font-family:LibreFranklin-Regular_14;color:#000080;}
.s9_4{font-size:14px;font-family:Monospac821BT_16;color:#00F;}
.t.m0_4{transform:matrix(0,-1,1,0,0,0);}
</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts4" type="text/css" >

@font-face {
	font-family: LibreFranklin-Medium_15;
	src: url("fonts/LibreFranklin-Medium_15.woff") format("woff");
}

@font-face {
	font-family: LibreFranklin-Regular_14;
	src: url("fonts/LibreFranklin-Regular_14.woff") format("woff");
}

@font-face {
	font-family: Monospac821BT_16;
	src: url("fonts/Monospac821BT_16.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg4Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg4" style="-webkit-user-select: none;"><object width="909" height="1286" data="4/4.svg" type="image/svg+xml" id="pdf4" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div class="text-container"><span id="t1_4" class="t s1_4">Pos </span><span id="t2_4" class="t s1_4">Menge </span><span id="t3_4" class="t s1_4">ME </span>
<span id="t4_4" class="t s2_4">92016 </span><span id="t5_4" class="t s2_4">BESTELL-Nr.: </span><span id="t6_4" class="t s2_4">Seite </span><span id="t7_4" class="t s2_4">6 </span><span id="t8_4" class="t s2_4">12.07.2024 </span><span id="t9_4" class="t s2_4">vom: </span>
<span id="ta_4" class="t s3_4">Lieferant: </span>
<span id="tb_4" class="t s4_4">662433 </span>
<span id="tc_4" class="t s1_4">EP und Zuschläge </span><span id="td_4" class="t s1_4">Summe EUR </span><span id="te_4" class="t s1_4">Artikel </span>
<span id="tf_4" class="t s3_4">APSOparts GmbH </span>
<span id="tg_4" class="t s2_4">/ </span><span id="th_4" class="t s2_4">4 </span>
<span id="ti_4" class="t s5_4">204-02621 </span>
<span id="tj_4" class="t s5_4">0,00 </span>
<span id="tk_4" class="t s5_4">182,90 </span>
<span id="tl_4" class="t s3_4">8,0 </span><span id="tm_4" class="t s3_4">25 </span><span id="tn_4" class="t s3_4">St </span>
<span id="to_4" class="t s5_4">0,00 </span>
<span id="tp_4" class="t s3_4">182,90 </span>
<span id="tq_4" class="t s5_4">0,00% </span>
<span id="tr_4" class="t s5_4">0,00% </span>
<span id="ts_4" class="t s5_4">Kommission: </span>
<span id="tt_4" class="t s6_4">Liefertermin an PRG: </span><span id="tu_4" class="t s6_4">29/2024 </span>
<span id="tv_4" class="t s3_4">Einzelpreis </span>
<span id="tw_4" class="t s5_4">Einkaufspreis </span>
<span id="tx_4" class="t s5_4">0,00% </span>
<span id="ty_4" class="t s5_4">25 </span><span id="tz_4" class="t s5_4">in PRG - LME </span>
<span id="t10_4" class="t s5_4">Teilenummer </span>
<span id="t11_4" class="t s5_4">0,038 </span><span id="t12_4" class="t s5_4">Pos in Kg </span><span id="t13_4" class="t s7_4">kg </span>
<span id="t14_4" class="t s5_4">0,002 </span><span id="t15_4" class="t s7_4">kg / St </span>
<span id="t16_4" class="t s5_4">Einheit </span>
<span id="t17_4" class="t s5_4">kg/ME </span>
<span id="t18_4" class="t s3_4">45,73 </span>
<span id="t19_4" class="t s6_4">Mittwoch - 17-Jul-2024 </span>
<span id="t1a_4" class="t s5_4">1140202658 </span><span id="t1b_4" class="t s5_4">Lief-.Best-Nr.: </span>
<span id="t1c_4" class="t s3_4">69,52x2,62 EPDM FDA, O-Ring USP </span>
<span id="t1d_4" class="t s3_4">Class VI, EG1935 </span>
<span id="t1e_4" class="t s5_4">HITEC EPDM 70.10-02 </span>
<span id="t1f_4" class="t s8_4">Z-ORing-EP_5598</span><span id="t1g_4" class="t s8_4">5 </span><span id="t1h_4" class="t s8_4">ZeichnungsNr.: </span><span id="t1i_4" class="t s9_4">INDEX </span>
<span id="t1j_4" class="t s5_4">204-02630 </span>
<span id="t1k_4" class="t s5_4">0,00 </span>
<span id="t1l_4" class="t s5_4">76,04 </span>
<span id="t1m_4" class="t s3_4">9,0 </span><span id="t1n_4" class="t s3_4">50 </span><span id="t1o_4" class="t s3_4">St </span>
<span id="t1p_4" class="t s5_4">0,00 </span>
<span id="t1q_4" class="t s3_4">76,04 </span>
<span id="t1r_4" class="t s5_4">0,00% </span>
<span id="t1s_4" class="t s5_4">0,00% </span>
<span id="t1t_4" class="t s5_4">Kommission: </span>
<span id="t1u_4" class="t s6_4">Liefertermin an PRG: </span><span id="t1v_4" class="t s6_4">29/2024 </span>
<span id="t1w_4" class="t s3_4">Einzelpreis </span>
<span id="t1x_4" class="t s5_4">Einkaufspreis </span>
<span id="t1y_4" class="t s5_4">0,00% </span>
<span id="t1z_4" class="t s5_4">50 </span><span id="t20_4" class="t s5_4">in PRG - LME </span>
<span id="t21_4" class="t s5_4">Teilenummer </span>
<span id="t22_4" class="t s5_4">0,000 </span><span id="t23_4" class="t s5_4">Pos in Kg </span><span id="t24_4" class="t s7_4">kg </span>
<span id="t25_4" class="t s5_4">0,000 </span><span id="t26_4" class="t s7_4">kg / St </span>
<span id="t27_4" class="t s5_4">Einheit </span>
<span id="t28_4" class="t s5_4">kg/ME </span>
<span id="t29_4" class="t s3_4">38,02 </span>
<span id="t2a_4" class="t s6_4">Mittwoch - 17-Jul-2024 </span>
<span id="t2b_4" class="t s5_4">1140203552 </span><span id="t2c_4" class="t s5_4">Lief-.Best-Nr.: </span>
<span id="t2d_4" class="t s3_4">69,44x3,53 EPDM FDA O-Ring USP </span>
<span id="t2e_4" class="t s3_4">Class VI, EG1935 </span>
<span id="t2f_4" class="t s5_4">HITEC EPDM 70.10-02 </span>
<span id="t2g_4" class="t s8_4">Z-ORing-EP_5598</span><span id="t2h_4" class="t s8_4">5 </span><span id="t2i_4" class="t s8_4">ZeichnungsNr.: </span><span id="t2j_4" class="t s9_4">INDEX </span>
<span id="t2k_4" class="t s5_4">0,00 </span>
<span id="t2l_4" class="t s3_4">10,0 </span><span id="t2m_4" class="t s3_4">10 </span><span id="t2n_4" class="t s3_4">St </span>
<span id="t2o_4" class="t s5_4">0,00 </span>
<span id="t2p_4" class="t s3_4">160,72 </span><span id="t2q_4" class="t s3_4">Einzelpreis </span><span id="t2r_4" class="t s3_4">16,07 </span>
<span id="t2s_4" class="t s3_4">85,32x3,53 EPDM FDA O-Ring USP </span>
<span id="t2t_4" class="t s3_4">Class VI, EG1935 </span>
<span id="t2u_4" class="t s5_4">HITEC® O-Ring EPDM 70.10-02 </span>
<span id="t2v_4" class="t m0_4 s4_4">F90 </span></div>
<!-- End text definitions -->


</div>



<div id="p5" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_5{left:74px;bottom:996px;letter-spacing:-0.53px;}
#t2_5{left:124px;bottom:996px;letter-spacing:-0.18px;}
#t3_5{left:177px;bottom:996px;letter-spacing:-0.18px;}
#t4_5{left:225px;bottom:1038px;letter-spacing:0.16px;}
#t5_5{left:74px;bottom:1038px;letter-spacing:-0.08px;}
#t6_5{left:593px;bottom:1038px;letter-spacing:0.08px;}
#t7_5{left:665px;bottom:1038px;}
#t8_5{left:359px;bottom:1038px;letter-spacing:0.13px;}
#t9_5{left:320px;bottom:1038px;letter-spacing:-0.05px;}
#ta_5{left:74px;bottom:1081px;letter-spacing:0.06px;}
#tb_5{left:160px;bottom:1086px;letter-spacing:0.11px;}
#tc_5{left:596px;bottom:996px;letter-spacing:-0.14px;word-spacing:-0.01px;}
#td_5{left:745px;bottom:996px;letter-spacing:-0.16px;word-spacing:-0.02px;}
#te_5{left:225px;bottom:996px;letter-spacing:-0.17px;}
#tf_5{left:225px;bottom:1081px;letter-spacing:0.13px;word-spacing:0.03px;}
#tg_5{left:697px;bottom:1038px;}
#th_5{left:722px;bottom:1038px;}
#ti_5{left:333px;bottom:870px;letter-spacing:0.14px;}
#tj_5{left:684px;bottom:956px;letter-spacing:0.12px;}
#tk_5{left:710px;bottom:931px;letter-spacing:0.15px;}
#tl_5{left:710px;bottom:887px;letter-spacing:0.15px;}
#tm_5{left:95px;bottom:913px;letter-spacing:0.04px;}
#tn_5{left:95px;bottom:892px;letter-spacing:0.06px;word-spacing:0.02px;}
#to_5{left:225px;bottom:892px;letter-spacing:0.14px;}
#tp_5{left:528px;bottom:956px;letter-spacing:0.08px;}
#tq_5{left:710px;bottom:909px;letter-spacing:0.15px;}
#tr_5{left:333px;bottom:805px;letter-spacing:0.13px;}
#ts_5{left:169px;bottom:805px;letter-spacing:0.1px;word-spacing:0.03px;}
#tt_5{left:225px;bottom:870px;letter-spacing:-0.03px;}
#tu_5{left:333px;bottom:848px;letter-spacing:0.14px;}
#tv_5{left:225px;bottom:848px;word-spacing:0.13px;}
#tw_5{left:476px;bottom:849px;letter-spacing:-0.25px;}
#tx_5{left:333px;bottom:827px;letter-spacing:0.14px;}
#ty_5{left:476px;bottom:828px;letter-spacing:-0.23px;word-spacing:0.63px;}
#tz_5{left:727px;bottom:956px;letter-spacing:0.11px;}
#t10_5{left:199px;bottom:827px;letter-spacing:0.09px;}
#t11_5{left:312px;bottom:892px;letter-spacing:0.05px;word-spacing:0.03px;}
#t12_5{left:333px;bottom:956px;letter-spacing:0.13px;}
#t13_5{left:225px;bottom:956px;letter-spacing:0.01px;}
#t14_5{left:225px;bottom:935px;letter-spacing:0.09px;}
#t15_5{left:331px;bottom:935px;}
#t16_5{left:95px;bottom:935px;letter-spacing:0.03px;}
#t17_5{left:351px;bottom:933px;letter-spacing:-0.18px;}
#t18_5{left:333px;bottom:567px;letter-spacing:0.14px;}
#t19_5{left:708px;bottom:718px;letter-spacing:0.13px;}
#t1a_5{left:672px;bottom:653px;letter-spacing:0.12px;}
#t1b_5{left:74px;bottom:759px;letter-spacing:0.08px;}
#t1c_5{left:166px;bottom:759px;}
#t1d_5{left:182px;bottom:759px;letter-spacing:-0.39px;}
#t1e_5{left:708px;bottom:740px;letter-spacing:0.13px;}
#t1f_5{left:672px;bottom:759px;letter-spacing:0.11px;}
#t1g_5{left:710px;bottom:627px;letter-spacing:0.15px;}
#t1h_5{left:710px;bottom:584px;letter-spacing:0.15px;}
#t1i_5{left:95px;bottom:610px;letter-spacing:0.04px;}
#t1j_5{left:95px;bottom:588px;letter-spacing:0.06px;word-spacing:0.02px;}
#t1k_5{left:225px;bottom:588px;letter-spacing:0.14px;}
#t1l_5{left:538px;bottom:759px;letter-spacing:0.08px;}
#t1m_5{left:528px;bottom:653px;letter-spacing:0.08px;}
#t1n_5{left:710px;bottom:606px;letter-spacing:0.15px;}
#t1o_5{left:333px;bottom:502px;}
#t1p_5{left:169px;bottom:502px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1q_5{left:225px;bottom:567px;letter-spacing:-0.03px;}
#t1r_5{left:333px;bottom:545px;letter-spacing:0.14px;}
#t1s_5{left:225px;bottom:545px;word-spacing:0.13px;}
#t1t_5{left:476px;bottom:546px;letter-spacing:-0.25px;}
#t1u_5{left:333px;bottom:523px;letter-spacing:0.14px;}
#t1v_5{left:476px;bottom:525px;letter-spacing:-0.23px;word-spacing:0.63px;}
#t1w_5{left:727px;bottom:653px;letter-spacing:0.11px;}
#t1x_5{left:199px;bottom:523px;letter-spacing:0.09px;}
#t1y_5{left:789px;bottom:759px;letter-spacing:0.11px;}
#t1z_5{left:312px;bottom:588px;letter-spacing:0.05px;word-spacing:0.03px;}
#t20_5{left:333px;bottom:653px;letter-spacing:0.12px;}
#t21_5{left:225px;bottom:653px;letter-spacing:0.01px;}
#t22_5{left:225px;bottom:761px;letter-spacing:0.11px;word-spacing:0.03px;}
#t23_5{left:225px;bottom:742px;letter-spacing:0.1px;word-spacing:0.03px;}
#t24_5{left:225px;bottom:700px;letter-spacing:0.11px;word-spacing:0.03px;}
#t25_5{left:225px;bottom:632px;letter-spacing:0.08px;}
#t26_5{left:95px;bottom:632px;letter-spacing:0.03px;}
#t27_5{left:351px;bottom:630px;letter-spacing:-0.18px;}
#t28_5{left:333px;bottom:264px;letter-spacing:0.14px;}
#t29_5{left:708px;bottom:415px;letter-spacing:0.13px;}
#t2a_5{left:673px;bottom:350px;letter-spacing:0.09px;}
#t2b_5{left:74px;bottom:456px;letter-spacing:0.08px;}
#t2c_5{left:164px;bottom:456px;}
#t2d_5{left:182px;bottom:456px;letter-spacing:-0.39px;}
#t2e_5{left:708px;bottom:437px;letter-spacing:0.13px;}
#t2f_5{left:674px;bottom:456px;letter-spacing:0.12px;}
#t2g_5{left:710px;bottom:324px;letter-spacing:0.15px;}
#t2h_5{left:710px;bottom:281px;letter-spacing:0.15px;}
#t2i_5{left:95px;bottom:307px;letter-spacing:0.04px;}
#t2j_5{left:95px;bottom:285px;letter-spacing:0.06px;word-spacing:0.02px;}
#t2k_5{left:225px;bottom:285px;letter-spacing:0.14px;}
#t2l_5{left:538px;bottom:456px;letter-spacing:0.08px;}
#t2m_5{left:528px;bottom:350px;letter-spacing:0.08px;}
#t2n_5{left:710px;bottom:303px;letter-spacing:0.15px;}
#t2o_5{left:333px;bottom:199px;}
#t2p_5{left:169px;bottom:199px;letter-spacing:0.1px;word-spacing:0.03px;}
#t2q_5{left:225px;bottom:264px;letter-spacing:-0.03px;}
#t2r_5{left:333px;bottom:242px;letter-spacing:0.14px;}
#t2s_5{left:225px;bottom:242px;word-spacing:0.13px;}
#t2t_5{left:476px;bottom:243px;letter-spacing:-0.25px;}
#t2u_5{left:333px;bottom:220px;letter-spacing:0.14px;}
#t2v_5{left:476px;bottom:222px;letter-spacing:-0.23px;word-spacing:0.63px;}
#t2w_5{left:727px;bottom:350px;letter-spacing:0.11px;}
#t2x_5{left:199px;bottom:220px;letter-spacing:0.09px;}
#t2y_5{left:788px;bottom:456px;letter-spacing:0.12px;}
#t2z_5{left:312px;bottom:285px;letter-spacing:0.05px;word-spacing:0.03px;}
#t30_5{left:333px;bottom:350px;letter-spacing:0.13px;}
#t31_5{left:225px;bottom:350px;letter-spacing:0.01px;}
#t32_5{left:225px;bottom:458px;letter-spacing:0.11px;word-spacing:0.03px;}
#t33_5{left:225px;bottom:439px;letter-spacing:0.1px;word-spacing:0.03px;}
#t34_5{left:225px;bottom:421px;letter-spacing:0.13px;}
#t35_5{left:225px;bottom:397px;letter-spacing:0.11px;word-spacing:0.02px;}
#t36_5{left:225px;bottom:329px;letter-spacing:0.08px;}
#t37_5{left:95px;bottom:329px;letter-spacing:0.03px;}
#t38_5{left:351px;bottom:327px;letter-spacing:-0.18px;}
#t39_5{left:847.4px;bottom:65.9px;letter-spacing:0.11px;}

.s1_5{font-size:14px;font-family:LibreFranklin-Regular_14;color:#000;}
.s2_5{font-size:15px;font-family:LibreFranklin-Medium_15;color:#000;}
.s3_5{font-size:15px;font-family:LibreFranklin-Regular_14;color:#000;}
.s4_5{font-size:9px;font-family:LibreFranklin-Regular_14;color:#000;}
.s5_5{font-size:12px;font-family:LibreFranklin-Regular_14;color:#000;}
.s6_5{font-size:12px;font-family:LibreFranklin-Medium_15;color:#000;}
.s7_5{font-size:11px;font-family:LibreFranklin-Regular_14;color:#000;}
.s8_5{font-size:12px;font-family:LibreFranklin-Regular_14;color:#000080;}
.s9_5{font-size:14px;font-family:Monospac821BT_16;color:#00F;}
.t.m0_5{transform:matrix(0,-1,1,0,0,0);}
</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts5" type="text/css" >

@font-face {
	font-family: LibreFranklin-Medium_15;
	src: url("fonts/LibreFranklin-Medium_15.woff") format("woff");
}

@font-face {
	font-family: LibreFranklin-Regular_14;
	src: url("fonts/LibreFranklin-Regular_14.woff") format("woff");
}

@font-face {
	font-family: Monospac821BT_16;
	src: url("fonts/Monospac821BT_16.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg5Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg5" style="-webkit-user-select: none;"><object width="909" height="1286" data="5/5.svg" type="image/svg+xml" id="pdf5" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div class="text-container"><span id="t1_5" class="t s1_5">Pos </span><span id="t2_5" class="t s1_5">Menge </span><span id="t3_5" class="t s1_5">ME </span>
<span id="t4_5" class="t s2_5">92016 </span><span id="t5_5" class="t s2_5">BESTELL-Nr.: </span><span id="t6_5" class="t s2_5">Seite </span><span id="t7_5" class="t s2_5">6 </span><span id="t8_5" class="t s2_5">12.07.2024 </span><span id="t9_5" class="t s2_5">vom: </span>
<span id="ta_5" class="t s3_5">Lieferant: </span>
<span id="tb_5" class="t s4_5">662433 </span>
<span id="tc_5" class="t s1_5">EP und Zuschläge </span><span id="td_5" class="t s1_5">Summe EUR </span><span id="te_5" class="t s1_5">Artikel </span>
<span id="tf_5" class="t s3_5">APSOparts GmbH </span>
<span id="tg_5" class="t s2_5">/ </span><span id="th_5" class="t s2_5">5 </span>
<span id="ti_5" class="t s5_5">204-02636 </span>
<span id="tj_5" class="t s5_5">160,72 </span>
<span id="tk_5" class="t s5_5">0,00% </span>
<span id="tl_5" class="t s5_5">0,00% </span>
<span id="tm_5" class="t s5_5">Kommission: </span>
<span id="tn_5" class="t s6_5">Liefertermin an PRG: </span><span id="to_5" class="t s6_5">29/2024 </span>
<span id="tp_5" class="t s5_5">Einkaufspreis </span>
<span id="tq_5" class="t s5_5">0,00% </span>
<span id="tr_5" class="t s5_5">10 </span><span id="ts_5" class="t s5_5">in PRG - LME </span>
<span id="tt_5" class="t s5_5">Teilenummer </span>
<span id="tu_5" class="t s5_5">0,000 </span><span id="tv_5" class="t s5_5">Pos in Kg </span><span id="tw_5" class="t s7_5">kg </span>
<span id="tx_5" class="t s5_5">0,000 </span><span id="ty_5" class="t s7_5">kg / St </span>
<span id="tz_5" class="t s5_5">Einheit </span>
<span id="t10_5" class="t s5_5">kg/ME </span>
<span id="t11_5" class="t s6_5">Freitag - 19-Jul-2024 </span>
<span id="t12_5" class="t s5_5">1140203561 </span><span id="t13_5" class="t s5_5">Lief-.Best-Nr.: </span>
<span id="t14_5" class="t s8_5">Z-ORing-EP_5598</span><span id="t15_5" class="t s8_5">5 </span><span id="t16_5" class="t s8_5">ZeichnungsNr.: </span><span id="t17_5" class="t s9_5">INDEX </span>
<span id="t18_5" class="t s5_5">204-02713 </span>
<span id="t19_5" class="t s5_5">0,00 </span>
<span id="t1a_5" class="t s5_5">4.075,72 </span>
<span id="t1b_5" class="t s3_5">11,0 </span><span id="t1c_5" class="t s3_5">1 </span><span id="t1d_5" class="t s3_5">St </span>
<span id="t1e_5" class="t s5_5">0,00 </span>
<span id="t1f_5" class="t s3_5">4.075,72 </span>
<span id="t1g_5" class="t s5_5">0,00% </span>
<span id="t1h_5" class="t s5_5">0,00% </span>
<span id="t1i_5" class="t s5_5">Kommission: </span>
<span id="t1j_5" class="t s6_5">Liefertermin an PRG: </span><span id="t1k_5" class="t s6_5">29/2024 </span>
<span id="t1l_5" class="t s3_5">Einzelpreis </span>
<span id="t1m_5" class="t s5_5">Einkaufspreis </span>
<span id="t1n_5" class="t s5_5">0,00% </span>
<span id="t1o_5" class="t s5_5">1 </span><span id="t1p_5" class="t s5_5">in PRG - LME </span>
<span id="t1q_5" class="t s5_5">Teilenummer </span>
<span id="t1r_5" class="t s5_5">0,000 </span><span id="t1s_5" class="t s5_5">Pos in Kg </span><span id="t1t_5" class="t s7_5">kg </span>
<span id="t1u_5" class="t s5_5">0,000 </span><span id="t1v_5" class="t s7_5">kg / St </span>
<span id="t1w_5" class="t s5_5">Einheit </span>
<span id="t1x_5" class="t s5_5">kg/ME </span>
<span id="t1y_5" class="t s3_5">40,76 </span>
<span id="t1z_5" class="t s6_5">Freitag - 19-Jul-2024 </span>
<span id="t20_5" class="t s5_5">1140355391 </span><span id="t21_5" class="t s5_5">Lief-.Best-Nr.: </span>
<span id="t22_5" class="t s3_5">354,97x5,33 Viton FDA O-Ring USP </span>
<span id="t23_5" class="t s3_5">Class VI, EG1935 </span>
<span id="t24_5" class="t s5_5">FKM HITEC 75.16-04 </span>
<span id="t25_5" class="t s8_5">Z-ORing-FKM-5149 </span><span id="t26_5" class="t s8_5">ZeichnungsNr.: </span><span id="t27_5" class="t s9_5">INDEX </span>
<span id="t28_5" class="t s5_5">204-02830 </span>
<span id="t29_5" class="t s5_5">0,00 </span>
<span id="t2a_5" class="t s5_5">4.741,45 </span>
<span id="t2b_5" class="t s3_5">12,0 </span><span id="t2c_5" class="t s3_5">2 </span><span id="t2d_5" class="t s3_5">St </span>
<span id="t2e_5" class="t s5_5">0,00 </span>
<span id="t2f_5" class="t s3_5">4.741,45 </span>
<span id="t2g_5" class="t s5_5">0,00% </span>
<span id="t2h_5" class="t s5_5">0,00% </span>
<span id="t2i_5" class="t s5_5">Kommission: </span>
<span id="t2j_5" class="t s6_5">Liefertermin an PRG: </span><span id="t2k_5" class="t s6_5">29/2024 </span>
<span id="t2l_5" class="t s3_5">Einzelpreis </span>
<span id="t2m_5" class="t s5_5">Einkaufspreis </span>
<span id="t2n_5" class="t s5_5">0,00% </span>
<span id="t2o_5" class="t s5_5">2 </span><span id="t2p_5" class="t s5_5">in PRG - LME </span>
<span id="t2q_5" class="t s5_5">Teilenummer </span>
<span id="t2r_5" class="t s5_5">0,000 </span><span id="t2s_5" class="t s5_5">Pos in Kg </span><span id="t2t_5" class="t s7_5">kg </span>
<span id="t2u_5" class="t s5_5">0,000 </span><span id="t2v_5" class="t s7_5">kg / St </span>
<span id="t2w_5" class="t s5_5">Einheit </span>
<span id="t2x_5" class="t s5_5">kg/ME </span>
<span id="t2y_5" class="t s3_5">94,83 </span>
<span id="t2z_5" class="t s6_5">Freitag - 19-Jul-2024 </span>
<span id="t30_5" class="t s5_5">1140357065 </span><span id="t31_5" class="t s5_5">Lief-.Best-Nr.: </span>
<span id="t32_5" class="t s3_5">443,36x6,99 Viton FDA O-Ring Temp. </span>
<span id="t33_5" class="t s3_5">-20/+200 Grad C USP Class VI, </span>
<span id="t34_5" class="t s3_5">EG1935 </span>
<span id="t35_5" class="t s5_5">HITEC FKM 75.16-04 </span>
<span id="t36_5" class="t s8_5">Z-ORing-FKM-5149 </span><span id="t37_5" class="t s8_5">ZeichnungsNr.: </span><span id="t38_5" class="t s9_5">INDEX </span>
<span id="t39_5" class="t m0_5 s4_5">F90 </span></div>
<!-- End text definitions -->


</div>




<div id="p6" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_6{left:74px;bottom:996px;letter-spacing:-0.53px;}
#t2_6{left:124px;bottom:996px;letter-spacing:-0.18px;}
#t3_6{left:177px;bottom:996px;letter-spacing:-0.18px;}
#t4_6{left:225px;bottom:1038px;letter-spacing:0.16px;}
#t5_6{left:74px;bottom:1038px;letter-spacing:-0.08px;}
#t6_6{left:593px;bottom:1038px;letter-spacing:0.08px;}
#t7_6{left:665px;bottom:1038px;}
#t8_6{left:359px;bottom:1038px;letter-spacing:0.13px;}
#t9_6{left:320px;bottom:1038px;letter-spacing:-0.05px;}
#ta_6{left:74px;bottom:1081px;letter-spacing:0.06px;}
#tb_6{left:160px;bottom:1086px;letter-spacing:0.11px;}
#tc_6{left:596px;bottom:996px;letter-spacing:-0.14px;word-spacing:-0.01px;}
#td_6{left:745px;bottom:996px;letter-spacing:-0.16px;word-spacing:-0.02px;}
#te_6{left:225px;bottom:996px;letter-spacing:-0.17px;}
#tf_6{left:225px;bottom:1081px;letter-spacing:0.13px;word-spacing:0.03px;}
#tg_6{left:697px;bottom:1038px;}
#th_6{left:722px;bottom:1038px;}
#ti_6{left:784px;bottom:911px;letter-spacing:0.1px;}
#tj_6{left:783px;bottom:846px;letter-spacing:0.12px;}
#tk_6{left:398px;bottom:911px;letter-spacing:0.07px;word-spacing:0.08px;}
#tl_6{left:398px;bottom:889px;letter-spacing:0.12px;}
#tm_6{left:463px;bottom:889px;}
#tn_6{left:797px;bottom:889px;letter-spacing:0.1px;}
#to_6{left:398px;bottom:868px;letter-spacing:0.1px;word-spacing:-0.02px;}
#tp_6{left:398px;bottom:716px;letter-spacing:0.11px;word-spacing:-0.02px;}
#tq_6{left:618px;bottom:716px;letter-spacing:0.09px;word-spacing:-0.02px;}
#tr_6{left:398px;bottom:846px;letter-spacing:0.1px;word-spacing:-0.01px;}
#ts_6{left:398px;bottom:805px;letter-spacing:0.07px;word-spacing:0.07px;}
#tt_6{left:398px;bottom:783px;letter-spacing:0.08px;word-spacing:0.05px;}
#tu_6{left:398px;bottom:762px;letter-spacing:0.02px;word-spacing:0.11px;}
#tv_6{left:485px;bottom:893px;letter-spacing:-0.28px;word-spacing:0.12px;}
#tw_6{left:74px;bottom:804px;letter-spacing:-0.18px;}
#tx_6{left:74px;bottom:782px;letter-spacing:-0.18px;}
#ty_6{left:74px;bottom:760px;letter-spacing:-0.18px;}
#tz_6{left:74px;bottom:739px;letter-spacing:-0.18px;}
#t10_6{left:78px;bottom:193px;letter-spacing:-0.2px;}
#t11_6{left:78px;bottom:172px;letter-spacing:-0.21px;}
#t12_6{left:78px;bottom:150px;letter-spacing:-0.27px;}
#t13_6{left:242px;bottom:172px;letter-spacing:-0.13px;}
#t14_6{left:242px;bottom:193px;letter-spacing:-0.3px;word-spacing:0.12px;}
#t15_6{left:242px;bottom:150px;letter-spacing:-0.13px;}
#t16_6{left:714px;bottom:193px;letter-spacing:-0.18px;}
#t17_6{left:520px;bottom:193px;letter-spacing:-0.21px;word-spacing:0.02px;}
#t18_6{left:805px;bottom:193px;letter-spacing:-0.24px;}
#t19_6{left:520px;bottom:150px;letter-spacing:-0.18px;word-spacing:0.01px;}
#t1a_6{left:851.8px;bottom:63.4px;letter-spacing:0.11px;}

.s1{font-size:14px;font-family:LibreFranklin-Regular_14;color:#000;}
.s2{font-size:15px;font-family:LibreFranklin-Medium_15;color:#000;}
.s3{font-size:15px;font-family:LibreFranklin-Regular_14;color:#000;}
.s4{font-size:9px;font-family:LibreFranklin-Regular_14;color:#000;}
.s5{font-size:12px;font-family:LibreFranklin-Italic_f;color:#000;}
.s6{font-size:11px;font-family:LibreFranklin-Regular_14;color:#000;}
.s7{font-size:14px;font-family:Monospac821BT_16;color:#00F;}
.t.m0_6{transform:matrix(0,-1,1,0,0,0);}
</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts6" type="text/css" >

@font-face {
	font-family: LibreFranklin-Italic_f;
	src: url("fonts/LibreFranklin-Italic_f.woff") format("woff");
}

@font-face {
	font-family: LibreFranklin-Medium_15;
	src: url("fonts/LibreFranklin-Medium_15.woff") format("woff");
}

@font-face {
	font-family: LibreFranklin-Regular_14;
	src: url("fonts/LibreFranklin-Regular_14.woff") format("woff");
}

@font-face {
	font-family: Monospac821BT_16;
	src: url("fonts/Monospac821BT_16.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg6Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg6" style="-webkit-user-select: none;"><object width="909" height="1286" data="6/6.svg" type="image/svg+xml" id="pdf6" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div class="text-container"><span id="t1_6" class="t s1">Pos </span><span id="t2_6" class="t s1">Menge </span><span id="t3_6" class="t s1">ME </span>
<span id="t4_6" class="t s2">92016 </span><span id="t5_6" class="t s2">BESTELL-Nr.: </span><span id="t6_6" class="t s2">Seite </span><span id="t7_6" class="t s2">6 </span><span id="t8_6" class="t s2">12.07.2024 </span><span id="t9_6" class="t s2">vom: </span>
<span id="ta_6" class="t s3">Lieferant: </span>
<span id="tb_6" class="t s4">662433 </span>
<span id="tc_6" class="t s1">EP und Zuschläge </span><span id="td_6" class="t s1">Summe EUR </span><span id="te_6" class="t s1">Artikel </span>
<span id="tf_6" class="t s3">APSOparts GmbH </span>
<span id="tg_6" class="t s2">/ </span><span id="th_6" class="t s2">6 </span>
<span id="ti_6" class="t s3">511,02 </span>
<span id="tj_6" class="t s2">511,02 </span>
<span id="tk_6" class="t s3">Zwischensumme Warenwert </span>
<span id="tl_6" class="t s3">Zuschläg</span><span id="tm_6" class="t s3">e </span><span id="tn_6" class="t s3">0,00 </span>
<span id="to_6" class="t s2">Bestellwert in EUR </span>
<span id="tp_6" class="t s2">PRG Präzisions-Rührer GmbH </span><span id="tq_6" class="t s2">/ Abtlg. Einkauf </span>
<span id="tr_6" class="t s2">alle Beträge sind ohne MWST </span>
<span id="ts_6" class="t s5">Liefertermine sind eingehend bei PRG Warburg </span>
<span id="tt_6" class="t s5">Geben Sie zu allen Belegen unsere Bestellnummer mit an! </span>
<span id="tu_6" class="t s5">Weisen Sie in allen Positionen die Zolltarifnummer (Stat.-WarenNr.) aus </span>
<span id="tv_6" class="t s6">Porto, Fracht, Verpack. </span>
<span id="tw_6" class="t s7">LIEFERADRESSE </span>
<span id="tx_6" class="t s7">Präzisions-Rührer-GmbH </span>
<span id="ty_6" class="t s7">Anton-Böhlen-Str. 13 </span>
<span id="tz_6" class="t s7">D-34414 Warburg </span>
<span id="t10_6" class="t s6">Zahlungsbedingung </span>
<span id="t11_6" class="t s6">Lieferbedingung </span>
<span id="t12_6" class="t s6">Versandart </span>
<span id="t13_6" class="t s6">n.n. </span>
<span id="t14_6" class="t s6">30 Tage netto </span>
<span id="t15_6" class="t s6">n.n. </span>
<span id="t16_6" class="t s6">0,156 </span><span id="t17_6" class="t s6">Gesamtgewicht der Bestellung </span><span id="t18_6" class="t s6">kg </span>
<span id="t19_6" class="t s6">inländische Lieferung </span>
<span id="t1a_6" class="t m0_6 s4">F90 </span></div>
<!-- End text definitions -->


</div>

</body>
</html>

  `,
  'Bestellung-92148-APSO': `
  <!DOCTYPE html>
<html lang="en">
<head>
<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
<meta charset="utf-8" />
</head>

<body style="margin: 0;">

<div id="p1" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_1{left:593px;bottom:997px;letter-spacing:0.12px;}
#t2_1{left:74px;bottom:779px;letter-spacing:-0.53px;}
#t3_1{left:124px;bottom:779px;letter-spacing:-0.19px;}
#t4_1{left:173px;bottom:779px;letter-spacing:-0.19px;}
#t5_1{left:247px;bottom:779px;letter-spacing:-0.17px;}
#t6_1{left:600px;bottom:779px;letter-spacing:-0.14px;word-spacing:-0.01px;}
#t7_1{left:745px;bottom:779px;letter-spacing:-0.16px;word-spacing:-0.02px;}
#t8_1{left:74px;bottom:886px;letter-spacing:-0.08px;}
#t9_1{left:225px;bottom:886px;letter-spacing:0.16px;}
#ta_1{left:593px;bottom:821px;letter-spacing:0.05px;}
#tb_1{left:677px;bottom:821px;}
#tc_1{left:507px;bottom:1063px;letter-spacing:-0.21px;}
#td_1{left:507px;bottom:1041px;letter-spacing:-0.41px;}
#te_1{left:593px;bottom:1041px;letter-spacing:-0.18px;}
#tf_1{left:675px;bottom:1041px;letter-spacing:-0.25px;}
#tg_1{left:593px;bottom:1063px;letter-spacing:-0.22px;word-spacing:0.03px;}
#th_1{left:593px;bottom:1020px;letter-spacing:-0.23px;}
#ti_1{left:507px;bottom:1020px;letter-spacing:-0.18px;}
#tj_1{left:593px;bottom:954px;letter-spacing:0.14px;}
#tk_1{left:507px;bottom:998px;letter-spacing:-0.28px;}
#tl_1{left:507px;bottom:976px;letter-spacing:-0.31px;}
#tm_1{left:593px;bottom:975px;letter-spacing:0.14px;}
#tn_1{left:507px;bottom:955px;letter-spacing:-0.58px;}
#to_1{left:507px;bottom:933px;letter-spacing:-0.55px;}
#tp_1{left:74px;bottom:817px;letter-spacing:0.07px;word-spacing:0.06px;}
#tq_1{left:507px;bottom:886px;letter-spacing:-0.43px;}
#tr_1{left:74px;bottom:951px;letter-spacing:0.01px;word-spacing:0.08px;}
#ts_1{left:74px;bottom:1059px;letter-spacing:0.14px;word-spacing:-0.02px;}
#tt_1{left:74px;bottom:864px;letter-spacing:0.09px;word-spacing:0.05px;}
#tu_1{left:699px;bottom:821px;}
#tv_1{left:736px;bottom:821px;}
#tw_1{left:74px;bottom:994px;letter-spacing:-0.02px;word-spacing:0.15px;}
#tx_1{left:593px;bottom:886px;letter-spacing:0.13px;}
#ty_1{left:74px;bottom:1084px;letter-spacing:0.11px;}
#tz_1{left:333px;bottom:528px;letter-spacing:0.15px;}
#t10_1{left:708px;bottom:680px;letter-spacing:0.13px;}
#t11_1{left:684px;bottom:615px;letter-spacing:0.11px;}
#t12_1{left:74px;bottom:720px;letter-spacing:0.08px;}
#t13_1{left:156px;bottom:720px;letter-spacing:0.11px;}
#t14_1{left:182px;bottom:720px;letter-spacing:-0.39px;}
#t15_1{left:708px;bottom:701px;letter-spacing:0.13px;}
#t16_1{left:688px;bottom:720px;letter-spacing:0.1px;}
#t17_1{left:710px;bottom:589px;letter-spacing:0.15px;}
#t18_1{left:710px;bottom:545px;letter-spacing:0.15px;}
#t19_1{left:95px;bottom:571px;letter-spacing:0.04px;}
#t1a_1{left:95px;bottom:550px;letter-spacing:0.06px;word-spacing:0.02px;}
#t1b_1{left:225px;bottom:550px;letter-spacing:0.12px;}
#t1c_1{left:538px;bottom:720px;letter-spacing:0.08px;}
#t1d_1{left:528px;bottom:615px;letter-spacing:0.08px;}
#t1e_1{left:710px;bottom:567px;letter-spacing:0.15px;}
#t1f_1{left:333px;bottom:463px;letter-spacing:0.13px;}
#t1g_1{left:169px;bottom:463px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1h_1{left:225px;bottom:528px;letter-spacing:-0.03px;}
#t1i_1{left:333px;bottom:506px;letter-spacing:0.1px;}
#t1j_1{left:225px;bottom:506px;word-spacing:0.13px;}
#t1k_1{left:476px;bottom:508px;letter-spacing:-0.25px;}
#t1l_1{left:333px;bottom:485px;letter-spacing:0.12px;}
#t1m_1{left:476px;bottom:486px;letter-spacing:-0.23px;word-spacing:0.63px;}
#t1n_1{left:727px;bottom:615px;letter-spacing:0.11px;}
#t1o_1{left:199px;bottom:485px;letter-spacing:0.09px;}
#t1p_1{left:794px;bottom:720px;letter-spacing:0.1px;}
#t1q_1{left:312px;bottom:550px;letter-spacing:0.1px;word-spacing:-0.02px;}
#t1r_1{left:333px;bottom:615px;letter-spacing:0.14px;}
#t1s_1{left:225px;bottom:615px;letter-spacing:0.01px;}
#t1t_1{left:225px;bottom:722px;letter-spacing:0.14px;word-spacing:0.02px;}
#t1u_1{left:225px;bottom:704px;letter-spacing:0.11px;word-spacing:0.02px;}
#t1v_1{left:225px;bottom:685px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1w_1{left:225px;bottom:661px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1x_1{left:95px;bottom:593px;letter-spacing:0.03px;}
#t1y_1{left:351px;bottom:592px;letter-spacing:-0.18px;}
#t1z_1{left:333px;bottom:225px;letter-spacing:0.14px;}
#t20_1{left:708px;bottom:376px;letter-spacing:0.13px;}
#t21_1{left:689px;bottom:312px;letter-spacing:0.11px;}
#t22_1{left:74px;bottom:417px;letter-spacing:0.08px;}
#t23_1{left:163px;bottom:417px;}
#t24_1{left:182px;bottom:417px;letter-spacing:-0.39px;}
#t25_1{left:708px;bottom:398px;letter-spacing:0.13px;}
#t26_1{left:693px;bottom:417px;letter-spacing:0.11px;}
#t27_1{left:710px;bottom:286px;letter-spacing:0.15px;}
#t28_1{left:710px;bottom:242px;letter-spacing:0.15px;}
#t29_1{left:95px;bottom:268px;letter-spacing:0.04px;}
#t2a_1{left:95px;bottom:247px;letter-spacing:0.06px;word-spacing:0.02px;}
#t2b_1{left:225px;bottom:247px;letter-spacing:0.12px;}
#t2c_1{left:538px;bottom:417px;letter-spacing:0.08px;}
#t2d_1{left:528px;bottom:312px;letter-spacing:0.08px;}
#t2e_1{left:710px;bottom:264px;letter-spacing:0.15px;}
#t2f_1{left:333px;bottom:160px;}
#t2g_1{left:169px;bottom:160px;letter-spacing:0.1px;word-spacing:0.03px;}
#t2h_1{left:225px;bottom:225px;letter-spacing:-0.03px;}
#t2i_1{left:333px;bottom:203px;letter-spacing:0.14px;}
#t2j_1{left:225px;bottom:203px;word-spacing:0.13px;}
#t2k_1{left:476px;bottom:204px;letter-spacing:-0.25px;}
#t2l_1{left:333px;bottom:182px;letter-spacing:0.14px;}
#t2m_1{left:476px;bottom:183px;letter-spacing:-0.23px;word-spacing:0.63px;}
#t2n_1{left:727px;bottom:312px;letter-spacing:0.11px;}
#t2o_1{left:199px;bottom:182px;letter-spacing:0.09px;}
#t2p_1{left:799px;bottom:417px;letter-spacing:0.1px;}
#t2q_1{left:312px;bottom:247px;letter-spacing:0.1px;word-spacing:-0.02px;}
#t2r_1{left:333px;bottom:312px;letter-spacing:0.14px;}
#t2s_1{left:225px;bottom:312px;letter-spacing:0.01px;}
#t2t_1{left:225px;bottom:419px;letter-spacing:0.1px;word-spacing:0.03px;}
#t2u_1{left:225px;bottom:358px;letter-spacing:0.14px;word-spacing:0.02px;}
#t2v_1{left:331px;bottom:358px;letter-spacing:0.12px;}
#t2w_1{left:95px;bottom:290px;letter-spacing:0.03px;}
#t2x_1{left:351px;bottom:289px;letter-spacing:-0.18px;}
#t2y_1{left:847.4px;bottom:65.9px;letter-spacing:0.11px;}

.s1_1{font-size:12px;font-family:LibreFranklin-Regular_18;color:#000080;}
.s2_1{font-size:14px;font-family:LibreFranklin-Regular_18;color:#000;}
.s3_1{font-size:15px;font-family:LibreFranklin-Medium_19;color:#000;}
.s4_1{font-size:15px;font-family:LibreFranklin-Regular_18;color:#000;}
.s5_1{font-size:11px;font-family:LibreFranklin-Regular_18;color:#000;}
.s6_1{font-size:11px;font-family:LibreFranklin-Regular_18;color:#000080;}
.s7_1{font-size:9px;font-family:LibreFranklin-Regular_18;color:#000;}
.s8_1{font-size:12px;font-family:LibreFranklin-Regular_18;color:#000;}
.s9_1{font-size:12px;font-family:LibreFranklin-Medium_19;color:#000;}
.sa_1{font-size:14px;font-family:Monospac821BT_1a;color:#00F;}
.t.m0_1{transform:matrix(0,-1,1,0,0,0);}
</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts1" type="text/css" >

@font-face {
	font-family: LibreFranklin-Medium_19;
	src: url("fonts/LibreFranklin-Medium_19.woff") format("woff");
}

@font-face {
	font-family: LibreFranklin-Regular_18;
	src: url("fonts/LibreFranklin-Regular_18.woff") format("woff");
}

@font-face {
	font-family: Monospac821BT_1a;
	src: url("fonts/Monospac821BT_1a.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg1Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg1" style="-webkit-user-select: none;"><object width="909" height="1286" data="1/1.svg" type="image/svg+xml" id="pdf1" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div class="text-container"><span id="t1_1" class="t s1_1">662433 </span>
<span id="t2_1" class="t s2_1">Pos </span><span id="t3_1" class="t s2_1">Menge </span><span id="t4_1" class="t s2_1">ME </span><span id="t5_1" class="t s2_1">Artikel </span><span id="t6_1" class="t s2_1">EP und Zuschläge </span><span id="t7_1" class="t s2_1">Summe EUR </span>
<span id="t8_1" class="t s3_1">BESTELL-Nr.: </span><span id="t9_1" class="t s3_1">92148 </span>
<span id="ta_1" class="t s4_1">Seite </span><span id="tb_1" class="t s4_1">1 </span>
<span id="tc_1" class="t s5_1">Sachbearbeiter </span>
<span id="td_1" class="t s5_1">Telefon </span><span id="te_1" class="t s5_1">05641 - 9006 - </span><span id="tf_1" class="t s5_1">81 </span>
<span id="tg_1" class="t s5_1">Michael Henke </span>
<span id="th_1" class="t s5_1">michael.henke@prg-gmbh.de </span><span id="ti_1" class="t s5_1">Email Sachbearb. </span>
<span id="tj_1" class="t s1_1">0711-4899922580 </span>
<span id="tk_1" class="t s6_1">Liefer.-Info </span>
<span id="tl_1" class="t s6_1">L-Kundennr </span><span id="tm_1" class="t s1_1">749490 </span>
<span id="tn_1" class="t s6_1">L-Telefon </span>
<span id="to_1" class="t s6_1">L-Telefax </span>
<span id="tp_1" class="t s4_1">Wir bestellen bei Ihnen folgende Artikel: </span>
<span id="tq_1" class="t s3_1">DATUM </span>
<span id="tr_1" class="t s3_1">70736 Fellbach </span>
<span id="ts_1" class="t s3_1">APSOparts GmbH </span>
<span id="tt_1" class="t s4_1">Ihr Angebot </span>
<span id="tu_1" class="t s4_1">/ </span><span id="tv_1" class="t s4_1">7 </span>
<span id="tw_1" class="t s4_1">Siemensstr. 5 </span>
<span id="tx_1" class="t s3_1">22.07.2024 </span>
<span id="ty_1" class="t s7_1">662433 </span>
<span id="tz_1" class="t s8_1">203-00576 </span>
<span id="t10_1" class="t s8_1">0,00 </span>
<span id="t11_1" class="t s8_1">141,00 </span>
<span id="t12_1" class="t s4_1">1,0 </span><span id="t13_1" class="t s4_1">10 </span><span id="t14_1" class="t s4_1">St </span>
<span id="t15_1" class="t s8_1">0,00 </span>
<span id="t16_1" class="t s4_1">141,00 </span>
<span id="t17_1" class="t s8_1">0,00% </span>
<span id="t18_1" class="t s8_1">0,00% </span>
<span id="t19_1" class="t s8_1">Kommission: </span>
<span id="t1a_1" class="t s9_1">Liefertermin an PRG: </span><span id="t1b_1" class="t s9_1">30/2024 </span>
<span id="t1c_1" class="t s4_1">Einzelpreis </span>
<span id="t1d_1" class="t s8_1">Einkaufspreis </span>
<span id="t1e_1" class="t s8_1">0,00% </span>
<span id="t1f_1" class="t s8_1">10 </span><span id="t1g_1" class="t s8_1">in PRG - LME </span>
<span id="t1h_1" class="t s8_1">Teilenummer </span>
<span id="t1i_1" class="t s8_1">0,340 </span><span id="t1j_1" class="t s8_1">Pos in Kg </span><span id="t1k_1" class="t s5_1">kg </span>
<span id="t1l_1" class="t s8_1">0,034 </span><span id="t1m_1" class="t s5_1">kg / St </span>
<span id="t1n_1" class="t s8_1">Einheit </span>
<span id="t1o_1" class="t s8_1">kg/ME </span>
<span id="t1p_1" class="t s4_1">14,10 </span>
<span id="t1q_1" class="t s9_1">Donnerstag - 25-Jul-2024 </span>
<span id="t1r_1" class="t s8_1">1150050492 </span><span id="t1s_1" class="t s8_1">Lief-.Best-Nr.: </span>
<span id="t1t_1" class="t s4_1">RWDR 55x75x10-NBR </span>
<span id="t1u_1" class="t s4_1">Radialwellendichtring Form A ohne </span>
<span id="t1v_1" class="t s4_1">Staublippe, mit VA-Feder </span>
<span id="t1w_1" class="t s8_1">A+P Radial-Wellendichtring Form A </span>
<span id="t1x_1" class="t s1_1">ZeichnungsNr.: </span><span id="t1y_1" class="t sa_1">INDEX </span>
<span id="t1z_1" class="t s8_1">204-00042 </span>
<span id="t20_1" class="t s8_1">0,00 </span>
<span id="t21_1" class="t s8_1">83,94 </span>
<span id="t22_1" class="t s4_1">2,0 </span><span id="t23_1" class="t s4_1">5 </span><span id="t24_1" class="t s4_1">St </span>
<span id="t25_1" class="t s8_1">0,00 </span>
<span id="t26_1" class="t s4_1">83,94 </span>
<span id="t27_1" class="t s8_1">0,00% </span>
<span id="t28_1" class="t s8_1">0,00% </span>
<span id="t29_1" class="t s8_1">Kommission: </span>
<span id="t2a_1" class="t s9_1">Liefertermin an PRG: </span><span id="t2b_1" class="t s9_1">30/2024 </span>
<span id="t2c_1" class="t s4_1">Einzelpreis </span>
<span id="t2d_1" class="t s8_1">Einkaufspreis </span>
<span id="t2e_1" class="t s8_1">0,00% </span>
<span id="t2f_1" class="t s8_1">5 </span><span id="t2g_1" class="t s8_1">in PRG - LME </span>
<span id="t2h_1" class="t s8_1">Teilenummer </span>
<span id="t2i_1" class="t s8_1">0,000 </span><span id="t2j_1" class="t s8_1">Pos in Kg </span><span id="t2k_1" class="t s5_1">kg </span>
<span id="t2l_1" class="t s8_1">0,000 </span><span id="t2m_1" class="t s5_1">kg / St </span>
<span id="t2n_1" class="t s8_1">Einheit </span>
<span id="t2o_1" class="t s8_1">kg/ME </span>
<span id="t2p_1" class="t s4_1">4,20 </span>
<span id="t2q_1" class="t s9_1">Donnerstag - 25-Jul-2024 </span>
<span id="t2r_1" class="t s8_1">1120071478 </span><span id="t2s_1" class="t s8_1">Lief-.Best-Nr.: </span>
<span id="t2t_1" class="t s4_1">10,77x2,62 Viton O-Ring </span>
<span id="t2u_1" class="t s8_1">NORMATEC FKM </span><span id="t2v_1" class="t s8_1">75.00-01 </span>
<span id="t2w_1" class="t s1_1">ZeichnungsNr.: </span><span id="t2x_1" class="t sa_1">INDEX </span>
<span id="t2y_1" class="t m0_1 s7_1">F90 </span></div>
<!-- End text definitions -->


</div>


<div id="p2" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_2{left:74px;bottom:996px;letter-spacing:-0.53px;}
#t2_2{left:124px;bottom:996px;letter-spacing:-0.18px;}
#t3_2{left:177px;bottom:996px;letter-spacing:-0.18px;}
#t4_2{left:225px;bottom:1038px;letter-spacing:0.17px;}
#t5_2{left:74px;bottom:1038px;letter-spacing:-0.08px;}
#t6_2{left:593px;bottom:1038px;letter-spacing:0.08px;}
#t7_2{left:666px;bottom:1038px;}
#t8_2{left:359px;bottom:1038px;letter-spacing:0.13px;}
#t9_2{left:320px;bottom:1038px;letter-spacing:-0.05px;}
#ta_2{left:74px;bottom:1081px;letter-spacing:0.06px;}
#tb_2{left:160px;bottom:1086px;letter-spacing:0.11px;}
#tc_2{left:596px;bottom:996px;letter-spacing:-0.14px;word-spacing:-0.01px;}
#td_2{left:745px;bottom:996px;letter-spacing:-0.16px;word-spacing:-0.02px;}
#te_2{left:225px;bottom:996px;letter-spacing:-0.17px;}
#tf_2{left:225px;bottom:1081px;letter-spacing:0.13px;word-spacing:0.03px;}
#tg_2{left:697px;bottom:1038px;}
#th_2{left:722px;bottom:1038px;}
#ti_2{left:333px;bottom:751px;letter-spacing:0.14px;}
#tj_2{left:708px;bottom:902px;letter-spacing:0.13px;}
#tk_2{left:690px;bottom:837px;letter-spacing:0.12px;}
#tl_2{left:74px;bottom:943px;letter-spacing:0.08px;}
#tm_2{left:156px;bottom:943px;letter-spacing:0.11px;}
#tn_2{left:182px;bottom:943px;letter-spacing:-0.39px;}
#to_2{left:708px;bottom:924px;letter-spacing:0.13px;}
#tp_2{left:695px;bottom:943px;letter-spacing:0.1px;}
#tq_2{left:710px;bottom:811px;letter-spacing:0.15px;}
#tr_2{left:710px;bottom:768px;letter-spacing:0.15px;}
#ts_2{left:95px;bottom:794px;letter-spacing:0.04px;}
#tt_2{left:95px;bottom:772px;letter-spacing:0.06px;word-spacing:0.02px;}
#tu_2{left:225px;bottom:772px;letter-spacing:0.12px;}
#tv_2{left:538px;bottom:943px;letter-spacing:0.08px;}
#tw_2{left:528px;bottom:837px;letter-spacing:0.08px;}
#tx_2{left:710px;bottom:790px;letter-spacing:0.15px;}
#ty_2{left:333px;bottom:686px;letter-spacing:0.13px;}
#tz_2{left:169px;bottom:686px;letter-spacing:0.1px;word-spacing:0.03px;}
#t10_2{left:225px;bottom:751px;letter-spacing:-0.03px;}
#t11_2{left:333px;bottom:729px;letter-spacing:0.14px;}
#t12_2{left:225px;bottom:729px;word-spacing:0.13px;}
#t13_2{left:476px;bottom:730px;letter-spacing:-0.25px;}
#t14_2{left:333px;bottom:707px;letter-spacing:0.14px;}
#t15_2{left:476px;bottom:709px;letter-spacing:-0.23px;word-spacing:0.63px;}
#t16_2{left:727px;bottom:837px;letter-spacing:0.11px;}
#t17_2{left:199px;bottom:707px;letter-spacing:0.09px;}
#t18_2{left:800px;bottom:943px;letter-spacing:0.1px;}
#t19_2{left:312px;bottom:772px;letter-spacing:0.1px;word-spacing:-0.02px;}
#t1a_2{left:333px;bottom:837px;letter-spacing:0.14px;}
#t1b_2{left:225px;bottom:837px;letter-spacing:0.01px;}
#t1c_2{left:225px;bottom:945px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1d_2{left:225px;bottom:884px;letter-spacing:0.13px;word-spacing:0.02px;}
#t1e_2{left:95px;bottom:816px;letter-spacing:0.03px;}
#t1f_2{left:351px;bottom:814px;letter-spacing:-0.18px;}
#t1g_2{left:333px;bottom:447px;letter-spacing:0.15px;}
#t1h_2{left:708px;bottom:599px;letter-spacing:0.13px;}
#t1i_2{left:683px;bottom:534px;letter-spacing:0.09px;}
#t1j_2{left:74px;bottom:640px;letter-spacing:0.11px;}
#t1k_2{left:156px;bottom:640px;letter-spacing:0.11px;}
#t1l_2{left:182px;bottom:640px;letter-spacing:-0.39px;}
#t1m_2{left:708px;bottom:621px;letter-spacing:0.13px;}
#t1n_2{left:686px;bottom:640px;letter-spacing:0.13px;}
#t1o_2{left:710px;bottom:508px;letter-spacing:0.15px;}
#t1p_2{left:710px;bottom:465px;letter-spacing:0.15px;}
#t1q_2{left:95px;bottom:491px;letter-spacing:0.04px;}
#t1r_2{left:95px;bottom:469px;letter-spacing:0.06px;word-spacing:0.02px;}
#t1s_2{left:225px;bottom:469px;letter-spacing:0.12px;}
#t1t_2{left:538px;bottom:640px;letter-spacing:0.08px;}
#t1u_2{left:528px;bottom:534px;letter-spacing:0.08px;}
#t1v_2{left:710px;bottom:486px;letter-spacing:0.15px;}
#t1w_2{left:333px;bottom:383px;letter-spacing:0.13px;}
#t1x_2{left:169px;bottom:383px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1y_2{left:225px;bottom:447px;letter-spacing:-0.03px;}
#t1z_2{left:333px;bottom:426px;letter-spacing:0.14px;}
#t20_2{left:225px;bottom:426px;word-spacing:0.13px;}
#t21_2{left:476px;bottom:427px;letter-spacing:-0.25px;}
#t22_2{left:333px;bottom:404px;letter-spacing:0.14px;}
#t23_2{left:476px;bottom:405px;letter-spacing:-0.23px;word-spacing:0.63px;}
#t24_2{left:727px;bottom:534px;letter-spacing:0.11px;}
#t25_2{left:199px;bottom:404px;letter-spacing:0.09px;}
#t26_2{left:791px;bottom:640px;letter-spacing:0.11px;}
#t27_2{left:312px;bottom:469px;letter-spacing:0.1px;word-spacing:-0.02px;}
#t28_2{left:333px;bottom:534px;letter-spacing:0.13px;}
#t29_2{left:225px;bottom:534px;letter-spacing:0.01px;}
#t2a_2{left:225px;bottom:642px;letter-spacing:0.11px;word-spacing:0.03px;}
#t2b_2{left:225px;bottom:581px;letter-spacing:0.13px;word-spacing:0.02px;}
#t2c_2{left:95px;bottom:512px;letter-spacing:0.03px;}
#t2d_2{left:351px;bottom:511px;letter-spacing:-0.18px;}
#t2e_2{left:708px;bottom:296px;letter-spacing:0.13px;}
#t2f_2{left:74px;bottom:337px;letter-spacing:0.12px;}
#t2g_2{left:156px;bottom:337px;letter-spacing:0.11px;}
#t2h_2{left:182px;bottom:337px;letter-spacing:-0.39px;}
#t2i_2{left:708px;bottom:318px;letter-spacing:0.13px;}
#t2j_2{left:694px;bottom:337px;letter-spacing:0.09px;}
#t2k_2{left:538px;bottom:337px;letter-spacing:0.08px;}
#t2l_2{left:799px;bottom:337px;letter-spacing:0.09px;}
#t2m_2{left:225px;bottom:338px;letter-spacing:0.11px;word-spacing:0.03px;}
#t2n_2{left:225px;bottom:278px;letter-spacing:0.13px;word-spacing:0.02px;}
#t2o_2{left:847.4px;bottom:65.9px;letter-spacing:0.11px;}

.s1_2{font-size:14px;font-family:LibreFranklin-Regular_18;color:#000;}
.s2_2{font-size:15px;font-family:LibreFranklin-Medium_19;color:#000;}
.s3_2{font-size:15px;font-family:LibreFranklin-Regular_18;color:#000;}
.s4_2{font-size:9px;font-family:LibreFranklin-Regular_18;color:#000;}
.s5_2{font-size:12px;font-family:LibreFranklin-Regular_18;color:#000;}
.s6_2{font-size:12px;font-family:LibreFranklin-Medium_19;color:#000;}
.s7_2{font-size:11px;font-family:LibreFranklin-Regular_18;color:#000;}
.s8_2{font-size:12px;font-family:LibreFranklin-Regular_18;color:#000080;}
.s9_2{font-size:14px;font-family:Monospac821BT_1a;color:#00F;}
.t.m0_2{transform:matrix(0,-1,1,0,0,0);}
</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts2" type="text/css" >

@font-face {
	font-family: LibreFranklin-Medium_19;
	src: url("fonts/LibreFranklin-Medium_19.woff") format("woff");
}

@font-face {
	font-family: LibreFranklin-Regular_18;
	src: url("fonts/LibreFranklin-Regular_18.woff") format("woff");
}

@font-face {
	font-family: Monospac821BT_1a;
	src: url("fonts/Monospac821BT_1a.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg2Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg2" style="-webkit-user-select: none;"><object width="909" height="1286" data="2/2.svg" type="image/svg+xml" id="pdf2" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div class="text-container"><span id="t1_2" class="t s1_2">Pos </span><span id="t2_2" class="t s1_2">Menge </span><span id="t3_2" class="t s1_2">ME </span>
<span id="t4_2" class="t s2_2">92148 </span><span id="t5_2" class="t s2_2">BESTELL-Nr.: </span><span id="t6_2" class="t s2_2">Seite </span><span id="t7_2" class="t s2_2">7 </span><span id="t8_2" class="t s2_2">22.07.2024 </span><span id="t9_2" class="t s2_2">vom: </span>
<span id="ta_2" class="t s3_2">Lieferant: </span>
<span id="tb_2" class="t s4_2">662433 </span>
<span id="tc_2" class="t s1_2">EP und Zuschläge </span><span id="td_2" class="t s1_2">Summe EUR </span><span id="te_2" class="t s1_2">Artikel </span>
<span id="tf_2" class="t s3_2">APSOparts GmbH </span>
<span id="tg_2" class="t s2_2">/ </span><span id="th_2" class="t s2_2">2 </span>
<span id="ti_2" class="t s5_2">204-00046 </span>
<span id="tj_2" class="t s5_2">0,00 </span>
<span id="tk_2" class="t s5_2">28,74 </span>
<span id="tl_2" class="t s3_2">3,0 </span><span id="tm_2" class="t s3_2">10 </span><span id="tn_2" class="t s3_2">St </span>
<span id="to_2" class="t s5_2">0,00 </span>
<span id="tp_2" class="t s3_2">28,74 </span>
<span id="tq_2" class="t s5_2">0,00% </span>
<span id="tr_2" class="t s5_2">0,00% </span>
<span id="ts_2" class="t s5_2">Kommission: </span>
<span id="tt_2" class="t s6_2">Liefertermin an PRG: </span><span id="tu_2" class="t s6_2">30/2024 </span>
<span id="tv_2" class="t s3_2">Einzelpreis </span>
<span id="tw_2" class="t s5_2">Einkaufspreis </span>
<span id="tx_2" class="t s5_2">0,00% </span>
<span id="ty_2" class="t s5_2">10 </span><span id="tz_2" class="t s5_2">in PRG - LME </span>
<span id="t10_2" class="t s5_2">Teilenummer </span>
<span id="t11_2" class="t s5_2">0,000 </span><span id="t12_2" class="t s5_2">Pos in Kg </span><span id="t13_2" class="t s7_2">kg </span>
<span id="t14_2" class="t s5_2">0,000 </span><span id="t15_2" class="t s7_2">kg / St </span>
<span id="t16_2" class="t s5_2">Einheit </span>
<span id="t17_2" class="t s5_2">kg/ME </span>
<span id="t18_2" class="t s3_2">2,87 </span>
<span id="t19_2" class="t s6_2">Donnerstag - 25-Jul-2024 </span>
<span id="t1a_2" class="t s5_2">1120070472 </span><span id="t1b_2" class="t s5_2">Lief-.Best-Nr.: </span>
<span id="t1c_2" class="t s3_2">12,42x1,78 Viton O-Ring </span>
<span id="t1d_2" class="t s5_2">NORMATEC FKM 75.00-01 </span>
<span id="t1e_2" class="t s8_2">ZeichnungsNr.: </span><span id="t1f_2" class="t s9_2">INDEX </span>
<span id="t1g_2" class="t s5_2">204-00190 </span>
<span id="t1h_2" class="t s5_2">0,00 </span>
<span id="t1i_2" class="t s5_2">481,49 </span>
<span id="t1j_2" class="t s3_2">4,0 </span><span id="t1k_2" class="t s3_2">10 </span><span id="t1l_2" class="t s3_2">St </span>
<span id="t1m_2" class="t s5_2">0,00 </span>
<span id="t1n_2" class="t s3_2">481,49 </span>
<span id="t1o_2" class="t s5_2">0,00% </span>
<span id="t1p_2" class="t s5_2">0,00% </span>
<span id="t1q_2" class="t s5_2">Kommission: </span>
<span id="t1r_2" class="t s6_2">Liefertermin an PRG: </span><span id="t1s_2" class="t s6_2">30/2024 </span>
<span id="t1t_2" class="t s3_2">Einzelpreis </span>
<span id="t1u_2" class="t s5_2">Einkaufspreis </span>
<span id="t1v_2" class="t s5_2">0,00% </span>
<span id="t1w_2" class="t s5_2">10 </span><span id="t1x_2" class="t s5_2">in PRG - LME </span>
<span id="t1y_2" class="t s5_2">Teilenummer </span>
<span id="t1z_2" class="t s5_2">0,000 </span><span id="t20_2" class="t s5_2">Pos in Kg </span><span id="t21_2" class="t s7_2">kg </span>
<span id="t22_2" class="t s5_2">0,000 </span><span id="t23_2" class="t s7_2">kg / St </span>
<span id="t24_2" class="t s5_2">Einheit </span>
<span id="t25_2" class="t s5_2">kg/ME </span>
<span id="t26_2" class="t s3_2">48,15 </span>
<span id="t27_2" class="t s6_2">Donnerstag - 25-Jul-2024 </span>
<span id="t28_2" class="t s5_2">1120073418 </span><span id="t29_2" class="t s5_2">Lief-.Best-Nr.: </span>
<span id="t2a_2" class="t s3_2">209,14x3,53 Viton O-Ring </span>
<span id="t2b_2" class="t s5_2">NORMATEC FKM 75.00-01 </span>
<span id="t2c_2" class="t s8_2">ZeichnungsNr.: </span><span id="t2d_2" class="t s9_2">INDEX </span>
<span id="t2e_2" class="t s5_2">0,00 </span>
<span id="t2f_2" class="t s3_2">5,0 </span><span id="t2g_2" class="t s3_2">10 </span><span id="t2h_2" class="t s3_2">St </span>
<span id="t2i_2" class="t s5_2">0,00 </span>
<span id="t2j_2" class="t s3_2">79,30 </span><span id="t2k_2" class="t s3_2">Einzelpreis </span><span id="t2l_2" class="t s3_2">7,93 </span>
<span id="t2m_2" class="t s3_2">29,74x3,53 Viton O-Ring </span>
<span id="t2n_2" class="t s5_2">NORMATEC FKM 75.00-01 </span>
<span id="t2o_2" class="t m0_2 s4_2">F90 </span></div>
<!-- End text definitions -->


</div>



<div id="p3" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_3{left:74px;bottom:996px;letter-spacing:-0.53px;}
#t2_3{left:124px;bottom:996px;letter-spacing:-0.18px;}
#t3_3{left:177px;bottom:996px;letter-spacing:-0.18px;}
#t4_3{left:225px;bottom:1038px;letter-spacing:0.17px;}
#t5_3{left:74px;bottom:1038px;letter-spacing:-0.08px;}
#t6_3{left:593px;bottom:1038px;letter-spacing:0.08px;}
#t7_3{left:666px;bottom:1038px;}
#t8_3{left:359px;bottom:1038px;letter-spacing:0.13px;}
#t9_3{left:320px;bottom:1038px;letter-spacing:-0.05px;}
#ta_3{left:74px;bottom:1081px;letter-spacing:0.06px;}
#tb_3{left:160px;bottom:1086px;letter-spacing:0.11px;}
#tc_3{left:596px;bottom:996px;letter-spacing:-0.14px;word-spacing:-0.01px;}
#td_3{left:745px;bottom:996px;letter-spacing:-0.16px;word-spacing:-0.02px;}
#te_3{left:225px;bottom:996px;letter-spacing:-0.17px;}
#tf_3{left:225px;bottom:1081px;letter-spacing:0.13px;word-spacing:0.03px;}
#tg_3{left:697px;bottom:1038px;}
#th_3{left:722px;bottom:1038px;}
#ti_3{left:333px;bottom:870px;letter-spacing:0.14px;}
#tj_3{left:689px;bottom:956px;letter-spacing:0.12px;}
#tk_3{left:710px;bottom:931px;letter-spacing:0.15px;}
#tl_3{left:710px;bottom:887px;letter-spacing:0.15px;}
#tm_3{left:95px;bottom:913px;letter-spacing:0.04px;}
#tn_3{left:95px;bottom:892px;letter-spacing:0.06px;word-spacing:0.02px;}
#to_3{left:225px;bottom:892px;letter-spacing:0.12px;}
#tp_3{left:528px;bottom:956px;letter-spacing:0.08px;}
#tq_3{left:710px;bottom:909px;letter-spacing:0.15px;}
#tr_3{left:333px;bottom:805px;letter-spacing:0.13px;}
#ts_3{left:169px;bottom:805px;letter-spacing:0.1px;word-spacing:0.03px;}
#tt_3{left:225px;bottom:870px;letter-spacing:-0.03px;}
#tu_3{left:333px;bottom:848px;letter-spacing:0.14px;}
#tv_3{left:225px;bottom:848px;word-spacing:0.13px;}
#tw_3{left:476px;bottom:849px;letter-spacing:-0.25px;}
#tx_3{left:333px;bottom:827px;letter-spacing:0.14px;}
#ty_3{left:476px;bottom:828px;letter-spacing:-0.23px;word-spacing:0.63px;}
#tz_3{left:727px;bottom:956px;letter-spacing:0.11px;}
#t10_3{left:199px;bottom:827px;letter-spacing:0.09px;}
#t11_3{left:312px;bottom:892px;letter-spacing:0.1px;word-spacing:-0.02px;}
#t12_3{left:333px;bottom:956px;letter-spacing:0.14px;}
#t13_3{left:225px;bottom:956px;letter-spacing:0.01px;}
#t14_3{left:95px;bottom:935px;letter-spacing:0.03px;}
#t15_3{left:351px;bottom:933px;letter-spacing:-0.18px;}
#t16_3{left:333px;bottom:567px;letter-spacing:0.14px;}
#t17_3{left:708px;bottom:718px;letter-spacing:0.13px;}
#t18_3{left:683px;bottom:653px;letter-spacing:0.12px;}
#t19_3{left:74px;bottom:759px;letter-spacing:0.12px;}
#t1a_3{left:163px;bottom:759px;}
#t1b_3{left:182px;bottom:759px;letter-spacing:-0.39px;}
#t1c_3{left:708px;bottom:740px;letter-spacing:0.13px;}
#t1d_3{left:686px;bottom:759px;letter-spacing:0.11px;}
#t1e_3{left:710px;bottom:627px;letter-spacing:0.15px;}
#t1f_3{left:710px;bottom:584px;letter-spacing:0.15px;}
#t1g_3{left:95px;bottom:610px;letter-spacing:0.04px;}
#t1h_3{left:95px;bottom:588px;letter-spacing:0.06px;word-spacing:0.02px;}
#t1i_3{left:225px;bottom:588px;letter-spacing:0.12px;}
#t1j_3{left:538px;bottom:759px;letter-spacing:0.08px;}
#t1k_3{left:528px;bottom:653px;letter-spacing:0.08px;}
#t1l_3{left:710px;bottom:606px;letter-spacing:0.15px;}
#t1m_3{left:333px;bottom:502px;}
#t1n_3{left:169px;bottom:502px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1o_3{left:225px;bottom:567px;letter-spacing:-0.03px;}
#t1p_3{left:333px;bottom:545px;letter-spacing:0.14px;}
#t1q_3{left:225px;bottom:545px;word-spacing:0.13px;}
#t1r_3{left:476px;bottom:546px;letter-spacing:-0.25px;}
#t1s_3{left:333px;bottom:523px;letter-spacing:0.14px;}
#t1t_3{left:476px;bottom:525px;letter-spacing:-0.23px;word-spacing:0.63px;}
#t1u_3{left:727px;bottom:653px;letter-spacing:0.11px;}
#t1v_3{left:199px;bottom:523px;letter-spacing:0.09px;}
#t1w_3{left:798px;bottom:759px;letter-spacing:0.1px;}
#t1x_3{left:312px;bottom:588px;letter-spacing:0.1px;word-spacing:-0.02px;}
#t1y_3{left:333px;bottom:653px;letter-spacing:0.13px;}
#t1z_3{left:225px;bottom:653px;letter-spacing:0.01px;}
#t20_3{left:225px;bottom:761px;letter-spacing:0.11px;word-spacing:0.02px;}
#t21_3{left:225px;bottom:700px;letter-spacing:0.13px;word-spacing:0.02px;}
#t22_3{left:95px;bottom:632px;letter-spacing:0.03px;}
#t23_3{left:351px;bottom:630px;letter-spacing:-0.18px;}
#t24_3{left:333px;bottom:264px;letter-spacing:0.15px;}
#t25_3{left:708px;bottom:415px;letter-spacing:0.13px;}
#t26_3{left:682px;bottom:350px;letter-spacing:0.12px;}
#t27_3{left:74px;bottom:456px;letter-spacing:0.08px;}
#t28_3{left:163px;bottom:456px;}
#t29_3{left:182px;bottom:456px;letter-spacing:-0.39px;}
#t2a_3{left:708px;bottom:437px;letter-spacing:0.13px;}
#t2b_3{left:685px;bottom:456px;letter-spacing:0.1px;}
#t2c_3{left:710px;bottom:324px;letter-spacing:0.15px;}
#t2d_3{left:710px;bottom:281px;letter-spacing:0.15px;}
#t2e_3{left:95px;bottom:307px;letter-spacing:0.04px;}
#t2f_3{left:95px;bottom:285px;letter-spacing:0.06px;word-spacing:0.02px;}
#t2g_3{left:225px;bottom:285px;letter-spacing:0.12px;}
#t2h_3{left:538px;bottom:456px;letter-spacing:0.08px;}
#t2i_3{left:528px;bottom:350px;letter-spacing:0.08px;}
#t2j_3{left:710px;bottom:303px;letter-spacing:0.15px;}
#t2k_3{left:333px;bottom:199px;}
#t2l_3{left:169px;bottom:199px;letter-spacing:0.1px;word-spacing:0.03px;}
#t2m_3{left:225px;bottom:264px;letter-spacing:-0.03px;}
#t2n_3{left:333px;bottom:242px;letter-spacing:0.14px;}
#t2o_3{left:225px;bottom:242px;word-spacing:0.13px;}
#t2p_3{left:476px;bottom:243px;letter-spacing:-0.25px;}
#t2q_3{left:333px;bottom:220px;letter-spacing:0.14px;}
#t2r_3{left:476px;bottom:222px;letter-spacing:-0.23px;word-spacing:0.63px;}
#t2s_3{left:727px;bottom:350px;letter-spacing:0.11px;}
#t2t_3{left:199px;bottom:220px;letter-spacing:0.09px;}
#t2u_3{left:794px;bottom:456px;letter-spacing:0.08px;}
#t2v_3{left:312px;bottom:285px;letter-spacing:0.1px;word-spacing:-0.02px;}
#t2w_3{left:333px;bottom:350px;letter-spacing:0.13px;}
#t2x_3{left:225px;bottom:350px;letter-spacing:0.01px;}
#t2y_3{left:225px;bottom:458px;letter-spacing:0.11px;word-spacing:0.03px;}
#t2z_3{left:225px;bottom:397px;letter-spacing:0.13px;word-spacing:0.02px;}
#t30_3{left:95px;bottom:329px;letter-spacing:0.03px;}
#t31_3{left:351px;bottom:327px;letter-spacing:-0.18px;}
#t32_3{left:847.4px;bottom:65.9px;letter-spacing:0.11px;}

.s1_3{font-size:14px;font-family:LibreFranklin-Regular_18;color:#000;}
.s2_3{font-size:15px;font-family:LibreFranklin-Medium_19;color:#000;}
.s3_3{font-size:15px;font-family:LibreFranklin-Regular_18;color:#000;}
.s4_3{font-size:9px;font-family:LibreFranklin-Regular_18;color:#000;}
.s5_3{font-size:12px;font-family:LibreFranklin-Regular_18;color:#000;}
.s6_3{font-size:12px;font-family:LibreFranklin-Medium_19;color:#000;}
.s7_3{font-size:11px;font-family:LibreFranklin-Regular_18;color:#000;}
.s8_3{font-size:12px;font-family:LibreFranklin-Regular_18;color:#000080;}
.s9_3{font-size:14px;font-family:Monospac821BT_1a;color:#00F;}
.t.m0_3{transform:matrix(0,-1,1,0,0,0);}
</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts3" type="text/css" >

@font-face {
	font-family: LibreFranklin-Medium_19;
	src: url("fonts/LibreFranklin-Medium_19.woff") format("woff");
}

@font-face {
	font-family: LibreFranklin-Regular_18;
	src: url("fonts/LibreFranklin-Regular_18.woff") format("woff");
}

@font-face {
	font-family: Monospac821BT_1a;
	src: url("fonts/Monospac821BT_1a.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg3Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg3" style="-webkit-user-select: none;"><object width="909" height="1286" data="3/3.svg" type="image/svg+xml" id="pdf3" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div class="text-container"><span id="t1_3" class="t s1_3">Pos </span><span id="t2_3" class="t s1_3">Menge </span><span id="t3_3" class="t s1_3">ME </span>
<span id="t4_3" class="t s2_3">92148 </span><span id="t5_3" class="t s2_3">BESTELL-Nr.: </span><span id="t6_3" class="t s2_3">Seite </span><span id="t7_3" class="t s2_3">7 </span><span id="t8_3" class="t s2_3">22.07.2024 </span><span id="t9_3" class="t s2_3">vom: </span>
<span id="ta_3" class="t s3_3">Lieferant: </span>
<span id="tb_3" class="t s4_3">662433 </span>
<span id="tc_3" class="t s1_3">EP und Zuschläge </span><span id="td_3" class="t s1_3">Summe EUR </span><span id="te_3" class="t s1_3">Artikel </span>
<span id="tf_3" class="t s3_3">APSOparts GmbH </span>
<span id="tg_3" class="t s2_3">/ </span><span id="th_3" class="t s2_3">3 </span>
<span id="ti_3" class="t s5_3">204-00259 </span>
<span id="tj_3" class="t s5_3">79,30 </span>
<span id="tk_3" class="t s5_3">0,00% </span>
<span id="tl_3" class="t s5_3">0,00% </span>
<span id="tm_3" class="t s5_3">Kommission: </span>
<span id="tn_3" class="t s6_3">Liefertermin an PRG: </span><span id="to_3" class="t s6_3">30/2024 </span>
<span id="tp_3" class="t s5_3">Einkaufspreis </span>
<span id="tq_3" class="t s5_3">0,00% </span>
<span id="tr_3" class="t s5_3">10 </span><span id="ts_3" class="t s5_3">in PRG - LME </span>
<span id="tt_3" class="t s5_3">Teilenummer </span>
<span id="tu_3" class="t s5_3">0,000 </span><span id="tv_3" class="t s5_3">Pos in Kg </span><span id="tw_3" class="t s7_3">kg </span>
<span id="tx_3" class="t s5_3">0,000 </span><span id="ty_3" class="t s7_3">kg / St </span>
<span id="tz_3" class="t s5_3">Einheit </span>
<span id="t10_3" class="t s5_3">kg/ME </span>
<span id="t11_3" class="t s6_3">Donnerstag - 25-Jul-2024 </span>
<span id="t12_3" class="t s5_3">1120073272 </span><span id="t13_3" class="t s5_3">Lief-.Best-Nr.: </span>
<span id="t14_3" class="t s8_3">ZeichnungsNr.: </span><span id="t15_3" class="t s9_3">INDEX </span>
<span id="t16_3" class="t s5_3">204-00730 </span>
<span id="t17_3" class="t s5_3">0,00 </span>
<span id="t18_3" class="t s5_3">167,84 </span>
<span id="t19_3" class="t s3_3">6,0 </span><span id="t1a_3" class="t s3_3">5 </span><span id="t1b_3" class="t s3_3">St </span>
<span id="t1c_3" class="t s5_3">0,00 </span>
<span id="t1d_3" class="t s3_3">167,84 </span>
<span id="t1e_3" class="t s5_3">0,00% </span>
<span id="t1f_3" class="t s5_3">0,00% </span>
<span id="t1g_3" class="t s5_3">Kommission: </span>
<span id="t1h_3" class="t s6_3">Liefertermin an PRG: </span><span id="t1i_3" class="t s6_3">30/2024 </span>
<span id="t1j_3" class="t s3_3">Einzelpreis </span>
<span id="t1k_3" class="t s5_3">Einkaufspreis </span>
<span id="t1l_3" class="t s5_3">0,00% </span>
<span id="t1m_3" class="t s5_3">5 </span><span id="t1n_3" class="t s5_3">in PRG - LME </span>
<span id="t1o_3" class="t s5_3">Teilenummer </span>
<span id="t1p_3" class="t s5_3">0,000 </span><span id="t1q_3" class="t s5_3">Pos in Kg </span><span id="t1r_3" class="t s7_3">kg </span>
<span id="t1s_3" class="t s5_3">0,000 </span><span id="t1t_3" class="t s7_3">kg / St </span>
<span id="t1u_3" class="t s5_3">Einheit </span>
<span id="t1v_3" class="t s5_3">kg/ME </span>
<span id="t1w_3" class="t s3_3">8,39 </span>
<span id="t1x_3" class="t s6_3">Donnerstag - 25-Jul-2024 </span>
<span id="t1y_3" class="t s5_3">1120073316 </span><span id="t1z_3" class="t s5_3">Lief-.Best-Nr.: </span>
<span id="t20_3" class="t s3_3">56,74x3,53 Viton O-Ring </span>
<span id="t21_3" class="t s5_3">NORMATEC FKM 75.00-01 </span>
<span id="t22_3" class="t s8_3">ZeichnungsNr.: </span><span id="t23_3" class="t s9_3">INDEX </span>
<span id="t24_3" class="t s5_3">204-00954 </span>
<span id="t25_3" class="t s5_3">0,00 </span>
<span id="t26_3" class="t s5_3">362,78 </span>
<span id="t27_3" class="t s3_3">7,0 </span><span id="t28_3" class="t s3_3">6 </span><span id="t29_3" class="t s3_3">St </span>
<span id="t2a_3" class="t s5_3">0,00 </span>
<span id="t2b_3" class="t s3_3">362,78 </span>
<span id="t2c_3" class="t s5_3">0,00% </span>
<span id="t2d_3" class="t s5_3">0,00% </span>
<span id="t2e_3" class="t s5_3">Kommission: </span>
<span id="t2f_3" class="t s6_3">Liefertermin an PRG: </span><span id="t2g_3" class="t s6_3">30/2024 </span>
<span id="t2h_3" class="t s3_3">Einzelpreis </span>
<span id="t2i_3" class="t s5_3">Einkaufspreis </span>
<span id="t2j_3" class="t s5_3">0,00% </span>
<span id="t2k_3" class="t s5_3">6 </span><span id="t2l_3" class="t s5_3">in PRG - LME </span>
<span id="t2m_3" class="t s5_3">Teilenummer </span>
<span id="t2n_3" class="t s5_3">0,000 </span><span id="t2o_3" class="t s5_3">Pos in Kg </span><span id="t2p_3" class="t s7_3">kg </span>
<span id="t2q_3" class="t s5_3">0,000 </span><span id="t2r_3" class="t s7_3">kg / St </span>
<span id="t2s_3" class="t s5_3">Einheit </span>
<span id="t2t_3" class="t s5_3">kg/ME </span>
<span id="t2u_3" class="t s3_3">21,77 </span>
<span id="t2v_3" class="t s6_3">Donnerstag - 25-Jul-2024 </span>
<span id="t2w_3" class="t s5_3">1120073384 </span><span id="t2x_3" class="t s5_3">Lief-.Best-Nr.: </span>
<span id="t2y_3" class="t s3_3">126,59x3,53 Viton O-Ring </span>
<span id="t2z_3" class="t s5_3">NORMATEC FKM 75.00-01 </span>
<span id="t30_3" class="t s8_3">ZeichnungsNr.: </span><span id="t31_3" class="t s9_3">INDEX </span>
<span id="t32_3" class="t m0_3 s4_3">F90 </span></div>
<!-- End text definitions -->


</div>



<div id="p4" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_4{left:74px;bottom:996px;letter-spacing:-0.53px;}
#t2_4{left:124px;bottom:996px;letter-spacing:-0.18px;}
#t3_4{left:177px;bottom:996px;letter-spacing:-0.18px;}
#t4_4{left:225px;bottom:1038px;letter-spacing:0.17px;}
#t5_4{left:74px;bottom:1038px;letter-spacing:-0.08px;}
#t6_4{left:593px;bottom:1038px;letter-spacing:0.08px;}
#t7_4{left:666px;bottom:1038px;}
#t8_4{left:359px;bottom:1038px;letter-spacing:0.13px;}
#t9_4{left:320px;bottom:1038px;letter-spacing:-0.05px;}
#ta_4{left:74px;bottom:1081px;letter-spacing:0.06px;}
#tb_4{left:160px;bottom:1086px;letter-spacing:0.11px;}
#tc_4{left:596px;bottom:996px;letter-spacing:-0.14px;word-spacing:-0.01px;}
#td_4{left:745px;bottom:996px;letter-spacing:-0.16px;word-spacing:-0.02px;}
#te_4{left:225px;bottom:996px;letter-spacing:-0.17px;}
#tf_4{left:225px;bottom:1081px;letter-spacing:0.13px;word-spacing:0.03px;}
#tg_4{left:697px;bottom:1038px;}
#th_4{left:722px;bottom:1038px;}
#ti_4{left:333px;bottom:762px;letter-spacing:0.14px;}
#tj_4{left:708px;bottom:913px;letter-spacing:0.13px;}
#tk_4{left:673px;bottom:848px;letter-spacing:0.11px;}
#tl_4{left:74px;bottom:954px;letter-spacing:0.11px;}
#tm_4{left:163px;bottom:954px;}
#tn_4{left:182px;bottom:954px;letter-spacing:-0.39px;}
#to_4{left:708px;bottom:935px;letter-spacing:0.13px;}
#tp_4{left:674px;bottom:954px;letter-spacing:0.09px;}
#tq_4{left:710px;bottom:822px;letter-spacing:0.15px;}
#tr_4{left:710px;bottom:779px;letter-spacing:0.15px;}
#ts_4{left:95px;bottom:805px;letter-spacing:0.04px;}
#tt_4{left:95px;bottom:783px;letter-spacing:0.06px;word-spacing:0.02px;}
#tu_4{left:225px;bottom:783px;letter-spacing:0.12px;}
#tv_4{left:538px;bottom:954px;letter-spacing:0.08px;}
#tw_4{left:528px;bottom:848px;letter-spacing:0.08px;}
#tx_4{left:710px;bottom:801px;letter-spacing:0.15px;}
#ty_4{left:333px;bottom:697px;}
#tz_4{left:169px;bottom:697px;letter-spacing:0.1px;word-spacing:0.03px;}
#t10_4{left:225px;bottom:762px;letter-spacing:-0.03px;}
#t11_4{left:333px;bottom:740px;letter-spacing:0.14px;}
#t12_4{left:225px;bottom:740px;word-spacing:0.13px;}
#t13_4{left:476px;bottom:741px;letter-spacing:-0.25px;}
#t14_4{left:333px;bottom:718px;letter-spacing:0.14px;}
#t15_4{left:476px;bottom:720px;letter-spacing:-0.23px;word-spacing:0.63px;}
#t16_4{left:727px;bottom:848px;letter-spacing:0.11px;}
#t17_4{left:199px;bottom:718px;letter-spacing:0.09px;}
#t18_4{left:789px;bottom:954px;letter-spacing:0.11px;}
#t19_4{left:312px;bottom:783px;letter-spacing:0.1px;word-spacing:-0.02px;}
#t1a_4{left:333px;bottom:848px;letter-spacing:0.15px;}
#t1b_4{left:225px;bottom:848px;letter-spacing:0.01px;}
#t1c_4{left:225px;bottom:956px;letter-spacing:0.11px;word-spacing:0.03px;}
#t1d_4{left:225px;bottom:895px;letter-spacing:0.13px;word-spacing:0.02px;}
#t1e_4{left:95px;bottom:827px;letter-spacing:0.03px;}
#t1f_4{left:351px;bottom:825px;letter-spacing:-0.18px;}
#t1g_4{left:333px;bottom:458px;letter-spacing:0.14px;}
#t1h_4{left:708px;bottom:610px;letter-spacing:0.13px;}
#t1i_4{left:680px;bottom:545px;letter-spacing:0.14px;}
#t1j_4{left:74px;bottom:651px;letter-spacing:0.09px;}
#t1k_4{left:163px;bottom:651px;}
#t1l_4{left:182px;bottom:651px;letter-spacing:-0.39px;}
#t1m_4{left:708px;bottom:632px;letter-spacing:0.13px;}
#t1n_4{left:683px;bottom:651px;letter-spacing:0.11px;}
#t1o_4{left:710px;bottom:519px;letter-spacing:0.15px;}
#t1p_4{left:710px;bottom:476px;letter-spacing:0.15px;}
#t1q_4{left:95px;bottom:502px;letter-spacing:0.04px;}
#t1r_4{left:95px;bottom:480px;letter-spacing:0.06px;word-spacing:0.02px;}
#t1s_4{left:225px;bottom:480px;letter-spacing:0.12px;}
#t1t_4{left:538px;bottom:651px;letter-spacing:0.08px;}
#t1u_4{left:528px;bottom:545px;letter-spacing:0.08px;}
#t1v_4{left:710px;bottom:497px;letter-spacing:0.15px;}
#t1w_4{left:333px;bottom:394px;}
#t1x_4{left:169px;bottom:394px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1y_4{left:225px;bottom:458px;letter-spacing:-0.03px;}
#t1z_4{left:333px;bottom:437px;letter-spacing:0.14px;}
#t20_4{left:225px;bottom:437px;word-spacing:0.13px;}
#t21_4{left:476px;bottom:438px;letter-spacing:-0.25px;}
#t22_4{left:333px;bottom:415px;letter-spacing:0.14px;}
#t23_4{left:476px;bottom:416px;letter-spacing:-0.23px;word-spacing:0.63px;}
#t24_4{left:727px;bottom:545px;letter-spacing:0.11px;}
#t25_4{left:199px;bottom:415px;letter-spacing:0.09px;}
#t26_4{left:787px;bottom:651px;letter-spacing:0.1px;}
#t27_4{left:312px;bottom:480px;letter-spacing:0.1px;word-spacing:-0.02px;}
#t28_4{left:333px;bottom:545px;letter-spacing:0.14px;}
#t29_4{left:225px;bottom:545px;letter-spacing:0.01px;}
#t2a_4{left:225px;bottom:653px;letter-spacing:0.1px;word-spacing:0.03px;}
#t2b_4{left:225px;bottom:592px;letter-spacing:0.13px;word-spacing:0.02px;}
#t2c_4{left:95px;bottom:523px;letter-spacing:0.03px;}
#t2d_4{left:351px;bottom:522px;letter-spacing:-0.18px;}
#t2e_4{left:708px;bottom:307px;letter-spacing:0.13px;}
#t2f_4{left:74px;bottom:348px;letter-spacing:0.09px;}
#t2g_4{left:166px;bottom:348px;}
#t2h_4{left:182px;bottom:348px;letter-spacing:-0.39px;}
#t2i_4{left:708px;bottom:329px;letter-spacing:0.13px;}
#t2j_4{left:689px;bottom:348px;letter-spacing:0.08px;}
#t2k_4{left:538px;bottom:348px;letter-spacing:0.08px;}
#t2l_4{left:800px;bottom:348px;letter-spacing:0.09px;}
#t2m_4{left:225px;bottom:349px;letter-spacing:0.11px;word-spacing:0.03px;}
#t2n_4{left:225px;bottom:289px;letter-spacing:0.13px;word-spacing:0.02px;}
#t2o_4{left:847.4px;bottom:65.9px;letter-spacing:0.11px;}

.s1_4{font-size:14px;font-family:LibreFranklin-Regular_18;color:#000;}
.s2_4{font-size:15px;font-family:LibreFranklin-Medium_19;color:#000;}
.s3_4{font-size:15px;font-family:LibreFranklin-Regular_18;color:#000;}
.s4_4{font-size:9px;font-family:LibreFranklin-Regular_18;color:#000;}
.s5_4{font-size:12px;font-family:LibreFranklin-Regular_18;color:#000;}
.s6_4{font-size:12px;font-family:LibreFranklin-Medium_19;color:#000;}
.s7_4{font-size:11px;font-family:LibreFranklin-Regular_18;color:#000;}
.s8_4{font-size:12px;font-family:LibreFranklin-Regular_18;color:#000080;}
.s9_4{font-size:14px;font-family:Monospac821BT_1a;color:#00F;}
.t.m0_4{transform:matrix(0,-1,1,0,0,0);}
</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts4" type="text/css" >

@font-face {
	font-family: LibreFranklin-Medium_19;
	src: url("fonts/LibreFranklin-Medium_19.woff") format("woff");
}

@font-face {
	font-family: LibreFranklin-Regular_18;
	src: url("fonts/LibreFranklin-Regular_18.woff") format("woff");
}

@font-face {
	font-family: Monospac821BT_1a;
	src: url("fonts/Monospac821BT_1a.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg4Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg4" style="-webkit-user-select: none;"><object width="909" height="1286" data="4/4.svg" type="image/svg+xml" id="pdf4" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div class="text-container"><span id="t1_4" class="t s1_4">Pos </span><span id="t2_4" class="t s1_4">Menge </span><span id="t3_4" class="t s1_4">ME </span>
<span id="t4_4" class="t s2_4">92148 </span><span id="t5_4" class="t s2_4">BESTELL-Nr.: </span><span id="t6_4" class="t s2_4">Seite </span><span id="t7_4" class="t s2_4">7 </span><span id="t8_4" class="t s2_4">22.07.2024 </span><span id="t9_4" class="t s2_4">vom: </span>
<span id="ta_4" class="t s3_4">Lieferant: </span>
<span id="tb_4" class="t s4_4">662433 </span>
<span id="tc_4" class="t s1_4">EP und Zuschläge </span><span id="td_4" class="t s1_4">Summe EUR </span><span id="te_4" class="t s1_4">Artikel </span>
<span id="tf_4" class="t s3_4">APSOparts GmbH </span>
<span id="tg_4" class="t s2_4">/ </span><span id="th_4" class="t s2_4">4 </span>
<span id="ti_4" class="t s5_4">204-01054 </span>
<span id="tj_4" class="t s5_4">0,00 </span>
<span id="tk_4" class="t s5_4">1.083,27 </span>
<span id="tl_4" class="t s3_4">8,0 </span><span id="tm_4" class="t s3_4">3 </span><span id="tn_4" class="t s3_4">St </span>
<span id="to_4" class="t s5_4">0,00 </span>
<span id="tp_4" class="t s3_4">1.083,27 </span>
<span id="tq_4" class="t s5_4">0,00% </span>
<span id="tr_4" class="t s5_4">0,00% </span>
<span id="ts_4" class="t s5_4">Kommission: </span>
<span id="tt_4" class="t s6_4">Liefertermin an PRG: </span><span id="tu_4" class="t s6_4">30/2024 </span>
<span id="tv_4" class="t s3_4">Einzelpreis </span>
<span id="tw_4" class="t s5_4">Einkaufspreis </span>
<span id="tx_4" class="t s5_4">0,00% </span>
<span id="ty_4" class="t s5_4">3 </span><span id="tz_4" class="t s5_4">in PRG - LME </span>
<span id="t10_4" class="t s5_4">Teilenummer </span>
<span id="t11_4" class="t s5_4">0,000 </span><span id="t12_4" class="t s5_4">Pos in Kg </span><span id="t13_4" class="t s7_4">kg </span>
<span id="t14_4" class="t s5_4">0,000 </span><span id="t15_4" class="t s7_4">kg / St </span>
<span id="t16_4" class="t s5_4">Einheit </span>
<span id="t17_4" class="t s5_4">kg/ME </span>
<span id="t18_4" class="t s3_4">32,50 </span>
<span id="t19_4" class="t s6_4">Donnerstag - 25-Jul-2024 </span>
<span id="t1a_4" class="t s5_4">1120075976 </span><span id="t1b_4" class="t s5_4">Lief-.Best-Nr.: </span>
<span id="t1c_4" class="t s3_4">253,37x5,33 Viton O-Ring </span>
<span id="t1d_4" class="t s5_4">NORMATEC FKM 75.00-01 </span>
<span id="t1e_4" class="t s8_4">ZeichnungsNr.: </span><span id="t1f_4" class="t s9_4">INDEX </span>
<span id="t1g_4" class="t s5_4">204-01271 </span>
<span id="t1h_4" class="t s5_4">0,00 </span>
<span id="t1i_4" class="t s5_4">999,96 </span>
<span id="t1j_4" class="t s3_4">9,0 </span><span id="t1k_4" class="t s3_4">3 </span><span id="t1l_4" class="t s3_4">St </span>
<span id="t1m_4" class="t s5_4">0,00 </span>
<span id="t1n_4" class="t s3_4">999,96 </span>
<span id="t1o_4" class="t s5_4">0,00% </span>
<span id="t1p_4" class="t s5_4">0,00% </span>
<span id="t1q_4" class="t s5_4">Kommission: </span>
<span id="t1r_4" class="t s6_4">Liefertermin an PRG: </span><span id="t1s_4" class="t s6_4">30/2024 </span>
<span id="t1t_4" class="t s3_4">Einzelpreis </span>
<span id="t1u_4" class="t s5_4">Einkaufspreis </span>
<span id="t1v_4" class="t s5_4">0,00% </span>
<span id="t1w_4" class="t s5_4">3 </span><span id="t1x_4" class="t s5_4">in PRG - LME </span>
<span id="t1y_4" class="t s5_4">Teilenummer </span>
<span id="t1z_4" class="t s5_4">0,000 </span><span id="t20_4" class="t s5_4">Pos in Kg </span><span id="t21_4" class="t s7_4">kg </span>
<span id="t22_4" class="t s5_4">0,000 </span><span id="t23_4" class="t s7_4">kg / St </span>
<span id="t24_4" class="t s5_4">Einheit </span>
<span id="t25_4" class="t s5_4">kg/ME </span>
<span id="t26_4" class="t s3_4">30,00 </span>
<span id="t27_4" class="t s6_4">Donnerstag - 25-Jul-2024 </span>
<span id="t28_4" class="t s5_4">1120075964 </span><span id="t29_4" class="t s5_4">Lief-.Best-Nr.: </span>
<span id="t2a_4" class="t s3_4">215,27x5,33 Viton O-Ring </span>
<span id="t2b_4" class="t s5_4">NORMATEC FKM 75.00-01 </span>
<span id="t2c_4" class="t s8_4">ZeichnungsNr.: </span><span id="t2d_4" class="t s9_4">INDEX </span>
<span id="t2e_4" class="t s5_4">0,00 </span>
<span id="t2f_4" class="t s3_4">10,0 </span><span id="t2g_4" class="t s3_4">1 </span><span id="t2h_4" class="t s3_4">St </span>
<span id="t2i_4" class="t s5_4">0,00 </span>
<span id="t2j_4" class="t s3_4">797,21 </span><span id="t2k_4" class="t s3_4">Einzelpreis </span><span id="t2l_4" class="t s3_4">7,97 </span>
<span id="t2m_4" class="t s3_4">266,29x3,53 Viton O-Ring </span>
<span id="t2n_4" class="t s5_4">NORMATEC FKM 75.00-01 </span>
<span id="t2o_4" class="t m0_4 s4_4">F90 </span></div>
<!-- End text definitions -->


</div>



<div id="p5" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_5{left:74px;bottom:996px;letter-spacing:-0.53px;}
#t2_5{left:124px;bottom:996px;letter-spacing:-0.18px;}
#t3_5{left:177px;bottom:996px;letter-spacing:-0.18px;}
#t4_5{left:225px;bottom:1038px;letter-spacing:0.17px;}
#t5_5{left:74px;bottom:1038px;letter-spacing:-0.08px;}
#t6_5{left:593px;bottom:1038px;letter-spacing:0.08px;}
#t7_5{left:666px;bottom:1038px;}
#t8_5{left:359px;bottom:1038px;letter-spacing:0.13px;}
#t9_5{left:320px;bottom:1038px;letter-spacing:-0.05px;}
#ta_5{left:74px;bottom:1081px;letter-spacing:0.06px;}
#tb_5{left:160px;bottom:1086px;letter-spacing:0.11px;}
#tc_5{left:596px;bottom:996px;letter-spacing:-0.14px;word-spacing:-0.01px;}
#td_5{left:745px;bottom:996px;letter-spacing:-0.16px;word-spacing:-0.02px;}
#te_5{left:225px;bottom:996px;letter-spacing:-0.17px;}
#tf_5{left:225px;bottom:1081px;letter-spacing:0.13px;word-spacing:0.03px;}
#tg_5{left:697px;bottom:1038px;}
#th_5{left:722px;bottom:1038px;}
#ti_5{left:333px;bottom:870px;letter-spacing:0.13px;}
#tj_5{left:685px;bottom:956px;letter-spacing:0.12px;}
#tk_5{left:710px;bottom:931px;letter-spacing:0.15px;}
#tl_5{left:710px;bottom:887px;letter-spacing:0.15px;}
#tm_5{left:95px;bottom:913px;letter-spacing:0.04px;}
#tn_5{left:95px;bottom:892px;letter-spacing:0.06px;word-spacing:0.02px;}
#to_5{left:225px;bottom:892px;letter-spacing:0.12px;}
#tp_5{left:528px;bottom:956px;letter-spacing:0.08px;}
#tq_5{left:710px;bottom:909px;letter-spacing:0.15px;}
#tr_5{left:333px;bottom:805px;}
#ts_5{left:169px;bottom:805px;letter-spacing:0.1px;word-spacing:0.03px;}
#tt_5{left:225px;bottom:870px;letter-spacing:-0.03px;}
#tu_5{left:333px;bottom:848px;letter-spacing:0.14px;}
#tv_5{left:225px;bottom:848px;word-spacing:0.13px;}
#tw_5{left:476px;bottom:849px;letter-spacing:-0.25px;}
#tx_5{left:333px;bottom:827px;letter-spacing:0.14px;}
#ty_5{left:476px;bottom:828px;letter-spacing:-0.23px;word-spacing:0.63px;}
#tz_5{left:727px;bottom:956px;letter-spacing:0.11px;}
#t10_5{left:199px;bottom:827px;letter-spacing:0.09px;}
#t11_5{left:312px;bottom:892px;letter-spacing:0.1px;word-spacing:-0.02px;}
#t12_5{left:333px;bottom:956px;letter-spacing:0.13px;}
#t13_5{left:225px;bottom:956px;letter-spacing:0.01px;}
#t14_5{left:95px;bottom:935px;letter-spacing:0.03px;}
#t15_5{left:351px;bottom:933px;letter-spacing:-0.18px;}
#t16_5{left:333px;bottom:567px;letter-spacing:0.13px;}
#t17_5{left:708px;bottom:718px;letter-spacing:0.13px;}
#t18_5{left:689px;bottom:653px;letter-spacing:0.12px;}
#t19_5{left:74px;bottom:759px;letter-spacing:0.08px;}
#t1a_5{left:156px;bottom:759px;letter-spacing:0.11px;}
#t1b_5{left:182px;bottom:759px;letter-spacing:-0.39px;}
#t1c_5{left:708px;bottom:740px;letter-spacing:0.13px;}
#t1d_5{left:694px;bottom:759px;letter-spacing:0.11px;}
#t1e_5{left:710px;bottom:627px;letter-spacing:0.15px;}
#t1f_5{left:710px;bottom:584px;letter-spacing:0.15px;}
#t1g_5{left:95px;bottom:610px;letter-spacing:0.04px;}
#t1h_5{left:95px;bottom:588px;letter-spacing:0.06px;word-spacing:0.02px;}
#t1i_5{left:225px;bottom:588px;letter-spacing:0.12px;}
#t1j_5{left:538px;bottom:759px;letter-spacing:0.08px;}
#t1k_5{left:528px;bottom:653px;letter-spacing:0.08px;}
#t1l_5{left:710px;bottom:606px;letter-spacing:0.15px;}
#t1m_5{left:333px;bottom:502px;letter-spacing:0.13px;}
#t1n_5{left:169px;bottom:502px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1o_5{left:225px;bottom:567px;letter-spacing:-0.03px;}
#t1p_5{left:333px;bottom:545px;letter-spacing:0.14px;}
#t1q_5{left:225px;bottom:545px;word-spacing:0.13px;}
#t1r_5{left:476px;bottom:546px;letter-spacing:-0.25px;}
#t1s_5{left:333px;bottom:523px;letter-spacing:0.14px;}
#t1t_5{left:476px;bottom:525px;letter-spacing:-0.23px;word-spacing:0.63px;}
#t1u_5{left:727px;bottom:653px;letter-spacing:0.11px;}
#t1v_5{left:199px;bottom:523px;letter-spacing:0.09px;}
#t1w_5{left:799px;bottom:759px;letter-spacing:0.11px;}
#t1x_5{left:312px;bottom:588px;letter-spacing:0.1px;word-spacing:-0.02px;}
#t1y_5{left:333px;bottom:653px;letter-spacing:0.14px;}
#t1z_5{left:225px;bottom:653px;letter-spacing:0.01px;}
#t20_5{left:225px;bottom:761px;letter-spacing:0.11px;word-spacing:0.02px;}
#t21_5{left:225px;bottom:700px;letter-spacing:0.11px;word-spacing:0.02px;}
#t22_5{left:95px;bottom:632px;letter-spacing:0.03px;}
#t23_5{left:351px;bottom:630px;letter-spacing:-0.18px;}
#t24_5{left:333px;bottom:264px;letter-spacing:0.14px;}
#t25_5{left:708px;bottom:415px;letter-spacing:0.13px;}
#t26_5{left:685px;bottom:350px;letter-spacing:0.11px;}
#t27_5{left:74px;bottom:456px;letter-spacing:0.08px;}
#t28_5{left:156px;bottom:456px;letter-spacing:0.1px;}
#t29_5{left:182px;bottom:456px;letter-spacing:-0.39px;}
#t2a_5{left:708px;bottom:437px;letter-spacing:0.13px;}
#t2b_5{left:688px;bottom:456px;letter-spacing:0.09px;}
#t2c_5{left:710px;bottom:324px;letter-spacing:0.15px;}
#t2d_5{left:710px;bottom:281px;letter-spacing:0.15px;}
#t2e_5{left:95px;bottom:307px;letter-spacing:0.04px;}
#t2f_5{left:95px;bottom:285px;letter-spacing:0.06px;word-spacing:0.02px;}
#t2g_5{left:225px;bottom:285px;letter-spacing:0.12px;}
#t2h_5{left:538px;bottom:456px;letter-spacing:0.08px;}
#t2i_5{left:528px;bottom:350px;letter-spacing:0.08px;}
#t2j_5{left:710px;bottom:303px;letter-spacing:0.15px;}
#t2k_5{left:333px;bottom:199px;letter-spacing:0.13px;}
#t2l_5{left:169px;bottom:199px;letter-spacing:0.1px;word-spacing:0.03px;}
#t2m_5{left:225px;bottom:264px;letter-spacing:-0.03px;}
#t2n_5{left:333px;bottom:242px;letter-spacing:0.14px;}
#t2o_5{left:225px;bottom:242px;word-spacing:0.13px;}
#t2p_5{left:476px;bottom:243px;letter-spacing:-0.25px;}
#t2q_5{left:333px;bottom:220px;letter-spacing:0.14px;}
#t2r_5{left:476px;bottom:222px;letter-spacing:-0.23px;word-spacing:0.63px;}
#t2s_5{left:727px;bottom:350px;letter-spacing:0.11px;}
#t2t_5{left:199px;bottom:220px;letter-spacing:0.09px;}
#t2u_5{left:794px;bottom:456px;letter-spacing:0.09px;}
#t2v_5{left:312px;bottom:285px;letter-spacing:0.1px;word-spacing:-0.02px;}
#t2w_5{left:333px;bottom:350px;letter-spacing:0.13px;}
#t2x_5{left:225px;bottom:350px;letter-spacing:0.01px;}
#t2y_5{left:225px;bottom:458px;letter-spacing:0.12px;word-spacing:0.03px;}
#t2z_5{left:225px;bottom:439px;letter-spacing:0.1px;word-spacing:0.03px;}
#t30_5{left:225px;bottom:397px;letter-spacing:0.12px;word-spacing:0.02px;}
#t31_5{left:225px;bottom:329px;letter-spacing:0.09px;}
#t32_5{left:331px;bottom:329px;}
#t33_5{left:95px;bottom:329px;letter-spacing:0.03px;}
#t34_5{left:351px;bottom:327px;letter-spacing:-0.18px;}
#t35_5{left:847.4px;bottom:65.9px;letter-spacing:0.11px;}

.s1_5{font-size:14px;font-family:LibreFranklin-Regular_18;color:#000;}
.s2_5{font-size:15px;font-family:LibreFranklin-Medium_19;color:#000;}
.s3_5{font-size:15px;font-family:LibreFranklin-Regular_18;color:#000;}
.s4_5{font-size:9px;font-family:LibreFranklin-Regular_18;color:#000;}
.s5_5{font-size:12px;font-family:LibreFranklin-Regular_18;color:#000;}
.s6_5{font-size:12px;font-family:LibreFranklin-Medium_19;color:#000;}
.s7_5{font-size:11px;font-family:LibreFranklin-Regular_18;color:#000;}
.s8_5{font-size:12px;font-family:LibreFranklin-Regular_18;color:#000080;}
.s9_5{font-size:14px;font-family:Monospac821BT_1a;color:#00F;}
.t.m0_5{transform:matrix(0,-1,1,0,0,0);}
</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts5" type="text/css" >

@font-face {
	font-family: LibreFranklin-Medium_19;
	src: url("fonts/LibreFranklin-Medium_19.woff") format("woff");
}

@font-face {
	font-family: LibreFranklin-Regular_18;
	src: url("fonts/LibreFranklin-Regular_18.woff") format("woff");
}

@font-face {
	font-family: Monospac821BT_1a;
	src: url("fonts/Monospac821BT_1a.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg5Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg5" style="-webkit-user-select: none;"><object width="909" height="1286" data="5/5.svg" type="image/svg+xml" id="pdf5" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div class="text-container"><span id="t1_5" class="t s1_5">Pos </span><span id="t2_5" class="t s1_5">Menge </span><span id="t3_5" class="t s1_5">ME </span>
<span id="t4_5" class="t s2_5">92148 </span><span id="t5_5" class="t s2_5">BESTELL-Nr.: </span><span id="t6_5" class="t s2_5">Seite </span><span id="t7_5" class="t s2_5">7 </span><span id="t8_5" class="t s2_5">22.07.2024 </span><span id="t9_5" class="t s2_5">vom: </span>
<span id="ta_5" class="t s3_5">Lieferant: </span>
<span id="tb_5" class="t s4_5">662433 </span>
<span id="tc_5" class="t s1_5">EP und Zuschläge </span><span id="td_5" class="t s1_5">Summe EUR </span><span id="te_5" class="t s1_5">Artikel </span>
<span id="tf_5" class="t s3_5">APSOparts GmbH </span>
<span id="tg_5" class="t s2_5">/ </span><span id="th_5" class="t s2_5">5 </span>
<span id="ti_5" class="t s5_5">204-01389 </span>
<span id="tj_5" class="t s5_5">797,21 </span>
<span id="tk_5" class="t s5_5">0,00% </span>
<span id="tl_5" class="t s5_5">0,00% </span>
<span id="tm_5" class="t s5_5">Kommission: </span>
<span id="tn_5" class="t s6_5">Liefertermin an PRG: </span><span id="to_5" class="t s6_5">30/2024 </span>
<span id="tp_5" class="t s5_5">Einkaufspreis </span>
<span id="tq_5" class="t s5_5">0,00% </span>
<span id="tr_5" class="t s5_5">1 </span><span id="ts_5" class="t s5_5">in PRG - LME </span>
<span id="tt_5" class="t s5_5">Teilenummer </span>
<span id="tu_5" class="t s5_5">0,000 </span><span id="tv_5" class="t s5_5">Pos in Kg </span><span id="tw_5" class="t s7_5">kg </span>
<span id="tx_5" class="t s5_5">0,000 </span><span id="ty_5" class="t s7_5">kg / St </span>
<span id="tz_5" class="t s5_5">Einheit </span>
<span id="t10_5" class="t s5_5">kg/ME </span>
<span id="t11_5" class="t s6_5">Donnerstag - 25-Jul-2024 </span>
<span id="t12_5" class="t s5_5">1120073434 </span><span id="t13_5" class="t s5_5">Lief-.Best-Nr.: </span>
<span id="t14_5" class="t s8_5">ZeichnungsNr.: </span><span id="t15_5" class="t s9_5">INDEX </span>
<span id="t16_5" class="t s5_5">204-01429 </span>
<span id="t17_5" class="t s5_5">0,00 </span>
<span id="t18_5" class="t s5_5">28,63 </span>
<span id="t19_5" class="t s3_5">11,0 </span><span id="t1a_5" class="t s3_5">10 </span><span id="t1b_5" class="t s3_5">St </span>
<span id="t1c_5" class="t s5_5">0,00 </span>
<span id="t1d_5" class="t s3_5">28,63 </span>
<span id="t1e_5" class="t s5_5">0,00% </span>
<span id="t1f_5" class="t s5_5">0,00% </span>
<span id="t1g_5" class="t s5_5">Kommission: </span>
<span id="t1h_5" class="t s6_5">Liefertermin an PRG: </span><span id="t1i_5" class="t s6_5">30/2024 </span>
<span id="t1j_5" class="t s3_5">Einzelpreis </span>
<span id="t1k_5" class="t s5_5">Einkaufspreis </span>
<span id="t1l_5" class="t s5_5">0,00% </span>
<span id="t1m_5" class="t s5_5">10 </span><span id="t1n_5" class="t s5_5">in PRG - LME </span>
<span id="t1o_5" class="t s5_5">Teilenummer </span>
<span id="t1p_5" class="t s5_5">0,000 </span><span id="t1q_5" class="t s5_5">Pos in Kg </span><span id="t1r_5" class="t s7_5">kg </span>
<span id="t1s_5" class="t s5_5">0,000 </span><span id="t1t_5" class="t s7_5">kg / St </span>
<span id="t1u_5" class="t s5_5">Einheit </span>
<span id="t1v_5" class="t s5_5">kg/ME </span>
<span id="t1w_5" class="t s3_5">2,86 </span>
<span id="t1x_5" class="t s6_5">Donnerstag - 25-Jul-2024 </span>
<span id="t1y_5" class="t s5_5">1140071060 </span><span id="t1z_5" class="t s5_5">Lief-.Best-Nr.: </span>
<span id="t20_5" class="t s3_5">6,00x1,50 Viton FDA O-Ring </span>
<span id="t21_5" class="t s5_5">HITEC® O-Ring FKM 75.16-04 </span>
<span id="t22_5" class="t s8_5">ZeichnungsNr.: </span><span id="t23_5" class="t s9_5">INDEX </span>
<span id="t24_5" class="t s5_5">204-02502 </span>
<span id="t25_5" class="t s5_5">0,00 </span>
<span id="t26_5" class="t s5_5">110,36 </span>
<span id="t27_5" class="t s3_5">12,0 </span><span id="t28_5" class="t s3_5">10 </span><span id="t29_5" class="t s3_5">St </span>
<span id="t2a_5" class="t s5_5">0,00 </span>
<span id="t2b_5" class="t s3_5">110,36 </span>
<span id="t2c_5" class="t s5_5">0,00% </span>
<span id="t2d_5" class="t s5_5">0,00% </span>
<span id="t2e_5" class="t s5_5">Kommission: </span>
<span id="t2f_5" class="t s6_5">Liefertermin an PRG: </span><span id="t2g_5" class="t s6_5">30/2024 </span>
<span id="t2h_5" class="t s3_5">Einzelpreis </span>
<span id="t2i_5" class="t s5_5">Einkaufspreis </span>
<span id="t2j_5" class="t s5_5">0,00% </span>
<span id="t2k_5" class="t s5_5">10 </span><span id="t2l_5" class="t s5_5">in PRG - LME </span>
<span id="t2m_5" class="t s5_5">Teilenummer </span>
<span id="t2n_5" class="t s5_5">0,000 </span><span id="t2o_5" class="t s5_5">Pos in Kg </span><span id="t2p_5" class="t s7_5">kg </span>
<span id="t2q_5" class="t s5_5">0,000 </span><span id="t2r_5" class="t s7_5">kg / St </span>
<span id="t2s_5" class="t s5_5">Einheit </span>
<span id="t2t_5" class="t s5_5">kg/ME </span>
<span id="t2u_5" class="t s3_5">11,04 </span>
<span id="t2v_5" class="t s6_5">Donnerstag - 25-Jul-2024 </span>
<span id="t2w_5" class="t s5_5">1140203566 </span><span id="t2x_5" class="t s5_5">Lief-.Best-Nr.: </span>
<span id="t2y_5" class="t s3_5">101,19x3,53 EPDM FDA O-Ring USP </span>
<span id="t2z_5" class="t s3_5">Class VI, EG1935 </span>
<span id="t30_5" class="t s5_5">HITEC® O-Ring EPDM 70.10-02 </span>
<span id="t31_5" class="t s8_5">Z-ORing-EP_5598</span><span id="t32_5" class="t s8_5">5 </span><span id="t33_5" class="t s8_5">ZeichnungsNr.: </span><span id="t34_5" class="t s9_5">INDEX </span>
<span id="t35_5" class="t m0_5 s4_5">F90 </span></div>
<!-- End text definitions -->


</div>



<div id="p6" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_6{left:74px;bottom:996px;letter-spacing:-0.53px;}
#t2_6{left:124px;bottom:996px;letter-spacing:-0.18px;}
#t3_6{left:177px;bottom:996px;letter-spacing:-0.18px;}
#t4_6{left:225px;bottom:1038px;letter-spacing:0.17px;}
#t5_6{left:74px;bottom:1038px;letter-spacing:-0.08px;}
#t6_6{left:593px;bottom:1038px;letter-spacing:0.08px;}
#t7_6{left:666px;bottom:1038px;}
#t8_6{left:359px;bottom:1038px;letter-spacing:0.13px;}
#t9_6{left:320px;bottom:1038px;letter-spacing:-0.05px;}
#ta_6{left:74px;bottom:1081px;letter-spacing:0.06px;}
#tb_6{left:160px;bottom:1086px;letter-spacing:0.11px;}
#tc_6{left:596px;bottom:996px;letter-spacing:-0.14px;word-spacing:-0.01px;}
#td_6{left:745px;bottom:996px;letter-spacing:-0.16px;word-spacing:-0.02px;}
#te_6{left:225px;bottom:996px;letter-spacing:-0.17px;}
#tf_6{left:225px;bottom:1081px;letter-spacing:0.13px;word-spacing:0.03px;}
#tg_6{left:697px;bottom:1038px;}
#th_6{left:722px;bottom:1038px;}
#ti_6{left:333px;bottom:762px;letter-spacing:0.13px;}
#tj_6{left:708px;bottom:913px;letter-spacing:0.13px;}
#tk_6{left:691px;bottom:848px;letter-spacing:0.1px;}
#tl_6{left:74px;bottom:954px;letter-spacing:0.08px;}
#tm_6{left:143px;bottom:954px;letter-spacing:0.12px;}
#tn_6{left:182px;bottom:954px;letter-spacing:-0.39px;}
#to_6{left:708px;bottom:935px;letter-spacing:0.13px;}
#tp_6{left:696px;bottom:954px;letter-spacing:0.11px;}
#tq_6{left:710px;bottom:822px;letter-spacing:0.15px;}
#tr_6{left:710px;bottom:779px;letter-spacing:0.15px;}
#ts_6{left:95px;bottom:805px;letter-spacing:0.04px;}
#tt_6{left:95px;bottom:783px;letter-spacing:0.06px;word-spacing:0.02px;}
#tu_6{left:225px;bottom:783px;letter-spacing:0.12px;}
#tv_6{left:538px;bottom:954px;letter-spacing:0.08px;}
#tw_6{left:528px;bottom:848px;letter-spacing:0.08px;}
#tx_6{left:710px;bottom:801px;letter-spacing:0.15px;}
#ty_6{left:333px;bottom:697px;letter-spacing:0.16px;}
#tz_6{left:169px;bottom:697px;letter-spacing:0.1px;word-spacing:0.03px;}
#t10_6{left:225px;bottom:762px;letter-spacing:-0.03px;}
#t11_6{left:333px;bottom:740px;letter-spacing:0.12px;}
#t12_6{left:225px;bottom:740px;word-spacing:0.13px;}
#t13_6{left:476px;bottom:741px;letter-spacing:-0.25px;}
#t14_6{left:333px;bottom:718px;letter-spacing:0.14px;}
#t15_6{left:476px;bottom:720px;letter-spacing:-0.23px;word-spacing:0.63px;}
#t16_6{left:727px;bottom:848px;letter-spacing:0.11px;}
#t17_6{left:199px;bottom:718px;letter-spacing:0.09px;}
#t18_6{left:790px;bottom:954px;letter-spacing:0.09px;}
#t19_6{left:312px;bottom:783px;letter-spacing:0.1px;word-spacing:-0.02px;}
#t1a_6{left:333px;bottom:848px;letter-spacing:0.13px;}
#t1b_6{left:225px;bottom:848px;letter-spacing:0.01px;}
#t1c_6{left:225px;bottom:956px;letter-spacing:0.12px;word-spacing:0.03px;}
#t1d_6{left:225px;bottom:937px;letter-spacing:0.1px;word-spacing:0.03px;}
#t1e_6{left:225px;bottom:895px;letter-spacing:0.13px;word-spacing:0.01px;}
#t1f_6{left:225px;bottom:827px;letter-spacing:0.09px;}
#t1g_6{left:331px;bottom:827px;}
#t1h_6{left:95px;bottom:827px;letter-spacing:0.03px;}
#t1i_6{left:351px;bottom:825px;letter-spacing:-0.18px;}
#t1j_6{left:333px;bottom:458px;letter-spacing:0.13px;}
#t1k_6{left:708px;bottom:610px;letter-spacing:0.13px;}
#t1l_6{left:686px;bottom:545px;letter-spacing:0.11px;}
#t1m_6{left:74px;bottom:651px;letter-spacing:0.12px;}
#t1n_6{left:153px;bottom:651px;letter-spacing:0.15px;}
#t1o_6{left:182px;bottom:651px;letter-spacing:-0.39px;}
#t1p_6{left:708px;bottom:632px;letter-spacing:0.13px;}
#t1q_6{left:690px;bottom:651px;letter-spacing:0.09px;}
#t1r_6{left:710px;bottom:519px;letter-spacing:0.15px;}
#t1s_6{left:710px;bottom:476px;letter-spacing:0.15px;}
#t1t_6{left:95px;bottom:502px;letter-spacing:0.04px;}
#t1u_6{left:95px;bottom:480px;letter-spacing:0.06px;word-spacing:0.02px;}
#t1v_6{left:225px;bottom:480px;letter-spacing:0.12px;}
#t1w_6{left:538px;bottom:651px;letter-spacing:0.08px;}
#t1x_6{left:528px;bottom:545px;letter-spacing:0.08px;}
#t1y_6{left:710px;bottom:497px;letter-spacing:0.15px;}
#t1z_6{left:333px;bottom:394px;letter-spacing:0.13px;}
#t20_6{left:169px;bottom:394px;letter-spacing:0.1px;word-spacing:0.03px;}
#t21_6{left:225px;bottom:458px;letter-spacing:-0.03px;}
#t22_6{left:333px;bottom:437px;letter-spacing:0.14px;}
#t23_6{left:225px;bottom:437px;word-spacing:0.13px;}
#t24_6{left:476px;bottom:438px;letter-spacing:-0.25px;}
#t25_6{left:333px;bottom:415px;letter-spacing:0.14px;}
#t26_6{left:476px;bottom:416px;letter-spacing:-0.23px;word-spacing:0.63px;}
#t27_6{left:727px;bottom:545px;letter-spacing:0.11px;}
#t28_6{left:199px;bottom:415px;letter-spacing:0.09px;}
#t29_6{left:790px;bottom:651px;letter-spacing:0.11px;}
#t2a_6{left:312px;bottom:480px;letter-spacing:0.1px;word-spacing:-0.02px;}
#t2b_6{left:333px;bottom:545px;letter-spacing:0.13px;}
#t2c_6{left:225px;bottom:545px;letter-spacing:0.01px;}
#t2d_6{left:225px;bottom:653px;letter-spacing:0.12px;word-spacing:0.03px;}
#t2e_6{left:225px;bottom:634px;letter-spacing:0.1px;word-spacing:0.03px;}
#t2f_6{left:225px;bottom:592px;letter-spacing:0.13px;word-spacing:0.01px;}
#t2g_6{left:225px;bottom:523px;letter-spacing:0.09px;}
#t2h_6{left:331px;bottom:523px;}
#t2i_6{left:95px;bottom:523px;letter-spacing:0.03px;}
#t2j_6{left:351px;bottom:522px;letter-spacing:-0.18px;}
#t2k_6{left:708px;bottom:307px;letter-spacing:0.13px;}
#t2l_6{left:74px;bottom:348px;letter-spacing:0.1px;}
#t2m_6{left:156px;bottom:348px;letter-spacing:0.11px;}
#t2n_6{left:182px;bottom:348px;letter-spacing:-0.39px;}
#t2o_6{left:708px;bottom:329px;letter-spacing:0.13px;}
#t2p_6{left:693px;bottom:348px;letter-spacing:0.12px;}
#t2q_6{left:538px;bottom:348px;letter-spacing:0.08px;}
#t2r_6{left:799px;bottom:348px;letter-spacing:0.11px;}
#t2s_6{left:225px;bottom:349px;letter-spacing:0.12px;word-spacing:0.03px;}
#t2t_6{left:225px;bottom:331px;letter-spacing:0.1px;word-spacing:0.03px;}
#t2u_6{left:225px;bottom:289px;letter-spacing:0.13px;word-spacing:0.02px;}
#t2v_6{left:847.4px;bottom:65.9px;letter-spacing:0.11px;}

.s1_6{font-size:14px;font-family:LibreFranklin-Regular_18;color:#000;}
.s2_6{font-size:15px;font-family:LibreFranklin-Medium_19;color:#000;}
.s3_6{font-size:15px;font-family:LibreFranklin-Regular_18;color:#000;}
.s4_6{font-size:9px;font-family:LibreFranklin-Regular_18;color:#000;}
.s5_6{font-size:12px;font-family:LibreFranklin-Regular_18;color:#000;}
.s6_6{font-size:12px;font-family:LibreFranklin-Medium_19;color:#000;}
.s7_6{font-size:11px;font-family:LibreFranklin-Regular_18;color:#000;}
.s8_6{font-size:12px;font-family:LibreFranklin-Regular_18;color:#000080;}
.s9_6{font-size:14px;font-family:Monospac821BT_1a;color:#00F;}
.t.m0_6{transform:matrix(0,-1,1,0,0,0);}
</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts6" type="text/css" >

@font-face {
	font-family: LibreFranklin-Medium_19;
	src: url("fonts/LibreFranklin-Medium_19.woff") format("woff");
}

@font-face {
	font-family: LibreFranklin-Regular_18;
	src: url("fonts/LibreFranklin-Regular_18.woff") format("woff");
}

@font-face {
	font-family: Monospac821BT_1a;
	src: url("fonts/Monospac821BT_1a.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg6Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg6" style="-webkit-user-select: none;"><object width="909" height="1286" data="6/6.svg" type="image/svg+xml" id="pdf6" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div class="text-container"><span id="t1_6" class="t s1_6">Pos </span><span id="t2_6" class="t s1_6">Menge </span><span id="t3_6" class="t s1_6">ME </span>
<span id="t4_6" class="t s2_6">92148 </span><span id="t5_6" class="t s2_6">BESTELL-Nr.: </span><span id="t6_6" class="t s2_6">Seite </span><span id="t7_6" class="t s2_6">7 </span><span id="t8_6" class="t s2_6">22.07.2024 </span><span id="t9_6" class="t s2_6">vom: </span>
<span id="ta_6" class="t s3_6">Lieferant: </span>
<span id="tb_6" class="t s4_6">662433 </span>
<span id="tc_6" class="t s1_6">EP und Zuschläge </span><span id="td_6" class="t s1_6">Summe EUR </span><span id="te_6" class="t s1_6">Artikel </span>
<span id="tf_6" class="t s3_6">APSOparts GmbH </span>
<span id="tg_6" class="t s2_6">/ </span><span id="th_6" class="t s2_6">6 </span>
<span id="ti_6" class="t s5_6">204-02511 </span>
<span id="tj_6" class="t s5_6">0,00 </span>
<span id="tk_6" class="t s5_6">14,89 </span>
<span id="tl_6" class="t s3_6">13,0 </span><span id="tm_6" class="t s3_6">200 </span><span id="tn_6" class="t s3_6">St </span>
<span id="to_6" class="t s5_6">0,00 </span>
<span id="tp_6" class="t s3_6">14,89 </span>
<span id="tq_6" class="t s5_6">0,00% </span>
<span id="tr_6" class="t s5_6">0,00% </span>
<span id="ts_6" class="t s5_6">Kommission: </span>
<span id="tt_6" class="t s6_6">Liefertermin an PRG: </span><span id="tu_6" class="t s6_6">30/2024 </span>
<span id="tv_6" class="t s3_6">Einzelpreis </span>
<span id="tw_6" class="t s5_6">Einkaufspreis </span>
<span id="tx_6" class="t s5_6">0,00% </span>
<span id="ty_6" class="t s5_6">200 </span><span id="tz_6" class="t s5_6">in PRG - LME </span>
<span id="t10_6" class="t s5_6">Teilenummer </span>
<span id="t11_6" class="t s5_6">0,040 </span><span id="t12_6" class="t s5_6">Pos in Kg </span><span id="t13_6" class="t s7_6">kg </span>
<span id="t14_6" class="t s5_6">0,000 </span><span id="t15_6" class="t s7_6">kg / St </span>
<span id="t16_6" class="t s5_6">Einheit </span>
<span id="t17_6" class="t s5_6">kg/ME </span>
<span id="t18_6" class="t s3_6">29,78 </span>
<span id="t19_6" class="t s6_6">Donnerstag - 25-Jul-2024 </span>
<span id="t1a_6" class="t s5_6">1140201820 </span><span id="t1b_6" class="t s5_6">Lief-.Best-Nr.: </span>
<span id="t1c_6" class="t s3_6">17,17x1,78 EPDM FDA O-Ring USP </span>
<span id="t1d_6" class="t s3_6">Class VI, EG1935 </span>
<span id="t1e_6" class="t s5_6">HITEC O-Ring EPDM 70.10-02 </span>
<span id="t1f_6" class="t s8_6">Z-ORing-EP_5598</span><span id="t1g_6" class="t s8_6">5 </span><span id="t1h_6" class="t s8_6">ZeichnungsNr.: </span><span id="t1i_6" class="t s9_6">INDEX </span>
<span id="t1j_6" class="t s5_6">204-02557 </span>
<span id="t1k_6" class="t s5_6">0,00 </span>
<span id="t1l_6" class="t s5_6">151,24 </span>
<span id="t1m_6" class="t s3_6">14,0 </span><span id="t1n_6" class="t s3_6">50 </span><span id="t1o_6" class="t s3_6">St </span>
<span id="t1p_6" class="t s5_6">0,00 </span>
<span id="t1q_6" class="t s3_6">151,24 </span>
<span id="t1r_6" class="t s5_6">0,00% </span>
<span id="t1s_6" class="t s5_6">0,00% </span>
<span id="t1t_6" class="t s5_6">Kommission: </span>
<span id="t1u_6" class="t s6_6">Liefertermin an PRG: </span><span id="t1v_6" class="t s6_6">30/2024 </span>
<span id="t1w_6" class="t s3_6">Einzelpreis </span>
<span id="t1x_6" class="t s5_6">Einkaufspreis </span>
<span id="t1y_6" class="t s5_6">0,00% </span>
<span id="t1z_6" class="t s5_6">50 </span><span id="t20_6" class="t s5_6">in PRG - LME </span>
<span id="t21_6" class="t s5_6">Teilenummer </span>
<span id="t22_6" class="t s5_6">0,000 </span><span id="t23_6" class="t s5_6">Pos in Kg </span><span id="t24_6" class="t s7_6">kg </span>
<span id="t25_6" class="t s5_6">0,000 </span><span id="t26_6" class="t s7_6">kg / St </span>
<span id="t27_6" class="t s5_6">Einheit </span>
<span id="t28_6" class="t s5_6">kg/ME </span>
<span id="t29_6" class="t s3_6">75,62 </span>
<span id="t2a_6" class="t s6_6">Donnerstag - 25-Jul-2024 </span>
<span id="t2b_6" class="t s5_6">1140203572 </span><span id="t2c_6" class="t s5_6">Lief-.Best-Nr.: </span>
<span id="t2d_6" class="t s3_6">120,24x3,53 EPDM FDA O-Ring USP </span>
<span id="t2e_6" class="t s3_6">Class VI, EG1935 </span>
<span id="t2f_6" class="t s5_6">HITEC EPDM 70-10.02 </span>
<span id="t2g_6" class="t s8_6">Z-ORing-EP_5598</span><span id="t2h_6" class="t s8_6">5 </span><span id="t2i_6" class="t s8_6">ZeichnungsNr.: </span><span id="t2j_6" class="t s9_6">INDEX </span>
<span id="t2k_6" class="t s5_6">0,00 </span>
<span id="t2l_6" class="t s3_6">16,0 </span><span id="t2m_6" class="t s3_6">10 </span><span id="t2n_6" class="t s3_6">St </span>
<span id="t2o_6" class="t s5_6">0,00 </span>
<span id="t2p_6" class="t s3_6">38,66 </span><span id="t2q_6" class="t s3_6">Einzelpreis </span><span id="t2r_6" class="t s3_6">3,87 </span>
<span id="t2s_6" class="t s3_6">21,89x2,62 EPDM FDA O-Ring USP </span>
<span id="t2t_6" class="t s3_6">Class VI, EG1935 </span>
<span id="t2u_6" class="t s5_6">HITEC DW EPDM 70.503-00 </span>
<span id="t2v_6" class="t m0_6 s4_6">F90 </span></div>
<!-- End text definitions -->


</div>



<div id="p7" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1286px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_7{left:74px;bottom:996px;letter-spacing:-0.53px;}
#t2_7{left:124px;bottom:996px;letter-spacing:-0.18px;}
#t3_7{left:177px;bottom:996px;letter-spacing:-0.18px;}
#t4_7{left:225px;bottom:1038px;letter-spacing:0.17px;}
#t5_7{left:74px;bottom:1038px;letter-spacing:-0.08px;}
#t6_7{left:593px;bottom:1038px;letter-spacing:0.08px;}
#t7_7{left:666px;bottom:1038px;}
#t8_7{left:359px;bottom:1038px;letter-spacing:0.13px;}
#t9_7{left:320px;bottom:1038px;letter-spacing:-0.05px;}
#ta_7{left:74px;bottom:1081px;letter-spacing:0.06px;}
#tb_7{left:160px;bottom:1086px;letter-spacing:0.11px;}
#tc_7{left:596px;bottom:996px;letter-spacing:-0.14px;word-spacing:-0.01px;}
#td_7{left:745px;bottom:996px;letter-spacing:-0.16px;word-spacing:-0.02px;}
#te_7{left:225px;bottom:996px;letter-spacing:-0.17px;}
#tf_7{left:225px;bottom:1081px;letter-spacing:0.13px;word-spacing:0.03px;}
#tg_7{left:697px;bottom:1038px;}
#th_7{left:723px;bottom:1038px;}
#ti_7{left:333px;bottom:870px;letter-spacing:0.14px;}
#tj_7{left:688px;bottom:956px;letter-spacing:0.1px;}
#tk_7{left:710px;bottom:931px;letter-spacing:0.15px;}
#tl_7{left:710px;bottom:887px;letter-spacing:0.15px;}
#tm_7{left:95px;bottom:913px;letter-spacing:0.04px;}
#tn_7{left:95px;bottom:892px;letter-spacing:0.06px;word-spacing:0.02px;}
#to_7{left:225px;bottom:892px;letter-spacing:0.12px;}
#tp_7{left:528px;bottom:956px;letter-spacing:0.08px;}
#tq_7{left:710px;bottom:909px;letter-spacing:0.15px;}
#tr_7{left:333px;bottom:805px;letter-spacing:0.13px;}
#ts_7{left:169px;bottom:805px;letter-spacing:0.1px;word-spacing:0.03px;}
#tt_7{left:225px;bottom:870px;letter-spacing:-0.03px;}
#tu_7{left:333px;bottom:848px;letter-spacing:0.14px;}
#tv_7{left:225px;bottom:848px;word-spacing:0.13px;}
#tw_7{left:476px;bottom:849px;letter-spacing:-0.25px;}
#tx_7{left:333px;bottom:827px;letter-spacing:0.14px;}
#ty_7{left:476px;bottom:828px;letter-spacing:-0.23px;word-spacing:0.63px;}
#tz_7{left:727px;bottom:956px;letter-spacing:0.11px;}
#t10_7{left:199px;bottom:827px;letter-spacing:0.09px;}
#t11_7{left:312px;bottom:892px;letter-spacing:0.1px;word-spacing:-0.02px;}
#t12_7{left:333px;bottom:956px;letter-spacing:0.13px;}
#t13_7{left:225px;bottom:956px;letter-spacing:0.01px;}
#t14_7{left:225px;bottom:935px;letter-spacing:0.09px;}
#t15_7{left:331px;bottom:935px;}
#t16_7{left:95px;bottom:935px;letter-spacing:0.03px;}
#t17_7{left:351px;bottom:933px;letter-spacing:-0.18px;}
#t18_7{left:387px;bottom:773px;letter-spacing:-0.28px;}
#t19_7{left:552px;bottom:773px;letter-spacing:-0.12px;}
#t1a_7{left:225px;bottom:773px;letter-spacing:-0.24px;}
#t1b_7{left:780px;bottom:701px;letter-spacing:0.1px;}
#t1c_7{left:784px;bottom:636px;letter-spacing:0.14px;}
#t1d_7{left:398px;bottom:701px;letter-spacing:0.07px;word-spacing:0.08px;}
#t1e_7{left:398px;bottom:679px;letter-spacing:0.12px;}
#t1f_7{left:463px;bottom:679px;}
#t1g_7{left:791px;bottom:679px;letter-spacing:0.09px;}
#t1h_7{left:398px;bottom:658px;letter-spacing:0.1px;word-spacing:-0.02px;}
#t1i_7{left:398px;bottom:506px;letter-spacing:0.11px;word-spacing:-0.02px;}
#t1j_7{left:618px;bottom:506px;letter-spacing:0.09px;word-spacing:-0.02px;}
#t1k_7{left:398px;bottom:636px;letter-spacing:0.1px;word-spacing:-0.01px;}
#t1l_7{left:398px;bottom:595px;letter-spacing:0.07px;word-spacing:0.07px;}
#t1m_7{left:398px;bottom:574px;letter-spacing:0.08px;word-spacing:0.05px;}
#t1n_7{left:398px;bottom:552px;letter-spacing:0.02px;word-spacing:0.11px;}
#t1o_7{left:485px;bottom:683px;letter-spacing:-0.28px;word-spacing:0.12px;}
#t1p_7{left:74px;bottom:594px;letter-spacing:-0.18px;}
#t1q_7{left:74px;bottom:572px;letter-spacing:-0.18px;}
#t1r_7{left:74px;bottom:551px;letter-spacing:-0.18px;}
#t1s_7{left:74px;bottom:529px;letter-spacing:-0.18px;}
#t1t_7{left:78px;bottom:193px;letter-spacing:-0.2px;}
#t1u_7{left:78px;bottom:172px;letter-spacing:-0.21px;}
#t1v_7{left:78px;bottom:150px;letter-spacing:-0.27px;}
#t1w_7{left:242px;bottom:172px;letter-spacing:-0.13px;}
#t1x_7{left:242px;bottom:193px;letter-spacing:-0.3px;word-spacing:0.12px;}
#t1y_7{left:242px;bottom:150px;letter-spacing:-0.13px;}
#t1z_7{left:714px;bottom:193px;letter-spacing:-0.23px;}
#t20_7{left:520px;bottom:193px;letter-spacing:-0.21px;word-spacing:0.02px;}
#t21_7{left:805px;bottom:193px;letter-spacing:-0.24px;}
#t22_7{left:520px;bottom:150px;letter-spacing:-0.18px;word-spacing:0.01px;}
#t23_7{left:851.8px;bottom:63.4px;letter-spacing:0.11px;}

.s1{font-size:14px;font-family:LibreFranklin-Regular_18;color:#000;}
.s2{font-size:15px;font-family:LibreFranklin-Medium_19;color:#000;}
.s3{font-size:15px;font-family:LibreFranklin-Regular_18;color:#000;}
.s4{font-size:9px;font-family:LibreFranklin-Regular_18;color:#000;}
.s5{font-size:12px;font-family:LibreFranklin-Regular_18;color:#000;}
.s6{font-size:12px;font-family:LibreFranklin-Medium_19;color:#000;}
.s7{font-size:11px;font-family:LibreFranklin-Regular_18;color:#000;}
.s8{font-size:12px;font-family:LibreFranklin-Regular_18;color:#000080;}
.s9{font-size:14px;font-family:Monospac821BT_1a;color:#00F;}
.sa{font-size:12px;font-family:LibreFranklin-Italic_i;color:#000;}
.t.m0_7{transform:matrix(0,-1,1,0,0,0);}
</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts7" type="text/css" >

@font-face {
	font-family: LibreFranklin-Italic_i;
	src: url("fonts/LibreFranklin-Italic_i.woff") format("woff");
}

@font-face {
	font-family: LibreFranklin-Medium_19;
	src: url("fonts/LibreFranklin-Medium_19.woff") format("woff");
}

@font-face {
	font-family: LibreFranklin-Regular_18;
	src: url("fonts/LibreFranklin-Regular_18.woff") format("woff");
}

@font-face {
	font-family: Monospac821BT_1a;
	src: url("fonts/Monospac821BT_1a.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg7Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg7" style="-webkit-user-select: none;"><object width="909" height="1286" data="7/7.svg" type="image/svg+xml" id="pdf7" style="width:909px; height:1286px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div class="text-container"><span id="t1_7" class="t s1">Pos </span><span id="t2_7" class="t s1">Menge </span><span id="t3_7" class="t s1">ME </span>
<span id="t4_7" class="t s2">92148 </span><span id="t5_7" class="t s2">BESTELL-Nr.: </span><span id="t6_7" class="t s2">Seite </span><span id="t7_7" class="t s2">7 </span><span id="t8_7" class="t s2">22.07.2024 </span><span id="t9_7" class="t s2">vom: </span>
<span id="ta_7" class="t s3">Lieferant: </span>
<span id="tb_7" class="t s4">662433 </span>
<span id="tc_7" class="t s1">EP und Zuschläge </span><span id="td_7" class="t s1">Summe EUR </span><span id="te_7" class="t s1">Artikel </span>
<span id="tf_7" class="t s3">APSOparts GmbH </span>
<span id="tg_7" class="t s2">/ </span><span id="th_7" class="t s2">7 </span>
<span id="ti_7" class="t s5">204-02821 </span>
<span id="tj_7" class="t s5">38,66 </span>
<span id="tk_7" class="t s5">0,00% </span>
<span id="tl_7" class="t s5">0,00% </span>
<span id="tm_7" class="t s5">Kommission: </span>
<span id="tn_7" class="t s6">Liefertermin an PRG: </span><span id="to_7" class="t s6">30/2024 </span>
<span id="tp_7" class="t s5">Einkaufspreis </span>
<span id="tq_7" class="t s5">0,00% </span>
<span id="tr_7" class="t s5">10 </span><span id="ts_7" class="t s5">in PRG - LME </span>
<span id="tt_7" class="t s5">Teilenummer </span>
<span id="tu_7" class="t s5">0,000 </span><span id="tv_7" class="t s5">Pos in Kg </span><span id="tw_7" class="t s7">kg </span>
<span id="tx_7" class="t s5">0,000 </span><span id="ty_7" class="t s7">kg / St </span>
<span id="tz_7" class="t s5">Einheit </span>
<span id="t10_7" class="t s5">kg/ME </span>
<span id="t11_7" class="t s6">Donnerstag - 25-Jul-2024 </span>
<span id="t12_7" class="t s5">1140012626 </span><span id="t13_7" class="t s5">Lief-.Best-Nr.: </span>
<span id="t14_7" class="t s8">Z-ORing-EP_5598</span><span id="t15_7" class="t s8">5 </span><span id="t16_7" class="t s8">ZeichnungsNr.: </span><span id="t17_7" class="t s9">INDEX </span>
<span id="t18_7" class="t s1">Fracht </span><span id="t19_7" class="t s1">10,70 </span><span id="t1a_7" class="t s1">ZUSCHLÄGE </span>
<span id="t1b_7" class="t s3">301,05 </span>
<span id="t1c_7" class="t s2">311,75 </span>
<span id="t1d_7" class="t s3">Zwischensumme Warenwert </span>
<span id="t1e_7" class="t s3">Zuschläg</span><span id="t1f_7" class="t s3">e </span><span id="t1g_7" class="t s3">10,70 </span>
<span id="t1h_7" class="t s2">Bestellwert in EUR </span>
<span id="t1i_7" class="t s2">PRG Präzisions-Rührer GmbH </span><span id="t1j_7" class="t s2">/ Abtlg. Einkauf </span>
<span id="t1k_7" class="t s2">alle Beträge sind ohne MWST </span>
<span id="t1l_7" class="t sa">Liefertermine sind eingehend bei PRG Warburg </span>
<span id="t1m_7" class="t sa">Geben Sie zu allen Belegen unsere Bestellnummer mit an! </span>
<span id="t1n_7" class="t sa">Weisen Sie in allen Positionen die Zolltarifnummer (Stat.-WarenNr.) aus </span>
<span id="t1o_7" class="t s7">Porto, Fracht, Verpack. </span>
<span id="t1p_7" class="t s9">LIEFERADRESSE </span>
<span id="t1q_7" class="t s9">Präzisions-Rührer-GmbH </span>
<span id="t1r_7" class="t s9">Anton-Böhlen-Str. 13 </span>
<span id="t1s_7" class="t s9">D-34414 Warburg </span>
<span id="t1t_7" class="t s7">Zahlungsbedingung </span>
<span id="t1u_7" class="t s7">Lieferbedingung </span>
<span id="t1v_7" class="t s7">Versandart </span>
<span id="t1w_7" class="t s7">n.n. </span>
<span id="t1x_7" class="t s7">30 Tage netto </span>
<span id="t1y_7" class="t s7">n.n. </span>
<span id="t1z_7" class="t s7">0,380 </span><span id="t20_7" class="t s7">Gesamtgewicht der Bestellung </span><span id="t21_7" class="t s7">kg </span>
<span id="t22_7" class="t s7">inländische Lieferung </span>
<span id="t23_7" class="t m0_7 s4">F90 </span></div>
<!-- End text definitions -->


</div>

</body>
</html>

  `,
  'Einkaufsbestellung B2410-0069': `
  <!DOCTYPE html>
<html lang="en">
<head>
<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
<meta charset="utf-8" />
</head>

<body style="margin: 0;">

<div id="p1" style="overflow: hidden; position: relative; background-color: white; width: 909px; height: 1285px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_1{left:226px;bottom:825px;letter-spacing:0.11px;}
#t2_1{left:87px;bottom:1062px;letter-spacing:0.12px;}
#t3_1{left:87px;bottom:1045px;letter-spacing:0.1px;word-spacing:0.01px;}
#t4_1{left:87px;bottom:1029px;letter-spacing:0.1px;}
#t5_1{left:87px;bottom:1013px;letter-spacing:0.1px;}
#t6_1{left:88px;bottom:825px;letter-spacing:-0.03px;word-spacing:0.16px;}
#t7_1{left:519px;bottom:842px;letter-spacing:0.12px;}
#t8_1{left:666px;bottom:842px;letter-spacing:-0.04px;word-spacing:0.14px;}
#t9_1{left:88px;bottom:1149px;letter-spacing:0.1px;}
#ta_1{left:200px;bottom:1149px;letter-spacing:0.05px;}
#tb_1{left:88px;bottom:1166px;letter-spacing:0.04px;word-spacing:0.06px;}
#tc_1{left:88px;bottom:1183px;letter-spacing:0.1px;}
#td_1{left:88px;bottom:1199px;letter-spacing:-0.2px;word-spacing:0.04px;}
#te_1{left:519px;bottom:1078px;letter-spacing:0.1px;}
#tf_1{left:519px;bottom:1046px;letter-spacing:0.07px;word-spacing:0.05px;}
#tg_1{left:519px;bottom:1030px;letter-spacing:0.1px;}
#th_1{left:519px;bottom:1014px;letter-spacing:0.1px;word-spacing:0.01px;}
#ti_1{left:519px;bottom:998px;letter-spacing:0.1px;}
#tj_1{left:87px;bottom:931px;letter-spacing:0.14px;}
#tk_1{left:519px;bottom:934px;letter-spacing:0.11px;}
#tl_1{left:811px;bottom:934px;letter-spacing:0.08px;word-spacing:0.01px;}
#tm_1{left:576px;bottom:934px;letter-spacing:0.1px;}
#tn_1{left:88px;bottom:894px;letter-spacing:-0.02px;word-spacing:0.12px;}
#to_1{left:226px;bottom:842px;letter-spacing:0.11px;}
#tp_1{left:88px;bottom:842px;letter-spacing:0.08px;word-spacing:0.03px;}
#tq_1{left:666px;bottom:894px;letter-spacing:0.1px;}
#tr_1{left:520px;bottom:894px;letter-spacing:0.12px;}
#ts_1{left:666px;bottom:876px;letter-spacing:0.17px;}
#tt_1{left:519px;bottom:876px;letter-spacing:0.12px;}
#tu_1{left:519px;bottom:825px;letter-spacing:0.12px;}
#tv_1{left:88px;bottom:758px;letter-spacing:-0.02px;}
#tw_1{left:153px;bottom:758px;letter-spacing:0.11px;}
#tx_1{left:457px;bottom:758px;letter-spacing:0.15px;}
#ty_1{left:522px;bottom:758px;letter-spacing:0.1px;}
#tz_1{left:599px;bottom:758px;letter-spacing:0.09px;}
#t10_1{left:640px;bottom:758px;letter-spacing:0.1px;word-spacing:0.02px;}
#t11_1{left:748px;bottom:758px;letter-spacing:0.11px;}
#t12_1{left:152px;bottom:719px;letter-spacing:0.12px;}
#t13_1{left:473px;bottom:719px;letter-spacing:0.1px;}
#t14_1{left:532px;bottom:719px;letter-spacing:0.01px;}
#t15_1{left:603px;bottom:719px;letter-spacing:0.1px;}
#t16_1{left:797px;bottom:719px;letter-spacing:0.1px;}
#t17_1{left:151px;bottom:736px;letter-spacing:0.12px;}
#t18_1{left:87px;bottom:736px;}
#t19_1{left:234px;bottom:700px;letter-spacing:0.12px;}
#t1a_1{left:153px;bottom:700px;letter-spacing:0.01px;word-spacing:0.08px;}
#t1b_1{left:152px;bottom:665px;letter-spacing:0.12px;}
#t1c_1{left:473px;bottom:665px;letter-spacing:0.1px;}
#t1d_1{left:532px;bottom:665px;letter-spacing:0.01px;}
#t1e_1{left:603px;bottom:665px;letter-spacing:0.1px;}
#t1f_1{left:797px;bottom:665px;letter-spacing:0.1px;}
#t1g_1{left:151px;bottom:683px;letter-spacing:0.12px;}
#t1h_1{left:87px;bottom:683px;}
#t1i_1{left:234px;bottom:646px;letter-spacing:0.12px;}
#t1j_1{left:153px;bottom:646px;letter-spacing:0.01px;word-spacing:0.08px;}
#t1k_1{left:563px;bottom:588px;letter-spacing:0.06px;word-spacing:0.05px;}
#t1l_1{left:798px;bottom:562px;letter-spacing:0.11px;}
#t1m_1{left:563px;bottom:562px;letter-spacing:0.03px;word-spacing:0.07px;}
#t1n_1{left:563px;bottom:614px;letter-spacing:0.01px;word-spacing:0.11px;}
#t1o_1{left:800px;bottom:588px;letter-spacing:0.1px;}
#t1p_1{left:798px;bottom:614px;letter-spacing:0.11px;}
#t1q_1{left:92px;bottom:118px;letter-spacing:0.07px;word-spacing:0.03px;}
#t1r_1{left:337px;bottom:102px;letter-spacing:0.12px;}

.s1{font-size:12px;font-family:DEVEXP-2BSegoeUI_o;color:#000;}
.s2{font-size:12px;font-family:DEVEXP-2BSegoeUI-2CBold_m;color:#000;}
.s3{font-size:14px;font-family:DEVEXP-2BSegoeUI-2CBold_m;color:#000;}
.s4{font-size:15px;font-family:DEVEXP-2BSegoeUI-2CBold_m;color:#000;}
</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts1" type="text/css" >

@font-face {
	font-family: DEVEXP-2BSegoeUI-2CBold_m;
	src: url("fonts/DEVEXP-2BSegoeUI-2CBold_m.woff") format("woff");
}

@font-face {
	font-family: DEVEXP-2BSegoeUI_o;
	src: url("fonts/DEVEXP-2BSegoeUI_o.woff") format("woff");
}

</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg1Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg1" style="-webkit-user-select: none;"><object width="909" height="1285" data="1/1.svg" type="image/svg+xml" id="pdf1" style="width:909px; height:1285px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div class="text-container"><span id="t1_1" class="t s1">CHE-108.067.379 </span>
<span id="t2_1" class="t s1">APSOparts AG </span>
<span id="t3_1" class="t s1">Leutschenbachstrasse 55 </span>
<span id="t4_1" class="t s1">8050 Zürich </span>
<span id="t5_1" class="t s1">Schweiz </span>
<span id="t6_1" class="t s2">MWST Nr. </span>
<span id="t7_1" class="t s2">Zahlungsbedingungen </span><span id="t8_1" class="t s1">30 Tage netto </span>
<span id="t9_1" class="t s1">inrag@inrag.ch </span><span id="ta_1" class="t s1">www.inrag.ch </span>
<span id="tb_1" class="t s1">Tel. +41 61 378 96 00 / Fax +41 61 378 96 11 </span>
<span id="tc_1" class="t s1">Auhafenstrasse 3a, CH - 4132 Muttenz </span>
<span id="td_1" class="t s3">INRAG AG </span>
<span id="te_1" class="t s2">Lieferadresse: </span>
<span id="tf_1" class="t s1">INRAG AG </span>
<span id="tg_1" class="t s1">Auhafenstrasse 3a </span>
<span id="th_1" class="t s1">4132 Muttenz </span>
<span id="ti_1" class="t s1">Schweiz </span>
<span id="tj_1" class="t s4">Einkaufsbestellung: B2410-0069 </span><span id="tk_1" class="t s2">Muttenz, </span><span id="tl_1" class="t s1">Seite 1 / 1 </span><span id="tm_1" class="t s1">22.10.24 </span>
<span id="tn_1" class="t s2">Projekt Nr. </span>
<span id="to_1" class="t s1">Micha Seltmann </span><span id="tp_1" class="t s2">Unsere Referenz </span>
<span id="tq_1" class="t s1">25.10.24 </span><span id="tr_1" class="t s2">Lieferdatum </span>
<span id="ts_1" class="t s1">EXW </span><span id="tt_1" class="t s2">Lieferbedingung </span>
<span id="tu_1" class="t s2">Ihre Angebotsnummer </span>
<span id="tv_1" class="t s2">Pos. </span><span id="tw_1" class="t s2">Beschreibung </span><span id="tx_1" class="t s2">Menge </span><span id="ty_1" class="t s2">Einheit </span><span id="tz_1" class="t s2">Preis </span><span id="t10_1" class="t s2">Rabatt % </span><span id="t11_1" class="t s2">Betrag (CHF) </span>
<span id="t12_1" class="t s1">O-Ring FKM Ø 28,0x2,0mm </span><span id="t13_1" class="t s1">3.00 </span><span id="t14_1" class="t s1">Stück </span><span id="t15_1" class="t s1">3.00 </span><span id="t16_1" class="t s1">3.00 </span>
<span id="t17_1" class="t s2">640887-016 </span><span id="t18_1" class="t s1">1 </span>
<span id="t19_1" class="t s1">1140072028 </span><span id="t1a_1" class="t s1">Ihre Artikelnr.: </span>
<span id="t1b_1" class="t s1">O-Ring FKM Ø 28,0x1,5mm </span><span id="t1c_1" class="t s1">3.00 </span><span id="t1d_1" class="t s1">Stück </span><span id="t1e_1" class="t s1">3.00 </span><span id="t1f_1" class="t s1">3.00 </span>
<span id="t1g_1" class="t s2">640887-017 </span><span id="t1h_1" class="t s1">2 </span>
<span id="t1i_1" class="t s1">1140071280 </span><span id="t1j_1" class="t s1">Ihre Artikelnr.: </span>
<span id="t1k_1" class="t s1">MwSt. 8.1% </span>
<span id="t1l_1" class="t s2">6.50 </span><span id="t1m_1" class="t s2">Total inkl. MwSt. (CHF) </span>
<span id="t1n_1" class="t s2">Total ohne MwSt. </span>
<span id="t1o_1" class="t s1">0.50 </span>
<span id="t1p_1" class="t s2">6.00 </span>
<span id="t1q_1" class="t s1">Telefon : +41 61 378 96 00 | E-Mail : inrag@inrag.ch | Homepage : www.inrag.ch | MWST Nr. : CHE-108.067.379 | IBAN : CH83 0023 3233 </span>
<span id="t1r_1" class="t s1">1597 0405 0 | SWIFT-Code : UBSWCHZH80A </span></div>
<!-- End text definitions -->


</div>
</body>
</html>
`,
}
