import React, { useState } from 'react'

import * as stringSimilarity from 'string-similarity'

import {
  getAlignment,
  getEditDistance,
  getInitialSelection,
  getIsUnique,
  getLeadingItemKey,
  getMatchingFormats,
  getSimilarity,
  isDate,
  isDecimal,
  splitValue,
} from '../utils'
import { ChoicesPopup } from './ChoicesPopup'

const SELECTED_LABEL_CLASS = 'uta-selected-label'
const SELECTED_VALUE_CLASS = 'uta-selected'

// tolerance used when selecting the value cell specified by the format
const GENERIC_POSITION_TOLERANCE = 10 // pixels

// tolerance for right-aligned elements
const RIGHT_POSITION_TOLERANCE = 10 // pixels
// tolerance for left-aligned elements
const LEFT_POSITION_TOLERANCE = 10 // pixels

// TODO too many props; consider useContext
export const Document = ({
  dateDataFormatId,
  decimalDataFormatId,
  addToCartErrors,
  locale,
  isInteractive,
  isLabelFeatureEnabled,
  onFieldSelected,
  resetSelection,
  currentField,
  format,
  dataFormats,
  onFormatChange,
  onDateDataFormatIdChange,
  onDecimalDataFormatIdChange,
  html,
  tutCurrentStep,
  headerHeight,
  isLatestFormatVersion,
  setIsFormatChecked,
  isFormatChecked,
  choicesTexts,
  setChoicesTexts,
  setSelection,
  selection,
  choicesValues,
  missingDataFormatKey,
  setChoicesValues,
  customFields,
}) => {
  const [lights, setLights] = useState({})
  const [updateLightsCount, setUpdateLightsCount] = useState(0)
  const [choicesTop, setChoicesTop] = useState({})
  const [choicesLeft, setChoicesLeft] = useState({})
  const [, setChoicesRight] = useState({})

  const [leadingItemKey] = useState(getLeadingItemKey(customFields))

  const Container = React.createRef()

  React.useEffect(() => {
    handleLayoutChanges()
    // we wanna execute this only on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    updateLights()
  }, [headerHeight, isFormatChecked, format])

  React.useEffect(() => {
    let initialSelection = getInitialSelection(customFields)

    setChoicesTop(initialSelection)
    setChoicesLeft(initialSelection)
  }, [customFields])

  React.useEffect(() => {
    if (lights[leadingItemKey]?.length) {
      // the leading item key has been selected;
      // we can update lights for all other item keys
      Object.keys(format)
        .filter((key) => {
          if (!customFields[key]) {
            console.log('error: customFields type item null for key:', key, customFields)
          }
          return key !== leadingItemKey && customFields[key]?.type === 'item'
        })
        .forEach((key) => updateLightsForKey({ key }))
    }
  }, [lights[leadingItemKey]])

  const hasLayoutChangedForKey = ({ key }) => {
    const { labelPosition, labelText } = format[key]

    const { cell: labelCell } = getLabelCells({ key, text: labelText })[0] || { cell: undefined }

    // we didn't find the label for this key;
    // that means that we didn't save it before (format was old) or that the new format is completely different
    if (!labelCell) {
      return
    }

    const { cellTop, cellLeft } = getCellPositionV2(labelCell)

    // is the label cell in a position that is different from the saved one?
    // (e.g. one additional line between the header and the order positions)
    return labelPosition.left !== cellLeft || labelPosition.top !== cellTop
  }

  const hasValuesNearby = ({ valuePosition: { top, left, right }, alignment }) => {
    const cellsInSimilarPosition = getAllCells().filter((cell) =>
      isInSimilarPosition({ cell, top, left, right, alignment })
    )

    return !!cellsInSimilarPosition[0]
  }

  const getPositionDiff = ({ top: aTop, left: aLeft }, { top: bTop, left: bLeft }) => {
    // TODO add some tolerance here
    const diffX = aLeft - bLeft
    const diffY = aTop - bTop

    return { diffX, diffY }
  }

  const getShiftedCellPosition = ({
    cellPosition: { top, left, right },
    diff: { diffX, diffY },
    alignment,
  }) => ({
    top: top + diffY,
    left: alignment === 'left' ? left + diffX : left,
    right: alignment === 'right' ? right - diffX : right,
  })

  /** Recursive */
  const handleLayoutChangesForKey = ({
    key,
    // used during recursion;
    // we wanna specify which occurrence of label we wanna use as the "real" label
    labelIndex = 0,
  }) => {
    const {
      labelPosition: storedLabelPosition,
      labelText,
      top: storedValueTop,
      left: storedValueLeft,
      right: storedValueRight,
    } = format[key]
    const { cell: labelCell } = getLabelCells({ key, text: labelText })[labelIndex] || {
      cell: undefined,
    }

    if (!labelCell) {
      // no occurrences of the label could be found; nothing to do
      return
    }

    const {
      cellTop: newLabelTop,
      cellLeft: newLabelLeft,
      cellRight: newLabelRight,
    } = getCellPositionV2(labelCell)

    // if the label is now shifted, the values must be shifted too;
    // the difference in pixel must be the same for both label and value
    const { diffX, diffY } = getPositionDiff(
      {
        top: newLabelTop,
        left: newLabelLeft,
      },
      storedLabelPosition
    )

    const alignment = getAlignment({ format, key, customFields })

    const newValuePosition = getShiftedCellPosition({
      cellPosition: {
        top: storedValueTop,
        left: storedValueLeft,
        right: storedValueRight,
      },
      diff: { diffX, diffY },
      alignment,
    })

    if (!hasValuesNearby({ valuePosition: newValuePosition, alignment })) {
      // no values found nearby; try with the next occurrence of the label
      return handleLayoutChangesForKey({ key, labelIndex: labelIndex + 1 })
    }

    // this is potentially a good label;
    // update the format with the new positions
    onFormatChange({
      key,
      valuePosition: newValuePosition,
      labelPosition: {
        top: newLabelTop,
        left: newLabelLeft,
        right: newLabelRight,
      },
      label: labelText,
      // TODO rename; separate concerns
      updateTut: false,
    })
  }

  /**
   * Check whether the saved format is still valid. If not, the format will be updated.
   */
  const handleLayoutChanges = () => {
    Object.keys(format).forEach((key) => {
      const { top, left } = format[key]

      if (!top || !left) {
        return
      }

      if (hasLayoutChangedForKey({ key })) {
        console.log(`Layout change detected for key "${key}", recalculating...`)

        handleLayoutChangesForKey({ key })
      }
    })
  }
  const getAllCells = () => Array.from(Container.current.getElementsByClassName('t'))

  const getLabelCells = ({ key, text }) => {
    if (!text) {
      return []
    }

    // 1. compute cells' similarity with the input text
    const cellsWithSimilarity = getAllCells().reduce((acc, cell) => {
      const cellValue = cell.innerText.trim()

      // e.g. 1 (for exact matches)
      const similarity = stringSimilarity.compareTwoStrings(cellValue, text)

      return [...acc, { cell, similarity }]
    }, [])

    const {
      labelPosition: { top: labelTop, left: labelLeft, right: labelRight },
      top: valueTop,
      left: valueLeft,
      right: valueRight,
    } = format[key]

    const alignment = getAlignment({ format, key, customFields })

    // TODO refactor and remove duplicate code
    if (
      Math.abs(labelTop - valueTop) <= GENERIC_POSITION_TOLERANCE &&
      (alignment === 'left'
        ? Math.abs(labelLeft === valueLeft) <= GENERIC_POSITION_TOLERANCE
        : Math.abs(labelRight === valueRight) <= GENERIC_POSITION_TOLERANCE)
    ) {
      // 2a. if the label and the value are in the same cell, we don't need exact matching
      const filtered = cellsWithSimilarity.filter(
        (item) =>
          // very arbitrary threshold, seems to work fine for most PDFs
          item.similarity >= 0.625
      )

      // 2b. if one of the matches is in the same position as the stored one, return this one
      const inSamePosition = filtered.find((item) => {
        const { cellTop, cellLeft, cellRight } = getCellPositionV2(item.cell)

        // 1px tolerance
        return Math.abs(cellTop - labelTop) <= 1 && alignment === 'left'
          ? Math.abs(cellLeft === labelLeft) <= 1
          : Math.abs(cellRight === labelRight) <= 1
      })

      return inSamePosition ? [inSamePosition] : filtered
    }

    // 2c. if the label is not the same cell as the value, we just return the match with similarity 1 (i.e. exact match);
    // there might be more than one cell that match the text exactly
    return cellsWithSimilarity.filter((item) => item.similarity === 1)
  }

  // TODO still needed?
  const updateForOldFormat = ({ cell, top, left, key }) => {
    const { cellTop, cellLeft, cellRight } = getCellPositionV2(cell)
    const { cellTop: oldCellTop, cellLeft: oldCellLeft } = getCellPositionV1(cell)

    if (oldCellTop === top && oldCellLeft === left && !isLatestFormatVersion) {
      // update the cell format with the new algorithm
      onFormatChange({
        key,
        valuePosition: {
          left: cellLeft,
          top: cellTop,
          right: cellRight,
        },
      })
    }
  }

  const isInSimilarPosition = ({ cell, top, left, right, alignment }) => {
    // get only the cells that are in a similar position with the saved one (within some tolerance)
    const { cellTop, cellLeft, cellRight } = getCellPositionV2(cell)

    return (
      Math.abs(cellTop - top) <= GENERIC_POSITION_TOLERANCE &&
      Math.abs(alignment === 'left' ? cellLeft - left : cellRight - right) <=
        GENERIC_POSITION_TOLERANCE
    )
  }

  // TODO still needed? get a test pdf
  // if needed, refactor
  const ensureValidQuantities = () => {
    // We added this code to avoid selection of invalid fields/text in quantity column.
    // On page load, we call onFormatChange with same similarity
    setUpdateLightsCount(updateLightsCount + 1)

    if (updateLightsCount === 1) {
      let key = 'qty'

      const similarity = getSimilarity({ key, format, customFields })

      if (similarity > 0) {
        onFormatChange({ key: `${key}-similarity`, value: similarity })
      }
    }

    if (updateLightsCount >= 1) {
      setIsFormatChecked(true)
    }
  }

  const handleLabelLight = ({ key, text }) => {
    shadeLabel({ key })

    const { cell: labelCell } = getLabelCells({ key, text })[0] || { cell: undefined }

    // we didn't find the label for this key;
    // that means that we didn't save it before (format was old) or that the new format is completely different
    if (!labelCell) {
      return
    }

    // light the label up
    light({ cell: labelCell, key, mode: 'label' })
  }

  const updateLightsForKey = ({ key }) => {
    // 1. remove any previous lights on this key;
    // we wanna do this even if no value has been saved yet for this key
    handleShadeForKey({ key })

    // 2. light up the label;
    // we wanna do this even if no value has been saved yet for this key
    handleLabelLight({ key, text: format[key].labelText })

    const { top, left, right } = format[key]
    const alignment = getAlignment({ format, key, customFields })
    const isUnique = getIsUnique({ format, key, customFields })

    // no value saved yet for this key
    if (!top || !left) {
      return
    }

    // 3. exclude cells that only contain special chars or punctuation
    const alphanumericCells = getAllCells().filter(isAlphanumeric)

    // 4. get the cell specified by the saved format (within some tolerance)
    const cellInSimilarPosition = alphanumericCells.find((cell) =>
      isInSimilarPosition({ cell, top, left, right, alignment })
    )

    // no cells found; the format is probably too different; nothing to do
    if (!cellInSimilarPosition) {
      return
    }

    // 5a. light up only this cell if unique
    if (isUnique) {
      handleLight({ cell: cellInSimilarPosition, key, top, left, right })

      return
    }

    // 5b. light up similar cells
    lightSimilarCells({ key, cell: cellInSimilarPosition })
  }

  /**
   * This function scans the HTML and updates the colored lights.
   */
  const updateLights = () => {
    // TODO still needed? get a test pdf
    ensureValidQuantities()

    // update lights for header keys;
    // we also update the lights for the leading item key; this allows us to extract the order position markers from the document later on
    Object.keys(format)
      .filter((key) => {
        if (!customFields[key]) {
          console.log('error: customFields type header null for key:', key, customFields)
        }
        return key === leadingItemKey || customFields[key]?.type === 'header'
      })
      .forEach((key) => updateLightsForKey({ key }))

    // we don't update lights for other item keys here;
    // an effect will take care of that;
    // this is to make sure that order positions are calculated correctly
  }

  const resetChoices = ({ key }) => {
    //remove selection from the choices array so it is not displayed in the UI
    setChoicesTexts((prev) => ({ ...prev, [key]: [] }))
    setChoicesTop((prev) => ({ ...prev, [key]: [] }))

    const alignment = getAlignment({ format, key, customFields })

    if (alignment === 'right') {
      setChoicesRight((prev) => ({ ...prev, [key]: [] }))
    }

    if (alignment === 'left') {
      setChoicesLeft((prev) => ({ ...prev, [key]: [] }))
    }
  }

  const updateChoices = ({ key, values, top, left, cell }) => {
    setChoicesTexts((prev) => {
      const optionsForKey = [...(prev[key] || []), values]

      return {
        ...prev,
        [key]: optionsForKey,
      }
    })

    if (!cell) {
      // cell might be null if we couldn't extract any value from the order position due to some weird layout;
      // nothing left to do in this case
      return
    }

    setChoicesTop((prev) => ({
      ...prev,
      [key]: [...(prev[key] || []), top],
    }))

    setChoicesLeft((prev) => ({
      ...prev,
      [key]: [...(prev[key] || []), left + cell.getBoundingClientRect().width + 30],
    }))
  }

  const handleChoices = ({ cell, key, top, left }) => {
    const { textContent: content } = cell || { textContent: '' }

    const value = (
      key === 'partNoCust'
        ? // fix for 84231-0000302985_4500765303.pdf;
          // https://gitlab.com/sly-ag/sly-connect/-/issues/148
          content.replaceAll(' / ', '/')
        : content
    ).trim()

    let values

    if (!value) {
      // we didn't match anything in the current order position;
      // this is probably because the order position has a weird layout in comparison with the other positions;
      // just add "undefined" to the options, will be hidden later
      return updateChoices({ key, values: [undefined], top, left, cell })
    }

    if (isDecimal({ key, customFields })) {
      /**
       * e.g. for DECIMAL types:
       *
       *    [
       *      {
       *        id: '640740b99006632c81c0cb76',
       *        dataType: 'DECIMAL',
       *        mask: '.',
       *        displayName: { en: '. (dot)' },
       *      },
       *      {
       *        id: '640740b99006632c81c0cb77',
       *        dataType: 'DECIMAL',
       *        mask: ',',
       *        displayName: { en: ', (comma)' },
       *      },
       *    ]
       */
      values = dataFormats.filter((df) => df.dataType === 'DECIMAL')
    } else if (isDate({ key, customFields })) {
      /**
       * e.g.
       *
       *    [
       *      {
       *        id: '640729299652b71a4d27df51',
       *        dataType: 'DATE',
       *        regex: '\\b(0?[1-9]|[12][0-9]|30|31)/(0?[1-9]|1[0-2]])/20[0-9]{2}',
       *        mask: 'dd/MM/yyyy',
       *        locale: null,
       *        displayName: { en: '(e.g. 17/01/2023)' },
       *      },
       *      {
       *        id: '640729179652b71a4d27df4e',
       *        dataType: 'DATE',
       *        regex: '\\b(0?[1-9]|[12][0-9]|30|31)/(0?[1-9]|1[0-2]])/20[0-9]{2}',
       *        mask: 'MM/dd/yyyy',
       *        locale: null,
       *        displayName: { en: '(e.g. 11/17/2023)' },
       *      }
       *    ]
       */
      values = getMatchingFormats({ value, type: 'DATE', dataFormats })
    } else {
      // string
      values = splitValue(value)
    }

    // TODO separate concerns
    if (values.length === 1) {
      // if only one dataFormat choice is available, store it in the format right away;
      // if multiple choices are available, skip this step; we'll store the one that the user selects later on (see the `handleChange` function in `ChoicesPopup.js`)

      if (isDate({ key, customFields })) {
        // save the chosen date format;
        // e.g. "a1"
        onDateDataFormatIdChange(values[0].id)
      }

      // TODO does this ever happen? this would mean that we only have one decimal dataFormat stored in the DB
      if (isDecimal({ key, customFields })) {
        // save the chosen decimal format;
        // e.g. "a1"
        onDecimalDataFormatIdChange(values[0].id)
      }
    }

    const shouldUpdateChoices =
      // only when we have multiple choices
      values.length > 1 &&
      // skip the first render, as we don't wanna show the popup as soon as the PDF has been loaded and lit up
      updateLightsCount >= 1

    if (shouldUpdateChoices) {
      updateChoices({ key, values, top, left, cell })
    } else {
      resetChoices({ key })
    }
  }

  // TODO separate concerns
  const prepareLight = ({ cell, key, top, left }) => {
    // add cell to current lights
    setLights((prev) => ({
      ...prev,
      [key]: [...(prev[key] || []), cell],
    }))

    // TODO separate concerns
    onFieldSelected({
      key,
      value:
        cell?.innerText.trim() ||
        // we need empty string here in order to avoid order positions being shifted when a value could not be extracted from all rows
        '',
    })

    // TODO separate concerns
    handleChoices({ cell, key, top, left })
  }

  const isAlphanumeric = (cell) => {
    const cellValue = cell.innerText.trim()

    return !!cellValue.match(new RegExp(/[a-zA-Z0-9]+/i, 'g'))
  }

  const isInColumn = ({ cell, alignment, top, left, right }) => {
    const { cellTop, cellLeft, cellRight } = getCellPositionV2(cell)

    if (alignment === 'right') {
      return Math.abs(right - cellRight) <= RIGHT_POSITION_TOLERANCE && cellTop >= top
    }

    return Math.abs(cellLeft - left) <= LEFT_POSITION_TOLERANCE && cellTop >= top
  }

  const hasSimilarContent = ({
    cell,
    isQuantityFormat,
    value,
    similarity,
    mustHaveSameLength,
    minLength,
    regex,
  }) => {
    const cellValue = cell.innerText.trim()

    if (!cellValue) {
      return false
    }

    // if the value is only text, we discard this selection, we need digits + text (optionally)
    if (isQuantityFormat && !/^[0-9](.*)$/.test(cellValue)) {
      return false
    }

    // 1. content is similar
    // TODO if a regex has been defined, it probably makes no sense to calculate the edit distance?
    // the regex should be enough
    const isSimilar = getEditDistance(value, cellValue) <= similarity
    const hasAcceptableLength =
      // 2. content has same length if required
      (!mustHaveSameLength || (mustHaveSameLength && value.length === cellValue.length)) &&
      // 3. content has a minimum length
      cellValue.length >= minLength
    // 4. matches specified regex
    const doesMatchRegex = regex ? cellValue.match(new RegExp(regex, 'g')) : true

    return isSimilar && hasAcceptableLength && doesMatchRegex
  }

  const hasSimilarStyle = ({ cell, fontSize }) => {
    const cellValue = cell.innerText.trim()

    if (!cellValue) {
      return false
    }

    const computedStyle = window.getComputedStyle(cell, null)

    const cellFontSize = computedStyle.fontSize

    return fontSize === cellFontSize
  }

  /** The markers indicate where order positions start */
  const getOrderPositionMarkers = () => {
    if (!lights[leadingItemKey]) {
      // we need the leading item to be selected
      return []
    }

    const selectedCells = lights[leadingItemKey]

    const tops = selectedCells.map((cell) => getCellPositionV2(cell).cellTop)

    return tops
  }

  // makes sure that we select only one cell per order position;
  // order positions are delimited by the leading items (e.g. qty)
  const getCellsInRowV3 = ({ cells, key, isUnique }) => {
    const cellsArray = Array.isArray(cells) ? cells : [cells]

    if (key === leadingItemKey || isUnique) {
      // nothing to do on leading or unique items
      return cells
    }

    const orderPositionMarkers = getOrderPositionMarkers()

    // filtering fields that belong to the same order position as the leadingItemKey
    return orderPositionMarkers.reduce(
      (acc, currentTop, i) => {
        // `currentTop` and `currentBottom` are the row (order position) delimiters;
        // they mark where the current order position starts and ends
        const currentBottom = orderPositionMarkers[i + 1] || 99999

        const previousTop = orderPositionMarkers[i - 1]
        const previousBottom = currentTop

        // we extract all the cells that belong to the current order position
        const { isLeadingItemOnFirstLine, cells: cellsInRow } = cellsArray.reduce(
          (acc, cell, j) => {
            const { cellTop } = getCellPositionV2(cell)

            const isBetweenMarkers = acc.isLeadingItemOnFirstLine
              ? // 5px tolerance
                cellTop >= currentTop - 5 && cellTop < currentBottom
              : cellTop >= previousTop && cellTop < previousBottom

            if (!isBetweenMarkers) {
              if (!previousTop && j === 0) {
                // the leading item is not on the first line within any given order position
                return { isLeadingItemOnFirstLine: false, cells: [...acc.cells, cell] }
              }

              return acc
            }

            return {
              isLeadingItemOnFirstLine: acc.isLeadingItemOnFirstLine,
              cells: [...acc.cells, cell],
            }
          },
          { isLeadingItemOnFirstLine: acc.isLeadingItemOnFirstLine, cells: [] }
        )

        return {
          isLeadingItemOnFirstLine,
          cells: [
            ...acc.cells,
            // just take the first cell if defined
            cellsInRow[0] ||
              // otherwise, return undefined;
              // we do this because we still need one value per order position,
              // so that the other positions do not get shifted during the add to cart process
              undefined,
          ],
        }
      },
      { isLeadingItemOnFirstLine: true, cells: [] }
    ).cells
  }

  const lightSimilarCells = ({ key, cell }) => {
    if (!customFields[key]) {
      // if the key from formats exist in conf, then find similar values
      // the issue can happen when new conf has less fields that what is saved in the last formats
      return
    }

    const { cellTop: top, cellLeft: left, cellRight: right } = getCellPositionV2(cell)
    const value = cell.innerText.trim()

    const { defaults, regex, mustHaveSameLength } = customFields[key]

    // 1. get cells that are on the same column (within some horizontal tolerance)
    const cellsInColumn = getAllCells().filter((cell) =>
      isInColumn({ cell, alignment: defaults.alignment, top, left, right })
    )

    // 2. exclude cells that have been selected already
    const nonSelectedCells = cellsInColumn.filter(
      (cell) => !cell.classList.contains(SELECTED_VALUE_CLASS)
    )

    // 3. get cells that have similar content and similar style
    const { isUnique, minLength, isQuantityFormat } = defaults
    const fontSize = window.getComputedStyle(cell, null).fontSize

    const similarCells = nonSelectedCells[isUnique ? 'find' : 'filter'](
      (cell) =>
        hasSimilarContent({
          cell,
          isQuantityFormat,
          value,
          similarity: getSimilarity({ key, format, customFields }),
          mustHaveSameLength:
            mustHaveSameLength !== undefined ? mustHaveSameLength : defaults.mustHaveSameLength,
          minLength,
          regex,
        }) && hasSimilarStyle({ cell, fontSize })
    )

    // 4. get cells in proper rows with the leadingItemKey
    const cellsInRow = getCellsInRowV3({ key, isUnique, cells: similarCells })

    // 5. light up the cells
    const result = (Array.isArray(cellsInRow) ? cellsInRow : [cellsInRow]).forEach((cell) =>
      handleLight({ cell, key, top, left, right })
    )
  }

  const shadeLabel = ({ key }) => {
    const labelCell = getAllCells().find(
      (cell) => cell.classList.contains(SELECTED_LABEL_CLASS) && cell.classList.contains(key)
    )

    if (labelCell) {
      shade({ cell: labelCell, key, mode: 'label' })
    }
  }

  const handleLight = ({ cell, key, top, left, right }) => {
    prepareLight({ cell, key, top, left, right })

    light({ cell, key })
  }

  const handleShadeForKey = ({ key }) => {
    prepareShade({ key })

    shadeLabel({ key })

    shadeAll({ key })
  }

  const light = ({ cell, key, mode = 'value' }) => {
    cell?.classList.add(mode === 'label' ? SELECTED_LABEL_CLASS : SELECTED_VALUE_CLASS)
    cell?.classList.add(key)
    cell?.classList.add('field_' + findKeyIndex({ key }))
  }

  const shade = ({ cell, key, mode = 'value' }) => {
    cell?.classList.remove(mode === 'label' ? SELECTED_LABEL_CLASS : SELECTED_VALUE_CLASS)
    cell?.classList.remove(key)
    cell?.classList.remove('field_' + findKeyIndex({ key }))
  }

  const prepareShade = ({ key }) => {
    resetSelection({ key })

    setLights((prev) => ({ ...prev, [key]: [] }))
  }

  const shadeAll = ({ key }) => (lights[key] || []).forEach((cell) => shade({ cell, key }))

  const findKeyIndex = ({ key }) => Object.keys(customFields).indexOf(key)

  const onLabelClick = async ({ cell, key }) => {
    if (!cell.classList.contains('t')) {
      return
    }

    const labelValue = cell.innerText.trim()
    const { cellTop, cellLeft, cellRight } = getCellPositionV2(cell)

    // save the label's text and position
    onFormatChange({
      key,
      labelPosition: {
        left: cellLeft,
        top: cellTop,
        right: cellRight,
      },
      label: labelValue,
      valuePosition: {},
      value: undefined,
    })
  }

  const onClick = async ({ target: cell }) => {
    //remove old selection options, before new one are set
    resetChoices({ key: currentField })

    // TODO in principle this should be in `resetChoices`, but that function
    // gets called after file upload, and resetting the choices values there
    // would mess up the choices coming from the db
    setChoicesValues((prev) => ({ ...prev, [currentField]: [] }))

    if (!cell.classList.contains('t')) {
      return
    }

    const value = cell.innerText.trim()
    const { cellTop, cellLeft, cellRight } = getCellPositionV2(cell)

    if (isLabelFeatureEnabled && !format[currentField]?.labelText) {
      /*
        An issue we currently have is that some PDFs from the same customer are different,
        e.g. they contain additional lines between the header and the order positions,
        therefore the format that has been previously saved is often no longer valid.
      
        We try to mitigate this by having the user click first on the label (e.g. "Mat-Nr."),
        then on the value. We save the text and position of both label and value, so that
        when a new PDF gets uploaded, we can search for the saved label within the HTML.
        From then on we know where the value is, so we can select it.
      */

      return await onLabelClick({ cell, key: currentField })
    }

    onFormatChange({
      key: currentField,
      value,
      valuePosition: {
        left: cellLeft,
        top: cellTop,
        right: cellRight,
      },
      label: format[currentField].labelText,
      labelPosition: format[currentField].labelPosition,
    })
  }

  const getCellPositionV2 = (cell) => {
    const parsedPage = Container.current.getBoundingClientRect()
    const { top, right } = cell.getBoundingClientRect()

    // some browsers give a too high precision here (e.g. Edge), we don't need any decimals
    return {
      cellTop: Math.ceil(top) - Math.ceil(parsedPage.top),
      cellLeft: Math.ceil(cell.offsetLeft),
      // cellRight: window.innerWidth - right,
      // TODO test this on the client app and on all screen sizes
      cellRight: Math.ceil(parsedPage.right) - Math.ceil(right),
      // cellRight: Math.ceil(cell.offsetLeft) + Math.ceil(width),
    }
  }

  /**
   * @deprecated Use `getCellPositionV2` instead.
   */
  const getCellPositionV1 = (cell) => {
    // formats algorithm v1
    // const rect = cell.getBoundingClientRect();
    // const pageYOffset = document.getElementById("upload-tool-app").getBoundingClientRect();
    //
    // // some browsers give a too high precision here (e.g. Edge), we don't need any decimals
    // return {
    //   cellTop:
    //     Math.round(rect.top) -
    //     Math.round(pageYOffset.top) -
    //     Math.round(headerHeight),
    //   cellLeft: Math.round(cell.offsetLeft)
    // };

    // formats algorithm v0
    const rect = cell.getBoundingClientRect()

    // some browsers give a too high precision here (e.g. Edge), we don't need any decimals
    return {
      cellTop: Math.round(rect.top) + Math.round(window.pageYOffset) - Math.round(headerHeight),
      cellLeft: Math.round(cell.offsetLeft),
    }
  }

  return (
    <div className="uta-interactive-divs">
      <ChoicesPopup
        {...{
          locale,
          missingDataFormatKey,
          onDateDataFormatIdChange,
          onDecimalDataFormatIdChange,
          setSelection,
          texts: choicesTexts,
          values: choicesValues,
          setValues: setChoicesValues,
          top: choicesTop,
          left: choicesLeft,
          dateDataFormatId,
          decimalDataFormatId,
          selection,
          customFields,
          currentField,
        }}
      />

      <div
        id="container"
        ref={Container}
        className={`${isInteractive ? 'uta-interactive' : ''} ${
          tutCurrentStep && tutCurrentStep.includes('location') ? 'uta-tut-highlighted' : ''
        }`}
        onClick={isInteractive ? (e) => onClick(e) : undefined}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </div>
  )
}
